/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import {
  faCircleArrowDown,
  faCircleArrowUp,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isUndefined } from "lodash";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

interface SurveyAverageScoreProps {
  averageScore?: number;
  previousScore?: number;
  maxScore: 5 | 10;
}

export function SurveyAverageScore({
  averageScore,
  previousScore,
  maxScore,
}: SurveyAverageScoreProps) {
  const theme = useTheme();

  const scoreDifference = (() => {
    // NPS scores can be zero, so cannot rely on truthiness
    if (isUndefined(averageScore) || isUndefined(previousScore)) {
      return undefined;
    }

    const diff = Math.round((averageScore - previousScore) * 10) / 10;

    if (diff === 0) {
      // only display the difference if it is non-zero
      return undefined;
    }

    return {
      points: Math.abs(diff),
      direction: diff > 0 ? "up" : "down",
    };
  })();

  return (
    <div
      css={(theme: AppTheme) => css`
        display: flex;
        align-items: center;
        gap: ${theme.spacing(1)};
      `}
    >
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          align-items: flex-end;
          margin: ${theme.spacing(2)} 0;
        `}
      >
        <Typography
          variant="h2"
          fontWeight={700}
          css={css`
            line-height: 1;
          `}
        >
          {averageScore ?? "-"}
        </Typography>
        <Typography
          variant="subtitle"
          color={theme.palette.grey[800]}
          css={(theme: AppTheme) => css`
            line-height: 1;
            margin-left: ${theme.spacing(0.75)};
          `}
        >
          /{maxScore}
        </Typography>
      </div>
      {scoreDifference && (
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            align-items: center;
            gap: ${theme.spacing(0.5)};
            padding: ${theme.spacing(0.5)} ${theme.spacing(1)};
            border-radius: ${theme.spacing(1)};
            background-color: ${theme.palette.grey[100]};
          `}
        >
          {scoreDifference.direction === "up" ? (
            <FontAwesomeIcon
              icon={faCircleArrowUp}
              color={theme.palette.success.main}
              width={13}
            />
          ) : (
            <FontAwesomeIcon
              icon={faCircleArrowDown}
              color={theme.palette.error.main}
              width={13}
            />
          )}
          <Typography
            variant="footnote"
            color={theme.palette.grey[800]}
            fontWeight={700}
          >
            {scoreDifference.points} pt{scoreDifference.points > 1 && "s"}
          </Typography>
        </div>
      )}
    </div>
  );
}
