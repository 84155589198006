import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CandidateDetailsQueryVariables = Types.Exact<{
  candidateId: Types.Scalars['ID'];
}>;


export type CandidateDetailsQuery = (
  { __typename?: 'Query' }
  & { organization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'shortName'>
  ), getCandidateById?: Types.Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Types.Candidate, 'id' | 'archived' | 'firstName' | 'lastName' | 'preferredName' | 'appliedOnDates' | 'email' | 'phoneNumber' | 'adminNotes' | 'hasUploadedResume'>
    & { organization: (
      { __typename?: 'RewardsOrganization' }
      & Pick<Types.RewardsOrganization, 'id' | 'shortName' | 'branchName'>
    ), jobsAppliedTo: Array<(
      { __typename?: 'JobPosting' }
      & Pick<Types.JobPosting, 'id' | 'title' | 'geography'>
    )>, referredByUser: (
      { __typename?: 'RewardsUser' }
      & Pick<Types.RewardsUser, 'id' | 'firstName' | 'lastName'>
    ), resumeReminders: Array<(
      { __typename?: 'CandidateResumeReminder' }
      & Pick<Types.CandidateResumeReminder, 'id' | 'sentAt'>
    )>, applicationSubmissions: Array<(
      { __typename?: 'ApplicationSubmission' }
      & Pick<Types.ApplicationSubmission, 'id' | 'submittedAt' | 'postalCode' | 'zipCode' | 'residingCity' | 'hasDriversLicenseAndAccessToCar' | 'yearsOfExperience'>
      & { jobPosting: (
        { __typename?: 'JobPosting' }
        & Pick<Types.JobPosting, 'id' | 'title' | 'geography'>
      ), yesNoAnswers?: Types.Maybe<Array<(
        { __typename?: 'ApplicationSubmissionYesNoAnswer' }
        & Pick<Types.ApplicationSubmissionYesNoAnswer, 'question' | 'answer'>
      )>> }
    )>, assignedToAdmin?: Types.Maybe<(
      { __typename?: 'RewardsAdminUser' }
      & Pick<Types.RewardsAdminUser, 'id' | 'firstName' | 'lastName'>
    )>, assignedToJobPosting?: Types.Maybe<(
      { __typename?: 'JobPosting' }
      & Pick<Types.JobPosting, 'id' | 'title' | 'geography' | 'candidateAlertEmail'>
    )>, completedStepsV2: Array<(
      { __typename?: 'RecordedCandidateRecruitmentStep' }
      & Pick<Types.RecordedCandidateRecruitmentStep, 'stepName' | 'recordedAt'>
    ) | (
      { __typename?: 'RecordedCandidateMonthlyRetentionStep' }
      & Pick<Types.RecordedCandidateMonthlyRetentionStep, 'durationMonths' | 'recordedAt'>
    ) | (
      { __typename?: 'RecordedCandidateHourlyRetentionStep' }
      & Pick<Types.RecordedCandidateHourlyRetentionStep, 'durationHours' | 'recordedAt'>
    )>, linkedEmployee?: Types.Maybe<(
      { __typename?: 'RewardsUser' }
      & Pick<Types.RewardsUser, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'>
      & { branch?: Types.Maybe<(
        { __typename?: 'RewardsOrganizationBranch' }
        & Pick<Types.RewardsOrganizationBranch, 'id' | 'name'>
      )> }
    )> }
  )> }
);


export const CandidateDetailsDocument = gql`
    query CandidateDetails($candidateId: ID!) {
  organization: getMyRewardsOrganization {
    id
    shortName
  }
  getCandidateById(candidateId: $candidateId) {
    id
    organization {
      id
      shortName
      branchName
    }
    archived
    firstName
    lastName
    preferredName
    jobsAppliedTo {
      id
      title
      geography
    }
    referredByUser {
      id
      firstName
      lastName
    }
    appliedOnDates
    email
    phoneNumber
    adminNotes
    hasUploadedResume
    resumeReminders {
      id
      sentAt
    }
    applicationSubmissions {
      id
      submittedAt
      jobPosting {
        id
        title
        geography
      }
      postalCode
      zipCode
      residingCity
      hasDriversLicenseAndAccessToCar
      yearsOfExperience
      yesNoAnswers {
        question
        answer
      }
    }
    assignedToAdmin {
      id
      firstName
      lastName
    }
    assignedToJobPosting {
      id
      title
      geography
      candidateAlertEmail
    }
    completedStepsV2 {
      ... on RecordedCandidateRecruitmentStep {
        stepName
        recordedAt
      }
      ... on RecordedCandidateMonthlyRetentionStep {
        durationMonths
        recordedAt
      }
      ... on RecordedCandidateHourlyRetentionStep {
        durationHours
        recordedAt
      }
    }
    linkedEmployee {
      id
      firstName
      lastName
      email
      phoneNumber
      branch {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useCandidateDetailsQuery__
 *
 * To run a query within a React component, call `useCandidateDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateDetailsQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useCandidateDetailsQuery(baseOptions: Apollo.QueryHookOptions<CandidateDetailsQuery, CandidateDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidateDetailsQuery, CandidateDetailsQueryVariables>(CandidateDetailsDocument, options);
      }
export function useCandidateDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidateDetailsQuery, CandidateDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidateDetailsQuery, CandidateDetailsQueryVariables>(CandidateDetailsDocument, options);
        }
export type CandidateDetailsQueryHookResult = ReturnType<typeof useCandidateDetailsQuery>;
export type CandidateDetailsLazyQueryHookResult = ReturnType<typeof useCandidateDetailsLazyQuery>;
export type CandidateDetailsQueryResult = Apollo.QueryResult<CandidateDetailsQuery, CandidateDetailsQueryVariables>;