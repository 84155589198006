import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateRewardsAdminMutationVariables = Types.Exact<{
  firstName: Types.Scalars['String'];
  lastName: Types.Scalars['String'];
  email: Types.Scalars['String'];
  jobTitle?: Types.Maybe<Types.Scalars['String']>;
  role?: Types.Maybe<Types.RewardsAdminRoleType>;
  permissions: Types.RewardsAdminPermissionsInput;
  branchIds?: Types.Maybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  automaticRecognitionBudgetConfig?: Types.Maybe<Types.RewardsAdminAutomaticRecognitionBudgetConfigInput>;
}>;


export type CreateRewardsAdminMutation = (
  { __typename?: 'Mutation' }
  & { createRewardsAdmin: (
    { __typename?: 'RewardsAdminUser' }
    & Pick<Types.RewardsAdminUser, 'id'>
  ) }
);


export const CreateRewardsAdminDocument = gql`
    mutation CreateRewardsAdmin($firstName: String!, $lastName: String!, $email: String!, $jobTitle: String, $role: RewardsAdminRoleType, $permissions: RewardsAdminPermissionsInput!, $branchIds: [ID!], $automaticRecognitionBudgetConfig: RewardsAdminAutomaticRecognitionBudgetConfigInput) {
  createRewardsAdmin(
    firstName: $firstName
    lastName: $lastName
    email: $email
    jobTitle: $jobTitle
    permissions: $permissions
    branchIds: $branchIds
    role: $role
    automaticRecognitionBudgetConfig: $automaticRecognitionBudgetConfig
  ) {
    id
  }
}
    `;
export type CreateRewardsAdminMutationFn = Apollo.MutationFunction<CreateRewardsAdminMutation, CreateRewardsAdminMutationVariables>;

/**
 * __useCreateRewardsAdminMutation__
 *
 * To run a mutation, you first call `useCreateRewardsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRewardsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRewardsAdminMutation, { data, loading, error }] = useCreateRewardsAdminMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      jobTitle: // value for 'jobTitle'
 *      role: // value for 'role'
 *      permissions: // value for 'permissions'
 *      branchIds: // value for 'branchIds'
 *      automaticRecognitionBudgetConfig: // value for 'automaticRecognitionBudgetConfig'
 *   },
 * });
 */
export function useCreateRewardsAdminMutation(baseOptions?: Apollo.MutationHookOptions<CreateRewardsAdminMutation, CreateRewardsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRewardsAdminMutation, CreateRewardsAdminMutationVariables>(CreateRewardsAdminDocument, options);
      }
export type CreateRewardsAdminMutationHookResult = ReturnType<typeof useCreateRewardsAdminMutation>;
export type CreateRewardsAdminMutationResult = Apollo.MutationResult<CreateRewardsAdminMutation>;
export type CreateRewardsAdminMutationOptions = Apollo.BaseMutationOptions<CreateRewardsAdminMutation, CreateRewardsAdminMutationVariables>;