import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type LaunchBudgetUtilizationAdminGoalConfigMutationVariables = Types.Exact<{
  adminIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  rewards: Types.AdminGoalRewardsInput;
  target: Types.AdminGoalTargetInput;
  startDate: Types.Scalars['Date'];
  endDate: Types.Scalars['Date'];
}>;


export type LaunchBudgetUtilizationAdminGoalConfigMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'launchNewAdminGoalConfig'>
);


export const LaunchBudgetUtilizationAdminGoalConfigDocument = gql`
    mutation LaunchBudgetUtilizationAdminGoalConfig($adminIds: [ID!]!, $rewards: AdminGoalRewardsInput!, $target: AdminGoalTargetInput!, $startDate: Date!, $endDate: Date!) {
  launchNewAdminGoalConfig(
    adminIds: $adminIds
    goalType: BUDGET_UTILIZATION
    rewards: $rewards
    target: $target
    startDate: $startDate
    endDate: $endDate
  )
}
    `;
export type LaunchBudgetUtilizationAdminGoalConfigMutationFn = Apollo.MutationFunction<LaunchBudgetUtilizationAdminGoalConfigMutation, LaunchBudgetUtilizationAdminGoalConfigMutationVariables>;

/**
 * __useLaunchBudgetUtilizationAdminGoalConfigMutation__
 *
 * To run a mutation, you first call `useLaunchBudgetUtilizationAdminGoalConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLaunchBudgetUtilizationAdminGoalConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [launchBudgetUtilizationAdminGoalConfigMutation, { data, loading, error }] = useLaunchBudgetUtilizationAdminGoalConfigMutation({
 *   variables: {
 *      adminIds: // value for 'adminIds'
 *      rewards: // value for 'rewards'
 *      target: // value for 'target'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useLaunchBudgetUtilizationAdminGoalConfigMutation(baseOptions?: Apollo.MutationHookOptions<LaunchBudgetUtilizationAdminGoalConfigMutation, LaunchBudgetUtilizationAdminGoalConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LaunchBudgetUtilizationAdminGoalConfigMutation, LaunchBudgetUtilizationAdminGoalConfigMutationVariables>(LaunchBudgetUtilizationAdminGoalConfigDocument, options);
      }
export type LaunchBudgetUtilizationAdminGoalConfigMutationHookResult = ReturnType<typeof useLaunchBudgetUtilizationAdminGoalConfigMutation>;
export type LaunchBudgetUtilizationAdminGoalConfigMutationResult = Apollo.MutationResult<LaunchBudgetUtilizationAdminGoalConfigMutation>;
export type LaunchBudgetUtilizationAdminGoalConfigMutationOptions = Apollo.BaseMutationOptions<LaunchBudgetUtilizationAdminGoalConfigMutation, LaunchBudgetUtilizationAdminGoalConfigMutationVariables>;