import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteRecognitionCategoryMutationVariables = Types.Exact<{
  recognitionCategoryId: Types.Scalars['ID'];
}>;


export type DeleteRecognitionCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteRecognitionCategory: (
    { __typename?: 'DeleteRecognitionCategoryResponse' }
    & Pick<Types.DeleteRecognitionCategoryResponse, 'deleted'>
  ) }
);


export const DeleteRecognitionCategoryDocument = gql`
    mutation DeleteRecognitionCategory($recognitionCategoryId: ID!) {
  deleteRecognitionCategory(recognitionCategoryId: $recognitionCategoryId) {
    deleted
  }
}
    `;
export type DeleteRecognitionCategoryMutationFn = Apollo.MutationFunction<DeleteRecognitionCategoryMutation, DeleteRecognitionCategoryMutationVariables>;

/**
 * __useDeleteRecognitionCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteRecognitionCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecognitionCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecognitionCategoryMutation, { data, loading, error }] = useDeleteRecognitionCategoryMutation({
 *   variables: {
 *      recognitionCategoryId: // value for 'recognitionCategoryId'
 *   },
 * });
 */
export function useDeleteRecognitionCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRecognitionCategoryMutation, DeleteRecognitionCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRecognitionCategoryMutation, DeleteRecognitionCategoryMutationVariables>(DeleteRecognitionCategoryDocument, options);
      }
export type DeleteRecognitionCategoryMutationHookResult = ReturnType<typeof useDeleteRecognitionCategoryMutation>;
export type DeleteRecognitionCategoryMutationResult = Apollo.MutationResult<DeleteRecognitionCategoryMutation>;
export type DeleteRecognitionCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteRecognitionCategoryMutation, DeleteRecognitionCategoryMutationVariables>;