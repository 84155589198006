import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RecognitionCategoryToDeleteQueryVariables = Types.Exact<{
  recognitionCategoryId: Types.Scalars['ID'];
}>;


export type RecognitionCategoryToDeleteQuery = (
  { __typename?: 'Query' }
  & { getRecognitionCategoryById?: Types.Maybe<(
    { __typename?: 'RecognitionCategory' }
    & Pick<Types.RecognitionCategory, 'id' | 'name'>
  )> }
);


export const RecognitionCategoryToDeleteDocument = gql`
    query RecognitionCategoryToDelete($recognitionCategoryId: ID!) {
  getRecognitionCategoryById(recognitionCategoryId: $recognitionCategoryId) {
    id
    name
  }
}
    `;

/**
 * __useRecognitionCategoryToDeleteQuery__
 *
 * To run a query within a React component, call `useRecognitionCategoryToDeleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecognitionCategoryToDeleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecognitionCategoryToDeleteQuery({
 *   variables: {
 *      recognitionCategoryId: // value for 'recognitionCategoryId'
 *   },
 * });
 */
export function useRecognitionCategoryToDeleteQuery(baseOptions: Apollo.QueryHookOptions<RecognitionCategoryToDeleteQuery, RecognitionCategoryToDeleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecognitionCategoryToDeleteQuery, RecognitionCategoryToDeleteQueryVariables>(RecognitionCategoryToDeleteDocument, options);
      }
export function useRecognitionCategoryToDeleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecognitionCategoryToDeleteQuery, RecognitionCategoryToDeleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecognitionCategoryToDeleteQuery, RecognitionCategoryToDeleteQueryVariables>(RecognitionCategoryToDeleteDocument, options);
        }
export type RecognitionCategoryToDeleteQueryHookResult = ReturnType<typeof useRecognitionCategoryToDeleteQuery>;
export type RecognitionCategoryToDeleteLazyQueryHookResult = ReturnType<typeof useRecognitionCategoryToDeleteLazyQuery>;
export type RecognitionCategoryToDeleteQueryResult = Apollo.QueryResult<RecognitionCategoryToDeleteQuery, RecognitionCategoryToDeleteQueryVariables>;