import * as React from "react";

function JobsLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 3h-4.333V1.667A1.668 1.668 0 0013 0H6.996a1.668 1.668 0 00-1.663 1.667V3H1a1 1 0 00-1 1v7.42a1.004 1.004 0 00.667.94V17a1 1 0 001 1h16.666a1 1 0 001-1v-4.639A1.004 1.004 0 0020 11.42V4a1 1 0 00-1-1zM6 1.667a1 1 0 01.996-1h6a1 1 0 011.004 1V3h-.667V1.667a.333.333 0 00-.329-.33H6.996a.333.333 0 00-.33.33V3H6V1.667zM12.667 3H7.333V2h5.334v1zm6 14a.333.333 0 01-.33.33H1.667a.334.334 0 01-.33-.33v-4.527l7.334 1.012v.846a1 1 0 001 1h.666a1 1 0 001-1v-.844l7.334-1.011L18.667 17zm-8-2.667a.333.333 0 01-.33.33h-.666a.333.333 0 01-.33-.33v-2a.333.333 0 01.33-.329h.666a.334.334 0 01.33.33v2zm8.666-2.913a.333.333 0 01-.286.329l-.092.012-7.622 1.053v-.48a1 1 0 00-1-1h-.666a1 1 0 00-1 1v.48L.954 11.751a.333.333 0 01-.287-.33V4a.333.333 0 01.329-.33h18a.333.333 0 01.33.33l.007 7.42z"
        fill={props.color}
      />
    </svg>
  );
}

export default JobsLogo;
