/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  AlayaCareIntegration,
  HhaxCaribouUserSyncFilters,
  HhaxIntegration,
} from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/types";

import { Accordion } from "../../../../../../components/accordion";

export interface ViewHHAXAutoSyncFiltersModalProps {
  integration?: HhaxIntegration | AlayaCareIntegration;
  open: boolean;
  onClose(): void;
}

const EMPTY_FILTERS = {
  hasCompletedFirstShift: [],
  disciplines: [],
  branches: [],
  offices: [],
  teams: [],
};

export function ViewHHAXAutoSyncFiltersModal({
  integration,
  open,
  onClose,
}: ViewHHAXAutoSyncFiltersModalProps) {
  const track = useTrack();

  useEffect(() => {
    if (open && integration) {
      track("HHAX Auto Sync opened view filters modal", {
        integrationId: integration.id,
      });
    }
  }, [open, integration, track]);

  const filters: HhaxCaribouUserSyncFilters = (() => {
    // Should not be more than one auto-sync per integration
    // when viewing for an organization.
    const autoSync = (integration as HhaxIntegration)?.userAutoSyncs[0]; // TODO (upcoming ticket):: https://cariboucare.atlassian.net/browse/CR-3947

    if (!autoSync || !autoSync.filters) {
      return EMPTY_FILTERS;
    }

    return autoSync.filters;
  })();

  const [openFilters, setOpenFilters] = useState({
    workedFirstShift: false,
    discipline: false,
    branch: false,
    office: false,
    team: false,
  });

  const toggleFilter = (filter: keyof typeof openFilters) => {
    setOpenFilters((prev) => ({
      ...prev,
      [filter]: !prev[filter],
    }));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      width="580px"
      disableBackdropClick={false}
    >
      <ModalTitle>Selected Filters</ModalTitle>
      <ModalContent>
        {filters.hasCompletedFirstShift.length > 0 && (
          <div
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            <Accordion
              title="Worked first shift"
              open={openFilters.workedFirstShift}
              onChange={() => toggleFilter("workedFirstShift")}
            >
              <Typography variant="body" color="grey.800">
                {filters.hasCompletedFirstShift.join(", ")}
              </Typography>
            </Accordion>
          </div>
        )}
        {filters.disciplines.length > 0 && (
          <div
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            <Accordion
              title="Discipline"
              open={openFilters.discipline}
              onChange={() => toggleFilter("discipline")}
            >
              <Typography variant="body" color="grey.800">
                {filters.disciplines
                  .map((d) => (d === null ? "(None)" : d))
                  .join(", ")}
              </Typography>
            </Accordion>
          </div>
        )}
        {filters.branches.length > 0 && (
          <div
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            <Accordion
              title="Branch"
              open={openFilters.branch}
              onChange={() => toggleFilter("branch")}
            >
              <Typography variant="body" color="grey.800">
                {filters.branches
                  .map((b) => (b.branchId === null ? "(None)" : b.branchId))
                  .join(", ")}
              </Typography>
            </Accordion>
          </div>
        )}
        {filters.offices.length > 0 && (
          <div
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            <Accordion
              title="Office"
              open={openFilters.office}
              onChange={() => toggleFilter("office")}
            >
              <Typography variant="body" color="grey.800">
                {filters.offices
                  .map((o) => (o.officeId === null ? "(None)" : o.officeId))
                  .join(", ")}
              </Typography>
            </Accordion>
          </div>
        )}
        {filters.teams.length > 0 && (
          <div
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            <Accordion
              title="Team"
              open={openFilters.team}
              onChange={() => toggleFilter("team")}
            >
              <Typography variant="body" color="grey.800">
                {filters.teams
                  .map((t) => (t.teamId === null ? "(None)" : t.teamId))
                  .join(", ")}
              </Typography>
            </Accordion>
          </div>
        )}
      </ModalContent>
      <ModalActions>
        <Button
          width="auto"
          size="large"
          color="primary"
          label="Done"
          onClick={onClose}
        />
      </ModalActions>
    </Modal>
  );
}
