import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DownloadReportModalDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DownloadReportModalDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'timezone' | 'launched' | 'launchedAt' | 'recognitionBudgetsEnabled' | 'hasVisitData'>
    & { automaticRecognitionBudgetConfig: (
      { __typename?: 'RewardsOrganizationAutomaticRecognitionBudgetConfig' }
      & Pick<Types.RewardsOrganizationAutomaticRecognitionBudgetConfig, 'frequency'>
    ) }
  ), getMyRewardsAdminUser?: Types.Maybe<(
    { __typename?: 'RewardsAdminUser' }
    & Pick<Types.RewardsAdminUser, 'id' | 'role'>
  )> }
);


export const DownloadReportModalDataDocument = gql`
    query DownloadReportModalData {
  getMyRewardsOrganization {
    id
    timezone
    launched
    launchedAt
    recognitionBudgetsEnabled
    automaticRecognitionBudgetConfig {
      frequency
    }
    hasVisitData
  }
  getMyRewardsAdminUser {
    id
    role
  }
}
    `;

/**
 * __useDownloadReportModalDataQuery__
 *
 * To run a query within a React component, call `useDownloadReportModalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadReportModalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadReportModalDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useDownloadReportModalDataQuery(baseOptions?: Apollo.QueryHookOptions<DownloadReportModalDataQuery, DownloadReportModalDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadReportModalDataQuery, DownloadReportModalDataQueryVariables>(DownloadReportModalDataDocument, options);
      }
export function useDownloadReportModalDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadReportModalDataQuery, DownloadReportModalDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadReportModalDataQuery, DownloadReportModalDataQueryVariables>(DownloadReportModalDataDocument, options);
        }
export type DownloadReportModalDataQueryHookResult = ReturnType<typeof useDownloadReportModalDataQuery>;
export type DownloadReportModalDataLazyQueryHookResult = ReturnType<typeof useDownloadReportModalDataLazyQuery>;
export type DownloadReportModalDataQueryResult = Apollo.QueryResult<DownloadReportModalDataQuery, DownloadReportModalDataQueryVariables>;