/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Divider } from "@rewards-web/shared/components/divider";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface BulkUploadProcessingModalProps {
  open: boolean;
  onClose(): void;
}

export function BulkUploadProcessingModal({
  open,
  onClose,
}: BulkUploadProcessingModalProps): JSX.Element {
  return (
    <Modal width="650px" open={open} onClose={onClose}>
      <ModalTitle>Bulk upload processing...</ModalTitle>
      <ModalContent>
        <Typography variant="body" color="textSecondary">
          You’ll be notified when your employees have been updated.
        </Typography>
        <Typography variant="body" color="textSecondary">
          If you have any questions contact us at{" "}
          <a href={`mailto:${encodeURI("help+admin@caribou.care")}`}>
            help+admin@caribou.care
          </a>
        </Typography>
      </ModalContent>

      <Divider
        css={(theme: AppTheme) =>
          css`
            margin-top: ${theme.spacing(2)};
            margin-bottom: ${theme.spacing(2)};
          `
        }
      />

      <ModalActions>
        <Button width="auto" color="primary" onClick={onClose} label="Okay" />
      </ModalActions>
    </Modal>
  );
}
