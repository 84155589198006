/**
 * Returns the percent difference between two numbers.
 *
 * May return a negative percentage if $curr has decreased.
 * Rounds to the nearest whole number.
 * Returns infinity / negative infinity if the previous value was zero and the current value is not.
 */
export const getPercentDifference = (
  prev: number,
  curr: number
): number | null => {
  if (prev === 0) {
    if (curr === 0) {
      return 0;
    }
    if (curr > 0) {
      return Infinity;
    }
    return -Infinity;
  }
  return Math.round(((curr - prev) / prev) * 100);
};

/**
 * Returns the percentage point difference between two percentages, ie (5/100, 2/100) => -3
 *
 * May return a negative percentage if $curr has decreased.
 * Rounds to the nearest whole number.
 */
export const getDifferenceOfPercentages = ({
  prev,
  curr,
}: {
  prev: { numerator: number; denominator: number };
  curr: { numerator: number; denominator: number };
}) => {
  const [prevPercentagePoints, currPercentagePoints] = [prev, curr].map(
    ({ numerator, denominator }) => {
      if (denominator === 0) {
        return 0;
      }
      return (numerator / denominator) * 100;
    }
  );

  return Math.round(currPercentagePoints - prevPercentagePoints);
};
