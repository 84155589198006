import { faBullseye } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { GoalsAchievedInsightMetric as GoalsAchievedInsightMetricType } from "@rewards-web/shared/graphql-types";

import { InsightMetric } from "../../../../components/insight-metric";
import { getPercentDifference } from "../../../../utils";

export type GoalsAchievedInsightMetricProps = {
  currentGoalsAchievedInsightMetric: GoalsAchievedInsightMetricType | undefined;
  previousGoalsAchievedInsightMetric:
    | GoalsAchievedInsightMetricType
    | undefined;
};

export function GoalsAchievedInsightMetric(
  props: GoalsAchievedInsightMetricProps
) {
  const currentGoalsAchieved =
    props.currentGoalsAchievedInsightMetric?.goalsAchievedCount;

  const percentPointChange = (() => {
    const previousGoalsAchieved =
      props.previousGoalsAchievedInsightMetric?.goalsAchievedCount;
    if (
      currentGoalsAchieved === undefined ||
      previousGoalsAchieved === undefined
    ) {
      return null;
    }
    return getPercentDifference(previousGoalsAchieved, currentGoalsAchieved);
  })();

  return (
    <InsightMetric
      label="Goals achieved"
      icon={<FontAwesomeIcon icon={faBullseye} />}
      tooltipText="This is the number of activities employees have completed, covering EVV, surveys, shifts, milestones, and more."
      value={
        currentGoalsAchieved !== undefined
          ? {
              type: "number",
              number: currentGoalsAchieved,
            }
          : {
              type: "loading",
            }
      }
      percentPointChange={percentPointChange}
    />
  );
}
