export function getCharactersRemainingText(
  message: string,
  allowedLimit: number
): string | undefined {
  if (message.length > allowedLimit) {
    return `Characters exceeded by: ${message.length - allowedLimit}`;
  }

  if (message.length >= allowedLimit * 0.7) {
    // show message if they reach 70% of allowed limit
    return `Characters remaining: ${allowedLimit - message.length}`;
  }
}
