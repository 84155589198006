/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  addDays,
  isFriday,
  isSaturday,
  isThursday,
  nextTuesday,
} from "date-fns";
import { Controller, useForm, useWatch } from "react-hook-form";

import { DateField } from "@rewards-web/shared/components/date-field";
import { Form } from "@rewards-web/shared/components/form";
import { Typography } from "@rewards-web/shared/components/typography";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { AdminOnboardingCard } from "../../../components/onboarding-card/onboarding-card";
import { AdminOnboardingCardContent } from "../../../components/onboarding-card/onboarding-card-content";
import { AdminOnboardingCardHeader } from "../../../components/onboarding-card/onboarding-card-header";
import { AdminOnboardingStepActions } from "../../../components/step-actions";

export interface BookKickoffFormValues {
  targetLaunchDate: string;
}

interface BookKickoffFormProps {
  onSubmit: (values: BookKickoffFormValues) => void;
}

export function BookKickoffForm({ onSubmit }: BookKickoffFormProps) {
  const form = useForm<BookKickoffFormValues>({
    defaultValues: { targetLaunchDate: "" },
  });

  const targetLaunchDate = useWatch({
    control: form.control,
    name: "targetLaunchDate",
  });
  const snackbar = useSnackbar();

  const twoBusinessDaysFromNow = (() => {
    const today = new Date();

    if (isThursday(today) || isFriday(today) || isSaturday(today)) {
      return nextTuesday(today);
    }

    return addDays(today, 2);
  })();

  return (
    <Form
      submitting={form.formState.isSubmitting}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <AdminOnboardingCard>
        <AdminOnboardingCardHeader title="⭐️ Book launch date" />
        <AdminOnboardingCardContent>
          <div
            css={(appTheme: AppTheme) => css`
              margin-bottom: ${appTheme.spacing(3.75)};
            `}
          >
            <Typography
              variant="body"
              color="textSecondary"
              css={(appTheme: AppTheme) => css`
                margin-bottom: ${appTheme.spacing(1)};
              `}
            >
              Caribooyah, we're almost there! Set your target launch date and we
              will work towards this goal together.
            </Typography>
            <Typography variant="body" color="text.secondary">
              What's next:
              <ul>
                <li>Our team will verify the information you shared with us</li>
                <li>Our team will reach out to confirm your launch date</li>
                <li>
                  On launch day, we will automatically invite your selected
                  employees to the rewards program
                </li>
              </ul>
            </Typography>
            <Typography
              variant="body"
              color="text.secondary"
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(1)};
              `}
            >
              We are excited to get your rewards program launched!
            </Typography>
            <Typography variant="body" color="text.secondary">
              <strong>
                Note: Please ensure you've completed all required steps before
                saving to avoid delaying your launch.
              </strong>
            </Typography>
          </div>
          <Controller
            control={form.control}
            name="targetLaunchDate"
            rules={{
              required: "Target launch date is required",
            }}
            render={({ field, fieldState }) => (
              <DateField
                {...field}
                error={fieldState.error}
                label="Target launch date"
                minDate={twoBusinessDaysFromNow}
              />
            )}
          />
        </AdminOnboardingCardContent>
      </AdminOnboardingCard>
      <AdminOnboardingStepActions
        onSubmit={() => {
          if (!targetLaunchDate) {
            snackbar.show({
              message: "Missing required fields",
              severity: "error",
            });
          }
        }}
        disableSkip
        submitButtonLabel="🎉 Save"
      />
    </Form>
  );
}
