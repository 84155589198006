import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ReferralsInsightMetricGroupDataQueryVariables = Types.Exact<{
  startDate: Types.Scalars['Timestamp'];
  endDate: Types.Scalars['Timestamp'];
}>;


export type ReferralsInsightMetricGroupDataQuery = (
  { __typename?: 'Query' }
  & { getJobSharesInsightMetric: (
    { __typename?: 'JobSharesInsightMetric' }
    & Pick<Types.JobSharesInsightMetric, 'id' | 'jobShareCount'>
  ), getApplicantsInsightMetric: (
    { __typename?: 'ApplicantsInsightMetric' }
    & Pick<Types.ApplicantsInsightMetric, 'id' | 'applicantCount'>
  ), getTotalHiresInsightMetric: (
    { __typename?: 'TotalHiresInsightMetric' }
    & Pick<Types.TotalHiresInsightMetric, 'id' | 'hiredCandidateCount'>
  ) }
);


export const ReferralsInsightMetricGroupDataDocument = gql`
    query ReferralsInsightMetricGroupData($startDate: Timestamp!, $endDate: Timestamp!) {
  getJobSharesInsightMetric(startDate: $startDate, endDate: $endDate) {
    id
    jobShareCount
  }
  getApplicantsInsightMetric(startDate: $startDate, endDate: $endDate) {
    id
    applicantCount
  }
  getTotalHiresInsightMetric(startDate: $startDate, endDate: $endDate) {
    id
    hiredCandidateCount
  }
}
    `;

/**
 * __useReferralsInsightMetricGroupDataQuery__
 *
 * To run a query within a React component, call `useReferralsInsightMetricGroupDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralsInsightMetricGroupDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralsInsightMetricGroupDataQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useReferralsInsightMetricGroupDataQuery(baseOptions: Apollo.QueryHookOptions<ReferralsInsightMetricGroupDataQuery, ReferralsInsightMetricGroupDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReferralsInsightMetricGroupDataQuery, ReferralsInsightMetricGroupDataQueryVariables>(ReferralsInsightMetricGroupDataDocument, options);
      }
export function useReferralsInsightMetricGroupDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralsInsightMetricGroupDataQuery, ReferralsInsightMetricGroupDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReferralsInsightMetricGroupDataQuery, ReferralsInsightMetricGroupDataQueryVariables>(ReferralsInsightMetricGroupDataDocument, options);
        }
export type ReferralsInsightMetricGroupDataQueryHookResult = ReturnType<typeof useReferralsInsightMetricGroupDataQuery>;
export type ReferralsInsightMetricGroupDataLazyQueryHookResult = ReturnType<typeof useReferralsInsightMetricGroupDataLazyQuery>;
export type ReferralsInsightMetricGroupDataQueryResult = Apollo.QueryResult<ReferralsInsightMetricGroupDataQuery, ReferralsInsightMetricGroupDataQueryVariables>;