/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Card } from "@rewards-web/shared/components/card";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  SurveyInsightsDateRange,
  SurveyInsightsDateRangeInput,
} from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { DateRangeSelectField } from "../components/date-range-select-field";
import { SurveyAgencyAverageCard } from "../components/survey-agency-average-card";
import { SurveyEngagementCard } from "../components/survey-engagement-card";
import { PULSE_SURVEY_QUESTIONS } from "../constants";
import { convertValueToDateRange } from "../utils";
import { usePulseSurveyAvailableDateRangesQuery } from "./pulse-survey-available-date-ranges.generated";
import { usePulseSurveyOverviewDataQuery } from "./pulse-survey-overview-data.generated";
import { SurveyQuestionScoreCard } from "./survey-question-score-card";

export function PulseSurveysPage() {
  const [searchParams] = useSearchParams();

  const {
    data: dateRangeData,
    error: dateRangeError,
  } = usePulseSurveyAvailableDateRangesQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
  });

  const [selectedDateRange, setSelectedDateRange] = useState<
    SurveyInsightsDateRange | undefined
  >();

  const dateRangeInput = ((): SurveyInsightsDateRangeInput | undefined => {
    if (!selectedDateRange) {
      return undefined;
    }

    return {
      label: selectedDateRange.label,
      year: selectedDateRange.year,
    };
  })();

  const { data, error } = usePulseSurveyOverviewDataQuery({
    onError: reportError,
    skip: !dateRangeInput,
    variables: {
      dateRange: dateRangeInput,
    },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    // set initial date range on load
    if (dateRangeData && !selectedDateRange) {
      const searchedDateRange = searchParams.get("dateRange");

      // initialize with searched date range, or else most recent
      const dateRange = searchedDateRange
        ? convertValueToDateRange(
            searchedDateRange,
            dateRangeData.getAvailableSurveyDateRanges
          )
        : dateRangeData.getAvailableSurveyDateRanges[0];

      return setSelectedDateRange(dateRange);
    }
  }, [dateRangeData, selectedDateRange, searchParams]);

  const theme = useTheme();

  if (error || dateRangeError) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  if (!dateRangeData) {
    return <PageLoadingState />;
  }

  const noSurveysSent = data?.getSurveyEngagement.numSent === 0;
  const noResponses = data?.getSurveyEngagement.numResponses === 0;

  return (
    <>
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <Typography variant="h2" color="textPrimary" fontWeight={700}>
          Overview
        </Typography>
        <DateRangeSelectField
          value={selectedDateRange}
          options={dateRangeData?.getAvailableSurveyDateRanges ?? []}
          onChange={(dateRange: SurveyInsightsDateRange) =>
            setSelectedDateRange(dateRange)
          }
        />
      </div>
      {!data ? (
        <PageLoadingState />
      ) : (
        <>
          {noSurveysSent ? (
            <Alert
              severity="info"
              message="No surveys sent during this time period."
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(1)};
              `}
            />
          ) : noResponses ? (
            <Alert
              severity="info"
              message="No responses recieved during this time period."
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(1)};
              `}
            />
          ) : null}
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              flex-wrap: wrap;
              gap: ${theme.spacing(2)};
              margin-bottom: ${theme.spacing(3)};
            `}
          >
            <SurveyEngagementCard
              dateRange={selectedDateRange}
              numSent={data.getSurveyEngagement.numSent}
              numResponses={data.getSurveyEngagement.numResponses}
              responseRatePercentage={Math.round(
                data.getSurveyEngagement.responseRate * 100
              )} // round to closest percentage
              reward={data.getSurveyEngagement.reward}
            />
            {!noResponses && (
              <SurveyAgencyAverageCard
                averageScore={
                  Math.round(data.getSurveyAverageScore.averageScore * 10) / 10
                } // round to 1 decimal place
                previousScore={
                  data.getSurveyAverageScore.previousAverageScore
                    ? Math.round(
                        data.getSurveyAverageScore.previousAverageScore * 10
                      ) / 10
                    : undefined
                }
              />
            )}
          </div>
          {!noResponses && (
            <Card
              css={(theme: AppTheme) =>
                css`
                  padding: ${theme.spacing(3)};
                `
              }
            >
              <Typography
                variant="h3"
                color="textPrimary"
                fontWeight={700}
                css={(theme: AppTheme) =>
                  css`
                    margin-bottom: ${theme.spacing(3)};
                  `
                }
              >
                Results
              </Typography>
              <div
                css={css`
                  display: flex;
                  flex-wrap: wrap;
                  gap: ${theme.spacing(3)};
                `}
              >
                {PULSE_SURVEY_QUESTIONS.map((questionType) => (
                  <SurveyQuestionScoreCard
                    key={questionType}
                    questionType={questionType}
                    dateRangeInput={dateRangeInput}
                  />
                ))}
              </div>
            </Card>
          )}
        </>
      )}
    </>
  );
}
