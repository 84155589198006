import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateRecognitionPointsMessageMutationVariables = Types.Exact<{
  recognitionPointsId: Types.Scalars['ID'];
  message: Types.Scalars['String'];
}>;


export type UpdateRecognitionPointsMessageMutation = (
  { __typename?: 'Mutation' }
  & { updateScheduledRecognitionPointsMessage: (
    { __typename?: 'RecognitionPoints' }
    & Pick<Types.RecognitionPoints, 'id' | 'message'>
  ) }
);


export const UpdateRecognitionPointsMessageDocument = gql`
    mutation UpdateRecognitionPointsMessage($recognitionPointsId: ID!, $message: String!) {
  updateScheduledRecognitionPointsMessage(
    recognitionPointsId: $recognitionPointsId
    message: $message
  ) {
    id
    message
  }
}
    `;
export type UpdateRecognitionPointsMessageMutationFn = Apollo.MutationFunction<UpdateRecognitionPointsMessageMutation, UpdateRecognitionPointsMessageMutationVariables>;

/**
 * __useUpdateRecognitionPointsMessageMutation__
 *
 * To run a mutation, you first call `useUpdateRecognitionPointsMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecognitionPointsMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecognitionPointsMessageMutation, { data, loading, error }] = useUpdateRecognitionPointsMessageMutation({
 *   variables: {
 *      recognitionPointsId: // value for 'recognitionPointsId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useUpdateRecognitionPointsMessageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecognitionPointsMessageMutation, UpdateRecognitionPointsMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecognitionPointsMessageMutation, UpdateRecognitionPointsMessageMutationVariables>(UpdateRecognitionPointsMessageDocument, options);
      }
export type UpdateRecognitionPointsMessageMutationHookResult = ReturnType<typeof useUpdateRecognitionPointsMessageMutation>;
export type UpdateRecognitionPointsMessageMutationResult = Apollo.MutationResult<UpdateRecognitionPointsMessageMutation>;
export type UpdateRecognitionPointsMessageMutationOptions = Apollo.BaseMutationOptions<UpdateRecognitionPointsMessageMutation, UpdateRecognitionPointsMessageMutationVariables>;