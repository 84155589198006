import { OnboardingStepsSupportedByUI } from "../../../../../../pages/authenticated/onboarding/constants";

export function calculateStepProgressPercentage(
  completedSteps: OnboardingStepsSupportedByUI[],
  orderedOnboardingSteps: OnboardingStepsSupportedByUI[]
) {
  const numCompletedSteps = completedSteps.length;
  const numSteps = orderedOnboardingSteps.length;

  if (numCompletedSteps <= numSteps && numCompletedSteps >= 0) {
    return Math.round((numCompletedSteps / numSteps) * 100);
  }
  return 0;
}
