export function CloseIcon() {
  return (
    <svg
      width="13"
      height="20"
      viewBox="0 0 13 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33594 10.5L12.5547 14.7188C12.7891 14.9531 12.7891 15.3828 12.5547 15.6172L11.5781 16.5938C11.3438 16.8281 10.9141 16.8281 10.6797 16.5938L6.5 12.375L2.28125 16.5938C2.04688 16.8281 1.61719 16.8281 1.38281 16.5938L0.40625 15.6172C0.171875 15.3828 0.171875 14.9531 0.40625 14.7188L4.625 10.5L0.40625 6.32031C0.171875 6.08594 0.171875 5.65625 0.40625 5.42188L1.38281 4.44531C1.61719 4.21094 2.04688 4.21094 2.28125 4.44531L6.5 8.66406L10.6797 4.44531C10.9141 4.21094 11.3438 4.21094 11.5781 4.44531L12.5547 5.42188C12.7891 5.65625 12.7891 6.08594 12.5547 6.32031L8.33594 10.5Z"
        fill="#444444"
      />
    </svg>
  );
}
