/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { AppBar } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import { sortBy } from "lodash";
import { useMatch } from "react-router-dom";

import { ProgressSpinner } from "@rewards-web/shared/components/progress-spinner";
import { SearchTextField } from "@rewards-web/shared/components/search-text-field";
import { Role } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { useRole } from "../../../modules/role";
import { useAdminNavBarOrgDataQuery } from "./admin-nav-bar-org-data.generated";
import { useAdminNavBarSuperuserOrgListQuery } from "./admin-nav-bar-superuser-org-list.generated";
import { useAdminNavBarUserDataQuery } from "./admin-nav-bar-user-data.generated";
import CaribouLogo from "./caribou-logo.png";
import { OnboardingProgress } from "./onboarding-progress";
import { OpenRewardsAppButton } from "./open-rewards-app-button";
import { NavigationBarOrganizationName } from "./organization-name";
import { UserMenu } from "./user-menu";

export interface NavigationBarProps {
  height: number;
  selectedSuperuserOrganizationId: number | null;
  setSelectedSuperuserOrganizationId(id: number | null): void;
  iconUrl?: string;
}

export function NavigationBar({
  height,
  selectedSuperuserOrganizationId,
  setSelectedSuperuserOrganizationId,
  iconUrl,
}: NavigationBarProps): JSX.Element {
  const role = useRole();
  const orgDataQuery = useAdminNavBarOrgDataQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first",
    skip: role.data?.role !== "admin" && !selectedSuperuserOrganizationId,
  });
  const userDataQuery = useAdminNavBarUserDataQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first",
  });
  const superuserOrgList = useAdminNavBarSuperuserOrgListQuery({
    onError: reportError,
    skip: role.data?.role !== "superuser",
  });
  const onEditAdminPage = useMatch("/admins/:id/edit");

  const hasActiveRewardsUserRole = userDataQuery.data?.myIdentity?.roles.some(
    (role) => role.roleName === Role.RewardsUser && role.active
  );

  return (
    <div>
      <AppBar
        css={(theme: AppTheme) => css`
          background-color: ${theme.palette.primary.main};
        `}
        elevation={0}
      >
        <Toolbar
          css={css`
            min-height: auto;
            height: ${height}px;
            display: flex;
            justify-content: space-between;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <img
              src={iconUrl ?? CaribouLogo}
              alt="Organization Logo"
              css={css`
                border-radius: 5px;
                width: 35px;
                height: 31px;
                object-fit: scale-down;
              `}
            />

            {role.data?.role === "superuser" && (
              <div
                css={(theme: AppTheme) => css`
                  margin-top: ${theme.spacing(1)};
                  margin-left: ${theme.spacing(2)};
                  width: 300px;
                `}
              >
                <SearchTextField
                  label="Organization"
                  hideLabel
                  hideSpaceForErrorText
                  disabled={onEditAdminPage ? true : false}
                  tooltipText={
                    onEditAdminPage
                      ? "The selected organization can not be changed while editing an admin. Please navigate to another page in order to change the organization."
                      : ""
                  }
                  value={String(selectedSuperuserOrganizationId)}
                  onChange={(e) => {
                    setSelectedSuperuserOrganizationId(e ? Number(e) : null);
                  }}
                  options={sortBy(
                    superuserOrgList.data?.adminListRewardsOrganizations
                      .items ?? [],
                    (org) => org.internalName ?? org.shortName
                  ).map((org) => ({
                    label: org.internalName ?? org.shortName,
                    value: org.id,
                  }))}
                />
              </div>
            )}

            {role.data?.role === "admin" && (
              <div
                css={(theme: AppTheme) => css`
                  margin-left: ${theme.spacing(2)};
                `}
              >
                {orgDataQuery.data && (
                  <NavigationBarOrganizationName
                    shortName={
                      orgDataQuery.data.getMyRewardsOrganization.shortName
                    }
                  />
                )}

                {!orgDataQuery.data && orgDataQuery.loading && (
                  <ProgressSpinner
                    size={30}
                    css={css`
                      color: white;
                    `}
                  />
                )}
              </div>
            )}

            <OnboardingProgress
              launched={orgDataQuery.data?.getMyRewardsOrganization.launched}
            />
          </div>

          <div
            css={css`
              display: flex;
              height: 100%;
              align-items: center;
            `}
          >
            {hasActiveRewardsUserRole && (
              <OpenRewardsAppButton
                rewardsProgramName={
                  orgDataQuery.data?.getMyRewardsOrganization.whiteLabelConfig
                    ?.rewardsProgramShortName ?? "Caribou Rewards"
                }
              />
            )}

            {userDataQuery.data && (
              <UserMenu
                name={userDataQuery.data.myIdentity!.name ?? "Unknown Name"}
              />
            )}

            {!userDataQuery.data && userDataQuery.loading && (
              <ProgressSpinner
                size={30}
                css={(theme: AppTheme) => css`
                  color: white;
                  margin-right: ${theme.spacing(2)};
                `}
              />
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
