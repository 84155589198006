import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type HhaxUserSyncToEditQueryVariables = Types.Exact<{
  userSyncId: Types.Scalars['ID'];
}>;


export type HhaxUserSyncToEditQuery = (
  { __typename?: 'Query' }
  & { getHHAXCaribouUserSyncById: (
    { __typename?: 'HHAXCaribouUserSync' }
    & Pick<Types.HhaxCaribouUserSync, 'id' | 'hhaxIntegrationId'>
    & { filters?: Types.Maybe<(
      { __typename?: 'HHAXCaribouUserSyncFilters' }
      & Pick<Types.HhaxCaribouUserSyncFilters, 'disciplines' | 'hasCompletedFirstShift'>
      & { branches: Array<(
        { __typename?: 'HHAXCaribouUserSyncBranchFilterOption' }
        & Pick<Types.HhaxCaribouUserSyncBranchFilterOption, 'branchId'>
      )>, offices: Array<(
        { __typename?: 'HHAXCaribouUserSyncOfficeFilterOption' }
        & Pick<Types.HhaxCaribouUserSyncOfficeFilterOption, 'officeId'>
      )>, teams: Array<(
        { __typename?: 'HHAXCaribouUserSyncTeamFilterOption' }
        & Pick<Types.HhaxCaribouUserSyncTeamFilterOption, 'teamId'>
      )> }
    )> }
  ) }
);


export const HhaxUserSyncToEditDocument = gql`
    query HHAXUserSyncToEdit($userSyncId: ID!) {
  getHHAXCaribouUserSyncById(userSyncId: $userSyncId) {
    id
    hhaxIntegrationId
    filters {
      disciplines
      branches {
        branchId
      }
      offices {
        officeId
      }
      teams {
        teamId
      }
      hasCompletedFirstShift
    }
  }
}
    `;

/**
 * __useHhaxUserSyncToEditQuery__
 *
 * To run a query within a React component, call `useHhaxUserSyncToEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useHhaxUserSyncToEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHhaxUserSyncToEditQuery({
 *   variables: {
 *      userSyncId: // value for 'userSyncId'
 *   },
 * });
 */
export function useHhaxUserSyncToEditQuery(baseOptions: Apollo.QueryHookOptions<HhaxUserSyncToEditQuery, HhaxUserSyncToEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HhaxUserSyncToEditQuery, HhaxUserSyncToEditQueryVariables>(HhaxUserSyncToEditDocument, options);
      }
export function useHhaxUserSyncToEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HhaxUserSyncToEditQuery, HhaxUserSyncToEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HhaxUserSyncToEditQuery, HhaxUserSyncToEditQueryVariables>(HhaxUserSyncToEditDocument, options);
        }
export type HhaxUserSyncToEditQueryHookResult = ReturnType<typeof useHhaxUserSyncToEditQuery>;
export type HhaxUserSyncToEditLazyQueryHookResult = ReturnType<typeof useHhaxUserSyncToEditLazyQuery>;
export type HhaxUserSyncToEditQueryResult = Apollo.QueryResult<HhaxUserSyncToEditQuery, HhaxUserSyncToEditQueryVariables>;