import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DrawDetailsQueryVariables = Types.Exact<{
  drawId: Types.Scalars['ID'];
}>;


export type DrawDetailsQuery = (
  { __typename?: 'Query' }
  & { draw?: Types.Maybe<(
    { __typename?: 'Draw' }
    & Pick<Types.Draw, 'id' | 'name' | 'scheduleStatus' | 'scheduledStartDate' | 'scheduledEndDate' | 'pendingWinnerApproval'>
    & { prizeStructure: (
      { __typename: 'DrawPrizeStructureSinglePrizeMultipleWinners' }
      & Pick<Types.DrawPrizeStructureSinglePrizeMultipleWinners, 'id' | 'numWinners' | 'totalPrizePointValue'>
      & { prize: (
        { __typename: 'DrawPrizePoints' }
        & Pick<Types.DrawPrizePoints, 'id' | 'pointValue'>
      ) | (
        { __typename: 'DrawTieredPrizePoints' }
        & Pick<Types.DrawTieredPrizePoints, 'id'>
      ) }
    ) | (
      { __typename: 'DrawPrizeStructureTieredPrizesMultipleWinners' }
      & Pick<Types.DrawPrizeStructureTieredPrizesMultipleWinners, 'id' | 'numWinners' | 'totalPrizePointValue'>
      & { prizes: Array<{ __typename: 'DrawPrizePoints' } | (
        { __typename: 'DrawTieredPrizePoints' }
        & Pick<Types.DrawTieredPrizePoints, 'pointValue' | 'numWinners'>
      )> }
    ), winners?: Types.Maybe<Array<(
      { __typename?: 'DrawWinner' }
      & Pick<Types.DrawWinner, 'id' | 'prizeTierIndex' | 'claimedPrize'>
      & { user: (
        { __typename?: 'RewardsUser' }
        & Pick<Types.RewardsUser, 'id' | 'firstName' | 'lastName' | 'workEmail'>
        & { personalContactInfo?: Types.Maybe<(
          { __typename: 'RewardsUserPersonalContactInfoData' }
          & Pick<Types.RewardsUserPersonalContactInfoData, 'id' | 'email'>
        ) | (
          { __typename: 'RewardsUserPersonalContactInfoSuppressed' }
          & Pick<Types.RewardsUserPersonalContactInfoSuppressed, 'id'>
        )> }
      ) }
    )>> }
  )>, organization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'pointsPerDollar' | 'timezone'>
    & { whiteLabelConfig?: Types.Maybe<(
      { __typename?: 'RewardsOrganizationWhiteLabelConfig' }
      & Pick<Types.RewardsOrganizationWhiteLabelConfig, 'id' | 'rewardsProgramShortName'>
    )> }
  ) }
);


export const DrawDetailsDocument = gql`
    query DrawDetails($drawId: ID!) {
  draw: getDrawById(drawId: $drawId) {
    id
    name
    scheduleStatus
    scheduledStartDate
    scheduledEndDate
    pendingWinnerApproval
    prizeStructure {
      __typename
      ... on BaseDrawPrizeStructure {
        id
      }
      ... on DrawPrizeStructureSinglePrizeMultipleWinners {
        prize {
          __typename
          ... on BaseDrawPrize {
            id
          }
          ... on DrawPrizePoints {
            pointValue
          }
        }
        numWinners
        totalPrizePointValue
      }
      ... on DrawPrizeStructureTieredPrizesMultipleWinners {
        prizes {
          __typename
          ... on DrawTieredPrizePoints {
            pointValue
            numWinners
          }
        }
        numWinners
        totalPrizePointValue
      }
    }
    winners {
      id
      prizeTierIndex
      user {
        id
        firstName
        lastName
        personalContactInfo {
          __typename
          ... on RewardsUserPersonalContactInfoData {
            id
            email
          }
          ... on RewardsUserPersonalContactInfoSuppressed {
            id
          }
        }
        workEmail
      }
      claimedPrize
    }
  }
  organization: getMyRewardsOrganization {
    id
    pointsPerDollar
    timezone
    whiteLabelConfig {
      id
      rewardsProgramShortName
    }
  }
}
    `;

/**
 * __useDrawDetailsQuery__
 *
 * To run a query within a React component, call `useDrawDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDrawDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDrawDetailsQuery({
 *   variables: {
 *      drawId: // value for 'drawId'
 *   },
 * });
 */
export function useDrawDetailsQuery(baseOptions: Apollo.QueryHookOptions<DrawDetailsQuery, DrawDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DrawDetailsQuery, DrawDetailsQueryVariables>(DrawDetailsDocument, options);
      }
export function useDrawDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DrawDetailsQuery, DrawDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DrawDetailsQuery, DrawDetailsQueryVariables>(DrawDetailsDocument, options);
        }
export type DrawDetailsQueryHookResult = ReturnType<typeof useDrawDetailsQuery>;
export type DrawDetailsLazyQueryHookResult = ReturnType<typeof useDrawDetailsLazyQuery>;
export type DrawDetailsQueryResult = Apollo.QueryResult<DrawDetailsQuery, DrawDetailsQueryVariables>;