/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Controller, useForm } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import {
  DateField,
  formatDateOnly,
} from "@rewards-web/shared/components/date-field";
import { Form } from "@rewards-web/shared/components/form";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { TextField } from "@rewards-web/shared/components/text-field";
import { isEmail } from "@rewards-web/shared/lib/is-email";
import { isPhoneNumber } from "@rewards-web/shared/lib/is-phone-number";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { JobSearchField } from "../job-search-field";
import { ReferredByEmployeeSearchField } from "../referred-by-employee-search-field";
import { useAddCandidateMutation } from "./add-candidate.generated";

export interface AddCandidateModalProps {
  open: boolean;
  onClose(): void;
  onAdded(): void;
}

interface AddCandidateFormValues {
  firstName: string;
  lastName: string;
  preferredName: string;
  email: string;
  phoneNumber: string;
  referringEmployeeId: string | null;
  appliedToJobPostingId: string | null;
  dateAppliedToJob: string;
}

export function AddCandidateModal({
  open,
  onClose,
  onAdded,
}: AddCandidateModalProps): JSX.Element {
  const track = useTrack();
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<AddCandidateFormValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      preferredName: "",
      referringEmployeeId: null,
      appliedToJobPostingId: null,
      dateAppliedToJob: formatDateOnly(new Date()),
    },
  });

  const [addCandidate] = useAddCandidateMutation({
    onError: reportError,
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = async (values: AddCandidateFormValues) => {
    try {
      const response = await addCandidate({
        variables: {
          firstName: values.firstName,
          lastName: values.lastName,
          preferredName: values.preferredName || null,
          email: values.email || null,
          phoneNumber: values.phoneNumber,
          referredByUserId: values.referringEmployeeId!,
          appledToJobPostingId: values.appliedToJobPostingId!,
          appliedOnDate: values.dateAppliedToJob,
        },
      });

      onAdded();
      handleClose();
      track("Candidate added", {
        candidateId: response.data?.addCandidate.id,
      });
    } catch (error) {
      reportError(error);
      alert("An unexpected error occurred. Please try again later.");
    }
  };

  return (
    <Modal
      width="700px"
      open={open}
      onClose={handleClose}
      disableBackdropClick={isSubmitting}
    >
      <Form
        css={css`
          display: contents; // Modal child components must be direct DOM descendents of Modal
        `}
        onSubmit={handleSubmit(onSubmit)}
        submitting={isSubmitting}
      >
        <ModalTitle>Add Candidate</ModalTitle>
        <ModalContent>
          <div
            css={(theme: AppTheme) => css`
              display: grid;
              grid-template-columns: calc(50% - ${theme.spacing(1)}) calc(
                  50% - ${theme.spacing(1)}
                );
              grid-column-gap: ${theme.spacing(2)};
            `}
          >
            <TextField
              label="First name of candidate"
              error={errors.firstName}
              autoFocus
              disableAutocomplete
              {...register("firstName", { required: "First name is required" })}
            />
            <TextField
              label="Last name of candidate"
              error={errors.lastName}
              disableAutocomplete
              {...register("lastName", { required: "Last name is required" })}
            />
          </div>

          <TextField
            label="Preferred name of candidate (optional)"
            disableAutocomplete
            {...register("preferredName")}
          />

          <div
            css={(theme: AppTheme) => css`
              display: grid;
              grid-template-columns: calc(50% - ${theme.spacing(1)}) calc(
                  50% - ${theme.spacing(1)}
                );
              grid-column-gap: ${theme.spacing(2)};
            `}
          >
            <TextField
              label="Email (optional)"
              error={errors.email}
              disableAutocomplete
              type="email"
              {...register("email", {
                validate: (value: string) => {
                  if (value && !isEmail(value)) {
                    return "This is not a valid email";
                  }
                },
              })}
            />
            <TextField
              label="Cell phone number"
              error={errors.phoneNumber}
              disableAutocomplete
              type="tel"
              {...register("phoneNumber", {
                required: "Cell phone number is required",
                validate: (value: string) => {
                  if (value && !isPhoneNumber(value)) {
                    return "This is not a valid phone number";
                  }
                },
              })}
            />
          </div>

          <JobSearchField
            control={control}
            name="appliedToJobPostingId"
            label="Job applied to"
            rules={{
              required: "Job applied to is required",
            }}
          />

          <div
            css={(theme: AppTheme) => css`
              display: grid;
              grid-template-columns: calc(50% - ${theme.spacing(1)}) calc(
                  50% - ${theme.spacing(1)}
                );
              grid-column-gap: ${theme.spacing(2)};
            `}
          >
            <Controller
              control={control}
              name="dateAppliedToJob"
              rules={{
                required: "Date applied to job is required",
              }}
              render={({ field, fieldState }) => (
                <DateField
                  {...field}
                  error={fieldState.error}
                  label="Date applied to job"
                />
              )}
            />
            <ReferredByEmployeeSearchField
              control={control}
              name="referringEmployeeId"
              skip={!open}
            />
          </div>
        </ModalContent>
        <ModalActions>
          <Button variant="outlined" onClick={handleClose} label="Cancel" />
          <Button
            type="submit"
            color="primary"
            label="Add Candidate"
            loading={isSubmitting}
          />
        </ModalActions>
      </Form>
    </Modal>
  );
}
