/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { CardContent } from "@rewards-web/shared/components/card";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface PageCardContentProps {
  children: ReactNode;
  maxWidth?: string;
}

export function PageCardContent({
  children,
  maxWidth = "718px",
}: PageCardContentProps) {
  return (
    <CardContent
      css={(theme: AppTheme) => css`
        padding: ${theme.spacing(5)} ${theme.spacing(3)} ${theme.spacing(2)};
        max-width: ${maxWidth};
      `}
    >
      {children}
    </CardContent>
  );
}
