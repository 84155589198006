/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faEdit, faEye } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { IconButton } from "@rewards-web/shared/components/icon-button";
import { Table } from "@rewards-web/shared/components/table-components/table";
import { TableBody } from "@rewards-web/shared/components/table-components/table-body";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableHeaders } from "@rewards-web/shared/components/table-components/table-headers";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  AlayaCareIntegration,
  HhaxIntegration,
} from "@rewards-web/shared/graphql-types";
import { AppTheme } from "@rewards-web/shared/style/types";

import { Dot } from "../../../../../components/dot";
import usersIcon from "./users.png";
import { ViewHHAXAutoSyncFiltersModal } from "./view-filters-modal";

interface EmployeeAutoSyncIntegrationsTableProps {
  name: string;
  integrationList:
    | Omit<HhaxIntegration, "autoSyncEnabled">[]
    | AlayaCareIntegration[];
  disableSetUpButtons?: boolean;
  disableEdit?: boolean;
  onClickSetUp(
    integration: Omit<HhaxIntegration, "autoSyncEnabled"> | AlayaCareIntegration
  ): void;
  onClickEdit(
    integration: Omit<HhaxIntegration, "autoSyncEnabled"> | AlayaCareIntegration
  ): void;
  organizationLaunched: boolean;
}

export function EmployeeAutoSyncIntegrationsTable({
  name,
  integrationList,
  disableSetUpButtons,
  disableEdit,
  onClickSetUp,
  onClickEdit,
  organizationLaunched,
}: EmployeeAutoSyncIntegrationsTableProps): JSX.Element {
  const theme = useTheme();

  const [openModal, setOpenModal] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState<
    HhaxIntegration | AlayaCareIntegration | undefined
  >();

  const handleViewFilters = (
    integration: HhaxIntegration | AlayaCareIntegration
  ) => {
    // TODO:: (Upcoming ticket) https://cariboucare.atlassian.net/browse/CR-3947
    if (integration.__typename === "HHAXIntegration") {
      setSelectedIntegration(integration);
      setOpenModal(true);
    }
  };

  const closeViewFiltersModal = () => {
    setSelectedIntegration(undefined);
    setOpenModal(false);
  };

  const getSyncStatus = (
    integration: HhaxIntegration | AlayaCareIntegration
  ) => {
    if (integration.userAutoSyncs.length === 0) {
      return (
        <>
          <Dot color={theme.palette.warning.main} size={10} marginRight={10} />
          <Typography variant="body" color="grey.800">
            Not enabled
          </Typography>
        </>
      );
    }

    if (integration.userAutoSyncs.some((sync) => sync?.enabled)) {
      return (
        <>
          <Dot color={theme.palette.success.main} size={10} marginRight={10} />
          <Typography variant="body" color="grey.800">
            Enabled
          </Typography>
        </>
      );
    }

    return (
      <>
        <Dot color={theme.palette.grey[800]} size={10} marginRight={10} />
        <Typography variant="body" color="grey.800">
          Disabled
        </Typography>
      </>
    );
  };

  const displayIntegrationId = integrationList.length > 1;

  return (
    <>
      <Card
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(3)};
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-bottom: 15px;
          `}
        >
          <img
            src={usersIcon}
            alt="users"
            width={24}
            css={css`
              margin-right: 12px;
            `}
          />
          <Typography variant="h3" color="textPrimary" fontWeight={700}>
            {/* HHAeXchange Integrations */}
            {name}
          </Typography>
        </div>
        <Table>
          <TableHeaders>
            <TableRow>
              {displayIntegrationId && (
                <TableHeader>
                  <Typography variant="subtitle" color="textPrimary">
                    Integration ID
                  </Typography>
                </TableHeader>
              )}
              <TableHeader>
                <Typography variant="subtitle" color="textPrimary">
                  Sync
                </Typography>
              </TableHeader>
              <TableHeader align="right">
                <Typography variant="subtitle" color="textPrimary">
                  Action
                </Typography>
              </TableHeader>
            </TableRow>
          </TableHeaders>
          <TableBody>
            {integrationList.map((integration) => {
              const action = (() => {
                if (integration.userAutoSyncs.length > 0) {
                  // there is already an auto-sync configured
                  if (!organizationLaunched && !disableEdit) {
                    return (
                      <IconButton
                        onClick={() => {
                          onClickEdit(integration);
                        }}
                        aria-label="View details"
                      >
                        <FontAwesomeIcon icon={faEdit} size="xs" />
                      </IconButton>
                    );
                  } else {
                    // user can view the filters if they can't edit
                    return (
                      <IconButton
                        onClick={() =>
                          handleViewFilters(integration as HhaxIntegration)
                        }
                        aria-label="View details"
                      >
                        <FontAwesomeIcon icon={faEye} size="xs" />
                      </IconButton>
                    );
                  }
                } else {
                  // no auto-syncs for this integration - show set up button
                  return (
                    <Button
                      onClick={() => onClickSetUp(integration)}
                      color="primary"
                      label="Set up"
                      // disable set-up button after org is launched
                      disabled={disableSetUpButtons}
                      width="auto"
                      size="small"
                    />
                  );
                }
              })();

              return (
                <TableRow key={integration.id}>
                  {displayIntegrationId && (
                    <TableCell divider>
                      <Typography
                        variant="body"
                        color="grey.800"
                        minWidth={200}
                      >
                        {integration.id}
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell divider>
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                      `}
                    >
                      {getSyncStatus(integration as HhaxIntegration)}
                    </div>
                  </TableCell>
                  <TableCell divider align="right">
                    {action}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Card>

      <ViewHHAXAutoSyncFiltersModal
        open={openModal}
        onClose={closeViewFiltersModal}
        integration={selectedIntegration}
      />
    </>
  );
}
