import { useCallback, useContext, useEffect } from "react";

import { AnalyticsContext } from "../context";

const inBrowser = typeof window !== "undefined" ? true : false;

/**
 * Identifies the user id with analytics
 */
export function useSetAnalyticsIdentity() {
  const { disabled: analyticsDisabled } = useContext(AnalyticsContext);

  return useCallback(
    (userId: string) => {
      inBrowser && !analyticsDisabled && window.analytics?.identify(userId);
    },
    [analyticsDisabled]
  );
}

export function useSetScreenRecordingIdentity() {
  const { disabled: analyticsDisabled } = useContext(AnalyticsContext);

  return useCallback(
    (
      userId: string | null,
      attributes: Record<string, number | string | boolean> = {}
    ) => {
      if (inBrowser && !analyticsDisabled && (window as any).hj) {
        // identify with hotjar
        (window as any).hj("identify", userId, { ...attributes, userId });
      }
    },
    [analyticsDisabled]
  );
}

/**
 * Clears the identified user with analytics & screen
 */
export function useClearAnalyticsIdentity() {
  const { disabled: analyticsDisabled } = useContext(AnalyticsContext);

  return () => inBrowser && !analyticsDisabled && window.analytics?.reset();
}

/**
 * Tracks a page view with analytics.
 *
 * @param pageName The name of the page
 * @example useTrackPageview("Home")
 */
export function useTrackPageview(pageName: string) {
  const { disabled: analyticsDisabled } = useContext(AnalyticsContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      inBrowser && !analyticsDisabled && window.analytics?.page(pageName);
    }, 600);

    //  this will be called for clean-up
    return () => clearTimeout(timer);
  }, [pageName, analyticsDisabled]);
}

/**
 * Returns a function that can be used to track analytics
 */
export function useTrack() {
  const { disabled: analyticsDisabled } = useContext(AnalyticsContext);

  return useCallback(
    (event: string, properties?: Record<string, any>) => {
      inBrowser &&
        !analyticsDisabled &&
        window.analytics?.track(event, properties);
    },
    [analyticsDisabled]
  );
}

/**
 * Marks a specific event, which will be sent to the screen recording service (hotjar)
 */
export function useTrackScreenRecordingEvent() {
  const { disabled: analyticsDisabled } = useContext(AnalyticsContext);

  return useCallback(
    (eventName: string) => {
      if (inBrowser && !analyticsDisabled && (window as any).hj) {
        (window as any).hj("event", eventName);
      }
    },
    [analyticsDisabled]
  );
}
