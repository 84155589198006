/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Divider } from "@rewards-web/shared/components/divider";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

const CARIBOU_HELP_EMAIL = "help+admin@caribou.care";

interface ConfirmDuplicateModalProps {
  open: boolean;
  onClose(): void;
}

export function RequestFullAccessModal({
  open,
  onClose,
}: ConfirmDuplicateModalProps): JSX.Element {
  return (
    <Modal width="488px" open={open} onClose={onClose}>
      <ModalTitle>Request Access</ModalTitle>
      <ModalContent>
        <Typography
          variant="body"
          color="textPrimary"
          css={(theme: AppTheme) =>
            css`
              padding-bottom: ${theme.spacing(1)};
            `
          }
        >
          Your account has View-Only access for this page.
        </Typography>
        <Typography
          variant="body"
          color="textPrimary"
          css={(theme: AppTheme) =>
            css`
              padding-bottom: ${theme.spacing(2)};
            `
          }
        >
          Talk to your agency lead if you would like{" "}
          <strong>edit access</strong> for this page.
        </Typography>
        <Divider />

        <Typography
          variant="body"
          color="textPrimary"
          css={(theme: AppTheme) =>
            css`
              padding-top: ${theme.spacing(2)};
              padding-bottom: ${theme.spacing(2)};
            `
          }
        >
          Email{" "}
          <a
            href={`mailto:${CARIBOU_HELP_EMAIL}?Subject=${encodeURI(
              "Request for edit access to admin dashboard"
            )}`}
            css={css`
              font-weight: bold;
            `}
          >
            {CARIBOU_HELP_EMAIL}
          </a>{" "}
          for further help.
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button size="large" label="Okay" color="primary" onClick={onClose} />
      </ModalActions>
    </Modal>
  );
}
