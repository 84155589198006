/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
} from "@material-ui/core";
import { forwardRef, ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";
import { ControlledFormFieldProps } from "@rewards-web/shared/types";

import { useFormControlContext } from "../../form/form-control";

interface RadioGroupProps extends ControlledFormFieldProps {
  children?: ReactNode;
  row?: boolean;
  ariaLabel: string;
  label?: string;
}

export const RadioGroup = forwardRef(
  (
    {
      children,
      label,
      row,
      ariaLabel,
      onChange,
      onBlur,
      name,
      value,
      error,
    }: RadioGroupProps,
    ref: any
  ): JSX.Element => {
    const { submitting, readOnly } = useFormControlContext();

    return (
      <FormControl
        component="fieldset"
        error={!!error}
        aria-invalid={!!error}
        disabled={submitting || readOnly}
      >
        {label && (
          <FormLabel
            css={(theme: AppTheme) => css`
              color: ${theme.palette.text.primary};
              padding-bottom: ${theme.spacing(1.5)};
              line-height: 1.3em;
            `}
            component="legend"
          >
            {label}
          </FormLabel>
        )}
        <MuiRadioGroup
          css={
            error
              ? (theme: AppTheme) => css`
                  label,
                  svg {
                    color: ${theme.palette.error.main};
                  }
                `
              : undefined
          }
          ref={ref}
          name={name}
          onBlur={onBlur}
          value={value}
          onChange={(_, value) => {
            onChange?.(value);
          }}
          aria-label={ariaLabel}
          row={row}
        >
          {children}
        </MuiRadioGroup>
      </FormControl>
    );
  }
);
