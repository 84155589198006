import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type HasManagedEmployeesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HasManagedEmployeesQuery = (
  { __typename?: 'Query' }
  & { listMyManagedEmployeesRecognitionSummaryV2: (
    { __typename?: 'ListMyManagedEmployeesRecognitionSummaryV2Response' }
    & Pick<Types.ListMyManagedEmployeesRecognitionSummaryV2Response, 'total'>
  ) }
);


export const HasManagedEmployeesDocument = gql`
    query HasManagedEmployees {
  listMyManagedEmployeesRecognitionSummaryV2(page: {limit: 0, offset: 0}) {
    total
  }
}
    `;

/**
 * __useHasManagedEmployeesQuery__
 *
 * To run a query within a React component, call `useHasManagedEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasManagedEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasManagedEmployeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasManagedEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<HasManagedEmployeesQuery, HasManagedEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasManagedEmployeesQuery, HasManagedEmployeesQueryVariables>(HasManagedEmployeesDocument, options);
      }
export function useHasManagedEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasManagedEmployeesQuery, HasManagedEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasManagedEmployeesQuery, HasManagedEmployeesQueryVariables>(HasManagedEmployeesDocument, options);
        }
export type HasManagedEmployeesQueryHookResult = ReturnType<typeof useHasManagedEmployeesQuery>;
export type HasManagedEmployeesLazyQueryHookResult = ReturnType<typeof useHasManagedEmployeesLazyQuery>;
export type HasManagedEmployeesQueryResult = Apollo.QueryResult<HasManagedEmployeesQuery, HasManagedEmployeesQueryVariables>;