import { groupBy, isEqual, keyBy, partition, sortBy, uniqBy } from "lodash";

import {
  JobPosting,
  Maybe,
  RewardsOrganizationBranch,
} from "@rewards-web/shared/graphql-types";

export type SerializedCandidateJobFilter = {
  branchIds: string[];
  jobPostingIds: string[];
};

const OTHER_JOBS_KEY = "other-jobs";

/**
 * Top-level filter values, which enable the child filter values.
 *
 * No filter is applied if none of its children are selected.
 */
type CandidateJobFilterBranchValue = `branch-${string}`;

/**
 * Bottom-level filter values.
 * These are the only ones that actually affect the filter.
 */
type CandidateJobFilterJobValue = `job-${string}`;

export type CandidateJobFilterValue =
  | CandidateJobFilterBranchValue
  | CandidateJobFilterJobValue;

type Job = Pick<
  JobPosting,
  "id" | "title" | "geography" | "closedForSubmission"
> & {
  branch?: Maybe<
    { __typename?: "RewardsOrganizationBranch" } & Pick<
      RewardsOrganizationBranch,
      "id" | "name"
    >
  >;
};

interface CandidateJobFilterCheckboxOption {
  label: string;
  value: CandidateJobFilterBranchValue;
  children: Array<CandidateJobFilterCheckboxChildOption>;
}

interface CandidateJobFilterCheckboxChildOption {
  label: string;
  subLabel: string;
  value: CandidateJobFilterJobValue;
}

export function getCandidateJobFilterCheckboxOptions(
  jobs: Job[]
):
  | CandidateJobFilterCheckboxOption[]
  | CandidateJobFilterCheckboxChildOption[] {
  const someJobsHaveBranches = jobs.some((job) => !!job.branch);

  if (someJobsHaveBranches) {
    const allBranchesByName = sortBy(
      uniqBy(
        jobs.map((job) => job.branch ?? null),
        (branch) => branch?.id
      ),
      (branch) => branch?.name
    );
    const jobsByBranch = groupBy(jobs, (job) => job.branch?.id ?? null);

    return allBranchesByName.map(
      (branch): CandidateJobFilterCheckboxOption => {
        const jobsForBranch =
          jobsByBranch[(branch?.id ?? null) as keyof typeof jobsByBranch];
        const openJobs = jobsForBranch.filter(
          (job) => !job.closedForSubmission
        );
        const closedJobs = jobsForBranch.filter(
          (job) => job.closedForSubmission
        );

        return {
          label: branch?.name ?? "Other Jobs",
          value: `branch-${branch?.id ?? OTHER_JOBS_KEY}`,
          children: [
            ...openJobs.map(jobToOption),
            ...closedJobs.map(jobToOption),
          ],
        };
      }
    );
  }

  const openJobs = jobs.filter((job) => !job.closedForSubmission);
  const closedJobs = jobs.filter((job) => job.closedForSubmission);

  return [...openJobs.map(jobToOption), ...closedJobs.map(jobToOption)];
}

function jobToOption(job: Job): CandidateJobFilterCheckboxChildOption {
  return {
    value: `job-${job.id}`,
    label: `${job.title}${job.closedForSubmission ? " (Closed)" : ""}`,
    subLabel: job.geography,
  };
}

/**
 * Deserializes the GraphQL Input candidate filter into the format needed
 * for the localized filter state.
 *
 * If previous filters are passed in, it will take them into account
 * so the local state is partially preserved.
 */
export function deserializeCandidateJobFilter(
  allJobs: Job[],
  value: SerializedCandidateJobFilter,
  previousFilters = new Set<CandidateJobFilterValue>()
): Set<CandidateJobFilterValue> {
  if (
    previousFilters &&
    isEqual(value, serializeCandidateJobFilter(previousFilters, allJobs))
  ) {
    // if it's functionally equivalent, keep the filter currently in state.
    // that way, we can keep the checkboxes the user previously had if
    // they re-enable the section.
    return previousFilters;
  }

  const noJobsHaveBranches = allJobs.every((job) => !job.branch);

  const jobsByBranchId = groupBy(
    allJobs,
    (job) => job.branch?.id ?? OTHER_JOBS_KEY
  );
  const jobsById = keyBy(allJobs, (job) => job.id);

  const checkedBranches = value.branchIds.map<CandidateJobFilterBranchValue>(
    (branchId) => `branch-${branchId}`
  );

  const branchesOfIndividuallyCheckedJobs = value.jobPostingIds.map<CandidateJobFilterBranchValue>(
    (jobPostingId) =>
      `branch-${jobsById[jobPostingId]?.branch?.id ?? OTHER_JOBS_KEY}`
  );

  const allJobsFromCheckedBranches = value.branchIds.flatMap<CandidateJobFilterJobValue>(
    (branchId): CandidateJobFilterJobValue[] =>
      (jobsByBranchId[branchId] ?? []).map(
        (job): CandidateJobFilterJobValue => `job-${job.id}`
      )
  );

  const individuallyCheckedJobs = value.jobPostingIds.map(
    (id): CandidateJobFilterJobValue => `job-${id}`
  );

  return new Set([
    ...(noJobsHaveBranches ? [`branch-${OTHER_JOBS_KEY}` as const] : []),
    ...checkedBranches,
    ...branchesOfIndividuallyCheckedJobs,
    ...allJobsFromCheckedBranches,
    ...individuallyCheckedJobs,
  ]);
}

/**
 * Serializes the local candidate job filter into the format needed
 * by the GraphQL input candidate job filter
 *
 * If all jobs selected for a given branch, just set the branch id
 * If not all jobs selected for a given branch, select each job individually
 */
export function serializeCandidateJobFilter(
  filterValue: Set<CandidateJobFilterValue>,
  allJobs: Job[]
): SerializedCandidateJobFilter {
  const someJobsHaveBranches = allJobs.some((job) => !!job.branch);

  const selectedJobIds = new Set(
    Array.from(filterValue)
      .filter((value) => value.startsWith("job-"))
      .map((value) => value.substring(4))
  );

  if (someJobsHaveBranches) {
    const selectedBranchIds = new Set(
      Array.from(filterValue)
        .filter((value) => value.startsWith("branch-"))
        .map((value) => value.substring(7))
    );

    const jobsByBranchId = groupBy(
      allJobs,
      (job) => job.branch?.id ?? OTHER_JOBS_KEY
    );
    const [
      selectedBranchIdsWithAllJobsSelected,
      selectedBranchIdsWithNotAllJobsSelected,
    ] = partition(
      Array.from(selectedBranchIds),
      (branchId) =>
        branchId !== OTHER_JOBS_KEY &&
        (jobsByBranchId[branchId] ?? []).every((job) =>
          selectedJobIds.has(job.id)
        )
    );

    return {
      branchIds: selectedBranchIdsWithAllJobsSelected,
      jobPostingIds: selectedBranchIdsWithNotAllJobsSelected.reduce<string[]>(
        (prev, branchId) => {
          const jobsForBranch = jobsByBranchId[branchId] ?? [];

          prev.push(
            ...jobsForBranch
              .filter((job) => selectedJobIds.has(job.id))
              .map((job) => job.id)
          );

          return prev;
        },
        []
      ),
    };
  }

  return {
    branchIds: [],
    jobPostingIds: Array.from(selectedJobIds),
  };
}
