/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { ReactNode, useState } from "react";

import { ReactionType } from "@rewards-web/shared/graphql-types";

import { Typography } from "../../typography";

export interface SocialPostReactionProps {
  active: boolean;
  count: number;
  type: ReactionType;
  onClick?: (type: ReactionType, added: boolean) => void;
}

const reactionIcons: Record<ReactionType, ReactNode> = {
  [ReactionType.Applaud]: <span>👏</span>,
  [ReactionType.Celebrate]: <span>🎉</span>,
  [ReactionType.Love]: <span>❤️</span>,
};

export function SocialPostReaction(props: SocialPostReactionProps) {
  const theme = useTheme();
  const [count, setCount] = useState(props.count);
  const [active, setActive] = useState(props.active);

  const handleClick = () => {
    setCount((prevCount) => (active ? prevCount - 1 : prevCount + 1));
    setActive((prevActive) => !prevActive);
    props.onClick?.(props.type, active);
  };

  return (
    <div
      key={props.type}
      css={css`
        display: flex;
        align-items: center;
        cursor: pointer;
        background-color: ${active ? theme.palette.grey[200] : undefined};
        border-radius: ${theme.spacing(0.5)};
        padding: ${theme.spacing(1)};
        user-select: none;
      `}
      onClick={handleClick}
      aria-label={props.type}
    >
      <Typography
        css={css`
          font-size: ${theme.typography.h5.fontSize};
          padding-right: ${theme.spacing(1)};
          user-select: none;
        `}
        variant="footnote"
      >
        {reactionIcons[props.type]}
      </Typography>

      <Typography
        css={css`
          user-select: none;
        `}
        variant="footnote"
        color="grey.800"
      >
        {count}
      </Typography>
    </div>
  );
}
