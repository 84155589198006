import * as Types from '@rewards-web/shared/graphql-types';

import { JobPostingEditDetailsFragment } from '../../../graphql/job-posting-fragment.generated';
import { gql } from '@apollo/client';
import { JobPostingEditDetailsFragmentDoc } from '../../../graphql/job-posting-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type JobPostingToEditQueryVariables = Types.Exact<{
  jobPostingId: Types.Scalars['ID'];
}>;


export type JobPostingToEditQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'defaultCandidateAlertEmail'>
  ), listUniqueCandidateAlertEmails: (
    { __typename?: 'ListUniqueCandidateAlertEmailsResponse' }
    & Pick<Types.ListUniqueCandidateAlertEmailsResponse, 'emails'>
  ), getJobPostingById?: Types.Maybe<(
    { __typename?: 'JobPosting' }
    & JobPostingEditDetailsFragment
  )> }
);


export const JobPostingToEditDocument = gql`
    query JobPostingToEdit($jobPostingId: ID!) {
  getMyRewardsOrganization {
    id
    defaultCandidateAlertEmail
  }
  listUniqueCandidateAlertEmails {
    emails
  }
  getJobPostingById(jobPostingId: $jobPostingId) {
    ...JobPostingEditDetails
  }
}
    ${JobPostingEditDetailsFragmentDoc}`;

/**
 * __useJobPostingToEditQuery__
 *
 * To run a query within a React component, call `useJobPostingToEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPostingToEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPostingToEditQuery({
 *   variables: {
 *      jobPostingId: // value for 'jobPostingId'
 *   },
 * });
 */
export function useJobPostingToEditQuery(baseOptions: Apollo.QueryHookOptions<JobPostingToEditQuery, JobPostingToEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobPostingToEditQuery, JobPostingToEditQueryVariables>(JobPostingToEditDocument, options);
      }
export function useJobPostingToEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobPostingToEditQuery, JobPostingToEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobPostingToEditQuery, JobPostingToEditQueryVariables>(JobPostingToEditDocument, options);
        }
export type JobPostingToEditQueryHookResult = ReturnType<typeof useJobPostingToEditQuery>;
export type JobPostingToEditLazyQueryHookResult = ReturnType<typeof useJobPostingToEditLazyQuery>;
export type JobPostingToEditQueryResult = Apollo.QueryResult<JobPostingToEditQuery, JobPostingToEditQueryVariables>;