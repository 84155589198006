import { sortBy } from "lodash";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

import { SelectField } from "@rewards-web/shared/components/select-field";

import { useMyBranches } from "../../../branches/use-my-branches";

interface EmployeeBranchFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export function EmployeeBranchField<T extends FieldValues>({
  control,
  name,
}: EmployeeBranchFieldProps<T>) {
  const branchesQuery = useMyBranches();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <SelectField
          {...field}
          width="full"
          label="Branch"
          options={sortBy(
            (branchesQuery.data?.getMyRewardsOrganization.branches ?? []).map(
              (branch) => ({
                value: branch.id,
                label: branch.name,
              })
            ),
            (branch) => branch.label
          )}
        />
      )}
    />
  );
}
