/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faGlobe } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { formatInTimeZone, utcToZonedTime } from "date-fns-tz";
import { ReactNode } from "react";

import { Card } from "@rewards-web/shared/components/card";
import { Table } from "@rewards-web/shared/components/table-components/table";
import { TableBody } from "@rewards-web/shared/components/table-components/table-body";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableHeaders } from "@rewards-web/shared/components/table-components/table-headers";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/types";

import { DrawListDataQuery } from "../draw-list-data.generated";
import starImageUrl from "./star.png";

export interface DrawListTableProps {
  dotColor: string;
  title: string;
  draws: DrawListDataQuery["getAllDraws"];
  timezone: string;
  getActionNode?: (
    drawId: DrawListDataQuery["getAllDraws"][number]
  ) => ReactNode;
}

type DrawPrizeStructureSinglePrizeMultipleWinnersType = Extract<
  NonNullable<DrawListDataQuery["getAllDraws"][number]>["prizeStructure"],
  { __typename: "DrawPrizeStructureSinglePrizeMultipleWinners" }
>;

export function DrawListTable({
  dotColor,
  title,
  draws,
  timezone,
  getActionNode,
}: DrawListTableProps) {
  const adminAppScheduleDrawV2Enabled = useFeatureFlag(
    "admin-app-tiered-prize-structure"
  );

  /* eventually we only want to show the totalPrizePointValue, however before rolling out this new UI
  we want to keep the original prize per person value
  and it all the current draws only have prize, which is for single prize draws 
  */
  const getTtotalPrizePointValue = (
    prizeStructure: DrawPrizeStructureSinglePrizeMultipleWinnersType
  ) => {
    if (adminAppScheduleDrawV2Enabled) {
      if (prizeStructure.totalPrizePointValue) {
        return numberWithCommas(prizeStructure.totalPrizePointValue);
      } else if (prizeStructure.prize.__typename === "DrawPrizePoints") {
        return numberWithCommas(prizeStructure.prize.pointValue);
      }
    } else if (prizeStructure.prize.__typename === "DrawPrizePoints") {
      return numberWithCommas(prizeStructure.prize.pointValue);
    }
  };
  return (
    <div
      css={(theme: AppTheme) => css`
        &:not(:last-of-type) {
          margin-bottom: ${theme.spacing(3)};
        }
      `}
    >
      <Typography
        variant="h3"
        component="h2"
        color="textPrimary"
        fontWeight={600}
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            content: "";
            background-color: ${dotColor};
            width: 13px;
            height: 13px;
            border-radius: 1000px;
            display: inline-block;
            margin-right: ${theme.spacing(1)};
          `}
        />
        {title}
      </Typography>
      <Card
        variant="outlined"
        css={(theme: AppTheme) => css`
          width: 100%;
          border-width: 0px;
          padding: 0 ${theme.spacing(3)};
        `}
      >
        <Table
          css={css`
            margin-bottom: 0px;
            width: 100%;
          `}
        >
          <TableHeaders>
            <TableRow>
              <TableHeader>
                <Typography variant="subtitle" color="textPrimary">
                  Name
                </Typography>
              </TableHeader>
              <TableHeader>
                <Typography variant="subtitle" color="textPrimary">
                  Date -{" "}
                  <Tooltip
                    title={`Draw dates are displayed in your agency's time zone (${timezone}).`}
                  >
                    <FontAwesomeIcon icon={faGlobe} size="xs" />{" "}
                    {formatInTimeZone(new Date(), timezone, "zzz")}
                  </Tooltip>
                </Typography>
              </TableHeader>
              <TableHeader>
                <Typography variant="subtitle" color="textPrimary">
                  {adminAppScheduleDrawV2Enabled
                    ? "Total Budget (up to)"
                    : "Prize"}
                </Typography>
              </TableHeader>
              <TableHeader>
                <Typography variant="subtitle" color="textPrimary">
                  # of Winners
                </Typography>
              </TableHeader>
              {getActionNode && (
                <TableHeader>
                  <Typography variant="subtitle" color="textPrimary">
                    Action
                  </Typography>
                </TableHeader>
              )}
            </TableRow>
          </TableHeaders>

          <TableBody
            css={css`
              position: relative;
            `}
          >
            {draws.map((draw, index) => {
              const prize = (() => {
                if (
                  draw.prizeStructure.__typename ===
                  "DrawPrizeStructureSinglePrizeMultipleWinners"
                ) {
                  return (
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                      `}
                    >
                      <img
                        src={starImageUrl}
                        alt="Star"
                        css={css`
                          margin-bottom: 3px;
                          margin-right: 4px;
                        `}
                      />{" "}
                      {getTtotalPrizePointValue(draw.prizeStructure)} points
                    </div>
                  );
                } else if (
                  draw.prizeStructure.__typename ===
                  "DrawPrizeStructureTieredPrizesMultipleWinners"
                ) {
                  return (
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                      `}
                    >
                      <img
                        src={starImageUrl}
                        alt="Star"
                        css={css`
                          margin-bottom: 3px;
                          margin-right: 4px;
                        `}
                      />{" "}
                      {numberWithCommas(
                        draw.prizeStructure.totalPrizePointValue
                      )}{" "}
                      points
                    </div>
                  );
                }

                return "Unknown";
              })();

              const numWinners = (() => {
                if (
                  draw.prizeStructure.__typename ===
                    "DrawPrizeStructureSinglePrizeMultipleWinners" ||
                  draw.prizeStructure.__typename ===
                    "DrawPrizeStructureTieredPrizesMultipleWinners"
                ) {
                  return numberWithCommas(draw.prizeStructure.numWinners);
                }

                return "Unknown";
              })();

              return (
                <TableRow key={draw.id}>
                  <TableCell divider={index !== draws.length - 1}>
                    <Typography variant="body" color="grey.800">
                      {draw.name}
                    </Typography>
                  </TableCell>
                  <TableCell divider={index !== draws.length - 1}>
                    <Typography variant="body" color="grey.800">
                      {format(
                        utcToZonedTime(draw.scheduledStartDate, timezone),
                        "MMM d, yyyy, h:mm a"
                      )}{" "}
                      -{" "}
                      {format(
                        utcToZonedTime(draw.scheduledEndDate, timezone),
                        "MMM d, yyyy, h:mm a"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell divider={index !== draws.length - 1}>
                    <Typography variant="body" color="grey.800">
                      {prize}
                    </Typography>
                  </TableCell>
                  <TableCell divider={index !== draws.length - 1}>
                    <Typography variant="body" color="grey.800">
                      {numWinners}
                    </Typography>
                  </TableCell>
                  {getActionNode && (
                    <TableCell divider={index !== draws.length - 1}>
                      {getActionNode(draw)}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
}
