import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GroupsListQueryVariables = Types.Exact<{
  offset: Types.Scalars['Int'];
  limit: Types.Scalars['Int'];
}>;


export type GroupsListQuery = (
  { __typename?: 'Query' }
  & { listRewardsGroups: (
    { __typename?: 'ListRewardsGroupsResponse' }
    & Pick<Types.ListRewardsGroupsResponse, 'total'>
    & { items: Array<(
      { __typename?: 'RewardsGroup' }
      & Pick<Types.RewardsGroup, 'id' | 'name'>
      & { tagsConfig: (
        { __typename?: 'RewardsGroupTagsConfigSchemaV1' }
        & Pick<Types.RewardsGroupTagsConfigSchemaV1, 'schema'>
        & { or: Array<(
          { __typename?: 'RewardsGroupTagsConfigSchemaV1AndSchema' }
          & { and: Array<(
            { __typename?: 'RewardsTag' }
            & Pick<Types.RewardsTag, 'externalId'>
          )> }
        )> }
      ) }
    )> }
  ) }
);


export const GroupsListDocument = gql`
    query GroupsList($offset: Int!, $limit: Int!) {
  listRewardsGroups(offset: $offset, limit: $limit) {
    total
    items {
      id
      name
      tagsConfig {
        ... on RewardsGroupTagsConfigSchemaV1 {
          schema
          or {
            and {
              externalId
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGroupsListQuery__
 *
 * To run a query within a React component, call `useGroupsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsListQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGroupsListQuery(baseOptions: Apollo.QueryHookOptions<GroupsListQuery, GroupsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupsListQuery, GroupsListQueryVariables>(GroupsListDocument, options);
      }
export function useGroupsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsListQuery, GroupsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupsListQuery, GroupsListQueryVariables>(GroupsListDocument, options);
        }
export type GroupsListQueryHookResult = ReturnType<typeof useGroupsListQuery>;
export type GroupsListLazyQueryHookResult = ReturnType<typeof useGroupsListLazyQuery>;
export type GroupsListQueryResult = Apollo.QueryResult<GroupsListQuery, GroupsListQueryVariables>;