/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useForm } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { TextButton } from "@rewards-web/shared/components/text-button";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { formatPhoneNumber } from "@rewards-web/shared/lib/phone-number-format";
import { AppTheme } from "@rewards-web/shared/style/types";

import { MfaPageContainer } from "../mfa-page-container";

const CONTACT_EMAIL = "help+admin@caribou.care";

interface EnableSmsMfaFormValues {
  code: string;
}

export interface SubmitMfaVerificationCodePageProps {
  phoneNumber: string;
  onSubmit(values: EnableSmsMfaFormValues): Promise<void>;
  cancelButtonLabel: string;
  onCancel(): void;
  onResendCode(): void;
}

export function SubmitMfaVerificationCodePage({
  phoneNumber,
  onSubmit,
  onCancel,
  onResendCode,
  cancelButtonLabel,
}: SubmitMfaVerificationCodePageProps) {
  const form = useForm<EnableSmsMfaFormValues>({
    defaultValues: {
      code: "",
    },
  });

  return (
    <MfaPageContainer>
      <Typography
        variant="body"
        css={(theme: AppTheme) => css`
          color: black;
          font-weight: 500;
          margin-bottom: ${theme.spacing(4)};
        `}
      >
        We've sent a verification code to your designated phone number{" "}
        {formatPhoneNumber(phoneNumber)}
      </Typography>

      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <TextField
          label="Verification Code"
          hideLabel
          placeholder="Verification Code"
          autoFocus
          error={form.formState.errors.code}
          {...form.register("code", {
            required: "You must enter your verification code.",
          })}
        />

        <Button
          loading={form.formState.isSubmitting}
          color="primary"
          label="Submit"
          type="submit"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(1)};
          `}
        />

        <Button
          onClick={onCancel}
          disabled={form.formState.isSubmitting}
          label={cancelButtonLabel}
        />
      </Form>

      <Typography
        variant="body"
        css={(theme: AppTheme) => css`
          color: black;
          font-weight: 500;
          margin-top: ${theme.spacing(4)};
        `}
      >
        Didn't receive the code?{" "}
        <TextButton onClick={onResendCode}>Re-send code</TextButton>
      </Typography>

      <Typography
        variant="body"
        css={(theme: AppTheme) => css`
          color: black;
          font-weight: 500;
          margin-top: ${theme.spacing(2)};
        `}
      >
        Need help? Email us at{" "}
        <a href={`mailto:${encodeURI(CONTACT_EMAIL)}`}>{CONTACT_EMAIL}</a>.
      </Typography>
    </MfaPageContainer>
  );
}
