import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ApproveDrawWinnersMutationVariables = Types.Exact<{
  drawId: Types.Scalars['ID'];
  winnerIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  prizeTierIndexes?: Types.Maybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
}>;


export type ApproveDrawWinnersMutation = (
  { __typename?: 'Mutation' }
  & { approveDrawWinners: (
    { __typename?: 'Draw' }
    & Pick<Types.Draw, 'id'>
  ) }
);


export const ApproveDrawWinnersDocument = gql`
    mutation ApproveDrawWinners($drawId: ID!, $winnerIds: [ID!]!, $prizeTierIndexes: [Int!]) {
  approveDrawWinners(
    drawId: $drawId
    winnerIds: $winnerIds
    prizeTierIndexes: $prizeTierIndexes
  ) {
    id
  }
}
    `;
export type ApproveDrawWinnersMutationFn = Apollo.MutationFunction<ApproveDrawWinnersMutation, ApproveDrawWinnersMutationVariables>;

/**
 * __useApproveDrawWinnersMutation__
 *
 * To run a mutation, you first call `useApproveDrawWinnersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveDrawWinnersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveDrawWinnersMutation, { data, loading, error }] = useApproveDrawWinnersMutation({
 *   variables: {
 *      drawId: // value for 'drawId'
 *      winnerIds: // value for 'winnerIds'
 *      prizeTierIndexes: // value for 'prizeTierIndexes'
 *   },
 * });
 */
export function useApproveDrawWinnersMutation(baseOptions?: Apollo.MutationHookOptions<ApproveDrawWinnersMutation, ApproveDrawWinnersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveDrawWinnersMutation, ApproveDrawWinnersMutationVariables>(ApproveDrawWinnersDocument, options);
      }
export type ApproveDrawWinnersMutationHookResult = ReturnType<typeof useApproveDrawWinnersMutation>;
export type ApproveDrawWinnersMutationResult = Apollo.MutationResult<ApproveDrawWinnersMutation>;
export type ApproveDrawWinnersMutationOptions = Apollo.BaseMutationOptions<ApproveDrawWinnersMutation, ApproveDrawWinnersMutationVariables>;