/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { times } from "lodash";

import { Card } from "@rewards-web/shared/components/card";
import { ProgressBar } from "@rewards-web/shared/components/progress-bar";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

export interface ScoreComparisonCardProps {
  previousScores: { averageScore: number; label: string; year: string }[];
  maxScore: 5 | 10;
}

export function ScoreComparisonCard({
  previousScores,
  maxScore,
}: ScoreComparisonCardProps) {
  const theme = useTheme();

  // stretch progress bar to fit the height of the card
  const progressBarHeight = maxScore === 10 ? "350px" : "144px";

  return (
    <Card
      css={(theme: AppTheme) =>
        css`
          padding: ${theme.spacing(3)};
          box-sizing: border-box;
          // ensure 2 cards per row, at the most
          width: calc((100% - ${theme.spacing(3)}) / 2);
          min-width: 450px;
        `
      }
    >
      <Typography variant="h3" fontWeight={700}>
        Quarterly Scores
      </Typography>
      <div
        css={css`
          display: flex;
          align-items: center;
          height: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            text-align: center;
            flex: 1;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: ${progressBarHeight};
              align-self: flex-start;
            `}
          >
            {times(maxScore, (index) => {
              const score = maxScore - index;
              return (
                <Typography key={index} variant="footnote" color="textPrimary">
                  {score} pt{score > 1 && "s"}
                </Typography>
              );
            })}
          </div>
          <div
            css={css`
              flex: 1;
              display: flex;
              justify-content: space-around;
            `}
          >
            {previousScores.map((score, i) => {
              return (
                <div key={i}>
                  <div
                    css={(theme: AppTheme) => css`
                      height: ${progressBarHeight};
                      padding: ${theme.spacing(0.5)};
                    `}
                  >
                    <ProgressBar
                      value={(score.averageScore / maxScore) * 100}
                      orientation="vertical"
                      greyBackground={true}
                      barWidth={32}
                      borderRadius={100}
                    />
                  </div>
                  <Typography
                    variant="footnote"
                    color={theme.palette.grey[800]}
                    css={(theme: AppTheme) =>
                      css`
                        margin-top: ${theme.spacing(1)};
                      `
                    }
                  >
                    {score.label}/{score.year.slice(-2)}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Card>
  );
}
