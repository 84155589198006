/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { alpha } from "@material-ui/core";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";

import {
  SelectCheckboxesField,
  SelectCheckboxesFieldOption,
} from "@rewards-web/shared/components/select-checkboxes-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

const noClaimOptions = {
  label: "All",
  value: "ALL",
};

interface ClaimFilterProps {
  value: (string | null)[];
  onChange(claimed: string[]): void;
  onOpen?(): void;
  width?: number;
}

export function ClaimFilter({
  value,
  onChange,
  onOpen,
  width,
}: ClaimFilterProps) {
  const claimStatusOptions: SelectCheckboxesFieldOption[] = [
    {
      label: "Claimed Points",
      value: "CLAIMED",
    },
    {
      label: "Unclaimed Points",
      value: "UNCLAIMED",
    },
  ];

  const selectedClaimStatus = value.map(
    (selectedClaim) => selectedClaim ?? noClaimOptions.value
  );

  return (
    <SelectCheckboxesField
      css={css`
        max-width: ${width ?? 200}px;
      `}
      label="Claim Status"
      options={claimStatusOptions}
      onChange={(selectedOptions: string[]) => {
        const options: string[] = [];
        for (const option of selectedOptions) {
          if (option !== noClaimOptions.value) {
            options.push(option);
          }
        }
        onChange(options);
      }}
      onOpen={onOpen}
      id="claim-status"
      value={selectedClaimStatus || []}
      leftIcon={<FilterListOutlinedIcon fontSize="small" />}
      rightAdornment={
        <Typography
          css={(theme: AppTheme) => css`
            font-weight: 800;
            padding-left: ${theme.spacing(1.5)};
            padding-bottom: 2px;
            height: 14px;
            display: flex;
            align-items: center;
            ${selectedClaimStatus.length > 0 &&
            css`
              border-left: 1px solid ${alpha("#444444", 0.2)};
            `}
          `}
          color={selectedClaimStatus.length > 0 ? "primary" : "textPrimary"}
          variant="body"
          align="right"
        >
          {selectedClaimStatus.length || claimStatusOptions?.length}
        </Typography>
      }
    />
  );
}
