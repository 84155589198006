import { SVGProps } from "react";

function TotalApplicationsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={28}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.769 9.915h.561c2.415-.003 4.371-1.902 4.375-4.246V4.246C12.701 1.902 10.745.003 8.33 0H7.77C5.353.003 3.396 1.902 3.394 4.246V5.67 5.67c.002 2.344 1.959 4.243 4.375 4.246Zm-2.625-5.67v.001C5.144 2.84 6.319 1.7 7.769 1.7h.561c1.448.001 2.623 1.14 2.625 2.547V5.67 5.67c-.002 1.406-1.177 2.546-2.625 2.548H7.77c-1.45-.002-2.624-1.142-2.625-2.548V4.245ZM16.097 16.77v2.373c0 .468-.392.849-.875.849a.862.862 0 0 1-.875-.85v-2.37c-.002-1.91-1.596-3.459-3.565-3.461H5.316c-1.969.002-3.563 1.55-3.566 3.46v2.372c0 .468-.392.849-.875.849a.863.863 0 0 1-.875-.85v-2.37a5.088 5.088 0 0 1 1.559-3.647 5.403 5.403 0 0 1 3.756-1.513h5.466c1.41.002 2.76.546 3.757 1.513a5.088 5.088 0 0 1 1.559 3.646Zm3.574-6.856.56.001c2.416-.003 4.373-1.902 4.376-4.246V4.246C24.604 1.902 22.647.003 20.232 0h-.562c-2.415.003-4.371 1.902-4.375 4.246V5.67 5.67c.004 2.344 1.96 4.243 4.375 4.246l.001-.001Zm-2.625-5.67v.002C17.048 2.84 18.222 1.7 19.671 1.7h.56c1.45.001 2.624 1.14 2.626 2.547V5.67 5.67c-.002 1.406-1.176 2.546-2.625 2.548h-.562c-1.448-.002-2.622-1.142-2.625-2.548l.001-1.425ZM28 16.773v2.378c0 .47-.392.849-.875.849a.862.862 0 0 1-.875-.85v-2.377c-.003-1.912-1.6-3.462-3.57-3.465h-5.32a.862.862 0 0 1-.874-.849c0-.468.39-.85.875-.85h5.32a5.413 5.413 0 0 1 3.76 1.515A5.097 5.097 0 0 1 28 16.773Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}

export default TotalApplicationsIcon;
