import { ClassNames } from "@emotion/react";
import { Step, StepContent, StepLabel, Stepper } from "@material-ui/core";
import { ReactNode } from "react";

import { FormStepTitle } from "./form-step-title";

interface FormStepperProps {
  steps: Array<{
    title: string;
    content: ReactNode;
    skip?: boolean;
  }>;
}

export function FormStepper({ steps }: FormStepperProps) {
  const filteredSteps = steps.filter((step) => {
    return !step.skip;
  });

  return (
    <ClassNames>
      {({ css, theme }) => (
        <Stepper
          nonLinear
          orientation="vertical"
          className={css`
            width: 98%;
            padding: 0;
            background-color: transparent;
            align-items: flex-start;
            width: 100%;
            & > :last-child {
              margin-bottom: ${theme.spacing(2)};
            }
          `}
        >
          {filteredSteps.map(({ title, content, skip }, index) => (
            <Step
              key={`title-${index}`}
              active
              classes={{
                root: css`
                  width: 100%;
                  margin-bottom: -${theme.spacing(3)};
                `,
              }}
            >
              <StepLabel
                StepIconComponent={FormStepTitle}
                StepIconProps={{ title }}
              />

              <StepContent
                className={css`
                  padding-right: 0px;
                `}
              >
                {content}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      )}
    </ClassNames>
  );
}
