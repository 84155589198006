import { useLocation, useMatch, useNavigate } from "react-router";

import { usePrevious } from "./use-previous";

export function usePathToggle(params: {
  onPath: string;
  offPath: string;
}): [
  /**
   * `true` if the `onPath` is the current route
   */
  boolean,
  {
    /**
     * Links to the `onPath`
     */
    onLink: string;

    /**
     * Navigates to the `onPath `
     */
    navigateToOnPath(): void;

    /**
     * Navigates to the `offPath`
     */
    navigateToOffPath(): void;
  }
] {
  const location = useLocation();
  const navigate = useNavigate();
  const pathMatch = useMatch(params.onPath);
  const previouslyOff = usePrevious(!pathMatch);

  const onLink = `${params.onPath}${location.search}`;

  return [
    Boolean(pathMatch),
    {
      onLink,
      navigateToOnPath: () => navigate(onLink),
      navigateToOffPath: () => {
        if (previouslyOff) {
          // navigate 'back' to clear the history
          navigate(-1);
        } else {
          // replace the route, because the off path is not the previous route
          navigate(`${params.offPath}${location.search}`, { replace: true });
        }
      },
    },
  ];
}
