import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListRedemptionsQueryVariables = Types.Exact<{
  filter: Types.RedemptionsFilterInput;
  offset: Types.Scalars['Int'];
  limit: Types.Scalars['Int'];
}>;


export type ListRedemptionsQuery = (
  { __typename?: 'Query' }
  & { listRedemptions: (
    { __typename?: 'RedemptionsResponse' }
    & Pick<Types.RedemptionsResponse, 'total'>
    & { items: Array<(
      { __typename?: 'Redemption' }
      & Pick<Types.Redemption, 'id' | 'status' | 'dollars' | 'points' | 'createdAt' | 'markedAsSentToPayrollAt' | 'markedAsPaidAt'>
      & { user: (
        { __typename?: 'RewardsUser' }
        & Pick<Types.RewardsUser, 'id' | 'firstName' | 'lastName' | 'externalEmployeeId'>
        & { groups: Array<(
          { __typename?: 'RewardsUserGroup' }
          & Pick<Types.RewardsUserGroup, 'id' | 'name'>
        )> }
      ) }
    )> }
  ) }
);


export const ListRedemptionsDocument = gql`
    query ListRedemptions($filter: RedemptionsFilterInput!, $offset: Int!, $limit: Int!) {
  listRedemptions(filter: $filter, offset: $offset, limit: $limit) {
    total
    items {
      id
      status
      dollars
      points
      createdAt
      markedAsSentToPayrollAt
      markedAsPaidAt
      user {
        id
        firstName
        lastName
        externalEmployeeId
        groups {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useListRedemptionsQuery__
 *
 * To run a query within a React component, call `useListRedemptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRedemptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRedemptionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListRedemptionsQuery(baseOptions: Apollo.QueryHookOptions<ListRedemptionsQuery, ListRedemptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListRedemptionsQuery, ListRedemptionsQueryVariables>(ListRedemptionsDocument, options);
      }
export function useListRedemptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListRedemptionsQuery, ListRedemptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListRedemptionsQuery, ListRedemptionsQueryVariables>(ListRedemptionsDocument, options);
        }
export type ListRedemptionsQueryHookResult = ReturnType<typeof useListRedemptionsQuery>;
export type ListRedemptionsLazyQueryHookResult = ReturnType<typeof useListRedemptionsLazyQuery>;
export type ListRedemptionsQueryResult = Apollo.QueryResult<ListRedemptionsQuery, ListRedemptionsQueryVariables>;