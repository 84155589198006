import { ClassNames } from "@emotion/react";
import {
  darken,
  alpha,
  Grow,
  ListSubheader,
  MenuItem,
  Select,
  OutlinedInput,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { compact } from "lodash";
import { ReactNode, useState } from "react";

import { StylableProps } from "@rewards-web/shared/types";

import { useFormControlContext } from "../form/form-control";
import { SelectCheckboxesFieldValue } from "./select-checkboxes-field-value";
import { SelectCheckboxesItemContents } from "./select-checkboxes-item-contents";

const MENU_ITEM_HEIGHT_PX = 30;

type SelectCheckboxesFieldOptionChildren = {
  label: string;
  subLabel?: string;
  value: string;
}[];

export type SelectCheckboxesFieldOption = {
  header?: boolean;
  label: string;
  subLabel?: string;
  children?: SelectCheckboxesFieldOptionChildren;
} & (
  | {
      value: string;
    }
  | {
      value?: string;
      children: SelectCheckboxesFieldOptionChildren;
    }
);

export interface SelectCheckboxesFieldProps extends StylableProps {
  id: string;
  leftIcon?: ReactNode;
  rightAdornment?: ReactNode;
  label: string;
  displayedValue?: string;
  options: SelectCheckboxesFieldOption[];
  value: string[];
  disabled?: boolean;

  variant?: "filter" | "field";

  /**
   * Calls onChange with a `string` if `type='radio'`
   */
  onChange(value: string[] | string): void;
  onOpen?(): void;
  type?: "checkboxes" | "radio";
  fullWidth?: boolean;
}

/**
 * Renders a checkbox dropdown.
 *
 * One nested level may be provided as child options.
 */
export function SelectCheckboxesField({
  variant = "filter",
  id,
  label,
  displayedValue = label,
  leftIcon,
  rightAdornment,
  options,
  value,
  onChange,
  onOpen,
  className,
  disabled: disabledProp = false,
  type = "checkboxes",
  fullWidth = false,
}: SelectCheckboxesFieldProps) {
  const { submitting } = useFormControlContext();
  const [open, setOpen] = useState(false);

  const totalCalculatedHeight = options.reduce(
    (prev, option) =>
      prev +
      1 +
      ((!option.value || value.includes(option.value)) && option.children
        ? option.children.length
        : 0),
    0
  );

  const disabled = disabledProp || submitting;

  return (
    <ClassNames>
      {({ css, cx, theme }) => {
        return (
          <FormControl
            className={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              ${variant === "filter"
                ? css`
                    height: 48px;
                  `
                : css`
                    margin-bottom: ${theme.spacing(3)};
                  `}
            `}
          >
            {variant === "field" && (
              <InputLabel id={id} variant="outlined" shrink>
                {label}
              </InputLabel>
            )}
            <Select
              disabled={disabled}
              label={variant === "field" ? label : undefined}
              input={
                <OutlinedInput
                  label={label}
                  notched={variant === "field"}
                  classes={{
                    root: "custom-input-root",
                    notchedOutline: "custom-notched-outline",
                  }}
                />
              }
              classes={{
                root: "custom-root",
              }}
              fullWidth={fullWidth}
              className={cx(
                css`
                  ${variant === "filter"
                    ? css`
                        max-height: 75%;
                      `
                    : css``}

                  background-color: ${theme.palette.background.paper};

                  &:hover:not(.Mui-focused):not(.Mui-error)
                    .custom-notched-outline {
                    border-color: ${darken(theme.palette.divider, 0.15)};
                  }

                  & .custom-notched-outline {
                    border-color: ${theme.palette.divider};
                  }

                  & .custom-root:focus {
                    background-color: transparent;
                  }

                  & .custom-root {
                    padding-right: ${theme.spacing(2)};
                    ${variant === "field" &&
                    css`
                      padding-top: 18px;
                      padding-bottom: 18px;
                    `}
                  }

                  &.Mui-focused .custom-notched-outline,
                  &:hover .custom-notched-outline {
                    border-width: 1px;
                  }

                  ${disabled &&
                  variant === "filter" &&
                  css`
                    opacity: 0.4;
                  `}

                  &.Mui-disabled .custom-notched-outline {
                    border-color: #dfe4eb;
                  }

                  text-align: center;
                  border-radius: ${theme.spacing(1)};

                  transition: border-bottom-left-radius 0.1s linear,
                    border-bottom-right-radius 0.1s linear;
                  ${open &&
                  css`
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                  `}

                  &.custom-input-root:hover .custom-notched-outline,
                  &.custom-input-root.Mui-focused
                    .custom-notched-outline {
                    border-left-width: 1px;
                    margin-left: -1px;
                  }
                `,
                className
              )}
              open={open}
              onClose={() => setOpen(false)}
              onOpen={() => {
                setOpen(true);
                onOpen?.();
              }}
              id={id}
              multiple={type === "checkboxes"}
              value={value}
              onChange={(e) => {
                onChange(e.target.value as string[]);
              }}
              IconComponent={() => null}
              variant="outlined"
              renderValue={() => (
                <SelectCheckboxesFieldValue
                  leftIcon={leftIcon}
                  label={displayedValue}
                  rightAdornment={rightAdornment}
                  variant={variant}
                  disabled={disabled ?? false}
                />
              )}
              displayEmpty
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                TransitionComponent: Grow,
                transitionDuration: 200,

                className: css`
                  ${variant === "filter" &&
                  css`
                    margin-top: -12px;
                  `}
                  margin-left: -1px;
                `,

                PaperProps: {
                  className: css`
                    border-radius: 8px;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;
                  `,
                },

                MenuListProps: {
                  className: css`
                    padding: 0px;

                    // this prevents a bug if the user expands a section
                    // but there's not enough space at the bottom of the UI
                    height: ${totalCalculatedHeight * MENU_ITEM_HEIGHT_PX}px;
                  `,
                },
              }}
            >
              {options.flatMap((option) =>
                compact([
                  option.value ? (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      className={css`
                        height: ${MENU_ITEM_HEIGHT_PX}px;

                        padding: 0px;
                        padding-top: 2px;
                        padding-bottom: 2px;

                        ${option.value &&
                        value.includes(option.value) &&
                        css`
                          &.Mui-selected {
                            background-color: ${alpha(
                              theme.palette.primary.main,
                              0.08
                            )};
                          }
                        `}
                      `}
                    >
                      <SelectCheckboxesItemContents
                        type={type}
                        checked={!!option.value && value.includes(option.value)}
                        label={option.label}
                        subLabel={option.subLabel}
                      />
                    </MenuItem>
                  ) : (
                    <ListSubheader
                      className={css`
                        height: ${MENU_ITEM_HEIGHT_PX}px;
                        line-height: ${MENU_ITEM_HEIGHT_PX}px;
                        font-weight: bold;
                        color: ${theme.palette.text.primary};
                      `}
                      key={`header-${option.label}`}
                      disableSticky
                    >
                      {option.label}
                    </ListSubheader>
                  ),
                  ...(option.children
                    ? option.children.map((childOption) => (
                        <MenuItem
                          key={childOption.value}
                          value={childOption.value}
                          className={css`
                            padding: 0px;
                            padding-left: 20px;
                            padding-top: 0px;
                            padding-bottom: 0px;

                            ${value.includes(childOption.value) &&
                            css`
                              &.Mui-selected {
                                background-color: inherit;
                              }
                            `}

                            transition: opacity 0.15s ease-in-out, height 0.15s ease-in-out;
                            opacity: 0;
                            height: 0;
                            ${(!option.value || value.includes(option.value)) &&
                            css`
                              opacity: 1;
                              height: ${MENU_ITEM_HEIGHT_PX}px;
                            `}
                          `}
                        >
                          <SelectCheckboxesItemContents
                            type={type}
                            checked={value.includes(childOption.value)}
                            label={childOption.label}
                            subLabel={childOption.subLabel}
                          />
                        </MenuItem>
                      ))
                    : []),
                ])
              )}
            </Select>
          </FormControl>
        );
      }}
    </ClassNames>
  );
}

/**
 * Counts the number of child checkbox options that are selected,
 * which should be the number that displays in the filter.
 */
export function countSelectedOptionChildren(
  value: Set<string>,
  options: SelectCheckboxesFieldOption[]
): number {
  return options.reduce((prev, option) => {
    if (value.has(option.value!)) {
      return (
        prev +
        (option.children?.reduce((prev, option) => {
          if (value.has(option.value)) {
            return prev + 1;
          }

          return prev;
        }, 0) ?? 0)
      );
    }

    return prev;
  }, 0);
}
