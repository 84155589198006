import { createTheme as createMui5Theme } from "@mui/material";

import { AppTheme, AppThemeProperties } from "./types";

export function useMui5Theme(appTheme: AppThemeProperties): AppTheme {
  const muiTheme = createMui5Theme({
    components: {
      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: appTheme.mediaQueryNoSSR,
        },
      },
      MuiButton: {
        defaultProps: {
          size: appTheme.defaultButtonSize,
        },
      },
    },
    palette: appTheme.palette,
    shape: appTheme.shape,
    typography: appTheme.typography,
    breakpoints: {
      // match mui 4 breakpoints
      values: {
        xs: 0,
        sm: 500,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });

  return {
    ...muiTheme,
    buttonBorderRadius: appTheme.buttonBorderRadius,
    defaultTextFieldSize: appTheme.defaultTextFieldSize,
    maxContentWidth: appTheme.maxContentWidth,
    palette: {
      ...muiTheme.palette,
      gold: appTheme.palette.gold,
    },
  };
}
