import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SendResumeReminderMutationVariables = Types.Exact<{
  candidateId: Types.Scalars['ID'];
}>;


export type SendResumeReminderMutation = (
  { __typename?: 'Mutation' }
  & { sendCandidateResumeReminder: (
    { __typename?: 'Candidate' }
    & Pick<Types.Candidate, 'id'>
    & { resumeReminders: Array<(
      { __typename?: 'CandidateResumeReminder' }
      & Pick<Types.CandidateResumeReminder, 'id'>
    )> }
  ) }
);


export const SendResumeReminderDocument = gql`
    mutation SendResumeReminder($candidateId: ID!) {
  sendCandidateResumeReminder(candidateId: $candidateId) {
    id
    resumeReminders {
      id
    }
  }
}
    `;
export type SendResumeReminderMutationFn = Apollo.MutationFunction<SendResumeReminderMutation, SendResumeReminderMutationVariables>;

/**
 * __useSendResumeReminderMutation__
 *
 * To run a mutation, you first call `useSendResumeReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResumeReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResumeReminderMutation, { data, loading, error }] = useSendResumeReminderMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useSendResumeReminderMutation(baseOptions?: Apollo.MutationHookOptions<SendResumeReminderMutation, SendResumeReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendResumeReminderMutation, SendResumeReminderMutationVariables>(SendResumeReminderDocument, options);
      }
export type SendResumeReminderMutationHookResult = ReturnType<typeof useSendResumeReminderMutation>;
export type SendResumeReminderMutationResult = Apollo.MutationResult<SendResumeReminderMutation>;
export type SendResumeReminderMutationOptions = Apollo.BaseMutationOptions<SendResumeReminderMutation, SendResumeReminderMutationVariables>;