import * as Types from '@rewards-web/shared/graphql-types';

import { OnboardingProfileDataFragmentFragment } from './onboarding-profile-data-fragment.generated';
import { gql } from '@apollo/client';
import { OnboardingProfileDataFragmentFragmentDoc } from './onboarding-profile-data-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SaveOnboardingProfileDataMutationVariables = Types.Exact<{
  shortName: Types.Scalars['String'];
  longName: Types.Scalars['String'];
  onboardingBusinessType?: Types.Maybe<Types.RewardsOrganizationOnboardingBusinessType>;
  emailBannerLogoImage?: Types.Maybe<Types.Scalars['Upload']>;
  timezone: Types.Scalars['String'];
}>;


export type SaveOnboardingProfileDataMutation = (
  { __typename?: 'Mutation' }
  & { partialUpdateMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & OnboardingProfileDataFragmentFragment
  ) }
);


export const SaveOnboardingProfileDataDocument = gql`
    mutation SaveOnboardingProfileData($shortName: String!, $longName: String!, $onboardingBusinessType: RewardsOrganizationOnboardingBusinessType, $emailBannerLogoImage: Upload, $timezone: String!) {
  partialUpdateMyRewardsOrganization(
    shortName: $shortName
    longName: $longName
    onboardingBusinessType: $onboardingBusinessType
    emailBannerLogoImage: $emailBannerLogoImage
    timezone: $timezone
  ) {
    ...OnboardingProfileDataFragment
  }
}
    ${OnboardingProfileDataFragmentFragmentDoc}`;
export type SaveOnboardingProfileDataMutationFn = Apollo.MutationFunction<SaveOnboardingProfileDataMutation, SaveOnboardingProfileDataMutationVariables>;

/**
 * __useSaveOnboardingProfileDataMutation__
 *
 * To run a mutation, you first call `useSaveOnboardingProfileDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveOnboardingProfileDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveOnboardingProfileDataMutation, { data, loading, error }] = useSaveOnboardingProfileDataMutation({
 *   variables: {
 *      shortName: // value for 'shortName'
 *      longName: // value for 'longName'
 *      onboardingBusinessType: // value for 'onboardingBusinessType'
 *      emailBannerLogoImage: // value for 'emailBannerLogoImage'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useSaveOnboardingProfileDataMutation(baseOptions?: Apollo.MutationHookOptions<SaveOnboardingProfileDataMutation, SaveOnboardingProfileDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveOnboardingProfileDataMutation, SaveOnboardingProfileDataMutationVariables>(SaveOnboardingProfileDataDocument, options);
      }
export type SaveOnboardingProfileDataMutationHookResult = ReturnType<typeof useSaveOnboardingProfileDataMutation>;
export type SaveOnboardingProfileDataMutationResult = Apollo.MutationResult<SaveOnboardingProfileDataMutation>;
export type SaveOnboardingProfileDataMutationOptions = Apollo.BaseMutationOptions<SaveOnboardingProfileDataMutation, SaveOnboardingProfileDataMutationVariables>;