import { useState } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import {
  AuthError,
  AuthErrorCode,
  useCognitoAuth,
} from "../../../shared/modules/cognito-auth";
import {
  SubmitMfaPhoneNumberPage,
  SubmitMfaPhoneNumberPageProps,
} from "../../../shared/modules/cognito-auth/sms-mfa/components/submit-mfa-phone-number-page";
import {
  SubmitMfaVerificationCodePage,
  SubmitMfaVerificationCodePageProps,
} from "../../../shared/modules/cognito-auth/sms-mfa/components/submit-mfa-verification-code-page";
import { useRole } from "../../../shared/modules/role";
import { useEnableSmsMfaPageDataQuery } from "./enable-sms-mfa-page-data.generated";
import { obfuscatePhoneNumber } from "./lib";

export function EnableSmsMfa() {
  const snackbar = useSnackbar();
  const track = useTrack();
  const {
    setMfaPhoneNumber,
    verifyNewMfaPhoneNumber,
    resendNewMfaVerificationCode,
  } = useCognitoAuth();
  const [
    submittedMfaPhoneNumber,
    setSubmittedMfaPhoneNumber,
  ] = useState<string>();
  const role = useRole();
  const isAdmin = role.data?.role === "admin";

  const query = useEnableSmsMfaPageDataQuery({
    onError: reportError,
    skip: !isAdmin,
  });

  const handlePhoneNumberSubmitted: SubmitMfaPhoneNumberPageProps["onSubmit"] = async ({
    phoneNumber,
  }) => {
    try {
      await setMfaPhoneNumber(phoneNumber);
      track("Set new SMS MFA phone number");
      setSubmittedMfaPhoneNumber(phoneNumber);
    } catch (error) {
      if (
        error instanceof AuthError &&
        error.code === AuthErrorCode.TooManyRequests
      ) {
        track("Tried to enable SMS MFA too many times", {
          stage: "set_phone_number",
        });
        snackbar.show({
          severity: "error",
          message:
            "You've attempted to verify too many times. Please try again later.",
          durationMs: 10000,
        });
      } else {
        reportError(error);
        snackbar.show({
          severity: "error",
          message: "An unexpected error occurred. Please try again later.",
        });
      }
    }
  };

  const handleVerificationCodeSubmitted: SubmitMfaVerificationCodePageProps["onSubmit"] = async ({
    code,
  }) => {
    try {
      await verifyNewMfaPhoneNumber(code);
      track("Verified new MFA phone number");
      // the user should automatically navigate away
    } catch (error) {
      if (
        error instanceof AuthError &&
        error.code === AuthErrorCode.IncorrectVerificationCode
      ) {
        track("Entered incorrect verification code when enabling SMS MFA");
        snackbar.show({
          severity: "error",
          message: "The code you entered was incorrect. Please try again.",
          durationMs: 10000,
        });
      } else {
        reportError(error);
        snackbar.show({
          severity: "error",
          message: "An unexpected error occurred. Please try again later.",
        });
      }
    }
  };

  const handleResendCode: SubmitMfaVerificationCodePageProps["onResendCode"] = async () => {
    try {
      await resendNewMfaVerificationCode();

      track("Resent new MFA phone number code");

      snackbar.show({
        severity: "success",
        message: "Your code has been re-sent.",
        durationMs: 10000,
      });
    } catch (error) {
      if (
        error instanceof AuthError &&
        error.code === AuthErrorCode.TooManyRequests
      ) {
        snackbar.show({
          severity: "error",
          message:
            "You've attempted to verify too many times. Please try again later.",
          durationMs: 10000,
        });

        track("Tried to enable SMS MFA too many times", {
          stage: "submit_verification_code",
        });
      } else {
        reportError(error);
        snackbar.show({
          severity: "error",
          message: "An unexpected error occurred. Please try again later.",
        });
      }
    }
  };

  const handleCancelVerificationCode: SubmitMfaVerificationCodePageProps["onCancel"] = async () => {
    setSubmittedMfaPhoneNumber(undefined);
  };

  if (query.error) {
    return (
      <Alert
        severity="error"
        message="An unexpected error occurred. Please try again later."
      />
    );
  }

  const organizationName = (() => {
    if (isAdmin) {
      return query.data?.myOrganization.shortName ?? "";
    }

    return "Caribou";
  })();

  if (!submittedMfaPhoneNumber) {
    return (
      <SubmitMfaPhoneNumberPage
        organizationName={organizationName}
        onSubmit={handlePhoneNumberSubmitted}
      />
    );
  }

  return (
    <SubmitMfaVerificationCodePage
      phoneNumber={obfuscatePhoneNumber(submittedMfaPhoneNumber)}
      onSubmit={handleVerificationCodeSubmitted}
      onResendCode={handleResendCode}
      onCancel={handleCancelVerificationCode}
      cancelButtonLabel="Update Phone Number"
    />
  );
}
