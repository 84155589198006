/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

interface TableRowProps {
  children?: ReactNode;
  highlightOnHover?: Boolean;
  backgroundColor?: string;
}

export function TableRow({
  children,
  highlightOnHover = false,
  backgroundColor,
}: TableRowProps): JSX.Element {
  return (
    <tr
      css={css`
        background-color: ${backgroundColor};
        ${highlightOnHover &&
        css`
          :hover {
            background-color: #f2f2f6;
          }
        `};
      `}
    >
      {children}
    </tr>
  );
}
