import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ReferringUserSearchQueryVariables = Types.Exact<{
  searchQuery?: Types.Maybe<Types.Scalars['String']>;
}>;


export type ReferringUserSearchQuery = (
  { __typename?: 'Query' }
  & { searchForRewardsUsers: Array<(
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'firstName' | 'lastName'>
  )> }
);


export const ReferringUserSearchDocument = gql`
    query ReferringUserSearch($searchQuery: String) {
  searchForRewardsUsers(searchQuery: $searchQuery, limit: 30) {
    id
    firstName
    lastName
  }
}
    `;

/**
 * __useReferringUserSearchQuery__
 *
 * To run a query within a React component, call `useReferringUserSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferringUserSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferringUserSearchQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useReferringUserSearchQuery(baseOptions?: Apollo.QueryHookOptions<ReferringUserSearchQuery, ReferringUserSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReferringUserSearchQuery, ReferringUserSearchQueryVariables>(ReferringUserSearchDocument, options);
      }
export function useReferringUserSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferringUserSearchQuery, ReferringUserSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReferringUserSearchQuery, ReferringUserSearchQueryVariables>(ReferringUserSearchDocument, options);
        }
export type ReferringUserSearchQueryHookResult = ReturnType<typeof useReferringUserSearchQuery>;
export type ReferringUserSearchLazyQueryHookResult = ReturnType<typeof useReferringUserSearchLazyQuery>;
export type ReferringUserSearchQueryResult = Apollo.QueryResult<ReferringUserSearchQuery, ReferringUserSearchQueryVariables>;