export function ClockIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.84375 4.03125C6.84375 3.67578 7.11719 3.375 7.5 3.375C7.85547 3.375 8.15625 3.67578 8.15625 4.03125V7.42188L10.4805 8.95312C10.7812 9.17188 10.8633 9.58203 10.6445 9.88281C10.4531 10.1836 10.043 10.2656 9.74219 10.0469L7.11719 8.29688C6.95312 8.1875 6.84375 7.96875 6.84375 7.75V4.03125ZM7.5 0.75C11.3555 0.75 14.5 3.89453 14.5 7.75C14.5 11.6328 11.3555 14.75 7.5 14.75C3.61719 14.75 0.5 11.6328 0.5 7.75C0.5 3.89453 3.61719 0.75 7.5 0.75ZM1.8125 7.75C1.8125 10.8945 4.35547 13.4375 7.5 13.4375C10.6172 13.4375 13.1875 10.8945 13.1875 7.75C13.1875 4.63281 10.6172 2.0625 7.5 2.0625C4.35547 2.0625 1.8125 4.63281 1.8125 7.75Z"
        fill="#444444"
      />
    </svg>
  );
}
