export function openedFromHomeScreen() {
  if (window.matchMedia?.("(display-mode: standalone)").matches) {
    return true; // android
  }

  if ((window.navigator as any)?.standalone) {
    return true; // ios
  }

  return false;
}
