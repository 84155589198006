import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type OnboardingStateQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OnboardingStateQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'launched'>
    & { onboardingState: (
      { __typename?: 'RewardsOrganizationOnboardingState' }
      & Pick<Types.RewardsOrganizationOnboardingState, 'id' | 'completedSteps' | 'disabledOnboarding'>
    ) }
  ) }
);


export const OnboardingStateDocument = gql`
    query OnboardingState {
  getMyRewardsOrganization {
    __typename
    id
    launched
    onboardingState {
      id
      completedSteps
      disabledOnboarding
    }
  }
}
    `;

/**
 * __useOnboardingStateQuery__
 *
 * To run a query within a React component, call `useOnboardingStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingStateQuery(baseOptions?: Apollo.QueryHookOptions<OnboardingStateQuery, OnboardingStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingStateQuery, OnboardingStateQueryVariables>(OnboardingStateDocument, options);
      }
export function useOnboardingStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingStateQuery, OnboardingStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingStateQuery, OnboardingStateQueryVariables>(OnboardingStateDocument, options);
        }
export type OnboardingStateQueryHookResult = ReturnType<typeof useOnboardingStateQuery>;
export type OnboardingStateLazyQueryHookResult = ReturnType<typeof useOnboardingStateLazyQuery>;
export type OnboardingStateQueryResult = Apollo.QueryResult<OnboardingStateQuery, OnboardingStateQueryVariables>;