import { SVGProps } from "react";

export function RecognitionPointsSentIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={17}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m5.13 9.22 6.183-3.533-3.534 6.184a.662.662 0 0 0 .024.694l2.76 4.14a.66.66 0 0 0 1.177-.157L16.966.87a.661.661 0 0 0-.835-.835L.45 5.26a.66.66 0 0 0-.157 1.176l4.141 2.76a.662.662 0 0 0 .694.024Z"
        fill={props.fill || "#5C9262"}
      />
    </svg>
  );
}
