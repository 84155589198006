import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ConfirmCandidateEmployeeLinkDataQueryVariables = Types.Exact<{
  candidateId: Types.Scalars['ID'];
  employeeId: Types.Scalars['ID'];
}>;


export type ConfirmCandidateEmployeeLinkDataQuery = (
  { __typename?: 'Query' }
  & { candidate?: Types.Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Types.Candidate, 'id' | 'firstName' | 'lastName'>
    & { assignedToJobPosting?: Types.Maybe<(
      { __typename?: 'JobPosting' }
      & Pick<Types.JobPosting, 'id' | 'title' | 'geography'>
    )> }
  )>, employee?: Types.Maybe<(
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'firstName' | 'lastName' | 'workEmail' | 'workPhoneNumber'>
    & { personalContactInfo?: Types.Maybe<(
      { __typename: 'RewardsUserPersonalContactInfoData' }
      & Pick<Types.RewardsUserPersonalContactInfoData, 'id' | 'email' | 'phoneNumber'>
    ) | (
      { __typename: 'RewardsUserPersonalContactInfoSuppressed' }
      & Pick<Types.RewardsUserPersonalContactInfoSuppressed, 'id'>
    )>, branch?: Types.Maybe<(
      { __typename?: 'RewardsOrganizationBranch' }
      & Pick<Types.RewardsOrganizationBranch, 'id' | 'name'>
    )> }
  )> }
);


export const ConfirmCandidateEmployeeLinkDataDocument = gql`
    query ConfirmCandidateEmployeeLinkData($candidateId: ID!, $employeeId: ID!) {
  candidate: getCandidateById(candidateId: $candidateId) {
    id
    firstName
    lastName
    assignedToJobPosting {
      id
      title
      geography
    }
  }
  employee: getRewardsUserById(userId: $employeeId) {
    id
    firstName
    lastName
    personalContactInfo {
      __typename
      ... on RewardsUserPersonalContactInfoData {
        id
        email
        phoneNumber
      }
      ... on RewardsUserPersonalContactInfoSuppressed {
        id
      }
    }
    workEmail
    workPhoneNumber
    branch {
      id
      name
    }
  }
}
    `;

/**
 * __useConfirmCandidateEmployeeLinkDataQuery__
 *
 * To run a query within a React component, call `useConfirmCandidateEmployeeLinkDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmCandidateEmployeeLinkDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmCandidateEmployeeLinkDataQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useConfirmCandidateEmployeeLinkDataQuery(baseOptions: Apollo.QueryHookOptions<ConfirmCandidateEmployeeLinkDataQuery, ConfirmCandidateEmployeeLinkDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfirmCandidateEmployeeLinkDataQuery, ConfirmCandidateEmployeeLinkDataQueryVariables>(ConfirmCandidateEmployeeLinkDataDocument, options);
      }
export function useConfirmCandidateEmployeeLinkDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfirmCandidateEmployeeLinkDataQuery, ConfirmCandidateEmployeeLinkDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfirmCandidateEmployeeLinkDataQuery, ConfirmCandidateEmployeeLinkDataQueryVariables>(ConfirmCandidateEmployeeLinkDataDocument, options);
        }
export type ConfirmCandidateEmployeeLinkDataQueryHookResult = ReturnType<typeof useConfirmCandidateEmployeeLinkDataQuery>;
export type ConfirmCandidateEmployeeLinkDataLazyQueryHookResult = ReturnType<typeof useConfirmCandidateEmployeeLinkDataLazyQuery>;
export type ConfirmCandidateEmployeeLinkDataQueryResult = Apollo.QueryResult<ConfirmCandidateEmployeeLinkDataQuery, ConfirmCandidateEmployeeLinkDataQueryVariables>;