import { Control, FieldValues, Path, useFormState, get } from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";

interface EmployeeLastNameFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export function EmployeeLastNameField<T extends FieldValues>({
  control,
  name,
}: EmployeeLastNameFieldProps<T>) {
  const formState = useFormState({ control, name });

  return (
    <TextField
      label="Last name"
      error={get(formState.errors, name)}
      disableAutocomplete
      {...control.register(name, {
        required: "Last name is required",
      })}
    />
  );
}
