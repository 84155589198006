import { SVGProps } from "react";

export function TrashIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      height={19}
      width={17}
      viewBox="0 0 17 19"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        d="M15.5312 3.5625C15.9883 3.5625 16.375 3.94922 16.375 4.40625C16.375 4.89844 15.9883 5.25 15.5312 5.25H15.25L14.4766 17.168C14.4414 18.082 13.7031 18.75 12.7891 18.75H4.17578C3.26172 18.75 2.52344 18.082 2.48828 17.168L1.75 5.25H1.43359C0.976562 5.25 0.625 4.82812 0.625 4.40625C0.625 3.94922 0.976562 3.5625 1.43359 3.5625H4.07031L5.26562 1.59375C5.58203 1.06641 6.10938 0.75 6.70703 0.75H10.2578C10.8555 0.75 11.3828 1.06641 11.6992 1.59375L12.8945 3.5625H15.5312ZM6.70703 2.4375L6.03906 3.5625H10.9258L10.2578 2.4375H6.70703ZM12.7891 17.0625L13.5273 5.25H3.4375L4.17578 17.0625H12.7891Z"
        fill={props.fill || "#2C393F"}
      />
    </svg>
  );
}
