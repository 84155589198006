/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Control,
  FieldValues,
  Path,
  useFormState,
  useWatch,
  get,
} from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";
import { getCharactersRemainingText } from "@rewards-web/shared/lib/characters-remaining-text";
import { AppTheme } from "@rewards-web/shared/style/types";

const MAX_CATEGORY_NAME_LENGTH = 30;

interface RecognitionCategoryNameFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  autoFocus?: boolean;
}

export function RecognitionCategoryNameField<T extends FieldValues>({
  control,
  name,
  autoFocus,
}: RecognitionCategoryNameFieldProps<T>) {
  const formState = useFormState({ control, name });
  const value = useWatch({ control, name });

  return (
    <TextField
      css={(theme: AppTheme) => css`
        margin-bottom: ${theme.spacing(2)};
      `}
      label="Category name"
      disableAutocomplete
      autoFocus={autoFocus}
      helperText={getCharactersRemainingText(value, MAX_CATEGORY_NAME_LENGTH)}
      error={get(formState.errors, name)}
      {...control.register(name, {
        required: "Category name is required",
        validate: (value) => {
          if (value && value.length > MAX_CATEGORY_NAME_LENGTH) {
            return getCharactersRemainingText(value, MAX_CATEGORY_NAME_LENGTH);
          }
        },
      })}
    />
  );
}
