import { SurveyQuestionType } from "@rewards-web/shared/graphql-types";

export const SURVEY_QUESTION_TYPES_SUPPORTED_BY_UI = [
  SurveyQuestionType.MeaningfulRecognition,
  SurveyQuestionType.ManageableWorkload,
  SurveyQuestionType.ReliableTransportation,
  SurveyQuestionType.LeadershipSupport,
  SurveyQuestionType.Resources,
  SurveyQuestionType.Nps,
] as const;

export type SurveyQuestionTypeSupportedByUI = typeof SURVEY_QUESTION_TYPES_SUPPORTED_BY_UI[number];

export const PULSE_SURVEY_QUESTIONS: SurveyQuestionTypeSupportedByUI[] = [
  SurveyQuestionType.MeaningfulRecognition,
  SurveyQuestionType.ManageableWorkload,
  SurveyQuestionType.ReliableTransportation,
  SurveyQuestionType.LeadershipSupport,
  SurveyQuestionType.Resources,
  SurveyQuestionType.Nps,
];
