export class AddEditEmployeeConflictError extends Error {
  constructor(
    public readonly fieldsAndUsers: Array<{
      name:
        | "workPhoneNumber"
        | "workEmail"
        | "personalPhoneNumber"
        | "personalEmail";
      userActive: boolean;
    }>
  ) {
    super("An existing employee has conflicting information");
  }
}

export class AddEditEmployeeAdminConflictError extends Error {
  constructor(public readonly fields: Array<"email">) {
    super("An existing admin has conflicting information");
  }
}

export class AddEditEmployeeInvalidWorkDomainError extends Error {
  constructor(public readonly requiredDomain: string) {
    super("Invalid work domain");
  }
}

export class UserCannotAssignManagerAsThemselvesError extends Error {}
