/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { EmployeeIcon } from "../../../candidate-employee-link/icons/employee-icon";

export function EmployeeSummaryLabel() {
  const theme = useTheme();
  return (
    <div
      css={(theme: AppTheme) => css`
        display: flex;
        align-items: center;
        padding: ${theme.spacing(2)} 0;
      `}
    >
      <EmployeeIcon width={30} height={30} fill={theme.palette.primary.main} />
      <Typography
        css={(theme: AppTheme) => css`
          text-transform: uppercase;
          font-size: 1.4em;
          font-weight: bolder;
          margin-left: ${theme.spacing(1)};
        `}
      >
        Employee
      </Typography>
    </div>
  );
}
