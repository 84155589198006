/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  ArrayPath,
  Control,
  Controller,
  FieldValues,
  useFieldArray,
  useWatch,
} from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { CheckboxField } from "@rewards-web/shared/components/checkbox-field";
import { useFormControlContext } from "@rewards-web/shared/components/form/form-control";
import { IconButton } from "@rewards-web/shared/components/icon-button";
import { RadioButton } from "@rewards-web/shared/components/radio-buttons";
import { RadioGroup } from "@rewards-web/shared/components/radio-buttons/radio-group";
import {
  SearchTextField,
  ValueLabel,
} from "@rewards-web/shared/components/search-text-field";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { JobPostingAutoRejectResponse } from "@rewards-web/shared/graphql-types";
import { getCharactersRemainingText } from "@rewards-web/shared/lib/characters-remaining-text";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/theme";

import {
  JobScreenerQuestionPopulatedValue,
  JobScreenerQuestionPresets,
  JobScreenerQuestionValueWithId,
} from "../../typings";

const SCREENER_QUESTION_MAX_CHARACTERS = 256;

const SCREENER_QUESTION_PRESETS: Array<{
  type: JobScreenerQuestionPopulatedValue["type"];
  question: string;
}> = [
  {
    type: JobScreenerQuestionPresets.HAS_DRIVERS_LICENSE_AND_ACCESS_TO_CAR,
    question:
      "Do you have a valid driver's license and reliable access to a vehicle?",
  },
  {
    type: "YES_OR_NO",
    question:
      "Do you hold any relevant certifications, such as Home Health Aide (HHA) or Certified Nursing Assistant(CNA)?”",
  },
  {
    type: "YES_OR_NO",
    question:
      "Do you have previous experience in a home care or healthcare setting?",
  },
  {
    type: "YES_OR_NO",
    question: "Have you been fully vaccinated against COVID-19?",
  },
  { type: "YES_OR_NO", question: "Are you at least 18 years old?" },
];

const SCREENER_QUESTION_PRESET_OPTIONS: ValueLabel[] = SCREENER_QUESTION_PRESETS.map(
  (preset) => ({
    value: preset.question,
    label: preset.question,
  })
);

interface JobScreenerQuestionsFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: ArrayPath<T>;
}

export function JobScreenerQuestionsField<T extends FieldValues>({
  control,
  name,
}: JobScreenerQuestionsFieldProps<T>): JSX.Element {
  const { fields, append, remove } = useFieldArray({ control, name });
  const { readOnly } = useFormControlContext();
  const screenerQuestionsArray = useWatch({
    control,
    name: `${name}` as any,
  });

  const autoRejectEnabled = useFeatureFlag(
    "admin-app-auto-reject-responses-job-temp"
  );
  const calculateMargin = (index: number, error: boolean) => {
    const screenerQuestion = screenerQuestionsArray[index];

    // Check if there is an error or if the question type is "YES_OR_NO" and has remaining characters
    if (
      error ||
      (screenerQuestion?.type === "YES_OR_NO" &&
        getCharactersRemainingText(
          screenerQuestion?.question ?? "",
          SCREENER_QUESTION_MAX_CHARACTERS
        ))
    ) {
      return -1;
    }
    return -3;
  };

  return (
    <>
      {((fields as unknown) as (JobScreenerQuestionValueWithId & {
        id: string;
      })[]).map((screenerField, index) => {
        const autoRejectValue = screenerQuestionsArray[index]?.autoReject;
        return (
          <div key={screenerField.id}>
            <div
              css={css`
                display: flex;
              `}
            >
              <Controller
                control={control}
                name={`${name}.${index}` as any}
                rules={{
                  required: "Job applied to is required",
                  validate: (value: JobScreenerQuestionValueWithId) => {
                    if (
                      value.type === "YES_OR_NO" &&
                      value.question?.length > SCREENER_QUESTION_MAX_CHARACTERS
                    ) {
                      return getCharactersRemainingText(
                        value.question,
                        SCREENER_QUESTION_MAX_CHARACTERS
                      );
                    }
                  },
                }}
                render={({ field, fieldState }) => (
                  <SearchTextField
                    css={css`
                      flex-grow: 1;
                    `}
                    name={field.name}
                    onBlur={field.onBlur}
                    fixLabelToTop
                    placeholder="E.g. Do you have a driving license and access to a car?"
                    value={(() => {
                      const value = field.value as JobScreenerQuestionValueWithId;

                      if (!value.type) {
                        return null;
                      }

                      if (value.type === "YES_OR_NO") {
                        return value.question;
                      }

                      const matchingPreset = SCREENER_QUESTION_PRESETS.find(
                        (option) => option.type === value.type
                      );

                      if (!matchingPreset) {
                        // should not happen
                        throw new Error("No matching preset");
                      }

                      return matchingPreset.question;
                    })()}
                    onChange={(value: string) => {
                      if (value === null) {
                        field.onChange({
                          type: null,
                        });
                      } else {
                        const matchingPreset = SCREENER_QUESTION_PRESETS.find(
                          (option) =>
                            option.question === value &&
                            option.type !== "YES_OR_NO"
                        );
                        if (matchingPreset) {
                          // non YES_OR_NO preset selected
                          field.onChange({
                            ...field.value,
                            type: matchingPreset.type,
                          });
                        } else {
                          field.onChange({
                            ...field.value,
                            type: "YES_OR_NO",
                            question: value,
                          });
                          // custom question entered
                        }
                      }
                    }}
                    helperText={
                      (field.value as JobScreenerQuestionValueWithId).type ===
                      "YES_OR_NO"
                        ? getCharactersRemainingText(
                            field.value.question ?? "",
                            SCREENER_QUESTION_MAX_CHARACTERS
                          )
                        : undefined
                    }
                    error={fieldState.error}
                    label={`Screening Question ${index + 1}`}
                    options={SCREENER_QUESTION_PRESET_OPTIONS}
                    allowCustomValues
                  />
                )}
              />

              {!readOnly && (
                <IconButton
                  css={(theme: AppTheme) => css`
                    height: 50px;
                    width: 50px;
                    margin-left: ${theme.spacing(1)};
                    margin-top: ${theme.spacing(0.5)};
                  `}
                  onClick={() => {
                    remove(index);
                  }}
                  aria-label={"Delete screener question"}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
            {autoRejectEnabled && (
              <div
                css={(theme: AppTheme) => css`
                  margin-bottom: ${theme.spacing(3)};
                `}
              >
                <Controller
                  control={control}
                  name={`${name}.${index}` as any}
                  render={({ field, fieldState }) => {
                    return (
                      <div
                        css={(theme: AppTheme) => css`
                          margin-top: ${theme.spacing(
                            calculateMargin(index, Boolean(fieldState.error))
                          )};
                          padding: ${theme.spacing(1)};
                        `}
                      >
                        <CheckboxField
                          {...field}
                          value={Boolean(field.value.autoReject?.length)}
                          error={fieldState.error}
                          label={
                            <span
                              css={(theme: AppTheme) => css`
                                margin-bottom: ${theme.spacing(1)};
                              `}
                            >
                              Auto-reject
                              <Tooltip
                                title={
                                  <>
                                    By enabling auto-reject, the candidate will
                                    be automatically archived and disqualified.
                                    <br />
                                    <br />
                                    The system will record the candidate's
                                    response and send them an email 24 hours
                                    after the application has been archived.
                                  </>
                                }
                                css={css`
                                  font-size: 14px;
                                `}
                              >
                                <FontAwesomeIcon
                                  icon={faInfoCircle}
                                  css={(theme: AppTheme) => css`
                                    margin-left: ${theme.spacing(1)};
                                    color: ${theme.palette.grey[600]};
                                  `}
                                />
                              </Tooltip>
                            </span>
                          }
                          display="block"
                          onChange={(value: boolean) => {
                            field.onChange({
                              ...field.value,
                              // If admin selected the auto-reject checkbox, we set the default value to NO
                              autoReject: value
                                ? [JobPostingAutoRejectResponse.No]
                                : null,
                            });
                          }}
                        />
                      </div>
                    );
                  }}
                />
                {/* the Yes or No radio buttons */}
                {Boolean(autoRejectValue) && (
                  <div
                    css={(theme: AppTheme) => css`
                      display: flex;
                      padding: ${theme.spacing(2)} ${theme.spacing(3)};
                      align-items: center;
                      gap: ${theme.spacing(1)};
                      border-radius: 8px;
                      background: ${theme.palette.grey[100]};
                      margin-bottom: ${theme.spacing(5)};
                    `}
                  >
                    Reject candidate if response is:
                    <Controller
                      control={control}
                      name={`${name}.${index}` as any}
                      rules={{
                        required: "This input is required if checked",
                      }}
                      render={({ field, fieldState }) => {
                        return (
                          <RadioGroup
                            ariaLabel="auto-reject-candidate"
                            error={fieldState.error}
                            row
                            {...field}
                            value={
                              field.value.autoReject
                                ? field.value.autoReject[0]
                                : false
                            }
                            onChange={(value) => {
                              field.onChange({
                                ...field.value,
                                autoReject: [value],
                              });
                            }}
                          >
                            <RadioButton
                              value={JobPostingAutoRejectResponse.Yes}
                              label="Yes"
                            />
                            <RadioButton
                              value={JobPostingAutoRejectResponse.No}
                              label="No"
                            />
                          </RadioGroup>
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}

      {!readOnly && (
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          label="Add a question"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(3)};
          `}
          onClick={() => {
            append({ type: null } as any);
          }}
        />
      )}
    </>
  );
}
