import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CandidateMergeSearchQueryVariables = Types.Exact<{
  searchQuery?: Types.Maybe<Types.Scalars['String']>;
}>;


export type CandidateMergeSearchQuery = (
  { __typename?: 'Query' }
  & { listCandidatesV2: (
    { __typename?: 'ListCandidatesResponse' }
    & { items: Array<(
      { __typename?: 'Candidate' }
      & Pick<Types.Candidate, 'id' | 'firstName' | 'lastName' | 'preferredName' | 'appliedOnDates'>
      & { jobsAppliedTo: Array<(
        { __typename?: 'JobPosting' }
        & Pick<Types.JobPosting, 'id' | 'title' | 'geography'>
      )>, referredByUser: (
        { __typename?: 'RewardsUser' }
        & Pick<Types.RewardsUser, 'id' | 'firstName' | 'lastName'>
      ), completedStepsV2: Array<(
        { __typename?: 'RecordedCandidateRecruitmentStep' }
        & Pick<Types.RecordedCandidateRecruitmentStep, 'stepName' | 'recordedAt'>
      ) | (
        { __typename?: 'RecordedCandidateMonthlyRetentionStep' }
        & Pick<Types.RecordedCandidateMonthlyRetentionStep, 'durationMonths' | 'recordedAt'>
      ) | (
        { __typename?: 'RecordedCandidateHourlyRetentionStep' }
        & Pick<Types.RecordedCandidateHourlyRetentionStep, 'durationHours' | 'recordedAt'>
      )> }
    )> }
  ) }
);


export const CandidateMergeSearchDocument = gql`
    query CandidateMergeSearch($searchQuery: String) {
  listCandidatesV2(filter: {searchQuery: $searchQuery}, offset: 0, limit: 20) {
    items {
      id
      firstName
      lastName
      preferredName
      appliedOnDates
      jobsAppliedTo {
        id
        title
        geography
      }
      referredByUser {
        id
        firstName
        lastName
      }
      completedStepsV2 {
        ... on RecordedCandidateRecruitmentStep {
          stepName
          recordedAt
        }
        ... on RecordedCandidateMonthlyRetentionStep {
          durationMonths
          recordedAt
        }
        ... on RecordedCandidateHourlyRetentionStep {
          durationHours
          recordedAt
        }
      }
    }
  }
}
    `;

/**
 * __useCandidateMergeSearchQuery__
 *
 * To run a query within a React component, call `useCandidateMergeSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateMergeSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateMergeSearchQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useCandidateMergeSearchQuery(baseOptions?: Apollo.QueryHookOptions<CandidateMergeSearchQuery, CandidateMergeSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidateMergeSearchQuery, CandidateMergeSearchQueryVariables>(CandidateMergeSearchDocument, options);
      }
export function useCandidateMergeSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidateMergeSearchQuery, CandidateMergeSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidateMergeSearchQuery, CandidateMergeSearchQueryVariables>(CandidateMergeSearchDocument, options);
        }
export type CandidateMergeSearchQueryHookResult = ReturnType<typeof useCandidateMergeSearchQuery>;
export type CandidateMergeSearchLazyQueryHookResult = ReturnType<typeof useCandidateMergeSearchLazyQuery>;
export type CandidateMergeSearchQueryResult = Apollo.QueryResult<CandidateMergeSearchQuery, CandidateMergeSearchQueryVariables>;