export function serializeBooleanQueryParam(
  value: boolean | null | undefined
): string | null {
  switch (value) {
    case true:
      return "true";
    case false:
      return "false";
    default:
      return null;
  }
}

export function parseBooleanQueryParam(
  value: string | null
): boolean | null | undefined {
  switch (value) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return null;
  }
}
