/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";

interface PipelineTableCellProps {
  children?: ReactNode;
  leftAlign?: boolean;
}

export function PipelineTableCell({
  children,
  leftAlign = false,
}: PipelineTableCellProps): JSX.Element {
  return (
    <td
      css={(theme: AppTheme) => css`
        ${leftAlign
          ? css`
              text-align: left;
            `
          : css`
              text-align: center;
            `};
        padding-top: ${theme.spacing(1)};
      `}
    >
      {children}
    </td>
  );
}
