/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useForm, useWatch } from "react-hook-form";

import { Form } from "@rewards-web/shared/components/form";
import { TextField } from "@rewards-web/shared/components/text-field";
import {
  CaMailingAddress,
  CaProvinceCode,
  MailingAddressCountryCode,
  Maybe,
  UsMailingAddress,
  UsStateAbbreviation,
} from "@rewards-web/shared/graphql-types";
import { useNavigationBlockingPrompt } from "@rewards-web/shared/hooks/use-navigation-blocking-prompt";
import { getCharactersRemainingText } from "@rewards-web/shared/lib/characters-remaining-text";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { FormFieldOutline } from "../../../../../../../shared/components/content-container";
import { PageCardActions } from "../../../../../../../shared/components/page-card/page-card-actions";
import { PageCardContent } from "../../../../../../../shared/components/page-card/page-card-content";
import { SettingsAddressFields } from "../../../../../../../shared/modules/settings/fields/settings-address-fields";
import { useAdminAppAuthenticatedOrganizationConfigQuery } from "../../../../../admin-app-authenticated-organization-config.generated";

const MAX_COMPANY_NAME_LENGTH = 50;

export interface AgencyCommunicationFormValues {
  address1: string;
  address2: string;
  city: string;
  country: MailingAddressCountryCode | "";
  province: CaProvinceCode | "";
  state: UsStateAbbreviation | "";
  companyName: string;
  zipCode: string;
  postalCode: string;
}

interface AgencyCommunicationFormProps {
  onSubmit(values: AgencyCommunicationFormValues): Promise<void>;
  agencyName: string | undefined;
  organizationMailingAddress: Maybe<
    | ({ __typename?: "CAMailingAddress" } & Pick<
        CaMailingAddress,
        | "countryCode"
        | "addressLine1"
        | "addressLine2"
        | "city"
        | "provinceCode"
        | "postalCode"
      >)
    | ({ __typename?: "USMailingAddress" } & Pick<
        UsMailingAddress,
        | "countryCode"
        | "addressLine1"
        | "addressLine2"
        | "city"
        | "stateAbbreviation"
        | "zipCode"
      >)
    | undefined
  >;
}

export function AgencyCommunicationForm({
  organizationMailingAddress,
  agencyName,
  onSubmit,
}: AgencyCommunicationFormProps): JSX.Element {
  const {
    control,
    handleSubmit,
    register,
    formState: { isSubmitting, errors, isDirty },
  } = useForm<AgencyCommunicationFormValues>({
    defaultValues: {
      address1: organizationMailingAddress?.addressLine1 ?? "",
      address2: organizationMailingAddress?.addressLine2 ?? "",
      city: organizationMailingAddress?.city ?? "",
      companyName: agencyName ?? "",
      country: organizationMailingAddress?.countryCode ?? "",
      province:
        organizationMailingAddress?.__typename === "CAMailingAddress"
          ? organizationMailingAddress.provinceCode ?? ""
          : "",
      state:
        organizationMailingAddress?.__typename === "USMailingAddress"
          ? organizationMailingAddress.stateAbbreviation ?? ""
          : "",
      postalCode:
        organizationMailingAddress?.__typename === "CAMailingAddress"
          ? organizationMailingAddress.postalCode ?? ""
          : "",
      zipCode:
        organizationMailingAddress?.__typename === "USMailingAddress"
          ? organizationMailingAddress.zipCode ?? ""
          : "",
    },
  });

  useNavigationBlockingPrompt(
    "Are you sure you want to leave this page? You will lose all unsaved changes.",
    isDirty
  );

  const companyName = useWatch({ control, name: "companyName" });

  const organizationWhiteLabelConfigQuery = useAdminAppAuthenticatedOrganizationConfigQuery();
  const rewardsProgramShortName =
    organizationWhiteLabelConfigQuery.data?.getMyRewardsOrganization
      ?.whiteLabelConfig?.rewardsProgramShortName ?? "Caribou Rewards";

  return (
    <Form onSubmit={handleSubmit(onSubmit)} submitting={isSubmitting}>
      <PageCardContent maxWidth="800px">
        <FormFieldOutline
          label="Contact information"
          name="contact-information"
          description={`${rewardsProgramShortName} will send emails to all staff,
        on behalf of your agency. What contact information should be provided on those emails?`}
        >
          <div
            css={(theme: AppTheme) => css`
              padding: ${theme.spacing(1)};
            `}
          >
            <TextField
              label="Legal Business Name"
              error={errors.companyName}
              disableAutocomplete
              helperText={getCharactersRemainingText(
                companyName,
                MAX_COMPANY_NAME_LENGTH
              )}
              {...register("companyName", {
                required: "Legal business name is required",
                minLength: {
                  value: 2,
                  message: "Legal business name must be at least 2 characters",
                },
                validate: (value) => {
                  if (value && value.length > MAX_COMPANY_NAME_LENGTH) {
                    return getCharactersRemainingText(
                      value,
                      MAX_COMPANY_NAME_LENGTH
                    );
                  }
                },
              })}
            />
            <SettingsAddressFields control={control} />
          </div>
        </FormFieldOutline>
      </PageCardContent>
      <PageCardActions />
    </Form>
  );
}
