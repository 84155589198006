/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";

interface TableHeaderProps {
  children?: ReactNode;
  divider?: boolean;
  rightDivider?: boolean;
  topDivider?: boolean;
  align?: "left" | "right" | "center";
  size?: "small" | "normal";
  noWrap?: boolean;
}

export function TableHeader({
  divider = true,
  topDivider = false,
  rightDivider = false,
  align = "left",
  size = "normal",
  noWrap,
  children,
}: TableHeaderProps): JSX.Element {
  return (
    <th
      css={(theme: AppTheme) => css`
        ${divider &&
        css`
          border-bottom: 1px solid #dfe4eb;
        `}
        ${topDivider &&
        css`
          border-top: 1px solid #dfe4eb;
        `}
        ${rightDivider &&
        css`
          border-right: 1px solid #dfe4eb;
        `}

        padding: ${theme.spacing(2)};
        &:first-of-type {
          padding-left: 0px;
        }
        &:last-of-type {
          padding-right: 0px;
        }

        text-align: ${align};

        ${size === "small" &&
        css`
          width: 10px;
        `}

        ${noWrap &&
        css`
          white-space: nowrap;
        `}
      `}
    >
      {children}
    </th>
  );
}
