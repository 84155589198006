import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteDrawMutationVariables = Types.Exact<{
  drawId: Types.Scalars['ID'];
}>;


export type DeleteDrawMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'deleteDraw'>
);


export const DeleteDrawDocument = gql`
    mutation DeleteDraw($drawId: ID!) {
  deleteDraw(drawId: $drawId)
}
    `;
export type DeleteDrawMutationFn = Apollo.MutationFunction<DeleteDrawMutation, DeleteDrawMutationVariables>;

/**
 * __useDeleteDrawMutation__
 *
 * To run a mutation, you first call `useDeleteDrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDrawMutation, { data, loading, error }] = useDeleteDrawMutation({
 *   variables: {
 *      drawId: // value for 'drawId'
 *   },
 * });
 */
export function useDeleteDrawMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDrawMutation, DeleteDrawMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDrawMutation, DeleteDrawMutationVariables>(DeleteDrawDocument, options);
      }
export type DeleteDrawMutationHookResult = ReturnType<typeof useDeleteDrawMutation>;
export type DeleteDrawMutationResult = Apollo.MutationResult<DeleteDrawMutation>;
export type DeleteDrawMutationOptions = Apollo.BaseMutationOptions<DeleteDrawMutation, DeleteDrawMutationVariables>;