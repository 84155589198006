import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RecognitionPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RecognitionPageDataQuery = (
  { __typename?: 'Query' }
  & { organization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'launched' | 'recognitionBudgetsEnabled'>
  ), myIdentity?: Types.Maybe<(
    { __typename?: 'Identity' }
    & Pick<Types.Identity, 'id'>
    & { roles: Array<(
      { __typename?: 'IdentityRole' }
      & Pick<Types.IdentityRole, 'id' | 'roleName' | 'active'>
    )> }
  )>, categoriesWithDefaultAmount: Array<(
    { __typename?: 'RecognitionCategory' }
    & Pick<Types.RecognitionCategory, 'id'>
  )>, myAdmin?: Types.Maybe<(
    { __typename?: 'RewardsAdminUser' }
    & Pick<Types.RewardsAdminUser, 'id'>
    & { permissions: { __typename?: 'RewardsAdminFullAccessPermissions' } | (
      { __typename?: 'RewardsAdminRestrictedAccessPermissions' }
      & Pick<Types.RewardsAdminRestrictedAccessPermissions, 'type'>
      & { permissionsV2?: Types.Maybe<(
        { __typename?: 'RewardsAdminRestrictedAccessPermissionsMapV2' }
        & { recognitionPoints?: Types.Maybe<(
          { __typename?: 'RewardsAdminRestrictedAccessRecognitionPointsPermissions' }
          & Pick<Types.RewardsAdminRestrictedAccessRecognitionPointsPermissions, 'canOverrideRecognitionCategoryDefaultAmounts' | 'canOverrideBudget'>
        )> }
      )> }
    ) }
  )> }
);


export const RecognitionPageDataDocument = gql`
    query RecognitionPageData {
  organization: getMyRewardsOrganization {
    id
    launched
    recognitionBudgetsEnabled
  }
  myIdentity: getMyIdentity {
    id
    roles {
      id
      roleName
      active
    }
  }
  categoriesWithDefaultAmount: getMyRewardsOrganizationRecognitionCategories(
    filter: {disableSendFromManagers: false, hasDefaultAmount: true}
  ) {
    id
  }
  myAdmin: getMyRewardsAdminUser {
    id
    permissions {
      ... on RewardsAdminRestrictedAccessPermissions {
        type
        permissionsV2 {
          recognitionPoints {
            canOverrideRecognitionCategoryDefaultAmounts
            canOverrideBudget
          }
        }
      }
    }
  }
}
    `;

/**
 * __useRecognitionPageDataQuery__
 *
 * To run a query within a React component, call `useRecognitionPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecognitionPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecognitionPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecognitionPageDataQuery(baseOptions?: Apollo.QueryHookOptions<RecognitionPageDataQuery, RecognitionPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecognitionPageDataQuery, RecognitionPageDataQueryVariables>(RecognitionPageDataDocument, options);
      }
export function useRecognitionPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecognitionPageDataQuery, RecognitionPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecognitionPageDataQuery, RecognitionPageDataQueryVariables>(RecognitionPageDataDocument, options);
        }
export type RecognitionPageDataQueryHookResult = ReturnType<typeof useRecognitionPageDataQuery>;
export type RecognitionPageDataLazyQueryHookResult = ReturnType<typeof useRecognitionPageDataLazyQuery>;
export type RecognitionPageDataQueryResult = Apollo.QueryResult<RecognitionPageDataQuery, RecognitionPageDataQueryVariables>;