import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EmployeesListQueryVariables = Types.Exact<{
  searchQuery?: Types.Maybe<Types.Scalars['String']>;
  limit: Types.Scalars['Int'];
  offset: Types.Scalars['Int'];
  branchIds?: Types.Maybe<Array<Types.Maybe<Types.Scalars['ID']>> | Types.Maybe<Types.Scalars['ID']>>;
  active?: Types.Maybe<Types.Scalars['Boolean']>;
  providedPersonalInfo?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type EmployeesListQuery = (
  { __typename?: 'Query' }
  & { listRewardsUsers: (
    { __typename?: 'ListRewardsUsersResponse' }
    & Pick<Types.ListRewardsUsersResponse, 'total'>
    & { items: Array<(
      { __typename?: 'RewardsUser' }
      & Pick<Types.RewardsUser, 'id' | 'firstName' | 'lastName' | 'workEmail' | 'workPhoneNumber' | 'active'>
      & { personalContactInfo?: Types.Maybe<(
        { __typename: 'RewardsUserPersonalContactInfoData' }
        & Pick<Types.RewardsUserPersonalContactInfoData, 'id' | 'email' | 'phoneNumber'>
      ) | (
        { __typename: 'RewardsUserPersonalContactInfoSuppressed' }
        & Pick<Types.RewardsUserPersonalContactInfoSuppressed, 'id'>
      )>, branch?: Types.Maybe<(
        { __typename?: 'RewardsOrganizationBranch' }
        & Pick<Types.RewardsOrganizationBranch, 'id' | 'name'>
      )>, manager?: Types.Maybe<(
        { __typename?: 'RewardsAdminUser' }
        & Pick<Types.RewardsAdminUser, 'id' | 'firstName' | 'lastName'>
      )> }
    )> }
  ) }
);


export const EmployeesListDocument = gql`
    query EmployeesList($searchQuery: String, $limit: Int!, $offset: Int!, $branchIds: [ID], $active: Boolean, $providedPersonalInfo: Boolean) {
  listRewardsUsers(
    searchQuery: $searchQuery
    limit: $limit
    offset: $offset
    filter: {branchIds: $branchIds, active: $active, providedPersonalInfo: $providedPersonalInfo}
  ) {
    total
    items {
      id
      firstName
      lastName
      personalContactInfo {
        __typename
        ... on RewardsUserPersonalContactInfoData {
          id
          email
          phoneNumber
        }
        ... on RewardsUserPersonalContactInfoSuppressed {
          id
        }
      }
      workEmail
      workPhoneNumber
      active
      branch {
        id
        name
      }
      manager {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useEmployeesListQuery__
 *
 * To run a query within a React component, call `useEmployeesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesListQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      branchIds: // value for 'branchIds'
 *      active: // value for 'active'
 *      providedPersonalInfo: // value for 'providedPersonalInfo'
 *   },
 * });
 */
export function useEmployeesListQuery(baseOptions: Apollo.QueryHookOptions<EmployeesListQuery, EmployeesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeesListQuery, EmployeesListQueryVariables>(EmployeesListDocument, options);
      }
export function useEmployeesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeesListQuery, EmployeesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeesListQuery, EmployeesListQueryVariables>(EmployeesListDocument, options);
        }
export type EmployeesListQueryHookResult = ReturnType<typeof useEmployeesListQuery>;
export type EmployeesListLazyQueryHookResult = ReturnType<typeof useEmployeesListLazyQuery>;
export type EmployeesListQueryResult = Apollo.QueryResult<EmployeesListQuery, EmployeesListQueryVariables>;