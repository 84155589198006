import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetMyAdminMfaStatusQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMyAdminMfaStatusQuery = (
  { __typename?: 'Query' }
  & { mfaStatus: (
    { __typename?: 'GetMyRewardsAdminMfaStatusResponse' }
    & Pick<Types.GetMyRewardsAdminMfaStatusResponse, 'id' | 'smsMFARequired' | 'smsNumberVerified'>
  ) }
);


export const GetMyAdminMfaStatusDocument = gql`
    query GetMyAdminMfaStatus {
  mfaStatus: getMyRewardsAdminMfaStatus {
    id
    smsMFARequired
    smsNumberVerified
  }
}
    `;

/**
 * __useGetMyAdminMfaStatusQuery__
 *
 * To run a query within a React component, call `useGetMyAdminMfaStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyAdminMfaStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyAdminMfaStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyAdminMfaStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetMyAdminMfaStatusQuery, GetMyAdminMfaStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyAdminMfaStatusQuery, GetMyAdminMfaStatusQueryVariables>(GetMyAdminMfaStatusDocument, options);
      }
export function useGetMyAdminMfaStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyAdminMfaStatusQuery, GetMyAdminMfaStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyAdminMfaStatusQuery, GetMyAdminMfaStatusQueryVariables>(GetMyAdminMfaStatusDocument, options);
        }
export type GetMyAdminMfaStatusQueryHookResult = ReturnType<typeof useGetMyAdminMfaStatusQuery>;
export type GetMyAdminMfaStatusLazyQueryHookResult = ReturnType<typeof useGetMyAdminMfaStatusLazyQuery>;
export type GetMyAdminMfaStatusQueryResult = Apollo.QueryResult<GetMyAdminMfaStatusQuery, GetMyAdminMfaStatusQueryVariables>;