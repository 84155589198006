import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RedrawWinnerMutationVariables = Types.Exact<{
  drawId: Types.Scalars['ID'];
  winnerId: Types.Scalars['ID'];
}>;


export type RedrawWinnerMutation = (
  { __typename?: 'Mutation' }
  & { redrawWinner?: Types.Maybe<(
    { __typename?: 'Draw' }
    & Pick<Types.Draw, 'id'>
  )> }
);


export const RedrawWinnerDocument = gql`
    mutation redrawWinner($drawId: ID!, $winnerId: ID!) {
  redrawWinner(drawId: $drawId, winnerId: $winnerId) {
    id
  }
}
    `;
export type RedrawWinnerMutationFn = Apollo.MutationFunction<RedrawWinnerMutation, RedrawWinnerMutationVariables>;

/**
 * __useRedrawWinnerMutation__
 *
 * To run a mutation, you first call `useRedrawWinnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedrawWinnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redrawWinnerMutation, { data, loading, error }] = useRedrawWinnerMutation({
 *   variables: {
 *      drawId: // value for 'drawId'
 *      winnerId: // value for 'winnerId'
 *   },
 * });
 */
export function useRedrawWinnerMutation(baseOptions?: Apollo.MutationHookOptions<RedrawWinnerMutation, RedrawWinnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RedrawWinnerMutation, RedrawWinnerMutationVariables>(RedrawWinnerDocument, options);
      }
export type RedrawWinnerMutationHookResult = ReturnType<typeof useRedrawWinnerMutation>;
export type RedrawWinnerMutationResult = Apollo.MutationResult<RedrawWinnerMutation>;
export type RedrawWinnerMutationOptions = Apollo.BaseMutationOptions<RedrawWinnerMutation, RedrawWinnerMutationVariables>;