/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Card as MuiCard } from "@material-ui/core";
import { forwardRef, ReactNode } from "react";

import { StylableProps } from "../../types";

export interface CardProps extends StylableProps {
  /**
   * The contents of the card
   */
  children: ReactNode;

  /**
   * Whether or not to show no elevation, an outline, or be elevated with a drop shadow
   */
  variant?: "flat" | "outlined" | "elevation";

  borderRadius?: number;

  onClick?(): void;

  backgroundColor?: "white" | "grey";

  disableHorizontalScroll?: boolean;
}

/**
 * A card is a surface that displays content on a single topic
 */
export const Card = forwardRef(
  (
    {
      variant = "elevation",
      children,
      className,
      borderRadius,
      onClick,
      backgroundColor = "white",
      disableHorizontalScroll = false,
    }: CardProps,
    ref
  ): JSX.Element => {
    return (
      <MuiCard
        ref={ref}
        onClick={onClick}
        variant={variant === "flat" ? "elevation" : variant}
        elevation={0}
        className={className}
        css={css`
          ${!disableHorizontalScroll &&
          css`
            overflow-x: auto;
          `}
          ${typeof borderRadius === "number" &&
          css`
            border-radius: ${borderRadius}px;
          `}
          ${variant === "elevation" &&
          css`
            box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.05);
          `}
          ${backgroundColor === "grey" &&
          css`
            background-color: rgba(223, 228, 235, 0.3);
          `}
        `}
      >
        {children}
      </MuiCard>
    );
  }
);
