import { Control, FieldValues, get, Path, useFormState } from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";
import { isURL } from "@rewards-web/shared/lib/is-url";

interface SettingsShortOrganizationURLFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  isRequired?: boolean;
}

export function SettingsShortOrganizationURLField<T extends FieldValues>({
  control,
  name,
  isRequired,
}: SettingsShortOrganizationURLFieldProps<T>) {
  const formState = useFormState({ control });

  return (
    <TextField
      label="Organization URL / link (Optional)"
      disableAutocomplete
      fixLabelToTop
      error={get(formState.errors, name)}
      placeholder="https://your-website.com"
      {...control.register(name, {
        required: isRequired,
        validate: (value) => {
          if (value && !isURL(value)) {
            return "Please enter a valid website URL / Link";
          }
        },
      })}
    />
  );
}
