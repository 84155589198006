import * as Types from '@rewards-web/shared/graphql-types';

import { OnboardingProfileDataFragmentFragment } from './onboarding-profile-data-fragment.generated';
import { gql } from '@apollo/client';
import { OnboardingProfileDataFragmentFragmentDoc } from './onboarding-profile-data-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type OnboardingProfileDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OnboardingProfileDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & OnboardingProfileDataFragmentFragment
  ) }
);


export const OnboardingProfileDataDocument = gql`
    query OnboardingProfileData {
  getMyRewardsOrganization {
    ...OnboardingProfileDataFragment
  }
}
    ${OnboardingProfileDataFragmentFragmentDoc}`;

/**
 * __useOnboardingProfileDataQuery__
 *
 * To run a query within a React component, call `useOnboardingProfileDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingProfileDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingProfileDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingProfileDataQuery(baseOptions?: Apollo.QueryHookOptions<OnboardingProfileDataQuery, OnboardingProfileDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingProfileDataQuery, OnboardingProfileDataQueryVariables>(OnboardingProfileDataDocument, options);
      }
export function useOnboardingProfileDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingProfileDataQuery, OnboardingProfileDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingProfileDataQuery, OnboardingProfileDataQueryVariables>(OnboardingProfileDataDocument, options);
        }
export type OnboardingProfileDataQueryHookResult = ReturnType<typeof useOnboardingProfileDataQuery>;
export type OnboardingProfileDataLazyQueryHookResult = ReturnType<typeof useOnboardingProfileDataLazyQuery>;
export type OnboardingProfileDataQueryResult = Apollo.QueryResult<OnboardingProfileDataQuery, OnboardingProfileDataQueryVariables>;