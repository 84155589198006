/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode, useEffect } from "react";

import { Alert } from "@rewards-web/shared/components/alert";

import { useTrack } from "../analytics";
import { useAppFeatureFlag } from "../feature-flag";

interface MaintenanceModeWrapperProps {
  children: ReactNode;
}

/**
 * Shows an alert and blocks the app from loading.
 *
 * This should be placed above the `ApolloProvider` to ensure
 * that the API is not invoked.
 */
export function MaintenanceModeWrapper({
  children,
}: MaintenanceModeWrapperProps) {
  const track = useTrack();
  const maintenanceModeEnabled = useAppFeatureFlag(
    "rewards-maintenance-mode-enabled-perm",
    {
      // set the default to false, so if the feature flagging service is down,
      // users can still potentially access the API (if the API is up)
      default: false,
    }
  );

  useEffect(() => {
    if (maintenanceModeEnabled) {
      track("Viewed maintenance mode screen");
    }
  }, [track, maintenanceModeEnabled]);

  if (maintenanceModeEnabled) {
    return (
      <div
        css={css`
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <Alert
          message="The app is down temporarily for maintenance. Come back soon!"
          severity="info"
        />
      </div>
    );
  } else {
    return <>{children}</>;
  }
}
