import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type BulkMarkRedemptionsAsGenericGiftCardSentMutationVariables = Types.Exact<{
  redemptionIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type BulkMarkRedemptionsAsGenericGiftCardSentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'bulkMarkRedemptionsAsGenericGiftCardSent'>
);


export const BulkMarkRedemptionsAsGenericGiftCardSentDocument = gql`
    mutation BulkMarkRedemptionsAsGenericGiftCardSent($redemptionIds: [ID!]!) {
  bulkMarkRedemptionsAsGenericGiftCardSent(redemptionIds: $redemptionIds)
}
    `;
export type BulkMarkRedemptionsAsGenericGiftCardSentMutationFn = Apollo.MutationFunction<BulkMarkRedemptionsAsGenericGiftCardSentMutation, BulkMarkRedemptionsAsGenericGiftCardSentMutationVariables>;

/**
 * __useBulkMarkRedemptionsAsGenericGiftCardSentMutation__
 *
 * To run a mutation, you first call `useBulkMarkRedemptionsAsGenericGiftCardSentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkMarkRedemptionsAsGenericGiftCardSentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkMarkRedemptionsAsGenericGiftCardSentMutation, { data, loading, error }] = useBulkMarkRedemptionsAsGenericGiftCardSentMutation({
 *   variables: {
 *      redemptionIds: // value for 'redemptionIds'
 *   },
 * });
 */
export function useBulkMarkRedemptionsAsGenericGiftCardSentMutation(baseOptions?: Apollo.MutationHookOptions<BulkMarkRedemptionsAsGenericGiftCardSentMutation, BulkMarkRedemptionsAsGenericGiftCardSentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkMarkRedemptionsAsGenericGiftCardSentMutation, BulkMarkRedemptionsAsGenericGiftCardSentMutationVariables>(BulkMarkRedemptionsAsGenericGiftCardSentDocument, options);
      }
export type BulkMarkRedemptionsAsGenericGiftCardSentMutationHookResult = ReturnType<typeof useBulkMarkRedemptionsAsGenericGiftCardSentMutation>;
export type BulkMarkRedemptionsAsGenericGiftCardSentMutationResult = Apollo.MutationResult<BulkMarkRedemptionsAsGenericGiftCardSentMutation>;
export type BulkMarkRedemptionsAsGenericGiftCardSentMutationOptions = Apollo.BaseMutationOptions<BulkMarkRedemptionsAsGenericGiftCardSentMutation, BulkMarkRedemptionsAsGenericGiftCardSentMutationVariables>;