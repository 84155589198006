/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useForm } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { isPhoneNumber } from "@rewards-web/shared/lib/is-phone-number";
import { stripNonNumericCharacters } from "@rewards-web/shared/lib/strip-non-numeric-characters";
import { AppTheme } from "@rewards-web/shared/style/types";

import { MfaPageContainer } from "../mfa-page-container";

const CONTACT_EMAIL = "help+admin@caribou.care";

interface EnableSmsMfaFormValues {
  phoneNumber: string;
}

export interface SubmitMfaPhoneNumberPageProps {
  organizationName: string;
  onSubmit(values: EnableSmsMfaFormValues): Promise<void>;
}

export function SubmitMfaPhoneNumberPage({
  organizationName,
  onSubmit,
}: SubmitMfaPhoneNumberPageProps) {
  const form = useForm<EnableSmsMfaFormValues>({
    defaultValues: {
      phoneNumber: "",
    },
  });

  return (
    <MfaPageContainer>
      <Typography
        variant="body"
        css={(theme: AppTheme) => css`
          color: black;
          font-weight: 500;
          margin-bottom: ${theme.spacing(4)};
        `}
      >
        {organizationName} has enabled two-factor authentication on your
        account. Please enter your cell phone number to get set up.
      </Typography>

      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <TextField
          type="tel"
          label="Cell Phone Number"
          hideLabel
          placeholder="Cell Phone Number"
          autoFocus
          error={form.formState.errors.phoneNumber}
          {...form.register("phoneNumber", {
            required: "You must enter your phone number.",
            validate: (value: string) => {
              if (value && !isPhoneNumber(stripNonNumericCharacters(value))) {
                return "This is not a valid phone number";
              }
            },
          })}
        />

        <Button
          loading={form.formState.isSubmitting}
          color="primary"
          label="Verify"
          type="submit"
        />
      </Form>

      <Typography
        variant="body"
        css={(theme: AppTheme) => css`
          color: black;
          font-weight: 500;
          margin-top: ${theme.spacing(4)};
        `}
      >
        Need help? Email us at{" "}
        <a href={`mailto:${encodeURI(CONTACT_EMAIL)}`}>{CONTACT_EMAIL}</a>.
      </Typography>
    </MfaPageContainer>
  );
}
