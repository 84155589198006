/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { MutableRefObject } from "react";
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Path,
} from "react-hook-form";

import { Card } from "@rewards-web/shared/components/card/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PermissionsTable } from "../../../../shared/components/permissions-table";
import { useAdminAppAuthenticatedOrganizationConfigQuery } from "../../admin-app-authenticated-organization-config.generated";
import { AccessLevelAccordion } from "../access-level-accordion";
import NotSelectedPermissionIcon from "../icons/not-selected-permission-icon";
import SelectedPermissionIcon from "../icons/selected-permission-icon";

interface AdminPermissionsProps<T extends FieldValues> {
  control: Control<T>;
  fieldNames: {
    permissions: Path<T>;
  };
  accessLevelRestricted: boolean;
  setAccessLevelRestricted(value: boolean): void;
  readOnly: boolean;
  permissionsRef?: MutableRefObject<HTMLDivElement | null>;
}

export function AdminPermissions<T extends FieldValues>({
  control,
  fieldNames,
  accessLevelRestricted,
  setAccessLevelRestricted,
  permissionsRef,
  readOnly,
}: AdminPermissionsProps<T>): JSX.Element {
  const theme = useTheme();

  const organizationConfigQuery = useAdminAppAuthenticatedOrganizationConfigQuery(
    {
      onError: reportError,
    }
  );
  const rewardsProgramShortName =
    organizationConfigQuery.data?.getMyRewardsOrganization?.whiteLabelConfig
      ?.rewardsProgramShortName ?? "Caribou Rewards";

  return (
    <Card
      css={(theme: AppTheme) => css`
        max-width: 861px;
        padding: ${theme.spacing(4)};
        margin-bottom: ${theme.spacing(3)};
      `}
      ref={permissionsRef}
    >
      <Typography
        variant="h3"
        component="h2"
        color="textPrimary"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(3)};
        `}
      >
        Permissions
      </Typography>

      <AccessLevelAccordion
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
          border-radius: 10px;
        `}
        expanded={!accessLevelRestricted}
        onClick={() => {
          setAccessLevelRestricted(false);
        }}
        startIcon={
          !accessLevelRestricted ? (
            <SelectedPermissionIcon color={theme.palette.primary.main} />
          ) : (
            <NotSelectedPermissionIcon color={theme.palette.text.primary} />
          )
        }
        title="Full Access"
        description="Gives this user unrestricted access to view
                      and edit all pages in the admin dashboard.
                      Also allows the user to create new admins."
        disabled={readOnly}
      />

      <AccessLevelAccordion
        css={css`
          border-radius: 10px;
        `}
        expanded={accessLevelRestricted}
        onClick={() => {
          setAccessLevelRestricted(true);
        }}
        startIcon={
          accessLevelRestricted ? (
            <SelectedPermissionIcon color={theme.palette.primary.main} />
          ) : (
            <NotSelectedPermissionIcon color={theme.palette.text.primary} />
          )
        }
        title="Restricted"
        description={`Limit a user's access to specific pages of the
                        ${rewardsProgramShortName} dashboard.`}
        disabled={readOnly}
      >
        <Controller
          control={control}
          name={fieldNames.permissions}
          rules={{
            validate: (value) => {
              if (Object.values(value).every((level) => !level)) {
                return "A user must have minimum view access to at least one page.";
              }
            },
          }}
          render={({ field, fieldState }) => (
            <PermissionsTable
              {...field}
              error={fieldState.error as FieldError | undefined}
              disabled={readOnly}
            />
          )}
        />
      </AccessLevelAccordion>
    </Card>
  );
}
