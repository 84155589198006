import { Alert } from "@rewards-web/shared/components/alert";
import {
  AlayaCareIntegration,
  HhaxIntegration,
} from "@rewards-web/shared/graphql-types";

import { AutoSyncIntegrationsTableDataQuery } from "./auto-sync-integrations-table-data.generated";
import { EmployeeAutoSyncIntegrationsTable } from "./employee-auto-sync-integrations-table";

interface EmployeeAutoSyncIntegrationsProps {
  data: AutoSyncIntegrationsTableDataQuery;
  disableEdit?: boolean;
  onClickSetUp(
    integration: Omit<HhaxIntegration, "autoSyncEnabled"> | AlayaCareIntegration
  ): void;
  onClickEdit(
    integration: Omit<HhaxIntegration, "autoSyncEnabled"> | AlayaCareIntegration
  ): void;
}

export function EmployeeAutoSyncIntegrations({
  data,
  disableEdit,
  onClickSetUp,
  onClickEdit,
}: EmployeeAutoSyncIntegrationsProps) {
  const { hhaxIntegrations, alayaCareIntegrations, organization } = data;
  const noIntegrations =
    hhaxIntegrations.length === 0 && alayaCareIntegrations.length === 0;

  return (
    <>
      {noIntegrations && (
        <Alert severity="info" message="No integrations have been enabled." />
      )}

      {hhaxIntegrations.length > 0 && (
        <EmployeeAutoSyncIntegrationsTable
          name="HHAeXchange Integrations"
          integrationList={hhaxIntegrations}
          disableSetUpButtons={organization.launched}
          disableEdit={disableEdit}
          onClickSetUp={onClickSetUp}
          onClickEdit={onClickEdit}
          organizationLaunched={organization.launched}
        />
      )}

      {alayaCareIntegrations.length > 0 && (
        <EmployeeAutoSyncIntegrationsTable
          name="Alayacare Integrations"
          integrationList={alayaCareIntegrations}
          disableSetUpButtons={organization.launched}
          disableEdit={disableEdit}
          onClickSetUp={onClickSetUp}
          onClickEdit={onClickEdit}
          organizationLaunched={organization.launched}
        />
      )}
    </>
  );
}
