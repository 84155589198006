/** @jsxImportSource @emotion/react */
import { useTheme, css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";

import FullAccessIcon from "../../icons/full-access-icon";

interface AdminAddedConfirmationModalProps {
  open: boolean;
  onClose(): void;
  adminFullName: string;
  adminEmail: string;
}

export function AdminAddedConfirmationModal({
  open,
  onClose,
  adminFullName,
  adminEmail,
}: AdminAddedConfirmationModalProps): JSX.Element {
  const theme = useTheme();
  return (
    <Modal width="500px" open={open} onClose={onClose}>
      <ModalTitle>
        <div
          css={css`
            text-align: center;
            width: 100%;
          `}
        >
          <FullAccessIcon color={theme.palette.primary.main} />
          <Typography
            align="center"
            variant="h2"
            css={css`
              margin-bottom: -${theme.spacing(2.5)};
            `}
          >
            Admin successfully invited
          </Typography>
        </div>
      </ModalTitle>
      <ModalContent>
        <Typography align="center" variant="body">
          User <strong>{adminFullName}</strong> with email{" "}
          <strong>{adminEmail}</strong> has been successfully invited.
        </Typography>
      </ModalContent>
      <ModalActions>
        <div
          css={css`
            text-align: center;
            width: 100%;
          `}
        >
          <Button
            css={css`
              width: 150px;
              height: 40px;
            `}
            color="primary"
            linkTo="/admins"
            label="Okay"
            size="small"
            width="auto"
          />
        </div>
      </ModalActions>
    </Modal>
  );
}
