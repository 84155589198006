import { ApolloError } from "@apollo/client";
import { useMemo } from "react";

import { Role as GqlRole } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";

import { useCognitoAuth } from "../cognito-auth";
import { useUserRoleQuery } from "./user-role.generated";

export type UseRoleData =
  | {
      role: "superuser";
    }
  | {
      role: "admin";
    };

export function useRole(): {
  loading: boolean;
  error: ApolloError | undefined;
  data: null | UseRoleData;
} {
  const { signedIn } = useCognitoAuth();
  const userRoleQuery = useUserRoleQuery({
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first",
    onError: reportError,
    skip: !signedIn,
  });

  const data = useMemo((): null | UseRoleData => {
    if (!userRoleQuery.data) {
      return null;
    }

    if (!userRoleQuery.data.myAssumedRole) {
      throw new Error(`Could not get authenticated user's identity`);
    }

    switch (userRoleQuery.data.myAssumedRole.role) {
      case GqlRole.RewardsSuperuser:
        return { role: "superuser" };
      case GqlRole.RewardsAdmin:
        return { role: "admin" };
      default:
        throw new Error(
          `Cannot use app with role ${userRoleQuery.data.myAssumedRole.role}`
        );
    }
  }, [userRoleQuery.data]);

  return {
    loading: userRoleQuery.loading,
    error: userRoleQuery.error,
    data,
  };
}
