/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import AddIcon from "@material-ui/icons/Add";

import { Button } from "@rewards-web/shared/components/button";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface CreateAdminButtonProps {
  disabled?: boolean;
}

export function CreateAdminButton(props: CreateAdminButtonProps) {
  return (
    <Button
      css={(theme: AppTheme) => css`
        height: fit-content;
        margin-bottom: ${theme.spacing(2)};
        float: right;
      `}
      color="primary"
      label="Add admin"
      size="medium"
      startIcon={<AddIcon />}
      width="auto"
      linkTo="/admins/add-new-admin"
      {...props}
    />
  );
}
