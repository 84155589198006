import { SVGProps } from "react";

export function JobStatsShareIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.037 0c-1.63 0-2.963 1.306-2.963 2.903 0 .326.056.641.157.935l-5.074 3.23a2.978 2.978 0 0 0-2.194-.971C1.333 6.097 0 7.403 0 9s1.333 2.903 2.963 2.903c.874 0 1.66-.375 2.204-.97l5.065 3.23a2.786 2.786 0 0 0-.158.934c0 1.597 1.333 2.903 2.963 2.903C14.667 18 16 16.694 16 15.097s-1.333-2.903-2.963-2.903c-.87 0-1.652.38-2.194.97l-5.074-3.23a2.851 2.851 0 0 0 0-1.868l5.064-3.23c.544.595 1.33.97 2.204.97C14.667 5.806 16 4.5 16 2.903S14.667 0 13.037 0Zm0 1.161c.988 0 1.778.774 1.778 1.742 0 .969-.79 1.742-1.778 1.742-.989 0-1.778-.773-1.778-1.742 0-.968.79-1.742 1.778-1.742ZM2.963 7.258c.988 0 1.778.774 1.778 1.742s-.79 1.742-1.778 1.742S1.185 9.968 1.185 9s.79-1.742 1.778-1.742Zm10.074 6.097c.988 0 1.778.773 1.778 1.742 0 .968-.79 1.742-1.778 1.742-.989 0-1.778-.774-1.778-1.742 0-.969.79-1.742 1.778-1.742Z"
        fill="#fff"
      />
    </svg>
  );
}
