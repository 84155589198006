import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
  TypographyTypeMap,
} from "@mui/material";
import { OverrideProps } from "@mui/material/OverridableComponent";
import { ReactNode } from "react";

import { StylableProps } from "@rewards-web/shared/types";

export type TypographyProps<
  D extends React.ElementType = "span"
> = StylableProps &
  OverrideProps<TypographyTypeMap, D> & {
    children?: ReactNode;
    component?: D;
    variant?: MuiTypographyProps["variant"];
    color?: MuiTypographyProps["color"];
    align?: MuiTypographyProps["align"];
    display?: MuiTypographyProps["display"];
  };

export function Typography<D extends React.ElementType = "span">({
  className,
  children,
  component,
  variant = "body",
  color,
  align,
  display,
  ...overrides
}: TypographyProps<D>) {
  return (
    <MuiTypography
      {...overrides}
      className={className}
      component={component}
      variant={variant}
      color={color}
      align={align}
      display={display}
      variantMapping={{
        footnote: "p",
        subtitle: "p",
        caption: "p",
        body: "p",
      }}
      letterSpacing={0}
    >
      {children}
    </MuiTypography>
  );
}
