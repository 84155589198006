import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { reportError } from "../error";
import {
  FeatureFlagContext,
  FeatureFlagContextValue,
  FeatureFlagUser,
} from "./context";
import { createLaunchDarklyFactory } from "./lib";

export * from "./hooks";
export * from "./lib";

interface LaunchDarklyFeatureFlagProviderProps {
  children: ReactNode;
  launchDarklyFactory?: ReturnType<typeof createLaunchDarklyFactory>;
  appName: string;
}

export function LaunchDarklyFeatureFlagProvider({
  children,
  launchDarklyFactory,
  appName,
}: LaunchDarklyFeatureFlagProviderProps): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const [user, setUser] = useState<FeatureFlagUser | null>(null);

  const handleSetUser = useCallback(
    (nextUser: FeatureFlagUser | null) => {
      if (user !== nextUser) {
        setInitialized(false);
        setUser(nextUser);
      }
    },
    [user]
  );

  const userClient = useMemo(() => {
    if (!launchDarklyFactory || !user) {
      return null;
    }

    // TODO log or error

    return launchDarklyFactory.client({
      ...user.attributes,
      kind: "user",
      key: user.userId,
    });
  }, [user, launchDarklyFactory]);

  const appClient = useMemo(() => {
    if (!launchDarklyFactory) {
      return null;
    }

    return launchDarklyFactory.client({
      kind: "app",
      key: appName,
      platform: "web",
    });
  }, [launchDarklyFactory, appName]);

  useEffect(() => {
    if (!launchDarklyFactory) {
      reportError(
        new Error(
          "Launch darkly feature flagging disabled, since env var is not set"
        )
      );
    }
  }, [launchDarklyFactory]);

  useEffect(() => {
    if (userClient && appClient) {
      Promise.all(
        [userClient, appClient].map((client) => client?.waitForInitialization())
      )
        .catch((error) => {
          reportError(error);
        })
        .finally(() => {
          setInitialized(true);
        });
    } else {
      setInitialized(true);
    }
  }, [userClient, appClient]);

  const getUserFlagEnabled = useCallback(
    (key: string): boolean => {
      if (!userClient || !initialized) {
        // default flag to false if no client is provided
        return false;
      }

      return userClient.variation(key, false);
    },
    [userClient, initialized]
  );

  const getAppFlagEnabled = useCallback(
    (key: string, opts?: { default?: boolean }): boolean => {
      if (!appClient || !initialized) {
        // default flag to false if no client is provided
        return false;
      }

      return appClient.variation(key, opts?.default ?? false);
    },
    [appClient, initialized]
  );

  const contextValue = useMemo(
    (): FeatureFlagContextValue => ({
      initialized,
      getFlagEnabled: getUserFlagEnabled,
      getAppFlagEnabled,
      user,
      setUser: handleSetUser,
    }),
    [initialized, user, handleSetUser, getUserFlagEnabled, getAppFlagEnabled]
  );

  return (
    <FeatureFlagContext.Provider value={contextValue}>
      {children}
    </FeatureFlagContext.Provider>
  );
}
