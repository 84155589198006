/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";

export interface ModalLoadingStateProps {
  width?: string;
}

export function ModalLoadingState({
  width,
}: ModalLoadingStateProps): JSX.Element {
  return (
    <ModalContent
      css={css`
        height: 300px;
        ${width &&
        css`
          width: ${width};
        `}
      `}
    >
      <PageLoadingState />
    </ModalContent>
  );
}
