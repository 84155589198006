/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { StylableProps } from "@rewards-web/shared/types";

interface TableBodyProps extends StylableProps {
  children?: ReactNode;
  disabled?: boolean;
}

export function TableBody({
  className,
  children,
  disabled = false,
}: TableBodyProps): JSX.Element {
  return (
    <tbody
      className={className}
      css={css`
        ${disabled &&
        css`
          background-color: #eee;
          opacity: 0.6;
          pointer-events: none;
        `}
      `}
    >
      {children}
    </tbody>
  );
}
