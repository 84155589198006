/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import {
  faExclamationCircle,
  faUsers,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faFileDownload,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { Form } from "@rewards-web/shared/components/form";
import { SelectField } from "@rewards-web/shared/components/select-field";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { getCharactersRemainingText } from "@rewards-web/shared/lib/characters-remaining-text";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { FileInputField } from "../../../../../shared/components/file-input-field";
import { parseCsv } from "../../../../../shared/lib/parse-csv";
import { ExportEmployeesModal } from "../../../employees/accounts/export-employees-modal";
import { GiveRecognitionPageDataQuery } from "../give-recognition-page-data.generated";
import { GiveRecognitionTabs } from "../give-recognition-tabs";
import { RecognitionCategoriesDataQuery } from "../recognition-categories-data.generated";
import { BulkRecognitionFilePreviewModal } from "./bulk-recognition-file-preview-modal";
import recognitionTemplateFile from "./bulk-recognition-template.csv";
import { useUploadBulkRecognitionFileMutation } from "./upload-bulk-recognition-file.generated";

const MAX_SENDER_NAME_LENGTH = 50;
const TEXT_FIELD_MAX_WIDTH = 600;
const MAX_NOTES_LENGTH = 300;
const TEMPLATE_FILE_NAME = "bulk-recognition-template.csv";
const SUPPORT_EMAIL = "help@caribou.care";
const SUPPORT_ARTICLE_URL =
  "https://support.caribou.care/hc/en-us/articles/14639157632407-Giving-out-recognition-points";

export interface UploadBulkFileFormValues {
  categoryId: string | undefined;
  from: string;
  file: File | null;
  notes: string;
}

export interface UploadBulkFilePreviewValues extends UploadBulkFileFormValues {
  categoryName?: string;
  totalRecognitions: number;
  totalPoints: number;
}

interface BulkRecognitionCsvRow {
  "First Name": string;
  "Last Name": string;
  "Phone Number": string;
  Email: string;
  Points: string;
  Message: string;
}

const DEFAULT_FORM_VALUES: UploadBulkFileFormValues = {
  categoryId: "",
  from: "",
  file: null,
  notes: "",
};

interface UploadBulkRecognitionFileFormProps {
  data: GiveRecognitionPageDataQuery;
  categoriesData: RecognitionCategoriesDataQuery;
}

export function UploadBulkRecognitionFileForm({
  data,
  categoriesData,
}: UploadBulkRecognitionFileFormProps): JSX.Element {
  const theme = useTheme();
  const snackbar = useSnackbar();
  const track = useTrack();
  const navigate = useNavigate();

  const form = useForm<UploadBulkFileFormValues>({
    mode: "onChange",
    defaultValues: { ...DEFAULT_FORM_VALUES },
  });

  const {
    control,
    register,
    formState: { errors },
  } = form;

  const [
    uploadRecognitionFile,
    { loading: isSubmitting },
  ] = useUploadBulkRecognitionFileMutation();

  const categoryIdValue = useWatch({ control, name: "categoryId" });
  const [showEmployeeExportModal, setShowEmployeeExportModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [
    previewValues,
    setPreviewValues,
  ] = useState<UploadBulkFilePreviewValues>();
  // requestId is used by the API to enforce idempotency
  const [requestId, setRequestId] = useState("");

  const categoryFieldValues = categoriesData.usableCategories.map(
    (category) => {
      return {
        label: category.name,
        value: category.id,
      };
    }
  );

  const pointsPerDollar = data.myOrganization.pointsPerDollar;

  const handlePreview = async (values: UploadBulkFileFormValues) => {
    setIsLoadingPreview(true);

    // Read the file to get number of rows (recognitions) and total points
    // Show an error snackbar if unable to read the file or get points values
    let caregiverCount = 0;
    let pointsCount = 0;
    try {
      const parsedData: BulkRecognitionCsvRow[] = await parseCsv(values.file!);
      caregiverCount = parsedData.length;
      pointsCount = parsedData.reduce((total, data) => {
        const points = parseInt(data.Points, 10);
        if (isNaN(points)) {
          throw new Error("Missing points data");
        }
        return total + points;
      }, 0);
    } catch (error) {
      setIsLoadingPreview(false);
      track("Error reading bulk recognition file", { error });
      return snackbar.show({
        message:
          "Error reading file. Please ensure your file matches the template and try again.",
        severity: "error",
      });
    }

    const previewValues = {
      ...values,
      categoryName: values.categoryId
        ? categoryFieldValues.find(
            (category) => category.value === values.categoryId
          )!.label
        : undefined,
      totalRecognitions: caregiverCount,
      totalPoints: pointsCount,
    };
    setPreviewValues(previewValues);
    setIsLoadingPreview(false);
    setRequestId(v4());
    setShowPreviewModal(true);
  };

  const handleSubmit = async (
    previewValues: UploadBulkFilePreviewValues,
    requestId: string
  ) => {
    try {
      await uploadRecognitionFile({
        variables: {
          bulkRecognitionFile: previewValues.file!,
          from: previewValues.from,
          categoryId:
            previewValues.categoryId === "" ? null : previewValues.categoryId,
          notes: previewValues.notes === "" ? null : previewValues.notes,
          previewTotals: {
            recognitions: previewValues.totalRecognitions,
            points: previewValues.totalPoints,
          },
          requestId,
        },
      });
      navigate("/recognition");
      snackbar.show({
        message:
          "Success! This bulk recognition has been submitted for processing.",
        severity: "success",
      });
    } catch (error) {
      setShowPreviewModal(false);
      setIsLoadingPreview(false);
      reportError(error);
      snackbar.show({
        message:
          "Looks like there was an error while trying to send your bulk recognition for processing. Please try again.",
        severity: "error",
      });
    }
  };

  const steps = [
    {
      title: "Recognition recipient(s)",
      content: (
        <>
          <GiveRecognitionTabs />
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              align-items: center;
              gap: ${theme.spacing(1)};
            `}
          >
            <FontAwesomeIcon icon={faExclamationCircle} />
            <Typography variant="body" color="textPrimary" fontWeight={600}>
              Upload bulk recognition details using the template below.
            </Typography>
          </div>
          <Typography
            variant="body"
            color="textPrimary"
            css={css`
              margin-top: 4px;
            `}
          >
            All required fields must be filled (
            <i>
              <strong>Points</strong>
            </i>
            ,{" "}
            <i>
              <strong>Message</strong>
            </i>
            , and either{" "}
            <i>
              <strong>Email</strong>
            </i>{" "}
            or{" "}
            <i>
              <strong>Phone Number</strong>
            </i>
            ).
          </Typography>
          <Typography
            variant="body"
            color="textPrimary"
            css={css`
              margin-top: 4px;
            `}
          >
            Recognition is in{" "}
            <strong>points ({pointsPerDollar} pts = $1).</strong>
          </Typography>
          <div
            css={(theme: AppTheme) => css`
              margin-top: ${theme.spacing(2)};
            `}
          >
            <Button
              linkTo={recognitionTemplateFile}
              download={TEMPLATE_FILE_NAME}
              variant="outlined"
              whiteBackground
              width="auto"
              size="large"
              textColor="primary"
              label={
                <div
                  css={(theme: AppTheme) => css`
                    display: flex;
                    align-items: center;
                    gap: ${theme.spacing(1)};
                  `}
                >
                  <FontAwesomeIcon icon={faFileDownload} />
                  <Typography fontWeight={600} variant="body">
                    Download bulk recognition template
                  </Typography>
                </div>
              }
            />
          </div>
          <Controller
            control={control}
            name="file"
            rules={{
              required: "Please select a file",
            }}
            render={({ field, fieldState }) => (
              <FileInputField
                {...field}
                error={fieldState.error}
                acceptedFileTypes=".csv"
              />
            )}
          />
          <div
            css={css`
              display: flex;
              gap: 10px;
            `}
          >
            <Alert
              severity="info"
              title="Employee list"
              message={
                <Typography variant="body">
                  <span
                    onClick={() => setShowEmployeeExportModal(true)}
                    css={css`
                      cursor: pointer;
                      color: ${theme.palette.primary.main};
                    `}
                  >
                    Download
                  </span>{" "}
                  a local copy of the list of your employees.
                </Typography>
              }
              customIcon={
                <FontAwesomeIcon
                  icon={faUsers}
                  color={theme.palette.text.primary}
                  width={18}
                />
              }
              css={css`
                flex: 1;
              `}
            />
            <Alert
              severity="info"
              title="Need help?"
              message={
                <Typography variant="body">
                  Check out this{" "}
                  <a
                    href={SUPPORT_ARTICLE_URL}
                    target="_blank"
                    rel="noreferrer"
                    css={css`
                      cursor: pointer;
                      color: ${theme.palette.primary.main};
                      text-decoration: none;
                    `}
                  >
                    support article
                  </a>{" "}
                  or contact{" "}
                  <a
                    href={`mailto:${SUPPORT_EMAIL}`}
                    css={css`
                      cursor: pointer;
                      color: ${theme.palette.primary.main};
                      text-decoration: none;
                    `}
                  >
                    {SUPPORT_EMAIL}
                  </a>
                  .
                </Typography>
              }
              customIcon={
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  color={theme.palette.text.primary}
                  width={18}
                />
              }
              css={css`
                flex: 1;
              `}
            />
          </div>
        </>
      ),
    },
    {
      title: "Sender details",
      content: (
        <div
          css={css`
            max-width: ${TEXT_FIELD_MAX_WIDTH}px;
          `}
        >
          <TextField
            label="Your name / who this message is from"
            error={errors.from}
            {...register("from", {
              required: "Sender's name is required",
              validate: (from) => {
                if (from && from.length > MAX_SENDER_NAME_LENGTH) {
                  return getCharactersRemainingText(
                    from,
                    MAX_SENDER_NAME_LENGTH
                  );
                }
              },
            })}
          />
          <Alert
            severity="info"
            title="Budget allocation"
            message="This bulk recognition will not be taken out of any admins’ budget."
          />
        </div>
      ),
    },
    {
      title: "Category details",
      content: categoryFieldValues.length > 0 && (
        <Controller
          control={control}
          name="categoryId"
          rules={{
            required: "Recognition point category is required",
          }}
          render={({ field, fieldState }) => (
            <SelectField
              {...field}
              label="Select category"
              hideLabel={!!categoryIdValue}
              error={fieldState.error}
              width="full"
              css={css`
                max-width: ${TEXT_FIELD_MAX_WIDTH}px;
              `}
              options={categoryFieldValues.sort((a, b) =>
                a.label.localeCompare(b.label)
              )}
            />
          )}
          css={css`
            max-width: ${TEXT_FIELD_MAX_WIDTH}px;
          `}
        />
      ),
    },
    {
      title: "Internal note",
      optional: true,
      content: (
        <TextField
          label="Notes about this bulk recognition file"
          type="textarea"
          minRows={4}
          error={errors.notes}
          {...register("notes", {
            validate: (notes) => {
              if (notes && notes.length > MAX_NOTES_LENGTH) {
                return getCharactersRemainingText(notes, MAX_NOTES_LENGTH);
              }
            },
          })}
          css={css`
            max-width: ${TEXT_FIELD_MAX_WIDTH}px;
          `}
        />
      ),
    },
  ];

  return (
    <>
      <Form
        onSubmit={form.handleSubmit(handlePreview)}
        submitting={isSubmitting}
        css={css`
          display: contents;
        `}
      >
        {steps
          .filter((step) => step.content)
          .map((step, idx) => (
            <Card
              key={idx}
              css={css`
                margin-bottom: 24px;
                padding: 30px;
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  margin-bottom: 30px;
                `}
              >
                <Typography variant="h5" fontWeight={700}>
                  {step.title}
                </Typography>
                {step.optional && (
                  <Typography
                    variant="subtitle"
                    color={theme.palette.grey[800]}
                    css={css`
                      margin-left: 5px;
                    `}
                  >
                    (optional)
                  </Typography>
                )}
              </div>
              {step.content}
            </Card>
          ))}
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
          `}
        >
          <Button
            variant="outlined"
            label="Cancel"
            linkTo="/recognition"
            width="auto"
            minWidthPx={200}
            size="large"
            css={css`
              margin-right: 20px;
            `}
          />
          <Button
            type="submit"
            loading={isLoadingPreview}
            label="Review"
            color="primary"
            width="auto"
            minWidthPx={200}
            size="large"
          />
        </div>
      </Form>
      <ExportEmployeesModal
        open={showEmployeeExportModal}
        onClose={() => setShowEmployeeExportModal(false)}
        filters={{}}
        exportCount={0}
      />
      {previewValues && (
        <BulkRecognitionFilePreviewModal
          open={showPreviewModal}
          isSubmitting={isSubmitting}
          onCancel={() => setShowPreviewModal(false)}
          onSubmit={() => handleSubmit(previewValues, requestId)}
          previewValues={previewValues}
          pointsPerDollar={pointsPerDollar}
        />
      )}
    </>
  );
}
