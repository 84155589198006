/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { alpha, Tab, Tabs } from "@mui/material";
import { Link } from "react-router-dom";

import { AppTheme } from "@rewards-web/shared/style/theme";

import {
  ONBOARDING_STEP_NAME_BY_KEY,
  ONBOARDING_STEP_PATH_BY_KEY,
  OnboardingStepsSupportedByUI,
} from "../constants";
import { CircleCheckIcon } from "./icons/circle-check";

const ONBOARDING_STEP_COMPLETED_CLASS_NAME = "Step-completed";

export interface OnboardingNavigationTabsProps {
  orderedSteps: OnboardingStepsSupportedByUI[];
  currentStep: OnboardingStepsSupportedByUI | null;
  completedSteps: OnboardingStepsSupportedByUI[];
}

export function OnboardingNavigationTabs({
  orderedSteps,
  currentStep,
  completedSteps,
}: OnboardingNavigationTabsProps): JSX.Element {
  const currentStepComplete =
    !!currentStep && completedSteps.includes(currentStep);
  const theme = useTheme();

  return (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      aria-label="Onboarding steps"
      value={currentStep}
      classes={{
        indicator: "custom-indicator",
      }}
      css={(theme: AppTheme) => css`
        margin-top: ${theme.spacing(6)};
        & .custom-indicator {
          background-color: ${currentStepComplete
            ? theme.palette.primary.main
            : "#96A2B0"};
          left: 0px;
          right: initial;
        }
      `}
    >
      {orderedSteps.map((step, idx) => {
        const isCompleted = completedSteps.includes(step);
        const isCurrentStep = currentStep === step;
        return (
          <Tab
            id={step}
            aria-controls={`onboarding-nav-${step}`}
            tabIndex={0}
            value={step}
            component={Link}
            to={`/onboarding/${ONBOARDING_STEP_PATH_BY_KEY[step]}`}
            className={
              isCompleted ? ONBOARDING_STEP_COMPLETED_CLASS_NAME : undefined
            }
            css={(theme: AppTheme) => css`
              color: #8795a5;
              text-align: left;
              border-left: 1px solid ${theme.palette.divider};
              min-height: 46px;
              opacity: 1;
              padding-top: 6px;
              padding-bottom: 6px;
              text-transform: none;

              &:hover,
              &.Mui-selected {
                background-color: ${alpha(theme.palette.primary.main, 0.05)};
              }
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              font-weight: 500;
              > *:first-child {
                margin-bottom: 0px;
                margin-left: ${theme.spacing(1)};
                svg {
                  display: none;
                }
              }
            `}
            icon={
              isCompleted ? (
                <CircleCheckIcon />
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="1"
                    y="1"
                    width="22"
                    height="22"
                    rx="11"
                    fill={
                      isCurrentStep ? theme.palette.primary.main : undefined
                    }
                    stroke={
                      isCurrentStep ? theme.palette.primary.main : "#8795A5"
                    }
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray={
                      isCurrentStep ? theme.palette.primary.main : "3 3"
                    }
                  />
                  <text
                    fill={isCurrentStep ? "#FFFFFF" : "#8795A5"}
                    alignmentBaseline="middle"
                    textAnchor="middle"
                    x="12"
                    y="13"
                    fontSize="10px"
                    fontWeight="600"
                  >
                    {idx + 1}
                  </text>
                </svg>
              )
            }
            label={
              <div
                css={(theme: AppTheme) => css`
                  margin-left: ${theme.spacing(2)};
                `}
              >
                {ONBOARDING_STEP_NAME_BY_KEY[step]}
              </div>
            }
          />
        );
      })}
    </Tabs>
  );
}
