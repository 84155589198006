/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

/**
 * aka. 404 page
 */
export function NotFoundPage() {
  return (
    <>
      <Typography
        css={(theme: AppTheme) => css`
          text-align: center;
          margin: ${theme.spacing(5)};
        `}
        variant="h1"
        color="textPrimary"
      >
        404 - Page Not Found
      </Typography>
    </>
  );
}
