/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useDeleteRecognitionCategoryMutation } from "./delete-recognition-category.generated";
import { useRecognitionCategoryToDeleteQuery } from "./recognition-category-to-delete.generated";

export interface DeleteRecognitionCategoryModalProps {
  open: boolean;
  recognitionCategoryId: string | undefined;
  onClose(): void;
  onExited(): void;
  onDeleted(): void;
}

export function DeleteRecognitionCategoryModal({
  open,
  recognitionCategoryId,
  onClose,
  onExited,
  onDeleted,
}: DeleteRecognitionCategoryModalProps) {
  const track = useTrack();
  const snackbar = useSnackbar();

  const categoryToDeleteQuery = useRecognitionCategoryToDeleteQuery({
    fetchPolicy: "cache-first",
    skip: !recognitionCategoryId,
    variables: {
      recognitionCategoryId: recognitionCategoryId!,
    },
  });
  const [
    deleteRecognitionCategory,
    { loading: submitting },
  ] = useDeleteRecognitionCategoryMutation();

  const handleDelete = async () => {
    try {
      await deleteRecognitionCategory({
        variables: {
          recognitionCategoryId: recognitionCategoryId!,
        },
      });

      snackbar.show({
        severity: "success",
        message: `Success! Category ${categoryToDeleteQuery.data?.getRecognitionCategoryById?.name} has been deleted.`,
      });
      onDeleted();
      onClose();
      track("Deleted recognition category", {
        recognitionCategoryId,
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  const onCancel = () => {
    onClose();
  };

  const modalContents = (() => {
    if (categoryToDeleteQuery.error) {
      return (
        <>
          <ModalContent>
            <Alert
              severity="error"
              message="An unexpected error occurred. Please try again later."
            />
          </ModalContent>
          <ModalActions>
            <Button label="Close" variant="outlined" onClick={onClose} />
          </ModalActions>
        </>
      );
    }

    if (
      categoryToDeleteQuery.data &&
      !categoryToDeleteQuery.data.getRecognitionCategoryById
    ) {
      return (
        <>
          <ModalContent>
            <Alert severity="warning" message="This category doesn't exist." />
          </ModalContent>
          <ModalActions>
            <Button label="Close" variant="outlined" onClick={onClose} />
          </ModalActions>
        </>
      );
    }

    return (
      <>
        <ModalTitle>Delete recognition category?</ModalTitle>
        <ModalContent>
          {categoryToDeleteQuery.data?.getRecognitionCategoryById?.name && (
            <Typography
              variant="body"
              color="grey.800"
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(2)};
              `}
            >
              "{categoryToDeleteQuery.data.getRecognitionCategoryById.name}"
              will no longer appear as a category option once deleted. This will
              not impact any past recognition given to employees.
            </Typography>
          )}
        </ModalContent>
        <ModalActions>
          <Button
            label="Cancel"
            variant="outlined"
            onClick={onCancel}
            disabled={submitting}
          />
          <Button
            label="Confirm"
            color="primary"
            onClick={handleDelete}
            loading={submitting}
          />
        </ModalActions>
      </>
    );
  })();

  return (
    <Modal
      open={open}
      onClose={onCancel}
      onExited={onExited}
      width="560px"
      background="white"
      disableBackdropClick={submitting}
    >
      {modalContents}
    </Modal>
  );
}
