/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Card, CardContent } from "@rewards-web/shared/components/card";
import {
  HalfDonutChart,
  HalfDonutChartProps,
} from "@rewards-web/shared/components/chart/half-donut";
import {
  Legend,
  LegendItem,
} from "@rewards-web/shared/components/chart/legend";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { RecognitionBudgetPeriod } from "@rewards-web/shared/graphql-types";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useHasManagedEmployeesQuery } from "../has-managed-employees.generated";
import { formatBudgetPeriodRange } from "../lib";
import { useRecognitionBudgetEnabledQuery } from "../recognition-budget-enabled.generated";
import { useRecognitionPointsReceivedSummaryQuery } from "../recognition-points-received-summary.generated";

interface PointsReceivedSummaryCardProps {
  period: Pick<RecognitionBudgetPeriod, "startDate" | "endDate">;
}

export function PointsReceivedSummaryCard({
  period,
}: PointsReceivedSummaryCardProps): JSX.Element | null {
  const theme = useTheme();

  const recognitionBudgetEnabledQuery = useRecognitionBudgetEnabledQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
  });

  const hasManagedEmployeesQuery = useHasManagedEmployeesQuery({
    onError: reportError,
  });

  const recognitionPointsReceivedSummaryQuery = useRecognitionPointsReceivedSummaryQuery(
    {
      onError: reportError,
      variables: {
        startDate: period.startDate,
        endDate: period.endDate,
      },
    }
  );

  const recognitionBudgetEnabled =
    recognitionBudgetEnabledQuery.data?.getMyRewardsOrganization
      .recognitionBudgetsEnabled ?? false;

  const pointsReceivedLegendItems: LegendItem[] = [
    {
      id: "1",
      color: theme.palette.primary.main,
      label: (
        <Typography>
          <strong>
            {numberWithCommas(
              recognitionPointsReceivedSummaryQuery.data
                ?.getMyManagedEmployeesRecognitionPointsReceivedSummaryV2
                .pointsReceivedFromManager ?? 0
            )}
          </strong>
          <Typography component="span" color="textSecondary" variant="caption">
            {" points"}
          </Typography>
          {" from me"}
        </Typography>
      ),
      shape: "circle",
    },
    {
      id: "2",
      color: theme.palette.secondary.main,
      label: (
        <Typography>
          <strong>
            {numberWithCommas(
              recognitionPointsReceivedSummaryQuery.data
                ?.getMyManagedEmployeesRecognitionPointsReceivedSummaryV2
                .pointsReceivedFromOthers ?? 0
            )}
          </strong>
          <Typography component="span" color="textSecondary" variant="caption">
            {" points"}
          </Typography>
          {" from others"}
        </Typography>
      ),
      shape: "circle",
    },
  ];

  const pointsReceivedThisPeriod =
    recognitionPointsReceivedSummaryQuery.data
      ?.getMyManagedEmployeesRecognitionPointsReceivedSummaryV2
      ?.pointsReceivedTotal ?? 0;

  const halfDonutProps: HalfDonutChartProps = (() => {
    return recognitionBudgetEnabled &&
      pointsReceivedThisPeriod &&
      pointsReceivedThisPeriod > 0
      ? {
          data: [
            {
              id: "fromMe",
              value:
                recognitionPointsReceivedSummaryQuery.data
                  ?.getMyManagedEmployeesRecognitionPointsReceivedSummaryV2
                  .pointsReceivedFromManager ?? 0,
            },
            {
              id: "fromOthers",
              value:
                recognitionPointsReceivedSummaryQuery.data
                  ?.getMyManagedEmployeesRecognitionPointsReceivedSummaryV2
                  .pointsReceivedFromOthers ?? 0,
            },
          ],
          labels: {
            fromMe: "From me",
            fromOthers: "From others",
          },
          styles: {
            fromMe: {
              color: theme.palette.primary.main,
            },
            fromOthers: {
              color: theme.palette.secondary.main,
            },
          },
          centerLabel: {
            fontSize: 16,
            text: `${numberWithCommas(
              recognitionPointsReceivedSummaryQuery.data
                ?.getMyManagedEmployeesRecognitionPointsReceivedSummaryV2
                .pointsReceivedTotal ?? 0
            )} points`,
          },
        }
      : ({
          data: [
            {
              id: "notAvailable",
              value: 1,
            },
          ],
          labels: {
            notAvailable: "Not available",
          },
          styles: {
            notAvailable: {
              color: "#F2F2F2",
            },
          },
          centerLabel: {
            fontSize: 16,
            text: pointsReceivedThisPeriod === 0 ? "0 Points" : "",
          },
          enableTooltips: false,
        } as HalfDonutChartProps);
  })();

  if (
    hasManagedEmployeesQuery.data?.listMyManagedEmployeesRecognitionSummaryV2
      .total === 0
  ) {
    return null;
  }

  if (
    [
      recognitionBudgetEnabledQuery,
      hasManagedEmployeesQuery,
      recognitionPointsReceivedSummaryQuery,
    ].some((query) => query.error)
  ) {
    return (
      <Alert
        severity="error"
        message="An unexpected error occurred. Please try again later."
      />
    );
  }

  if (
    [
      recognitionBudgetEnabledQuery,
      hasManagedEmployeesQuery,
      recognitionPointsReceivedSummaryQuery,
    ].some((query) => !query.data || query.loading)
  ) {
    return <PageLoadingState />;
  }

  return (
    <Card variant="flat">
      <CardContent>
        <Typography variant="h3">
          Points My Team Received this Period{" "}
          <sub
            css={(theme: AppTheme) =>
              css`
                color: ${theme.palette.primary.main};
                vertical-align: middle;
                white-space: nowrap;
              `
            }
          >
            ({formatBudgetPeriodRange(period.startDate, period.endDate)})
          </sub>
        </Typography>

        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 200px;
            width: 100%;
            div:only-child {
              margin: 0 auto;
            }
          `}
        >
          <div
            css={css`
              height: 200px;
              width: 60%;
            `}
          >
            <HalfDonutChart {...halfDonutProps} />
          </div>

          {recognitionBudgetEnabled && (
            <Legend items={pointsReceivedLegendItems} />
          )}
        </div>

        <Typography align="center">
          {`${
            recognitionPointsReceivedSummaryQuery.data
              ?.getMyManagedEmployeesRecognitionPointsReceivedSummaryV2
              .percentageOfTeamEarnedPoints ?? 0
          }% of your team has earned points this period`}
        </Typography>
      </CardContent>
    </Card>
  );
}
