/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { values as valuesFromObject } from "lodash";
import { useEffect } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { getCharactersRemainingText } from "@rewards-web/shared/lib/characters-remaining-text";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useCreateGroupMutation } from "./create-group.generated";
import { TagsConfigField } from "./tags-config-field";

const MAX_GROUP_NAME_LENGTH = 100;

export interface CreateGroupModalProps {
  open: boolean;
  onClose(): void;
  onExited?(): void;
  onSuccess?(): void;
}

type TagsCombinationsValue = {
  label: string;
  value: string;
};

interface CreateGroupFormValues {
  name: string;
  tagsCombinations: (TagsCombinationsValue | {})[][];
}

const DEFAULT_FORM_VALUES: CreateGroupFormValues = {
  name: "",
  tagsCombinations: [[{}]],
};

export function CreateGroupModal({
  open,
  onClose,
  onExited,
  onSuccess,
}: CreateGroupModalProps): JSX.Element {
  const track = useTrack();
  const snackbar = useSnackbar();
  const [createGroup] = useCreateGroupMutation();
  const form = useForm<CreateGroupFormValues>({
    mode: "onChange",
    defaultValues: { ...DEFAULT_FORM_VALUES },
  });

  const {
    control,
    reset,
    formState: { isSubmitting },
  } = form;

  useEffect(() => {
    track("Viewed create group modal");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // reset form when modal opens
  useEffect(() => {
    if (open) {
      reset(DEFAULT_FORM_VALUES);
    }
  }, [open, reset]);

  const name = useWatch({ control, name: "name" });

  const handleSubmit = async (values: CreateGroupFormValues) => {
    try {
      const andCombinations = values.tagsCombinations.map((combination) => {
        const tags = valuesFromObject(
          combination
        ).filter((tag): tag is TagsCombinationsValue =>
          Object.hasOwn(tag, "value")
        );

        return {
          and: tags.map((tag) => ({ tagId: tag.value })),
        };
      });

      await createGroup({
        variables: {
          name: values.name,
          tagsConfig: {
            or: andCombinations,
          },
        },
      });

      onSuccess?.();
      onClose();

      snackbar.show({
        severity: "success",
        message: "Group created successfully!",
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        message: "An unexpected error occurred. Please try again later.",
        severity: "error",
      });
    }
  };

  return (
    <Modal width="550px" open={open} onClose={onClose} onExited={onExited}>
      <ModalTitle>Create group</ModalTitle>

      <Form
        onSubmit={form.handleSubmit(handleSubmit)}
        submitting={isSubmitting}
      >
        <ModalContent>
          <Controller
            control={control}
            name="name"
            rules={{
              required: "Group name is required",
              validate: (message) => {
                if (message && message.length > MAX_GROUP_NAME_LENGTH) {
                  return getCharactersRemainingText(
                    message,
                    MAX_GROUP_NAME_LENGTH
                  );
                }
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Group name"
                error={fieldState.error}
                helperText={getCharactersRemainingText(
                  name,
                  MAX_GROUP_NAME_LENGTH
                )}
                disableAutocomplete
              />
            )}
          />

          <Typography
            variant="h2"
            component="h1"
            color="textPrimary"
            fontWeight="700"
            css={(theme: AppTheme) => css`
              margin-top: ${theme.spacing(2)};
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            Select tags for group
          </Typography>

          <TagsConfigField control={control} name="tagsCombinations" />
        </ModalContent>

        <ModalActions>
          <Button variant="outlined" onClick={onClose} label="Cancel" />
          <Button type="submit" label="Save" color="primary" />
        </ModalActions>
      </Form>
    </Modal>
  );
}
