/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";
import { TestProps } from "@rewards-web/shared/types";

interface BannerProps extends TestProps {
  description: ReactNode;
  hidden?: boolean;
  maxWidth?: number;
  title?: string;
  withTitleIcon?: boolean;
  action?: JSX.Element;
}

export function Banner({
  description,
  hidden = false,
  maxWidth,
  title,
  withTitleIcon = true,
  action,
  testId,
}: BannerProps): JSX.Element | null {
  if (hidden) {
    return null;
  }

  return (
    <div
      data-testid={testId}
      css={(theme: AppTheme) => css`
        background-color: ${theme.palette.primary.dark};
        height: 55px;
        align-items: center;
        border-bottom: solid;
        border-width: 1px;
        ${!withTitleIcon &&
        css`
          justify-content: center;
          display: flex;
        `}
      `}
    >
      <div
        css={(theme: AppTheme) => css`
          ${withTitleIcon &&
          css`
            display: flex;
            align-items: center;
            height: 100%;

            ${maxWidth &&
            css`
              max-width: ${maxWidth}px;
            `}
            padding-left: ${theme.spacing(6)};
            padding-right: ${theme.spacing(4)};
          `}
        `}
      >
        {title && withTitleIcon && (
          <>
            <span
              css={(theme: AppTheme) => css`
                height: 12px;
                width: 12px;
                background-color: ${theme.palette.text.secondary};
                border-radius: 50%;
                display: inline-block;
                align-self: center;
                margin-right: ${theme.spacing(2)};
              `}
            ></span>

            <Typography
              variant="body"
              display="inline"
              color="textSecondary"
              css={(theme: AppTheme) => css`
                margin-right: ${theme.spacing(2)};
              `}
            >
              {title}
            </Typography>
          </>
        )}

        <Typography
          variant="body"
          display="inline"
          css={(theme: AppTheme) => css`
            margin-right: ${theme.spacing(2)};
            flex-grow: 1;
            color: #ffffff;
          `}
        >
          {description}
        </Typography>
        {action && action}
      </div>
    </div>
  );
}
