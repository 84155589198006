/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";

interface LoginPageContainerProps {
  children: ReactNode;
}

export function LoginPageContainer({
  children,
}: LoginPageContainerProps): JSX.Element {
  return (
    <div
      css={(theme: AppTheme) => css`
        background: ${theme.palette.primary.main};
        height: 100%;
        width: 100%;
        & > * {
          padding-top: 15vh;
        }
        ${theme.breakpoints.up("md")} {
          display: flex;
          justify-content: space-evenly;
        }
      `}
    >
      <div
        css={(theme: AppTheme) => css`
          ${theme.breakpoints.down("sm")} {
            display: none;
          }
          flex-grow: 1;
          text-align: center;
        `}
      ></div>
      <div
        css={(theme: AppTheme) => css`
          ${theme.breakpoints.up("md")} {
            width: 46%;
          }
          display: block;
          height: 100%;
          background-color: #f9f9fb;
        `}
      >
        {children}
      </div>
    </div>
  );
}
