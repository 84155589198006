/** @jsxImportSource @emotion/react */
import { FormControl } from "@material-ui/core";
import { forwardRef, useEffect, useState } from "react";
import { FieldError } from "react-hook-form";

import { ControlledFormFieldProps } from "@rewards-web/shared/types";

import { FileInput } from "../file-input";

export interface FileInputFieldProps extends ControlledFormFieldProps {
  acceptedFileTypes?: string;
}

export const FileInputField = forwardRef<HTMLDivElement, FileInputFieldProps>(
  (
    { acceptedFileTypes, error: errorProp, onChange }: FileInputFieldProps,
    ref
  ) => {
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [fileError, setFileError] = useState<FieldError | undefined>(
      errorProp
    );

    useEffect(() => {
      setFileError(errorProp);
    }, [errorProp]);

    const onUpload = (file: File) => {
      if (onChange) {
        onChange(file);
      }
      setUploadedFile(file);
    };

    return (
      <FormControl fullWidth ref={ref} error={!!fileError}>
        <FileInput
          uploadedFile={uploadedFile}
          setUploadedFile={onUpload}
          error={fileError}
          setError={setFileError}
          acceptedFileTypes={acceptedFileTypes}
        />
      </FormControl>
    );
  }
);
