export function stripHTMLTags(html: string) {
  return stripHtmlEntities(html.replace(/<[^>]*>?/gm, ""));
}

function stripHtmlEntities(html: String) {
  return String(html)
    .replace(/&nbsp;/g, String.fromCharCode(160))
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&excl;/g, "!")
    .replace(/&quot;/g, '"')
    .replace(/&num;/g, "#")
    .replace(/&dollar;/g, "$")
    .replace(/&percnt;/g, "%")
    .replace(/&amp;/g, "&")
    .replace(/&ast;/g, "*");
}
