/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";

import { AuthorizedAdminPageWrapper } from "../../../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { SettingsPayrollRedemptionNotificationEmailForm } from "./payroll-redemption-notification-email-form";
import { useRedemptionSettingsPageDataQuery } from "./redemption-settings-page-data.generated";
import { SettingsRedemptionThresholdForm } from "./redemption-threshold-form";

export function SettingsRedemptionPage() {
  const { loading, data } = useRedemptionSettingsPageDataQuery({
    onError: reportError,
    fetchPolicy: "cache-and-network",
  });

  if (loading) {
    return <PageLoadingState />;
  }

  if (!data) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  const redemptionSummaryNotificationEmails = data.getMyRewardsOrganization.redemptionSummaryNotificationEmails.map(
    (email) => {
      return { email };
    }
  );

  const payrollRedemptionsAllowed = data.getMyRewardsOrganization.allowedRedemptionMethods.includes(
    RedemptionMethod.Payroll
  );

  return (
    <AuthorizedAdminPageWrapper resource="settingsRedemption">
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 24px;
        `}
      >
        <div>
          <SettingsRedemptionThresholdForm
            initialValue={
              data.getMyRewardsOrganization.minimumPointsNeededToRedeem
            }
            pointsPerDollar={data.getMyRewardsOrganization.pointsPerDollar}
          />
        </div>

        {payrollRedemptionsAllowed && (
          <div>
            <SettingsPayrollRedemptionNotificationEmailForm
              initialValue={
                redemptionSummaryNotificationEmails.length > 0
                  ? redemptionSummaryNotificationEmails
                  : undefined
              }
            />
          </div>
        )}
      </div>
    </AuthorizedAdminPageWrapper>
  );
}
