/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { StylableProps } from "@rewards-web/shared/types";

import { AppTheme } from "../../style/theme";
import { ProgressSpinner } from "../progress-spinner";
import { Typography } from "../typography";

export interface PageLoadingStateProps extends StylableProps {
  label?: string;
}

/**
 * Renders a progress spinner that is centered on the page
 */
export function PageLoadingState({ label, className }: PageLoadingStateProps) {
  return (
    <div
      className={className}
      css={css`
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}
    >
      {label && (
        <Typography
          variant="footnote"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          {label}
        </Typography>
      )}
      <ProgressSpinner />
    </div>
  );
}
