import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SurveyQuestionDetailsPageDataQueryVariables = Types.Exact<{
  questionType: Types.SurveyQuestionType;
  dateRange?: Types.Maybe<Types.SurveyInsightsDateRangeInput>;
}>;


export type SurveyQuestionDetailsPageDataQuery = (
  { __typename?: 'Query' }
  & { getScoreDistributionForSurveyQuestion: Array<(
    { __typename?: 'SurveyQuestionScoreCount' }
    & Pick<Types.SurveyQuestionScoreCount, 'score' | 'count'>
  )>, getSurveyQuestionResults: (
    { __typename?: 'SurveyQuestionResultsResponse' }
    & Pick<Types.SurveyQuestionResultsResponse, 'averageScore'>
    & { previousAverageScores?: Types.Maybe<Array<(
      { __typename?: 'SurveyQuestionPreviousResultsResponse' }
      & Pick<Types.SurveyQuestionPreviousResultsResponse, 'averageScore'>
      & { dateRange: (
        { __typename?: 'SurveyInsightsDateRange' }
        & Pick<Types.SurveyInsightsDateRange, 'label' | 'year'>
      ) }
    )>> }
  ) }
);


export const SurveyQuestionDetailsPageDataDocument = gql`
    query SurveyQuestionDetailsPageData($questionType: SurveyQuestionType!, $dateRange: SurveyInsightsDateRangeInput) {
  getScoreDistributionForSurveyQuestion(
    surveyType: PULSE_CHECK
    questionType: $questionType
    dateRange: $dateRange
  ) {
    score
    count
  }
  getSurveyQuestionResults(
    surveyType: PULSE_CHECK
    questionType: $questionType
    dateRange: $dateRange
  ) {
    averageScore
    previousAverageScores {
      dateRange {
        label
        year
      }
      averageScore
    }
  }
}
    `;

/**
 * __useSurveyQuestionDetailsPageDataQuery__
 *
 * To run a query within a React component, call `useSurveyQuestionDetailsPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyQuestionDetailsPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyQuestionDetailsPageDataQuery({
 *   variables: {
 *      questionType: // value for 'questionType'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useSurveyQuestionDetailsPageDataQuery(baseOptions: Apollo.QueryHookOptions<SurveyQuestionDetailsPageDataQuery, SurveyQuestionDetailsPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyQuestionDetailsPageDataQuery, SurveyQuestionDetailsPageDataQueryVariables>(SurveyQuestionDetailsPageDataDocument, options);
      }
export function useSurveyQuestionDetailsPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyQuestionDetailsPageDataQuery, SurveyQuestionDetailsPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyQuestionDetailsPageDataQuery, SurveyQuestionDetailsPageDataQueryVariables>(SurveyQuestionDetailsPageDataDocument, options);
        }
export type SurveyQuestionDetailsPageDataQueryHookResult = ReturnType<typeof useSurveyQuestionDetailsPageDataQuery>;
export type SurveyQuestionDetailsPageDataLazyQueryHookResult = ReturnType<typeof useSurveyQuestionDetailsPageDataLazyQuery>;
export type SurveyQuestionDetailsPageDataQueryResult = Apollo.QueryResult<SurveyQuestionDetailsPageDataQuery, SurveyQuestionDetailsPageDataQueryVariables>;