/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { AdminGoalType } from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/types";

import {
  ADMIN_GOAL_TYPES_SUPPORTED_BY_UI,
  AdminGoalTypeSupportedByUI,
} from "./constants";
import { useCoordinatorGoalsSettingsPageDataQuery } from "./coordinator-goals-settings-page-data.generated";
import coinsIcon from "./icons/coins.png";

const GOAL_CONFIG_CONTENT = {
  [AdminGoalType.BudgetUtilization]: {
    label: "Recognition budget utilization",
    description:
      "Set recognition goals to foster a culture of appreciation, ensuring caregivers feel valued and supported.",
    icon: coinsIcon,
    iconAltText: "coins",
    linkTo: "/settings/coordinator-goals/recognition-budget",
  },
};

export function CoordinatorGoalsSettingsPageContents(): JSX.Element {
  const track = useTrack();

  const budgetUtilizationGoalEnabled = useFeatureFlag(
    "admin-app-budget-utilization-goal-available-temp"
  );

  const availableGoalTypes: AdminGoalTypeSupportedByUI[] = ADMIN_GOAL_TYPES_SUPPORTED_BY_UI.filter(
    // eslint-disable-next-line array-callback-return
    (goalType) => {
      switch (goalType) {
        case AdminGoalType.BudgetUtilization:
          return budgetUtilizationGoalEnabled;
        // add other goal types here
        default:
          assertNever(goalType);
      }
    }
  );

  const coordinatorGoalsSettingsQuery = useCoordinatorGoalsSettingsPageDataQuery(
    {
      onError: reportError,
    }
  );

  useEffect(() => {
    track("Viewed coordinator goals settings page", {
      availableGoalTypes: availableGoalTypes,
    });
  }, [track, availableGoalTypes]);

  if (coordinatorGoalsSettingsQuery.error) {
    return (
      <Alert
        severity="error"
        message="An unexpected error occurred. Please try again later."
      />
    );
  }

  if (
    coordinatorGoalsSettingsQuery.loading ||
    !coordinatorGoalsSettingsQuery.data
  ) {
    return (
      <div
        css={css`
          height: 200px;
        `}
      >
        <PageLoadingState />
      </div>
    );
  }

  if (availableGoalTypes.length === 0) {
    // The page should only be visible if they have available goal types,
    // so this would only happen if they navigate to the route directly.
    return (
      <Alert
        severity="info"
        message="There are no goals available to configure at this time."
      />
    );
  }

  const {
    getCurrentAdminGoalConfigs: existingConfigs,
  } = coordinatorGoalsSettingsQuery.data;

  return (
    <>
      <Typography
        variant="h5"
        fontWeight={700}
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        Select one goal to start with
      </Typography>
      <div>
        {availableGoalTypes.map((goalType, idx) => {
          const goalConfigContent = GOAL_CONFIG_CONTENT[goalType];
          const existingConfig = existingConfigs.find(
            (c) => c.goalType === goalType
          );

          return (
            <Card
              key={`${goalType}-${idx}`}
              variant="flat"
              css={(theme: AppTheme) => css`
                padding: ${theme.spacing(3)};
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                `}
              >
                <div>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <img
                      width={30}
                      src={goalConfigContent.icon}
                      alt={goalConfigContent.iconAltText}
                    />
                    <Typography
                      variant="h6"
                      fontWeight={700}
                      color="textPrimary"
                      css={(theme: AppTheme) => css`
                        margin-left: ${theme.spacing(2)};
                      `}
                    >
                      {goalConfigContent.label}
                    </Typography>
                  </div>
                  <Typography
                    variant="footnote"
                    color="grey.800"
                    maxWidth={543}
                    css={(theme: AppTheme) => css`
                      margin-top: ${theme.spacing(1)};
                    `}
                  >
                    {goalConfigContent.description}
                  </Typography>
                </div>
                <Button
                  variant="outlined"
                  size="medium"
                  width="auto"
                  disabled={existingConfig ? true : false}
                  label={
                    existingConfig
                      ? existingConfig.launched
                        ? "Launched"
                        : "Scheduled"
                      : "Start"
                  }
                  endIcon={
                    existingConfig ? undefined : (
                      <FontAwesomeIcon icon={faArrowRight} width={13} />
                    )
                  }
                  linkTo={goalConfigContent.linkTo}
                  onClick={() =>
                    track("Clicked to start configuring coordinator goal", {
                      goalType,
                    })
                  }
                />
              </div>
            </Card>
          );
        })}
      </div>
    </>
  );
}
