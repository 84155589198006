import { Control, FieldValues, Path, Controller } from "react-hook-form";

import {
  SelectCheckboxesField,
  SelectCheckboxesFieldOption,
} from "@rewards-web/shared/components/select-checkboxes-field";

import { useMyBranches } from "../../../../shared/modules/branches/use-my-branches";

interface AdminBranchesFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  disabled?: boolean;
}

export function AdminBranchesField<T extends FieldValues>({
  control,
  name,
  disabled = false,
}: AdminBranchesFieldProps<T>) {
  const branches = useMyBranches();

  const options = (branches.data?.getMyRewardsOrganization?.branches ?? []).map(
    (branch): SelectCheckboxesFieldOption => ({
      value: branch.id,
      label: branch.name,
    })
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <SelectCheckboxesField
          id="admin-select-checkboxes"
          variant="field"
          label="Assigned Branches"
          disabled={disabled}
          displayedValue={
            field.value.length === 0
              ? "All"
              : field.value
                  .map(
                    (branchId: string) =>
                      options.find((option) => option.value === branchId)?.label
                  )
                  .join(", ")
          }
          options={options}
          fullWidth
          {...field}
        />
      )}
    />
  );
}
