import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EnableSmsMfaMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type EnableSmsMfaMutation = (
  { __typename?: 'Mutation' }
  & { enableSmsMfaForMyRewardsAdmin: (
    { __typename?: 'GetMyRewardsAdminMfaStatusResponse' }
    & Pick<Types.GetMyRewardsAdminMfaStatusResponse, 'id' | 'smsMFARequired' | 'smsNumberVerified'>
  ) }
);


export const EnableSmsMfaDocument = gql`
    mutation EnableSmsMfa {
  enableSmsMfaForMyRewardsAdmin {
    id
    smsMFARequired
    smsNumberVerified
  }
}
    `;
export type EnableSmsMfaMutationFn = Apollo.MutationFunction<EnableSmsMfaMutation, EnableSmsMfaMutationVariables>;

/**
 * __useEnableSmsMfaMutation__
 *
 * To run a mutation, you first call `useEnableSmsMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableSmsMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableSmsMfaMutation, { data, loading, error }] = useEnableSmsMfaMutation({
 *   variables: {
 *   },
 * });
 */
export function useEnableSmsMfaMutation(baseOptions?: Apollo.MutationHookOptions<EnableSmsMfaMutation, EnableSmsMfaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableSmsMfaMutation, EnableSmsMfaMutationVariables>(EnableSmsMfaDocument, options);
      }
export type EnableSmsMfaMutationHookResult = ReturnType<typeof useEnableSmsMfaMutation>;
export type EnableSmsMfaMutationResult = Apollo.MutationResult<EnableSmsMfaMutation>;
export type EnableSmsMfaMutationOptions = Apollo.BaseMutationOptions<EnableSmsMfaMutation, EnableSmsMfaMutationVariables>;