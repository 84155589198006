import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Control, FieldValues, get, Path, useFormState } from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";
import { StylableProps } from "@rewards-web/shared/types";

const SHORT_NAME_MIN_LENGTH = 2;
const SHORT_NAME_MAX_LENGTH = 30;

interface SettingsShortOrganizationNameFieldProps<T extends FieldValues>
  extends StylableProps {
  control: Control<T>;
  name: Path<T>;
  isRequired?: boolean;
}

export function SettingsShortOrganizationNameField<T extends FieldValues>({
  className,
  control,
  name,
  isRequired,
}: SettingsShortOrganizationNameFieldProps<T>) {
  const formState = useFormState({ control });

  return (
    <TextField
      className={className}
      label="Short company name"
      disableAutocomplete
      error={get(formState.errors, name)}
      helperText={
        <>
          <FontAwesomeIcon icon={faInfoCircle} /> How should we refer to your
          agency when communicating with caregivers?
        </>
      }
      {...control.register(name, {
        required: isRequired,
        minLength: {
          value: SHORT_NAME_MIN_LENGTH,
          message: `The name must have more than ${SHORT_NAME_MIN_LENGTH} characters`,
        },
        maxLength: {
          value: SHORT_NAME_MAX_LENGTH,
          message: `The name must not exceed ${SHORT_NAME_MAX_LENGTH} characters`,
        },
      })}
    />
  );
}
