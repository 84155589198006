/** @jsxImportSource @emotion/react */
import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";

export interface LaunchGoalConfirmationModalProps {
  open: boolean;
  onClose(): void;
  onConfirm(): void;
  goalPeriod: string;
  daysLeftInPeriod: number;
  lateLaunch: boolean;
  loading?: boolean;
}

export function LaunchGoalConfirmationModal({
  open,
  onClose,
  onConfirm,
  goalPeriod,
  daysLeftInPeriod,
  lateLaunch,
  loading,
}: LaunchGoalConfirmationModalProps): JSX.Element {
  return (
    <Modal width="500px" open={open} onClose={onClose}>
      <ModalTitle>
        <Typography variant="h5" fontWeight={700}>
          {lateLaunch
            ? `Are you sure you want to launch this goal for ${goalPeriod}?`
            : "Ready to launch this goal?"}
        </Typography>
      </ModalTitle>
      <ModalContent>
        <Typography variant="body">
          {lateLaunch
            ? `There ${
                daysLeftInPeriod > 1 ? "are" : "is"
              } only ${daysLeftInPeriod} day${
                daysLeftInPeriod > 1 ? "s" : ""
              } left in ${goalPeriod}. The
          receivers of the goal will have limited time to participate and
          complete this goal. If you'd prefer, you can come back at the start of
          the next month to launch this goal.`
            : `Once launched, this goal will be live and cannot be reversed or
          deleted. Please confirm before proceeding.`}
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          variant="outlined"
          onClick={onClose}
          label="Cancel"
          size="medium"
          width="auto"
          disabled={loading}
        />
        <Button
          color="primary"
          onClick={onConfirm}
          label="Confirm launch"
          size="medium"
          width="auto"
          loading={loading}
        />
      </ModalActions>
    </Modal>
  );
}
