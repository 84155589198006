import * as React from "react";

function SurveysLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.625 0.351562C15.8555 0.351562 16.875 1.37109 16.875 2.60156V16.1016C16.875 17.3672 15.8555 18.3516 14.625 18.3516H4.5C3.23438 18.3516 2.25 17.3672 2.25 16.1016V14.4141H0.5625C0.246094 14.4141 0 14.168 0 13.8516C0 13.5703 0.246094 13.2891 0.5625 13.2891H2.25V9.91406H0.5625C0.246094 9.91406 0 9.66797 0 9.35156C0 9.07031 0.246094 8.78906 0.5625 8.78906H2.25V5.41406H0.5625C0.246094 5.41406 0 5.16797 0 4.85156C0 4.57031 0.246094 4.28906 0.5625 4.28906H2.25V2.60156C2.25 1.37109 3.23438 0.351562 4.5 0.351562H14.625ZM4.5 17.2266H6.75V1.47656H4.5C3.86719 1.47656 3.375 2.00391 3.375 2.60156V4.28906H4.57031C4.88672 4.28906 5.13281 4.57031 5.13281 4.85156C5.13281 5.16797 4.88672 5.41406 4.57031 5.41406H3.375V8.78906H4.57031C4.88672 8.78906 5.13281 9.07031 5.13281 9.35156C5.13281 9.66797 4.88672 9.91406 4.57031 9.91406H3.375V13.2891H4.57031C4.88672 13.2891 5.13281 13.5703 5.13281 13.8516C5.13281 14.168 4.88672 14.4141 4.57031 14.4141H3.375V16.1016C3.375 16.7344 3.86719 17.2266 4.5 17.2266ZM15.75 16.1016V2.60156C15.75 2.00391 15.2227 1.47656 14.625 1.47656H7.875V17.2266H14.625C15.2227 17.2266 15.75 16.7344 15.75 16.1016Z"
        fill={props.color}
      />
    </svg>
  );
}

export default SurveysLogo;
