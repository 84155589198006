import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ConfirmChangeReferredByEmployeeDataQueryVariables = Types.Exact<{
  candidateId: Types.Scalars['ID'];
  previousUserId: Types.Scalars['ID'];
  nextUserId: Types.Scalars['ID'];
}>;


export type ConfirmChangeReferredByEmployeeDataQuery = (
  { __typename?: 'Query' }
  & { candidate?: Types.Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Types.Candidate, 'id' | 'firstName' | 'lastName' | 'pointsEarnedByReferredByUser'>
  )>, previousUser?: Types.Maybe<(
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'firstName' | 'lastName'>
  )>, nextUser?: Types.Maybe<(
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'firstName' | 'lastName'>
  )> }
);


export const ConfirmChangeReferredByEmployeeDataDocument = gql`
    query ConfirmChangeReferredByEmployeeData($candidateId: ID!, $previousUserId: ID!, $nextUserId: ID!) {
  candidate: getCandidateById(candidateId: $candidateId) {
    id
    firstName
    lastName
    pointsEarnedByReferredByUser
  }
  previousUser: getRewardsUserById(userId: $previousUserId) {
    id
    firstName
    lastName
  }
  nextUser: getRewardsUserById(userId: $nextUserId) {
    id
    firstName
    lastName
  }
}
    `;

/**
 * __useConfirmChangeReferredByEmployeeDataQuery__
 *
 * To run a query within a React component, call `useConfirmChangeReferredByEmployeeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmChangeReferredByEmployeeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmChangeReferredByEmployeeDataQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      previousUserId: // value for 'previousUserId'
 *      nextUserId: // value for 'nextUserId'
 *   },
 * });
 */
export function useConfirmChangeReferredByEmployeeDataQuery(baseOptions: Apollo.QueryHookOptions<ConfirmChangeReferredByEmployeeDataQuery, ConfirmChangeReferredByEmployeeDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfirmChangeReferredByEmployeeDataQuery, ConfirmChangeReferredByEmployeeDataQueryVariables>(ConfirmChangeReferredByEmployeeDataDocument, options);
      }
export function useConfirmChangeReferredByEmployeeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfirmChangeReferredByEmployeeDataQuery, ConfirmChangeReferredByEmployeeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfirmChangeReferredByEmployeeDataQuery, ConfirmChangeReferredByEmployeeDataQueryVariables>(ConfirmChangeReferredByEmployeeDataDocument, options);
        }
export type ConfirmChangeReferredByEmployeeDataQueryHookResult = ReturnType<typeof useConfirmChangeReferredByEmployeeDataQuery>;
export type ConfirmChangeReferredByEmployeeDataLazyQueryHookResult = ReturnType<typeof useConfirmChangeReferredByEmployeeDataLazyQuery>;
export type ConfirmChangeReferredByEmployeeDataQueryResult = Apollo.QueryResult<ConfirmChangeReferredByEmployeeDataQuery, ConfirmChangeReferredByEmployeeDataQueryVariables>;