import { ReactNode } from "react";
import { Helmet } from "react-helmet";

import CaribouIcon from "@rewards-web/rewards-app/public/favicon.ico";
import { useTrackPageview } from "@rewards-web/shared/modules/analytics";

interface PageProps {
  /**
   * Title to display in the html <title>
   */
  browserTitle: string;

  /**
   * Icon to display in the html <link rel="">
   */
  faviconUrl?: string;

  /**
   * This should not change once deployed to production for a given page.
   */
  analyticsPageName: string;

  /**
   * Component to render for the page
   */
  children: ReactNode;
}

/**
 * Creates a page:
 * - Renders a react router <Route> using the given `path`
 * - Renders the given browser page <title>
 * - Tracks the pageview with analytics using the given page name identifier
 * - Renders the given element when the URL matches
 */
export function Page({
  browserTitle,
  analyticsPageName,
  children,
  faviconUrl,
}: PageProps): JSX.Element {
  useTrackPageview(analyticsPageName);

  return (
    <>
      <Helmet>
        <title>{browserTitle}</title>
        <link rel="icon" href={faviconUrl ?? CaribouIcon} />
      </Helmet>
      {children}
    </>
  );
}
