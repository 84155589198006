import { createContext } from "react";

interface CognitoAuthContextValue {
  userId: null | string;
  setUserId(userId: string): void;
}

export const CognitoAuthContext = createContext<CognitoAuthContextValue>({
  userId: null,
  setUserId: () => {},
});
