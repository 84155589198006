/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

import { usePathParamToggle } from "@rewards-web/shared/hooks/use-path-param-toggle";
import {
  useTrack,
  useTrackScreenRecordingEvent,
} from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";

import { BranchFilter } from "../../../shared/components/branch-filter";
import { HeaderWithButton } from "../../../shared/components/header-with-button";
import { AddNewJobModal } from "../../../shared/modules/jobs/list/components/add-new-job-modal";
import { AdminJobsDataTable } from "../../../shared/modules/jobs/list/components/jobs-data-table";
import { useAdminJobsTableQuery } from "../../../shared/modules/jobs/list/hooks/use-jobs-table-query";
import { useHasPermissionQuery } from "../../../shared/modules/permissions/hooks/use-has-permission-query";
import { CreateJobButton } from "./create-job-button";
import { JobsHeader } from "./jobs-header";
import { useJobsPageDataQuery } from "./jobs-page-data.generated";

const ITEMS_PER_PAGE = 10;

export function JobsPageContents() {
  const trackScreenRecordingEvent = useTrackScreenRecordingEvent();
  const track = useTrack();
  const fullJobsPermissionsQuery = useHasPermissionQuery("full", "jobs");
  const jobsPageData = useJobsPageDataQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
  });

  const readOnly =
    !fullJobsPermissionsQuery.hasPermission ||
    jobsPageData.data?.getMyRewardsOrganization.referralsEnabled === false;

  const organizationHasBranches =
    (jobsPageData.data?.getMyRewardsOrganization.branches ?? []).length > 0;

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [addNewJobModalOpen, setAddNewJobModalOpen] = useState(false);
  const [selectedBranchIds, setSelectedBranchIds] = useState<(string | null)[]>(
    []
  );

  const jobsQueryVariables =
    selectedBranchIds.length === 0
      ? { currentPageIndex, perPage: ITEMS_PER_PAGE }
      : {
          branchIds: selectedBranchIds,
          currentPageIndex,
          perPage: ITEMS_PER_PAGE,
        };

  const { items, total, loading, refetch } = useAdminJobsTableQuery(
    jobsQueryVariables
  );
  const [editingJobId, editingJob] = usePathParamToggle({
    param: "id",
    onPath: "/jobs/:id/edit",
    offPath: "/jobs",
  });

  return (
    <>
      {organizationHasBranches ? (
        <>
          <JobsHeader />

          <div
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            `}
          >
            <BranchFilter
              value={selectedBranchIds ?? [null]}
              onChange={setSelectedBranchIds}
              resourceName="Jobs"
              onOpen={() => {
                trackScreenRecordingEvent("jobs_branch_filter_opened");
                track("Opened jobs branch filter");
              }}
            />

            <CreateJobButton
              disabled={readOnly}
              onClick={() => {
                setAddNewJobModalOpen(true);
              }}
            />
          </div>
        </>
      ) : (
        <HeaderWithButton
          header={<JobsHeader />}
          button={
            <CreateJobButton
              disabled={readOnly}
              onClick={() => {
                setAddNewJobModalOpen(true);
              }}
            />
          }
        />
      )}

      <AdminJobsDataTable
        currentPageIndex={currentPageIndex}
        setCurrentPageIndex={setCurrentPageIndex}
        perPage={ITEMS_PER_PAGE}
        loading={loading}
        items={items}
        total={total}
        editingJobId={editingJobId}
        onCloseEditJob={editingJob.navigateToOffPath}
        onOpenEditJob={editingJob.navigateToOnPath}
        refreshList={refetch}
        readOnly={readOnly}
        duplicateJobConfirmationDescription="You'll be directed to a duplicate of the job posting you selected. You can edit this duplicate before publishing it."
        duplicateJobSubmitButtonLabel="Publish"
        editJobDescription="Press “Save” to publish these changes on the active job posting."
        getCloseJobConfirmationDescription={(jobPostingTitle) =>
          `The job posting "${jobPostingTitle}" will no longer be visible by employees or future candidates.`
        }
        duplicateJobSuccessSnackbarMessage="The job posting was published"
      />

      <AddNewJobModal
        open={addNewJobModalOpen}
        onClose={() => {
          setAddNewJobModalOpen(false);
        }}
        onAdded={() => refetch()}
        duplicatingJobPostingId={null}
        submitButtonLabel="Publish"
        successSnackbarMessage="The job posting was published"
      />
    </>
  );
}
