import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type NumJobsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type NumJobsQuery = (
  { __typename?: 'Query' }
  & { listJobPostings: (
    { __typename?: 'JobPostingListResponse' }
    & Pick<Types.JobPostingListResponse, 'total'>
  ) }
);


export const NumJobsDocument = gql`
    query NumJobs {
  listJobPostings(limit: 0, offset: 0) {
    total
  }
}
    `;

/**
 * __useNumJobsQuery__
 *
 * To run a query within a React component, call `useNumJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNumJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNumJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNumJobsQuery(baseOptions?: Apollo.QueryHookOptions<NumJobsQuery, NumJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NumJobsQuery, NumJobsQueryVariables>(NumJobsDocument, options);
      }
export function useNumJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NumJobsQuery, NumJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NumJobsQuery, NumJobsQueryVariables>(NumJobsDocument, options);
        }
export type NumJobsQueryHookResult = ReturnType<typeof useNumJobsQuery>;
export type NumJobsLazyQueryHookResult = ReturnType<typeof useNumJobsLazyQuery>;
export type NumJobsQueryResult = Apollo.QueryResult<NumJobsQuery, NumJobsQueryVariables>;