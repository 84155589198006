/** @jsxImportSource @emotion/react */
import { ClassNames } from "@emotion/react";
import { Tooltip as MUITooltip } from "@material-ui/core";
import { ReactNode } from "react";

import { StylableProps } from "../../types";

export interface TooltipProps extends StylableProps {
  title: NonNullable<ReactNode>;
  placement?: "top" | "bottom" | "right" | "left";
  disabled?: boolean;
  children?: ReactNode;
  spaceFromElement?: number;
  onOpen?(): void;
}

export function Tooltip({
  children,
  disabled = false,
  title,
  className,
  placement = "bottom",
  spaceFromElement,
  onOpen,
}: TooltipProps): JSX.Element {
  return (
    <ClassNames>
      {({ css, theme }) => (
        <MUITooltip
          title={title}
          arrow
          placement={placement}
          className={className}
          disableHoverListener={disabled}
          // onOpen={onOpen}
          color="rgba(0,0,0,0.8)"
          classes={{
            tooltip: css`
              border-radius: 5px;
              font-size: 0.9em;
              background-color: ${theme.palette.text.primary};
            `,
            arrow: css`
              color: ${theme.palette.text.primary}; ;
            `,
            tooltipPlacementBottom: css`
              ${spaceFromElement
                ? css`
                    margin-top: ${spaceFromElement}px;
                  `
                : null}
            `,
          }}
        >
          <div
            className={css`
              display: inline-block;
            `}
            onMouseEnter={onOpen}
          >
            {children}
          </div>
        </MUITooltip>
      )}
    </ClassNames>
  );
}
