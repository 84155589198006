import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type HiringOrganizationStatsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HiringOrganizationStatsQuery = (
  { __typename?: 'Query' }
  & { getRewardsOrganizationStats?: Types.Maybe<(
    { __typename?: 'RewardsOrganizationStats' }
    & Pick<Types.RewardsOrganizationStats, 'id' | 'totalNumberOfApplicants' | 'totalNumberOfCandidatesContacted' | 'totalNumberOfCandidatesScheduledInterview' | 'totalNumberOfCandidatesPassedInterview' | 'totalNumberOfCandidatesStartedWork' | 'totalNumberOfCandidatesHired' | 'totalNumberOfCandidatesStartedOrientation' | 'totalNumberOfCandidatesCompletedOrientation' | 'totalNumberOfCandidatesCompletedFirstShift' | 'totalNumberOfRetainedCandidatesAfter3Months' | 'totalNumberOfRetainedCandidatesAfter6Months' | 'totalPointsRedeemed'>
  )> }
);


export const HiringOrganizationStatsDocument = gql`
    query HiringOrganizationStats {
  getRewardsOrganizationStats {
    id
    totalNumberOfApplicants
    totalNumberOfCandidatesContacted
    totalNumberOfCandidatesScheduledInterview
    totalNumberOfCandidatesPassedInterview
    totalNumberOfCandidatesStartedWork
    totalNumberOfCandidatesHired
    totalNumberOfCandidatesStartedOrientation
    totalNumberOfCandidatesCompletedOrientation
    totalNumberOfCandidatesCompletedFirstShift
    totalNumberOfRetainedCandidatesAfter3Months
    totalNumberOfRetainedCandidatesAfter6Months
    totalPointsRedeemed
  }
}
    `;

/**
 * __useHiringOrganizationStatsQuery__
 *
 * To run a query within a React component, call `useHiringOrganizationStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHiringOrganizationStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHiringOrganizationStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHiringOrganizationStatsQuery(baseOptions?: Apollo.QueryHookOptions<HiringOrganizationStatsQuery, HiringOrganizationStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HiringOrganizationStatsQuery, HiringOrganizationStatsQueryVariables>(HiringOrganizationStatsDocument, options);
      }
export function useHiringOrganizationStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HiringOrganizationStatsQuery, HiringOrganizationStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HiringOrganizationStatsQuery, HiringOrganizationStatsQueryVariables>(HiringOrganizationStatsDocument, options);
        }
export type HiringOrganizationStatsQueryHookResult = ReturnType<typeof useHiringOrganizationStatsQuery>;
export type HiringOrganizationStatsLazyQueryHookResult = ReturnType<typeof useHiringOrganizationStatsLazyQuery>;
export type HiringOrganizationStatsQueryResult = Apollo.QueryResult<HiringOrganizationStatsQuery, HiringOrganizationStatsQueryVariables>;