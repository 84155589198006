import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EditAdminRecognitionBudgetsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EditAdminRecognitionBudgetsQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'recognitionBudgetsEnabled' | 'pointsPerDollar'>
    & { automaticRecognitionBudgetConfig: (
      { __typename?: 'RewardsOrganizationAutomaticRecognitionBudgetConfig' }
      & Pick<Types.RewardsOrganizationAutomaticRecognitionBudgetConfig, 'frequency'>
    ) }
  ) }
);


export const EditAdminRecognitionBudgetsDocument = gql`
    query EditAdminRecognitionBudgets {
  getMyRewardsOrganization {
    id
    recognitionBudgetsEnabled
    automaticRecognitionBudgetConfig {
      frequency
    }
    pointsPerDollar
  }
}
    `;

/**
 * __useEditAdminRecognitionBudgetsQuery__
 *
 * To run a query within a React component, call `useEditAdminRecognitionBudgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditAdminRecognitionBudgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditAdminRecognitionBudgetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditAdminRecognitionBudgetsQuery(baseOptions?: Apollo.QueryHookOptions<EditAdminRecognitionBudgetsQuery, EditAdminRecognitionBudgetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditAdminRecognitionBudgetsQuery, EditAdminRecognitionBudgetsQueryVariables>(EditAdminRecognitionBudgetsDocument, options);
      }
export function useEditAdminRecognitionBudgetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditAdminRecognitionBudgetsQuery, EditAdminRecognitionBudgetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditAdminRecognitionBudgetsQuery, EditAdminRecognitionBudgetsQueryVariables>(EditAdminRecognitionBudgetsDocument, options);
        }
export type EditAdminRecognitionBudgetsQueryHookResult = ReturnType<typeof useEditAdminRecognitionBudgetsQuery>;
export type EditAdminRecognitionBudgetsLazyQueryHookResult = ReturnType<typeof useEditAdminRecognitionBudgetsLazyQuery>;
export type EditAdminRecognitionBudgetsQueryResult = Apollo.QueryResult<EditAdminRecognitionBudgetsQuery, EditAdminRecognitionBudgetsQueryVariables>;