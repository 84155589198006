export function UserCardIcon() {
  return (
    <svg
      width="11"
      height="15"
      viewBox="0 0 11 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0.75C9.95703 0.75 10.75 1.54297 10.75 2.5V13C10.75 13.9844 9.95703 14.75 9 14.75H2C1.01562 14.75 0.25 13.9844 0.25 13V2.5C0.25 1.54297 1.01562 0.75 2 0.75H9ZM9.4375 13V2.5C9.4375 2.28125 9.21875 2.0625 9 2.0625H7.25V2.5C7.25 2.99219 6.83984 3.375 6.375 3.375H4.625C4.13281 3.375 3.75 2.99219 3.75 2.5V2.0625H2C1.75391 2.0625 1.5625 2.28125 1.5625 2.5V13C1.5625 13.2461 1.75391 13.4375 2 13.4375H9C9.21875 13.4375 9.4375 13.2461 9.4375 13ZM5.5 8.625C4.51562 8.625 3.75 7.85938 3.75 6.875C3.75 5.91797 4.51562 5.125 5.5 5.125C6.45703 5.125 7.25 5.91797 7.25 6.875C7.25 7.85938 6.45703 8.625 5.5 8.625ZM6.375 9.5C7.57812 9.5 8.5625 10.4844 8.5625 11.6875C8.5625 11.9336 8.34375 12.125 8.125 12.125H2.875C2.62891 12.125 2.4375 11.9336 2.4375 11.6875C2.4375 10.4844 3.39453 9.5 4.625 9.5H6.375Z"
        fill="#444444"
      />
    </svg>
  );
}
