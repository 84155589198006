import { ResponsivePie } from "@nivo/pie";
import { BasicTooltip } from "@nivo/tooltip";

import { assertNever } from "@rewards-web/shared/lib/assert-never";

interface StyleConfig {
  color: string;
  pattern?: "none" | "lines" | "dots";
}

export interface HalfDonutChartProps {
  data: { id: string; value: number }[];
  labels: { [key: string]: string };
  styles: { [key: string]: StyleConfig };
  centerLabel?: { fontSize: number; text: string };
  enableTooltips?: boolean;
}

export function HalfDonutChart(props: HalfDonutChartProps): JSX.Element {
  return (
    <ResponsivePie
      data={props.data}
      isInteractive={props.enableTooltips ?? true}
      startAngle={-90}
      endAngle={90}
      innerRadius={0.5}
      colors={({ id }) => props.styles[id].color}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      tooltip={({ datum }) => (
        <BasicTooltip id={props.labels[datum.id]} value={datum.value} />
      )}
      layers={[
        "arcs",
        "arcLabels",
        "arcLinkLabels",
        "legends",
        function CenteredMetric({ centerX, centerY }): JSX.Element {
          const words = props.centerLabel?.text.split(" ") ?? [];
          const fontSize = props.centerLabel?.fontSize ?? 16;
          return (
            <>
              {words.map((word, index) => (
                <text
                  x={centerX}
                  y={centerY - (words.length - index - 1) * fontSize}
                  textAnchor="middle"
                  dominantBaseline="auto"
                  style={{
                    fontSize,
                    fontWeight: 600,
                  }}
                >
                  {word}
                </text>
              ))}
            </>
          );
        },
      ]}
      fill={Object.keys(props.styles).map((key) => ({
        id: `${key}-style`,
        match: {
          id: key,
        },
      }))}
      defs={(Object.entries(props.styles) as Array<[string, StyleConfig]>).map(
        ([key, style]) => ({
          id: `${key}-style`,
          color: style.color,
          ...(() => {
            switch (style.pattern) {
              case undefined:
              case "none":
                return {};
              case "lines":
                return {
                  type: "patternLines",
                  rotation: -45,
                  lineWidth: 2,
                  spacing: 4,
                };
              case "dots":
                return {
                  type: "patternDots",
                };
              default:
                assertNever(style.pattern);
            }
          })(),
        })
      )}
    />
  );
}
