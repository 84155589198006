import { SVGProps } from "react";

export function InfoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.5 0.25C14.457 0.25 18.5 4.29297 18.5 9.25C18.5 14.2422 14.457 18.25 9.5 18.25C4.50781 18.25 0.5 14.2422 0.5 9.25C0.5 4.29297 4.50781 0.25 9.5 0.25ZM9.5 4.75C8.86719 4.75 8.375 5.27734 8.375 5.875C8.375 6.50781 8.86719 7 9.5 7C10.0977 7 10.625 6.50781 10.625 5.875C10.625 5.27734 10.0977 4.75 9.5 4.75ZM10.9062 13.75C11.3633 13.75 11.75 13.3984 11.75 12.9062C11.75 12.4492 11.3633 12.0625 10.9062 12.0625H10.3438V8.96875C10.3438 8.51172 9.95703 8.125 9.5 8.125H8.375C7.88281 8.125 7.53125 8.51172 7.53125 8.96875C7.53125 9.46094 7.88281 9.8125 8.375 9.8125H8.65625V12.0625H8.09375C7.60156 12.0625 7.25 12.4492 7.25 12.9062C7.25 13.3984 7.60156 13.75 8.09375 13.75H10.9062Z"
        fill={props.fill || "#747676"}
      />
    </svg>
  );
}
