import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateOrganizationEvvRulesMutationVariables = Types.Exact<{
  newRules: Types.EvvRulesInput;
}>;


export type UpdateOrganizationEvvRulesMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationEVVRules: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id'>
  ) }
);


export const UpdateOrganizationEvvRulesDocument = gql`
    mutation UpdateOrganizationEVVRules($newRules: EVVRulesInput!) {
  updateOrganizationEVVRules(newRules: $newRules) {
    id
  }
}
    `;
export type UpdateOrganizationEvvRulesMutationFn = Apollo.MutationFunction<UpdateOrganizationEvvRulesMutation, UpdateOrganizationEvvRulesMutationVariables>;

/**
 * __useUpdateOrganizationEvvRulesMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationEvvRulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationEvvRulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationEvvRulesMutation, { data, loading, error }] = useUpdateOrganizationEvvRulesMutation({
 *   variables: {
 *      newRules: // value for 'newRules'
 *   },
 * });
 */
export function useUpdateOrganizationEvvRulesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationEvvRulesMutation, UpdateOrganizationEvvRulesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationEvvRulesMutation, UpdateOrganizationEvvRulesMutationVariables>(UpdateOrganizationEvvRulesDocument, options);
      }
export type UpdateOrganizationEvvRulesMutationHookResult = ReturnType<typeof useUpdateOrganizationEvvRulesMutation>;
export type UpdateOrganizationEvvRulesMutationResult = Apollo.MutationResult<UpdateOrganizationEvvRulesMutation>;
export type UpdateOrganizationEvvRulesMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationEvvRulesMutation, UpdateOrganizationEvvRulesMutationVariables>;