/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { compact } from "lodash";
import { useEffect } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";

import {
  RightDrawer,
  RightDrawerActions,
  RightDrawerContent,
} from "../../../../shared/components/right-drawer";
import { MergeCandidateAddCard } from "./merge-candidate-add-card";
import { MergeCandidateSearchCard } from "./merge-candidate-search-card";
import { useMergeCandidatesMutation } from "./merge-candidates.generated";
import { MergeCandidateFormValues } from "./types";

export interface MergeCandidatesDrawerProps {
  open: boolean;
  onClose(): void;
  onMerged(): void;
}

export function MergeCandidatesDrawer({
  open,
  onClose,
  onMerged,
}: MergeCandidatesDrawerProps): JSX.Element {
  const track = useTrack();
  const snackbar = useSnackbar();
  const [mergeCandidates, { loading: merging }] = useMergeCandidatesMutation();
  const form = useForm<MergeCandidateFormValues>({
    defaultValues: {
      intoCandidateId: "",
      fromCandidates: [{ candidateId: "" }],
    },
  });
  const {
    formState: { isSubmitting },
    reset,
  } = form;

  useEffect(() => {
    // clear form when modal is closed
    if (!open) {
      reset();
    }
  }, [open, reset]);

  const onSubmit = async (values: MergeCandidateFormValues) => {
    try {
      const fromCandidateIds = values.fromCandidates
        .map(({ candidateId }) => candidateId)
        .filter((candidateId) => !!candidateId);

      const toCandidateId = values.intoCandidateId;

      await mergeCandidates({ variables: { fromCandidateIds, toCandidateId } });
      snackbar.show({ severity: "success", message: "Successfully merged" });
      onClose();
      onMerged();
      track("Merged candidates", { fromCandidateIds, toCandidateId });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  const fromCandidates = useFieldArray({
    name: "fromCandidates",
    control: form.control,
  });

  const intoCandidateId = useWatch({
    name: "intoCandidateId",
    control: form.control,
  });

  const addedCandidates = useWatch({
    control: form.control,
    name: "fromCandidates",
  });

  const allSelectedCandidateIds = new Set(
    compact([
      intoCandidateId,
      ...addedCandidates.map((candidate) => candidate.candidateId),
    ])
  );

  return (
    <RightDrawer
      open={open}
      onClose={onClose}
      title="Merge Applications"
      disableClose={merging}
    >
      <FormProvider {...form}>
        <Form
          css={css`
            display: contents;
          `}
          onSubmit={form.handleSubmit(onSubmit)}
          submitting={isSubmitting}
        >
          <RightDrawerContent>
            <div
              css={(theme: AppTheme) => css`
                & > * {
                  margin-bottom: ${theme.spacing(3)};
                }
              `}
            >
              <MergeCandidateSearchCard
                name="intoCandidateId"
                label="Application 1"
                allSelectedCandidateIds={allSelectedCandidateIds}
                required
              />
              {fromCandidates.fields.map((field, idx) => (
                <MergeCandidateSearchCard
                  key={field.id}
                  name={`fromCandidates.${idx}.candidateId`}
                  label={`Application ${idx + 2}`}
                  allSelectedCandidateIds={allSelectedCandidateIds}
                  required={fromCandidates.fields.every(
                    (field) => !field.candidateId
                  )}
                />
              ))}
              <MergeCandidateAddCard
                onAdd={() => fromCandidates.append({ candidateId: "" })}
              />
            </div>
          </RightDrawerContent>
          <RightDrawerActions>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
              }}
              label="Cancel"
            />
            <Button type="submit" color="primary" label="Confirm" />
          </RightDrawerActions>
        </Form>
      </FormProvider>
    </RightDrawer>
  );
}
