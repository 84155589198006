/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { ManagedEmployeesRecognitionDataTable } from "./managed-employees-recognition-data-table";
import { useMyRecognitionBudgetPeriodsQuery } from "./my-recognition-budget-periods.generated";
import { PointsReceivedSummaryCard } from "./points-received-summary-card";
import { PointsSentSummaryCard } from "./points-sent-summary-card";

export function MyRecognitionSummaryPage() {
  const budgetPeriods = useMyRecognitionBudgetPeriodsQuery({
    onError: reportError,
  });

  if (budgetPeriods.error) {
    return (
      <Alert
        severity="error"
        message="An unexpected error has occurred. Please try again later."
      />
    );
  }

  if (!budgetPeriods.data) {
    return <PageLoadingState />;
  }

  const currentPeriod = budgetPeriods.data.myBudgetPeriods.items.find(
    (period) => period.current
  );

  return (
    <>
      <div
        css={(theme: AppTheme) => css`
          display: grid;
          grid-template-columns: 50% 50%;
          grid-gap: ${theme.spacing(2)};
          width: calc(100% - ${theme.spacing(2)});
        `}
      >
        <PointsSentSummaryCard period={currentPeriod} />
        {currentPeriod && <PointsReceivedSummaryCard period={currentPeriod} />}
      </div>

      <ManagedEmployeesRecognitionDataTable period={currentPeriod} />
    </>
  );
}
