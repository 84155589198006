import { useNavigate, useParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { HhaxCaribouUserSyncFiltersInput } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { useHhaxUserSyncToEditQuery } from "../../../../../../../shared/modules/employee-auto-sync/components/configure-hhax-employee-auto-sync/hhax-user-sync-to-edit.generated";
import { useUpdateHhaxCaribouUserSyncFiltersMutation } from "../../../../../../../shared/modules/employee-auto-sync/components/configure-hhax-employee-auto-sync/update-hhax-user-sync-filters.generated";
import { OnboardingEmployeeAutoSyncEditor } from "../employee-auto-sync-editor";

export function OnboardingEditEmployeeAutoSyncHHAXPage() {
  const snackbar = useSnackbar();
  const params = useParams<"sync_id">();
  const navigate = useNavigate();
  const syncId = params.sync_id!;

  const userSyncQuery = useHhaxUserSyncToEditQuery({
    onError: reportError,
    variables: {
      userSyncId: syncId,
    },
  });

  const [updateFiltersMutation] = useUpdateHhaxCaribouUserSyncFiltersMutation();

  const onSubmit = async (filters: HhaxCaribouUserSyncFiltersInput) => {
    try {
      await updateFiltersMutation({
        variables: {
          userSyncId: syncId,
          filters,
        },
      });

      snackbar.show({
        severity: "success",
        message: "Employee auto-sync has been updated",
      });

      // navigate back to main integrations screen
      navigate("/onboarding/employee-auto-sync");
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  if (userSyncQuery.error) {
    return (
      <Alert
        severity="error"
        message="An unexpected error occurred. Please try again later."
      />
    );
  }

  if (!userSyncQuery.data) {
    return <PageLoadingState />;
  }

  const sync = userSyncQuery.data.getHHAXCaribouUserSyncById;

  return (
    <OnboardingEmployeeAutoSyncEditor
      integrationId={sync.hhaxIntegrationId}
      onSubmit={onSubmit}
      initialFilters={{
        disciplines: sync.filters?.disciplines ?? undefined,
        branchIds:
          sync.filters?.branches.map(({ branchId }) => branchId ?? null) ??
          undefined,
        officeIds:
          sync.filters?.offices.map(({ officeId }) => officeId ?? null) ??
          undefined,
        teamIds:
          sync.filters?.teams.map(({ teamId }) => teamId ?? null) ?? undefined,
        hasCompletedFirstShift:
          sync.filters?.hasCompletedFirstShift ?? undefined,
      }}
    />
  );
}
