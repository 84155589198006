import * as React from "react";

export default function MomentsLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6 19.05L12.5 19.15L12.39 19.05C7.64 14.74 4.5 11.89 4.5 9C4.5 7 6 5.5 8 5.5C9.54 5.5 11.04 6.5 11.57 7.86H13.43C13.96 6.5 15.46 5.5 17 5.5C19 5.5 20.5 7 20.5 9C20.5 11.89 17.36 14.74 12.6 19.05ZM17 3.5C15.26 3.5 13.59 4.31 12.5 5.58C11.41 4.31 9.74 3.5 8 3.5C4.92 3.5 2.5 5.91 2.5 9C2.5 12.77 5.9 15.86 11.05 20.53L12.5 21.85L13.95 20.53C19.1 15.86 22.5 12.77 22.5 9C22.5 5.91 20.08 3.5 17 3.5Z"
        fill={props.color}
      />
    </svg>
  );
}
