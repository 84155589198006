/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

export function SuccessPageContents({
  totalRecords,
}: {
  totalRecords: number;
}) {
  return (
    <>
      <Typography
        variant="body"
        color="grey.800"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(5)};
        `}
      >
        Congratulations, existing and new employees will now be invited to
        Caribou.
      </Typography>
      <Card
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(5)};
        `}
      >
        <Typography
          variant="h3"
          color="textPrimary"
          fontWeight={700}
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(4)};
          `}
        >
          🎉 Employee contacts are being imported!
        </Typography>
        <Typography
          variant="h6"
          fontWeight={700}
          color="textPrimary"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          {totalRecords} employees will be invited to Caribou Rewards
        </Typography>
        <Typography
          variant="body"
          color="grey.800"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          The import process may take up to 20 minutes, depending on the number
          of employee records. Employee invites will be sent out upon program
          launch.
        </Typography>
        <Typography variant="body" color="grey.800">
          If an employee is missing from the list please contact
          help@caribou.care for assistance.
        </Typography>
      </Card>
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          justify-content: flex-end;
          margin-top: ${theme.spacing(3)};
        `}
      >
        <Button
          label="Done"
          linkTo="/employees/auto-sync"
          color="primary"
          size="large"
          width="auto"
        />
      </div>
    </>
  );
}
