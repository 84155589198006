/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Slide } from "@material-ui/core";
import MuiSnackbar from "@material-ui/core/Snackbar";
import { TransitionProps } from "@material-ui/core/transitions";

import { Alert } from "@rewards-web/shared/components/alert";
import { AppTheme } from "@rewards-web/shared/style/theme";

export type SnackbarSeverity = "success" | "error" | "warning";

const DEFAULT_AUTO_HIDE_DURATION_MS = 3000;
const MOBILE_TAB_NAVIGATION_HEIGHT = 62;
const DEFAULT_SNACKBAR_SEVERITY: SnackbarSeverity = "success";

export interface SnackbarProps {
  open: boolean;
  onClose(): void;
  onExited(): void;
  position?: "bottom" | "top";
  greyscale?: boolean;
  smaller?: boolean;
  adjustedForMobile?: boolean;
  message: string;
  severity?: SnackbarSeverity;
  durationMs?: number;
}

export function Snackbar({
  open,
  onClose,
  onExited,
  position = "bottom",
  greyscale = false,
  smaller = false,
  adjustedForMobile,
  message,
  severity,
  durationMs,
}: SnackbarProps) {
  return (
    <MuiSnackbar
      css={
        adjustedForMobile
          ? (theme: AppTheme) => css`
              ${theme.breakpoints.down("md")} {
                margin-bottom: ${MOBILE_TAB_NAVIGATION_HEIGHT}px;
              }
            `
          : undefined
      }
      open={open}
      anchorOrigin={{
        vertical: position,
        horizontal: "center",
      }}
      TransitionComponent={
        position === "bottom" ? SlideUpTransition : SlideDownTransition
      }
      TransitionProps={{ onExited }}
      autoHideDuration={durationMs ?? DEFAULT_AUTO_HIDE_DURATION_MS}
      onClose={onClose}
    >
      <Alert
        severity={severity ?? DEFAULT_SNACKBAR_SEVERITY}
        message={message}
        onClose={onClose}
        variant="filled"
        css={css`
          ${greyscale &&
          css`
            background-color: #434546;
          `}

          ${smaller &&
          css`
            padding: 2px 16px;
          `}
        `}
      />
    </MuiSnackbar>
  );
}

function SlideUpTransition(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

function SlideDownTransition(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}
