/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { AppTheme } from "@rewards-web/shared/style/theme";

import {
  EDIT_ADMIN_PAGE_TABS,
  EditAdminPageTabName,
} from "../edit-admin-navigation-tabs";

interface EditAdminNavigationTabPanelProps {
  currentTab: number;
  tabName: EditAdminPageTabName;
  children?: React.ReactNode;
}

export function EditAdminNavigationTabPanel({
  currentTab,
  tabName,
  children,
}: EditAdminNavigationTabPanelProps): JSX.Element {
  return (
    <div
      role="tabpanel"
      hidden={currentTab !== EDIT_ADMIN_PAGE_TABS.indexOf(tabName)}
      id={`edit-admin-tabpanel-${tabName}`}
      aria-labelledby={`Edit administrator tabs - ${tabName}`}
    >
      {currentTab === EDIT_ADMIN_PAGE_TABS.indexOf(tabName) && (
        <div
          css={(theme: AppTheme) => css`
            max-width: 861px;
            padding: ${theme.spacing(3)};
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          {children}
        </div>
      )}
    </div>
  );
}
