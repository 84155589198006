import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RecognitionInsightMetricGroupDataQueryVariables = Types.Exact<{
  startDate: Types.Scalars['Timestamp'];
  endDate: Types.Scalars['Timestamp'];
}>;


export type RecognitionInsightMetricGroupDataQuery = (
  { __typename?: 'Query' }
  & { getRecognizedEmployeesInsightMetric: (
    { __typename?: 'RecognizedEmployeesInsightMetric' }
    & Pick<Types.RecognizedEmployeesInsightMetric, 'id' | 'recognizedEmployeeCount' | 'activeUserCount'>
  ), getRecognitionAwardedInsightMetric: (
    { __typename?: 'RecognitionPointsInsightMetric' }
    & Pick<Types.RecognitionPointsInsightMetric, 'id' | 'bonusPointsAwarded'>
  ), getBudgetUsageInsightMetric: (
    { __typename?: 'BudgetUsageInsightMetric' }
    & Pick<Types.BudgetUsageInsightMetric, 'id' | 'pointsSentByAdminsWithBudgets' | 'adminBudgetTotal'>
  ), getAdminsUsingRecognitionInsightMetric: (
    { __typename?: 'AdminsUsingRecognitionInsightMetric' }
    & Pick<Types.AdminsUsingRecognitionInsightMetric, 'id' | 'adminsUsingRecognitionCount'>
  ) }
);


export const RecognitionInsightMetricGroupDataDocument = gql`
    query RecognitionInsightMetricGroupData($startDate: Timestamp!, $endDate: Timestamp!) {
  getRecognizedEmployeesInsightMetric(startDate: $startDate, endDate: $endDate) {
    id
    recognizedEmployeeCount
    activeUserCount
  }
  getRecognitionAwardedInsightMetric(startDate: $startDate, endDate: $endDate) {
    id
    bonusPointsAwarded
  }
  getBudgetUsageInsightMetric(startDate: $startDate, endDate: $endDate) {
    id
    pointsSentByAdminsWithBudgets
    adminBudgetTotal
  }
  getAdminsUsingRecognitionInsightMetric(startDate: $startDate, endDate: $endDate) {
    id
    adminsUsingRecognitionCount
  }
}
    `;

/**
 * __useRecognitionInsightMetricGroupDataQuery__
 *
 * To run a query within a React component, call `useRecognitionInsightMetricGroupDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecognitionInsightMetricGroupDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecognitionInsightMetricGroupDataQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useRecognitionInsightMetricGroupDataQuery(baseOptions: Apollo.QueryHookOptions<RecognitionInsightMetricGroupDataQuery, RecognitionInsightMetricGroupDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecognitionInsightMetricGroupDataQuery, RecognitionInsightMetricGroupDataQueryVariables>(RecognitionInsightMetricGroupDataDocument, options);
      }
export function useRecognitionInsightMetricGroupDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecognitionInsightMetricGroupDataQuery, RecognitionInsightMetricGroupDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecognitionInsightMetricGroupDataQuery, RecognitionInsightMetricGroupDataQueryVariables>(RecognitionInsightMetricGroupDataDocument, options);
        }
export type RecognitionInsightMetricGroupDataQueryHookResult = ReturnType<typeof useRecognitionInsightMetricGroupDataQuery>;
export type RecognitionInsightMetricGroupDataLazyQueryHookResult = ReturnType<typeof useRecognitionInsightMetricGroupDataLazyQuery>;
export type RecognitionInsightMetricGroupDataQueryResult = Apollo.QueryResult<RecognitionInsightMetricGroupDataQuery, RecognitionInsightMetricGroupDataQueryVariables>;