import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PulseSurveyQuestionScoreCardDataQueryVariables = Types.Exact<{
  questionType: Types.SurveyQuestionType;
  dateRange?: Types.Maybe<Types.SurveyInsightsDateRangeInput>;
}>;


export type PulseSurveyQuestionScoreCardDataQuery = (
  { __typename?: 'Query' }
  & { getSurveyQuestionResults: (
    { __typename?: 'SurveyQuestionResultsResponse' }
    & Pick<Types.SurveyQuestionResultsResponse, 'averageScore' | 'numComments'>
    & { previousAverageScores?: Types.Maybe<Array<(
      { __typename?: 'SurveyQuestionPreviousResultsResponse' }
      & Pick<Types.SurveyQuestionPreviousResultsResponse, 'averageScore'>
      & { dateRange: (
        { __typename?: 'SurveyInsightsDateRange' }
        & Pick<Types.SurveyInsightsDateRange, 'label' | 'year'>
      ) }
    )>> }
  ) }
);


export const PulseSurveyQuestionScoreCardDataDocument = gql`
    query PulseSurveyQuestionScoreCardData($questionType: SurveyQuestionType!, $dateRange: SurveyInsightsDateRangeInput) {
  getSurveyQuestionResults(
    surveyType: PULSE_CHECK
    questionType: $questionType
    dateRange: $dateRange
  ) {
    averageScore
    numComments
    previousAverageScores {
      dateRange {
        label
        year
      }
      averageScore
    }
  }
}
    `;

/**
 * __usePulseSurveyQuestionScoreCardDataQuery__
 *
 * To run a query within a React component, call `usePulseSurveyQuestionScoreCardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePulseSurveyQuestionScoreCardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePulseSurveyQuestionScoreCardDataQuery({
 *   variables: {
 *      questionType: // value for 'questionType'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function usePulseSurveyQuestionScoreCardDataQuery(baseOptions: Apollo.QueryHookOptions<PulseSurveyQuestionScoreCardDataQuery, PulseSurveyQuestionScoreCardDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PulseSurveyQuestionScoreCardDataQuery, PulseSurveyQuestionScoreCardDataQueryVariables>(PulseSurveyQuestionScoreCardDataDocument, options);
      }
export function usePulseSurveyQuestionScoreCardDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PulseSurveyQuestionScoreCardDataQuery, PulseSurveyQuestionScoreCardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PulseSurveyQuestionScoreCardDataQuery, PulseSurveyQuestionScoreCardDataQueryVariables>(PulseSurveyQuestionScoreCardDataDocument, options);
        }
export type PulseSurveyQuestionScoreCardDataQueryHookResult = ReturnType<typeof usePulseSurveyQuestionScoreCardDataQuery>;
export type PulseSurveyQuestionScoreCardDataLazyQueryHookResult = ReturnType<typeof usePulseSurveyQuestionScoreCardDataLazyQuery>;
export type PulseSurveyQuestionScoreCardDataQueryResult = Apollo.QueryResult<PulseSurveyQuestionScoreCardDataQuery, PulseSurveyQuestionScoreCardDataQueryVariables>;