import {
  isEqual,
  format,
  formatDistance,
  parseISO,
  startOfDay,
  sub,
} from "date-fns";
import { last, reverse } from "lodash";

import {
  CandidateRecruitmentStep,
  CandidateRecruitmentStepName,
  CandidateStep,
  ReferralRewardStructure,
  ReferralRewardStructureReferralRetentionItemDurationUnit,
} from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";

import { CandidateListDetailsFragment } from "../candidate-fragment.generated";

export type DisplayStep = {
  status: "future" | "completed" | "actionable" | "archived";
} & (
  | {
      type: "CandidateRecruitmentStep";
      stepName: CandidateRecruitmentStepName;
      date?: Date;
    }
  | {
      type: "CandidateMonthlyRetentionStep";
      durationMonths: number;
      date?: Date;
    }
  | {
      type: "CandidateHourlyRetentionStep";
      durationHours: number;
      date?: Date;
    }
);

function getCompletedCandidateSteps(
  candidate: Pick<CandidateListDetailsFragment, "completedStepsV2">
) {
  return candidate.completedStepsV2.map(
    (step): DisplayStep => {
      if (!step.__typename) {
        throw new Error("Step does not have __typename");
      }

      const status = "completed" as const;

      switch (step.__typename) {
        case "RecordedCandidateRecruitmentStep":
          return {
            status,
            type: "CandidateRecruitmentStep" as const,
            stepName: step.stepName,
            date: new Date(step.recordedAt!),
          };
        case "RecordedCandidateMonthlyRetentionStep":
          return {
            status,
            type: "CandidateMonthlyRetentionStep" as const,
            durationMonths: step.durationMonths,
            date: new Date(step.recordedAt!),
          };
        case "RecordedCandidateHourlyRetentionStep":
          return {
            status,
            type: "CandidateHourlyRetentionStep" as const,
            durationHours: step.durationHours,
            date: new Date(step.recordedAt!),
          };
        default:
          assertNever(
            step.__typename,
            "Unrecognized Candidate Recorded Step type"
          );
      }

      throw new Error("Unreachable");
    }
  );
}

/**
 * Gets the most recently completed step for a candidate,
 * including "Application submitted" step.
 */
export function getMostRecentCandidateStep(
  candidate: Pick<CandidateListDetailsFragment, "completedStepsV2">
) {
  return last(getCompletedCandidateSteps(candidate));
}

/**
 * Gets steps to display for candidates list cards
 */
export function getActionableStepsToDisplay(
  candidate: Pick<
    CandidateListDetailsFragment,
    "completedStepsV2" | "archived" | "archivedAt"
  >,
  organizationSteps: CandidateStep[],
  organizationReferralRewardStructure: Pick<
    ReferralRewardStructure,
    "referralRecruitmentStructure" | "referralRetentionStructure"
  >
): DisplayStep[] {
  const stepsToDisplay = getCompletedCandidateSteps(candidate).filter(
    (step) =>
      !(
        step.type === "CandidateRecruitmentStep" &&
        step.stepName === CandidateRecruitmentStepName.ApplicationSubmitted
      )
  );

  /**
   * Removes the `ApplicationSubmitted` step from organization steps because the candidate would surely have completed it sa they appear here only after that.
   * Since they have already completed `ApplicationSubmitted` step, that step is no longer actionable.
   */
  const organizationRecruitmentStepsToDisplay = organizationSteps
    .filter(
      (step): step is CandidateRecruitmentStep =>
        step.__typename === "CandidateRecruitmentStep"
    )
    .filter(
      (step) =>
        step.stepName !== CandidateRecruitmentStepName.ApplicationSubmitted
    );

  /**
   * Ideally, only `organizationReferralRewardStructure` should be used to display the steps to the user because it is extendable to support all kinds of steps.
   * But, currently, the `organizationReferralRewardStructure` doesn't include unrewarded recruitment steps that can be tracked from the admin app.
   * So, we extract recruitmentSteps from the `organizationSteps` prop here, which comes from a property named `candidateSteps` on the organization object.
   *
   * Eventually, we should remove the `candidateSteps` property from the organization object and use only `organizationReferralRewardStructure` to display all the referral steps.
   */
  const organizationStepsToDisplay = [
    ...organizationRecruitmentStepsToDisplay,
    ...organizationReferralRewardStructure.referralRetentionStructure,
  ];

  const indexOfLastRecordedCandidateStepMatchingOrganizationSteps = reverse(
    stepsToDisplay.slice()
  ).reduce((prev, completedCandidateStep) => {
    if (prev !== -1) {
      return prev;
    }

    switch (completedCandidateStep.type) {
      case "CandidateRecruitmentStep":
        return organizationStepsToDisplay.findIndex((orgStep) => {
          return (
            orgStep.__typename === "CandidateRecruitmentStep" &&
            completedCandidateStep.stepName === orgStep.stepName
          );
        });
      case "CandidateMonthlyRetentionStep":
        return organizationStepsToDisplay.findIndex(
          (orgStep) =>
            orgStep.__typename ===
              "ReferralRewardStructureReferralRetentionItem" &&
            orgStep.duration.unit ===
              ReferralRewardStructureReferralRetentionItemDurationUnit.Months &&
            completedCandidateStep.durationMonths === orgStep.duration.amount
        );
      case "CandidateHourlyRetentionStep":
        return organizationStepsToDisplay.findIndex(
          (orgStep) =>
            orgStep.__typename ===
              "ReferralRewardStructureReferralRetentionItem" &&
            orgStep.duration.unit ===
              ReferralRewardStructureReferralRetentionItemDurationUnit.Hours &&
            completedCandidateStep.durationHours === orgStep.duration.amount
        );
      default:
        assertNever(
          completedCandidateStep,
          `Unrecognized candidate step type: ${
            (completedCandidateStep as DisplayStep).type
          }`
        );
    }

    throw new Error("Unreachable");
  }, -1);

  const remainingSteps = (indexOfLastRecordedCandidateStepMatchingOrganizationSteps ===
  -1
    ? organizationStepsToDisplay
    : organizationStepsToDisplay.filter(
        (_, index) =>
          index > indexOfLastRecordedCandidateStepMatchingOrganizationSteps
      )
  ).map((step, index) => {
    const status = (() => {
      if (index > 0) {
        return "future" as const;
      }

      if (index === 0 && candidate.archived) {
        return "archived" as const;
      }

      return "actionable" as const;
    })();

    const date =
      status === "archived" ? new Date(candidate.archivedAt) : undefined;

    if (!step.__typename) {
      throw new Error("Step does not have __typename");
    }

    if (step.__typename === "CandidateRecruitmentStep") {
      return {
        status,
        type: "CandidateRecruitmentStep" as const,
        stepName: step.stepName,
        date,
      };
    } else if (
      step.__typename === "ReferralRewardStructureReferralRetentionItem" &&
      step.duration.unit ===
        ReferralRewardStructureReferralRetentionItemDurationUnit.Months
    ) {
      return {
        status,
        type: "CandidateMonthlyRetentionStep" as const,
        durationMonths: step.duration.amount,
        date,
      };
    } else if (
      step.__typename === "ReferralRewardStructureReferralRetentionItem" &&
      step.duration.unit ===
        ReferralRewardStructureReferralRetentionItemDurationUnit.Hours
    ) {
      return {
        status,
        type: "CandidateHourlyRetentionStep" as const,
        durationHours: step.duration.amount,
        date,
      };
    } else {
      throw new Error(`Unrecognized step type: ${step.__typename}`);
    }
  });

  return [...stepsToDisplay, ...remainingSteps];
}

export function getCompletedStepLabel(step: DisplayStep) {
  switch (step.type) {
    case "CandidateRecruitmentStep": {
      switch (step.stepName) {
        case CandidateRecruitmentStepName.ApplicationSubmitted:
          return "Application submitted";
        case CandidateRecruitmentStepName.Contacted:
          return "Contacted";
        case CandidateRecruitmentStepName.InterviewScheduled:
          return "Interview scheduled";
        case CandidateRecruitmentStepName.InterviewSuccessful:
          return "Offer extended";
        case CandidateRecruitmentStepName.Hired:
          return "Hired";
        case CandidateRecruitmentStepName.StartedWork:
          return "Candidate started working";
        case CandidateRecruitmentStepName.StartedOrientation:
          return "Candidate started orientation";
        case CandidateRecruitmentStepName.CompletedOrientation:
          return "Candidate completed orientation";
        case CandidateRecruitmentStepName.CompletedFirstShift:
          return "Candidate completed first shift";
        default:
          return `${step.stepName}`;
      }
    }

    case "CandidateMonthlyRetentionStep":
      return `Candidate has worked for ${numberWithCommas(
        step.durationMonths
      )} months`;

    case "CandidateHourlyRetentionStep":
      return `Candidate has worked for ${numberWithCommas(
        step.durationHours
      )} hours`;

    default: {
      throw new Error(
        `Could not recognize step type ${(step as DisplayStep).type}`
      );
    }
  }
}

export function getActionableStepLabel(step: DisplayStep) {
  switch (step.type) {
    case "CandidateRecruitmentStep": {
      switch (step.stepName) {
        case CandidateRecruitmentStepName.Contacted:
          return "Was the candidate contacted?";
        case CandidateRecruitmentStepName.InterviewScheduled:
          return "Was an interview scheduled?";
        case CandidateRecruitmentStepName.InterviewSuccessful:
          return "Was an offer extended?";
        case CandidateRecruitmentStepName.Hired:
          return "Was the candidate hired?";
        case CandidateRecruitmentStepName.StartedWork:
          return "Has the candidate started working?";
        case CandidateRecruitmentStepName.StartedOrientation:
          return "Did the candidate start orientation?";
        case CandidateRecruitmentStepName.CompletedOrientation:
          return "Did the candidate complete orientation?";
        case CandidateRecruitmentStepName.CompletedFirstShift:
          return "Did the candidate complete their first shift?";
        default:
          return `${step.stepName}?`;
      }
    }
    case "CandidateMonthlyRetentionStep":
      return `Has the candidate worked for ${numberWithCommas(
        step.durationMonths
      )} months?`;

    case "CandidateHourlyRetentionStep":
      return `Has the candidate worked for ${numberWithCommas(
        step.durationHours
      )} hours?`;

    default: {
      throw new Error(
        `Could not recognize step type ${(step as DisplayStep).type}`
      );
    }
  }
}

export function getArchivedStepLabel(step: DisplayStep) {
  switch (step.type) {
    case "CandidateRecruitmentStep": {
      switch (step.stepName) {
        case CandidateRecruitmentStepName.Contacted:
          return "Candidate not contacted";
        case CandidateRecruitmentStepName.InterviewScheduled:
          return "Interview not scheduled";
        case CandidateRecruitmentStepName.InterviewSuccessful:
          return "Offer not extended";
        case CandidateRecruitmentStepName.Hired:
          return "Candidate not hired";
        case CandidateRecruitmentStepName.StartedWork:
          return "Candidate has not started working";
        case CandidateRecruitmentStepName.StartedOrientation:
          return "Did not start orientation";
        case CandidateRecruitmentStepName.CompletedOrientation:
          return "Did not complete orientation";
        case CandidateRecruitmentStepName.CompletedFirstShift:
          return "Did not complete first shift";
        default:
          return `${step.stepName}?`;
      }
    }

    case "CandidateMonthlyRetentionStep":
      return `Candidate did not work for ${numberWithCommas(
        step.durationMonths
      )} months`;

    case "CandidateHourlyRetentionStep":
      return `Candidate did not work for ${numberWithCommas(
        step.durationHours
      )} hours`;

    default: {
      throw new Error(
        `Could not recognize step type ${(step as DisplayStep).type}`
      );
    }
  }
}

export function formatCandidateActionDate(date: Date) {
  return format(date, "MMM d yyyy");
}

export function formatCandidateApplicationDateDistance(
  applicationDate: string
): string {
  const startOfToday = startOfDay(new Date());
  const startOfApplicationDay = parseISO(applicationDate);

  if (startOfApplicationDay >= startOfToday) {
    return "Applied Today";
  }

  const startOfYesterday = sub(startOfDay(new Date()), { days: 1 });
  if (isEqual(startOfApplicationDay, startOfYesterday)) {
    return "Applied Yesterday";
  }

  return `Applied ${formatDistance(startOfApplicationDay, startOfToday, {
    addSuffix: true,
  })}`;
}
