import { useCallback, useMemo } from "react";

import { useQueryParam } from "@rewards-web/shared/hooks/use-query-param";
import {
  serializeBooleanQueryParam,
  parseBooleanQueryParam,
} from "@rewards-web/shared/lib/query-param-utils";

export interface EmployeeStatusFilterState {
  active?: boolean | null;
  providedPersonalInfo?: boolean | null;
}

type UpdateFilterInput = (
  nextFilters:
    | EmployeeStatusFilterState
    | ((prev: EmployeeStatusFilterState) => EmployeeStatusFilterState)
) => void;

export function useEmployeeStatusFilterState(): [
  EmployeeStatusFilterState,
  UpdateFilterInput
] {
  const [activeQueryParam, setActiveQueryParam] = useQueryParam("active");
  const [
    providedPersonalInfoQueryParam,
    setProvidedPersonalInfoQueryParam,
  ] = useQueryParam("signedUp");

  const value = useMemo((): EmployeeStatusFilterState => {
    return {
      active: parseBooleanQueryParam(activeQueryParam),
      providedPersonalInfo: parseBooleanQueryParam(
        providedPersonalInfoQueryParam
      ),
    };
  }, [activeQueryParam, providedPersonalInfoQueryParam]);

  const handleUpdate = useCallback<UpdateFilterInput>(
    (updaterOrNextValue) => {
      const nextValue =
        typeof updaterOrNextValue === "function"
          ? updaterOrNextValue(value ?? {})
          : updaterOrNextValue;

      setActiveQueryParam(serializeBooleanQueryParam(nextValue.active));
      setProvidedPersonalInfoQueryParam(
        serializeBooleanQueryParam(nextValue.providedPersonalInfo)
      );
    },
    [setActiveQueryParam, setProvidedPersonalInfoQueryParam, value]
  );

  return [value, handleUpdate];
}
