import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListRewardsAdminsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  offset: Types.Scalars['Int'];
  branchIds?: Types.Maybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  searchQuery?: Types.Maybe<Types.Scalars['String']>;
}>;


export type ListRewardsAdminsQuery = (
  { __typename?: 'Query' }
  & { listRewardsAdmins: (
    { __typename?: 'ListRewardsAdminsResponse' }
    & Pick<Types.ListRewardsAdminsResponse, 'total'>
    & { items: Array<(
      { __typename?: 'RewardsAdminUser' }
      & Pick<Types.RewardsAdminUser, 'id' | 'firstName' | 'lastName' | 'active' | 'email' | 'jobTitle' | 'role'>
      & { permissions: (
        { __typename?: 'RewardsAdminFullAccessPermissions' }
        & Pick<Types.RewardsAdminFullAccessPermissions, 'type'>
      ) | (
        { __typename?: 'RewardsAdminRestrictedAccessPermissions' }
        & Pick<Types.RewardsAdminRestrictedAccessPermissions, 'type'>
        & { permissionsV2?: Types.Maybe<(
          { __typename?: 'RewardsAdminRestrictedAccessPermissionsMapV2' }
          & { jobs?: Types.Maybe<(
            { __typename?: 'RewardsAdminRestrictedAccessJobsPermissions' }
            & Pick<Types.RewardsAdminRestrictedAccessJobsPermissions, 'permissionLevel'>
          )>, candidates?: Types.Maybe<(
            { __typename?: 'RewardsAdminRestrictedAccessCandidatesPermissions' }
            & Pick<Types.RewardsAdminRestrictedAccessCandidatesPermissions, 'permissionLevel'>
          )>, redemptions?: Types.Maybe<(
            { __typename?: 'RewardsAdminRestrictedAccessRedemptionsPermissions' }
            & Pick<Types.RewardsAdminRestrictedAccessRedemptionsPermissions, 'permissionLevel'>
          )>, recognitionPoints?: Types.Maybe<(
            { __typename?: 'RewardsAdminRestrictedAccessRecognitionPointsPermissions' }
            & Pick<Types.RewardsAdminRestrictedAccessRecognitionPointsPermissions, 'permissionLevel'>
          )>, employees?: Types.Maybe<(
            { __typename?: 'RewardsAdminRestrictedAccessEmployeesPermissions' }
            & Pick<Types.RewardsAdminRestrictedAccessEmployeesPermissions, 'permissionLevel'>
          )>, administrators?: Types.Maybe<(
            { __typename?: 'RewardsAdminRestrictedAccessAdministratorsPermissions' }
            & Pick<Types.RewardsAdminRestrictedAccessAdministratorsPermissions, 'permissionLevel'>
          )> }
        )> }
      ), branches: Array<(
        { __typename?: 'RewardsOrganizationBranch' }
        & Pick<Types.RewardsOrganizationBranch, 'id' | 'name'>
      )> }
    )> }
  ) }
);


export const ListRewardsAdminsDocument = gql`
    query ListRewardsAdmins($limit: Int!, $offset: Int!, $branchIds: [ID!], $searchQuery: String) {
  listRewardsAdmins(
    limit: $limit
    offset: $offset
    filter: {branchIds: $branchIds, searchQuery: $searchQuery}
  ) {
    total
    items {
      id
      firstName
      lastName
      active
      email
      jobTitle
      role
      permissions {
        ... on RewardsAdminFullAccessPermissions {
          type
        }
        ... on RewardsAdminRestrictedAccessPermissions {
          type
          permissionsV2 {
            jobs {
              permissionLevel
            }
            candidates {
              permissionLevel
            }
            redemptions {
              permissionLevel
            }
            recognitionPoints {
              permissionLevel
            }
            employees {
              permissionLevel
            }
            administrators {
              permissionLevel
            }
          }
        }
      }
      active
      branches {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useListRewardsAdminsQuery__
 *
 * To run a query within a React component, call `useListRewardsAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRewardsAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRewardsAdminsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      branchIds: // value for 'branchIds'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useListRewardsAdminsQuery(baseOptions: Apollo.QueryHookOptions<ListRewardsAdminsQuery, ListRewardsAdminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListRewardsAdminsQuery, ListRewardsAdminsQueryVariables>(ListRewardsAdminsDocument, options);
      }
export function useListRewardsAdminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListRewardsAdminsQuery, ListRewardsAdminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListRewardsAdminsQuery, ListRewardsAdminsQueryVariables>(ListRewardsAdminsDocument, options);
        }
export type ListRewardsAdminsQueryHookResult = ReturnType<typeof useListRewardsAdminsQuery>;
export type ListRewardsAdminsLazyQueryHookResult = ReturnType<typeof useListRewardsAdminsLazyQuery>;
export type ListRewardsAdminsQueryResult = Apollo.QueryResult<ListRewardsAdminsQuery, ListRewardsAdminsQueryVariables>;