import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SideMenuDataQueryVariables = Types.Exact<{
  canEditRecognition: Types.Scalars['Boolean'];
  canViewSurveys: Types.Scalars['Boolean'];
}>;


export type SideMenuDataQuery = (
  { __typename?: 'Query' }
  & { scheduledRecognitionPointsWithNoMessage: Types.Maybe<(
    { __typename?: 'ListMyManagerScheduledRecognitionPointsResponse' }
    & Pick<Types.ListMyManagerScheduledRecognitionPointsResponse, 'total'>
  )>, getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'drawsEnabled' | 'socialFeedEnabled'>
  ), getSurveyEngagement?: Types.Maybe<(
    { __typename?: 'SurveyEngagementResponse' }
    & Pick<Types.SurveyEngagementResponse, 'numSent'>
  )> }
);


export const SideMenuDataDocument = gql`
    query SideMenuData($canEditRecognition: Boolean!, $canViewSurveys: Boolean!) {
  scheduledRecognitionPointsWithNoMessage: listMyManagerScheduledRecognitionPoints(
    limit: 0
    offset: 0
    filter: {hasMessage: false}
  ) @include(if: $canEditRecognition) {
    total
  }
  getMyRewardsOrganization {
    id
    drawsEnabled
    socialFeedEnabled
  }
  getSurveyEngagement(surveyType: PULSE_CHECK) @include(if: $canViewSurveys) {
    numSent
  }
}
    `;

/**
 * __useSideMenuDataQuery__
 *
 * To run a query within a React component, call `useSideMenuDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSideMenuDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSideMenuDataQuery({
 *   variables: {
 *      canEditRecognition: // value for 'canEditRecognition'
 *      canViewSurveys: // value for 'canViewSurveys'
 *   },
 * });
 */
export function useSideMenuDataQuery(baseOptions: Apollo.QueryHookOptions<SideMenuDataQuery, SideMenuDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SideMenuDataQuery, SideMenuDataQueryVariables>(SideMenuDataDocument, options);
      }
export function useSideMenuDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SideMenuDataQuery, SideMenuDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SideMenuDataQuery, SideMenuDataQueryVariables>(SideMenuDataDocument, options);
        }
export type SideMenuDataQueryHookResult = ReturnType<typeof useSideMenuDataQuery>;
export type SideMenuDataLazyQueryHookResult = ReturnType<typeof useSideMenuDataLazyQuery>;
export type SideMenuDataQueryResult = Apollo.QueryResult<SideMenuDataQuery, SideMenuDataQueryVariables>;