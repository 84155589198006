/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

export function FileInputError(props: { error: string }) {
  return (
    <div
      css={(theme: AppTheme) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: ${theme.spacing(2)};
        border: 1px solid ${theme.palette.divider};
        border-top: none;
        border-bottom-left-radius: ${theme.shape.borderRadius}px;
        border-bottom-right-radius: ${theme.shape.borderRadius}px;
        border-color: ${theme.palette.error.main};
      `}
    >
      <Typography
        variant="caption"
        css={(theme: AppTheme) => css`
          font-size: 0.83em;
          font-style: italic;
          color: ${theme.palette.error.main};
          font-weight: 800;
          font-style: initial;
        `}
      >
        {props.error}
      </Typography>
    </div>
  );
}
