/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface ConfirmDuplicateModalProps {
  open: boolean;
  onCancel(): void;
  onConfirm(): void;
  description: string;
}

export function ConfirmDuplicateModal({
  open,
  onCancel,
  onConfirm,
  description,
}: ConfirmDuplicateModalProps): JSX.Element {
  return (
    <Modal width="488px" open={open} onClose={onCancel}>
      <ModalTitle>Create duplicate?</ModalTitle>
      <ModalContent>
        <Typography
          variant="body"
          color="textPrimary"
          css={(theme: AppTheme) =>
            css`
              padding-bottom: ${theme.spacing(2)};
            `
          }
        >
          {description}
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          size="large"
          label="Cancel"
          variant="outlined"
          onClick={() => {
            onCancel();
          }}
        />
        <Button size="large" label="Next" color="primary" onClick={onConfirm} />
      </ModalActions>
    </Modal>
  );
}
