/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faCamcorder,
  faInfoCircle,
  faFilmAlt,
  faPlayCircle,
  IconDefinition,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Card, CardContent } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

export function AnnouncementVideoInfoCard() {
  return (
    <Card variant="flat" backgroundColor="grey">
      <CardContent>
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            align-items: center;
            padding: ${theme.spacing(1)};
          `}
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            size="xl"
            css={(theme: AppTheme) => css`
              margin-right: ${theme.spacing(1)};
            `}
          />
          <Typography variant="subtitle" color="textPrimary">
            Tips for a short and effective video
          </Typography>
        </div>

        <div
          css={css`
            display: flex;
            align-items: stretch;
          `}
        >
          <Tip
            faIcon={faFilmAlt}
            title="Drive Excitement"
            description="Include key information such as number of winners, prizes and end date."
          />

          <Tip
            faIcon={faCamcorder}
            title="Educate"
            description="Briefly state how employees earn tickets and why this matters to your organization."
          />

          <Tip
            faIcon={faPlayCircle}
            title="Call to action"
            description='End on a direct call to action like "Share jobs today" to create a sense of urgency.'
          />
        </div>
      </CardContent>
    </Card>
  );
}

interface TipProps {
  faIcon: IconDefinition;
  title: string;
  description: string;
}

function Tip(props: TipProps) {
  return (
    <div
      css={(theme: AppTheme) => css`
        padding: ${theme.spacing(1)};
      `}
    >
      <FontAwesomeIcon
        icon={props.faIcon}
        size="xl"
        css={css`
          padding-bottom: 2px;
        `}
      />

      <Typography
        css={css`
          padding-bottom: 2px;
        `}
        variant="subtitle"
        color="textPrimary"
      >
        {props.title}
      </Typography>

      <Typography>{props.description}</Typography>
    </div>
  );
}
