import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EnabledRecognitionPageTabsDataQueryVariables = Types.Exact<{
  month: Types.Scalars['MonthAndYear'];
}>;


export type EnabledRecognitionPageTabsDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'recognitionBudgetsEnabled'>
  ), listMyManagedEmployeesRecognitionSummary: (
    { __typename?: 'ListMyManagedEmployeesRecognitionSummaryResponse' }
    & Pick<Types.ListMyManagedEmployeesRecognitionSummaryResponse, 'total'>
  ), totalScheduledRecognitionPoints: (
    { __typename?: 'ListMyManagerScheduledRecognitionPointsResponse' }
    & Pick<Types.ListMyManagerScheduledRecognitionPointsResponse, 'total'>
  ), totalScheduledRecognitionPointsRequiringMessage: (
    { __typename?: 'ListMyManagerScheduledRecognitionPointsResponse' }
    & Pick<Types.ListMyManagerScheduledRecognitionPointsResponse, 'total'>
  ) }
);


export const EnabledRecognitionPageTabsDataDocument = gql`
    query EnabledRecognitionPageTabsData($month: MonthAndYear!) {
  getMyRewardsOrganization {
    id
    recognitionBudgetsEnabled
  }
  listMyManagedEmployeesRecognitionSummary(
    filter: {month: $month}
    page: {limit: 0, offset: 0}
  ) {
    total
  }
  totalScheduledRecognitionPoints: listMyManagerScheduledRecognitionPoints(
    limit: 0
    offset: 0
  ) {
    total
  }
  totalScheduledRecognitionPointsRequiringMessage: listMyManagerScheduledRecognitionPoints(
    limit: 0
    offset: 0
    filter: {hasMessage: false}
  ) {
    total
  }
}
    `;

/**
 * __useEnabledRecognitionPageTabsDataQuery__
 *
 * To run a query within a React component, call `useEnabledRecognitionPageTabsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnabledRecognitionPageTabsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnabledRecognitionPageTabsDataQuery({
 *   variables: {
 *      month: // value for 'month'
 *   },
 * });
 */
export function useEnabledRecognitionPageTabsDataQuery(baseOptions: Apollo.QueryHookOptions<EnabledRecognitionPageTabsDataQuery, EnabledRecognitionPageTabsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnabledRecognitionPageTabsDataQuery, EnabledRecognitionPageTabsDataQueryVariables>(EnabledRecognitionPageTabsDataDocument, options);
      }
export function useEnabledRecognitionPageTabsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnabledRecognitionPageTabsDataQuery, EnabledRecognitionPageTabsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnabledRecognitionPageTabsDataQuery, EnabledRecognitionPageTabsDataQueryVariables>(EnabledRecognitionPageTabsDataDocument, options);
        }
export type EnabledRecognitionPageTabsDataQueryHookResult = ReturnType<typeof useEnabledRecognitionPageTabsDataQuery>;
export type EnabledRecognitionPageTabsDataLazyQueryHookResult = ReturnType<typeof useEnabledRecognitionPageTabsDataLazyQuery>;
export type EnabledRecognitionPageTabsDataQueryResult = Apollo.QueryResult<EnabledRecognitionPageTabsDataQuery, EnabledRecognitionPageTabsDataQueryVariables>;