import { useMatch } from "react-router";

import {
  ONBOARDING_STEP_BY_PATH,
  ONBOARDING_STEP_PATH_BY_KEY,
} from "../constants";
import { useOnboardingState } from "./use-onboarding-state";

export function usePrevOnboardingStepPath(): string | undefined {
  const onboardingState = useOnboardingState();
  const match = useMatch("/onboarding/:step/*");
  const currentStepPath = match?.params.step;

  if (!currentStepPath) {
    return;
  }

  const currentStep = ONBOARDING_STEP_BY_PATH[currentStepPath];

  if (!currentStep) {
    return;
  }

  const prevIndex = onboardingState.orderedSteps.indexOf(currentStep) - 1;

  if (prevIndex < 0) {
    return; // first step
  }

  return `/onboarding/${
    ONBOARDING_STEP_PATH_BY_KEY[onboardingState.orderedSteps[prevIndex]]
  }`;
}
