import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GenerateEmployeeExportMutationVariables = Types.Exact<{
  searchQuery?: Types.Maybe<Types.Scalars['String']>;
  filter?: Types.Maybe<Types.ListRewardsUsersFilterInput>;
}>;


export type GenerateEmployeeExportMutation = (
  { __typename?: 'Mutation' }
  & { generateRewardsUserExport?: Types.Maybe<(
    { __typename?: 'GenerateRewardsUserExportResponse' }
    & Pick<Types.GenerateRewardsUserExportResponse, 'downloadLink'>
  )> }
);


export const GenerateEmployeeExportDocument = gql`
    mutation GenerateEmployeeExport($searchQuery: String, $filter: ListRewardsUsersFilterInput) {
  generateRewardsUserExport(searchQuery: $searchQuery, filter: $filter) {
    downloadLink
  }
}
    `;
export type GenerateEmployeeExportMutationFn = Apollo.MutationFunction<GenerateEmployeeExportMutation, GenerateEmployeeExportMutationVariables>;

/**
 * __useGenerateEmployeeExportMutation__
 *
 * To run a mutation, you first call `useGenerateEmployeeExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateEmployeeExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateEmployeeExportMutation, { data, loading, error }] = useGenerateEmployeeExportMutation({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGenerateEmployeeExportMutation(baseOptions?: Apollo.MutationHookOptions<GenerateEmployeeExportMutation, GenerateEmployeeExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateEmployeeExportMutation, GenerateEmployeeExportMutationVariables>(GenerateEmployeeExportDocument, options);
      }
export type GenerateEmployeeExportMutationHookResult = ReturnType<typeof useGenerateEmployeeExportMutation>;
export type GenerateEmployeeExportMutationResult = Apollo.MutationResult<GenerateEmployeeExportMutation>;
export type GenerateEmployeeExportMutationOptions = Apollo.BaseMutationOptions<GenerateEmployeeExportMutation, GenerateEmployeeExportMutationVariables>;