import { SVGProps } from "react";

export function ViewIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0001 16.2412C2.74056 16.2412 0.166543 10.1862 0.0604417 9.92897C-0.0201472 9.73161 -0.0201472 9.50957 0.0604417 9.31221C0.166524 9.05481 2.74035 3 10.0001 3C17.2573 3 19.8337 9.05496 19.9389 9.31221C20.0204 9.50957 20.0204 9.73161 19.9389 9.92897C19.8337 10.1864 17.2573 16.2412 10.0001 16.2412ZM1.70755 9.62372C2.2914 10.7701 4.65145 14.6193 10.0001 14.6193C15.3488 14.6193 17.7104 10.7658 18.2942 9.62035C17.7111 8.47647 15.3511 4.62136 10.0001 4.62136C4.69018 4.62136 2.29405 8.49386 1.70755 9.62372Z"
        fill={props.fill || "#2C393F"}
      />
      <path
        d="M10.0001 12.7488C8.27485 12.7488 6.87201 11.3458 6.87201 9.62066C6.87201 7.89548 8.27493 6.49255 10.0001 6.49255C11.7253 6.49255 13.1282 7.89548 13.1282 9.62066C13.1282 11.3458 11.7253 12.7488 10.0001 12.7488ZM10.0001 8.11397C9.16955 8.11397 8.4936 8.78993 8.4936 9.62049C8.4936 10.4511 9.16955 11.127 10.0001 11.127C10.8307 11.127 11.5066 10.4511 11.5066 9.62049C11.5066 8.78993 10.8307 8.11397 10.0001 8.11397Z"
        fill={props.fill || "#2C393F"}
      />
    </svg>
  );
}
