/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { lighten } from "@material-ui/core";
import { Control, FieldValues, Path } from "react-hook-form";

import { Table } from "@rewards-web/shared/components/table-components/table";
import { TableBody } from "@rewards-web/shared/components/table-components/table-body";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Typography } from "@rewards-web/shared/components/typography";
import { formatPointsAndDollars } from "@rewards-web/shared/lib/format-reward-points";
import { AppTheme } from "@rewards-web/shared/style/types";

import { DollarIcon } from "../referrals-structure-table/icons/dollar-icon";
import { FIELD_VALIDATION_RULES } from "./field-validation-rule";
import { ReferralRewardNumDrawTicketsField } from "./referral-reward-num-draw-ticket-field";
import { ReferralRewardPointValueField } from "./referral-reward-point-value-field";

export type RewardType = "points" | "draw_tickets";

export interface EditReferralStructureFormValues {
  hundredHourWorkedPointValue: string;
  threeHundredHourWorkedPointValue: string;
  CompletedOrientationPointValue: string;
  candidateExpressInterestPointValue: string;
  CompletedFirstShiftPointValue: string;
}

export type RewardStructureRow = {
  description: string;
  reward?: JSX.Element | string;
  icon: JSX.Element;
  type?: JSX.Element | string;
  fieldName: keyof EditReferralStructureFormValues;
  fieldType: "points" | "draw_tickets";
  isTotalRow?: boolean;
};

interface EditReferralStructureFormContentProps<T extends FieldValues> {
  control: Control<T>;
  tableRows: RewardStructureRow[];
  pointsPerDollar: number;
  totalPointValue: number;
  cardHeadingContent?: JSX.Element;
  showExplanationTooltip: boolean;
}
export function EditReferralStructureFormContent<T extends FieldValues>({
  tableRows,
  control,
  pointsPerDollar,
  totalPointValue,
  cardHeadingContent,
  showExplanationTooltip,
}: EditReferralStructureFormContentProps<T>) {
  const theme = useTheme();
  return (
    <div>
      {cardHeadingContent}
      <Table
        css={(theme: AppTheme) => css`
          & th,
          td {
            padding: ${theme.spacing(1)};
          }
        `}
      >
        <TableRow>
          <TableHeader>
            <Typography variant="body" color="textPrimary" fontWeight={800}>
              Step of New Hire
            </Typography>
          </TableHeader>
          <TableHeader>
            <Typography variant="body" color="textPrimary" fontWeight={800}>
              Reward type
            </Typography>
          </TableHeader>
          <TableHeader>
            <Typography variant="body" color="textPrimary" fontWeight={800}>
              Value
            </Typography>
          </TableHeader>
        </TableRow>

        <TableBody>
          {tableRows.map((row, index) => (
            <TableRow
              backgroundColor={
                row.isTotalRow
                  ? lighten(theme.palette.tertiary.main, 0.9)
                  : undefined
              }
              css={css`
                border-radius: ${row.isTotalRow ? "20px" : "0px"};
              `}
              key={index}
            >
              <TableCell divider={index !== tableRows.length - 1}>
                <div
                  css={(theme: AppTheme) =>
                    css`
                      margin-left: ${theme.spacing(2)};
                      display: flex;
                      align-items: center;
                    `
                  }
                >
                  {row.icon}
                  <Typography
                    css={(theme: AppTheme) =>
                      css`
                        margin-left: ${theme.spacing(1.5)};
                        font-weight: ${row.isTotalRow ? "600" : "400"};
                      `
                    }
                    variant="body"
                    color="textPrimary"
                  >
                    {row.description}
                  </Typography>
                </div>
              </TableCell>
              <TableCell divider={index !== tableRows.length - 1}>
                <Typography
                  css={css`
                    font-weight: ${row.isTotalRow ? "600" : "400"};
                  `}
                  variant="body"
                >
                  {row.type}
                </Typography>
              </TableCell>
              <TableCell divider={index !== tableRows.length - 1}>
                {row.fieldType === "points" && (
                  <ReferralRewardPointValueField
                    control={control}
                    name={row.fieldName as Path<T>}
                    pointsPerDollar={pointsPerDollar}
                    min={FIELD_VALIDATION_RULES[row.fieldName].min}
                    max={FIELD_VALIDATION_RULES[row.fieldName].max}
                    showExplanationTooltip={showExplanationTooltip}
                  />
                )}
                {row.fieldType === "draw_tickets" && (
                  <ReferralRewardNumDrawTicketsField
                    control={control}
                    name={row.fieldName as Path<T>}
                    min={FIELD_VALIDATION_RULES[row.fieldName].min}
                    max={FIELD_VALIDATION_RULES[row.fieldName].max}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div
        css={(theme: AppTheme) => css`
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          width: 600px;
          max-width: 100%;
          border: 1px solid ${theme.palette.grey[400]};
          padding: ${theme.spacing(1)} ${theme.spacing(0)};
          margin: ${theme.spacing(2)} auto ${theme.spacing(2)};
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          <DollarIcon />
          <Typography>Total rewards to referring employee</Typography>
        </div>
        <div
          css={css`
            margin-left: 10px;
          `}
        >
          <Typography
            variant="body"
            color={"textPrimary"}
            fontWeight="fontWeightBold"
          >
            {formatPointsAndDollars(totalPointValue, pointsPerDollar)}
          </Typography>
        </div>
      </div>
    </div>
  );
}
