/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { CardContent } from "@rewards-web/shared/components/card/card-content";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { RewardsAdminPermissionsType } from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { referralStructureIsEditable } from "../../../../../shared/components/edit-referral-structure-form-content/lib";
import { PageCard } from "../../../../../shared/components/page-card";
import { PageCardTitle } from "../../../../../shared/components/page-card/page-card-title";
import { ReferralsStructureTable } from "../../../../../shared/components/referrals-structure-table";
import { useRewardsStructureQuery } from "../../../../../shared/components/referrals-structure-table/rewards-structure.generated";
import { useOrganizationLaunchStatusQuery } from "../../../../../shared/modules/launch/hooks/use-organization-launch-status/organization-launch-status.generated";
import { usePermissionsQuery } from "../../../../../shared/modules/permissions/hooks/use-permissions-query";

export function SettingsReferralStructurePage() {
  const { data: permissionsQueryData } = usePermissionsQuery();

  const hasFullAccessLevel =
    permissionsQueryData?.getMyRewardsAdminUser.permissions.type ===
    RewardsAdminPermissionsType.FullAccess;
  const launchStatusQuery = useOrganizationLaunchStatusQuery({
    onError: reportError,
  });
  const preLaunch =
    launchStatusQuery.data?.getMyRewardsOrganization.launched === false;
  const { data } = useRewardsStructureQuery({ onError: reportError });

  const editAvailability = (():
    | {
        editable: true;
      }
    | {
        editable: false;
        reason: "prelaunch" | "default_structure_changed";
      } => {
    if (!preLaunch) {
      return {
        editable: false,
        reason: "prelaunch",
      };
    }

    if (
      data &&
      !referralStructureIsEditable(
        data.getMyRewardsOrganization.referralRewardStructure
      )
    ) {
      return {
        editable: false,
        reason: "default_structure_changed",
      };
    }

    return {
      editable: true,
    };
  })();

  return (
    <PageCard>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;
        `}
      >
        <PageCardTitle title="Referral Structure" />
        {hasFullAccessLevel && (
          <Tooltip
            title={(() => {
              if (!editAvailability.editable) {
                switch (editAvailability.reason) {
                  case "prelaunch":
                    return "Referral structures cannot be modified after launch";
                  case "default_structure_changed":
                    return "You have a custom referral structure. Contact help@caribou.care to modify it.";
                  default:
                    assertNever(editAvailability.reason);
                }
              }

              return "";
            })()}
            disabled={editAvailability.editable}
          >
            <Button
              css={(theme: AppTheme) => css`
                height: fit-content;
                margin-right: ${theme.spacing(2)};
                margin-top: ${theme.spacing(2)};
              `}
              color="primary"
              label="Edit"
              size="medium"
              width="auto"
              linkTo="/settings/referral-structure/edit-referral-structure"
              disabled={!editAvailability.editable}
            />
          </Tooltip>
        )}
      </div>

      <CardContent
        css={(theme: AppTheme) => css`
          padding-left: ${theme.spacing(3)};
        `}
      >
        <ReferralsStructureTable />
      </CardContent>
    </PageCard>
  );
}
