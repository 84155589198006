/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { CardActions as MuiCardActions } from "@material-ui/core";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";

import { StylableProps } from "../../types";

export interface CardActionsProps extends StylableProps {
  /**
   * The actions of the card.
   *
   * This should include controls such as buttons.
   */
  children: ReactNode;
}

export function CardActions({
  children,
  className,
}: CardActionsProps): JSX.Element {
  return (
    <MuiCardActions
      className={className}
      css={(theme: AppTheme) => css`
        padding: ${theme.spacing(2)};
      `}
    >
      {children}
    </MuiCardActions>
  );
}
