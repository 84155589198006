import { AuthErrorCode, CognitoError, CognitoErrorCode } from "./constants";

export class AuthError extends Error {
  private constructor(message: string, public readonly code: AuthErrorCode) {
    super(message);

    this.code = code;
  }

  static fromCognitoError(message: string, cognitoError: CognitoError) {
    let code: AuthErrorCode;

    switch (cognitoError.code) {
      case CognitoErrorCode.TooManyRequestsException:
      case CognitoErrorCode.LimitExceededException:
        code = AuthErrorCode.TooManyRequests;
        break;
      case CognitoErrorCode.UserNotConfirmedException:
        code = AuthErrorCode.UserNotConfirmed;
        break;
      case CognitoErrorCode.UserNotFoundException:
        code = AuthErrorCode.UserDoesNotExist;
        break;
      case CognitoErrorCode.NotAuthorizedException:
        if (cognitoError.message === "User is disabled.") {
          code = AuthErrorCode.Deactivated;
        } else if (cognitoError.message === "Password attempts exceeded") {
          code = AuthErrorCode.TooManyRequests;
        } else {
          code = AuthErrorCode.NotAuthorized;
        }
        break;
      case CognitoErrorCode.CodeMismatchException:
        code = AuthErrorCode.IncorrectVerificationCode;
        break;
      default:
        code = AuthErrorCode.Unknown;
    }

    return new AuthError(
      `${message} (authErrorCode=${code},cognitoErrorCode=${cognitoError.code},cognitoMessage=${cognitoError.message})`,
      code
    );
  }
}

export class NewPasswordRequiredError extends Error {}

export class SmsMfaRequiredError extends Error {
  constructor(public readonly deliveredToPhoneNumber: string) {
    super("SMS Required");
  }
}
