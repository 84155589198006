/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { FormControlProvider } from "@rewards-web/shared/components/form/form-control";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalErrorState } from "@rewards-web/shared/components/modal/modal-error-state";
import { ModalLoadingState } from "@rewards-web/shared/components/modal/modal-loading-state";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { useConfirmChangeReferredByEmployeeDataQuery } from "./confirm-change-referred-by-employee-data.generated";

interface ConfirmChangeReferredByEmployeeModalProps {
  open: boolean;
  confirming: boolean;
  onConfirm(): void;
  onCancel(): void;
  candidateId: string;
  currentReferringUserId: string | null;
  nextReferringUserId: string | null;
}

export function ConfirmChangeReferredByEmployeeModal({
  open,
  confirming,
  onConfirm,
  onCancel,
  candidateId,
  currentReferringUserId,
  nextReferringUserId,
}: ConfirmChangeReferredByEmployeeModalProps): JSX.Element {
  const confirmChangeReferredByEmployeeData = useConfirmChangeReferredByEmployeeDataQuery(
    {
      onError: reportError,
      skip:
        !currentReferringUserId ||
        !nextReferringUserId ||
        currentReferringUserId === nextReferringUserId,
      variables: {
        candidateId,
        previousUserId: currentReferringUserId!,
        nextUserId: nextReferringUserId!,
      },
    }
  );

  useEffect(() => {
    if (
      confirmChangeReferredByEmployeeData.data &&
      (!confirmChangeReferredByEmployeeData.data.candidate ||
        !confirmChangeReferredByEmployeeData.data.previousUser ||
        !confirmChangeReferredByEmployeeData.data.nextUser)
    ) {
      reportError(
        new Error(
          `Candidate, current referring user or next referring user could not be found`
        )
      );
    }
  }, [confirmChangeReferredByEmployeeData.data]);

  const content = (() => {
    if (confirmChangeReferredByEmployeeData.error) {
      return <ModalErrorState onClose={onCancel} />;
    }

    if (!confirmChangeReferredByEmployeeData.data) {
      return <ModalLoadingState />;
    }

    if (
      !confirmChangeReferredByEmployeeData.data.candidate ||
      !confirmChangeReferredByEmployeeData.data.previousUser ||
      !confirmChangeReferredByEmployeeData.data.nextUser
    ) {
      return <ModalErrorState onClose={onCancel} />;
    }

    const {
      data: { candidate, previousUser, nextUser },
    } = confirmChangeReferredByEmployeeData;

    return (
      <>
        <ModalTitle>Confirm</ModalTitle>
        <ModalContent>
          <Typography
            variant="body"
            color="textPrimary"
            css={(theme: AppTheme) =>
              css`
                padding-bottom: ${theme.spacing(2)};
              `
            }
          >
            {candidate.firstName} {candidate.lastName} will be assigned to{" "}
            {nextUser.firstName} {nextUser.lastName}.{" "}
            <strong>{candidate.pointsEarnedByReferredByUser} points</strong>{" "}
            will be removed from{" "}
            <strong>
              {previousUser.firstName} {previousUser.lastName}
            </strong>{" "}
            and added to{" "}
            <strong>
              {nextUser.firstName} {nextUser.lastName}.
            </strong>
          </Typography>
        </ModalContent>
        <ModalActions>
          <Button
            size="large"
            label="Cancel"
            variant="outlined"
            onClick={() => {
              onCancel();
            }}
          />
          <Button
            size="large"
            label="Confirm"
            color="primary"
            loading={confirming}
            onClick={onConfirm}
          />
        </ModalActions>
      </>
    );
  })();

  return (
    <FormControlProvider value={{ submitting: confirming }}>
      <Modal
        width="488px"
        open={open}
        onClose={onCancel}
        disableBackdropClick={confirming}
      >
        {content}
      </Modal>
    </FormControlProvider>
  );
}
