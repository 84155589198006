/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";

import { InsightMetricGroup } from "../../components/insight-metric-group";
import rocketIconUrl from "../../graphics/rocket-icon.png";

export interface OnboardingInsightMetricGroupProps {}

export function OnboardingInsightMetricGroup() {
  const theme = useTheme();
  const track = useTrack();

  return (
    <InsightMetricGroup
      label="Onboarding"
      icon={<img src={rocketIconUrl} width={24} alt="megaphone" />}
      trackingProperties={{}}
    >
      <Card
        css={css`
          text-align: center;
          padding: ${theme.spacing(2.25)};
        `}
        variant="outlined"
      >
        <Typography variant="h3" fontWeight={700}>
          Onboarding that drives results
        </Typography>
        <Typography marginTop={theme.spacing(1.25)} color="grey.800">
          Enable onboarding to reduce time to first shift and gather valuable
          feedback with first-shift surveys.
        </Typography>
        <Button
          css={css`
            margin-top: ${theme.spacing(2)};
            border-radius: 100px;
            height: auto;
            padding: ${theme.spacing(1)} ${theme.spacing(2)};
          `}
          width="auto"
          variant="outlined"
          linkTo="mailto:help@caribou.care"
          externalLink
          label="Contact us"
          onClick={() =>
            track("Pressed 'Contact us' in onboarding insight metric group")
          }
        />
      </Card>
    </InsightMetricGroup>
  );
}
