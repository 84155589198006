import { useLocation, useMatch, useNavigate } from "react-router";

import { usePrevious } from "./use-previous";

/**
 * Manages the on/off state based on a route param route
 */
export function usePathParamToggle<T extends string>(params: {
  onPath: string;
  offPath: string;
  param: T;
}): [
  string | undefined,
  {
    /**
     * Links to the `onPath`
     */
    getOnLink: (param: string) => string;

    /**
     * Navigates to the `onPath `
     */
    navigateToOnPath(param: string): void;

    /**
     * Navigates to the `offPath`
     */
    navigateToOffPath(): void;
  }
] {
  const location = useLocation();
  const navigate = useNavigate();
  const candidateDetailsMatch = useMatch<T>(params.onPath);
  const previouslyOff = usePrevious(!candidateDetailsMatch);

  const getOnLink = (param: string) =>
    `${params.onPath.replace(`:${params.param}`, param)}${location.search}`;

  return [
    candidateDetailsMatch?.params[params.param] || undefined,
    {
      getOnLink,
      navigateToOnPath: (param: string) => navigate(getOnLink(param)),
      navigateToOffPath: () => {
        if (previouslyOff) {
          // navigate 'back' to clear the history
          navigate(-1);
        } else {
          // replace the route, because the off path is not the previous route
          navigate(`${params.offPath}${location.search}`, { replace: true });
        }
      },
    },
  ];
}
