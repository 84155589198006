/** @jsxImportSource @emotion/react */
import { Skeleton as MuiSkeleton } from "@material-ui/lab";

import { StylableProps } from "../../types";

export interface SkeletonProps extends StylableProps {
  width?: string | number;
  height?: string | number;
  animated?: boolean;
}

export function Skeleton({
  className,
  width,
  height,
  animated = false,
}: SkeletonProps): JSX.Element {
  return (
    <MuiSkeleton
      animation={animated ? undefined : false}
      className={className}
      variant="text"
      width={width}
      height={height}
    />
  );
}
