import { ApolloError } from "@apollo/client";

import { AdminPermissionType } from "../../types";
import { hasPermission as checkHasPermission } from "../lib";
import { usePermissionsQuery } from "../use-permissions-query";

export function useHasPermissionQuery(
  level: "read" | "full",
  resource: AdminPermissionType
): {
  hasPermission: boolean | undefined;
  loading?: boolean;
  error?: ApolloError;
} {
  const query = usePermissionsQuery();

  const hasPermission = ((): boolean | undefined => {
    if (!query.data) {
      return undefined;
    }

    const {
      getMyRewardsAdminUser: { permissions },
    } = query.data;

    return checkHasPermission(permissions, level, resource);
  })();

  return {
    ...query,
    hasPermission,
  };
}
