import * as Types from '@rewards-web/shared/graphql-types';

import { CandidateListDetailsFragment } from '../candidate-fragment.generated';
import { gql } from '@apollo/client';
import { CandidateListDetailsFragmentDoc } from '../candidate-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AddCandidateMutationVariables = Types.Exact<{
  firstName: Types.Scalars['String'];
  lastName: Types.Scalars['String'];
  preferredName?: Types.Maybe<Types.Scalars['String']>;
  referredByUserId: Types.Scalars['ID'];
  appledToJobPostingId: Types.Scalars['ID'];
  appliedOnDate: Types.Scalars['Date'];
  email?: Types.Maybe<Types.Scalars['String']>;
  phoneNumber: Types.Scalars['String'];
}>;


export type AddCandidateMutation = (
  { __typename?: 'Mutation' }
  & { addCandidate: (
    { __typename?: 'Candidate' }
    & CandidateListDetailsFragment
  ) }
);


export const AddCandidateDocument = gql`
    mutation AddCandidate($firstName: String!, $lastName: String!, $preferredName: String, $referredByUserId: ID!, $appledToJobPostingId: ID!, $appliedOnDate: Date!, $email: String, $phoneNumber: String!) {
  addCandidate(
    firstName: $firstName
    lastName: $lastName
    preferredName: $preferredName
    referredByUserId: $referredByUserId
    appledToJobPostingId: $appledToJobPostingId
    appliedOnDate: $appliedOnDate
    email: $email
    phoneNumber: $phoneNumber
  ) {
    ...CandidateListDetails
  }
}
    ${CandidateListDetailsFragmentDoc}`;
export type AddCandidateMutationFn = Apollo.MutationFunction<AddCandidateMutation, AddCandidateMutationVariables>;

/**
 * __useAddCandidateMutation__
 *
 * To run a mutation, you first call `useAddCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCandidateMutation, { data, loading, error }] = useAddCandidateMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      preferredName: // value for 'preferredName'
 *      referredByUserId: // value for 'referredByUserId'
 *      appledToJobPostingId: // value for 'appledToJobPostingId'
 *      appliedOnDate: // value for 'appliedOnDate'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useAddCandidateMutation(baseOptions?: Apollo.MutationHookOptions<AddCandidateMutation, AddCandidateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCandidateMutation, AddCandidateMutationVariables>(AddCandidateDocument, options);
      }
export type AddCandidateMutationHookResult = ReturnType<typeof useAddCandidateMutation>;
export type AddCandidateMutationResult = Apollo.MutationResult<AddCandidateMutation>;
export type AddCandidateMutationOptions = Apollo.BaseMutationOptions<AddCandidateMutation, AddCandidateMutationVariables>;