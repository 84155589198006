import { SVGProps } from "react";

export function JobStatsInterviewsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={15}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.71 2.717H1.193C.535 2.717 0 3.25 0 3.908v8.024c0 .657.535 1.192 1.192 1.192h1.272a.32.32 0 0 1 .276.161l.876 1.537a.881.881 0 0 0 .754.448h.02a.88.88 0 0 0 .752-.417l.992-1.58a.318.318 0 0 1 .27-.15h7.308c.657 0 1.191-.534 1.191-1.191V3.908c0-.657-.535-1.191-1.192-1.191Zm.622 9.216a.62.62 0 0 1-.62.62H6.403a.884.884 0 0 0-.753.417l-.991 1.58a.311.311 0 0 1-.277.15.313.313 0 0 1-.27-.161l-.876-1.537a.893.893 0 0 0-.773-.45H1.192a.62.62 0 0 1-.62-.62L.57 3.91a.62.62 0 0 1 .62-.62h12.52a.62.62 0 0 1 .62.62v8.024Z"
        fill="#fff"
      />
      <path
        d="M16.808 0H4.288c-.657 0-1.191.535-1.191 1.192v.387a.286.286 0 1 0 .571 0V1.19a.62.62 0 0 1 .62-.62h12.52a.62.62 0 0 1 .62.62v8.024a.62.62 0 0 1-.62.62h-.526a.286.286 0 1 0 0 .572h.526c.657 0 1.192-.535 1.192-1.192V1.19C18 .534 17.465 0 16.808 0Z"
        fill="#fff"
      />
      <path
        d="M11.807 5.233h-8.71a.286.286 0 1 0 0 .572h8.712a.286.286 0 1 0-.002-.572ZM11.807 7.635h-8.71a.286.286 0 1 0 0 .572h8.712a.286.286 0 1 0-.002-.572ZM11.807 10.036h-8.71a.286.286 0 1 0 0 .572h8.712a.286.286 0 1 0-.002-.572Z"
        fill="#fff"
      />
    </svg>
  );
}
