/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { JobPosting } from "@rewards-web/shared/graphql-types";

interface HiringPipelineJobDetailsProps {
  jobPosting: { __typename?: "JobPosting" } & Pick<
    JobPosting,
    "id" | "title" | "geography" | "closedForSubmission"
  >;
  totalSubmissions: number;
}

export function HiringPipelineJobDetails({
  jobPosting,
  totalSubmissions,
}: HiringPipelineJobDetailsProps): JSX.Element {
  return (
    <div
      css={css`
        max-width: 200px;
      `}
    >
      <Typography display="inline" variant="h3">
        {jobPosting.title}
      </Typography>
      {jobPosting.closedForSubmission && (
        <Typography display="inline"> (Closed) </Typography>
      )}

      <Typography
        css={css`
          font-size: 0.75em;
        `}
      >
        Total Submissions: <strong>{totalSubmissions}</strong>
      </Typography>

      <Typography
        css={css`
          font-size: 0.75em;
        `}
      >
        Region: <strong>{jobPosting.geography}</strong>
      </Typography>
    </div>
  );
}
