import { SVGProps } from "react";

export function JobStatsHiresIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.46 4.016c-1.112 0-1.988.976-1.988 2.133s.876 2.134 1.988 2.134c1.111 0 1.988-.977 1.988-2.134s-.877-2.133-1.988-2.133Zm0 .776c.653 0 1.212.586 1.212 1.357 0 .772-.559 1.358-1.212 1.358-.653 0-1.212-.586-1.212-1.358 0-.771.559-1.357 1.212-1.357ZM9.46 9.057c-1.135 0-2.161.259-2.934.69-.773.433-1.334 1.067-1.334 1.831v1.085c0 .091.033.18.091.249a5.422 5.422 0 0 0 8.353 0 .388.388 0 0 0 .091-.249v-1.085c0-.764-.56-1.398-1.333-1.83-.773-.432-1.8-.691-2.934-.691Zm0 .776c1.014 0 1.928.242 2.558.594.63.351.934.782.934 1.151v.922a4.63 4.63 0 0 1-3.492 1.6 4.63 4.63 0 0 1-3.492-1.6v-.922c0-.37.304-.8.934-1.151.63-.352 1.543-.594 2.558-.594ZM9.454.903a.387.387 0 0 0-.382.394v.97a.39.39 0 0 0 .388.394.39.39 0 0 0 .388-.393v-.97a.388.388 0 0 0-.394-.395Z"
        fill="#fff"
      />
      <path
        d="M9.46 2.076a7.377 7.377 0 0 0-7.371 7.37c0 3.934 3.1 7.124 6.983 7.33v.818a.39.39 0 0 0 .388.393.39.39 0 0 0 .388-.393V16.43a.388.388 0 0 0-.388-.388 6.59 6.59 0 0 1-6.595-6.595A6.59 6.59 0 0 1 9.46 2.852a6.59 6.59 0 0 1 6.595 6.595 6.597 6.597 0 0 1-5.128 6.431.386.386 0 0 0-.138.724c.095.054.21.064.313.028a7.37 7.37 0 0 0 5.729-7.183A7.377 7.377 0 0 0 9.46 2.076Z"
        fill="#fff"
      />
      <path
        d="M16.6 9.059a.387.387 0 1 0 .036.776h.971a.389.389 0 0 0 .278-.665.389.389 0 0 0-.278-.111h-1.006ZM1.276 9.059a.387.387 0 1 0 .036.776h.97a.39.39 0 0 0 .394-.388.39.39 0 0 0-.393-.388H1.276Z"
        fill="#fff"
      />
    </svg>
  );
}
