import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SendLoginLinkToEmployeeMutationVariables = Types.Exact<{
  employeeId: Types.Scalars['ID'];
}>;


export type SendLoginLinkToEmployeeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'sendLoginLinkToEmployee'>
);


export const SendLoginLinkToEmployeeDocument = gql`
    mutation SendLoginLinkToEmployee($employeeId: ID!) {
  sendLoginLinkToEmployee(employeeId: $employeeId)
}
    `;
export type SendLoginLinkToEmployeeMutationFn = Apollo.MutationFunction<SendLoginLinkToEmployeeMutation, SendLoginLinkToEmployeeMutationVariables>;

/**
 * __useSendLoginLinkToEmployeeMutation__
 *
 * To run a mutation, you first call `useSendLoginLinkToEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendLoginLinkToEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendLoginLinkToEmployeeMutation, { data, loading, error }] = useSendLoginLinkToEmployeeMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useSendLoginLinkToEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<SendLoginLinkToEmployeeMutation, SendLoginLinkToEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendLoginLinkToEmployeeMutation, SendLoginLinkToEmployeeMutationVariables>(SendLoginLinkToEmployeeDocument, options);
      }
export type SendLoginLinkToEmployeeMutationHookResult = ReturnType<typeof useSendLoginLinkToEmployeeMutation>;
export type SendLoginLinkToEmployeeMutationResult = Apollo.MutationResult<SendLoginLinkToEmployeeMutation>;
export type SendLoginLinkToEmployeeMutationOptions = Apollo.BaseMutationOptions<SendLoginLinkToEmployeeMutation, SendLoginLinkToEmployeeMutationVariables>;