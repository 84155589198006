import {
  CandidateRecruitmentStep,
  CandidateRecruitmentStepName,
  CandidateStep,
  ListCandidatesV2FilterInput,
  ListCandidateV2FilterStatusInput,
  ReferralRewardStructure,
  ReferralRewardStructureReferralRetentionItemDurationUnit,
} from "@rewards-web/shared/graphql-types";

import {
  getInitialCandidateFilterStepOptionsForOrganizationSteps,
  serializeCandidateFilterStepOptionIntoGraphQLInput,
} from "./candidate-filter/lib";

export interface OrganizationSteps {
  recruitmentStatuses: CandidateRecruitmentStepName[];
  retentionMonthStatuses: number[];
  retentionHoursStatuses: number[];
}

export function getOrganizationSteps(
  candidateSteps: CandidateStep[],
  referralRewardStructure: Pick<
    ReferralRewardStructure,
    "referralRecruitmentStructure" | "referralRetentionStructure"
  >
): OrganizationSteps {
  return {
    /**
     * Recruitment steps come from `organization.candidateSteps` because it includes all steps regardless of whether
     * they are awarded or not.
     */
    recruitmentStatuses: candidateSteps
      .filter(
        (step): step is CandidateRecruitmentStep =>
          step.__typename === "CandidateRecruitmentStep"
      )
      .map((step) => step.stepName),

    /**
     * Eventually we want to use `organization.referralRewardStructure` so we are using it here to get the retention.
     */
    retentionMonthStatuses: referralRewardStructure.referralRetentionStructure
      .filter(
        (item) =>
          item.duration.unit ===
          ReferralRewardStructureReferralRetentionItemDurationUnit.Months
      )
      .map((item) => item.duration.amount),

    retentionHoursStatuses: referralRewardStructure.referralRetentionStructure
      .filter(
        (item) =>
          item.duration.unit ===
          ReferralRewardStructureReferralRetentionItemDurationUnit.Hours
      )
      .map((item) => item.duration.amount),
  };
}

export function allCandidatesShowingExceptArchived(
  organizationSteps: OrganizationSteps,
  filters: ListCandidatesV2FilterInput
): boolean {
  return (
    filters.archived === false &&
    allFiltersExceptArchivedDisabled(organizationSteps, filters)
  );
}

function allFiltersExceptArchivedDisabled(
  organizationSteps: OrganizationSteps,
  filters: ListCandidatesV2FilterInput
): boolean {
  return (
    searchQueryEmpty(filters) &&
    resumeFilterDisabled(filters) &&
    stepFilterDisabled(organizationSteps, filters)
  );
}

function searchQueryEmpty(filters: ListCandidatesV2FilterInput): boolean {
  return !filters.searchQuery;
}

function resumeFilterDisabled(filters: ListCandidatesV2FilterInput): boolean {
  return typeof filters.hasUploadedResume !== "boolean";
}

function stepFilterDisabled(
  organizationSteps: OrganizationSteps,
  filters: ListCandidatesV2FilterInput
): boolean {
  if (!Array.isArray(filters.statuses)) {
    return true;
  }

  const statusFilterOptions = getInitialCandidateFilterStepOptionsForOrganizationSteps(
    organizationSteps
  ).map(serializeCandidateFilterStepOptionIntoGraphQLInput);

  if (statusFiltersEqual(statusFilterOptions, filters.statuses!)) {
    return true;
  }

  return false;
}

function statusFiltersEqual(
  filters1: ListCandidateV2FilterStatusInput[],
  filters2: ListCandidateV2FilterStatusInput[]
): boolean {
  return filters1.every((filter1) =>
    filters2.some(
      (filter2) =>
        (filter1.recruitmentStep ?? undefined) ===
          (filter2.recruitmentStep ?? undefined) &&
        (filter1.retentionDurationMonths ?? undefined) ===
          (filter2.retentionDurationMonths ?? undefined)
    )
  );
}
