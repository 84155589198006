import { useEffect, useState } from "react";

/**
 * Adapted from: https://stackoverflow.com/a/65008608
 *
 * @deprecated use `useOnScreenV2` to avoid bugs
 */
export function useOnScreen(
  ref: { current: Element | undefined | null },
  options: IntersectionObserverInit
) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (ref.current) {
      if (window.IntersectionObserver) {
        // some older browsers don't seem to support IntersectionObserver
        const observer = new IntersectionObserver(
          ([entry]) => setIntersecting(entry.isIntersecting),
          options
        );

        observer.observe(ref.current);
        // Remove the observer as soon as the component is unmounted
        return () => {
          observer.disconnect();
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isIntersecting;
}

/**
 * Improved version of useOnScreen, which properly
 * handles if `ref.current` isn't initially set.
 */
export function useOnScreenV2<T extends Element>(options: {
  threshold: number;
}): [boolean, (el: T | null) => void] {
  const [element, elementRef] = useState<T | null>(null);
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (window.IntersectionObserver) {
      // some older browsers don't seem to support IntersectionObserver
      const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        { threshold: options.threshold }
      );

      if (element) {
        observer.observe(element);
      }
      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect();
      };
    }
  }, [element, options.threshold]);

  return [isIntersecting, elementRef];
}
