/** @jsxImportSource @emotion/react */
import { BaseGoalConfigurationPage } from "../base-goal-configuration-page";
import { BudgetUtilizationGoalForm } from "./budget-utilization-goal-form";

export function BudgetUtilizationGoalConfigurationPage(): JSX.Element {
  return (
    <BaseGoalConfigurationPage>
      <BudgetUtilizationGoalForm />
    </BaseGoalConfigurationPage>
  );
}
