import {
  Control,
  FieldValues,
  Path,
  useFormState,
  useWatch,
  get,
} from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";
import { getCharactersRemainingText } from "@rewards-web/shared/lib/characters-remaining-text";

const MAX_LOCATION_LENGTH = 115;

interface JobLocationFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export function JobLocationField<T extends FieldValues>({
  control,
  name,
}: JobLocationFieldProps<T>) {
  const formState = useFormState({ control, name });
  const value = useWatch({ control, name });

  return (
    <TextField
      fixLabelToTop
      label="Location"
      placeholder="E.g. New York, NY"
      disableAutocomplete
      helperText={getCharactersRemainingText(value, MAX_LOCATION_LENGTH)}
      error={get(formState.errors, name)}
      {...control.register(name, {
        required: "Location is required",
        validate: (value) => {
          if (value && value.length > MAX_LOCATION_LENGTH) {
            return getCharactersRemainingText(value, MAX_LOCATION_LENGTH);
          }
        },
      })}
    />
  );
}
