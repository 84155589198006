import { DependencyList, useEffect } from "react";

/**
 * Scrolls to top when provided dependencies change
 */
export function useScrollToTop(deps: DependencyList = []) {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
