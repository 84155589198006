/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Card, CardContent } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import * as GraphQLTypes from "@rewards-web/shared/graphql-types";
import { formatPhoneNumber } from "@rewards-web/shared/lib/phone-number-format";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { MailIcon } from "../../../candidate-employee-link/icons/mail-icon";
import { PhoneIcon } from "../../../candidate-employee-link/icons/phone-icon";

export function EmployeeSummaryCard({
  employee,
  jobTitle,
}: {
  employee: Pick<
    GraphQLTypes.RewardsUser,
    | "id"
    | "firstName"
    | "lastName"
    | "personalContactInfo"
    | "workEmail"
    | "workPhoneNumber"
  > & {
    branch?: Pick<GraphQLTypes.RewardsOrganizationBranch, "id" | "name"> | null;
  };
  jobTitle?: string;
}) {
  const jobTitleToDisplay = employee.branch?.name
    ? `${jobTitle} (${employee.branch.name})`
    : jobTitle;

  const emailToDisplay = (() => {
    if (
      employee.personalContactInfo?.__typename ===
      "RewardsUserPersonalContactInfoData"
    ) {
      return employee.personalContactInfo.email;
    }

    return employee.workEmail;
  })();
  const phoneNumberToDisplay = (() => {
    if (
      employee.personalContactInfo?.__typename ===
      "RewardsUserPersonalContactInfoData"
    ) {
      return employee.personalContactInfo.phoneNumber;
    }

    return employee.workPhoneNumber;
  })();

  return (
    <Card
      css={(theme: AppTheme) => css`
        background-color: transparent;
        border: 1px solid ${theme.palette.primary.main};
        min-width: 200px;
        flex-grow: 1;
      `}
      variant="outlined"
    >
      <CardContent>
        <Typography
          css={css`
            font-weight: bolder;
            font-size: 1.1em;
            word-break: break-all;
          `}
          variant="h2"
        >
          {employee.firstName} {employee.lastName}
        </Typography>

        <Typography variant="body">{jobTitleToDisplay}</Typography>

        {emailToDisplay && (
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              align-items: center;
              margin-top: ${theme.spacing(2)};
            `}
          >
            <div
              css={css`
                flex-shrink: 0;
              `}
            >
              <MailIcon />
            </div>

            <Typography
              css={(theme: AppTheme) => css`
                word-break: break-all;
                margin-left: ${theme.spacing(1)};
              `}
            >
              {emailToDisplay}
            </Typography>
          </div>
        )}

        {phoneNumberToDisplay && (
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              align-items: center;
              padding-bottom: ${theme.spacing(2)};
              padding-left: ${theme.spacing(
                0.5
              )}; // to center-align with the phone icon
            `}
          >
            <PhoneIcon />
            <Typography>{formatPhoneNumber(phoneNumberToDisplay)}</Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
}
