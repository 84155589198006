import { useNavigate, useParams } from "react-router-dom";

import {
  HhaxCaribouUserSyncFiltersInput,
  RewardsOrganizationOnboardingStepName,
} from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { useEnableHhaxAutoSyncMutation } from "../../../../../../../shared/modules/employee-auto-sync/components/configure-hhax-employee-auto-sync/enable-hhax-auto-sync-confirmation-modal/enable-hhax-auto-sync.generated";
import { useOnboardingState } from "../../../../hooks/use-onboarding-state";
import { OnboardingEmployeeAutoSyncEditor } from "../employee-auto-sync-editor";

export function OnboardingEnableEmployeeAutoSyncHHAXPage() {
  const snackbar = useSnackbar();
  const params = useParams<"integration_id">();
  const navigate = useNavigate();
  const integrationId = params.integration_id!;

  const { recordCompletedOnboardingStep } = useOnboardingState();

  const [enableAutoSync] = useEnableHhaxAutoSyncMutation();

  const onSubmit = async (filters: HhaxCaribouUserSyncFiltersInput) => {
    try {
      await enableAutoSync({
        variables: {
          hhaxIntegrationId: integrationId,
          filters,
        },
      });

      await recordCompletedOnboardingStep(
        RewardsOrganizationOnboardingStepName.EmployeeAutoSync
      );

      snackbar.show({
        severity: "success",
        message: "Employee auto-sync has been enabled",
      });

      // navigate back to main integrations screen
      navigate("/onboarding/employee-auto-sync");
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  return (
    <OnboardingEmployeeAutoSyncEditor
      onSubmit={onSubmit}
      integrationId={integrationId}
    />
  );
}
