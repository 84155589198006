import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ReactNode } from "react";

interface DateFieldProviderProps {
  children: ReactNode;
}

export function DateFieldProvider({ children }: DateFieldProviderProps) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {children}
    </MuiPickersUtilsProvider>
  );
}
