/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { compact } from "lodash";
import { Fragment, ReactNode } from "react";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme, headerFontFamily } from "@rewards-web/shared/style/theme";

export type CandidateDetailsTableItem = {
  label: string;
  value: ReactNode;
} | null;

interface CandidateDetailsTableProps {
  items: CandidateDetailsTableItem[] | CandidateDetailsTableItem[][];
}

export function CandidateDetailsTable({
  items: itemsProp,
}: CandidateDetailsTableProps): JSX.Element {
  const itemSections: CandidateDetailsTableItem[][] = itemsProp.some((item) =>
    Array.isArray(item)
  )
    ? (itemsProp as CandidateDetailsTableItem[][])
    : [itemsProp as CandidateDetailsTableItem[]];

  return (
    <table
      css={(theme: AppTheme) => css`
        margin: 0 auto;
        border-spacing: 0 ${theme.spacing(2)};
        width: 100%;
      `}
    >
      <tbody>
        {compact(itemSections).map((section, idx) => {
          const items = compact(section);
          if (items.length === 0) {
            return null;
          }

          return (
            <Fragment key={idx}>
              {items.map(({ label, value }) => (
                <tr key={label}>
                  <td
                    css={css`
                      white-space: nowrap;
                      vertical-align: top;
                    `}
                  >
                    <Typography
                      variant="body"
                      align="left"
                      color="textSecondary"
                      css={css`
                        font-weight: 500;
                        font-family: ${headerFontFamily};
                      `}
                    >
                      {label}:
                    </Typography>
                  </td>
                  <td>
                    {typeof value === "string" ? (
                      <Typography
                        variant="body"
                        align="right"
                        css={css`
                          vertical-align: top;
                          font-weight: 500;
                          font-family: ${headerFontFamily};
                        `}
                      >
                        {value}
                      </Typography>
                    ) : (
                      value
                    )}
                  </td>
                </tr>
              ))}
              {idx !== itemSections.length - 1 && (
                <tr key={`padding-${idx}`}>
                  <td
                    css={(theme: AppTheme) => css`
                      height: ${theme.spacing(2)};
                    `}
                  />
                </tr>
              )}
              {/* Add empty rows inbetween sections */}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
}
