import * as React from "react";

function ProfileIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.967 20.728l-.006.032.027.018A10.584 10.584 0 0012 22.644c2.23 0 4.3-.69 6.012-1.866l.027-.018-.006-.032A6.109 6.109 0 0012 15.666a6.109 6.109 0 00-6.033 5.062zm13.187-.953l.022.079.06-.056A10.617 10.617 0 0022.644 12c0-5.869-4.775-10.644-10.644-10.644S1.356 6.131 1.356 12c0 3.077 1.314 5.853 3.408 7.798l.06.056.022-.08a7.426 7.426 0 012.17-3.495 7.426 7.426 0 012.158-1.363l.088-.036-.08-.052a5.172 5.172 0 01-2.354-4.334A5.178 5.178 0 0112 5.322a5.178 5.178 0 015.172 5.172c0 1.813-.938 3.41-2.354 4.334l-.08.052.088.036a7.422 7.422 0 012.159 1.363 7.425 7.425 0 012.169 3.496zM12 .05c3.192 0 6.193 1.243 8.45 3.5a11.871 11.871 0 013.5 8.45c0 3.192-1.243 6.193-3.5 8.45a11.872 11.872 0 01-8.45 3.5 11.871 11.871 0 01-8.45-3.5A11.872 11.872 0 01.05 12c0-3.192 1.243-6.193 3.5-8.45A11.871 11.871 0 0112 .05zm0 6.578a3.87 3.87 0 00-3.866 3.866A3.87 3.87 0 0012 14.359a3.87 3.87 0 003.866-3.865A3.87 3.87 0 0012 6.628z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.1}
      />
    </svg>
  );
}

export default ProfileIcon;
