import { PageLayout } from "../../../shared/components/page-layout";
import { AuthorizedAdminPageWrapper } from "../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { RecognitionPageContents } from "./page-contents";

const MAX_CONTENT_WIDTH = 1200;

export function RecognitionPointsPage(): JSX.Element {
  return (
    <AuthorizedAdminPageWrapper resource="recognitionPoints">
      <PageLayout maxContentWidth={MAX_CONTENT_WIDTH}>
        <RecognitionPageContents />
      </PageLayout>
    </AuthorizedAdminPageWrapper>
  );
}
