import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PublishCustomSocialPostMutationVariables = Types.Exact<{
  messageText: Types.Scalars['String'];
}>;


export type PublishCustomSocialPostMutation = (
  { __typename?: 'Mutation' }
  & { publishCustomSocialPost: (
    { __typename?: 'SocialPost' }
    & Pick<Types.SocialPost, 'id'>
  ) }
);


export const PublishCustomSocialPostDocument = gql`
    mutation PublishCustomSocialPost($messageText: String!) {
  publishCustomSocialPost(messageText: $messageText) {
    id
  }
}
    `;
export type PublishCustomSocialPostMutationFn = Apollo.MutationFunction<PublishCustomSocialPostMutation, PublishCustomSocialPostMutationVariables>;

/**
 * __usePublishCustomSocialPostMutation__
 *
 * To run a mutation, you first call `usePublishCustomSocialPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishCustomSocialPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishCustomSocialPostMutation, { data, loading, error }] = usePublishCustomSocialPostMutation({
 *   variables: {
 *      messageText: // value for 'messageText'
 *   },
 * });
 */
export function usePublishCustomSocialPostMutation(baseOptions?: Apollo.MutationHookOptions<PublishCustomSocialPostMutation, PublishCustomSocialPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishCustomSocialPostMutation, PublishCustomSocialPostMutationVariables>(PublishCustomSocialPostDocument, options);
      }
export type PublishCustomSocialPostMutationHookResult = ReturnType<typeof usePublishCustomSocialPostMutation>;
export type PublishCustomSocialPostMutationResult = Apollo.MutationResult<PublishCustomSocialPostMutation>;
export type PublishCustomSocialPostMutationOptions = Apollo.BaseMutationOptions<PublishCustomSocialPostMutation, PublishCustomSocialPostMutationVariables>;