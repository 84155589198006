import { ReactNode } from "react";

import { StylableProps } from "@rewards-web/shared/types";

interface TableHeadersProps extends StylableProps {
  children?: ReactNode;
}

export function TableHeaders({
  className,
  children,
}: TableHeadersProps): JSX.Element {
  return <thead className={className}>{children}</thead>;
}
