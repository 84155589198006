/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { DialogActions } from "@material-ui/core";
import { ReactNode } from "react";

import { AppTheme } from "../../style/theme";
import { StylableProps } from "../../types";

interface ModalActionsProps extends StylableProps {
  children: ReactNode;
  /**
   * Stacks actions vertically
   */
  stacked?: boolean;
}

export function ModalActions({
  className,
  stacked,
  children,
}: ModalActionsProps) {
  return (
    <DialogActions
      className={className}
      css={(theme: AppTheme) => css`
        padding-left: ${theme.spacing(3)};
        padding-right: ${theme.spacing(3)};
        ${stacked &&
        css`
          flex-direction: column;
          & > :not(:last-child) {
            margin-bottom: ${theme.spacing(2)};
          }
        `}
      `}
      disableSpacing={stacked}
    >
      {children}
    </DialogActions>
  );
}
