/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { alpha } from "@material-ui/core";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";

import {
  SelectCheckboxesField,
  SelectCheckboxesFieldOption,
} from "@rewards-web/shared/components/select-checkboxes-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

export interface CaregiverFilterProps {
  id: string;
  label: string;
  onChange(value: any): void;
  value: string[];
  options: SelectCheckboxesFieldOption[];
  onOpen?(): void;
}

export function CaregiverFilter({
  id,
  label,
  onChange,
  value,
  options,
  onOpen,
}: CaregiverFilterProps): JSX.Element {
  const numItemsSelected = value.length;

  return (
    <SelectCheckboxesField
      css={css`
        min-width: 206px;
      `}
      disabled={options.length === 0}
      id={id}
      label={label}
      leftIcon={<FilterListOutlinedIcon fontSize="small" />}
      options={options}
      value={Array.from(value)}
      onChange={onChange}
      onOpen={onOpen}
      rightAdornment={
        <Typography
          css={(theme: AppTheme) => css`
            font-weight: 800;
            padding-left: ${theme.spacing(1.5)};
            padding-bottom: 2px;
            height: 14px;
            display: flex;
            align-items: center;
            ${numItemsSelected > 0 &&
            css`
              border-left: 1px solid ${alpha("#444444", 0.2)};
            `}
          `}
          color={numItemsSelected > 0 ? "primary" : "textPrimary"}
          variant="body"
          align="right"
        >
          {numItemsSelected}
        </Typography>
      }
    />
  );
}
