import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { usePostLoginRedirect } from "@rewards-web/shared/hooks/use-post-login-redirect";

import { useCognitoAuth } from "../../../../shared/modules/cognito-auth";

export function useLoginRedirect(): {
  shouldRedirectToLogin: boolean;
} {
  const { signedIn } = useCognitoAuth();
  const navigate = useNavigate();
  const shouldRedirectToLogin = !signedIn;
  const { loginPath } = usePostLoginRedirect({ loginPath: "/login" });
  useEffect(() => {
    if (shouldRedirectToLogin) {
      navigate(loginPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { shouldRedirectToLogin };
}
