import { SVGProps } from "react";

function PointerIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.893 12.902c-.284-.358-.63-1.092-1.243-1.983-.348-.505-1.211-1.453-1.468-1.935-.223-.426-.2-.617-.146-.97.094-.628.738-1.117 1.425-1.051.519.049.959.392 1.355.716.239.195.533.574.71.788.163.196.203.277.377.509.23.307.302.459.214.12-.071-.495-.187-1.342-.355-2.091-.128-.568-.16-.657-.281-1.093-.13-.464-.195-.79-.316-1.281-.084-.348-.235-1.06-.276-1.46-.057-.546-.087-1.438.264-1.848.275-.321.906-.418 1.297-.22.512.259.803 1.003.936 1.3.239.534.387 1.15.516 1.96.164 1.032.466 2.463.476 2.764.024-.37-.068-1.146-.004-1.5.058-.321.328-.694.666-.795.286-.085.62-.116.916-.055.313.064.643.288.766.499.362.624.369 1.899.384 1.83.086-.375.07-1.228.284-1.583.14-.234.497-.445.687-.48.294-.051.655-.067.964-.007.249.049.586.345.677.487.218.344.342 1.317.379 1.658.015.14.074-.392.293-.736.406-.64 1.843-.763 1.898.639.025.654.02.624.02 1.064 0 .517-.012.828-.04 1.202-.031.4-.117 1.303-.242 1.742-.086.3-.371.977-.652 1.383 0 0-1.074 1.25-1.191 1.814-.118.562-.08.566-.102.965-.023.398.12.921.12.921s-.801.104-1.233.035c-.391-.063-.875-.84-1-1.079-.172-.328-.54-.264-.682-.023-.225.383-.71 1.07-1.051 1.113-.668.084-2.054.032-3.14.02 0 0 .186-1.01-.226-1.357-.305-.26-.83-.784-1.144-1.06l-.832-.921Z"
        fill="#fff"
      />
      <path
        clipRule="evenodd"
        d="M3.893 12.902c-.284-.358-.63-1.092-1.243-1.983-.348-.505-1.211-1.453-1.468-1.935-.223-.426-.2-.617-.146-.97.094-.628.738-1.117 1.425-1.051.519.049.959.392 1.355.716.239.195.533.574.71.788.163.196.203.277.377.509.23.307.302.459.214.12-.071-.495-.187-1.342-.355-2.091-.128-.568-.16-.657-.281-1.093-.13-.464-.195-.79-.316-1.281-.084-.348-.235-1.06-.276-1.46-.057-.546-.087-1.438.264-1.848.275-.321.906-.418 1.297-.22.512.259.803 1.003.936 1.3.239.534.387 1.15.516 1.96.164 1.032.466 2.463.476 2.764.024-.37-.068-1.146-.004-1.5.058-.321.328-.694.666-.795.286-.085.62-.116.916-.055.313.064.643.288.766.499.362.624.369 1.899.384 1.83.086-.375.07-1.228.284-1.583.14-.234.497-.445.687-.48.294-.051.655-.067.964-.007.249.049.586.345.677.487.218.344.342 1.317.379 1.658.015.14.074-.392.293-.736.406-.64 1.843-.763 1.898.639.025.654.02.624.02 1.064 0 .517-.012.828-.04 1.202-.031.4-.117 1.303-.242 1.742-.086.3-.371.977-.652 1.383 0 0-1.074 1.25-1.191 1.814-.118.562-.08.566-.102.965-.023.398.12.921.12.921s-.801.104-1.233.035c-.391-.063-.875-.84-1-1.079-.172-.328-.54-.264-.682-.023-.225.383-.71 1.07-1.051 1.113-.668.084-2.054.032-3.14.02 0 0 .186-1.01-.226-1.357-.305-.26-.83-.784-1.144-1.06l-.832-.921Z"
        stroke="#000"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.128 13.257v-3.46M10.112 13.269l-.016-3.473M8.116 9.827l.021 3.426"
        stroke="#000"
        strokeWidth={0.75}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default PointerIcon;
