import { SVGProps } from "react";

export function RecognitionBudgetIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle
        opacity="0.89"
        cx="12"
        cy="12"
        r="11"
        fill={props.fill || "0098A8"}
        stroke="white"
        stroke-width="2"
      />

      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.9867 5.75029C11.8744 5.75579 11.777 5.8257 11.742 5.92657L10.3806 9.77719H6.02046V9.77769C5.90339 9.77769 5.79956 9.8486 5.76354 9.95347C5.72699 10.0583 5.76619 10.1732 5.85995 10.2391L9.40169 12.6806L8.0403 16.6655V16.665C8.00375 16.7709 8.04401 16.8863 8.13936 16.9512C8.23471 17.0166 8.36343 17.0161 8.45826 16.9507L12 14.5092L15.5417 16.9507C15.6366 17.0161 15.7653 17.0166 15.8606 16.9512C15.956 16.8863 15.9963 16.7709 15.9597 16.665L14.5983 12.6801L18.14 10.2386V10.2391C18.2338 10.1732 18.273 10.0583 18.2365 9.95347C18.2004 9.8486 18.0966 9.77769 17.9795 9.77769H13.6194L12.258 5.92707V5.92657C12.2204 5.81721 12.1091 5.7448 11.9868 5.75029H11.9867Z"
        fill="white"
      />
    </svg>
  );
}
