import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ScheduleNewDrawPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ScheduleNewDrawPageDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'pointsPerDollar' | 'timezone'>
  ), getMyRewardsAdminUser?: Types.Maybe<(
    { __typename?: 'RewardsAdminUser' }
    & Pick<Types.RewardsAdminUser, 'id'>
  )> }
);


export const ScheduleNewDrawPageDataDocument = gql`
    query ScheduleNewDrawPageData {
  getMyRewardsOrganization {
    id
    pointsPerDollar
    timezone
  }
  getMyRewardsAdminUser {
    id
  }
}
    `;

/**
 * __useScheduleNewDrawPageDataQuery__
 *
 * To run a query within a React component, call `useScheduleNewDrawPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleNewDrawPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleNewDrawPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useScheduleNewDrawPageDataQuery(baseOptions?: Apollo.QueryHookOptions<ScheduleNewDrawPageDataQuery, ScheduleNewDrawPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduleNewDrawPageDataQuery, ScheduleNewDrawPageDataQueryVariables>(ScheduleNewDrawPageDataDocument, options);
      }
export function useScheduleNewDrawPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduleNewDrawPageDataQuery, ScheduleNewDrawPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduleNewDrawPageDataQuery, ScheduleNewDrawPageDataQueryVariables>(ScheduleNewDrawPageDataDocument, options);
        }
export type ScheduleNewDrawPageDataQueryHookResult = ReturnType<typeof useScheduleNewDrawPageDataQuery>;
export type ScheduleNewDrawPageDataLazyQueryHookResult = ReturnType<typeof useScheduleNewDrawPageDataLazyQuery>;
export type ScheduleNewDrawPageDataQueryResult = Apollo.QueryResult<ScheduleNewDrawPageDataQuery, ScheduleNewDrawPageDataQueryVariables>;