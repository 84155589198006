/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

import { Divider } from "@rewards-web/shared/components/divider";
import { Typography } from "@rewards-web/shared/components/typography";
import * as GraphQLTypes from "@rewards-web/shared/graphql-types";
import { formatPhoneNumber } from "@rewards-web/shared/lib/phone-number-format";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { EmployeeIcon } from "./icons/employee-icon";
import { MailIcon } from "./icons/mail-icon";
import { PhoneIcon } from "./icons/phone-icon";

interface CandidateEmployeeLinkProps {
  linkedEmployee: Pick<
    GraphQLTypes.RewardsUser,
    "id" | "firstName" | "lastName" | "email" | "phoneNumber"
  > & {
    branch?: Pick<GraphQLTypes.RewardsOrganizationBranch, "id" | "name"> | null;
  };
  jobTitle?: string;
}

export function CandidateEmployeeLink({
  linkedEmployee,
  jobTitle,
}: CandidateEmployeeLinkProps) {
  const jobTitleToDisplay = linkedEmployee.branch?.name
    ? `${jobTitle} (${linkedEmployee.branch.name})`
    : jobTitle;

  return (
    <>
      <Typography variant="body">
        This candidate has been linked to the following employee account:
      </Typography>

      <div
        css={(theme: AppTheme) => css`
          display: flex;
          padding-top: ${theme.spacing(2)};
        `}
      >
        <div
          css={css`
            flex-shrink: 0;
          `}
        >
          <EmployeeIcon width={40} height={40} />
        </div>

        <div
          css={(theme: AppTheme) => css`
            padding-left: ${theme.spacing(2)};
          `}
        >
          <Link
            to={`/employees/${linkedEmployee.id}/edit`}
            css={css`
              text-decoration: none;
            `}
          >
            <Typography
              variant="h2"
              css={(theme: AppTheme) => css`
                font-weight: bolder;
                font-size: 1.6em;
                word-break: break-all;
                color: ${theme.palette.primary.main};
              `}
            >
              {linkedEmployee.firstName} {linkedEmployee.lastName}
            </Typography>
          </Link>

          <Typography variant="body">{jobTitleToDisplay}</Typography>

          {linkedEmployee.email && (
            <div
              css={(theme: AppTheme) => css`
                display: flex;
                align-items: center;
                margin-top: ${theme.spacing(1)};
              `}
            >
              <div
                css={css`
                  flex-shrink: 0;
                `}
              >
                <MailIcon />
              </div>

              <Typography
                css={(theme: AppTheme) => css`
                  word-break: break-all;
                  margin-left: ${theme.spacing(1)};
                `}
              >
                {linkedEmployee.email}
              </Typography>
            </div>
          )}

          {linkedEmployee.phoneNumber && (
            <div
              css={(theme: AppTheme) => css`
                display: flex;
                align-items: center;
                padding-bottom: ${theme.spacing(2)};
                padding-left: ${theme.spacing(
                  0.5
                )}; // to center-align with the phone icon
              `}
            >
              <PhoneIcon />
              <Typography>
                {formatPhoneNumber(linkedEmployee.phoneNumber)}
              </Typography>
            </div>
          )}
        </div>
      </div>

      <Divider
        css={(theme: AppTheme) => css`
          margin-top: ${theme.spacing(2)};
          margin-bottom: ${theme.spacing(1)};
        `}
      />

      <Typography variant="body">
        Need help with this?{" "}
        <Typography
          component="a"
          href="mailto:help@caribou.care"
          color="primary"
          css={css`
            cursor: pointer;
            text-decoration: none;
          `}
        >
          Contact Us
        </Typography>
      </Typography>
    </>
  );
}
