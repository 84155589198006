/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";

interface RightDrawerContentProps {
  children: ReactNode;
  disablePadding?: boolean;
}

export function RightDrawerContent({
  children,
  disablePadding,
}: RightDrawerContentProps): JSX.Element {
  return (
    <div
      css={(theme: AppTheme) => css`
        overflow-y: scroll;
        flex: 1 1 auto;
        padding: 0 ${disablePadding ? 0 : theme.spacing(1)};

        // force scrollbars to show

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
      `}
    >
      {children}
    </div>
  );
}
