import * as Types from '@rewards-web/shared/graphql-types';

import { JobPostingSettingsFragmentFragment } from './job-posting-settings-fragment.generated';
import { gql } from '@apollo/client';
import { JobPostingSettingsFragmentFragmentDoc } from './job-posting-settings-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetOrganizationInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetOrganizationInfoQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & JobPostingSettingsFragmentFragment
  ) }
);


export const GetOrganizationInfoDocument = gql`
    query GetOrganizationInfo {
  getMyRewardsOrganization {
    ...JobPostingSettingsFragment
  }
}
    ${JobPostingSettingsFragmentFragmentDoc}`;

/**
 * __useGetOrganizationInfoQuery__
 *
 * To run a query within a React component, call `useGetOrganizationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationInfoQuery, GetOrganizationInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationInfoQuery, GetOrganizationInfoQueryVariables>(GetOrganizationInfoDocument, options);
      }
export function useGetOrganizationInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationInfoQuery, GetOrganizationInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationInfoQuery, GetOrganizationInfoQueryVariables>(GetOrganizationInfoDocument, options);
        }
export type GetOrganizationInfoQueryHookResult = ReturnType<typeof useGetOrganizationInfoQuery>;
export type GetOrganizationInfoLazyQueryHookResult = ReturnType<typeof useGetOrganizationInfoLazyQuery>;
export type GetOrganizationInfoQueryResult = Apollo.QueryResult<GetOrganizationInfoQuery, GetOrganizationInfoQueryVariables>;