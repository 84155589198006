import * as React from "react";

function CheckIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.01 9.663l-.002.001L0 5.656l1.318-1.319 2.905 2.905L11.211.254l1.323 1.323L4.23 9.883l-.22-.22z"
        fill="#3F879A"
      />
    </svg>
  );
}

export default CheckIcon;
