import { SVGProps } from "react";

function ExclamationIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity={0.5} fill="#fff">
        <path d="M13 0H3a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3Zm1 13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v10Z" />
        <path d="M7 3.75h2V8.5H7V3.75ZM7 10.25h2v2H7v-2Z" />
      </g>
    </svg>
  );
}

export default ExclamationIcon;
