/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { Alert } from "../../components/alert";

interface ErrorPageProps {
  message?: ReactNode;
}

export function ErrorPage({
  message = "An unexpected error occurred. Refresh the page to try again.",
}: ErrorPageProps): JSX.Element {
  return (
    <div
      css={css`
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <Alert message={message} severity="error" />
    </div>
  );
}
