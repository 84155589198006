import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { reportError } from "@rewards-web/shared/modules/error";

import { useMyBranches } from "../../../../../shared/modules/branches/use-my-branches";
import { OnboardingAddJob } from "./add-job";
import { OnboardingListJobs } from "./list-jobs";
import { useNumJobsQuery } from "./list-jobs/num-jobs.generated";

export function OnboardingCreateJobsPage() {
  const numJobsQuery = useNumJobsQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onError: reportError,
  });
  const myBranchesQuery = useMyBranches();

  if (numJobsQuery.error || myBranchesQuery.error) {
    return (
      <Alert
        message="An unexpected error occurred. Please try again later."
        severity="error"
      />
    );
  }

  if (!numJobsQuery.data || !myBranchesQuery.data) {
    return <PageLoadingState />;
  }

  if (numJobsQuery.data.listJobPostings.total > 0) {
    return <OnboardingListJobs />;
  }

  return (
    <OnboardingAddJob
      branchesData={myBranchesQuery.data}
      onAddedJob={() => {
        numJobsQuery.updateQuery((prev) => ({
          ...prev,
          listJobPostings: {
            ...prev.listJobPostings,
            total: 1,
          },
        }));
      }}
    />
  );
}
