import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

/**
 * - Returns the current given url parameter value (initialized from query params)
 * - Exposes function to set the given url parameter value
 *
 * When the url parameter value is updated:
 * - It will update the query parameter
 * - The returned current value will update (debounced)
 */
export function useQueryParam(
  parameter: string
): [string | null, (q: string | null) => void] {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const value = searchParams.get(parameter) ?? null;

  // this can be debounced if:
  // - we don't want to make possible onChange function calls too quickly, or
  // - updating state causes too many rerenders (and slows the app down)
  const setValue = useCallback(
    (nextValue: string | null) => {
      // reference window.location.search directly for the latest value,
      // since the `useSearchParams` value could be stale
      const nextSearchParams = new URLSearchParams(window.location.search);

      if (nextValue) {
        nextSearchParams.set(parameter, nextValue);
      } else {
        nextSearchParams.delete(parameter);
      }

      if (Array.from(nextSearchParams.entries()).length === 0) {
        // strip "?" from URL if there are no query params
        navigate(location.pathname, { replace: true });
      } else {
        setSearchParams(nextSearchParams, { replace: true });
      }
    },
    [location.pathname, navigate, parameter, setSearchParams]
  );

  return [value, setValue];
}
