import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RecognitionSettingsPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RecognitionSettingsPageDataQuery = (
  { __typename?: 'Query' }
  & { myIdentity?: Types.Maybe<(
    { __typename?: 'Identity' }
    & Pick<Types.Identity, 'id'>
    & { roles: Array<(
      { __typename?: 'IdentityRole' }
      & Pick<Types.IdentityRole, 'id' | 'roleName' | 'active'>
    )> }
  )>, getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'pointsPerDollar'>
  ) }
);


export const RecognitionSettingsPageDataDocument = gql`
    query RecognitionSettingsPageData {
  myIdentity: getMyIdentity {
    id
    roles {
      id
      roleName
      active
    }
  }
  getMyRewardsOrganization {
    id
    pointsPerDollar
  }
}
    `;

/**
 * __useRecognitionSettingsPageDataQuery__
 *
 * To run a query within a React component, call `useRecognitionSettingsPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecognitionSettingsPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecognitionSettingsPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecognitionSettingsPageDataQuery(baseOptions?: Apollo.QueryHookOptions<RecognitionSettingsPageDataQuery, RecognitionSettingsPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecognitionSettingsPageDataQuery, RecognitionSettingsPageDataQueryVariables>(RecognitionSettingsPageDataDocument, options);
      }
export function useRecognitionSettingsPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecognitionSettingsPageDataQuery, RecognitionSettingsPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecognitionSettingsPageDataQuery, RecognitionSettingsPageDataQueryVariables>(RecognitionSettingsPageDataDocument, options);
        }
export type RecognitionSettingsPageDataQueryHookResult = ReturnType<typeof useRecognitionSettingsPageDataQuery>;
export type RecognitionSettingsPageDataLazyQueryHookResult = ReturnType<typeof useRecognitionSettingsPageDataLazyQuery>;
export type RecognitionSettingsPageDataQueryResult = Apollo.QueryResult<RecognitionSettingsPageDataQuery, RecognitionSettingsPageDataQueryVariables>;