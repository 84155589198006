import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SettingsPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SettingsPageDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'usesWorkDevices' | 'referralsEnabled'>
  ) }
);


export const SettingsPageDataDocument = gql`
    query SettingsPageData {
  getMyRewardsOrganization {
    id
    usesWorkDevices
    referralsEnabled
  }
}
    `;

/**
 * __useSettingsPageDataQuery__
 *
 * To run a query within a React component, call `useSettingsPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsPageDataQuery(baseOptions?: Apollo.QueryHookOptions<SettingsPageDataQuery, SettingsPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingsPageDataQuery, SettingsPageDataQueryVariables>(SettingsPageDataDocument, options);
      }
export function useSettingsPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsPageDataQuery, SettingsPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingsPageDataQuery, SettingsPageDataQueryVariables>(SettingsPageDataDocument, options);
        }
export type SettingsPageDataQueryHookResult = ReturnType<typeof useSettingsPageDataQuery>;
export type SettingsPageDataLazyQueryHookResult = ReturnType<typeof useSettingsPageDataLazyQuery>;
export type SettingsPageDataQueryResult = Apollo.QueryResult<SettingsPageDataQuery, SettingsPageDataQueryVariables>;