import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typography } from "@rewards-web/shared/components/typography";

import { InsightMetric } from "../../../../components/insight-metric";

export interface TimeToContactInsightMetricProps {}

export function TimeToContactInsightMetric(
  props: TimeToContactInsightMetricProps
) {
  return (
    <InsightMetric
      label="Time to contact"
      icon={<FontAwesomeIcon icon={faClock} />}
      tooltipText="The average time (in days) from when a candidate applied to when they are marked as ‘contacted’ on the Candidates page."
      value={{
        type: "empty",
        content: <Typography fontWeight={700}>Coming soon</Typography>,
      }}
      percentPointChange={null}
    />
  );
}
