/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "@material-ui/core";
import { useForm } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { CardActions } from "@rewards-web/shared/components/card";
import { Form } from "@rewards-web/shared/components/form";
import { Typography } from "@rewards-web/shared/components/typography";
import { formatPointsAndDollars } from "@rewards-web/shared/lib/format-reward-points";
import { AppTheme } from "@rewards-web/shared/style/types";

import {
  EditReferralStructureFormContent,
  EditReferralStructureFormValues,
  RewardType,
} from "../../../../../../../shared/components/edit-referral-structure-form-content";
import { calculateTotalReferralPointValue } from "../../../../../../../shared/components/edit-referral-structure-form-content/calculate-total-referral-point-value";
import { generateEditRefferalStructureTableRows } from "../../../../../../../shared/components/edit-referral-structure-form-content/generate-edit-referral-structure-table-rows";
import { PageCard } from "../../../../../../../shared/components/page-card";
import { RewardsStructureQuery } from "../../../../../../../shared/components/referrals-structure-table/rewards-structure.generated";

export interface EditReferralStructureFormProps {
  data: RewardsStructureQuery;
  onSubmit(values: EditReferralStructureFormValues): Promise<void>;
}

export const REWARD_TYPE_OPTIONS: Array<{
  label: string;
  value: RewardType;
}> = [
  { label: "Points", value: "points" },
  {
    label: "Draw ticket",
    value: "draw_tickets",
  },
];

export function getDefaultValues(
  data: RewardsStructureQuery
): EditReferralStructureFormValues {
  const pointValues: { [key: string]: number } = {};

  data.getMyRewardsOrganization.referralRewardStructure.items.forEach(
    (item) => {
      const value = ((): number => {
        switch (item.reward.__typename) {
          case "ReferralRewardStructureDrawTicketReward":
            return item.reward.numTickets;
          case "ReferralRewardStructurePointReward":
            return item.reward.pointValue;
          default:
            throw new Error(
              `Unexpected reward type: ${item.reward.__typename}`
            );
        }
      })();

      switch (item.__typename) {
        case "ReferralRewardStructureJobShareItem":
          break; // this isn't editable, so we won't populate a default for it
        case "ReferralRewardStructureReferralRecruitmentItem":
          if (!pointValues["candidateExpressInterestPointValue"]) {
            pointValues["candidateExpressInterestPointValue"] = value;
          } else if (!pointValues["CompletedOrientationPointValue"]) {
            pointValues["CompletedOrientationPointValue"] = value;
          } else if (!pointValues["CompletedFirstShiftPointValue"]) {
            pointValues["CompletedFirstShiftPointValue"] = value;
          }
          break;
        case "ReferralRewardStructureReferralRetentionItem":
          if (!pointValues["hundredHourWorkedPointValue"]) {
            pointValues["hundredHourWorkedPointValue"] = value;
          } else if (!pointValues["threeHundredHourWorkedPointValue"]) {
            pointValues["threeHundredHourWorkedPointValue"] = value;
          }
          break;
        default:
          throw new Error(`Unrecognized item type: ${item.__typename}`);
      }
    }
  );

  return {
    candidateExpressInterestPointValue: formatPointsAndDollars(
      pointValues["candidateExpressInterestPointValue"]
    ),
    CompletedOrientationPointValue: formatPointsAndDollars(
      pointValues["CompletedOrientationPointValue"]
    ),
    CompletedFirstShiftPointValue: formatPointsAndDollars(
      pointValues["CompletedFirstShiftPointValue"]
    ),
    hundredHourWorkedPointValue: formatPointsAndDollars(
      pointValues["hundredHourWorkedPointValue"]
    ),
    threeHundredHourWorkedPointValue: formatPointsAndDollars(
      pointValues["threeHundredHourWorkedPointValue"]
    ),
  };
}

export function EditRewardStructureForm({
  data,
  onSubmit,
}: EditReferralStructureFormProps): JSX.Element {
  const initialValues = getDefaultValues(data);
  const form = useForm<EditReferralStructureFormValues>({
    defaultValues: initialValues,
  });
  const { control } = form;

  const values = form.watch();
  const totalPointValue = calculateTotalReferralPointValue(values);

  const tableRows = generateEditRefferalStructureTableRows({
    data,
  });

  const pointsPerDollar = data.getMyRewardsOrganization.pointsPerDollar;

  return (
    <Form
      submitting={form.formState.isSubmitting}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <PageCard
        css={(theme: AppTheme) => css`
          padding: 0 ${theme.spacing(2)};
        `}
      >
        <EditReferralStructureFormContent
          tableRows={tableRows}
          control={control}
          pointsPerDollar={pointsPerDollar}
          totalPointValue={totalPointValue}
          cardHeadingContent={
            <Typography
              color="grey.800"
              css={(theme: AppTheme) => css`
                margin: ${theme.spacing(3)} 0;
              `}
            >
              Modify referral reward values to match your agency budget.
            </Typography>
          }
          showExplanationTooltip={false}
        />
      </PageCard>

      <CardActions
        css={css`
          display: flex;
          justify-content: flex-end;
        `}
      >
        <Button
          width="auto"
          minWidthPx={130}
          label="Cancel"
          variant="outlined"
          linkTo="/settings/referral-structure"
        />
        <Button
          width="auto"
          minWidthPx={130}
          type="submit"
          color="primary"
          label="Save"
        />
      </CardActions>
    </Form>
  );
}
