import { HubCallback } from "@aws-amplify/core/lib-esm";
import { Auth, Hub } from "aws-amplify";
import { useState, useEffect, ReactNode } from "react";

import { openedFromHomeScreen } from "@rewards-web/shared/lib/opened-from-homescreen";
import {
  useClearAnalyticsIdentity,
  useSetAnalyticsIdentity,
  useTrack,
} from "@rewards-web/shared/modules/analytics";
import {
  reportError,
  useClearErrorTrackingIdentity,
  useSetErrorTrackingIdentity,
} from "@rewards-web/shared/modules/error";

import { CognitoAuthContext } from "./context";
import { useSmsMfaStatus } from "./sms-mfa/hooks";

export { getCognitoAuthStorage } from "./auth-storage";

export * from "./hooks";
export * from "./errors";
export * from "./constants";

let currentLoggedInState: null | "loggedOut" | "loggedIn" = null;

/**
 * Auth state is tracked in memory so non-react parts can access it.
 */
export const CognitoAuthStatus = {
  isAuthenticated() {
    return currentLoggedInState === "loggedIn";
  },
};

interface CognitoAuthProviderProps {
  children: ReactNode;
}

/**
 * This should wrap the application.
 *
 * It will render children after initialization (ie. it knows if the user is logged in or not)
 */
export function CognitoAuthProvider({
  children,
}: CognitoAuthProviderProps): JSX.Element {
  const track = useTrack();
  const setAnalyticsIdentity = useSetAnalyticsIdentity();
  const clearAnalyticsIdentity = useClearAnalyticsIdentity();
  const setErrorTrackingIdentity = useSetErrorTrackingIdentity();
  const clearErrorTrackingIdentity = useClearErrorTrackingIdentity();
  const [initialized, setInitialized] = useState(false);
  const [userId, setUserId] = useState<string | null>(null);
  const { setMfaStatusPostLogin } = useSmsMfaStatus();

  const handleIdentifiedUser = (userId: string) => {
    currentLoggedInState = "loggedIn";
    setAnalyticsIdentity(userId);
    setErrorTrackingIdentity(userId);
    setUserId(userId);
  };

  const handleUserLoggedOut = () => {
    currentLoggedInState = "loggedOut";
    clearAnalyticsIdentity();
    clearErrorTrackingIdentity();
    setUserId(null);
  };

  const initialize = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      handleIdentifiedUser(
        user.signInUserSession.idToken.payload["cognito:username"]
      );
      track("Re-opened app from previous login", {
        openedFromHomeScreen: openedFromHomeScreen(),
      });
    } catch (error) {
      if (error !== "The user is not authenticated") {
        reportError(
          error instanceof Error
            ? error
            : new Error(
                `Could not initialize authenticated user: ${JSON.stringify(
                  error
                )}`
              )
        );
      }
      handleUserLoggedOut();
    }

    setInitialized(true);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    // listen for post-initialization login events and update context accordingly

    const handleAuthEvent: HubCallback = async (event) => {
      switch (event.payload.event) {
        case "signIn":
          currentLoggedInState = "loggedIn"; // ensure mfa status check knows we're authenticated

          await setMfaStatusPostLogin();

          return handleIdentifiedUser(
            event.payload.data.signInUserSession.idToken.payload[
              "cognito:username"
            ]
          );
        case "signOut":
          return handleUserLoggedOut();
      }
    };

    Hub.listen("auth", handleAuthEvent);
    return () => Hub.remove("auth", handleAuthEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUserId, initialized]);

  return (
    <CognitoAuthContext.Provider value={{ userId, setUserId }}>
      {initialized && children}
    </CognitoAuthContext.Provider>
  );
}
