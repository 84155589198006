/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";
import { StylableProps } from "@rewards-web/shared/types";

interface RightDrawerBaseCardProps extends StylableProps {
  children: ReactNode;
}

export function RightDrawerBaseCard({
  className,
  children,
}: RightDrawerBaseCardProps): JSX.Element {
  return (
    <div
      className={className}
      css={(theme: AppTheme) => css`
        background-color: #f9f9fb;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        padding: ${theme.spacing(1.5)} ${theme.spacing(1.3)};
      `}
    >
      {children}
    </div>
  );
}
