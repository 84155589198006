/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";
import { ApolloGraphQLResponseContext } from "@rewards-web/shared/types/apollo-response-context";

import { JobSearchField } from "../../../../job-search-field";
import { useChangeCandidateAssignedJobMutation } from "./change-candidate-assigned-job.generated";
import { ConfirmChangeJobModal } from "./confirm-change-job-modal";

interface ChangeCandidateJobFormValues {
  jobPostingId: string | null;
}

interface ChangeCandidateJobFormProps {
  candidateId: string;
  currentJobPostingId: string | undefined;
  onCancel(): void;
  onChanged(details: { movedOutOfOrganization: boolean }): void;
}

export function ChangeCandidateJobForm({
  candidateId,
  currentJobPostingId,
  onCancel,
  onChanged,
}: ChangeCandidateJobFormProps): JSX.Element {
  const track = useTrack();
  const snackbar = useSnackbar();
  const [confirmPromptOpen, setConfirmPromptOpen] = useState(false);
  const [
    changeAssignedJob,
    { loading: changingJob },
  ] = useChangeCandidateAssignedJobMutation();
  const form = useForm<ChangeCandidateJobFormValues>({
    defaultValues: {
      jobPostingId: null,
    },
  });

  const nextJobPostingId = useWatch({
    control: form.control,
    name: "jobPostingId",
  });

  return (
    <>
      <ConfirmChangeJobModal
        open={confirmPromptOpen}
        onCancel={() => {
          setConfirmPromptOpen(false);
        }}
        confirming={changingJob}
        onConfirm={async (details) => {
          try {
            const { context } = await changeAssignedJob({
              variables: {
                candidateId,
                jobPostingId: nextJobPostingId!,
              },
            });
            setConfirmPromptOpen(false);
            onChanged(details);
            snackbar.show({
              severity: "success",
              message: "The candidate's job has been changed.",
            });
            track("Changed candidate job", {
              candidateId,
              jobPostingId: nextJobPostingId!,
              requestId: (context as ApolloGraphQLResponseContext).requestId,
            });
          } catch (error) {
            snackbar.show({
              severity: "error",
              message: "An unexpected error occurred. Please try again later.",
            });
          }
        }}
        currentJobPostingId={currentJobPostingId ?? null}
        nextJobPostingId={nextJobPostingId}
      />
      <Form
        onSubmit={form.handleSubmit(({ jobPostingId }) => {
          if (jobPostingId === currentJobPostingId) {
            // job hasn't changed, so just close the form and don't execute mutation
            onCancel();
          } else {
            setConfirmPromptOpen(true);
          }
        })}
      >
        <JobSearchField
          control={form.control}
          name="jobPostingId"
          label="Change Job"
          displayCandidateAlertEmail
          rules={{
            required: true,
          }}
          fixLabelToTop
          includeFromSiblingOrganizations
        />
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
          `}
        >
          <Button
            css={(theme: AppTheme) => css`
              margin-right: ${theme.spacing(1)};
            `}
            variant="outlined"
            width="auto"
            label="Cancel"
            onClick={onCancel}
          />
          <Button color="primary" width="auto" label="Confirm" type="submit" />
        </div>
      </Form>
    </>
  );
}
