/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

export function TrashIcon() {
  const theme = useTheme();
  return (
    <svg
      width="11"
      height="13"
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.375 2.375C10.5625 2.375 10.75 2.5625 10.75 2.75V3.125C10.75 3.33594 10.5625 3.5 10.375 3.5H10V11C10 11.8438 9.32031 12.5 8.5 12.5H2.5C1.65625 12.5 1 11.8438 1 11V3.5H0.625C0.414062 3.5 0.25 3.33594 0.25 3.125V2.75C0.25 2.5625 0.414062 2.375 0.625 2.375H2.54688L3.34375 1.0625C3.55469 0.710938 3.90625 0.5 4.30469 0.5H6.67188C7.07031 0.5 7.42188 0.710938 7.63281 1.0625L8.42969 2.375H10.375ZM4.25781 1.69531L3.85938 2.375H7.11719L6.71875 1.69531C6.69531 1.67188 6.64844 1.625 6.60156 1.625H4.39844C4.32812 1.625 4.28125 1.67188 4.25781 1.69531ZM8.5 11.375C8.6875 11.375 8.875 11.2109 8.875 11V3.5H2.125V11C2.125 11.2109 2.28906 11.375 2.5 11.375H8.5ZM5.5 10.25C5.28906 10.25 5.125 10.0859 5.125 9.875V5C5.125 4.8125 5.28906 4.625 5.5 4.625C5.6875 4.625 5.875 4.8125 5.875 5V9.875C5.875 10.0859 5.6875 10.25 5.5 10.25ZM3.625 10.25C3.41406 10.25 3.25 10.0859 3.25 9.875V5C3.25 4.8125 3.41406 4.625 3.625 4.625C3.8125 4.625 4 4.8125 4 5V9.875C4 10.0859 3.8125 10.25 3.625 10.25ZM7.375 10.25C7.16406 10.25 7 10.0859 7 9.875V5C7 4.8125 7.16406 4.625 7.375 4.625C7.5625 4.625 7.75 4.8125 7.75 5V9.875C7.75 10.0859 7.5625 10.25 7.375 10.25Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
}
