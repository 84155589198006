import { compact } from "lodash";

import { CandidateRecruitmentStepName } from "@rewards-web/shared/graphql-types";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";

import { EditReferralStructureFormValues, RewardStructureRow } from ".";
import { safeGetRecruitmentItemDescriptionAndIcon } from "../referrals-structure-table";
import { UserCardIcon } from "../referrals-structure-table/icons/user-card-icon";
import { RewardsStructureQuery } from "../referrals-structure-table/rewards-structure.generated";

export interface generateEditRewardStructureTableRowsProps {
  data: RewardsStructureQuery;
}
export function generateEditRefferalStructureTableRows({
  data,
}: generateEditRewardStructureTableRowsProps) {
  if (!data) {
    return [];
  }

  const items = data.getMyRewardsOrganization.referralRewardStructure.items.map(
    (item): RewardStructureRow | null => {
      switch (item.__typename) {
        case "ReferralRewardStructureJobShareItem": {
          return null;
        }
        case "ReferralRewardStructureReferralRecruitmentItem": {
          const fieldName: keyof EditReferralStructureFormValues = (() => {
            switch (item.step) {
              case CandidateRecruitmentStepName.ApplicationSubmitted:
                return "candidateExpressInterestPointValue";
              case CandidateRecruitmentStepName.CompletedOrientation:
                return "CompletedOrientationPointValue";
              case CandidateRecruitmentStepName.CompletedFirstShift:
                return "CompletedFirstShiftPointValue";
              default: {
                throw new Error(
                  `Unexpected ReferralRewardStructureReferralRecruitmentItem reward of type: ${item.reward.__typename}`
                );
              }
            }
          })();

          switch (item.reward.__typename) {
            case "ReferralRewardStructureDrawTicketReward": {
              return {
                ...safeGetRecruitmentItemDescriptionAndIcon(item.step),
                type: "Draw tickets",
                fieldName,
                fieldType: "draw_tickets",
              };
            }
            case "ReferralRewardStructurePointReward": {
              return {
                ...safeGetRecruitmentItemDescriptionAndIcon(item.step),
                type: "Points",
                fieldName,
                fieldType: "points",
              };
            }
            default: {
              throw new Error(
                `Unexpected ReferralRewardStructureReferralRecruitmentItem reward of type: ${item.reward.__typename}`
              );
            }
          }
        }
        case "ReferralRewardStructureReferralRetentionItem": {
          switch (item.reward.__typename) {
            case "ReferralRewardStructureDrawTicketReward": {
              return {
                icon: <UserCardIcon />,
                description: `${numberWithCommas(
                  item.duration.amount
                )} ${item.duration.unit.toLowerCase()} worked`,
                type: "Draw tickets",
                fieldName: "hundredHourWorkedPointValue",
                fieldType: "draw_tickets",
              };
            }
            case "ReferralRewardStructurePointReward": {
              const fieldName: keyof EditReferralStructureFormValues = (() => {
                switch (item.duration.amount) {
                  case 100:
                    return "hundredHourWorkedPointValue";
                  case 300:
                    return "threeHundredHourWorkedPointValue";

                  default:
                    throw new Error(
                      `Unexpected ReferralRewardStructureReferralRetentionItem reward of type: ${item.reward.__typename}`
                    );
                }
              })();
              return {
                icon: <UserCardIcon />,
                description: `${numberWithCommas(
                  item.duration.amount
                )} ${item.duration.unit.toLowerCase()} worked`,
                type: "Points",

                fieldName,
                fieldType: "points",
              };
            }
            default: {
              throw new Error(
                `Unexpected ReferralRewardStructureReferralRetentionItem reward of type: ${item.reward.__typename}`
              );
            }
          }
        }
        default: {
          throw new Error(
            `Unrecognized ReferralRewardStructure item of type: ${item.__typename}`
          );
        }
      }
    }
  );

  return compact(items);
}
