import { addDays, differenceInDays, isPast, min as minDate } from "date-fns";

import {
  JSONSerializableDateRange,
  DateRangeOptions,
  DateRangeSelection,
} from "./types";

export const optionToValueStr = ({
  startDate,
  endDate,
  type,
}: JSONSerializableDateRange) => JSON.stringify({ startDate, endDate, type });
// control order of props so stringify is deterministic

export const strToDateRange = (str: string): JSONSerializableDateRange =>
  JSON.parse(str) as JSONSerializableDateRange;

/**
 * Get initial date range to show before the user makes any date range selections.
 */
export const getDefaultDateRangeSelection = (
  options: DateRangeOptions
): DateRangeSelection | null => {
  const option = options.monthly.at(0) ?? options.quarterly.at(0);
  if (!option) {
    return null;
  }
  return getDateRangeSelectionFromDateRange({
    selectedOption: option,
    options,
  });
};

/**
 * Convert a single date range into a date range selection with a previous range.
 */
export const getDateRangeSelectionFromDateRange = ({
  selectedOption,
  options,
}: {
  selectedOption: JSONSerializableDateRange;
  options: DateRangeOptions;
}): DateRangeSelection => {
  const type = selectedOption.type;
  const currentRange = {
    startDate: new Date(selectedOption.startDate),
    endDate: new Date(selectedOption.endDate),
  };

  const previousFullRange = (() => {
    const allOptionsForType = options[type];
    const currentIndex = allOptionsForType.findIndex(
      (option) =>
        selectedOption.startDate.valueOf() === option.startDate.valueOf()
    );
    if (currentIndex === -1 || currentIndex === allOptionsForType.length - 1) {
      return null;
    }
    // options are sorted in reverse chronological order so we want to get the next element in the array
    const prev = allOptionsForType[currentIndex + 1];
    return {
      startDate: new Date(prev.startDate),
      endDate: new Date(prev.endDate),
    };
  })();

  return {
    currentRange,
    previousRange: previousFullRange
      ? {
          fullRange: previousFullRange,
          partialRange: getPreviousRangeForComparison({
            previousFullRange,
            currentFullRange: currentRange,
          }),
        }
      : null,
    type,
  };
};

/**
 * Returns a previous range to be used for comparison.
 * If the current range is only partially elapsed,
 * we will return the same number of days in the previous
 * range for comparison.
 *
 * For example, if we are 10 days into Q3,
 * we will only use 10 days into Q2 for comparison.
 *
 * If the elapsed number of days in the current range exceeds
 * the number of days in the previous range (for example, we're 30
 * days into March but February only has 29 days), we will simply
 * return the previous range end.
 *
 * Does not consider timezones such as DST -- if we add days across
 * a DST boundary, we will not get +- 1 hour to account for DST.
 */
export const getPreviousRangeForComparison = ({
  previousFullRange,
  currentFullRange,
}: {
  previousFullRange: { startDate: Date; endDate: Date };
  currentFullRange: { startDate: Date; endDate: Date };
}): { startDate: Date; endDate: Date } => {
  if (isPast(currentFullRange.endDate)) {
    // we don't need to apply any partial range logic if the current range is not in-progress
    return previousFullRange;
  }
  // get the days elapsed in current range
  const daysElapsedInCurrentRange = differenceInDays(
    new Date(),
    currentFullRange.startDate
  );

  // apply days elapsed in current range to previous range start
  const previousEndDateEquivalent = addDays(
    previousFullRange.startDate,
    daysElapsedInCurrentRange
  );

  return {
    startDate: previousFullRange.startDate,
    endDate: minDate([previousFullRange.endDate, previousEndDateEquivalent]),
  };
};
