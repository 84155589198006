/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useFormContext, useWatch } from "react-hook-form";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { LoginPageContainer } from "../login-page-container";
import { CompleteNewPasswordFormValues, LoginSubmissionError } from "../types";
import { PasswordRequirements } from "./password-requirements";
import { validatePassword } from "./password-requirements/lib";

export interface CompleteNewPasswordFormProps {
  onSubmit(values: CompleteNewPasswordFormValues): void;
  submissionError: LoginSubmissionError | null;
  type: "RESET_PASSWORD" | "INITIAL_PASSWORD";
}

export function CompleteNewPasswordForm({
  onSubmit,
  submissionError,
  type,
}: CompleteNewPasswordFormProps): JSX.Element {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useFormContext<CompleteNewPasswordFormValues>();
  const password = useWatch({ control, name: "password" });

  return (
    <LoginPageContainer>
      <Form onSubmit={handleSubmit(onSubmit)} submitting={isSubmitting}>
        <div
          css={css`
            max-width: 370px;
            margin: 0 auto;
          `}
        >
          <Typography
            variant="h1"
            color="textPrimary"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(4)};
            `}
          >
            Create New Password
          </Typography>
          <div
            css={(theme: AppTheme) =>
              css`
                margin-bottom: ${theme.spacing(4)};
              `
            }
          >
            <Typography variant="body" color="textSecondary">
              {type === "INITIAL_PASSWORD"
                ? "Almost done! Please pick a password with:"
                : "Thank you! We’ve emailed you a verification code. Please enter it and pick a password with:"}
            </Typography>
            <PasswordRequirements />
          </div>
          {submissionError && (
            <Alert
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(3)};
              `}
              message={
                <>
                  An unexpected error occurred.
                  <br />
                  Please try again later.
                </>
              }
              severity={submissionError.severity}
            />
          )}

          {type === "RESET_PASSWORD" && (
            <TextField
              label="Verification Code"
              error={errors.code}
              disableAutocomplete
              {...register("code", {
                required: "The verification code is required",
              })}
            />
          )}

          <TextField
            label="Password"
            type="password"
            error={errors.password}
            {...register("password", {
              required: "Password is required",
              validate: (value) => {
                const validations = validatePassword(value);

                if (validations.some((validation) => !validation.fulfilled)) {
                  return "The password requirements have not been met";
                }
              },
            })}
          />
          <TextField
            label="Confirm Password"
            type="password"
            error={errors.confirmPassword}
            {...register("confirmPassword", {
              required: "Confirm Password is required",
              validate: (value: string) => {
                if (value !== password) {
                  return "Passwords do not match";
                }
              },
            })}
          />
          <Button
            color="primary"
            label="Create New Password"
            type="submit"
            loading={isSubmitting}
          />
        </div>
      </Form>
    </LoginPageContainer>
  );
}
