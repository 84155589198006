import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AwardBonusPointsMutationVariables = Types.Exact<{
  amount: Types.Scalars['Int'];
  userId: Types.Scalars['ID'];
  messageToUser: Types.Scalars['String'];
  categoryId?: Types.Maybe<Types.Scalars['ID']>;
  from: Types.Scalars['String'];
}>;


export type AwardBonusPointsMutation = (
  { __typename?: 'Mutation' }
  & { awardUserBonusPoints?: Types.Maybe<(
    { __typename?: 'AwardedBonusPoints' }
    & Pick<Types.AwardedBonusPoints, 'id'>
  )> }
);


export const AwardBonusPointsDocument = gql`
    mutation AwardBonusPoints($amount: Int!, $userId: ID!, $messageToUser: String!, $categoryId: ID, $from: String!) {
  awardUserBonusPoints(
    amount: $amount
    userId: $userId
    messageToUser: $messageToUser
    from: $from
    categoryId: $categoryId
    mustBeClaimed: true
  ) {
    id
  }
}
    `;
export type AwardBonusPointsMutationFn = Apollo.MutationFunction<AwardBonusPointsMutation, AwardBonusPointsMutationVariables>;

/**
 * __useAwardBonusPointsMutation__
 *
 * To run a mutation, you first call `useAwardBonusPointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAwardBonusPointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [awardBonusPointsMutation, { data, loading, error }] = useAwardBonusPointsMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      userId: // value for 'userId'
 *      messageToUser: // value for 'messageToUser'
 *      categoryId: // value for 'categoryId'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useAwardBonusPointsMutation(baseOptions?: Apollo.MutationHookOptions<AwardBonusPointsMutation, AwardBonusPointsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AwardBonusPointsMutation, AwardBonusPointsMutationVariables>(AwardBonusPointsDocument, options);
      }
export type AwardBonusPointsMutationHookResult = ReturnType<typeof useAwardBonusPointsMutation>;
export type AwardBonusPointsMutationResult = Apollo.MutationResult<AwardBonusPointsMutation>;
export type AwardBonusPointsMutationOptions = Apollo.BaseMutationOptions<AwardBonusPointsMutation, AwardBonusPointsMutationVariables>;