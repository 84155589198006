import { ClassNames } from "@emotion/react";
import SettingsIcon from "@material-ui/icons/Settings";

interface SettingsLogoProps {
  className?: string;
  color?: string;
}

export function SettingsLogo({ className, color }: SettingsLogoProps) {
  return (
    <ClassNames>
      {({ css, cx }) => (
        <SettingsIcon
          className={cx(
            className,
            color
              ? css`
                  color: ${color};
                `
              : null
          )}
        />
      )}
    </ClassNames>
  );
}
