import * as React from "react";

export function NoImageIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity={0.1} fill="#000">
        <path d="M13.6 14.073a3.697 3.697 0 11-7.394.002 3.697 3.697 0 017.394-.002zM29.298 17.68L14.535 32.445h22.197v-7.33l-7.434-7.435z" />
        <path d="M39.054 7.926a.924.924 0 01.027.216v25.71a.94.94 0 01-.94.94H12.183l-2.346 2.346H38.14a3.292 3.292 0 003.288-3.288l-.002-25.708c0-.73-.242-1.403-.646-1.949l-1.727 1.733zM41.659.343a1.175 1.175 0 00-1.66 0l-4.516 4.516H3.856A3.292 3.292 0 00.57 8.147v25.71a3.29 3.29 0 002.682 3.229L.343 39.994A1.175 1.175 0 001.172 42c.299 0 .598-.115.828-.347L41.657 1.996a1.17 1.17 0 00.002-1.653zM3.856 34.797a.94.94 0 01-.94-.94L2.916 8.141c0-.52.42-.94.94-.94H33.13L15.028 25.31l-2.982-2.982-6.783 6.782v3.34H7.89l-2.347 2.346-1.686.002z" />
      </g>
    </svg>
  );
}
