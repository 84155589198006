/** @jsxImportSource @emotion/react */
import { ClassNames } from "@emotion/react";
import { FormControlLabel } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Radio from "@material-ui/core/Radio";
import { ReactNode } from "react";

import { Typography } from "@rewards-web/shared/components/typography";
import { StylableProps } from "@rewards-web/shared/types";

interface AccessLevelAccordionProps extends StylableProps {
  children?: ReactNode;
  expanded: boolean;
  title: string;
  description: string;
  startIcon: JSX.Element;
  disabled: boolean;
  onClick(): void;
}

export function AccessLevelAccordion({
  children,
  expanded,
  title,
  description,
  startIcon,
  disabled,
  onClick,
  className,
}: AccessLevelAccordionProps): JSX.Element {
  return (
    <ClassNames>
      {({ css, theme }) => (
        <Accordion
          css={css`
            box-shadow: 0px 0px 0px 0px;
            border-width: ${expanded ? 2 : 1}px;
            border-color: ${expanded ? theme.palette.primary.main : "#dfe4eb"};
            border-style: solid;
          `}
          className={className}
          expanded={children ? expanded : false}
        >
          <AccordionSummary>
            <FormControlLabel
              css={css`
                margin-left: 0;
              `}
              aria-label={`Select ${title}`}
              onClick={(event: any) => {
                event.stopPropagation();
              }}
              control={
                <Radio
                  color="primary"
                  onClick={onClick}
                  checked={expanded}
                  disabled={disabled}
                />
              }
              label={
                <div
                  css={css`
                    display: grid;
                    grid-template-columns: 1fr 20fr 1fr;
                    grid-gap: 16px;
                    justify: space-between;
                  `}
                >
                  <div
                    css={css`
                      margin-top: 8px;
                    `}
                  >
                    {startIcon}
                  </div>
                  <div>
                    <Typography
                      css={css`
                        font-weight: bold;
                      `}
                    >
                      {title}
                    </Typography>
                    <Typography>{description}</Typography>
                  </div>
                </div>
              }
              labelPlacement="start"
            />
          </AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
      )}
    </ClassNames>
  );
}
