/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/types";

export function OverrideCategoryInfoAlert() {
  const track = useTrack();

  return (
    <Alert
      severity="info"
      title="Override category points"
      message={
        <>
          Individual Admins can override the default points, depending on their
          permissions. Note that Admins without this permission cannot access
          any categories that do not have default points set. Go to{" "}
          <Link
            to="/admins"
            onClick={() => {
              track(
                "Clicked link to admins from settings recognition category modal"
              );
            }}
            css={(theme: AppTheme) => css`
              text-decoration: none;
              color: ${theme.palette.primary.main};
              font-weight: 600;
            `}
          >
            Admins
          </Link>{" "}
          to modify permissions.
        </>
      }
    />
  );
}
