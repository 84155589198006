/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";

import { PageBanners } from "./page-banners";

interface PageLayoutProps {
  children?: ReactNode;
  maxContentWidth: number;
}

export function PageLayout({
  children,
  maxContentWidth,
}: PageLayoutProps): JSX.Element {
  return (
    <>
      <div
        css={css`
          position: relative;
          width: 100%;
        `}
      >
        <PageBanners maxContentWidth={maxContentWidth} />
      </div>
      <div
        css={(theme: AppTheme) => css`
          max-width: ${maxContentWidth}px;
          padding: ${theme.spacing(3)} ${theme.spacing(4)};
          padding-top: ${theme.spacing(5)};
        `}
      >
        {children}
      </div>
    </>
  );
}
