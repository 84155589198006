/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import AddIcon from "@material-ui/icons/Add";
import { useEffect, useState } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { HeaderWithButton } from "../../../../shared/components/header-with-button";
import { CreateGroupModal } from "./create-group-modal";
import { GroupListTable } from "./group-list-table";
import { useGroupsListQuery } from "./groups-list.generated";

const GROUPS_LIMIT = 2000;

export function GroupsPage() {
  const [createGroupModalOpen, setCreateGroupModalOpen] = useState<boolean>(
    false
  );
  const { data, error, refetch } = useGroupsListQuery({
    onError: reportError,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    variables: {
      offset: 0,
      limit: GROUPS_LIMIT,
    },
  });

  const totalGroups = data?.listRewardsGroups.total;
  useEffect(() => {
    if (typeof totalGroups === "number" && totalGroups > GROUPS_LIMIT) {
      reportError(
        new Error(
          `Warning -- organization with > ${GROUPS_LIMIT} groups; only top ${GROUPS_LIMIT} groups shown in GroupsListTable`
        )
      );
    }
  }, [totalGroups]);

  if (error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  if (!data) {
    return <PageLoadingState />;
  }

  const groups = data.listRewardsGroups.items;

  return (
    <>
      <CreateGroupModal
        open={createGroupModalOpen}
        onClose={() => setCreateGroupModalOpen(false)}
        onExited={() => setCreateGroupModalOpen(false)}
        onSuccess={refetch}
      />

      <Card
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(3)};
        `}
      >
        <HeaderWithButton
          header={
            <Typography variant="h3" color="textPrimary" fontWeight={700}>
              Employee groups
            </Typography>
          }
          button={
            <Button
              css={(theme: AppTheme) => css`
                height: fit-content;
                margin-bottom: ${theme.spacing(2)};
                float: right;
              `}
              color="primary"
              label="Add group"
              size="medium"
              startIcon={<AddIcon />}
              width="auto"
              onClick={() => setCreateGroupModalOpen(true)}
            />
          }
        />

        {groups.length > 0 ? (
          <GroupListTable
            rows={groups.map((group) => ({
              id: group.id,
              name: group.name,
              tagsCount: group.tagsConfig.or.flatMap((andSet) =>
                andSet.and.map((tag) => tag.externalId)
              ).length,
            }))}
          />
        ) : (
          <Typography>
            Create an employee group to help you manage operations and reporting
            in a way that is specific to your team. Add as many groups as
            needed.
          </Typography>
        )}
      </Card>
    </>
  );
}
