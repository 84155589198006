/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { MouseEventHandler, useState } from "react";

import { IconButton } from "@rewards-web/shared/components/icon-button";
import { AppTheme } from "@rewards-web/shared/style/types";

interface ListActionsProps {
  menuOptions: Array<{
    label: string;
    onClick(): void;
    icon?: JSX.Element;
    disabled?: boolean;
  }>;
}

export function ListActions({
  menuOptions,
}: ListActionsProps): JSX.Element | null {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (menuOptions.length === 0) {
    return null;
  }

  return (
    <>
      <IconButton
        css={css`
          margin-top: -14px;
          margin-bottom: -14px;
        `}
        onClick={handleClick}
        aria-label={"Actions"}
      >
        <MoreVertIcon
          css={css`
            height: 0.8em;
            width: 0.8em;
          `}
        />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {menuOptions.map(({ label, icon, disabled, onClick }) => (
          <MenuItem
            key={label}
            onClick={() => {
              handleClose();
              onClick();
            }}
            css={css`
              display: flex;
              align-items: center;
            `}
            disabled={disabled}
          >
            {icon && (
              <Box
                css={(theme: AppTheme) => css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: ${theme.spacing(2)};
                `}
              >
                {icon}
              </Box>
            )}
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
