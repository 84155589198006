import { css } from "@emotion/react";
import { Control, Controller, useFormState, useWatch } from "react-hook-form";

import {
  SearchTextField,
  ValueLabel,
} from "@rewards-web/shared/components/search-text-field";
import { TextField } from "@rewards-web/shared/components/text-field";
import {
  CaProvinceCode,
  MailingAddressCountryCode,
  UsStateAbbreviation,
} from "@rewards-web/shared/graphql-types";
import { getCharactersRemainingText } from "@rewards-web/shared/lib/characters-remaining-text";
import { isPostalcode } from "@rewards-web/shared/lib/is-postal-code";
import { isZipcode } from "@rewards-web/shared/lib/is-zip-code";
import { AppTheme } from "@rewards-web/shared/style/types";

const MAX_ADDRESS_LENGTH = 45;
const MAX_CITY_LENGTH = 30;

// Helper function to create new enum keys type
function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

export type AddressFormValues = {
  address1: string;
  companyName: string;
  address2: string;
  city: string;
  country: MailingAddressCountryCode | "";
  province: CaProvinceCode | "";
  state: UsStateAbbreviation | "";
  postalCode: string;
  zipCode: string;
};

interface SettingsAddressFieldsProps {
  control: Control<AddressFormValues>;
  isRequired?: boolean;
}

export function SettingsAddressFields({
  control,
  isRequired,
}: SettingsAddressFieldsProps) {
  const { errors } = useFormState({ control });
  const address1 = useWatch({ control, name: "address1" });
  const address2 = useWatch({ control, name: "address2" });
  const countryValue = useWatch({ control, name: "country" });
  const cityValue = useWatch({ control, name: "city" });

  const provinces = enumKeys(CaProvinceCode).map<ValueLabel>((key) => ({
    label: CaProvinceCode[key],
    value: CaProvinceCode[key],
  }));

  const states = enumKeys(UsStateAbbreviation).map<ValueLabel>((key) => ({
    label: UsStateAbbreviation[key],
    value: UsStateAbbreviation[key],
  }));

  return (
    <div>
      <TextField
        label="Address 1"
        error={errors.address1}
        helperText={getCharactersRemainingText(address1, MAX_ADDRESS_LENGTH)}
        {...control.register("address1", {
          required: address1 && "Address is a required field",
          minLength: {
            value: 2,
            message: "Address must be at least 2 characters",
          },

          validate: (value) => {
            if (value && value.length > MAX_ADDRESS_LENGTH) {
              return getCharactersRemainingText(value, MAX_ADDRESS_LENGTH);
            }
          },
        })}
      />

      <TextField
        label="Address 2 (optional)"
        error={errors.address2}
        helperText={getCharactersRemainingText(address2, MAX_ADDRESS_LENGTH)}
        {...control.register("address2", {
          validate: (value) => {
            if (value && value.length > MAX_ADDRESS_LENGTH) {
              return getCharactersRemainingText(value, MAX_ADDRESS_LENGTH);
            }
          },
        })}
      />

      <div
        css={(theme: AppTheme) => css`
          display: grid;
          grid-template-columns: calc(50% - ${theme.spacing(1)}) calc(
              50% - ${theme.spacing(1)}
            );
          grid-column-gap: ${theme.spacing(1)};
        `}
      >
        <TextField
          label="City"
          error={errors.city}
          helperText={getCharactersRemainingText(cityValue, MAX_CITY_LENGTH)}
          {...control.register("city", {
            required: address1 && "City is a required field",

            minLength: {
              value: 2,
              message: "City must be at least 2 characters",
            },

            validate: (value) => {
              if (value && value.length > MAX_CITY_LENGTH) {
                return getCharactersRemainingText(value, MAX_CITY_LENGTH);
              }
            },
          })}
        />

        <Controller
          control={control}
          name="country"
          rules={{
            required: address1 && "Country is required",
          }}
          render={({ field, fieldState: { error } }) => (
            <SearchTextField
              {...field}
              error={error}
              label="Country"
              options={[
                {
                  label: "Canada",
                  value: MailingAddressCountryCode.Ca,
                },
                {
                  label: "United States",
                  value: MailingAddressCountryCode.Us,
                },
              ]}
            />
          )}
        />
      </div>

      <div
        css={(theme: AppTheme) => css`
          display: grid;
          grid-template-columns: calc(50% - ${theme.spacing(1)}) calc(
              50% - ${theme.spacing(1)}
            );
          grid-column-gap: ${theme.spacing(1)};
        `}
      >
        {countryValue === MailingAddressCountryCode.Ca && (
          <Controller
            control={control}
            name="province"
            rules={{ required: address1 && "Province is required" }}
            render={({ field }) => (
              <SearchTextField
                {...field}
                error={errors.province}
                label="Province"
                options={provinces}
              />
            )}
          />
        )}

        {countryValue === MailingAddressCountryCode.Us && (
          <Controller
            control={control}
            name="state"
            rules={{ required: address1 && "State is required" }}
            render={({ field }) => (
              <SearchTextField
                {...field}
                error={errors.state}
                label="State"
                options={states}
              />
            )}
          />
        )}

        {countryValue === MailingAddressCountryCode.Ca && (
          <TextField
            label="Postal Code"
            placeholder="e.g. A1A 1A1"
            error={errors.postalCode}
            {...control.register("postalCode", {
              required: address1 && "Postal code is a required field",
              validate: (value) => {
                if (!isPostalcode(value)) {
                  return "Please enter a valid postal code";
                }
              },
            })}
          />
        )}
        {countryValue === MailingAddressCountryCode.Us && (
          <TextField
            label="Zip Code"
            error={errors.zipCode}
            {...control.register("zipCode", {
              required: address1 && "Zip code is a required field",
              validate: (value) => {
                if (value && !isZipcode(value)) {
                  return "Please enter a valid zip code";
                }
              },
            })}
          />
        )}
      </div>
    </div>
  );
}
