/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box } from "@material-ui/core";

import { IconButton } from "@rewards-web/shared/components/icon-button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";

import { CloseIcon } from "./close-icon";

interface OverBudgetModalProps {
  open: boolean;
  onCancel(): void;
}

export function OverBudgetModal(props: OverBudgetModalProps): JSX.Element {
  return (
    <Modal width="550px" open={props.open} onClose={props.onCancel}>
      <Box
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <ModalTitle>{"No points available"}</ModalTitle>
        <IconButton onClick={props.onCancel} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Box>
      <ModalContent>
        <Typography>
          {`You do not have points available to reward anyone. Please speak with your manager if you need more budget for this period.`}
        </Typography>
      </ModalContent>
    </Modal>
  );
}
