/** @jsxImportSource @emotion/react */
import { ClassNames } from "@emotion/react";
import {
  Button as MuiButton,
  CircularProgress,
  darken,
} from "@material-ui/core";
import { TypographyProps } from "@mui/material";
import { forwardRef, MouseEventHandler, ReactNode } from "react";
import { Link } from "react-router-dom";

import { TestProps, StylableProps } from "../../types";
import { useFormControlContext } from "../form/form-control";
import { Typography } from "../typography";

export interface ButtonProps extends StylableProps, TestProps {
  /**
   * Button label
   */
  label: string | ReactNode;
  /**
   * Optional click handler
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;

  /**
   * Passed to underlying `button#type`
   */
  type?: "button" | "submit";

  /**
   * Path that the button click leads to (optional)
   */
  linkTo?: string;

  /**
   * If `linkTo` is provided, this state is passed with the link (for routing state).
   */
  linkState?: any;

  /**
   * Uses an external link tag instead of react router's Link
   */
  externalLink?: boolean;

  /**
   * Downloads contents of the linkTo with given filename, if provided.
   */
  download?: boolean | string;

  target?: "_blank";

  /**
   * Disables the button and shows a loading spinner
   */
  loading?: boolean;

  /**
   * Disables the button.
   * If `loading` is `true`, the button will be disabled, and this prop has no  effect.
   */
  disabled?: boolean;

  variant?: "contained" | "outlined" | "text";
  color?:
    | "primary"
    | "primaryLight"
    | "secondary"
    | "tertiary"
    | "tertiaryLight";
  textColor?: "primary";
  whiteBackground?: boolean;
  size?: "tiny" | "small" | "medium" | "large";

  startIcon?: ReactNode;
  endIcon?: ReactNode;

  width?: "full" | "auto";
  minWidthPx?: number;
  typographyVariant?: TypographyProps["variant"];
  noWrap?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      cypressId,
      testId,
      label,
      onClick,
      className,
      linkTo,
      linkState,
      type = "button",
      loading = false,
      disabled = false,
      variant = "contained",
      startIcon,
      download,
      endIcon,
      color,
      textColor,
      whiteBackground,
      size,
      width = "full",
      minWidthPx,
      externalLink = false,
      target,
      typographyVariant = "buttonLarge",
      noWrap,
    }: ButtonProps,
    ref
  ): JSX.Element => {
    const { submitting, readOnly } = useFormControlContext();

    const component = (() => {
      if (linkTo) {
        if (externalLink || download) {
          return "a";
        }

        return Link;
      }

      return "button";
    })();

    const componentProps = (() => {
      if (component === "a") {
        return {
          download: download,
          href: linkTo,
          target,
        };
      }

      if (component === Link) {
        return {
          to: linkTo,
          state: linkState,
        };
      }

      return {};
    })();

    return (
      <ClassNames>
        {({ css, cx, theme }) => (
          <MuiButton
            ref={ref}
            data-cy={cypressId}
            data-testid={testId}
            download={download}
            className={cx(
              css`
                ${width === "full"
                  ? css`
                      min-width: auto;
                      width: 100%;
                    `
                  : css`
                      width: auto;
                    `}

                ${minWidthPx &&
                css`
                  min-width: ${minWidthPx}px;
                `}
                text-transform: initial;
                ${theme.buttonBorderRadius &&
                css`
                  border-radius: ${theme.buttonBorderRadius}px;
                `}

                ${whiteBackground &&
                css`
                  background-color: #ffffff;
                `}

                ${color === "tertiary" &&
                css`
                  background-color: ${theme.palette.divider};
                  &:hover {
                    background-color: ${darken(theme.palette.divider, 0.1)};
                  }
                  border-color: ${theme.palette.divider};
                `}

                ${color === "tertiaryLight" &&
                css`
                  background-color: #fff;
                  &:hover {
                    background-color: ${darken("#fff", 0.1)};
                  }
                  border-color: ${theme.palette.grey[400]};
                `}

              ${color === "primaryLight" &&
                css`
                  color: ${theme.palette.getContrastText(
                    theme.palette.primary.main
                  )};
                  background-color: ${theme.palette.tertiary.main};
                  &:hover {
                    background-color: ${darken(
                      theme.palette.tertiary.main,
                      0.1
                    )};
                  }
                  border-color: ${theme.palette.divider};
                `}
                
              ${!color &&
                css`
                  color: ${theme.palette.text.primary};
                  border-color: ${theme.palette.divider};
                `}
              text-align: center;
                ${textColor &&
                css`
                  color: ${(() => {
                    switch (textColor) {
                      case "primary":
                        return theme.palette.primary.main;
                      default:
                        return undefined;
                    }
                  })()};
                `}
                ${(component === "a" || component === Link) &&
                css`
                  // disable long press styles for anchor tags on mobile
                  -webkit-touch-callout: none;
                  -webkit-user-drag: none;
                `}
              `,
              className
            )}
            color={
              color === "primary" || color === "secondary" ? color : undefined
            }
            disableElevation
            variant={variant === "text" ? undefined : variant}
            component={component}
            {...componentProps}
            onClick={onClick}
            type={type}
            disabled={
              disabled ||
              loading ||
              submitting ||
              (type === "submit" && readOnly)
            }
            startIcon={startIcon}
            endIcon={endIcon}
            size={size === "tiny" ? "small" : size}
            classes={{
              sizeSmall: css``,
              sizeLarge: css`
                height: 48px;
                line-height: 19px;
              `,
              iconSizeLarge: css`
                & > * {
                  font-size: 16px;
                }
              `,
            }}
          >
            <Typography
              css={css`
                ${size === "tiny" &&
                css`
                  font-size: 0.75rem;
                `}
                ${size === "small" &&
                theme.typography.buttonSmall.fontSize &&
                css`
                  font-size: ${theme.typography.buttonSmall.fontSize};
                `};
                ${size === "large" &&
                theme.typography.buttonLarge.fontSize &&
                css`
                  font-size: ${theme.typography.buttonLarge.fontSize};
                `};

                ${noWrap
                  ? css`
                      white-space: nowrap;
                    `
                  : undefined}
              `}
              variant={typographyVariant}
            >
              {label}
            </Typography>

            {(loading || (submitting && type === "submit")) && (
              <CircularProgress
                size={16}
                className={css`
                  margin-left: ${theme.spacing(1)};
                  color: rgba(0, 0, 0, 0.38);
                `}
              />
            )}
          </MuiButton>
        )}
      </ClassNames>
    );
  }
);
