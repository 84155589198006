import {
  Control,
  FieldValues,
  Path,
  useFormState,
  get,
  useWatch,
} from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";
import { isPhoneNumber } from "@rewards-web/shared/lib/is-phone-number";
import { stripNonNumericCharacters } from "@rewards-web/shared/lib/strip-non-numeric-characters";

interface EmployeePersonalCellFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  parsonalPhoneNumberName: Path<T>;
  personalEmailName: Path<T>;
  usesWorkDevices: boolean;
}

export function EmployeePersonalCellField<T extends FieldValues>({
  control,
  name,
  personalEmailName,
  parsonalPhoneNumberName,
  usesWorkDevices,
}: EmployeePersonalCellFieldProps<T>) {
  const formState = useFormState({ control, name });

  const personalPhoneNumber = useWatch({
    control,
    name: parsonalPhoneNumberName,
  });
  const personalEmail = useWatch({ control, name: personalEmailName });

  return (
    <TextField
      type="tel"
      label={`Personal cell phone${usesWorkDevices ? " (optional)" : ""}`}
      error={get(formState.errors, name)}
      {...control.register(name, {
        required: !usesWorkDevices ? `Phone number is required` : undefined,
        validate: (value: string) => {
          if (value && !isPhoneNumber(stripNonNumericCharacters(value))) {
            return "This is not a valid phone number";
          }
          if (personalEmail && !personalPhoneNumber) {
            return "Phone number is required if email is provided";
          }
        },
      })}
    />
  );
}
