import { useState } from "react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  useWatch,
} from "react-hook-form";
import { useDebounce } from "use-debounce";

import { SearchTextField } from "@rewards-web/shared/components/search-text-field";
import { reportError } from "@rewards-web/shared/modules/error";

import { useManagerFieldSearchQuery } from "./manager-field-search.generated";

const SEARCH_DEBOUNCE_MS = 300;

interface EmployeeManagerFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  personalEmailFieldName: Path<T>;
  workEmailFieldName: Path<T>;
  existingEmployeeId?: string;
}

export function EmployeeManagerField<T extends FieldValues>({
  control,
  name,
  personalEmailFieldName,
  workEmailFieldName,
  existingEmployeeId,
}: EmployeeManagerFieldProps<T>) {
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedManagerSearchQuery] = useDebounce(
    searchQuery,
    SEARCH_DEBOUNCE_MS
  );
  const managerSearchQuery = useManagerFieldSearchQuery({
    fetchPolicy: "cache-first",
    onError: reportError,
    variables: {
      searchQuery: debouncedManagerSearchQuery || null,
    },
  });

  const personalEmailField = useWatch({
    control,
    name: personalEmailFieldName,
  });
  const workEmailField = useWatch({ control, name: workEmailFieldName });

  const managerOptions =
    (managerSearchQuery.data ?? managerSearchQuery.previousData)
      ?.listRewardsAdmins.items ?? [];

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (managerId) => {
          if (managerId) {
            const manager = managerOptions.find(
              (option) => option.id === managerId
            );

            if (manager?.active === false) {
              return "De-activated admins can not be assigned as managers";
            }

            if (
              !existingEmployeeId &&
              manager?.email &&
              (manager.email.trim() === personalEmailField.trim() ||
                manager.email.trim() === workEmailField.trim())
            ) {
              return "This manager matches this employee's email. An employee can not assign themself as their manager.";
            }
          }
        },
      }}
      render={({ field, fieldState }) => (
        <SearchTextField
          {...field}
          error={fieldState.error}
          label="Manager"
          options={managerOptions.map((admin) => ({
            value: admin.id,
            label: `${admin.firstName} ${admin.lastName}`,
            subLabels: admin.active ? [] : ["De-activated"],
          }))}
          loadingOptions={managerSearchQuery.loading}
          onInputChange={(text) => setSearchQuery(text)}
          debouncedInputValue={debouncedManagerSearchQuery}
          closedWhenInputTextEmpty
          placeholder="Type to search..."
          obscureOptionsFromRecordings
        />
      )}
    />
  );
}
