import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EnableHhaxAutoSyncMutationVariables = Types.Exact<{
  hhaxIntegrationId: Types.Scalars['ID'];
  filters: Types.HhaxCaribouUserSyncFiltersInput;
}>;


export type EnableHhaxAutoSyncMutation = (
  { __typename?: 'Mutation' }
  & { enableCaribouUserSyncForHHAXIntegration?: Types.Maybe<(
    { __typename?: 'HHAXIntegration' }
    & Pick<Types.HhaxIntegration, 'id'>
  )> }
);


export const EnableHhaxAutoSyncDocument = gql`
    mutation EnableHHAXAutoSync($hhaxIntegrationId: ID!, $filters: HHAXCaribouUserSyncFiltersInput!) {
  enableCaribouUserSyncForHHAXIntegration(
    hhaxIntegrationId: $hhaxIntegrationId
    filters: $filters
  ) {
    id
  }
}
    `;
export type EnableHhaxAutoSyncMutationFn = Apollo.MutationFunction<EnableHhaxAutoSyncMutation, EnableHhaxAutoSyncMutationVariables>;

/**
 * __useEnableHhaxAutoSyncMutation__
 *
 * To run a mutation, you first call `useEnableHhaxAutoSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableHhaxAutoSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableHhaxAutoSyncMutation, { data, loading, error }] = useEnableHhaxAutoSyncMutation({
 *   variables: {
 *      hhaxIntegrationId: // value for 'hhaxIntegrationId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEnableHhaxAutoSyncMutation(baseOptions?: Apollo.MutationHookOptions<EnableHhaxAutoSyncMutation, EnableHhaxAutoSyncMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableHhaxAutoSyncMutation, EnableHhaxAutoSyncMutationVariables>(EnableHhaxAutoSyncDocument, options);
      }
export type EnableHhaxAutoSyncMutationHookResult = ReturnType<typeof useEnableHhaxAutoSyncMutation>;
export type EnableHhaxAutoSyncMutationResult = Apollo.MutationResult<EnableHhaxAutoSyncMutation>;
export type EnableHhaxAutoSyncMutationOptions = Apollo.BaseMutationOptions<EnableHhaxAutoSyncMutation, EnableHhaxAutoSyncMutationVariables>;