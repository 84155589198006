import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ReactNode, useCallback } from "react";

import { ErrorPage } from "../../pages/error";

export type TransformBreadcrumbFn = (
  breadcrumb: Sentry.Breadcrumb,
  hint?: Sentry.BreadcrumbHint
) => Sentry.Breadcrumb;

export type AllowUrls = Sentry.BrowserOptions["allowUrls"];

export function initializeSentry(config: {
  dsn: string;
  environment: string;
  transformBreadcrumb?: TransformBreadcrumbFn;
  allowUrls: AllowUrls;
}) {
  Sentry.init({
    dsn: config.dsn,
    environment: config.environment,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    beforeBreadcrumb: config.transformBreadcrumb,
    allowUrls: config.allowUrls,
  });
}

export function useSetErrorTrackingIdentity() {
  return useCallback((userId: string) => Sentry.setUser({ id: userId }), []);
}

export function useClearErrorTrackingIdentity() {
  return () => Sentry.configureScope((scope) => scope.setUser(null));
}

/**
 * Reports an error to the error reporting service
 */
export function reportError(error: any) {
  captureError(error);
}

function captureError(error: any) {
  Sentry.captureException(error);
  // eslint-disable-next-line no-console
  console.error(error);
}

/**
 * Reports a breadcrumb to provide context leading up to a potential error
 */
export function reportErrorBreadcrumb(breadcrumb: {
  type?: "error";
  category?: string;
  level?: `${Sentry.Severity}`;
  data?: {
    [key: string]: any;
  };
}) {
  Sentry.addBreadcrumb({
    ...breadcrumb,
    level: Sentry.Severity.Info,
  });
}

interface AppErrorBoundaryProps {
  children: ReactNode;
}

/**
 * This should wrap the application
 */
export function AppErrorBoundary({ children }: AppErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={
        <ErrorPage
          message={
            <>
              An unexpected error occurred. Refresh the page to try again.
              <br />
              If the problem persists, please contact{" "}
              <a href="mailto:help@caribou.care">help@caribou.care</a>.
            </>
          }
        />
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
