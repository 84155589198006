/** @jsxImportSource @emotion/react */
import { ReactNode } from "react";

import { Card } from "@rewards-web/shared/components/card";
import { StylableProps } from "@rewards-web/shared/types";

interface AdminOnboardingCardProps extends StylableProps {
  children: ReactNode;
}

export function AdminOnboardingCard({
  children,
  className,
}: AdminOnboardingCardProps) {
  return <Card className={className}>{children}</Card>;
}
