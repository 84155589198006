import { SVGProps } from "react";

export function StatsStarIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={17}
      fill="none"
      {...props}
    >
      <circle
        cx={8.934}
        cy={8.269}
        r={8.173}
        fill={props.fill}
        opacity={0.89}
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M8.922 3.365a.235.235 0 0 0-.213.145L7.522 6.68H3.721a.234.234 0 0 0-.224.145.199.199 0 0 0 .084.235L6.67 9.07l-1.187 3.281a.198.198 0 0 0 .086.236.26.26 0 0 0 .278 0l3.088-2.01 3.087 2.01a.26.26 0 0 0 .278 0 .198.198 0 0 0 .087-.236L11.199 9.07l3.088-2.01a.199.199 0 0 0 .084-.235.234.234 0 0 0-.224-.145h-3.802L9.16 3.51c-.033-.09-.13-.15-.237-.145Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
