/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

export function CircleCheckIcon() {
  const theme = useTheme();
  return (
    <svg
      height="22"
      width="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        margin: 0px 1px;
      `}
    >
      <rect width="22" height="22" rx="11" fill={theme.palette.primary.main} />
      <path
        d="M15.375 8.25C15.375 8.42578 15.2969 8.58203 15.1797 8.69922L10.1797 13.6992C10.0625 13.8164 9.90625 13.875 9.75 13.875C9.57422 13.875 9.41797 13.8164 9.30078 13.6992L6.80078 11.1992C6.68359 11.082 6.625 10.9258 6.625 10.75C6.625 10.3984 6.89844 10.125 7.25 10.125C7.40625 10.125 7.5625 10.2031 7.67969 10.3203L9.75 12.3711L14.3008 7.82031C14.418 7.70312 14.5742 7.625 14.75 7.625C15.082 7.625 15.375 7.89844 15.375 8.25Z"
        fill="white"
      />
    </svg>
  );
}
