import { Alert } from "@rewards-web/shared/components/alert";

export function ForbiddenErrorPage(): JSX.Element {
  return (
    <Alert
      severity="warning"
      message="You do not have permission to view this page."
    />
  );
}
