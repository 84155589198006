import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ChangeCandidateAssignedJobMutationVariables = Types.Exact<{
  candidateId: Types.Scalars['ID'];
  jobPostingId: Types.Scalars['ID'];
}>;


export type ChangeCandidateAssignedJobMutation = (
  { __typename?: 'Mutation' }
  & { assignCandidateToJobPosting: (
    { __typename?: 'Candidate' }
    & Pick<Types.Candidate, 'id'>
    & { assignedToJobPosting?: Types.Maybe<(
      { __typename?: 'JobPosting' }
      & Pick<Types.JobPosting, 'id' | 'title' | 'geography'>
    )> }
  ) }
);


export const ChangeCandidateAssignedJobDocument = gql`
    mutation ChangeCandidateAssignedJob($candidateId: ID!, $jobPostingId: ID!) {
  assignCandidateToJobPosting(
    candidateId: $candidateId
    jobPostingId: $jobPostingId
  ) {
    id
    assignedToJobPosting {
      id
      title
      geography
    }
  }
}
    `;
export type ChangeCandidateAssignedJobMutationFn = Apollo.MutationFunction<ChangeCandidateAssignedJobMutation, ChangeCandidateAssignedJobMutationVariables>;

/**
 * __useChangeCandidateAssignedJobMutation__
 *
 * To run a mutation, you first call `useChangeCandidateAssignedJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCandidateAssignedJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCandidateAssignedJobMutation, { data, loading, error }] = useChangeCandidateAssignedJobMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      jobPostingId: // value for 'jobPostingId'
 *   },
 * });
 */
export function useChangeCandidateAssignedJobMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCandidateAssignedJobMutation, ChangeCandidateAssignedJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeCandidateAssignedJobMutation, ChangeCandidateAssignedJobMutationVariables>(ChangeCandidateAssignedJobDocument, options);
      }
export type ChangeCandidateAssignedJobMutationHookResult = ReturnType<typeof useChangeCandidateAssignedJobMutation>;
export type ChangeCandidateAssignedJobMutationResult = Apollo.MutationResult<ChangeCandidateAssignedJobMutation>;
export type ChangeCandidateAssignedJobMutationOptions = Apollo.BaseMutationOptions<ChangeCandidateAssignedJobMutation, ChangeCandidateAssignedJobMutationVariables>;