/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { ComponentType } from "react";

import { Card } from "@rewards-web/shared/components/card";
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Typography } from "@rewards-web/shared/components/typography";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { AppTheme } from "@rewards-web/shared/style/types";

export interface EmployeeStatCardProps {
  value: number | undefined;
  label: string;
  IconComponent: ComponentType<{ fill: string }>;
}

export function EmployeeStatCard({
  value,
  label,
  IconComponent,
}: EmployeeStatCardProps) {
  const theme = useTheme();

  return (
    <Card
      variant="outlined"
      css={(theme: AppTheme) => css`
        padding: ${theme.spacing(2)} min(25%, ${theme.spacing(6)});
        margin-bottom: ${theme.spacing(1.5)};
      `}
    >
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          align-items: center;
          gap: ${theme.spacing(1.5)};
        `}
      >
        <IconComponent
          fill={theme.palette.primary.main}
          css={css`
            overflow: visible;
          `}
        />
        <Typography
          color="primary"
          css={css`
            font-size: 1.4rem;
            font-weight: 600;
            overflow-wrap: anywhere;
            width: 100%;
          `}
        >
          {typeof value === "number" ? (
            numberWithCommas(value)
          ) : (
            <Skeleton animated width="100%" />
          )}
        </Typography>
      </div>
      <Typography variant="caption" display="block">
        {label}
      </Typography>
    </Card>
  );
}
