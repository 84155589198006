import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CandidatesCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CandidatesCountQuery = (
  { __typename?: 'Query' }
  & { listCandidatesV2: (
    { __typename?: 'ListCandidatesResponse' }
    & Pick<Types.ListCandidatesResponse, 'total'>
  ) }
);


export const CandidatesCountDocument = gql`
    query CandidatesCount {
  listCandidatesV2(offset: 0, limit: 0) {
    total
  }
}
    `;

/**
 * __useCandidatesCountQuery__
 *
 * To run a query within a React component, call `useCandidatesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidatesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidatesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useCandidatesCountQuery(baseOptions?: Apollo.QueryHookOptions<CandidatesCountQuery, CandidatesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidatesCountQuery, CandidatesCountQueryVariables>(CandidatesCountDocument, options);
      }
export function useCandidatesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidatesCountQuery, CandidatesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidatesCountQuery, CandidatesCountQueryVariables>(CandidatesCountDocument, options);
        }
export type CandidatesCountQueryHookResult = ReturnType<typeof useCandidatesCountQuery>;
export type CandidatesCountLazyQueryHookResult = ReturnType<typeof useCandidatesCountLazyQuery>;
export type CandidatesCountQueryResult = Apollo.QueryResult<CandidatesCountQuery, CandidatesCountQueryVariables>;