/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FileError, useDropzone } from "react-dropzone";
import { FieldError } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

import { CircleCheckIcon } from "./images/circle-check";

export interface FileInputProps {
  isDisabled?: boolean;
  uploadedFile: File | null;
  setUploadedFile: (file: File) => void;
  error: FieldError | undefined;
  setError: (error: FieldError | undefined) => void;
  hasPreviouslyUploaded?: boolean;
  acceptedFileTypes?: string;
  maxSizeMB?: number;
}

export function FileInput({
  isDisabled,
  uploadedFile,
  setUploadedFile,
  error,
  setError,
  hasPreviouslyUploaded = false,
  acceptedFileTypes = ".csv,.xlsx",
  maxSizeMB = 10,
}: FileInputProps) {
  const handleFileSelected = (file: File) => {
    setUploadedFile(file);
    setError(undefined);
  };

  const handleFileRejected = async (rawErrors: FileError[]) => {
    if (rawErrors.length > 0) {
      setError({
        type: "maxLength",
        message: `File size has to be less than ${maxSizeMB} MB`,
      });
    }
  };

  const { getInputProps, open: openFileSelector } = useDropzone({
    noDrag: true, // disables drag n drop
    noClick: true,
    disabled: isDisabled,
    maxFiles: 1,
    accept: acceptedFileTypes,
    maxSize: maxSizeMB * 1000000, // in bytes
    onDropAccepted: (files) => {
      handleFileSelected(files[0]);
    },
    onDropRejected: (rejections) => {
      if (rejections.length > 0) {
        handleFileRejected(rejections[0].errors);
      }
    },
  });

  return (
    <div
      css={(theme: AppTheme) => css`
        display: grid;
        grid-template-columns: 65% 30%;
        column-gap: ${theme.spacing(1.5)};
        margin-top: ${theme.spacing(3)};
        ${hasPreviouslyUploaded &&
        !uploadedFile &&
        css`
          padding: ${theme.spacing(1.25)} 0;
          border: 1px solid ${theme.palette.divider};
          border-radius: 8px;
          margin-bottom: ${theme.spacing(3.75)};
        `}
      `}
    >
      {hasPreviouslyUploaded && !uploadedFile ? (
        <div
          css={(appTheme: AppTheme) => css`
            display: flex;
            align-items: center;
            padding-left: ${appTheme.spacing(2)};
          `}
        >
          <CircleCheckIcon />
          <Typography
            color="primary"
            css={(appTheme: AppTheme) => css`
              font-weight: 600;
              margin-left: ${appTheme.spacing(1.25)};
            `}
          >
            File Uploaded
          </Typography>
        </div>
      ) : (
        <TextField
          size="medium"
          error={error}
          fixLabelToTop
          disableAutocomplete
          readOnly
          label={`${acceptedFileTypes} file`}
          value={uploadedFile?.name ? uploadedFile.name : ""}
        />
      )}
      <div>
        <input data-testid="photo-uploader" {...getInputProps()} />
        <Button
          disabled={isDisabled}
          variant="outlined"
          color="primary"
          label={
            uploadedFile || hasPreviouslyUploaded
              ? "Change file"
              : "Select file"
          }
          onClick={openFileSelector}
        />
      </div>
    </div>
  );
}
