/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { useSelectedSuperuserTenantId } from "../../../modules/superuser-tenant-selector";
import { NavigationBar } from "../navigation-bar";
import { SideMenu } from "../side-menu";

const NAVIGATION_BAR_HEIGHT = 60;
const MENU_WIDTH = 200;

interface NavigationContainerProps {
  children: ReactNode;
  iconUrl?: string;
}

export function NavigationContainer({
  children,
  iconUrl,
}: NavigationContainerProps): JSX.Element {
  const [
    { canMakeQueriesWithTenant, selectedTenantId },
    setSelectedTenantId,
  ] = useSelectedSuperuserTenantId();

  return (
    <>
      <SideMenu navbarHeight={NAVIGATION_BAR_HEIGHT} navbarWidth={MENU_WIDTH} />

      <NavigationBar
        height={NAVIGATION_BAR_HEIGHT}
        selectedSuperuserOrganizationId={selectedTenantId}
        setSelectedSuperuserOrganizationId={setSelectedTenantId}
        iconUrl={iconUrl}
      />

      {canMakeQueriesWithTenant && (
        <div
          css={css`
            padding-top: ${NAVIGATION_BAR_HEIGHT}px;
            margin-left: ${MENU_WIDTH}px;
            position: relative;
          `}
        >
          {children}
        </div>
      )}
    </>
  );
}
