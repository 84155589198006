import { SVGProps } from "react";

export function EditIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.00713 7.81471L8.05091 10.6833V10.6842C7.94967 10.9889 8.00029 11.3236 8.18872 11.5851C8.37621 11.8457 8.67808 11.9995 8.99962 11.9995C9.10743 11.9995 9.2143 11.9826 9.31554 11.948L12.1841 10.9917H12.1851C12.3313 10.943 12.4654 10.8605 12.5751 10.7508L19.3106 4.01525C20.2284 3.09654 20.2284 1.60791 19.3106 0.688266C18.391 -0.227632 16.9042 -0.227632 15.9846 0.688266L9.24905 7.42479C9.13936 7.53447 9.05587 7.66846 9.00713 7.81471ZM10.8305 8.67154L17.3993 2.0999C17.538 1.96209 17.7611 1.96209 17.8999 2.0999C18.0377 2.23864 18.0377 2.46175 17.8999 2.60049L11.3282 9.17022L10.5811 9.41959L10.8305 8.67154Z"
        fill={props.fill || "#2C393F"}
      />
      <path
        d="M18.9996 7.00001C18.4474 7.00001 17.9993 7.44812 17.9993 8.00028V15.0003V14.9994C17.9993 16.6568 16.6569 17.9993 14.9994 17.9993H5.00051C3.34309 17.9993 2.00063 16.6568 2.00063 14.9994V5.00043C2.00063 3.34301 3.34309 2.00056 5.00051 2.00056H12.0005H11.9996C12.5518 2.00056 12.9999 1.55245 12.9999 1.00028C12.9999 0.448109 12.5518 0 11.9996 0H5.00053C3.67497 0.00187504 2.40384 0.528722 1.4662 1.46619C0.528554 2.40367 0.00187192 3.67485 0 5.00053V14.9995C0.00187504 16.325 0.528722 17.5962 1.4662 18.5338C2.40367 19.4714 3.67485 19.9981 5.00053 20H14.9995C16.325 19.9981 17.5962 19.4713 18.5338 18.5338C19.4714 17.5963 19.9981 16.3252 20 14.9995V8.0004C20 7.73509 19.8941 7.4801 19.7066 7.29262C19.5191 7.10514 19.2649 7.00001 18.9996 7.00001Z"
        fill={props.fill || "#2C393F"}
      />
    </svg>
  );
}
