/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { alpha } from "@material-ui/core";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import { useEffect, useMemo, useState } from "react";

import {
  countSelectedOptionChildren,
  SelectCheckboxesField,
} from "@rewards-web/shared/components/select-checkboxes-field";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  JobPosting,
  Maybe,
  RewardsOrganizationBranch,
} from "@rewards-web/shared/graphql-types";
import {
  useTrack,
  useTrackScreenRecordingEvent,
} from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/theme";

import {
  CandidateJobFilterValue,
  deserializeCandidateJobFilter,
  getCandidateJobFilterCheckboxOptions,
  serializeCandidateJobFilter,
  SerializedCandidateJobFilter,
} from "./lib";

type Job = Pick<
  JobPosting,
  "id" | "title" | "geography" | "closedForSubmission"
> & {
  branch?: Maybe<
    { __typename?: "RewardsOrganizationBranch" } & Pick<
      RewardsOrganizationBranch,
      "id" | "name"
    >
  >;
};

export interface CandidateJobFilterProps {
  jobs: Job[];
  /**
   * Job posting ids
   */
  value: SerializedCandidateJobFilter;
  onChange(value: SerializedCandidateJobFilter): void;
  disabled: boolean;
}

export function CandidateJobFilter({
  jobs,
  value: propValue,
  onChange,
  disabled,
}: CandidateJobFilterProps): JSX.Element {
  const track = useTrack();
  const trackScreenRecordingEvent = useTrackScreenRecordingEvent();
  const value = propValue;

  const [selectedFilterValues, setSelectedFilterValues] = useState<
    Set<CandidateJobFilterValue>
  >(() => deserializeCandidateJobFilter(jobs, value));

  useEffect(() => {
    setSelectedFilterValues((prev) => {
      return deserializeCandidateJobFilter(jobs, value, prev);
    });
  }, [jobs, value]);

  const options = getCandidateJobFilterCheckboxOptions(jobs);

  const someJobsHaveBranches = useMemo(() => jobs.some((job) => !!job.branch), [
    jobs,
  ]);

  const numItemsSelected = someJobsHaveBranches
    ? countSelectedOptionChildren(selectedFilterValues, options)
    : propValue.jobPostingIds.length;

  return (
    <SelectCheckboxesField
      css={css`
        width: 190px;
      `}
      id="job-filter"
      disabled={disabled}
      label="Jobs"
      leftIcon={<FilterListOutlinedIcon fontSize="small" />}
      options={options}
      value={Array.from(selectedFilterValues)}
      onOpen={() => {
        trackScreenRecordingEvent("candidates_job_filter_opened");
      }}
      onChange={(value: string[]) => {
        const nextValue = new Set(value as CandidateJobFilterValue[]);
        setSelectedFilterValues(nextValue);
        onChange(serializeCandidateJobFilter(nextValue, jobs));

        track("Candidates job filter updated", {
          selectedJobPostingIds: Array.from(nextValue)
            .filter((value) => value.startsWith("job-"))
            .map((value) => value.substring(4)),
        });
      }}
      rightAdornment={
        numItemsSelected > 0 && (
          <Typography
            css={(theme: AppTheme) => css`
              font-weight: 800;
              padding-left: ${theme.spacing(1.5)};
              padding-bottom: 2px;
              height: 14px;
              display: flex;
              align-items: center;
              ${numItemsSelected > 0 &&
              css`
                border-left: 1px solid ${alpha("#444444", 0.2)};
              `}
            `}
            color={"primary"}
            variant="body"
            align="right"
          >
            {numItemsSelected}
          </Typography>
        )
      }
    />
  );
}
