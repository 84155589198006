import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateRedemptionNotificationEmailsMutationVariables = Types.Exact<{
  redemptionSummaryNotificationEmails: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type UpdateRedemptionNotificationEmailsMutation = (
  { __typename?: 'Mutation' }
  & { partialUpdateMyRewardsOrganizationRedemptionSettings: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'redemptionSummaryNotificationEmails'>
  ) }
);


export const UpdateRedemptionNotificationEmailsDocument = gql`
    mutation UpdateRedemptionNotificationEmails($redemptionSummaryNotificationEmails: [String!]!) {
  partialUpdateMyRewardsOrganizationRedemptionSettings(
    redemptionSummaryNotificationEmails: $redemptionSummaryNotificationEmails
  ) {
    id
    redemptionSummaryNotificationEmails
  }
}
    `;
export type UpdateRedemptionNotificationEmailsMutationFn = Apollo.MutationFunction<UpdateRedemptionNotificationEmailsMutation, UpdateRedemptionNotificationEmailsMutationVariables>;

/**
 * __useUpdateRedemptionNotificationEmailsMutation__
 *
 * To run a mutation, you first call `useUpdateRedemptionNotificationEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRedemptionNotificationEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRedemptionNotificationEmailsMutation, { data, loading, error }] = useUpdateRedemptionNotificationEmailsMutation({
 *   variables: {
 *      redemptionSummaryNotificationEmails: // value for 'redemptionSummaryNotificationEmails'
 *   },
 * });
 */
export function useUpdateRedemptionNotificationEmailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRedemptionNotificationEmailsMutation, UpdateRedemptionNotificationEmailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRedemptionNotificationEmailsMutation, UpdateRedemptionNotificationEmailsMutationVariables>(UpdateRedemptionNotificationEmailsDocument, options);
      }
export type UpdateRedemptionNotificationEmailsMutationHookResult = ReturnType<typeof useUpdateRedemptionNotificationEmailsMutation>;
export type UpdateRedemptionNotificationEmailsMutationResult = Apollo.MutationResult<UpdateRedemptionNotificationEmailsMutation>;
export type UpdateRedemptionNotificationEmailsMutationOptions = Apollo.BaseMutationOptions<UpdateRedemptionNotificationEmailsMutation, UpdateRedemptionNotificationEmailsMutationVariables>;