import { AuthorizedAdminPageWrapper } from "../../../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { SettingsJobPostingCard } from "./job-posting-card";

export function SettingsJobPostingPage() {
  return (
    <AuthorizedAdminPageWrapper resource="settingsJobPosting">
      <SettingsJobPostingCard />;
    </AuthorizedAdminPageWrapper>
  );
}
