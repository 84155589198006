/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { FieldError } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Card } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { RewardsOrganizationOnboardingStepName } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { FileInput } from "../../../../../shared/components/file-input";
import { useUploadBulkUserFileMutation } from "../../../../../shared/modules/employees/list/components/bulk-employee-upload-modal/bulk-employee-upload.generated";
import { AdminOnboardingCard } from "../../components/onboarding-card/onboarding-card";
import { AdminOnboardingCardContent } from "../../components/onboarding-card/onboarding-card-content";
import { AdminOnboardingCardHeader } from "../../components/onboarding-card/onboarding-card-header";
import { AdminOnboardingStepActions } from "../../components/step-actions";
import { useNextOnboardingStepPath } from "../../hooks/use-next-step-path";
import { useOnboardingState } from "../../hooks/use-onboarding-state";
import { useOnboardingCaregiverInvitesDataQuery } from "./caregiver-invites-data.generated";
import { BellIcon } from "./images/bell-icon";

export function OnboardingCaregiverInvitesPage() {
  const onboardingState = useOnboardingState();

  const hasPreviouslyCompleted = onboardingState.completedSteps.has(
    RewardsOrganizationOnboardingStepName.CaregiverInvites
  );
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [error, setError] = useState<FieldError | undefined>(undefined);
  const [uploadFileMutation] = useUploadBulkUserFileMutation();
  const snackbar = useSnackbar();
  const nextOnboardingStepPath = useNextOnboardingStepPath();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { data } = useOnboardingCaregiverInvitesDataQuery({
    onError: reportError,
  });

  const onSubmit = async () => {
    if (
      !uploadedFile &&
      !data?.getMyRewardsOrganization.bulkUserUploadIsProcessing
    ) {
      setError({
        type: "required",
        message: "Please select a file before uploading.",
      });
      return;
    }
    setIsLoading(true);
    try {
      if (uploadedFile) {
        await uploadFileMutation({ variables: { file: uploadedFile } });
      }
      await onboardingState.recordCompletedOnboardingStep(
        RewardsOrganizationOnboardingStepName.CaregiverInvites
      );

      if (!nextOnboardingStepPath) {
        throw new Error("No next onboarding step path");
      }
      navigate(nextOnboardingStepPath);
    } catch (e) {
      reportError(e);
      snackbar.show({
        severity: "error",
        message: "An unexpected error has occurred. Please try again later.",
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <AdminOnboardingCard>
        <AdminOnboardingCardHeader title="📤 Caregiver Invites" />
        <AdminOnboardingCardContent>
          <div
            css={css`
              display: flex;
            `}
          >
            <Typography
              css={(appTheme: AppTheme) =>
                css`
                  margin-right: ${appTheme.spacing(3.75)};
                `
              }
              color="textSecondary"
            >
              Your agency is unique. Every home care provider keeps track of
              their active staff in a slightly different way. We require a
              caregiver list to ensure that we are inviting the right caregivers
              to your rewards program when we launch.{" "}
            </Typography>
          </div>
          <Typography
            variant="body"
            css={(appTheme: AppTheme) => css`
              font-weight: 600;
              margin-top: ${appTheme.spacing(3.75)};
              margin-bottom: ${appTheme.spacing(0.75)};
            `}
          >
            Upload Caregiver List
          </Typography>
          <Typography>
            These are the caregivers who should have a rewards account created
            for them.
          </Typography>
          <FileInput
            uploadedFile={uploadedFile}
            setUploadedFile={setUploadedFile}
            isDisabled={isLoading}
            error={error}
            setError={setError}
            hasPreviouslyUploaded={
              data?.getMyRewardsOrganization.bulkUserUploadIsProcessing
            }
          />
          <Card
            css={(appTheme: AppTheme) => css`
              padding: ${appTheme.spacing(2.5)} ${appTheme.spacing(3.75)};
            `}
            backgroundColor="grey"
            variant="flat"
          >
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <BellIcon />
              <Typography
                css={(appTheme: AppTheme) =>
                  css`
                    font-weight: 600;
                    margin-left: ${appTheme.spacing(1)};
                  `
                }
              >
                If you use HHAeXchange
              </Typography>
            </div>
            <Typography
              css={(appTheme: AppTheme) =>
                css`
                  margin-top: ${appTheme.spacing(0.5)};
                `
              }
            >
              If you are on HHAeXchange, you can export a copy of your List of
              Caregivers report. Filter for the employee type, discipline,
              office, and branch as applicable before exporting.
              <br />
              <br />
              On an ongoing basis, we will sync this list to your active Caribou
              accounts!
            </Typography>
          </Card>
        </AdminOnboardingCardContent>
      </AdminOnboardingCard>
      <AdminOnboardingStepActions
        disableSkip={hasPreviouslyCompleted}
        submitButtonLabel={
          hasPreviouslyCompleted ? "Save and Continue" : "Save"
        }
        onSubmit={onSubmit}
        disableContinue={isLoading}
      />
    </>
  );
}
