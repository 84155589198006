import { useLocation, useSearchParams } from "react-router-dom";

const QUERY_PARAM_KEY = "redirect";

/**
 * Given a path to the login route, returns paths that can facilitate
 * a post-login redirect.
 */
export function usePostLoginRedirect(params: {
  loginPath: string;
}): {
  /**
   * The current login redirect path. This should be accessed in the login route.
   * After logging in, the user should be navigated to this path.
   */
  postLoginRedirectPath: string | null;

  /**
   * The path that can be used to log the user in, which includes the login redirect path.
   */
  loginPath: string;
} {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  return {
    postLoginRedirectPath: searchParams.get(QUERY_PARAM_KEY),
    loginPath: `${params.loginPath}?${new URLSearchParams({
      redirect: `${location.pathname}${location.search}${location.hash}`,
    })}`,
  };
}
