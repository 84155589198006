import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

import { PageLayout } from "../../../shared/components/page-layout";
import { AuthorizedAdminPageWrapper } from "../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { ForbiddenErrorPage } from "../../../shared/modules/permissions/components/forbidden-error-page";
import { InsightsContent } from "./page-contents";

const MAX_CONTENT_WIDTH = 1400;

export function InsightsPage(): JSX.Element {
  const insightsPageEnabled = useFeatureFlag(
    "admin-app-homepage-insights-page-enabled-temp"
  );

  if (!insightsPageEnabled) {
    return <ForbiddenErrorPage />;
  }

  return (
    <AuthorizedAdminPageWrapper resource="home">
      <PageLayout maxContentWidth={MAX_CONTENT_WIDTH}>
        <InsightsContent />
      </PageLayout>
    </AuthorizedAdminPageWrapper>
  );
}
