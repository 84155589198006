import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type TimezoneFieldOptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TimezoneFieldOptionsQuery = (
  { __typename?: 'Query' }
  & { getTimezoneOptions: Array<(
    { __typename?: 'TimezoneOption' }
    & Pick<Types.TimezoneOption, 'id' | 'name' | 'currentTimeFormat'>
  )> }
);


export const TimezoneFieldOptionsDocument = gql`
    query TimezoneFieldOptions {
  getTimezoneOptions {
    id
    name
    currentTimeFormat
  }
}
    `;

/**
 * __useTimezoneFieldOptionsQuery__
 *
 * To run a query within a React component, call `useTimezoneFieldOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimezoneFieldOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimezoneFieldOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimezoneFieldOptionsQuery(baseOptions?: Apollo.QueryHookOptions<TimezoneFieldOptionsQuery, TimezoneFieldOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimezoneFieldOptionsQuery, TimezoneFieldOptionsQueryVariables>(TimezoneFieldOptionsDocument, options);
      }
export function useTimezoneFieldOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimezoneFieldOptionsQuery, TimezoneFieldOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimezoneFieldOptionsQuery, TimezoneFieldOptionsQueryVariables>(TimezoneFieldOptionsDocument, options);
        }
export type TimezoneFieldOptionsQueryHookResult = ReturnType<typeof useTimezoneFieldOptionsQuery>;
export type TimezoneFieldOptionsLazyQueryHookResult = ReturnType<typeof useTimezoneFieldOptionsLazyQuery>;
export type TimezoneFieldOptionsQueryResult = Apollo.QueryResult<TimezoneFieldOptionsQuery, TimezoneFieldOptionsQueryVariables>;