/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { last } from "lodash";

import { Button } from "@rewards-web/shared/components/button";
import { FormControlProvider } from "@rewards-web/shared/components/form/form-control";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { useSendResumeReminderMutation } from "./send-resume-reminder.generated";

interface ConfirmSendResumeReminderModalProps {
  candidateId: string;
  open: boolean;
  onClose(): void;
  onSentResume(): void;
}

export function ConfirmSendResumeReminderModal({
  candidateId,
  open,
  onClose,
  onSentResume,
}: ConfirmSendResumeReminderModalProps): JSX.Element {
  const track = useTrack();
  const snackbar = useSnackbar();
  const [
    sendResumeReminder,
    { loading: sending },
  ] = useSendResumeReminderMutation();

  const handleConfirm = async () => {
    try {
      const response = await sendResumeReminder({ variables: { candidateId } });
      onClose();
      onSentResume();
      track("Resume reminder sent", {
        candidateId,
        reminderId: last(
          response.data?.sendCandidateResumeReminder.resumeReminders
        )?.id,
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  return (
    <FormControlProvider value={{ submitting: sending }}>
      <Modal
        width="488px"
        open={open}
        onClose={onClose}
        disableBackdropClick={sending}
      >
        <ModalTitle>Send a Reminder?</ModalTitle>
        <ModalContent>
          <Typography
            variant="body"
            color="textPrimary"
            css={(theme: AppTheme) =>
              css`
                padding-bottom: ${theme.spacing(2)};
              `
            }
          >
            Clicking "confirm" will send the candidate an email asking them to
            provide their resume.
          </Typography>
        </ModalContent>
        <ModalActions>
          <Button
            size="large"
            label="Cancel"
            variant="outlined"
            onClick={() => {
              onClose();
            }}
          />
          <Button
            size="large"
            label="Confirm"
            color="primary"
            onClick={handleConfirm}
          />
        </ModalActions>
      </Modal>
    </FormControlProvider>
  );
}
