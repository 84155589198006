import {
  Control,
  FieldValues,
  Path,
  useFormState,
  useWatch,
  get,
} from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";
import { getCharactersRemainingText } from "@rewards-web/shared/lib/characters-remaining-text";

const MAX_RATE_OF_PAY_LENGTH = 55;

interface JobRateOfPayFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export function JobRateOfPayField<T extends FieldValues>({
  control,
  name,
}: JobRateOfPayFieldProps<T>) {
  const formState = useFormState({ control, name });
  const value = useWatch({ control, name });

  return (
    <TextField
      fixLabelToTop
      label="Rate of pay"
      placeholder="E.g. $20 per hr"
      disableAutocomplete
      helperText={getCharactersRemainingText(value, MAX_RATE_OF_PAY_LENGTH)}
      error={get(formState.errors, name)}
      {...control.register(name, {
        validate: (value) => {
          if (value && value.length > MAX_RATE_OF_PAY_LENGTH) {
            return getCharactersRemainingText(value, MAX_RATE_OF_PAY_LENGTH);
          }
        },
      })}
    />
  );
}
