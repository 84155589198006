import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateRewardsAdminInfoMutationVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
  firstName?: Types.Maybe<Types.Scalars['String']>;
  lastName?: Types.Maybe<Types.Scalars['String']>;
  email?: Types.Maybe<Types.Scalars['String']>;
  jobTitle?: Types.Maybe<Types.Scalars['String']>;
  permissions?: Types.Maybe<Types.RewardsAdminPermissionsInput>;
  role?: Types.Maybe<Types.RewardsAdminRoleType>;
  branchIds?: Types.Maybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  active?: Types.Maybe<Types.Scalars['Boolean']>;
  automaticRecognitionBudgetConfig?: Types.Maybe<Types.RewardsAdminAutomaticRecognitionBudgetConfigInput>;
}>;


export type UpdateRewardsAdminInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateRewardsAdmin: (
    { __typename?: 'RewardsAdminUser' }
    & Pick<Types.RewardsAdminUser, 'id'>
  ) }
);


export const UpdateRewardsAdminInfoDocument = gql`
    mutation UpdateRewardsAdminInfo($userId: ID!, $firstName: String, $lastName: String, $email: String, $jobTitle: String, $permissions: RewardsAdminPermissionsInput, $role: RewardsAdminRoleType, $branchIds: [ID!], $active: Boolean, $automaticRecognitionBudgetConfig: RewardsAdminAutomaticRecognitionBudgetConfigInput) {
  updateRewardsAdmin(
    userId: $userId
    firstName: $firstName
    lastName: $lastName
    email: $email
    jobTitle: $jobTitle
    permissions: $permissions
    branchIds: $branchIds
    active: $active
    role: $role
    automaticRecognitionBudgetConfig: $automaticRecognitionBudgetConfig
  ) {
    id
  }
}
    `;
export type UpdateRewardsAdminInfoMutationFn = Apollo.MutationFunction<UpdateRewardsAdminInfoMutation, UpdateRewardsAdminInfoMutationVariables>;

/**
 * __useUpdateRewardsAdminInfoMutation__
 *
 * To run a mutation, you first call `useUpdateRewardsAdminInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRewardsAdminInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRewardsAdminInfoMutation, { data, loading, error }] = useUpdateRewardsAdminInfoMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      jobTitle: // value for 'jobTitle'
 *      permissions: // value for 'permissions'
 *      role: // value for 'role'
 *      branchIds: // value for 'branchIds'
 *      active: // value for 'active'
 *      automaticRecognitionBudgetConfig: // value for 'automaticRecognitionBudgetConfig'
 *   },
 * });
 */
export function useUpdateRewardsAdminInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRewardsAdminInfoMutation, UpdateRewardsAdminInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRewardsAdminInfoMutation, UpdateRewardsAdminInfoMutationVariables>(UpdateRewardsAdminInfoDocument, options);
      }
export type UpdateRewardsAdminInfoMutationHookResult = ReturnType<typeof useUpdateRewardsAdminInfoMutation>;
export type UpdateRewardsAdminInfoMutationResult = Apollo.MutationResult<UpdateRewardsAdminInfoMutation>;
export type UpdateRewardsAdminInfoMutationOptions = Apollo.BaseMutationOptions<UpdateRewardsAdminInfoMutation, UpdateRewardsAdminInfoMutationVariables>;