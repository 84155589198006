import { parseISO } from "date-fns";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useDebounce } from "use-debounce";

import { SearchTextField } from "@rewards-web/shared/components/search-text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";

import { RightDrawerBaseCard } from "../../../../../shared/components/right-drawer/right-drawer-base-card";
import {
  formatCandidateActionDate,
  getCompletedStepLabel,
  getMostRecentCandidateStep,
} from "../../candidate-list/lib";
import { MergeCandidateFormValues } from "../types";
import { useCandidateMergeSearchQuery } from "./candidate-merge-search.generated";

const SEARCH_DEBOUNCE_MS = 300;

interface MergeCandidateSearchCardProps {
  name: "intoCandidateId" | `fromCandidates.${number}.candidateId`;
  label: string;
  allSelectedCandidateIds: Set<string>;
  required: boolean;
}

export function MergeCandidateSearchCard({
  name,
  label,
  allSelectedCandidateIds,
  required,
}: MergeCandidateSearchCardProps): JSX.Element {
  const { control } = useFormContext<MergeCandidateFormValues>();

  const [candidateSearchText, setCandidateSearchText] = useState("");
  const [debouncedCandidateSearchText] = useDebounce(
    candidateSearchText,
    SEARCH_DEBOUNCE_MS
  );
  const candidateMergeSearchQuery = useCandidateMergeSearchQuery({
    onError: reportError,
    skip: !candidateSearchText,
    variables: {
      searchQuery: candidateSearchText,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
  });

  return (
    <RightDrawerBaseCard>
      <Controller
        control={control}
        name={name}
        rules={{
          required: required ? `${label} is required` : undefined,
        }}
        render={({ field, fieldState }) => {
          const selectedCandidate =
            field.value &&
            candidateMergeSearchQuery.data?.listCandidatesV2.items.find(
              (candidate) => candidate.id === field.value
            );

          return (
            <>
              <SearchTextField
                {...field}
                error={fieldState.error}
                label={label}
                options={(
                  (
                    candidateMergeSearchQuery.data ??
                    candidateMergeSearchQuery.previousData
                  )?.listCandidatesV2.items ?? []
                )
                  .filter(
                    (item) =>
                      item.id === field.value ||
                      !allSelectedCandidateIds.has(item.id)
                  )
                  .map((item) => ({
                    value: item.id,
                    label: `${item.firstName} ${
                      item.preferredName ? `(${item.preferredName}) ` : ""
                    }${item.lastName}`,
                  }))}
                loadingOptions={candidateMergeSearchQuery.loading}
                onInputChange={(text) => setCandidateSearchText(text)}
                debouncedInputValue={debouncedCandidateSearchText}
                closedWhenInputTextEmpty
                placeholder="Type to search..."
              />
              <Typography
                display="block"
                variant="footnote"
                color="textSecondary"
              >
                Applied on{" "}
                {selectedCandidate
                  ? formatCandidateActionDate(
                      parseISO(selectedCandidate.appliedOnDates[0]!)
                    )
                  : "–"}
              </Typography>
              <Typography
                display="block"
                variant="footnote"
                color="textSecondary"
              >
                Referred by{" "}
                {selectedCandidate
                  ? `${selectedCandidate.referredByUser.firstName} ${selectedCandidate.referredByUser.lastName}`
                  : "–"}
              </Typography>
              <Typography
                display="block"
                variant="footnote"
                color="textSecondary"
              >
                {selectedCandidate && selectedCandidate.jobsAppliedTo.length > 0
                  ? `${selectedCandidate.jobsAppliedTo[0]!.title} (${
                      selectedCandidate.jobsAppliedTo[0]!.geography
                    })`
                  : "Job –"}
              </Typography>
              <Typography
                display="block"
                variant="footnote"
                color="textSecondary"
              >
                Last step completed
                {selectedCandidate
                  ? `: ${getCompletedStepLabel(
                      getMostRecentCandidateStep(selectedCandidate)!
                    )}`
                  : " –"}
              </Typography>
            </>
          );
        }}
      />
    </RightDrawerBaseCard>
  );
}
