/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { RewardsOrganizationOnboardingStepName } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { AdminOnboardingCard } from "../../components/onboarding-card/onboarding-card";
import { useMarkOnboardingStepCompletedMutation } from "../../hooks/mark-step-completed.generated";
import { useNextOnboardingStepPath } from "../../hooks/use-next-step-path";
import { useOnboardingState } from "../../hooks/use-onboarding-state";

export function OnboardingIntroPage() {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const nextStepPath = useNextOnboardingStepPath();
  const { orderedSteps } = useOnboardingState();

  const [
    markStepCompleted,
    { loading },
  ] = useMarkOnboardingStepCompletedMutation({
    onError: (e) => {
      reportError(e);
      snackbar.show({
        message: "An unexpected error occurred. Please try again later.",
        severity: "error",
      });
    },
    onCompleted: () => {
      if (nextStepPath) {
        navigate(nextStepPath);
      }
    },
  });

  return (
    <AdminOnboardingCard
      css={(theme: AppTheme) => css`
        box-sizing: border-box;
        text-align: center;
        width: 100%;
        padding: ${theme.spacing(4)} ${theme.spacing(5)};
        ${theme.breakpoints.up("lg")} {
          padding: ${theme.spacing(10)} ${theme.spacing(12)};
        }
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <Typography variant="h2" component="h1">
        Welcome to Caribou!
      </Typography>
      <Typography
        css={(appTheme: AppTheme) =>
          css`
            margin-top: ${appTheme.spacing(0.75)};
            margin-bottom: ${appTheme.spacing(5)};
          `
        }
        variant="body"
        color="textSecondary"
      >
        It's time to empower and reward your caregivers
      </Typography>
      <Card
        backgroundColor="grey"
        css={(appTheme: AppTheme) =>
          css`
            padding: ${appTheme.spacing(3.75)};
            display: flex;
            flex-direction: column;
            align-items: start;
          `
        }
        variant="flat"
      >
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            align-items: center;
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          <Typography
            css={css`
              font-weight: 600;
            `}
            variant="h3"
          >
            🚀 Start onboarding now{" "}
          </Typography>
        </div>
        <Typography
          color="text.primary"
          variant="body"
          css={(theme: AppTheme) => css`
            text-align: left;
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          We are excited to get you started! Block off time in your calendar to
          complete your agency onboarding in{" "}
          {
            // display 1 less step than the total,
            // because 'intro' doesn't really count as a step
            orderedSteps.length - 1
          }{" "}
          simple steps.
        </Typography>

        <Button
          css={css`
            margin: 0 auto;
          `}
          color="primary"
          width="auto"
          label="✨ Let's Start!"
          onClick={() => {
            markStepCompleted({
              variables: { step: RewardsOrganizationOnboardingStepName.Intro },
            });
          }}
          loading={loading}
        />
      </Card>
    </AdminOnboardingCard>
  );
}
