import { SVGProps } from "react";

export function LinkIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.5">
        <path
          d="M4.85636 3.23787C4.99764 3.23787 5.11286 3.13091 5.11286 2.99978V1.30948C5.11286 0.8454 5.51065 0.476175 6.01062 0.476175H8.74252C8.8838 0.476175 8.99902 0.369221 8.99902 0.238087C8.99902 0.106954 8.88379 0 8.74252 0H6.01062C5.22808 0 4.59985 0.583123 4.59985 1.30948V2.98823C4.59985 3.13099 4.71558 3.23795 4.85636 3.23795V3.23787Z"
          fill={props.fill || "#2C393F"}
        />
        <path
          d="M8.74252 9.52341H6.01062C5.51065 9.52341 5.11286 9.15419 5.11286 8.69011V6.99981C5.11286 6.86867 4.99763 6.76172 4.85636 6.76172C4.71507 6.76172 4.59985 6.86867 4.59985 6.99981V8.69011C4.59985 9.41646 5.22808 9.99959 6.01062 9.99959H8.74252C8.8838 9.99959 8.99902 9.89263 8.99902 9.7615C8.99902 9.63037 8.88379 9.52341 8.74252 9.52341Z"
          fill={props.fill || "#2C393F"}
        />
        <path
          d="M15.1165 6.76172C14.9752 6.76172 14.86 6.86867 14.86 6.99981V8.69011C14.86 9.15419 14.4622 9.52341 13.9622 9.52341H11.2309C11.0896 9.52341 10.9744 9.63037 10.9744 9.7615C10.9744 9.89263 11.0896 9.99959 11.2309 9.99959H13.9628C14.7453 9.99959 15.3735 9.41646 15.3735 8.69011L15.373 6.99981C15.373 6.86867 15.2578 6.76172 15.1165 6.76172H15.1165Z"
          fill={props.fill || "#2C393F"}
        />
        <path
          d="M11.2311 0.476175H13.963C14.463 0.476175 14.8608 0.8454 14.8608 1.30948V2.98823C14.8608 3.11937 14.976 3.22632 15.1173 3.22632C15.2585 3.22632 15.3738 3.11937 15.3738 2.98823L15.3733 1.30948C15.3733 0.583123 14.745 0 13.9625 0H11.2311C11.0898 0 10.9746 0.106954 10.9746 0.238087C10.9746 0.369221 11.0898 0.476175 11.2311 0.476175H11.2311Z"
          fill={props.fill || "#2C393F"}
        />
        <path
          d="M8.73987 5.07095C8.73987 5.05886 8.73988 5.05886 8.7529 5.04723C8.7529 5.03514 8.7529 5.03514 8.76593 5.02352C8.77895 4.98771 8.77895 4.96399 8.76593 4.92819C8.76593 4.9161 8.76593 4.9161 8.7529 4.90447C8.7529 4.89238 8.7529 4.89238 8.73987 4.88076C8.73987 4.86867 8.72685 4.86867 8.72685 4.85704C8.72685 4.85704 8.72685 4.84495 8.71383 4.84495C8.7008 4.83286 8.68827 4.82123 8.67525 4.80914L6.90576 3.16622C6.80306 3.07089 6.64925 3.07089 6.54655 3.16622C6.44385 3.26155 6.44385 3.40431 6.54655 3.49964L7.88011 4.73746H0.255771C0.114489 4.73746 -0.000732422 4.84441 -0.000732422 4.97554C-0.000732422 5.10668 0.114494 5.21363 0.255771 5.21363H7.88011L6.54655 6.46359C6.44385 6.55892 6.44385 6.70168 6.54655 6.79701C6.59765 6.84444 6.66178 6.86862 6.7259 6.86862C6.79002 6.86862 6.85415 6.8449 6.90524 6.79701L8.67525 5.15408C8.68827 5.14199 8.7008 5.13037 8.71383 5.11828L8.72685 5.10619C8.72685 5.09456 8.72685 5.08257 8.73987 5.07095Z"
          fill={props.fill || "#2C393F"}
        />
        <path
          d="M19.7433 4.74918H12.2467L13.5802 3.51136C13.6829 3.41603 13.6829 3.27327 13.5802 3.17794C13.4775 3.08261 13.3237 3.08261 13.221 3.17794L11.4515 4.82086C11.4385 4.83295 11.426 4.84458 11.4129 4.85667L11.3999 4.86876C11.3999 4.88085 11.3869 4.88085 11.3869 4.89248C11.3869 4.90457 11.3869 4.90457 11.3739 4.91619C11.3739 4.92828 11.3739 4.92828 11.3608 4.93991C11.3478 4.97571 11.3478 4.99943 11.3608 5.03524C11.3608 5.04733 11.3608 5.04732 11.3739 5.05895C11.3739 5.07104 11.3739 5.07104 11.3869 5.08267C11.3869 5.09476 11.3999 5.09476 11.3999 5.10638C11.3999 5.10638 11.3999 5.11847 11.4129 5.11847C11.426 5.13056 11.4385 5.14219 11.4515 5.15428L13.2215 6.7972C13.2726 6.84463 13.3367 6.86881 13.4009 6.86881C13.465 6.86881 13.5291 6.8451 13.5802 6.7972C13.6829 6.70187 13.6829 6.55911 13.5802 6.46378L12.2467 5.22549H19.7427C19.884 5.22549 19.9992 5.11854 19.9992 4.9874C19.9997 4.85673 19.884 4.74918 19.7433 4.74918Z"
          fill={props.fill || "#2C393F"}
        />
      </g>
    </svg>
  );
}
