/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Checkbox } from "@material-ui/core";
import {
  FieldValues,
  Control,
  Path,
  Controller,
  useWatch,
} from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { getCharactersRemainingText } from "@rewards-web/shared/lib/characters-remaining-text";
import { AppTheme, headerFontFamily } from "@rewards-web/shared/style/theme";

const MAX_JOB_CUSTOM_SMS_LENGTH = 300;

interface JobCustomSMSFieldProps<T extends FieldValues> {
  name: Path<T>;
  checkBoxName: Path<T>;
  control: Control<T>;
}

export function JobCustomSMSField<T extends FieldValues>({
  name,
  control,
  checkBoxName,
}: JobCustomSMSFieldProps<T>): JSX.Element {
  const open = useWatch({ control, name: checkBoxName });

  return (
    <div>
      <div
        css={css`
          display: inline-flex;
          align-items: center;
        `}
      >
        <Checkbox
          data-testid="sms-checkbox"
          color="primary"
          checked={open}
          {...control.register(checkBoxName)}
        />

        <div
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(2)};
          `}
        >
          <Typography variant="body" color="textPrimary">
            Add custom SMS response
          </Typography>
          <Typography
            css={css`
              font-style: italic;
            `}
            variant="footnote"
            color="textSecondary"
          >
            Create a custom SMS message that is sent to applicants
          </Typography>
        </div>
      </div>

      {open && (
        <div
          css={(theme: AppTheme) => css`
            padding-left: ${theme.spacing(1.5)};
          `}
        >
          <Controller
            control={control}
            name={name}
            rules={{
              required: "Custom SMS is required if checked",
              validate: (value) => {
                if (value && value.length > MAX_JOB_CUSTOM_SMS_LENGTH) {
                  return getCharactersRemainingText(
                    value,
                    MAX_JOB_CUSTOM_SMS_LENGTH
                  );
                }
              },
            }}
            render={({ field, fieldState }) => (
              <div
                css={css`
                  position: relative;
                `}
              >
                <div
                  css={(theme: AppTheme) => css`
                    position: absolute;
                    margin-top: ${theme.spacing(1.5)};
                    margin-left: 14px;
                    z-index: 1;
                  `}
                >
                  <Typography
                    variant="body"
                    color="textSecondary"
                    css={css`
                      font-family: ${headerFontFamily};
                    `}
                  >
                    {"Hi <Applicant name>"}
                  </Typography>
                </div>
                <TextField
                  {...field}
                  label="Custom SMS message"
                  topPadding={20}
                  hideLabel
                  type="textarea"
                  minRows={6}
                  error={fieldState.error}
                  disableAutocomplete
                />
              </div>
            )}
          />
        </div>
      )}
    </div>
  );
}
