import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type OrganizationSendersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrganizationSendersQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'getAllRecognitionSendersForOrganization'>
);


export const OrganizationSendersDocument = gql`
    query OrganizationSenders {
  getAllRecognitionSendersForOrganization
}
    `;

/**
 * __useOrganizationSendersQuery__
 *
 * To run a query within a React component, call `useOrganizationSendersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSendersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSendersQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationSendersQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationSendersQuery, OrganizationSendersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationSendersQuery, OrganizationSendersQueryVariables>(OrganizationSendersDocument, options);
      }
export function useOrganizationSendersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationSendersQuery, OrganizationSendersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationSendersQuery, OrganizationSendersQueryVariables>(OrganizationSendersDocument, options);
        }
export type OrganizationSendersQueryHookResult = ReturnType<typeof useOrganizationSendersQuery>;
export type OrganizationSendersLazyQueryHookResult = ReturnType<typeof useOrganizationSendersLazyQuery>;
export type OrganizationSendersQueryResult = Apollo.QueryResult<OrganizationSendersQuery, OrganizationSendersQueryVariables>;