import { faUsers } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ActiveUsersInsightMetric as ActiveUsersInsightMetricType } from "@rewards-web/shared/graphql-types";

import { InsightMetric } from "../../../../components/insight-metric";
import { getPercentDifference } from "../../../../utils";

export interface ActiveUsersInsightMetricProps {
  currentActiveUsersInsightMetric: ActiveUsersInsightMetricType | undefined;
  previousActiveUsersInsightMetric: ActiveUsersInsightMetricType | undefined;
}

export function ActiveUsersInsightMetric({
  currentActiveUsersInsightMetric,
  previousActiveUsersInsightMetric,
}: ActiveUsersInsightMetricProps) {
  const currentActiveEmployees =
    currentActiveUsersInsightMetric?.activeUserCount;
  const previousActiveEmployees =
    previousActiveUsersInsightMetric?.activeUserCount;

  const percentPointChange = (() => {
    if (
      currentActiveEmployees === undefined ||
      previousActiveEmployees === undefined
    ) {
      return null;
    }
    return getPercentDifference(
      previousActiveEmployees,
      currentActiveEmployees
    );
  })();

  return (
    <InsightMetric
      label="Active employees"
      icon={<FontAwesomeIcon icon={faUsers} />}
      tooltipText="Number of employees currently enrolled in the Caribou program."
      value={
        currentActiveEmployees !== undefined
          ? { type: "number", number: currentActiveEmployees }
          : {
              type: "loading",
            }
      }
      percentPointChange={percentPointChange}
    />
  );
}
