/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import ReactDomConfetti from "react-dom-confetti";

export interface ConfettiProps {
  active: boolean;
}

export const DEFAULT_CONFETTI_DURATION_MS = 4000;

export function Confetti({ active }: ConfettiProps) {
  const theme = useTheme();

  return (
    <div
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1500;
        pointer-events: none;
      `}
    >
      <ReactDomConfetti
        config={{
          elementCount: 80,
          duration: DEFAULT_CONFETTI_DURATION_MS,
          colors: [
            theme.palette.primary.main,
            theme.palette.tertiary.main,
            theme.palette.secondary.main,
            theme.palette.secondary.light,
            theme.palette.gold.light,
          ],
        }}
        active={active}
      />
    </div>
  );
}
