import { PageLayout } from "../../../../../../shared/components/page-layout";
import { AuthorizedAdminPageWrapper } from "../../../../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { EditReferralStructurePageContents } from "./page-content";

const MAX_CONTENT_WIDTH = 1400;

export function EditReferralStructurePage(): JSX.Element {
  return (
    <AuthorizedAdminPageWrapper resource="settings">
      <PageLayout maxContentWidth={MAX_CONTENT_WIDTH}>
        <EditReferralStructurePageContents />
      </PageLayout>
    </AuthorizedAdminPageWrapper>
  );
}
