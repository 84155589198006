import { ReactNode, FormEventHandler, forwardRef } from "react";

import { StylableProps } from "../../types";
import { FormControlContext } from "./form-control";

interface FormProps extends StylableProps {
  onSubmit: FormEventHandler<HTMLFormElement>;
  submitting?: boolean;
  readOnly?: boolean;
  children: ReactNode;
}

export const Form = forwardRef<HTMLFormElement, FormProps>(
  (
    {
      onSubmit,
      children,
      submitting = false,
      readOnly = false,
      className,
    }: FormProps,
    ref
  ): JSX.Element => {
    return (
      <form ref={ref} onSubmit={onSubmit} className={className}>
        <FormControlContext.Provider
          value={{
            submitting,
            readOnly,
          }}
        >
          {children}
        </FormControlContext.Provider>
      </form>
    );
  }
);
