import { isUndefined } from "lodash";

import {
  SurveyInsightsDateRangeInput,
  SurveyQuestionPreviousResultsResponse,
} from "@rewards-web/shared/graphql-types";

import { shortenDateRangeLabel } from "../utils";

/**
 * Given the array of previous scores from the API (ordered from newest to oldest),
 * will convert to the format needed for comparison, ordered from oldest to newest.
 */
export const getPreviousScoresForComparison = (
  previousAverageScores: SurveyQuestionPreviousResultsResponse[]
) => {
  if (!previousAverageScores.length) {
    return [];
  }

  return previousAverageScores
    .map((result) => {
      return {
        averageScore: result.averageScore,
        label: shortenDateRangeLabel(result.dateRange.label),
        year: result.dateRange.year.toString(),
      };
    })
    .slice(0, 4) // only show the previous 4 scores (plus the current)
    .reverse(); // order from oldest to newest
};

/**
 * Given the average score and date range input, will convert to the
 * format needed for comparison to previous scores.
 * Will return null if not all data is available, ie. while queries are loading.
 */
export const getCurrentScoreForComparison = (
  averageScore?: number,
  dateRange?: SurveyInsightsDateRangeInput
) => {
  if (isUndefined(averageScore) || !dateRange) {
    return null;
  }

  return {
    averageScore: Math.round(averageScore * 10) / 10, // round to 1 decimal place
    label: shortenDateRangeLabel(dateRange.label),
    year: dateRange.year.toString(),
  };
};
