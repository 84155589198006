import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
export type CandidateListDetailsFragment = (
  { __typename?: 'Candidate' }
  & Pick<Types.Candidate, 'id' | 'archived' | 'archivedAt' | 'archiveReason' | 'firstName' | 'lastName' | 'preferredName' | 'appliedOnDates' | 'hasUploadedResume'>
  & { completedStepsV2: Array<(
    { __typename?: 'RecordedCandidateRecruitmentStep' }
    & Pick<Types.RecordedCandidateRecruitmentStep, 'stepName' | 'recordedAt'>
  ) | (
    { __typename?: 'RecordedCandidateMonthlyRetentionStep' }
    & Pick<Types.RecordedCandidateMonthlyRetentionStep, 'durationMonths' | 'recordedAt'>
  ) | (
    { __typename?: 'RecordedCandidateHourlyRetentionStep' }
    & Pick<Types.RecordedCandidateHourlyRetentionStep, 'durationHours' | 'recordedAt'>
  )>, jobsAppliedTo: Array<(
    { __typename?: 'JobPosting' }
    & Pick<Types.JobPosting, 'id' | 'title' | 'geography'>
  )>, assignedToJobPosting?: Types.Maybe<(
    { __typename?: 'JobPosting' }
    & Pick<Types.JobPosting, 'id' | 'title' | 'geography'>
  )>, referredByUser: (
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'firstName' | 'lastName'>
  ), assignedToAdmin?: Types.Maybe<(
    { __typename?: 'RewardsAdminUser' }
    & Pick<Types.RewardsAdminUser, 'id' | 'firstName' | 'lastName'>
  )> }
);

export const CandidateListDetailsFragmentDoc = gql`
    fragment CandidateListDetails on Candidate {
  id
  archived
  archivedAt
  archiveReason
  completedStepsV2 {
    ... on RecordedCandidateRecruitmentStep {
      stepName
      recordedAt
    }
    ... on RecordedCandidateMonthlyRetentionStep {
      durationMonths
      recordedAt
    }
    ... on RecordedCandidateHourlyRetentionStep {
      durationHours
      recordedAt
    }
  }
  firstName
  lastName
  preferredName
  jobsAppliedTo {
    id
    title
    geography
  }
  assignedToJobPosting {
    id
    title
    geography
  }
  appliedOnDates
  referredByUser {
    id
    firstName
    lastName
  }
  hasUploadedResume
  assignedToAdmin {
    id
    firstName
    lastName
  }
}
    `;