/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export interface LegendProps {
  items: LegendItem[];
  variant?: "vertical" | "horizontal";
}

export interface LegendItem {
  id: string;
  color: string;
  label: JSX.Element;
  shape?: "square" | "circle";
  pattern?: "none" | "lines";
}

export function Legend(props: LegendProps): JSX.Element {
  return (
    <div
      css={() => {
        if (props.variant === "horizontal") {
          return css`
            display: flex;
            justify-content: space-between;
            margin-left: -8px; // to offset the margin-left of the first item tile shape
          `;
        }
      }}
    >
      {props.items.map((item) => (
        <div
          key={item.id}
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={{
              margin: "0 8px 0 8px",
              width: "12px",
              height: "12px",
              borderRadius: item.shape === "circle" ? "50%" : "2px",
              background:
                item.pattern === "lines"
                  ? `repeating-linear-gradient(-45deg, #fff, #fff 2px, ${item.color} 2px, ${item.color} 4px)`
                  : item.color,
            }}
          />
          {item.label}
        </div>
      ))}
    </div>
  );
}
