/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import {
  faCalendar,
  faChevronLeft,
  faCircleExclamation,
  faDollar,
  faGift,
  faLoader,
  faTrophy,
  faUsers,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faBellExclamation,
  faCheckCircle,
  faCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatInTimeZone } from "date-fns-tz";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  DrawStatusOnSchedule,
  DrawWinnerClaimedPrize,
} from "@rewards-web/shared/graphql-types";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import {
  DrawSection,
  getDrawSectionTitleAndColor,
  getPrizeTierName,
  numberToWords,
} from "../lib";
import { useDrawDetailsQuery } from "./draw-details.generated";
import starUrl from "./star.png";
import { WinnersTable } from "./winners-table";

export function DrawDetailsPageContents() {
  const params = useParams();
  const theme = useTheme();
  const drawId = params.id!;
  const navigate = useNavigate();
  const query = useDrawDetailsQuery({
    onError: reportError,
    variables: { drawId },
    fetchPolicy: "no-cache",
  });

  if (query.error) {
    return (
      <Alert
        message="An unexpected error occurred. Please try again later."
        severity="error"
      />
    );
  }

  if (!query.data) {
    return <PageLoadingState />;
  }

  const {
    draw,
    organization: { timezone, pointsPerDollar, whiteLabelConfig },
  } = query.data;

  const rewardsProgramShortName =
    whiteLabelConfig?.rewardsProgramShortName ?? "Caribou Rewards";

  if (!draw) {
    return (
      <Alert
        message={
          <>
            This draw no longer exists. <Link to="/draw">Click here</Link> to go
            back to Draws.
          </>
        }
        severity="warning"
      />
    );
  }

  const {
    name,
    scheduleStatus,
    pendingWinnerApproval,
    scheduledStartDate,
    scheduledEndDate,
    prizeStructure,
    winners,
  } = draw;

  const drawStatus = ((): DrawSection => {
    switch (scheduleStatus) {
      case DrawStatusOnSchedule.Live:
        return "live";
      case DrawStatusOnSchedule.Scheduled:
        return "scheduled";
      case DrawStatusOnSchedule.Completed:
        if (pendingWinnerApproval) {
          return "awaitingWinnerApproval";
        }
        return "completed";
      default:
        // can't display other draw statuses here
        return "unknown";
    }
  })();

  const prizeType = ((): string => {
    switch (prizeStructure.__typename) {
      case "DrawPrizeStructureSinglePrizeMultipleWinners": {
        switch (prizeStructure.prize.__typename) {
          case "DrawPrizePoints":
            return "Points";
          default:
            // can't display other prize structure types here
            return "Non-points";
        }
      }
      case "DrawPrizeStructureTieredPrizesMultipleWinners": {
        switch (prizeStructure.prizes[0].__typename) {
          case "DrawTieredPrizePoints":
            return "Points";
          default:
            return "Non-points";
        }
      }

      default:
        // can't display other prize structure types here
        return "Non-points";
    }
  })();

  const pointsPrizePerWinner = ((): number | undefined => {
    switch (prizeStructure.__typename) {
      case "DrawPrizeStructureSinglePrizeMultipleWinners": {
        switch (prizeStructure.prize.__typename) {
          case "DrawPrizePoints":
            return prizeStructure.prize.pointValue;
          default:
            // can't display other prize structure types here
            return undefined;
        }
      }
      case "DrawPrizeStructureTieredPrizesMultipleWinners":
      default:
        // can't display other prize structure types here
        return undefined;
    }
  })();

  const { dotColor, title: statusTitle } = getDrawSectionTitleAndColor(
    drawStatus,
    theme
  );

  function getDrawWinnersContent() {
    if (scheduleStatus !== DrawStatusOnSchedule.Completed) {
      return "N/A";
    }
    if (pendingWinnerApproval) {
      return (
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <FontAwesomeIcon
            icon={faCircleExclamation}
            color="red"
            size="sm"
            css={css`
              margin-right: 6px;
            `}
          />
          <Typography variant="body" color="red">
            Waiting for approval from agency
          </Typography>
        </div>
      );
    }
    if (winners && winners.length > 0) {
      return winners.map((winner, idx) => (
        <div
          key={idx}
          css={css`
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            &:last-child {
              margin-bottom: 0;
            }
          `}
        >
          {winner.claimedPrize === DrawWinnerClaimedPrize.Yes ? (
            <Tooltip title="Employee has claimed points">
              <FontAwesomeIcon
                icon={faCheckCircle}
                color={theme.palette.success.main}
                css={css`
                  margin-right: 10px;
                  width: 18px;
                  height: 18px;
                  vertical-align: middle;
                `}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Employee hasn't claimed points">
              <FontAwesomeIcon
                icon={faBellExclamation}
                color={theme.palette.gold.light}
                css={css`
                  margin-right: 10px;
                  width: 18px;
                  height: 18px;
                  vertical-align: middle;
                `}
              />
            </Tooltip>
          )}
          <Typography variant="body" color="textPrimary">
            {winner.user.firstName} {winner.user.lastName}
          </Typography>
          <FontAwesomeIcon
            icon={faCircle}
            color="textPrimary"
            width={5}
            css={css`
              margin: 0 10px;
            `}
          />
          <Typography variant="body" color="textPrimary">
            {(() => {
              if (
                winner.user.personalContactInfo?.__typename ===
                "RewardsUserPersonalContactInfoSuppressed"
              ) {
                return "(Suppressed)";
              }

              return (
                winner.user.personalContactInfo?.email ??
                winner.user.workEmail ??
                "-"
              );
            })()}
          </Typography>
        </div>
      ));
    }
    if (scheduledEndDate < new Date("2024-01-31")) {
      // Before automated selection of draw winners went live
      return (
        <div
          css={css`
            text-align: right;
          `}
        >
          <Typography variant="body" color="textPrimary">
            Winners selected and announced outside of {rewardsProgramShortName}.
          </Typography>
          <Typography variant="body" color="textPrimary">
            For additional information, contact{" "}
            <a href="mailto:help@caribou.care">help@caribou.care</a>.
          </Typography>
        </div>
      );
    }
    // Draw winners are being automatically selected by the system
    // and will soon be pending approval.
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <FontAwesomeIcon
          icon={faLoader}
          size="sm"
          css={css`
            margin-right: 6px;
          `}
        />
        <Typography variant="body" color="textPrimary">
          Winners pending
        </Typography>
      </div>
    );
  }

  function handlePendingWinner() {
    if (scheduleStatus !== DrawStatusOnSchedule.Completed) {
      return "N/A";
    }
    if (pendingWinnerApproval) {
      return (
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <FontAwesomeIcon
            icon={faCircleExclamation}
            color="red"
            size="sm"
            css={css`
              margin-right: 6px;
            `}
          />
          <Typography variant="body" color="red">
            Waiting for approval from agency
          </Typography>
        </div>
      );
    }

    if (scheduledEndDate < new Date("2024-01-31")) {
      // Before automated selection of draw winners went live
      return (
        <div
          css={css`
            text-align: right;
          `}
        >
          <Typography variant="body" color="textPrimary">
            Winners selected and announced outside of {rewardsProgramShortName}.
          </Typography>
          <Typography variant="body" color="textPrimary">
            For additional information, contact{" "}
            <a href="mailto:help@caribou.care">help@caribou.care</a>.
          </Typography>
        </div>
      );
    }
    // Draw winners are being automatically selected by the system
    // and will soon be pending approval.
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <FontAwesomeIcon
          icon={faLoader}
          size="sm"
          css={css`
            margin-right: 6px;
          `}
        />
        <Typography variant="body" color="textPrimary">
          Winners pending
        </Typography>
      </div>
    );
  }
  const sections = [
    {
      icon: faTrophy,
      title: "Draw name",
      content: name ?? "-",
    },
    {
      icon: faCalendar,
      title: "Start date",
      content: formatInTimeZone(scheduledStartDate, timezone, "PPPp zzz"),
    },
    {
      icon: faCalendar,
      title: "End date",
      content: formatInTimeZone(scheduledEndDate, timezone, "PPPp zzz"),
    },
    {
      icon: faGift,
      title: "Prize type",
      content: prizeType,
    },
    {
      icon: faUsers,
      title: "Number of winners",
      content:
        prizeStructure.__typename ===
        "DrawPrizeStructureSinglePrizeMultipleWinners"
          ? `${prizeStructure.numWinners}`
          : prizeStructure.prizes.map((prize, index) => {
              if (prize.__typename === "DrawTieredPrizePoints") {
                return (
                  <div
                    key={index}
                    css={css`
                      display: flex;
                      align-items: center;
                      margin-bottom: 10px;
                      &:last-child {
                        margin-bottom: 0;
                      }
                    `}
                  >
                    <Typography variant="body" color="textPrimary">
                      <strong>{prize.numWinners} </strong>
                      {getPrizeTierName(index)}
                    </Typography>
                  </div>
                );
              } else {
                reportError(
                  new Error(`Unexpected prize type ${prize.__typename}`)
                );
                return undefined;
              }
            }),
    },

    ...(prizeStructure.__typename ===
    "DrawPrizeStructureSinglePrizeMultipleWinners"
      ? [
          {
            icon: faDollar,
            title: "Prize value",
            content: pointsPrizePerWinner ? (
              <div
                css={css`
                  display: flex;
                  align-content: center;
                `}
              >
                <img
                  src={starUrl}
                  alt="Star"
                  css={css`
                    margin-right: 6px;
                  `}
                />
                <Typography variant="body" color="textPrimary">
                  {numberWithCommas(pointsPrizePerWinner)} points (
                  {formatDollars(pointsPrizePerWinner / pointsPerDollar)}) per
                  winner
                </Typography>
              </div>
            ) : (
              "N/A"
            ),
          },
        ]
      : []),
    ...(prizeStructure.__typename ===
    "DrawPrizeStructureSinglePrizeMultipleWinners"
      ? [
          {
            icon: faUsers,
            title: "Winners",
            content: getDrawWinnersContent(),
          },
        ]
      : []),
  ];

  if (
    prizeStructure &&
    prizeStructure.__typename ===
      "DrawPrizeStructureTieredPrizesMultipleWinners" &&
    !(winners && winners.length > 0)
  ) {
    sections.push({
      icon: faUsers,
      title: "Winners",
      content: handlePendingWinner(),
    });
  }
  const numBonusPrizeWinners = winners?.filter(
    (winner) => winner.prizeTierIndex === 2
  ).length;

  return (
    <div>
      <Button
        variant="text"
        startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        label="Back"
        width="auto"
        typographyVariant="body"
        onClick={() => {
          navigate(-1);
        }}
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      />
      <div
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(3)};
          display: flex;
        `}
      >
        <Typography
          variant="h1"
          color="textPrimary"
          fontWeight={700}
          css={css`
            flex: 1;
          `}
        >
          {name ?? "Draw Details"}
        </Typography>
        <div
          css={css`
            display: flex;
            align-items: center;
            align-self: center;
          `}
        >
          <div
            css={(theme: AppTheme) => css`
              content: "";
              background-color: ${dotColor};
              width: 13px;
              height: 13px;
              border-radius: 1000px;
              margin-right: ${theme.spacing(1)};
            `}
          />
          <Typography variant="subtitle" color="textPrimary" fontWeight={600}>
            {statusTitle}
          </Typography>
        </div>
      </div>

      <Card
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(4)};
          margin-bottom: ${theme.spacing(3)};
        `}
      >
        {sections.map(({ icon, title: sectionTitle, content }, idx) => (
          <div
            key={idx}
            css={css`
              display: flex;
              align-items: center;
              margin-bottom: 30px;
              &:last-child {
                margin-bottom: 0;
              }
            `}
          >
            <div
              css={(theme: AppTheme) => css`
                flex: 1;
                align-self: flex-start;
                display: flex;
                align-items: center;
                margin-right: ${theme.spacing(2)};
              `}
            >
              <FontAwesomeIcon
                icon={icon}
                size="lg"
                css={css`
                  margin-right: 6px;
                `}
              />
              <Typography
                variant="subtitle"
                color="textPrimary"
                fontWeight={600}
              >
                {sectionTitle}
              </Typography>
            </div>
            <div>
              {typeof content === "string" ? (
                <Typography variant="body" color="textPrimary">
                  {content}
                </Typography>
              ) : (
                content
              )}
            </div>
          </div>
        ))}
        {prizeStructure.__typename ===
          "DrawPrizeStructureTieredPrizesMultipleWinners" &&
          winners &&
          winners.length > 0 && (
            <>
              <WinnersTable
                winners={winners}
                prizes={prizeStructure.prizes.filter(
                  (
                    prize
                  ): prize is {
                    __typename: "DrawTieredPrizePoints";
                    pointValue: number;
                    numWinners: number;
                  } => prize.__typename === "DrawTieredPrizePoints"
                )}
              />
              {numBonusPrizeWinners !== undefined && numBonusPrizeWinners > 0 && (
                <Typography
                  variant="body"
                  color="textPrimary"
                  css={css`
                    text-align: center;
                  `}
                >
                  <div
                    css={(theme: AppTheme) => css`
                      padding: ${theme.spacing(1)};
                      width: 100%;
                      border-radius: 10px;
                      background-color: #f8f0ea;
                    `}
                  >
                    <strong>({numBonusPrizeWinners})</strong>{" "}
                    {numberToWords(numBonusPrizeWinners)}{" "}
                    {numBonusPrizeWinners === 1 ? "person" : "people"} won the
                    🥉 Third prize
                  </div>{" "}
                </Typography>
              )}
            </>
          )}
      </Card>
    </div>
  );
}
