import * as React from "react";

function CrossIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.36 7.682l5.136 5.136 1.322-1.322L7.682 6.36l5.087-5.087L11.496 0 6.409 5.087 1.322 0 0 1.322 5.087 6.41 0 11.496l1.273 1.272L6.36 7.682z"
        fill="#7F8E9F"
      />
    </svg>
  );
}

export default CrossIcon;
