import { faMoneyBill1 } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typography } from "@rewards-web/shared/components/typography";
import { TotalHiresInsightMetric as TotalHiresInsightMetricType } from "@rewards-web/shared/graphql-types";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";

import { InsightMetric } from "../../../../components/insight-metric";

export interface AllTimeProjectedValueInsightMetricProps {
  totalHiresInsightMetric: TotalHiresInsightMetricType | undefined;
  pointsPerDollar: number;
}

export function AllTimeProjectedValueInsightMetric({
  totalHiresInsightMetric,
  pointsPerDollar,
}: AllTimeProjectedValueInsightMetricProps) {
  const totalHires = totalHiresInsightMetric?.hiredCandidateCount;

  /**
   * This is an industry standard multiplier of $X per hire used to calculate projected value
   */
  const moneyMultiplier = 6000;
  const pointMultiplier = moneyMultiplier * pointsPerDollar;

  return (
    <InsightMetric
      label="Projected value"
      icon={<FontAwesomeIcon icon={faMoneyBill1} />}
      tooltipText={`Projected value is calculated as ${formatDollars(
        moneyMultiplier
      )} USD per hire, based on industry reports and agency testimonials.`}
      value={(() => {
        if (totalHires === undefined) {
          return {
            type: "loading",
          };
        }
        if (totalHires === 0) {
          return {
            type: "empty",
            content: (
              <Typography color="grey.800" fontWeight={700}>
                Pending
              </Typography>
            ),
          };
        }
        return {
          type: "dollar",
          points: totalHires * pointMultiplier,
          pointsPerDollar,
        };
      })()}
      percentPointChange={null}
    />
  );
}
