/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import {
  useTrack,
  useTrackScreenRecordingEvent,
} from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { BranchFilter } from "../../../../shared/components/branch-filter";
import { CategoryFilter } from "../../../../shared/components/category-filter";
import { ClaimFilter } from "../../../../shared/components/claim-filter";
import { FromFilter } from "../../../../shared/components/from-filter";
import { UserFilter } from "../../../../shared/components/user-filter";
import { useMyBranches } from "../../../../shared/modules/branches/use-my-branches";
import { RecognitionPointsDataTable } from "./recognition-points-data-table";
import { useRecognitionListQuery } from "./recognition-points-list.generated";

const ITEMS_PER_PAGE = 10;

export function AwardedRecognitionPointsPage() {
  const [recognitionPointsId, setRecognitionPointsId] = useState<
    string | undefined
  >();
  const [selectedBranchIds, setSelectedBranchIds] = useState<(string | null)[]>(
    []
  );
  const [
    selectedRecognitionCategoryIds,
    setSelectedRecognitionCategoryIds,
  ] = useState<(string | null)[]>([]);

  const [selectedFromIds, setSelectedFromIds] = useState<string[]>([]);
  const [selectedClaimStatus, setSelectedClaimStatus] = useState<string[]>([]);
  const [selectedUserFilterId, setSelectedUserFilterId] = useState<
    string | null
  >(null);

  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const getClaimedValue = (): boolean | null => {
    if (selectedClaimStatus && selectedClaimStatus.length === 1) {
      if (selectedClaimStatus[0] === "CLAIMED") {
        return true;
      } else {
        return false;
      }
    }
    return null;
  };

  const myOrganizationBranchesQuery = useMyBranches();
  const track = useTrack();
  const trackScreenRecordingEvent = useTrackScreenRecordingEvent();

  const recognitionPointsListQuery = useRecognitionListQuery({
    variables: {
      offset: currentPageIndex * ITEMS_PER_PAGE,
      limit: ITEMS_PER_PAGE,
      filter: {
        branchIds: selectedBranchIds.length > 0 ? selectedBranchIds : undefined,
        employeeId: selectedUserFilterId,
        recognitionCategoryIds:
          selectedRecognitionCategoryIds.length > 0
            ? selectedRecognitionCategoryIds
            : undefined,
        froms:
          selectedFromIds && selectedFromIds.length > 0
            ? selectedFromIds
            : undefined,
        claimed: getClaimedValue(),
      },
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only", // prevent from reloading query automatically after a mutation
    onError: reportError,
  });

  if (recognitionPointsListQuery.error || myOrganizationBranchesQuery.error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  const loading = recognitionPointsListQuery.loading;
  const total =
    recognitionPointsListQuery.data?.listSentRecognitionPoints?.total;
  const items =
    recognitionPointsListQuery.data?.listSentRecognitionPoints?.items ?? [];
  const organizationHasBranches =
    (myOrganizationBranchesQuery.data?.getMyRewardsOrganization.branches ?? [])
      .length > 0;

  return (
    <>
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            align-items: center;
            & > *:not(:first-of-type) {
              margin-left: ${theme.spacing(2)};
            }
            max-width: 100%;
            margin-right: ${theme.spacing(2)};
          `}
        >
          {organizationHasBranches && (
            <BranchFilter
              value={selectedBranchIds ?? [null]}
              onChange={setSelectedBranchIds}
              width={360}
              resourceName="Recognition Points"
              onOpen={() => {
                trackScreenRecordingEvent("bonus_points_branch_filter_opened");
                track("Opened bonus points branch filter");
              }}
            />
          )}

          <UserFilter
            value={selectedUserFilterId}
            onChange={(nextId) => {
              setSelectedUserFilterId(nextId);
              trackScreenRecordingEvent("bonus_points_employee_filter_changed");
              track("Updated bonus points employee filter");
            }}
            css={(theme: AppTheme) => css`
              margin-top: ${theme.spacing(1)};
            `}
          />
          <CategoryFilter
            value={selectedRecognitionCategoryIds ?? [null]}
            onChange={setSelectedRecognitionCategoryIds}
            width={150}
            onOpen={() => {
              trackScreenRecordingEvent("bonus_points_categoty_filter_opened");
              track("Opened bonus points categoty filter");
            }}
          />
          <FromFilter
            value={selectedFromIds ?? [null]}
            onChange={setSelectedFromIds}
            onOpen={() => {
              trackScreenRecordingEvent("bonus_points_from_filter_opened");
              track("Opened bonus points from filter");
            }}
          />
          <ClaimFilter
            value={selectedClaimStatus ?? [null]}
            onChange={setSelectedClaimStatus}
            onOpen={() => {
              trackScreenRecordingEvent(
                "bonus_points_claim_status_filter_opened"
              );
              track("Opened bonus points claim status filter");
            }}
          />
        </div>
      </div>

      <RecognitionPointsDataTable
        perPage={ITEMS_PER_PAGE}
        currentPageIndex={currentPageIndex}
        onChangeCurrentPageIndex={setCurrentPageIndex}
        loading={loading}
        items={items}
        total={total}
        viewingRecognitionPointsId={recognitionPointsId}
        onCloseEditEmployee={() => setRecognitionPointsId(undefined)}
        onViewRecognitionPointsId={(id) => setRecognitionPointsId(id)}
      />
    </>
  );
}
