import { useMediaQuery } from "@material-ui/core";
import { ReactNode, useEffect, useState } from "react";

import { useTrack } from "@rewards-web/shared/modules/analytics";

import { SmallScreenWarningModal } from "./small-screen-warning-modal";

const STORAGE_KEY = "dismissed_small_screen_warning_modal";

function loadDismissedModalFromStorage() {
  return localStorage.getItem(STORAGE_KEY) ? true : false;
}

function saveDismissedModalToStorage() {
  return localStorage.setItem(STORAGE_KEY, "true");
}

interface SmallScreenWarningModalProviderProps {
  children: ReactNode;
}

/**
 * Provides a small screen warning to the user if they are viewing on a small screen.
 * If they see it once, they
 */
export function SmallScreenWarningModalProvider({
  children,
}: SmallScreenWarningModalProviderProps) {
  const track = useTrack();
  const [dismissedModal, setDismissedModal] = useState(
    loadDismissedModalFromStorage()
  );
  const [modalOpen, setModalOpen] = useState(false);
  const smallScreen = useMediaQuery("(max-width: 1100px)");

  useEffect(() => {
    if (smallScreen && !dismissedModal) {
      setModalOpen(true);
      track("Viewed small screen warning");
    }
  }, [smallScreen, dismissedModal, track]);

  const handleClose = () => {
    setDismissedModal(true);
    saveDismissedModalToStorage();
    setModalOpen(false);
    track("Dismissed small screen warning");
  };

  return (
    <>
      {children}
      <SmallScreenWarningModal open={modalOpen} onClose={handleClose} />
    </>
  );
}
