import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type MyRecognitionBudgetPeriodsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyRecognitionBudgetPeriodsQuery = (
  { __typename?: 'Query' }
  & { myBudgetPeriods: (
    { __typename?: 'ListMyRecognitionBudgetPeriodsResponse' }
    & { items: Array<(
      { __typename?: 'RecognitionBudgetPeriod' }
      & Pick<Types.RecognitionBudgetPeriod, 'id' | 'startDate' | 'endDate' | 'current'>
    )> }
  ) }
);


export const MyRecognitionBudgetPeriodsDocument = gql`
    query MyRecognitionBudgetPeriods {
  myBudgetPeriods: listMyRecognitionBudgetPeriods {
    items {
      id
      startDate
      endDate
      current
    }
  }
}
    `;

/**
 * __useMyRecognitionBudgetPeriodsQuery__
 *
 * To run a query within a React component, call `useMyRecognitionBudgetPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRecognitionBudgetPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRecognitionBudgetPeriodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyRecognitionBudgetPeriodsQuery(baseOptions?: Apollo.QueryHookOptions<MyRecognitionBudgetPeriodsQuery, MyRecognitionBudgetPeriodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyRecognitionBudgetPeriodsQuery, MyRecognitionBudgetPeriodsQueryVariables>(MyRecognitionBudgetPeriodsDocument, options);
      }
export function useMyRecognitionBudgetPeriodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyRecognitionBudgetPeriodsQuery, MyRecognitionBudgetPeriodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyRecognitionBudgetPeriodsQuery, MyRecognitionBudgetPeriodsQueryVariables>(MyRecognitionBudgetPeriodsDocument, options);
        }
export type MyRecognitionBudgetPeriodsQueryHookResult = ReturnType<typeof useMyRecognitionBudgetPeriodsQuery>;
export type MyRecognitionBudgetPeriodsLazyQueryHookResult = ReturnType<typeof useMyRecognitionBudgetPeriodsLazyQuery>;
export type MyRecognitionBudgetPeriodsQueryResult = Apollo.QueryResult<MyRecognitionBudgetPeriodsQuery, MyRecognitionBudgetPeriodsQueryVariables>;