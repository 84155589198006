import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type StartJobPromotionMutationVariables = Types.Exact<{
  jobPostingId: Types.Scalars['ID'];
}>;


export type StartJobPromotionMutation = (
  { __typename?: 'Mutation' }
  & { startJobPostingPromotion: (
    { __typename?: 'JobPosting' }
    & Pick<Types.JobPosting, 'id'>
    & { activePromotion?: Types.Maybe<(
      { __typename?: 'JobPostingPromotion' }
      & Pick<Types.JobPostingPromotion, 'id'>
    )> }
  ) }
);


export const StartJobPromotionDocument = gql`
    mutation StartJobPromotion($jobPostingId: ID!) {
  startJobPostingPromotion(jobPostingId: $jobPostingId) {
    id
    activePromotion {
      id
    }
  }
}
    `;
export type StartJobPromotionMutationFn = Apollo.MutationFunction<StartJobPromotionMutation, StartJobPromotionMutationVariables>;

/**
 * __useStartJobPromotionMutation__
 *
 * To run a mutation, you first call `useStartJobPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartJobPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startJobPromotionMutation, { data, loading, error }] = useStartJobPromotionMutation({
 *   variables: {
 *      jobPostingId: // value for 'jobPostingId'
 *   },
 * });
 */
export function useStartJobPromotionMutation(baseOptions?: Apollo.MutationHookOptions<StartJobPromotionMutation, StartJobPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartJobPromotionMutation, StartJobPromotionMutationVariables>(StartJobPromotionDocument, options);
      }
export type StartJobPromotionMutationHookResult = ReturnType<typeof useStartJobPromotionMutation>;
export type StartJobPromotionMutationResult = Apollo.MutationResult<StartJobPromotionMutation>;
export type StartJobPromotionMutationOptions = Apollo.BaseMutationOptions<StartJobPromotionMutation, StartJobPromotionMutationVariables>;