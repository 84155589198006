import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SaveOnboardingJobSettingsMutationVariables = Types.Exact<{
  jobPostingBannerImage?: Types.Maybe<Types.Scalars['Upload']>;
  jobPostingLogoImage?: Types.Maybe<Types.Scalars['Upload']>;
  jobPostingLocationField?: Types.Maybe<Types.JobPostingLocationField>;
  websiteUrl?: Types.Maybe<Types.Scalars['String']>;
}>;


export type SaveOnboardingJobSettingsMutation = (
  { __typename?: 'Mutation' }
  & { partialUpdateMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'themeJobPostingBannerImageUrl' | 'themeSquareLogoUrl' | 'jobPostingLocationField' | 'websiteUrl'>
  ) }
);

export type OnboardingJobSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OnboardingJobSettingsQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'themeJobPostingBannerImageUrl' | 'themeSquareLogoUrl' | 'themePrimaryColor' | 'jobPostingLocationField' | 'websiteUrl'>
  ) }
);


export const SaveOnboardingJobSettingsDocument = gql`
    mutation SaveOnboardingJobSettings($jobPostingBannerImage: Upload, $jobPostingLogoImage: Upload, $jobPostingLocationField: JobPostingLocationField, $websiteUrl: String) {
  partialUpdateMyRewardsOrganization(
    jobPostingBannerImage: $jobPostingBannerImage
    jobPostingLogoImage: $jobPostingLogoImage
    jobPostingLocationField: $jobPostingLocationField
    websiteUrl: $websiteUrl
  ) {
    id
    themeJobPostingBannerImageUrl
    themeSquareLogoUrl
    jobPostingLocationField
    websiteUrl
  }
}
    `;
export type SaveOnboardingJobSettingsMutationFn = Apollo.MutationFunction<SaveOnboardingJobSettingsMutation, SaveOnboardingJobSettingsMutationVariables>;

/**
 * __useSaveOnboardingJobSettingsMutation__
 *
 * To run a mutation, you first call `useSaveOnboardingJobSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveOnboardingJobSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveOnboardingJobSettingsMutation, { data, loading, error }] = useSaveOnboardingJobSettingsMutation({
 *   variables: {
 *      jobPostingBannerImage: // value for 'jobPostingBannerImage'
 *      jobPostingLogoImage: // value for 'jobPostingLogoImage'
 *      jobPostingLocationField: // value for 'jobPostingLocationField'
 *      websiteUrl: // value for 'websiteUrl'
 *   },
 * });
 */
export function useSaveOnboardingJobSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SaveOnboardingJobSettingsMutation, SaveOnboardingJobSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveOnboardingJobSettingsMutation, SaveOnboardingJobSettingsMutationVariables>(SaveOnboardingJobSettingsDocument, options);
      }
export type SaveOnboardingJobSettingsMutationHookResult = ReturnType<typeof useSaveOnboardingJobSettingsMutation>;
export type SaveOnboardingJobSettingsMutationResult = Apollo.MutationResult<SaveOnboardingJobSettingsMutation>;
export type SaveOnboardingJobSettingsMutationOptions = Apollo.BaseMutationOptions<SaveOnboardingJobSettingsMutation, SaveOnboardingJobSettingsMutationVariables>;
export const OnboardingJobSettingsDocument = gql`
    query OnboardingJobSettings {
  getMyRewardsOrganization {
    id
    themeJobPostingBannerImageUrl
    themeSquareLogoUrl
    themePrimaryColor
    jobPostingLocationField
    websiteUrl
  }
}
    `;

/**
 * __useOnboardingJobSettingsQuery__
 *
 * To run a query within a React component, call `useOnboardingJobSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingJobSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingJobSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingJobSettingsQuery(baseOptions?: Apollo.QueryHookOptions<OnboardingJobSettingsQuery, OnboardingJobSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingJobSettingsQuery, OnboardingJobSettingsQueryVariables>(OnboardingJobSettingsDocument, options);
      }
export function useOnboardingJobSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingJobSettingsQuery, OnboardingJobSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingJobSettingsQuery, OnboardingJobSettingsQueryVariables>(OnboardingJobSettingsDocument, options);
        }
export type OnboardingJobSettingsQueryHookResult = ReturnType<typeof useOnboardingJobSettingsQuery>;
export type OnboardingJobSettingsLazyQueryHookResult = ReturnType<typeof useOnboardingJobSettingsLazyQuery>;
export type OnboardingJobSettingsQueryResult = Apollo.QueryResult<OnboardingJobSettingsQuery, OnboardingJobSettingsQueryVariables>;