import { Modal } from "@rewards-web/shared/components/modal/modal";
import { serializePhoneNumber } from "@rewards-web/shared/lib/serialize-phone-number";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { useCreateRewardsUserMutation } from "../../../graphql/create-employee.generated";
import { useEmployeeErrorHandler } from "../../hooks/use-employee-error-handler";
import { AlayaCareIntegrationEnabledAlert } from "../alayacare-integration-enabled-alert";
import { AddEmployeeForm } from "./add-employee-form";

export interface AddEmployeeModalProps {
  open: boolean;
  onClose(): void;
  onUpdate(): void;
  usesWorkDevices: boolean;
  managersEnabled: boolean;
  integratedWithAlayaCare: boolean;
  withGroupIds?: boolean;
}

export function AddEmployeeModal({
  open,
  onClose,
  onUpdate,
  usesWorkDevices,
  managersEnabled,
  integratedWithAlayaCare,
  withGroupIds,
}: AddEmployeeModalProps): JSX.Element {
  const track = useTrack();
  const snackbar = useSnackbar();
  const handleEmployeeError = useEmployeeErrorHandler();

  const [
    createNewEmployee,
    { loading: creatingEmployee },
  ] = useCreateRewardsUserMutation();

  const modalChildren = (() => {
    if (integratedWithAlayaCare) {
      return (
        <AlayaCareIntegrationEnabledAlert
          onClose={onClose}
          attemptedAction="Create employee"
        />
      );
    }

    return (
      <AddEmployeeForm
        onClose={onClose}
        usesWorkDevices={usesWorkDevices}
        managersEnabled={managersEnabled}
        withGroupIds={withGroupIds}
        onSubmit={async (values) => {
          try {
            const response = await createNewEmployee({
              variables: {
                firstName: values.firstName,
                lastName: values.lastName,
                workEmail: values.workEmail,
                workPhoneNumber: values.workPhoneNumber
                  ? serializePhoneNumber(values.workPhoneNumber)
                  : null,
                email: values.personalEmail,
                phoneNumber: values.personalPhoneNumber
                  ? serializePhoneNumber(values.personalPhoneNumber)
                  : null,
                assignedToUserGroupIds: values.assignedToUserGroupId
                  ? [values.assignedToUserGroupId]
                  : [],
                branchId: values.branchId || null,
                managerId: values.managerId || null,
              },
            });

            snackbar.show({
              severity: "success",
              message: "The employee was successfully created.",
            });
            onClose();
            onUpdate();
            track("Rewards user created", {
              rewardsUserId: response.data?.createRewardsUser.id,
            });
          } catch (error) {
            handleEmployeeError(error);
          }
        }}
      />
    );
  })();

  return (
    <Modal
      width="700px"
      open={open}
      onClose={onClose}
      disableBackdropClick={creatingEmployee}
    >
      {modalChildren}
    </Modal>
  );
}
