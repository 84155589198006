/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faEdit, faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Typography } from "@rewards-web/shared/components/typography";
import { Role } from "@rewards-web/shared/graphql-types";
import { usePathParamToggle } from "@rewards-web/shared/hooks/use-path-param-toggle";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { DataTable } from "../../../../../shared/components/data-table";
import { ListActions } from "../../../../../shared/components/list-actions";
import { useDrawerControl } from "../../../../../shared/components/right-drawer/use-drawer-control";
import { AuthorizedAdminPageWrapper } from "../../../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { CreateRecognitionCategoryModal } from "./create-recognition-category-modal";
import { DeleteRecognitionCategoryModal } from "./delete-recognition-category-modal";
import { EditRecognitionCategoryModal } from "./edit-recognition-category-modal";
import { useRecognitionCategoriesListQuery } from "./recognition-categories-list.generated";
import { useRecognitionSettingsPageDataQuery } from "./recognition-categories-settings-page-data.generated";

const CATEGORIES_PER_PAGE = 10;

export function SettingsRecognitionPage() {
  const [createCategoryModalOpen, setCreateCandidateModalOpen] = useState(
    false
  );
  const [
    editingCategoryId,
    recognitionCategoryEditActions,
  ] = usePathParamToggle({
    param: "id",
    onPath: "/settings/recognition/categories/:id/edit",
    offPath: "/settings/recognition",
  });
  const [editCategoryModal, editCategoryModalActions] = useDrawerControl(
    editingCategoryId
  );
  const [deletingCategoryId, setDeletingCategoryId] = useState<string>();
  const [
    deletingCategoryModal,
    deletingCategoryModalActions,
  ] = useDrawerControl(deletingCategoryId);

  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const pageDataQuery = useRecognitionSettingsPageDataQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
  });

  const isSuperuser = pageDataQuery.data?.myIdentity
    ? pageDataQuery.data.myIdentity.roles.some((role: { roleName: Role }) =>
        [Role.Superuser, Role.RewardsSuperuser].includes(role.roleName)
      )
    : false;

  const listQuery = useRecognitionCategoriesListQuery({
    onError: reportError,
    variables: {
      limit: CATEGORIES_PER_PAGE,
      offset: currentPageIndex * CATEGORIES_PER_PAGE,
    },
  });

  if (listQuery.error || pageDataQuery.error) {
    return (
      <Alert
        message="An unexpected error occurred. Please try again later."
        severity="error"
      />
    );
  }

  const loading = listQuery.loading;
  const total = listQuery.data?.listRecognitionCategories.total;
  const items = listQuery.data?.listRecognitionCategories.items ?? [];

  return (
    <AuthorizedAdminPageWrapper resource="settingsRecognition">
      <div
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(3)};
          display: flex;
        `}
      >
        <Button
          color="primary"
          startIcon={<FontAwesomeIcon icon={faPlus} />}
          label="Create category"
          size="medium"
          onClick={() => {
            setCreateCandidateModalOpen(true);
          }}
          width="auto"
          css={css`
            margin-left: auto;
          `}
        />
      </div>
      <DataTable
        itemsPerPage={CATEGORIES_PER_PAGE}
        currentPageIndex={currentPageIndex}
        onCurrentPageIndexChange={setCurrentPageIndex}
        loading={loading}
        items={items}
        total={total}
        emptyText="There are no recognition categories."
        tableHeaders={
          <TableRow>
            <TableHeader>
              <Typography variant="subtitle" color="textPrimary">
                Category name
              </Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="subtitle" color="textPrimary">
                Default points
              </Typography>
            </TableHeader>
            <TableHeader align="right">
              <Typography variant="subtitle" color="textPrimary">
                Action
              </Typography>
            </TableHeader>
          </TableRow>
        }
        tableBody={items?.map((category, index) => {
          const divider = !(
            // don't show divider for last item
            (
              index === items.length - 1 &&
              // and don't show if we're not paginated
              (total ?? 0) <= CATEGORIES_PER_PAGE
            )
          );

          return (
            <TableRow key={category.id}>
              <TableCell divider={divider}>
                <Typography variant="body" color="textPrimary">
                  {category.name}
                </Typography>
              </TableCell>
              <TableCell divider={divider}>
                <Typography variant="body" color="grey.800">
                  {(() => {
                    if (typeof category.defaultPointAmount === "number") {
                      if (pageDataQuery.data) {
                        return `${numberWithCommas(
                          category.defaultPointAmount
                        )} (${formatDollars(
                          category.defaultPointAmount /
                            pageDataQuery.data.getMyRewardsOrganization
                              .pointsPerDollar
                        )})`;
                      } else {
                        return numberWithCommas(category.defaultPointAmount);
                      }
                    } else {
                      return (
                        <span
                          css={css`
                            font-style: italic;
                          `}
                        >
                          No default points set
                        </span>
                      );
                    }
                  })()}
                </Typography>
              </TableCell>
              <TableCell divider={divider} align="right">
                <ListActions
                  menuOptions={[
                    {
                      icon: <FontAwesomeIcon icon={faEdit} />,
                      label: "Edit",
                      onClick: () => {
                        recognitionCategoryEditActions.navigateToOnPath(
                          category.id
                        );
                      },
                    },
                    {
                      icon: <FontAwesomeIcon icon={faTrash} />,
                      label: "Delete",
                      onClick: () => {
                        setDeletingCategoryId(category.id);
                      },
                    },
                  ]}
                />
              </TableCell>
            </TableRow>
          );
        })}
      />

      {typeof pageDataQuery.data?.getMyRewardsOrganization.pointsPerDollar ===
        "number" && (
        <>
          <CreateRecognitionCategoryModal
            open={createCategoryModalOpen}
            onClose={() => {
              setCreateCandidateModalOpen(false);
            }}
            onCreated={() => {
              if (currentPageIndex !== 0) {
                setCurrentPageIndex(0);
              }
              listQuery.refetch();
            }}
            pointsPerDollar={
              pageDataQuery.data.getMyRewardsOrganization.pointsPerDollar
            }
            isSuperuser={isSuperuser}
          />
          <EditRecognitionCategoryModal
            open={editCategoryModal.open}
            recognitionCategoryId={editCategoryModal.state}
            onClose={() => {
              recognitionCategoryEditActions.navigateToOffPath();
            }}
            onExited={() => {
              editCategoryModalActions.onExited();
            }}
            onSaved={() => {
              listQuery.refetch();
            }}
            pointsPerDollar={
              pageDataQuery.data.getMyRewardsOrganization.pointsPerDollar
            }
            isSuperuser={isSuperuser}
          />
        </>
      )}

      <DeleteRecognitionCategoryModal
        open={deletingCategoryModal.open}
        recognitionCategoryId={deletingCategoryModal.state}
        onClose={() => {
          setDeletingCategoryId(undefined);
        }}
        onExited={() => {
          deletingCategoryModalActions.onExited();
        }}
        onDeleted={() => {
          listQuery.refetch();
        }}
      />
    </AuthorizedAdminPageWrapper>
  );
}
