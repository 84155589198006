import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { usePostLoginRedirect } from "@rewards-web/shared/hooks/use-post-login-redirect";
import { useQueryParam } from "@rewards-web/shared/hooks/use-query-param";
import { isEmail } from "@rewards-web/shared/lib/is-email";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { useCognitoAuth } from "../../shared/modules/cognito-auth";
import { AuthErrorCode } from "../../shared/modules/cognito-auth/constants";
import {
  AuthError,
  NewPasswordRequiredError,
  SmsMfaRequiredError,
} from "../../shared/modules/cognito-auth/errors";
import {
  SubmitMfaVerificationCodePage,
  SubmitMfaVerificationCodePageProps,
} from "../../shared/modules/cognito-auth/sms-mfa/components/submit-mfa-verification-code-page";
import { CompleteNewPasswordForm } from "./complete-new-password-form";
import { ForgotPasswordForm } from "./forgot-password-form";
import { LoginForm, LoginFormProps } from "./login-form";
import {
  CompleteNewPasswordFormValues,
  ForgotPasswordFormValues,
  LoginFormValues,
  LoginSubmissionError,
} from "./types";

export function LoginPage(): JSX.Element | null {
  const snackbar = useSnackbar();
  const track = useTrack();
  const navigate = useNavigate();
  const {
    signedIn,
    signInWithPassword,
    completeNewPassword,
    forgotPassword,
    forgotPasswordSubmit,
    submitMfaCode,
    resendMfaCode,
  } = useCognitoAuth();
  const [
    submissionError,
    setSubmissionError,
  ] = useState<LoginSubmissionError | null>(null);
  const [completingNewPassword, setCompletingNewPassword] = useState(false);
  const [
    verifyingViaSmsMfaPhoneNumber,
    setVerifyingViaSmsMfaPhoneNumber,
  ] = useState<{ phoneNumber: string; email: string; password: string }>();
  const [forgotPasswordPage, setForgotPasswordPage] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>("");
  const [adminEmail] = useQueryParam("email");
  const { postLoginRedirectPath } = usePostLoginRedirect({
    loginPath: "/login",
  });
  const [sessionExpired] = useQueryParam("session_expired");

  const onLoginSubmit: LoginFormProps["onSubmit"] = async (
    values,
    { passwordPasted }
  ) => {
    track("Login form submitted", {
      // sometimes, users are unable to login with their temp passwords.
      // we are tracking password metrics to hopefully identify the issue
      // once this issue is fixed, we can likely remove this track call
      // IMPORTANT: we should be very careful not to track the actual password here.
      isValidEmail: isEmail(values.email),
      includesWhitespace: values.password !== values.password.trim(),
      numPasswordCharacters: values.password.length,
      passwordPasted,
    });

    const email = values.email.trim();
    const password = values.password.trim();

    try {
      await signInWithPassword(email, password);
      track("Login with email succeeded");
    } catch (error) {
      if (error instanceof NewPasswordRequiredError) {
        setCompletingNewPassword(true);
        setSubmissionError(null);
        return;
      } else if (error instanceof SmsMfaRequiredError) {
        track("Login challenged with SMS MFA");
        setVerifyingViaSmsMfaPhoneNumber({
          phoneNumber: error.deliveredToPhoneNumber,
          email,
          password,
        });
        setSubmissionError(null);
        return;
      } else if (error instanceof AuthError) {
        switch (error.code) {
          case AuthErrorCode.UserDoesNotExist:
          case AuthErrorCode.NotAuthorized:
          case AuthErrorCode.Deactivated:
            return setSubmissionError({
              code: error.code,
              severity: "warning",
            });
          case AuthErrorCode.UserNotConfirmed:
            return setSubmissionError({
              code: error.code,
              severity: "warning",
            });
          case AuthErrorCode.TooManyRequests:
            return setSubmissionError({
              code: error.code,
              severity: "error",
            });
        }
      }

      setSubmissionError({
        code: null,
        severity: "error",
      });
      reportError(error);
    }
  };

  useEffect(() => {
    if (signedIn) {
      // this navigation is trigged after the user is signed here
      // because we need to wait for the auth module (react context) to register
      // the login asynchronously
      // it's too early to navigate after calling `signInWithPassword`
      navigate(postLoginRedirectPath || "/");
    }
  }, [signedIn, postLoginRedirectPath, navigate]);

  const onCompleteNewPasswordSubmit = async (
    values: CompleteNewPasswordFormValues
  ) => {
    try {
      await completeNewPassword(values.password);
    } catch (error) {
      if (error instanceof NewPasswordRequiredError) {
        setCompletingNewPassword(false);
      } else if (error instanceof AuthError) {
        switch (error.code) {
          case AuthErrorCode.NotAuthorized:
            setCompletingNewPassword(false);
            loginForm.setValue("password", "");
            return setSubmissionError({
              code: "SET_NEW_PASSWORD_EXPIRED",
              severity: "warning",
            });
        }
        return setSubmissionError({
          code: error.code,
          severity: "error",
        });
      }

      setSubmissionError({
        code: null,
        severity: "error",
      });
      reportError(error);
    }
  };

  const onForgotPassword = async (values: ForgotPasswordFormValues) => {
    try {
      await forgotPassword(values.email);
      setForgotPasswordEmail(values.email);
      setForgotPasswordPage(false);
      setResetPassword(true);
    } catch (error) {
      setSubmissionError({
        code: null,
        severity: "error",
      });
      reportError(error);
    }
  };

  const onForgotPasswordSubmit = async (
    values: CompleteNewPasswordFormValues
  ) => {
    try {
      await forgotPasswordSubmit(
        forgotPasswordEmail,
        values.code!.trim(),
        values.password
      );
      await signInWithPassword(forgotPasswordEmail, values.password);
      navigate("/");
    } catch (error) {
      if (error instanceof SmsMfaRequiredError) {
        // it's possible when signing in that the
        // admin will encounter the MFA flow
        // (if they have an MFA phone number set)

        setVerifyingViaSmsMfaPhoneNumber({
          phoneNumber: error.deliveredToPhoneNumber,
          email: forgotPasswordEmail,
          password: values.password,
        });
        setSubmissionError(null);
        return;
      }

      // unexpected error

      setSubmissionError({
        code: null,
        severity: "error",
      });
      reportError(error);
    }
  };

  const onSubmitMfaVerificationCode: SubmitMfaVerificationCodePageProps["onSubmit"] = async ({
    code,
  }) => {
    try {
      await submitMfaCode(code);
      track("MFA code submission succeeded", {
        adminEmail: verifyingViaSmsMfaPhoneNumber!.email,
      });
    } catch (error) {
      if (
        error instanceof AuthError &&
        error.code === AuthErrorCode.IncorrectVerificationCode
      ) {
        track("MFA code submission incorrect", {
          adminEmail: verifyingViaSmsMfaPhoneNumber!.email,
        });
        snackbar.show({
          severity: "error",
          message: "The code you entered was incorrect. Please try again.",
          durationMs: 10000,
        });
      } else if (
        error instanceof AuthError &&
        error.code === AuthErrorCode.NotAuthorized
      ) {
        track("MFA code submitted too many times", {
          adminEmail: verifyingViaSmsMfaPhoneNumber!.email,
        });
        snackbar.show({
          severity: "error",
          message: "You have submitted too many codes. Please try again later.",
          durationMs: 10000,
        });
      } else {
        snackbar.show({
          severity: "error",
          message: "An unexpected error occurred. Please try again later.",
        });
        reportError(error);
      }
    }
  };

  const onResendMfaCode: SubmitMfaVerificationCodePageProps["onResendCode"] = async () => {
    try {
      await resendMfaCode(
        verifyingViaSmsMfaPhoneNumber!.email,
        verifyingViaSmsMfaPhoneNumber!.password
      );

      track("Resent MFA code");

      snackbar.show({
        severity: "success",
        message: "Your code has been re-sent",
        durationMs: 10000,
      });
    } catch (error) {
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
      reportError(error);
    }
  };

  const loginForm = useForm<LoginFormValues>({
    defaultValues: {
      email: adminEmail ? adminEmail : "",
      password: "",
    },
  });

  const resetPasswordForm = useForm<CompleteNewPasswordFormValues>({
    defaultValues: {
      code: "",
      password: "",
      confirmPassword: "",
    },
  });

  const forgotPasswordForm = useForm<ForgotPasswordFormValues>({
    defaultValues: {
      email: "",
    },
  });

  if (signedIn) {
    return null; // should redirect
  }

  if (verifyingViaSmsMfaPhoneNumber) {
    // this case must be before the reset password flow,
    // because the reset password flow may take the user
    // straight into the MFA flow

    return (
      <SubmitMfaVerificationCodePage
        phoneNumber={verifyingViaSmsMfaPhoneNumber.phoneNumber}
        onResendCode={onResendMfaCode}
        onCancel={() => setVerifyingViaSmsMfaPhoneNumber(undefined)}
        onSubmit={onSubmitMfaVerificationCode}
        cancelButtonLabel="Cancel"
      />
    );
  }

  if (resetPassword) {
    return (
      <FormProvider {...resetPasswordForm}>
        <CompleteNewPasswordForm
          submissionError={submissionError}
          onSubmit={onForgotPasswordSubmit}
          type="RESET_PASSWORD"
        />
      </FormProvider>
    );
  }

  if (forgotPasswordPage) {
    return (
      <FormProvider {...forgotPasswordForm}>
        <ForgotPasswordForm
          onCancel={() => setForgotPasswordPage(false)}
          onSubmit={onForgotPassword}
          submissionError={submissionError}
        />
      </FormProvider>
    );
  }

  if (completingNewPassword) {
    return (
      <FormProvider {...resetPasswordForm}>
        <CompleteNewPasswordForm
          submissionError={submissionError}
          onSubmit={onCompleteNewPasswordSubmit}
          type="INITIAL_PASSWORD"
        />
      </FormProvider>
    );
  }

  return (
    <FormProvider {...loginForm}>
      <LoginForm
        redirecting={!!postLoginRedirectPath}
        sessionExpired={!!sessionExpired}
        onForgotPassword={() => setForgotPasswordPage(true)}
        submissionError={submissionError}
        onSubmit={onLoginSubmit}
      />
    </FormProvider>
  );
}
