import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type MyAdminPermissionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyAdminPermissionsQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsAdminUser?: Types.Maybe<(
    { __typename?: 'RewardsAdminUser' }
    & Pick<Types.RewardsAdminUser, 'id'>
    & { permissions: (
      { __typename?: 'RewardsAdminFullAccessPermissions' }
      & Pick<Types.RewardsAdminFullAccessPermissions, 'type'>
    ) | (
      { __typename?: 'RewardsAdminRestrictedAccessPermissions' }
      & Pick<Types.RewardsAdminRestrictedAccessPermissions, 'type'>
      & { permissionsV2?: Types.Maybe<(
        { __typename?: 'RewardsAdminRestrictedAccessPermissionsMapV2' }
        & { jobs?: Types.Maybe<(
          { __typename?: 'RewardsAdminRestrictedAccessJobsPermissions' }
          & Pick<Types.RewardsAdminRestrictedAccessJobsPermissions, 'permissionLevel'>
        )>, candidates?: Types.Maybe<(
          { __typename?: 'RewardsAdminRestrictedAccessCandidatesPermissions' }
          & Pick<Types.RewardsAdminRestrictedAccessCandidatesPermissions, 'permissionLevel'>
        )>, redemptions?: Types.Maybe<(
          { __typename?: 'RewardsAdminRestrictedAccessRedemptionsPermissions' }
          & Pick<Types.RewardsAdminRestrictedAccessRedemptionsPermissions, 'permissionLevel'>
        )>, recognitionPoints?: Types.Maybe<(
          { __typename?: 'RewardsAdminRestrictedAccessRecognitionPointsPermissions' }
          & Pick<Types.RewardsAdminRestrictedAccessRecognitionPointsPermissions, 'permissionLevel'>
        )>, employees?: Types.Maybe<(
          { __typename?: 'RewardsAdminRestrictedAccessEmployeesPermissions' }
          & Pick<Types.RewardsAdminRestrictedAccessEmployeesPermissions, 'permissionLevel'>
        )>, administrators?: Types.Maybe<(
          { __typename?: 'RewardsAdminRestrictedAccessAdministratorsPermissions' }
          & Pick<Types.RewardsAdminRestrictedAccessAdministratorsPermissions, 'permissionLevel'>
        )>, surveys?: Types.Maybe<(
          { __typename?: 'RewardsAdminRestrictedAccessSurveysPermissions' }
          & Pick<Types.RewardsAdminRestrictedAccessSurveysPermissions, 'permissionLevel'>
        )> }
      )> }
    ) }
  )> }
);


export const MyAdminPermissionsDocument = gql`
    query MyAdminPermissions {
  getMyRewardsAdminUser {
    id
    permissions {
      ... on RewardsAdminFullAccessPermissions {
        type
      }
      ... on RewardsAdminRestrictedAccessPermissions {
        type
        permissionsV2 {
          jobs {
            permissionLevel
          }
          candidates {
            permissionLevel
          }
          redemptions {
            permissionLevel
          }
          recognitionPoints {
            permissionLevel
          }
          employees {
            permissionLevel
          }
          administrators {
            permissionLevel
          }
          surveys {
            permissionLevel
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMyAdminPermissionsQuery__
 *
 * To run a query within a React component, call `useMyAdminPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAdminPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAdminPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyAdminPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<MyAdminPermissionsQuery, MyAdminPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyAdminPermissionsQuery, MyAdminPermissionsQueryVariables>(MyAdminPermissionsDocument, options);
      }
export function useMyAdminPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyAdminPermissionsQuery, MyAdminPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyAdminPermissionsQuery, MyAdminPermissionsQueryVariables>(MyAdminPermissionsDocument, options);
        }
export type MyAdminPermissionsQueryHookResult = ReturnType<typeof useMyAdminPermissionsQuery>;
export type MyAdminPermissionsLazyQueryHookResult = ReturnType<typeof useMyAdminPermissionsLazyQuery>;
export type MyAdminPermissionsQueryResult = Apollo.QueryResult<MyAdminPermissionsQuery, MyAdminPermissionsQueryVariables>;