import { SVGProps } from "react";

export function SendIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.9606 1.12667L13.8676 19.4223C13.762 19.7338 13.4873 19.958 13.1609 20H13.0488C12.7558 20 12.4833 19.8502 12.3249 19.6034L9.48106 15.1285C9.22467 14.7287 9.33994 14.1963 9.73961 13.9387C9.93351 13.8137 10.1694 13.7716 10.3946 13.8212C10.6197 13.8719 10.8147 14.0098 10.9375 14.206L12.8077 17.1719L17.7803 2.22132L2.85365 7.19612L5.79236 9.06707C6.19418 9.32141 6.31375 9.8549 6.05953 10.2569C5.8053 10.6589 5.27204 10.7785 4.87022 10.5242L0.397342 7.68762C0.118335 7.50872 -0.0335644 7.1854 0.00628588 6.85669C0.0472227 6.5269 0.27237 6.24992 0.586941 6.14431L18.8745 0.0399643C19.1783 -0.055956 19.509 0.0237981 19.7363 0.246891C19.9711 0.474295 20.0584 0.814864 19.9604 1.12633L19.9606 1.12667ZM13.0661 6.95511C12.73 6.62102 12.1871 6.62102 11.851 6.95511L5.75791 13.0509C5.42396 13.3872 5.42396 13.9303 5.75791 14.2666C6.09403 14.6007 6.63696 14.6007 6.97305 14.2666L13.0661 8.17079C13.3958 7.83022 13.3882 7.28705 13.0489 6.95511H13.0661Z"
        fill={props.fill || "#2C393F"}
      />
    </svg>
  );
}
