import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SaveLaunchDateMutationVariables = Types.Exact<{
  onboardingTargetLaunchDate?: Types.Maybe<Types.Scalars['Date']>;
}>;


export type SaveLaunchDateMutation = (
  { __typename?: 'Mutation' }
  & { partialUpdateMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id'>
    & { onboardingState: (
      { __typename?: 'RewardsOrganizationOnboardingState' }
      & Pick<Types.RewardsOrganizationOnboardingState, 'id' | 'targetLaunchDate'>
    ) }
  ) }
);


export const SaveLaunchDateDocument = gql`
    mutation SaveLaunchDate($onboardingTargetLaunchDate: Date) {
  partialUpdateMyRewardsOrganization(
    onboardingTargetLaunchDate: $onboardingTargetLaunchDate
  ) {
    id
    onboardingState {
      id
      targetLaunchDate
    }
  }
}
    `;
export type SaveLaunchDateMutationFn = Apollo.MutationFunction<SaveLaunchDateMutation, SaveLaunchDateMutationVariables>;

/**
 * __useSaveLaunchDateMutation__
 *
 * To run a mutation, you first call `useSaveLaunchDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLaunchDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLaunchDateMutation, { data, loading, error }] = useSaveLaunchDateMutation({
 *   variables: {
 *      onboardingTargetLaunchDate: // value for 'onboardingTargetLaunchDate'
 *   },
 * });
 */
export function useSaveLaunchDateMutation(baseOptions?: Apollo.MutationHookOptions<SaveLaunchDateMutation, SaveLaunchDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveLaunchDateMutation, SaveLaunchDateMutationVariables>(SaveLaunchDateDocument, options);
      }
export type SaveLaunchDateMutationHookResult = ReturnType<typeof useSaveLaunchDateMutation>;
export type SaveLaunchDateMutationResult = Apollo.MutationResult<SaveLaunchDateMutation>;
export type SaveLaunchDateMutationOptions = Apollo.BaseMutationOptions<SaveLaunchDateMutation, SaveLaunchDateMutationVariables>;