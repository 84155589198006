export function QuestionMarkIcon() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.854492C12.4062 0.854492 16 4.44824 16 8.85449C16 13.292 12.4062 16.8545 8 16.8545C3.5625 16.8545 0 13.292 0 8.85449C0 4.44824 3.5625 0.854492 8 0.854492ZM8 15.3545C11.5625 15.3545 14.5 12.4482 14.5 8.85449C14.5 5.29199 11.5625 2.35449 8 2.35449C4.40625 2.35449 1.5 5.29199 1.5 8.85449C1.5 12.4482 4.40625 15.3545 8 15.3545ZM8 11.3545C8.53125 11.3545 9 11.792 9 12.3545C9 12.917 8.53125 13.3545 8 13.3545C7.40625 13.3545 7 12.917 7 12.3545C7 11.792 7.4375 11.3545 8 11.3545ZM9.03125 4.85449C10.2812 4.85449 11.25 5.82324 11.2188 7.04199C11.2188 7.79199 10.8125 8.51074 10.1562 8.91699L8.75 9.79199V9.85449C8.75 10.2607 8.40625 10.6045 8 10.6045C7.59375 10.6045 7.25 10.2607 7.25 9.85449V9.35449C7.25 9.10449 7.375 8.85449 7.625 8.69824L9.40625 7.63574C9.625 7.51074 9.75 7.29199 9.75 7.04199C9.75 6.66699 9.40625 6.35449 9 6.35449H7.40625C7.03125 6.35449 6.75 6.66699 6.75 7.04199C6.75 7.44824 6.40625 7.79199 6 7.79199C5.59375 7.79199 5.25 7.44824 5.25 7.04199C5.25 5.82324 6.21875 4.85449 7.4375 4.85449H9.03125Z"
        fill="#444444"
      />
    </svg>
  );
}
