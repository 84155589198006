import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AllTimePerformanceInsightMetricGroupDataQueryVariables = Types.Exact<{
  startDate: Types.Scalars['Timestamp'];
  endDate: Types.Scalars['Timestamp'];
}>;


export type AllTimePerformanceInsightMetricGroupDataQuery = (
  { __typename?: 'Query' }
  & { getLoggedInUsersInsightMetric: (
    { __typename?: 'LoggedInUsersInsightMetric' }
    & Pick<Types.LoggedInUsersInsightMetric, 'id' | 'usersWhoLoggedInCount' | 'activeUserCount'>
  ), getTotalHiresInsightMetric: (
    { __typename?: 'TotalHiresInsightMetric' }
    & Pick<Types.TotalHiresInsightMetric, 'id' | 'hiredCandidateCount'>
  ) }
);


export const AllTimePerformanceInsightMetricGroupDataDocument = gql`
    query AllTimePerformanceInsightMetricGroupData($startDate: Timestamp!, $endDate: Timestamp!) {
  getLoggedInUsersInsightMetric(startDate: $startDate, endDate: $endDate) {
    id
    usersWhoLoggedInCount
    activeUserCount
  }
  getTotalHiresInsightMetric(startDate: $startDate, endDate: $endDate) {
    id
    hiredCandidateCount
  }
}
    `;

/**
 * __useAllTimePerformanceInsightMetricGroupDataQuery__
 *
 * To run a query within a React component, call `useAllTimePerformanceInsightMetricGroupDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTimePerformanceInsightMetricGroupDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTimePerformanceInsightMetricGroupDataQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useAllTimePerformanceInsightMetricGroupDataQuery(baseOptions: Apollo.QueryHookOptions<AllTimePerformanceInsightMetricGroupDataQuery, AllTimePerformanceInsightMetricGroupDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTimePerformanceInsightMetricGroupDataQuery, AllTimePerformanceInsightMetricGroupDataQueryVariables>(AllTimePerformanceInsightMetricGroupDataDocument, options);
      }
export function useAllTimePerformanceInsightMetricGroupDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTimePerformanceInsightMetricGroupDataQuery, AllTimePerformanceInsightMetricGroupDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTimePerformanceInsightMetricGroupDataQuery, AllTimePerformanceInsightMetricGroupDataQueryVariables>(AllTimePerformanceInsightMetricGroupDataDocument, options);
        }
export type AllTimePerformanceInsightMetricGroupDataQueryHookResult = ReturnType<typeof useAllTimePerformanceInsightMetricGroupDataQuery>;
export type AllTimePerformanceInsightMetricGroupDataLazyQueryHookResult = ReturnType<typeof useAllTimePerformanceInsightMetricGroupDataLazyQuery>;
export type AllTimePerformanceInsightMetricGroupDataQueryResult = Apollo.QueryResult<AllTimePerformanceInsightMetricGroupDataQuery, AllTimePerformanceInsightMetricGroupDataQueryVariables>;