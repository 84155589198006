import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UploadBulkRecognitionFileMutationVariables = Types.Exact<{
  bulkRecognitionFile: Types.Scalars['Upload'];
  requestId: Types.Scalars['String'];
  from: Types.Scalars['String'];
  categoryId?: Types.Maybe<Types.Scalars['ID']>;
  notes?: Types.Maybe<Types.Scalars['String']>;
  previewTotals: Types.BulkRecognitionUploadPreviewTotals;
}>;


export type UploadBulkRecognitionFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadBulkRecognitionFile: (
    { __typename?: 'BulkRecognitionUpload' }
    & Pick<Types.BulkRecognitionUpload, 'id'>
  ) }
);


export const UploadBulkRecognitionFileDocument = gql`
    mutation UploadBulkRecognitionFile($bulkRecognitionFile: Upload!, $requestId: String!, $from: String!, $categoryId: ID, $notes: String, $previewTotals: BulkRecognitionUploadPreviewTotals!) {
  uploadBulkRecognitionFile(
    bulkRecognitionFile: $bulkRecognitionFile
    requestId: $requestId
    from: $from
    categoryId: $categoryId
    notes: $notes
    previewTotals: $previewTotals
  ) {
    id
  }
}
    `;
export type UploadBulkRecognitionFileMutationFn = Apollo.MutationFunction<UploadBulkRecognitionFileMutation, UploadBulkRecognitionFileMutationVariables>;

/**
 * __useUploadBulkRecognitionFileMutation__
 *
 * To run a mutation, you first call `useUploadBulkRecognitionFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBulkRecognitionFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBulkRecognitionFileMutation, { data, loading, error }] = useUploadBulkRecognitionFileMutation({
 *   variables: {
 *      bulkRecognitionFile: // value for 'bulkRecognitionFile'
 *      requestId: // value for 'requestId'
 *      from: // value for 'from'
 *      categoryId: // value for 'categoryId'
 *      notes: // value for 'notes'
 *      previewTotals: // value for 'previewTotals'
 *   },
 * });
 */
export function useUploadBulkRecognitionFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadBulkRecognitionFileMutation, UploadBulkRecognitionFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadBulkRecognitionFileMutation, UploadBulkRecognitionFileMutationVariables>(UploadBulkRecognitionFileDocument, options);
      }
export type UploadBulkRecognitionFileMutationHookResult = ReturnType<typeof useUploadBulkRecognitionFileMutation>;
export type UploadBulkRecognitionFileMutationResult = Apollo.MutationResult<UploadBulkRecognitionFileMutation>;
export type UploadBulkRecognitionFileMutationOptions = Apollo.BaseMutationOptions<UploadBulkRecognitionFileMutation, UploadBulkRecognitionFileMutationVariables>;