import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EmployeeFeedbackTableDataQueryVariables = Types.Exact<{
  questionType: Types.SurveyQuestionType;
  limit: Types.Scalars['Int'];
  offset: Types.Scalars['Int'];
  filter?: Types.Maybe<Types.SurveyCommentsFiltersInput>;
}>;


export type EmployeeFeedbackTableDataQuery = (
  { __typename?: 'Query' }
  & { listCommentsForSurveyQuestion: (
    { __typename?: 'ListSurveyCommentsResponse' }
    & Pick<Types.ListSurveyCommentsResponse, 'total'>
    & { items: Array<(
      { __typename?: 'SurveyQuestionResponse' }
      & Pick<Types.SurveyQuestionResponse, 'surveySubmissionId' | 'score' | 'comment'>
    )> }
  ) }
);


export const EmployeeFeedbackTableDataDocument = gql`
    query EmployeeFeedbackTableData($questionType: SurveyQuestionType!, $limit: Int!, $offset: Int!, $filter: SurveyCommentsFiltersInput) {
  listCommentsForSurveyQuestion(
    surveyType: PULSE_CHECK
    questionType: $questionType
    limit: $limit
    offset: $offset
    filter: $filter
  ) {
    total
    items {
      surveySubmissionId
      score
      comment
    }
  }
}
    `;

/**
 * __useEmployeeFeedbackTableDataQuery__
 *
 * To run a query within a React component, call `useEmployeeFeedbackTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeFeedbackTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeFeedbackTableDataQuery({
 *   variables: {
 *      questionType: // value for 'questionType'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEmployeeFeedbackTableDataQuery(baseOptions: Apollo.QueryHookOptions<EmployeeFeedbackTableDataQuery, EmployeeFeedbackTableDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeFeedbackTableDataQuery, EmployeeFeedbackTableDataQueryVariables>(EmployeeFeedbackTableDataDocument, options);
      }
export function useEmployeeFeedbackTableDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeFeedbackTableDataQuery, EmployeeFeedbackTableDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeFeedbackTableDataQuery, EmployeeFeedbackTableDataQueryVariables>(EmployeeFeedbackTableDataDocument, options);
        }
export type EmployeeFeedbackTableDataQueryHookResult = ReturnType<typeof useEmployeeFeedbackTableDataQuery>;
export type EmployeeFeedbackTableDataLazyQueryHookResult = ReturnType<typeof useEmployeeFeedbackTableDataLazyQuery>;
export type EmployeeFeedbackTableDataQueryResult = Apollo.QueryResult<EmployeeFeedbackTableDataQuery, EmployeeFeedbackTableDataQueryVariables>;