/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

export function InfoIcon() {
  const theme = useTheme();

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0.243164C10.7188 0.243164 13.7812 3.30566 13.7812 7.02441C13.7812 10.7705 10.7188 13.8057 7 13.8057C3.25391 13.8057 0.21875 10.7705 0.21875 7.02441C0.21875 3.30566 3.25391 0.243164 7 0.243164ZM7 12.4932C10.0078 12.4932 12.4688 10.0596 12.4688 7.02441C12.4688 4.0166 10.0078 1.55566 7 1.55566C3.96484 1.55566 1.53125 4.0166 1.53125 7.02441C1.53125 10.0596 3.96484 12.4932 7 12.4932ZM7 3.25098C7.62891 3.25098 8.14844 3.77051 8.14844 4.39941C8.14844 5.05566 7.62891 5.54785 7 5.54785C6.34375 5.54785 5.85156 5.05566 5.85156 4.39941C5.85156 3.77051 6.34375 3.25098 7 3.25098ZM8.53125 10.1963C8.53125 10.3877 8.36719 10.5244 8.20312 10.5244H5.79688C5.60547 10.5244 5.46875 10.3877 5.46875 10.1963V9.54004C5.46875 9.37598 5.60547 9.21191 5.79688 9.21191H6.125V7.46191H5.79688C5.60547 7.46191 5.46875 7.3252 5.46875 7.13379V6.47754C5.46875 6.31348 5.60547 6.14941 5.79688 6.14941H7.54688C7.71094 6.14941 7.875 6.31348 7.875 6.47754V9.21191H8.20312C8.36719 9.21191 8.53125 9.37598 8.53125 9.54004V10.1963Z"
        fill={theme.palette.text.secondary}
      />
    </svg>
  );
}
