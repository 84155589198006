import { SVGProps } from "react";

export function EmployeeIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.99989 0C7.34923 0.00351532 4.80746 1.05817 2.93281 2.93281C1.05815 4.80746 0.00337492 7.34923 0 9.99989C0.000878829 12.6514 1.05379 15.195 2.92943 17.0706C4.80496 18.9461 7.34855 19.9991 10.0001 20C10.1873 20 10.3754 19.9938 10.5626 19.9815V19.9807C13.1122 19.8348 15.5089 18.7203 17.2632 16.8649C19.0183 15.0096 19.9964 12.5531 20 9.9999C19.9965 7.34924 18.9418 4.80748 17.0672 2.93282C15.1925 1.05816 12.6508 0.00338908 10.0001 1.41617e-05L9.99989 0ZM17.0125 14.5745C15.0842 12.8483 12.5873 11.8939 9.99989 11.8939C7.41245 11.8939 4.91546 12.8484 2.98725 14.5745C2.08991 13.2184 1.61355 11.6266 1.61884 9.99989C1.61884 7.00543 3.21666 4.2389 5.80936 2.74201C8.40198 1.2444 11.5978 1.2444 14.1904 2.74201C16.783 4.23874 18.3809 7.00543 18.3809 9.99989C18.3862 11.6267 17.9098 13.2184 17.0125 14.5745Z"
        fill={props.fill || "#116872"}
      />
      <path
        d="M12.9012 7.49744C12.9295 8.29656 12.6399 9.0739 12.096 9.65968C11.5529 10.2461 10.7985 10.5931 9.99991 10.625C9.20122 10.5931 8.44682 10.2461 7.90379 9.65968C7.35996 9.07396 7.07025 8.29661 7.09858 7.49744C7.07026 6.69904 7.35996 5.92242 7.90379 5.33743C8.44762 4.75171 9.20129 4.40622 9.99991 4.375C10.7986 4.40621 11.5523 4.75169 12.096 5.33743C12.6399 5.92242 12.9296 6.69904 12.9012 7.49744Z"
        fill={props.fill || "#116872"}
      />
    </svg>
  );
}
