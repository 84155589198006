import * as Types from '@rewards-web/shared/graphql-types';

import { JobPostingEditDetailsFragment } from '../../../graphql/job-posting-fragment.generated';
import { gql } from '@apollo/client';
import { JobPostingEditDetailsFragmentDoc } from '../../../graphql/job-posting-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type JobPostingToDuplicateQueryVariables = Types.Exact<{
  jobPostingId: Types.Scalars['ID'];
}>;


export type JobPostingToDuplicateQuery = (
  { __typename?: 'Query' }
  & { getJobPostingById?: Types.Maybe<(
    { __typename?: 'JobPosting' }
    & JobPostingEditDetailsFragment
  )> }
);


export const JobPostingToDuplicateDocument = gql`
    query JobPostingToDuplicate($jobPostingId: ID!) {
  getJobPostingById(jobPostingId: $jobPostingId) {
    ...JobPostingEditDetails
  }
}
    ${JobPostingEditDetailsFragmentDoc}`;

/**
 * __useJobPostingToDuplicateQuery__
 *
 * To run a query within a React component, call `useJobPostingToDuplicateQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPostingToDuplicateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPostingToDuplicateQuery({
 *   variables: {
 *      jobPostingId: // value for 'jobPostingId'
 *   },
 * });
 */
export function useJobPostingToDuplicateQuery(baseOptions: Apollo.QueryHookOptions<JobPostingToDuplicateQuery, JobPostingToDuplicateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobPostingToDuplicateQuery, JobPostingToDuplicateQueryVariables>(JobPostingToDuplicateDocument, options);
      }
export function useJobPostingToDuplicateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobPostingToDuplicateQuery, JobPostingToDuplicateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobPostingToDuplicateQuery, JobPostingToDuplicateQueryVariables>(JobPostingToDuplicateDocument, options);
        }
export type JobPostingToDuplicateQueryHookResult = ReturnType<typeof useJobPostingToDuplicateQuery>;
export type JobPostingToDuplicateLazyQueryHookResult = ReturnType<typeof useJobPostingToDuplicateLazyQuery>;
export type JobPostingToDuplicateQueryResult = Apollo.QueryResult<JobPostingToDuplicateQuery, JobPostingToDuplicateQueryVariables>;