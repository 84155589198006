import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

import { PageLayout } from "../../../../shared/components/page-layout";
import { AuthorizedAdminPageWrapper } from "../../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { ScheduleNewDrawPageContents } from "./page-contents";
import { ScheduleNewDrawV2PageContents } from "./page-contents-v2";

const MAX_CONTENT_WIDTH = 1400;

export function ScheduleNewDrawPage(): JSX.Element {
  const adminAppScheduleDrawV2Enabled = useFeatureFlag(
    "admin-app-tiered-prize-structure"
  );

  return (
    <AuthorizedAdminPageWrapper resource="draw">
      <PageLayout maxContentWidth={MAX_CONTENT_WIDTH}>
        {adminAppScheduleDrawV2Enabled ? (
          <ScheduleNewDrawV2PageContents />
        ) : (
          <ScheduleNewDrawPageContents />
        )}
      </PageLayout>
    </AuthorizedAdminPageWrapper>
  );
}
