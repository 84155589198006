import AddIcon from "@material-ui/icons/Add";

import { Button } from "@rewards-web/shared/components/button";

import { RightDrawerBaseCard } from "../../../../../shared/components/right-drawer/right-drawer-base-card";

interface MergeCandidateAddCardProps {
  onAdd(): void;
}

export function MergeCandidateAddCard({
  onAdd,
}: MergeCandidateAddCardProps): JSX.Element {
  return (
    <RightDrawerBaseCard>
      <Button
        startIcon={<AddIcon />}
        label="Additional Application"
        size="medium"
        variant="outlined"
        onClick={onAdd}
      />
    </RightDrawerBaseCard>
  );
}
