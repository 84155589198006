/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Form } from "@rewards-web/shared/components/form";
import { Typography } from "@rewards-web/shared/components/typography";
import { HhaxCaribouUserSyncFiltersInput } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { HhaxAutoSyncFilters } from "../../../../../../../shared/modules/employee-auto-sync/components/configure-hhax-employee-auto-sync/hhax-auto-sync-filters";
import { useHhaxCaregiverFilterOptionsQuery } from "../../../../../../../shared/modules/employee-auto-sync/components/configure-hhax-employee-auto-sync/hhax-caregiver-filter-options.generated";
import { HHAXAutoSyncCaregiverPreviewTable } from "../../../../../../../shared/modules/employee-auto-sync/components/configure-hhax-employee-auto-sync/hhax-caregiver-preview-table";
import { HHAXAutoSyncCaregiverTitleTotal } from "../../../../../../../shared/modules/employee-auto-sync/components/configure-hhax-employee-auto-sync/hhax-caregiver-title-total";
import {
  HhaxCaregiversToSyncQueryVariables,
  useHhaxCaregiversToSyncQuery,
} from "../../../../../../../shared/modules/employee-auto-sync/components/configure-hhax-employee-auto-sync/hhax-caregivers-to-sync.generated";
import { AdminOnboardingCard } from "../../../../components/onboarding-card/onboarding-card";
import { AdminOnboardingCardContent } from "../../../../components/onboarding-card/onboarding-card-content";
import { AdminOnboardingCardHeader } from "../../../../components/onboarding-card/onboarding-card-header";
import { AdminOnboardingStepActions } from "../../../../components/step-actions";

const ITEMS_PER_PAGE = 6;

interface OnboardingEmployeeAutoSyncEditorProps {
  onSubmit(filters: HhaxCaribouUserSyncFiltersInput): void;
  initialFilters?: HhaxCaribouUserSyncFiltersInput;
  integrationId: string;
}

export function OnboardingEmployeeAutoSyncEditor({
  onSubmit,
  initialFilters,
  integrationId,
}: OnboardingEmployeeAutoSyncEditorProps) {
  const navigate = useNavigate();
  const form = useForm();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [filters, setFilters] = useState<HhaxCaribouUserSyncFiltersInput>(
    initialFilters ?? {
      // these will be initialized when the options load:
      disciplines: undefined,
      officeIds: undefined,
      branchIds: undefined,
      teamIds: undefined,
      hasCompletedFirstShift: undefined,
    }
  );

  const filterOptionsQuery = useHhaxCaregiverFilterOptionsQuery({
    onError: reportError,
    variables: {
      hhaxIntegrationId: integrationId,
    },
  });

  const queryVariables: HhaxCaregiversToSyncQueryVariables = useMemo(
    () => ({
      hhaxIntegrationId: integrationId,
      limit: ITEMS_PER_PAGE,
      offset: currentPageIndex * ITEMS_PER_PAGE,
      filters,
    }),
    [integrationId, currentPageIndex, filters]
  );

  const caregiversToSyncQuery = useHhaxCaregiversToSyncQuery({
    onError: reportError,
    variables: queryVariables,
  });

  if (filterOptionsQuery.error || caregiversToSyncQuery.error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  const listLoading = filterOptionsQuery.loading;
  const total = caregiversToSyncQuery.data?.caregiversToSync?.total;
  const items = caregiversToSyncQuery.data?.caregiversToSync?.items ?? [];
  const filterOptions = filterOptionsQuery.data?.filterOptions;

  const onPrevious = () => {
    // navigate back to main integrations screen
    navigate("/onboarding/employee-auto-sync");
  };

  return (
    <Form
      onSubmit={form.handleSubmit(() => onSubmit(filters))}
      submitting={form.formState.isSubmitting}
    >
      <AdminOnboardingCard
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <AdminOnboardingCardHeader title="HHAeXchange Integration" />
        <AdminOnboardingCardContent>
          <Typography variant="body" color="grey.800">
            Select filters to determine which employees are eligible for the
            rewards program. When you launch, Caribou will automatically invite
            employees based on your selected filters. New employees that start
            after the launch date will be invited if they match these filters.
          </Typography>
        </AdminOnboardingCardContent>
      </AdminOnboardingCard>

      <HHAXAutoSyncCaregiverPreviewTable
        integrationId={integrationId}
        itemsPerPage={ITEMS_PER_PAGE}
        currentPageIndex={currentPageIndex}
        onCurrentPageIndexChange={setCurrentPageIndex}
        loading={listLoading}
        items={items}
        total={total}
        title={
          <>
            <HHAXAutoSyncCaregiverTitleTotal
              total={total}
              integrationId={integrationId}
              hideIcon
              hideActiveCaregiverInfoTooltip
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(2)};
              `}
            />

            <HhaxAutoSyncFilters
              value={filters}
              onChange={setFilters}
              filterOptions={filterOptions}
              // only initialize values if initial filter values aren't provided
              initializeAllPossibleValuesOnOptionsLoad={!initialFilters}
              integrationId={integrationId}
            />
          </>
        }
      />

      <AdminOnboardingStepActions
        disableSkip
        disableContinue={items.length === 0}
        submitButtonLabel="Save & Continue"
        onPrevious={onPrevious}
      />
    </Form>
  );
}
