/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

interface DotProps {
  color: string;
  size: number;
  marginRight?: number;
}

export function Dot({ color, size, marginRight }: DotProps) {
  return (
    <div
      css={css`
        content: "";
        background-color: ${color};
        width: ${size}px;
        height: ${size}px;
        border-radius: 1000px;
        margin-right: ${marginRight ?? 0}px;
      `}
    />
  );
}
