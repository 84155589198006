import { useEffect, useState } from "react";

import { ImageFieldSupportedMimeType } from "../components/image-field";

interface ImagePreviewSrcReturnValues {
  imageUrl: string | null;
  loading: boolean;
}

const SUPPORTED_IMAGE_MIME_TYPES = ["image/jpeg", "image/png"] as const;

export function useImagePreviewSrc(
  image: File | string | null
): ImagePreviewSrcReturnValues {
  const [loadingSelectedImageUrl, setLoadingSelectedImageUrl] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);

  useEffect(() => {
    if (
      image instanceof File &&
      SUPPORTED_IMAGE_MIME_TYPES.includes(
        image.type as ImageFieldSupportedMimeType
      )
    ) {
      setLoadingSelectedImageUrl(true);
      const reader = new FileReader();
      reader.onload = (e) => {
        setLoadingSelectedImageUrl(false);
        setSelectedImageUrl(e.target!.result as string);
      };
      reader.readAsDataURL(image);
    }
  }, [image]);

  const selectedFileUrl = (() => {
    if (image instanceof File) {
      return selectedImageUrl ?? null;
    }

    return image;
  })();

  return { imageUrl: selectedFileUrl, loading: loadingSelectedImageUrl };
}
