import {
  CandidateRecruitmentStepName,
  ReferralRewardStructureReferralRetentionItemDurationUnit,
} from "@rewards-web/shared/graphql-types";

import { RewardsStructureQuery } from "../referrals-structure-table/rewards-structure.generated";

const EDITABLE_RECRUITMENT_STEPS = new Set<CandidateRecruitmentStepName>([
  CandidateRecruitmentStepName.ApplicationSubmitted,
  CandidateRecruitmentStepName.CompletedOrientation,
  CandidateRecruitmentStepName.CompletedFirstShift,
]);

const EDITABLE_RETENTION_MILESTONE_HOURS = new Set<number>([100, 300]);

export function referralStructureIsEditable(
  structure: RewardsStructureQuery["getMyRewardsOrganization"]["referralRewardStructure"]
) {
  return (
    structure.items.every((item) => {
      switch (item.__typename) {
        case "ReferralRewardStructureJobShareItem":
          return true; // we ignore job share items - it isn't available to edit
        case "ReferralRewardStructureReferralRecruitmentItem":
          // only support recruitment steps with a reward, and are editable
          return item.reward && EDITABLE_RECRUITMENT_STEPS.has(item.step);
        case "ReferralRewardStructureReferralRetentionItem":
          return (
            item.duration.unit ===
              ReferralRewardStructureReferralRetentionItemDurationUnit.Hours &&
            EDITABLE_RETENTION_MILESTONE_HOURS.has(item.duration.amount)
          );
        default:
          throw new Error(
            `Unexpected referral structure item type ${item.__typename}`
          );
      }
    }) &&
    Array.from(EDITABLE_RECRUITMENT_STEPS).every((editableRecruitmentStep) =>
      structure.items.some(
        (item) =>
          item.__typename ===
            "ReferralRewardStructureReferralRecruitmentItem" &&
          item.step === editableRecruitmentStep
      )
    ) &&
    Array.from(EDITABLE_RETENTION_MILESTONE_HOURS).every((hours) =>
      structure.items.some(
        (item) =>
          item.__typename === "ReferralRewardStructureReferralRetentionItem" &&
          item.duration.unit ===
            ReferralRewardStructureReferralRetentionItemDurationUnit.Hours &&
          item.duration.amount === hours
      )
    )
  );
}
