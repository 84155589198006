/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { format } from "date-fns";
import { Control, FieldValues, Path, UseFormSetValue } from "react-hook-form";

import { Alert } from "@rewards-web/shared/components/alert";
import { Card } from "@rewards-web/shared/components/card/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { RewardsOrganizationAutomaticRecognitionBudgetFrequency } from "@rewards-web/shared/graphql-types";
import { AppTheme } from "@rewards-web/shared/style/types";

import { BudgetDefaultPointsField } from "../budget-default-points-field";

interface AdminBudgetProps<T extends FieldValues> {
  firstName: string;
  control: Control<T>;
  fieldNames: {
    defaultBudgetPoints: Path<T>;
    budgetPointsAccessEnabled: Path<T>;
  };
  setValue: UseFormSetValue<T>;
  canSendRecognition: boolean;
  canOverrideBudget: boolean;
  handleGoToPermissionsClick: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
  recognitionBudgetsEnabled?: boolean;
  pointsPerDollar?: number;
  recognitionBudgetsFrequency?: RewardsOrganizationAutomaticRecognitionBudgetFrequency;
}

const RECOGNITION_BUDGETS_FREQUENCY: Record<string, number> = {
  NONE: 0,
  MONTHLY: 1,
};

export function AdminBudget<T extends FieldValues>({
  firstName,
  control,
  fieldNames,
  setValue,
  canSendRecognition,
  canOverrideBudget,
  handleGoToPermissionsClick,
  recognitionBudgetsEnabled,
  pointsPerDollar,
  recognitionBudgetsFrequency,
}: AdminBudgetProps<T>): JSX.Element {
  const nextDateForFrequency = (() => {
    const now = new Date();

    if (!recognitionBudgetsFrequency) {
      return "";
    }

    if (
      recognitionBudgetsFrequency ===
        RewardsOrganizationAutomaticRecognitionBudgetFrequency.None ||
      RECOGNITION_BUDGETS_FREQUENCY[recognitionBudgetsFrequency] === undefined
    ) {
      return "";
    }

    return format(
      new Date(
        now.getFullYear(),
        now.getMonth() +
          RECOGNITION_BUDGETS_FREQUENCY[recognitionBudgetsFrequency],
        1
      ),
      "LLLL d, y"
    );
  })();

  return (
    <>
      {recognitionBudgetsEnabled &&
        canSendRecognition &&
        recognitionBudgetsFrequency &&
        recognitionBudgetsFrequency !==
          RewardsOrganizationAutomaticRecognitionBudgetFrequency.None &&
        pointsPerDollar && (
          <Card
            css={(theme: AppTheme) => css`
              max-width: 861px;
              padding: ${theme.spacing(4)};
              margin-bottom: ${theme.spacing(3)};
            `}
          >
            <Typography
              variant="h3"
              component="h2"
              color="textPrimary"
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(1)};
              `}
            >
              Budget
            </Typography>

            <Typography variant="body" color="grey.800">
              This admin’s budget will repeat{" "}
              {recognitionBudgetsFrequency.toLocaleLowerCase()} starting on{" "}
              {nextDateForFrequency}.
            </Typography>

            <BudgetDefaultPointsField
              control={control}
              name={fieldNames.defaultBudgetPoints}
              enabledSwitchName={fieldNames.budgetPointsAccessEnabled}
              setValue={setValue}
              pointsPerDollar={pointsPerDollar}
            />

            <Alert
              css={(theme: AppTheme) => css`
                margin-top: ${theme.spacing(3)};
              `}
              severity="info"
              title={
                canOverrideBudget
                  ? "Can override budget"
                  : "Cannot override budget"
              }
              message={[
                `${firstName || "Admin"} ${
                  canOverrideBudget
                    ? "has the ability to override their budget."
                    : "cannot override their budget."
                } If you want to change this, go to `,
                <strong
                  css={(theme: AppTheme) => css`
                    color: ${theme.palette.primary.main};
                    cursor: pointer;
                  `}
                  onClick={handleGoToPermissionsClick}
                >
                  Permissions
                </strong>,
                " to modify this setting.",
              ]}
            />
          </Card>
        )}
    </>
  );
}
