import { useEffect } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { useTrack } from "@rewards-web/shared/modules/analytics";

interface AddEmployeeAlayaCareEnabledProps {
  onClose(): void;
  attemptedAction: string;
}

export function AlayaCareIntegrationEnabledAlert({
  onClose,
  attemptedAction,
}: AddEmployeeAlayaCareEnabledProps) {
  const track = useTrack();
  useEffect(() => {
    track("Viewed alayacare integration enabled alert", {
      attemptedAction,
    });
  }, [track, attemptedAction]);

  return (
    <>
      <ModalTitle>Disabled due to AlayaCare integration</ModalTitle>
      <ModalContent>
        <Alert
          severity="info"
          message={
            <>
              Because your system is integrated with AlayaCare, you are not able
              to perform this operation. Please go to the AlayaCare dashboard
              and make the desired changes to the corresponding AlayaCare
              employee, and it will automatically be synchronized to Caribou
              Rewards.
              <br />
              <br />
              Contact{" "}
              <a href={`mailto:${encodeURI("help+admin@caribou.care")}`}>
                help+admin@caribou.care
              </a>{" "}
              if you have any questions or concerns.
            </>
          }
        />
      </ModalContent>
      <ModalActions>
        <Button color="primary" label="Ok" onClick={onClose} />
      </ModalActions>
    </>
  );
}
