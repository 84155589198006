import { SVGProps } from "react";

export function StatsShareIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={16}
      fill="none"
      {...props}
    >
      <path
        fill={props.fill}
        d="M13.083.017H3.938A3.185 3.185 0 0 0 .761 3.2v9.138a3.179 3.179 0 0 0 3.177 3.177h9.145a3.18 3.18 0 0 0 3.178-3.177V3.201A3.185 3.185 0 0 0 13.082.017ZM11.43 13.21a1.871 1.871 0 0 1-1.867-1.866c0-.117.013-.227.033-.33L6.878 9.324a2.16 2.16 0 0 1-1.176.342 2.222 2.222 0 1 1 0-4.443c.522 0 1.007.187 1.388.49l1.815-1.136a1.867 1.867 0 1 1 3.694-.38c0 1.026-.84 1.865-1.866 1.865-.426 0-.82-.142-1.137-.387l-1.782 1.11c.07.214.103.434.103.66 0 .316-.07.626-.187.897l2.512 1.563a1.867 1.867 0 1 1 1.188 3.307Z"
      />
    </svg>
  );
}
