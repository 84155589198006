import { sortBy } from "lodash";

import { DrawStatusOnSchedule } from "@rewards-web/shared/graphql-types";
import { AppTheme } from "@rewards-web/shared/style/types";

import { DrawListDataQuery } from "./draw-list-data.generated";

type DrawFromQuery = DrawListDataQuery["getAllDraws"][number];
export type DrawSection =
  | "live"
  | "awaitingWinnerApproval"
  | "scheduled"
  | "completed"
  | "unknown";

type PrizesListType = {
  prizePointValue: string;
  numberOfWinners: string;
}[];

export function validateNumberOfWinners(
  value: string,
  index: number,
  INTEGERS_REGEX: RegExp,
  MIN_NUM_WINNERS: number,
  MAX_NUM_ULTIMATE_WINNERS: number,
  MAX_NUM_SILVER_WINNERS: number,
  MAX_NUM_BONUS_WINNERS: number
): string | undefined {
  if (value !== "") {
    if (!value.match(INTEGERS_REGEX)) {
      return "You can only enter numbers (no decimals)";
    }
    if (parseInt(value, 10) < MIN_NUM_WINNERS) {
      return "Number of winners must be greater than 0";
    }
  }
  if (index === 0 && parseInt(value, 10) > MAX_NUM_ULTIMATE_WINNERS) {
    return `Number of winners must be ${MAX_NUM_ULTIMATE_WINNERS} or less`;
  }
  if (index === 1 && parseInt(value, 10) > MAX_NUM_SILVER_WINNERS) {
    return `Number of winners must be ${MAX_NUM_SILVER_WINNERS} or less`;
  }
  if (index === 2 && parseInt(value, 10) > MAX_NUM_BONUS_WINNERS) {
    return `Number of winners must be ${MAX_NUM_BONUS_WINNERS} or less`;
  }
}

export function validatePrizeValue(
  value: string,
  index: number,
  INTEGERS_REGEX: RegExp,
  MIN_POINTS: number,
  MAX_POINTS: number,
  prizesList: PrizesListType
): string | undefined {
  if (value !== "") {
    if (!value.match(INTEGERS_REGEX)) {
      return "You can only enter numbers (no decimals)";
    }
    if (parseInt(value, 10) < MIN_POINTS) {
      return `Point value must be greater than ${MIN_POINTS}`;
    }
    if (parseInt(value, 10) > MAX_POINTS) {
      return `Point value must be ${MAX_POINTS} or less`;
    }
  }
  if (
    index === 1 &&
    parseInt(value, 10) >= parseInt(prizesList[0].prizePointValue, 10)
  ) {
    return `Second Prize must be less than the First prize`;
  }
  if (
    index === 2 &&
    parseInt(value, 10) >= parseInt(prizesList[1].prizePointValue, 10)
  ) {
    return `Third Prize must be less than the Second prize`;
  }
}

export function groupDrawsBySection(allDraws: DrawFromQuery[]) {
  return sortBy(allDraws, (draw) => draw.scheduledStartDate).reduce<{
    awaitingWinnerApproval: DrawFromQuery[];
    live: DrawFromQuery[];
    scheduled: DrawFromQuery[];
    completed: DrawFromQuery[];
  }>(
    (prev, draw) => {
      switch (draw.scheduleStatus) {
        case DrawStatusOnSchedule.Live:
          return {
            ...prev,
            live: [...prev.live, draw],
          };
        case DrawStatusOnSchedule.Scheduled:
          return {
            ...prev,
            scheduled: [...prev.scheduled, draw],
          };
        case DrawStatusOnSchedule.Completed:
          if (draw.pendingWinnerApproval) {
            return {
              ...prev,
              awaitingWinnerApproval: [...prev.awaitingWinnerApproval, draw],
            };
          }

          return {
            ...prev,
            completed: [...prev.completed, draw],
          };
        default:
          return prev;
      }
    },
    {
      awaitingWinnerApproval: [],
      live: [],
      scheduled: [],
      completed: [],
    }
  );
}

export function getDrawSectionTitleAndColor(
  section: DrawSection,
  theme: AppTheme
) {
  switch (section) {
    case "live":
      return {
        title: "Live",
        dotColor: theme.palette.success.main,
      };
    case "awaitingWinnerApproval":
      return {
        title: "Awaiting winner approval",
        dotColor: theme.palette.error.main,
      };
    case "scheduled":
      return {
        title: "Scheduled",
        dotColor: theme.palette.primary.main,
      };
    case "completed":
      return {
        title: "Completed",
        dotColor: theme.palette.gold.light,
      };
    default:
      // unknown draw status
      return {
        title: "",
        dotColor: "",
      };
  }
}

export function getPrizeTierName(index: number): string {
  switch (index) {
    case 0:
      return "🏆 First prize";
    case 1:
      return "🥈 Second prize";
    case 2:
      return "🥉 Third prize";
    default:
      return "🏆 First prize";
  }
}
export function getPrizeTierNamewithoutMedal(index: number): string {
  switch (index) {
    case 0:
      return "first prize";
    case 1:
      return "second prize";
    case 2:
      return "third prize";
    default:
      return "first prize";
  }
}

const ones = [
  "",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
];
const teens = [
  "ten",
  "eleven",
  "twelve",
  "thirteen",
  "fourteen",
  "fifteen",
  "sixteen",
  "seventeen",
  "eighteen",
  "nineteen",
];
const tens = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];
const thousands = ["", "thousand", "million", "billion", "trillion"];

export function numberToWords(num: number): string {
  if (num === 0) return "zero";

  let word = "";
  let i = 0;

  while (num > 0) {
    if (num % 1000 !== 0) {
      word = convertHundreds(num % 1000) + thousands[i] + " " + word;
    }
    num = Math.floor(num / 1000);
    i++;
  }

  return word.trim();
}

function convertHundreds(num: number): string {
  let word = "";

  if (num > 99) {
    word += ones[Math.floor(num / 100)] + " hundred ";
    num %= 100;
  }

  if (num > 19) {
    word += tens[Math.floor(num / 10)] + " ";
    num %= 10;
  }

  if (num > 0) {
    word += num < 10 ? ones[num] : teens[num - 10];
  }

  return word.trim() + " ";
}
