import { ToggleButtons } from "../../../../../shared/components/toggle-buttons";

export enum GiveRecognitionPaths {
  SearchEmployees = "/recognition/give-points",
  UploadFile = "/recognition/give-points/upload",
}

export function GiveRecognitionTabs(): JSX.Element {
  return (
    <ToggleButtons
      ariaLabel="Give recognition options"
      toggleButtons={[
        {
          label: "Search employees",
          path: GiveRecognitionPaths.SearchEmployees,
        },
        {
          label: "Upload bulk recognition file",
          path: GiveRecognitionPaths.UploadFile,
        },
      ]}
    />
  );
}
