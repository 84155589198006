/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Control, FieldValues, get, useFormState, Path } from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { extractNumber } from "@rewards-web/shared/lib/extract-number";
import { AppTheme } from "@rewards-web/shared/style/types";

interface EVVRuleTimeFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export function EVVRuleTimeField<T extends FieldValues>({
  control,
  name,
}: EVVRuleTimeFieldProps<T>) {
  const formState = useFormState({ control });
  const endAdornmentLabel = (() => {
    if (name.includes("ScheduledEnd") || name.includes("ScheduledStart")) {
      if (name.includes("minutesLessThan")) {
        return "Before";
      } else if (name.includes("minutesGreaterThan")) {
        return "After";
      }
    } else if (name.includes("ScheduledDuration")) {
      if (name.includes("minutesLessThan")) {
        return "Less";
      } else if (name.includes("minutesGreaterThan")) {
        return "More";
      }
    }
  })();

  return (
    <TextField
      css={(theme: AppTheme) => css`
        max-width: 450px;
        margin-bottom: 0;

        input::placeholder {
          font-size: 13px !important;
          color: ${theme.palette.grey[800]};
        }
      `}
      placeholder="No rule applied if left empty"
      {...control.register(name, {
        validate: (value: string) => {
          const numericValue = extractNumber(value);

          if (isNaN(numericValue) || numericValue < 0) {
            return "Value should be 0 or greater number";
          }
          if (isNaN(numericValue) || numericValue >= 60) {
            return "Value should be less than 60 minutes";
          }
        },
      })}
      label=""
      hideLabel
      endAdornment={
        endAdornmentLabel ? (
          <Typography variant="body" fontWeight={600} color="textPrimary">
            {endAdornmentLabel}
          </Typography>
        ) : null
      }
      error={get(formState.errors, name)}
    />
  );
}
