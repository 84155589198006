import { SVGProps } from "react";

function RightArrow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={11}
      height={8}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 3.368h9.158v1.181H0V3.368Z" fill={props.color} />
      <path
        d="M6.618 7.918 5.79 7.09 8.922 3.96 5.79.828 6.618 0l3.958 3.959-3.958 3.959Z"
        fill={props.color}
      />
    </svg>
  );
}

export default RightArrow;
