import { Autocomplete, TextField } from "@mui/material";

import {
  ControlledFormFieldProps,
  StylableProps,
} from "@rewards-web/shared/types";

export interface MultiSelectAutocompleteOption {
  /**
   * label for the given option
   */
  label: string;
  /**
   * value for the given option, should be unique
   */
  value: string;
}

export interface MultiSelectAutocompleteProps
  extends StylableProps,
    ControlledFormFieldProps {
  /**
   * label for the main component
   */
  label: string;
  size: "small" | "medium";
  options: Array<MultiSelectAutocompleteOption>;
  initialValue?: Array<MultiSelectAutocompleteOption>;
  /**
   * placeholder text for the type-ahead search
   */
  placeholder?: string;
}

export function MultiSelectAutocomplete({
  label,
  size,
  options,
  initialValue,
  placeholder,
  onChange,
  className,
  error,
}: MultiSelectAutocompleteProps) {
  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      className={className}
      size={size}
      options={options}
      onChange={(_event, value) => onChange?.(value)}
      defaultValue={initialValue ?? []}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          color={Boolean(error) ? "error" : "primary"}
          error={Boolean(error)}
          helperText={error?.message}
        />
      )}
      ChipProps={{ color: Boolean(error) ? "error" : "primary" }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
    />
  );
}
