import { faUsers } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TotalHiresInsightMetric as TotalHiresInsightMetricType } from "@rewards-web/shared/graphql-types";

import { InsightMetric } from "../../../../components/insight-metric";

export interface AllTimeTotalHiresInsightMetricProps {
  totalHiresInsightMetric: TotalHiresInsightMetricType | undefined;
}

export function AllTimeTotalHiresInsightMetric({
  totalHiresInsightMetric,
}: AllTimeTotalHiresInsightMetricProps) {
  const totalHires = totalHiresInsightMetric?.hiredCandidateCount;

  return (
    <InsightMetric
      label="Total hires"
      icon={<FontAwesomeIcon icon={faUsers} />}
      tooltipText="The number of hires made through the referral program, including manually added candidates by admins."
      value={
        totalHires !== undefined
          ? { type: "number", number: totalHires }
          : {
              type: "loading",
            }
      }
      percentPointChange={null}
    />
  );
}
