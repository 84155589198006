import { RewardsAdminPermissionsType } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";

import { usePermissionsQuery } from "../permissions/hooks/use-permissions-query";
import { useEmployeeAutoSyncAvailableDataQuery } from "./employee-auto-sync-available-data.generated";

export function useEmployeeAutoSyncAvailableQuery(opts?: {
  fetchPolicy?: "cache-and-network" | "cache-first";
  skip?: boolean;
}) {
  const permissions = usePermissionsQuery();

  const fullAccessAdmin =
    permissions.data?.getMyRewardsAdminUser.permissions.type ===
    RewardsAdminPermissionsType.FullAccess;

  const query = useEmployeeAutoSyncAvailableDataQuery({
    onError: reportError,
    fetchPolicy: opts?.fetchPolicy,
    skip: !fullAccessAdmin,
  });

  const data = (() => {
    if (!permissions.data) {
      return null; // null while loading permissions
    }

    if (!fullAccessAdmin) {
      // only full access admins / superusers can see/manage auto-sync
      return { enabled: false };
    }

    if (!query.data) {
      return null; // null while loading auto sync data
    }

    return {
      // auto-sync is considered 'available' if there is at least one HHAX integration
      enabled: query.data.hhaxIntegrations.length > 0,
    };
  })();

  return {
    error: query.error || permissions.error,
    loading: query.loading || permissions.loading,
    data,
  };
}
