/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

import { SmallScreenIcon } from "./small-screen-icon";

export interface SmallScreenWarningModalProps {
  open: boolean;
  onClose(): void;
}

export function SmallScreenWarningModal({
  open,
  onClose,
}: SmallScreenWarningModalProps) {
  return (
    <Modal open={open} onClose={onClose} width="500px">
      <ModalTitle
        css={css`
          text-align: center;
        `}
      >
        Minimum Screen Size
      </ModalTitle>
      <ModalContent>
        <SmallScreenIcon
          css={(theme: AppTheme) => css`
            display: block;
            margin: 0 auto;
            margin-bottom: ${theme.spacing(2)};
          `}
        />
        <Typography
          variant="body"
          gutterBottom
          css={css`
            text-align: center;
          `}
        >
          The manager dashboard is difficult to use on a mobile phone or tablet.
          We recommend using a computer.
        </Typography>
        <Typography
          variant="body"
          css={css`
            text-align: center;
          `}
        >
          If you have questions, contact us at{" "}
          <a href="help+admin@caribou.care">help+admin@caribou.care</a>.
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button color="primary" onClick={onClose} label="Okay" />
      </ModalActions>
    </Modal>
  );
}
