import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type MergeCandidatesMutationVariables = Types.Exact<{
  fromCandidateIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  toCandidateId: Types.Scalars['ID'];
}>;


export type MergeCandidatesMutation = (
  { __typename?: 'Mutation' }
  & { mergeCandidates: (
    { __typename?: 'Candidate' }
    & Pick<Types.Candidate, 'id'>
  ) }
);


export const MergeCandidatesDocument = gql`
    mutation MergeCandidates($fromCandidateIds: [ID!]!, $toCandidateId: ID!) {
  mergeCandidates(
    fromCandidateIds: $fromCandidateIds
    toCandidateId: $toCandidateId
  ) {
    id
  }
}
    `;
export type MergeCandidatesMutationFn = Apollo.MutationFunction<MergeCandidatesMutation, MergeCandidatesMutationVariables>;

/**
 * __useMergeCandidatesMutation__
 *
 * To run a mutation, you first call `useMergeCandidatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeCandidatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeCandidatesMutation, { data, loading, error }] = useMergeCandidatesMutation({
 *   variables: {
 *      fromCandidateIds: // value for 'fromCandidateIds'
 *      toCandidateId: // value for 'toCandidateId'
 *   },
 * });
 */
export function useMergeCandidatesMutation(baseOptions?: Apollo.MutationHookOptions<MergeCandidatesMutation, MergeCandidatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MergeCandidatesMutation, MergeCandidatesMutationVariables>(MergeCandidatesDocument, options);
      }
export type MergeCandidatesMutationHookResult = ReturnType<typeof useMergeCandidatesMutation>;
export type MergeCandidatesMutationResult = Apollo.MutationResult<MergeCandidatesMutation>;
export type MergeCandidatesMutationOptions = Apollo.BaseMutationOptions<MergeCandidatesMutation, MergeCandidatesMutationVariables>;