export function ShareIcon() {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.65625 4.78125H12.0664L8.59375 1.80078C8.32031 1.55469 8.29297 1.14453 8.51172 0.871094C8.75781 0.597656 9.16797 0.570312 9.44141 0.789062L14.2539 4.91797C14.3906 5.08203 14.5 5.24609 14.5 5.4375C14.5 5.62891 14.3906 5.82031 14.2539 5.95703L9.44141 10.0859C9.33203 10.1953 9.16797 10.25 9.03125 10.25C8.83984 10.25 8.64844 10.168 8.51172 10.0039C8.29297 9.73047 8.32031 9.32031 8.59375 9.07422L12.0664 6.09375H4.65625C3.07031 6.09375 1.8125 7.35156 1.8125 8.91016V12.1641C1.8125 12.6016 1.51172 12.875 1.15625 12.875C0.773438 12.875 0.5 12.6016 0.5 12.2188V8.96484C0.5 6.66797 2.35938 4.78125 4.65625 4.78125Z"
        fill="#444444"
      />
    </svg>
  );
}
