/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { Divider } from "@material-ui/core";
import {
  forwardRef,
  Fragment,
  ReactNode,
  useState,
  isValidElement,
  cloneElement,
} from "react";

import {
  ReactionsSummary,
  ReactionType,
} from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { AppTheme } from "@rewards-web/shared/style/types";

import { Card } from "../../card";
import { Skeleton, SkeletonProps } from "../../skeleton";
import { Typography } from "../../typography";
import { SocialPostReaction } from "../social-post-reaction";

export type BaseSocialPostCardImage = {
  url: string;
  overlayText?: {
    text: string;

    /**
     * @default "white"
     */
    textColor?: string;

    /**
     * Overrides the overlay tint color
     *
     * @default "black"
     */
    imageTintColor?: string;

    /**
     * @default middle_centered
     */
    position?: "center" | "top" | "right" | "left";
  };
} | null;

export interface BaseSocialPostCardProps {
  /**
   * If `null`, a skeleton state will be rendered
   */
  data: {
    logoUrl: string | null;
    publishedByText: string;
    publishedAtText: string;
    tagText: string;
    tagColor: "gold" | "primary";
    messageText: string | ReactNode;
    image: BaseSocialPostCardImage | null;
    postReactions: ReactionsSummary[];
    myReactions: ReactionsSummary[];
    onMyReactionChange: (reactionType: ReactionType, added: boolean) => void;
    showMoreText: string;
    showLessText: string;
    onShowMoreExpanded?: () => void;
    onShowMoreCollapsed?: () => void;
  } | null;

  /**
   * By default, the skeleton will be animated. Set to `false` to disable.
   */
  skeletonProps?: Pick<SkeletonProps, "animated">;
}

const MAX_MESSAGE_CHARS = 250;

/**
 * Parses the given node to extract concatenated markup text only.
 * Using textContent like API due to its performance gains,
 * and security against parsing HTML this preventing XSS.
 *
 * @param node ReactNode to traverse recursively
 * @returns a long string that contains all the text of react node, similar to Node.textContent
 * @see Node.textContent
 */
function getTextContent(node: ReactNode): string {
  if (node === null || node === undefined) {
    return "";
  }

  if (typeof node === "string" || typeof node === "number") {
    return String(node);
  }

  if (Array.isArray(node)) {
    return node.map(getTextContent).join("");
  }

  if (isValidElement(node)) {
    if (typeof node.type === "function") {
      throw new Error("Function components are not supported");
    } else {
      return getTextContent(node.props.children);
    }
  }

  // Handle other cases (boolean, etc.)
  return "";
}

/**
 * Utility function to truncate a ReactNode for a given maxLength.
 * Useful to control rendering large elements,
 * which can be used to expose API to show more.
 */
function truncateReactNode(node: ReactNode, maxLength: number): ReactNode {
  let currentLength = 0;

  const truncateNode = (child: ReactNode): ReactNode | null => {
    if (typeof child === "string") {
      const remainingLength = maxLength - currentLength;
      if (remainingLength <= 0) return null;

      const text = child.slice(0, remainingLength);
      currentLength += text.length;
      return text;
    }

    if (Array.isArray(child)) {
      const result = child.map((c) => truncateNode(c)).filter(Boolean);
      return result.length ? result : null;
    }

    if (isValidElement(child)) {
      const truncatedChildren = truncateNode(child.props.children);
      if (!truncatedChildren) return null;
      if (child.type === "function") {
        return null;
      }

      /**
       * Ideally we should use a render prop from the consumer
       *
       * Ref: https://react.dev/reference/react/cloneElement#alternatives
       * TBD(@shailscript): Consider refactoring
       */
      return cloneElement(child, {
        ...child.props,
        children: truncatedChildren,
      });
    }

    return null;
  };

  return truncateNode(node);
}

function NewLineFormattedText(props: { text: string }): JSX.Element {
  return (
    <>
      {props.text.split("\n").map((line, index) => (
        <Fragment key={index}>
          {line}
          {index < props.text.split("\n").length && (
            <div
              css={(theme: AppTheme) => css`
                content: "";
                height: ${theme.spacing(1)};
              `}
            />
          )}
        </Fragment>
      ))}
    </>
  );
}

export const BaseSocialPostCard = forwardRef(
  ({ data, skeletonProps }: BaseSocialPostCardProps, ref) => {
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = useState(false);

    const handleExpand = () => {
      setIsExpanded(true);
      data?.onShowMoreExpanded?.();
    };

    const handleCollapse = () => {
      setIsExpanded(false);
      data?.onShowMoreCollapsed?.();
    };

    /**
     * Utility function to determine if we should show full-text
     * or concatenated text on screen.
     */
    const renderMessageText = (
      message: ReactNode,
      showMoreText: string,
      showLessText: string
    ) => {
      let textContent: string | undefined;

      try {
        textContent = getTextContent(message);
      } catch (error) {
        textContent = undefined;
      }

      const shouldTruncate = textContent
        ? textContent.length > MAX_MESSAGE_CHARS
        : true;

      if (!shouldTruncate) {
        return typeof message === "string" ? (
          <NewLineFormattedText text={message} />
        ) : (
          message
        );
      }

      const displayContent = (() => {
        if (isExpanded) {
          return message;
        }

        if (typeof message === "string") {
          return `${message.slice(0, MAX_MESSAGE_CHARS)}`;
        } else {
          return truncateReactNode(message, MAX_MESSAGE_CHARS);
        }
      })();

      return (
        <>
          {typeof displayContent === "string" ? (
            <NewLineFormattedText text={displayContent} />
          ) : (
            displayContent
          )}

          <Typography
            variant="footnote"
            color={theme.palette.grey[800]}
            component="button"
            onClick={isExpanded ? handleCollapse : handleExpand}
            css={css`
              font-weight: bolder;
              background: none;
              border: none;
              padding: 0;
              cursor: pointer;
            `}
          >
            {isExpanded ? showLessText : `...${showMoreText}`}
          </Typography>
        </>
      );
    };

    const tagColor = ((): string => {
      if (!data) {
        return theme.palette.grey[500];
      }

      switch (data.tagColor) {
        case "gold":
          return theme.palette.gold.light;
        case "primary":
          return theme.palette.primary.main;
        default:
          assertNever(data.tagColor);
      }
    })();

    const cardPadding = theme.spacing(2.5);

    return (
      <Card
        ref={ref}
        variant="outlined"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <div
          css={css`
            padding: ${cardPadding};
          `}
        >
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              gap: ${theme.spacing(1)};
              margin-bottom: ${theme.spacing(1)};
              flex-wrap: wrap;
            `}
          >
            <div
              css={css`
                display: flex;
                gap: ${theme.spacing(1)};

                /* Ensure the 'tag' only wraps when it absolutely has to. This text can wrap first. */
                flex-basis: min-content;
                flex-grow: 1;
              `}
            >
              {!data && (
                <Skeleton
                  animated={skeletonProps?.animated ?? true}
                  width="50px"
                  height="50px"
                  css={css`
                    // ensure it displays as a circle
                    transform: initial;
                    border-radius: 100px;
                  `}
                />
              )}
              {data?.logoUrl && (
                <div
                  css={(theme: AppTheme) => css`
                    flex-shrink: 0;
                    background-image: url(${data.logoUrl});
                    background-size: 90%;
                    background-repeat: no-repeat;
                    background-position: center;
                    content: "";
                    width: 50px;
                    height: 50px;
                    border: 1px solid ${theme.palette.grey[300]};
                    border-radius: 100px;
                  `}
                />
              )}
              <div>
                <Typography variant="subtitle" color="black">
                  {data ? (
                    data.publishedByText
                  ) : (
                    <Skeleton
                      animated={skeletonProps?.animated ?? true}
                      width="100%"
                      height="28px"
                    />
                  )}
                </Typography>
                <Typography
                  variant="footnote"
                  color="grey.800"
                  css={css`
                    min-width: 120px;
                  `}
                >
                  {data ? (
                    data.publishedAtText
                  ) : (
                    <Skeleton
                      animated={skeletonProps?.animated ?? true}
                      width="100%"
                    />
                  )}
                </Typography>
              </div>
            </div>
            <div
              css={css`
                margin-right: -${cardPadding};
              `}
            >
              {data ? (
                <Typography
                  variant="caption"
                  css={css`
                    color: ${theme.palette.getContrastText(tagColor)};
                    background-color: ${tagColor};
                    display: block;
                    padding: 3px 5px;
                    text-transform: uppercase;
                  `}
                >
                  {data.tagText}
                </Typography>
              ) : (
                <Skeleton
                  animated={skeletonProps?.animated ?? true}
                  width="120px"
                  height="44px"
                  css={css`
                    margin-top: -10px;
                    border-radius: 0;
                  `}
                />
              )}
            </div>
          </div>
          <Typography variant="footnote" color="text.primary">
            {/* Respect newlines */}

            {!data && (
              <>
                <Skeleton
                  animated={skeletonProps?.animated ?? true}
                  width="100%"
                />
                <Skeleton
                  animated={skeletonProps?.animated ?? true}
                  width="100%"
                />
                <Skeleton
                  animated={skeletonProps?.animated ?? true}
                  width="75%"
                />
              </>
            )}

            {data &&
              renderMessageText(
                data.messageText,
                data.showMoreText,
                data.showLessText
              )}
          </Typography>

          {(!data || data?.image) && (
            <div
              css={(theme: AppTheme) => css`
                margin-top: ${theme.spacing(2)};
                position: relative;

                ${data &&
                css`
                  /* matches height of image, so overlay matches it */
                  height: 200px;
                  @media (min-width: 500px) {
                    height: 250px;
                  }
                `}
              `}
            >
              {!data && (
                <Skeleton
                  animated={skeletonProps?.animated ?? true}
                  width="100%"
                  css={css`
                    margin-top: -52px;
                    margin-bottom: -36px;
                    height: 250px;
                    @media (min-width: 500px) {
                      margin-top: -64px;
                      margin-bottom: -48px;
                      height: 310px;
                    }
                  `}
                />
              )}

              {data?.image?.url && (
                <img
                  src={data.image.url}
                  alt="Post"
                  // width/height are on the img rather than css,
                  // so it takes up the appropriate amount of space while loading
                  width="100%"
                  height="200px"
                  css={(theme: AppTheme) => css`
                    background-color: ${theme.palette
                      .grey[500]}; // while loading
                    object-fit: cover;
                    border-radius: 16px;

                    @media (min-width: 500px) {
                      height: 250px;
                    }
                  `}
                />
              )}

              {/* Darken image so we can show text, using provided tint color (or black) */}
              {data?.image?.overlayText && (
                <div
                  css={css`
                    background-color: ${data.image.overlayText.imageTintColor ??
                    "#000"};
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0.5;
                    content: "";
                    border-radius: 16px;
                    position: absolute;
                  `}
                />
              )}

              {/* image text overlay */}
              {data?.image?.overlayText && (
                <Typography
                  component="caption"
                  variant="h1"
                  color={data.image.overlayText.textColor ?? "white"}
                  css={css`
                    position: absolute;
                    font-weight: 800;
                    height: 100%;
                    left: ${theme.spacing(0)};
                    top: ${theme.spacing(0)};
                    right: ${theme.spacing(0)};
                    bottom: ${theme.spacing(0)};
                    padding: ${theme.spacing(3)} ${theme.spacing(2)};
                    display: flex;
                    ${(() => {
                      switch (data.image.overlayText.position) {
                        case "center":
                          return css`
                            align-items: center;
                          `;
                        case "top":
                          return css`
                            align-items: flex-start;
                          `;
                        case "right":
                          return css`
                            align-items: center;
                            max-width: 50%;
                            left: 50%;
                            text-align: right;
                          `;
                        case "left":
                          return css`
                            align-items: center;
                            right: 50%;
                            text-align: left;
                          `;
                      }
                    })()}
                    justify-content: center;
                    text-transform: uppercase;

                    @media (max-width: 420px) {
                      font-size: 1.5rem;
                      line-height: 1.7rem;
                    }
                  `}
                >
                  {data.image.overlayText.text}
                </Typography>
              )}
            </div>
          )}
        </div>

        <Divider />

        <div
          css={(theme: AppTheme) => css`
            padding: ${theme.spacing(2)};
            display: flex;
            align: center;
            gap: ${theme.spacing(1)};
          `}
        >
          {data?.postReactions.map((reaction) => (
            <SocialPostReaction
              key={reaction.type}
              type={reaction.type}
              count={reaction.numReactions}
              active={
                data.myReactions!.find(
                  (myReaction) => myReaction.type === reaction.type
                )?.numReactions === 1
              }
              onClick={data.onMyReactionChange!}
            />
          ))}
        </div>
      </Card>
    );
  }
);
