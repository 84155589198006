import { darken, lighten } from "@material-ui/core";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { Page } from "@rewards-web/shared/modules/page";
import { AppThemeProvider } from "@rewards-web/shared/style/theme";

import { NotFoundPage } from "../../shared/components/not-found-page";
import { NavigationContainer } from "../../shared/components/page-layout/navigation-container";
import { AdminsPage } from "./admins";
import { AddAdminPage } from "./admins/add-admin-page";
import { EditAdminPage } from "./admins/edit-admin-page";
import { CandidatesPage } from "./candidates";
import { DrawPage } from "./draw";
import { ApproveDrawWinnersPage } from "./draw/approve-draw-winners-page";
import { DrawDetailsPage } from "./draw/draw-details";
import { ScheduleNewDrawPage } from "./draw/schedule-new-draw-page";
import { EmployeesPage } from "./employees";
import { AccountsPage } from "./employees/accounts";
import { AutoSyncPage } from "./employees/auto-sync";
import { EnableHHAXAutoSyncPage } from "./employees/auto-sync/enable-hhax-auto-sync-page";
import { GroupsPage } from "./employees/groups";
import { EnableSmsMfa } from "./enable-sms-mfa";
import { GoalsPage } from "./goals";
import { HiringPage } from "./hiring";
import { AdminIndexPage } from "./index";
import { useAdminAppInitializer } from "./initializer";
import { InsightsPage } from "./insights";
import { JobsPage } from "./jobs";
import { LegacyHomePage } from "./legacy-home";
import { MomentsPage } from "./moments";
import { OnboardingPage } from "./onboarding";
import { ONBOARDING_STEP_PATH_BY_KEY } from "./onboarding/constants";
import { OnboardingIndex } from "./onboarding/index/index";
import { OnboardingBookKickoff } from "./onboarding/pages/book-kickoff";
import { OnboardingCaregiverInvitesPage } from "./onboarding/pages/caregiver-invites";
import { OnboardingCreateJobsPage } from "./onboarding/pages/create-jobs";
import { OnboardingEmployeeAutoSyncPage } from "./onboarding/pages/employee-auto-sync";
import { OnboardingEditEmployeeAutoSyncHHAXPage } from "./onboarding/pages/employee-auto-sync/hhax/edit-employee-auto-sync-hhax";
import { OnboardingEnableEmployeeAutoSyncHHAXPage } from "./onboarding/pages/employee-auto-sync/hhax/enable-employee-auto-sync-hhax";
import { OnboardingEVVRulesPage } from "./onboarding/pages/evv";
import { OnboardingIntroPage } from "./onboarding/pages/intro";
import { OnboardingJobSettingsPage } from "./onboarding/pages/job-settings";
import { OnboardingProfilePage } from "./onboarding/pages/profile";
import { OnboardingReferralsPage } from "./onboarding/pages/referrals";
import { RecognitionPointsPage } from "./recognition";
import { AwardedRecognitionPointsPage } from "./recognition/awarded-recognition-points-page";
import { GiveRecognitionPointsPage } from "./recognition/give-recognition-points-page";
import { RecognitionPointsIndexPage } from "./recognition/index-page";
import { MyRecognitionSummaryPage } from "./recognition/my-recognition-summary-page";
import { MyScheduledPointsPage } from "./recognition/my-scheduled-points-page";
import { RedemptionsPage } from "./redemptions";
import { SettingsPage } from "./settings";
import { SettingsCommunicationPage } from "./settings/pages/communication";
import { SettingsCoordinatorGoalsPage } from "./settings/pages/coordinator-goals";
import { BudgetUtilizationGoalConfigurationPage } from "./settings/pages/coordinator-goals/budget-utilization-goal-configuration-page";
import { SettingsEmployeeAccountsPage } from "./settings/pages/employee-accounts";
import { SettingsEVVRulesPage } from "./settings/pages/evv";
import { SettingsJobPostingPage } from "./settings/pages/job-posting";
import { SettingsRecognitionPage } from "./settings/pages/recognition";
import { SettingsRedemptionPage } from "./settings/pages/redemption";
import { SettingsReferralStructurePage } from "./settings/pages/referral-structure";
import { EditReferralStructurePage } from "./settings/pages/referral-structure/edit-referral-structure";
import { SurveysPage } from "./surveys";
import { PulseSurveysPage } from "./surveys/pulse-surveys";
import { SurveyQuestionDetailsPage } from "./surveys/pulse-surveys/question-details-page";

export function AuthenticatedRoutes(): JSX.Element | null {
  const {
    loading,
    error,
    whiteLabelConfig,
    mustEnableSmsMfa,
  } = useAdminAppInitializer();
  const evvRulesEnabled = useFeatureFlag("admin-app-evv-rules-temp");
  const homepageInsightsEnabled = useFeatureFlag(
    "admin-app-homepage-insights-page-enabled-temp"
  );

  if (error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  const titlePostfix =
    whiteLabelConfig?.rewardsProgramShortName ?? "Caribou Rewards";
  const agencyFaviconUrl = whiteLabelConfig?.faviconImageUrl ?? undefined;
  const whiteLabelPrimaryColor = whiteLabelConfig?.primaryColor;
  const whiteLabelSecondaryColor = whiteLabelConfig?.secondaryColor;
  const whiteLabelIconUrl = whiteLabelConfig?.rewardsPageTabIconUrl;
  if (loading) {
    return <PageLoadingState />;
  }
  const routes = (() => {
    if (mustEnableSmsMfa) {
      // force the user to visit /enable-sms-mfa page
      // to continue using the app
      return (
        <Routes>
          <Route
            path="/enable-sms-mfa"
            element={
              <Page
                browserTitle={`Enable MFA - ${titlePostfix} Admin`}
                analyticsPageName="SMS MFA"
                faviconUrl={agencyFaviconUrl}
              >
                <EnableSmsMfa />
              </Page>
            }
          />
          <Route path="*" element={<Navigate to="/enable-sms-mfa" replace />} />
        </Routes>
      );
    }

    return (
      <NavigationContainer iconUrl={whiteLabelIconUrl}>
        <Routes>
          <Route path="/" element={<AdminIndexPage />} />

          <Route path="/enable-sms-mfa" element={<Navigate to="/" replace />} />

          <Route
            path="/home"
            element={
              <Page
                browserTitle={`Home - ${titlePostfix} Admin`}
                analyticsPageName="Home"
                faviconUrl={agencyFaviconUrl}
              >
                {homepageInsightsEnabled ? (
                  <InsightsPage />
                ) : (
                  <LegacyHomePage />
                )}
              </Page>
            }
          />

          <Route path="/candidates/">
            <Route
              index
              element={
                <Page
                  browserTitle={`Candidates - ${titlePostfix} Admin`}
                  analyticsPageName="Candidates"
                  faviconUrl={agencyFaviconUrl}
                >
                  <CandidatesPage />
                </Page>
              }
            />
            <Route
              path=":id/details"
              element={
                <Page
                  browserTitle={`Candidate Details - ${titlePostfix} Admin`}
                  analyticsPageName="CandidateDetails"
                  faviconUrl={agencyFaviconUrl}
                >
                  <CandidatesPage />
                </Page>
              }
            />
          </Route>

          <Route
            path="/payments"
            element={<Navigate to="/redemptions" replace />}
          />
          <Route
            path="/redemptions"
            element={
              <Page
                browserTitle={`Redemptions - ${titlePostfix} Admin`}
                analyticsPageName="Redemptions"
                faviconUrl={agencyFaviconUrl}
              >
                <RedemptionsPage />
              </Page>
            }
          />

          {/* legacy redirect to recognition */}
          <Route
            path="/bonus-points"
            element={<Navigate to="/recognition" replace />}
          />

          <Route path="/recognition" element={<RecognitionPointsPage />}>
            <Route index element={<RecognitionPointsIndexPage />} />

            <Route
              path="awarded-points"
              element={
                <Page
                  browserTitle={`Recognition - Awarded Points - ${titlePostfix} Admin`}
                  analyticsPageName="BonusPoints"
                  faviconUrl={agencyFaviconUrl}
                >
                  <AwardedRecognitionPointsPage />
                </Page>
              }
            />

            <Route
              path="my-summary"
              element={
                <Page
                  browserTitle={`Recognition - My Summary - ${titlePostfix} Admin`}
                  analyticsPageName="RecognitionMySummary"
                  faviconUrl={agencyFaviconUrl}
                >
                  <MyRecognitionSummaryPage />
                </Page>
              }
            />

            <Route
              path="my-scheduled-points"
              element={
                <Page
                  browserTitle={`Recognition - My Scheduled Points - ${titlePostfix} Admin`}
                  analyticsPageName="RecognitionMyScheduledPoints"
                  faviconUrl={agencyFaviconUrl}
                >
                  <MyScheduledPointsPage />
                </Page>
              }
            />
          </Route>

          <Route
            path="/recognition/give-points/*"
            element={
              <Page
                browserTitle={`Recognition - Give Points - ${titlePostfix} Admin`}
                analyticsPageName="GiveRecognitionPoints"
                faviconUrl={agencyFaviconUrl}
              >
                <GiveRecognitionPointsPage />
              </Page>
            }
          />

          <Route path="/employees/*" element={<EmployeesPage />}>
            <Route
              index
              element={<Navigate to="/employees/accounts" replace />}
            />

            <Route
              path="accounts"
              element={
                <Page
                  browserTitle={`Employees - Accounts - ${titlePostfix} Admin`}
                  analyticsPageName="Employees"
                  faviconUrl={agencyFaviconUrl}
                >
                  <AccountsPage />
                </Page>
              }
            >
              <Route
                path="export"
                element={
                  <Page
                    browserTitle={`Export Employees - ${titlePostfix} Admin`}
                    analyticsPageName="ExportEmployees"
                    faviconUrl={agencyFaviconUrl}
                  >
                    <EmployeesPage />
                  </Page>
                }
              />

              <Route
                path=":id/edit"
                element={
                  <Page
                    browserTitle={`Edit Employee - ${titlePostfix} Admin`}
                    analyticsPageName="EditEmployee"
                    faviconUrl={agencyFaviconUrl}
                  >
                    <EmployeesPage />
                  </Page>
                }
              />
            </Route>

            <Route
              path="groups"
              element={
                <Page
                  browserTitle={`Employee Groups - ${titlePostfix} Admin`}
                  analyticsPageName="EmployeeGroups"
                  faviconUrl={agencyFaviconUrl}
                >
                  <GroupsPage />
                </Page>
              }
            />

            <Route
              path="auto-sync"
              element={
                <Page
                  browserTitle={`Employee Auto Sync - ${titlePostfix} Admin`}
                  analyticsPageName="EmployeeAutoSync"
                  faviconUrl={agencyFaviconUrl}
                >
                  <AutoSyncPage />
                </Page>
              }
            />
          </Route>

          <Route
            path="/employees/auto-sync/enable-hhax/:id"
            element={
              <Page
                browserTitle={`Enable HHAX Auto-sync - ${titlePostfix} Admin`}
                analyticsPageName="EnableHHAXAutoSync"
                faviconUrl={agencyFaviconUrl}
              >
                <EnableHHAXAutoSyncPage />
              </Page>
            }
          />

          <Route path="/jobs/">
            <Route
              index
              element={
                <Page
                  browserTitle={`Jobs - ${titlePostfix} Admin`}
                  analyticsPageName="Jobs"
                  faviconUrl={agencyFaviconUrl}
                >
                  <JobsPage />
                </Page>
              }
            />
            <Route
              path=":id/edit"
              element={
                <Page
                  browserTitle={`Edit Job - ${titlePostfix} Admin`}
                  analyticsPageName="EditJob"
                  faviconUrl={agencyFaviconUrl}
                >
                  <JobsPage />
                </Page>
              }
            />
          </Route>

          <Route path="/admins/">
            <Route
              index
              element={
                <Page
                  browserTitle={`Admins - ${titlePostfix} Admin`}
                  analyticsPageName="Admins"
                  faviconUrl={agencyFaviconUrl}
                >
                  <AdminsPage />
                </Page>
              }
            />
            <Route
              path="add-new-admin"
              element={
                <Page
                  browserTitle={`Add Admin - ${titlePostfix} Admin`}
                  analyticsPageName="AddAdmin"
                  faviconUrl={agencyFaviconUrl}
                >
                  <AddAdminPage />
                </Page>
              }
            />
            <Route
              path=":id/edit"
              element={
                <Page
                  browserTitle={`Edit Admins - ${titlePostfix} Admin`}
                  analyticsPageName="EditAdmins"
                  faviconUrl={agencyFaviconUrl}
                >
                  <EditAdminPage />
                </Page>
              }
            />
          </Route>

          <Route
            path="/draw"
            element={
              <Page
                browserTitle={`Draw - ${titlePostfix} Admin`}
                analyticsPageName="Draw"
                faviconUrl={agencyFaviconUrl}
              >
                <DrawPage />
              </Page>
            }
          />

          <Route
            path="/draw/schedule-new"
            element={
              <Page
                browserTitle={`Schedule a Draw - ${titlePostfix} Admin`}
                analyticsPageName="ScheduleNewDraw"
                faviconUrl={agencyFaviconUrl}
              >
                <ScheduleNewDrawPage />
              </Page>
            }
          />

          <Route
            path="/draw/:id"
            element={
              <Page
                browserTitle={`Draw Details - ${titlePostfix} Admin`}
                analyticsPageName="DrawDetails"
                faviconUrl={agencyFaviconUrl}
              >
                <DrawDetailsPage />
              </Page>
            }
          />

          <Route
            path="/draw/:id/approve-winners"
            element={
              <Page
                browserTitle={`Approve Draw Winners - ${titlePostfix} Admin`}
                analyticsPageName="ApproveDrawWinners"
                faviconUrl={agencyFaviconUrl}
              >
                <ApproveDrawWinnersPage />
              </Page>
            }
          />

          <Route path="/settings/" element={<SettingsPage />}>
            <Route
              index
              element={<Navigate to="/settings/referral-structure" replace />}
            />

            <Route
              path="recognition/*"
              element={
                <Page
                  browserTitle={`Recognition Settings - ${titlePostfix} Admin`}
                  analyticsPageName="Recognition Settings"
                  faviconUrl={agencyFaviconUrl}
                >
                  <SettingsRecognitionPage />
                </Page>
              }
            />

            <Route
              path="communication"
              element={
                <Page
                  browserTitle={`Communication Settings - ${titlePostfix} Admin`}
                  analyticsPageName="Communication Settings"
                  faviconUrl={agencyFaviconUrl}
                >
                  <SettingsCommunicationPage />
                </Page>
              }
            />
            {evvRulesEnabled && (
              <Route
                path="evv-rules"
                element={
                  <Page
                    browserTitle={`EVV Rules - ${titlePostfix} Admin`}
                    analyticsPageName="EVV Rules"
                    faviconUrl={agencyFaviconUrl}
                  >
                    <SettingsEVVRulesPage />
                  </Page>
                }
              />
            )}
            <Route
              path="employee-accounts"
              element={
                <Page
                  browserTitle={`Employee Accounts Settings - ${titlePostfix} Admin`}
                  analyticsPageName="Employee Accounts Settings"
                  faviconUrl={agencyFaviconUrl}
                >
                  <SettingsEmployeeAccountsPage />
                </Page>
              }
            />

            <Route
              path="job-posting"
              element={
                <Page
                  browserTitle={`Job Postings Settings - ${titlePostfix} Admin`}
                  analyticsPageName="Job Postings Settings"
                  faviconUrl={agencyFaviconUrl}
                >
                  <SettingsJobPostingPage />
                </Page>
              }
            />

            <Route
              path="redemption"
              element={
                <Page
                  browserTitle={`Redemption Settings - ${titlePostfix} Admin`}
                  analyticsPageName="Redemption Settings"
                  faviconUrl={agencyFaviconUrl}
                >
                  <SettingsRedemptionPage />
                </Page>
              }
            />

            <Route
              path="referral-structure"
              element={
                <Page
                  browserTitle={`Referral Structure Settings - ${titlePostfix} Admin`}
                  analyticsPageName="Referral Structure Settings"
                  faviconUrl={agencyFaviconUrl}
                >
                  <SettingsReferralStructurePage />
                </Page>
              }
            />

            <Route
              path="coordinator-goals"
              element={
                <Page
                  browserTitle={`Coordinator Goals - ${titlePostfix} Admin`}
                  analyticsPageName="Coordinator Goals Settings"
                  faviconUrl={agencyFaviconUrl}
                >
                  <SettingsCoordinatorGoalsPage />
                </Page>
              }
            />
          </Route>
          <Route
            path="/settings/coordinator-goals/recognition-budget"
            element={
              <Page
                browserTitle={`Recognition Budget Utilization - Coordinator Goals - ${titlePostfix} Admin`}
                analyticsPageName="Coordinator Goals Settings - Budget Utilization"
                faviconUrl={agencyFaviconUrl}
              >
                <BudgetUtilizationGoalConfigurationPage />
              </Page>
            }
          />
          <Route
            path="/settings/referral-structure/edit-referral-structure"
            element={
              <Page
                browserTitle={`Edit Referral - ${titlePostfix} Structure`}
                analyticsPageName="EditReferralStructure"
                faviconUrl={agencyFaviconUrl}
              >
                <EditReferralStructurePage />
              </Page>
            }
          />
          <Route path="/onboarding/" element={<OnboardingPage />}>
            <Route index element={<OnboardingIndex />} />
            <Route
              path={ONBOARDING_STEP_PATH_BY_KEY.INTRO}
              element={
                <Page
                  browserTitle={`Onboarding - Intro - ${titlePostfix} Admin`}
                  analyticsPageName="Onboarding - Intro"
                  faviconUrl={agencyFaviconUrl}
                >
                  <OnboardingIntroPage />
                </Page>
              }
            />
            <Route
              path={ONBOARDING_STEP_PATH_BY_KEY.YOUR_PROFILE}
              element={
                <Page
                  browserTitle={`Onboarding - Profile - ${titlePostfix} Admin`}
                  analyticsPageName="Onboarding - Profile"
                  faviconUrl={agencyFaviconUrl}
                >
                  <OnboardingProfilePage />
                </Page>
              }
            />
            <Route
              path={ONBOARDING_STEP_PATH_BY_KEY.CONFIGURE_JOBS}
              element={
                <Page
                  browserTitle={`Onboarding - Create Jobs - ${titlePostfix} Admin`}
                  analyticsPageName="Onboarding - Create Jobs"
                  faviconUrl={agencyFaviconUrl}
                >
                  <OnboardingCreateJobsPage />
                </Page>
              }
            />
            <Route
              path={ONBOARDING_STEP_PATH_BY_KEY.JOB_SETTING}
              element={
                <Page
                  browserTitle={`Onboarding - Job Settings - ${titlePostfix} Admin`}
                  analyticsPageName="Onboarding - Job Settings"
                  faviconUrl={agencyFaviconUrl}
                >
                  <OnboardingJobSettingsPage />
                </Page>
              }
            />
            <Route
              path={ONBOARDING_STEP_PATH_BY_KEY.CAREGIVER_INVITES}
              element={
                <Page
                  browserTitle={`Onboarding - Employees - ${titlePostfix} Admin`}
                  analyticsPageName="Onboarding - Employees"
                  faviconUrl={agencyFaviconUrl}
                >
                  <OnboardingCaregiverInvitesPage />
                </Page>
              }
            />
            <Route
              path={ONBOARDING_STEP_PATH_BY_KEY.EMPLOYEE_AUTO_SYNC}
              element={
                <Page
                  browserTitle={`Onboarding - Employee Auto-Sync - ${titlePostfix} Admin`}
                  analyticsPageName="Onboarding - Employee Auto-Sync"
                  faviconUrl={agencyFaviconUrl}
                >
                  <Outlet />
                </Page>
              }
            >
              <Route index element={<OnboardingEmployeeAutoSyncPage />} />
              <Route
                path="enable-hhax/:integration_id"
                element={<OnboardingEnableEmployeeAutoSyncHHAXPage />}
              />
              <Route
                path="edit-hhax/:sync_id"
                element={<OnboardingEditEmployeeAutoSyncHHAXPage />}
              />
            </Route>
            <Route
              path={ONBOARDING_STEP_PATH_BY_KEY.REFERRAL_STRUCTURE}
              element={
                <Page
                  browserTitle={`Onboarding - Referrals - ${titlePostfix} Admin`}
                  analyticsPageName="Onboarding - Referrals"
                  faviconUrl={agencyFaviconUrl}
                >
                  <OnboardingReferralsPage />
                </Page>
              }
            />
            {evvRulesEnabled && (
              <Route
                path={ONBOARDING_STEP_PATH_BY_KEY.EVV_COMPLIANCE}
                element={
                  <Page
                    browserTitle={`Onboarding - EVV-Compliance - ${titlePostfix} Admin`}
                    analyticsPageName="Onboarding - EVV-Compliance"
                    faviconUrl={agencyFaviconUrl}
                  >
                    <OnboardingEVVRulesPage />
                  </Page>
                }
              />
            )}
            <Route
              path={ONBOARDING_STEP_PATH_BY_KEY.BOOK_KICK_OFF}
              element={
                <Page
                  browserTitle={`Onboarding - Book Kick-off - ${titlePostfix} Admin`}
                  analyticsPageName="Onboarding - Book Kick-off"
                  faviconUrl={agencyFaviconUrl}
                >
                  <OnboardingBookKickoff />
                </Page>
              }
            />
          </Route>

          <Route path="/surveys" element={<SurveysPage />}>
            <Route index element={<Navigate to="/surveys/pulse" replace />} />
            <Route
              path="pulse"
              element={
                <Page
                  browserTitle={`Surveys - Pulse - ${titlePostfix} Admin`}
                  analyticsPageName="Surveys - Pulse"
                  faviconUrl={agencyFaviconUrl}
                >
                  <PulseSurveysPage />
                </Page>
              }
            />
          </Route>
          <Route
            path="/surveys/pulse/:question_type"
            element={
              <Page
                browserTitle={`Surveys - Pulse - Question - ${titlePostfix} Admin`}
                analyticsPageName="Surveys - Pulse - Question"
                faviconUrl={agencyFaviconUrl}
              >
                <SurveyQuestionDetailsPage />
              </Page>
            }
          />
          <Route path="/insights" element={<InsightsPage />} />
          <Route path="/hiring" element={<HiringPage />} />
          <Route
            path="/goals"
            element={
              <Page
                browserTitle={`Goals - ${titlePostfix} Admin`}
                analyticsPageName="Admin Goals"
                faviconUrl={agencyFaviconUrl}
              >
                <GoalsPage />
              </Page>
            }
          />
          <Route path="/moments" element={<MomentsPage />} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </NavigationContainer>
    );
  })();

  return (
    <AppThemeProvider
      theme={
        whiteLabelPrimaryColor && whiteLabelSecondaryColor
          ? {
              palette: {
                primary: {
                  main: whiteLabelPrimaryColor,
                  dark: darken(whiteLabelPrimaryColor, 0.3),
                  light: lighten(whiteLabelPrimaryColor, 0.3),
                },
                secondary: {
                  main: whiteLabelSecondaryColor,
                  dark: darken(whiteLabelSecondaryColor, 0.3),
                  light: lighten(whiteLabelSecondaryColor, 0.3),
                },
                background: {
                  default: lighten(whiteLabelPrimaryColor, 0.95),
                },
              },
            }
          : {} // Partial theme with nothing updated
      }
    >
      {routes}
    </AppThemeProvider>
  );
}
