import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ScheduleDrawMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  start: Types.Scalars['Timestamp'];
  end: Types.Scalars['Timestamp'];
  prizeStructure: Types.DrawPrizeStructureInput;
  announcementVideoId?: Types.Maybe<Types.Scalars['ID']>;
}>;


export type ScheduleDrawMutation = (
  { __typename?: 'Mutation' }
  & { scheduleDraw: (
    { __typename?: 'Draw' }
    & Pick<Types.Draw, 'id'>
  ) }
);


export const ScheduleDrawDocument = gql`
    mutation ScheduleDraw($name: String!, $start: Timestamp!, $end: Timestamp!, $prizeStructure: DrawPrizeStructureInput!, $announcementVideoId: ID) {
  scheduleDraw(
    name: $name
    start: $start
    end: $end
    prizeStructure: $prizeStructure
    announcementVideoId: $announcementVideoId
  ) {
    id
  }
}
    `;
export type ScheduleDrawMutationFn = Apollo.MutationFunction<ScheduleDrawMutation, ScheduleDrawMutationVariables>;

/**
 * __useScheduleDrawMutation__
 *
 * To run a mutation, you first call `useScheduleDrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleDrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleDrawMutation, { data, loading, error }] = useScheduleDrawMutation({
 *   variables: {
 *      name: // value for 'name'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      prizeStructure: // value for 'prizeStructure'
 *      announcementVideoId: // value for 'announcementVideoId'
 *   },
 * });
 */
export function useScheduleDrawMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleDrawMutation, ScheduleDrawMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleDrawMutation, ScheduleDrawMutationVariables>(ScheduleDrawDocument, options);
      }
export type ScheduleDrawMutationHookResult = ReturnType<typeof useScheduleDrawMutation>;
export type ScheduleDrawMutationResult = Apollo.MutationResult<ScheduleDrawMutation>;
export type ScheduleDrawMutationOptions = Apollo.BaseMutationOptions<ScheduleDrawMutation, ScheduleDrawMutationVariables>;