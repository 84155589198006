import { formatDollars } from "./format-dollars";
import { numberWithCommas } from "./format-numbers-with-commas";

/**
 * This function returns the reward points in different string format.
 * For example: formatRewardPoints(1000, 10)) will output "1,000 points($100)"
 * formatRewardPoints(1000) will output "1,000 points"
 */
export function formatPointsAndDollars(
  pointValue?: number,
  pointsPerDollar?: number
) {
  if (pointValue) {
    if (pointsPerDollar) {
      return `${numberWithCommas(pointValue)} points  (${formatDollars(
        pointValue / pointsPerDollar
      )})`;
    }
    return `${numberWithCommas(pointValue)} points`;
  }
  return "";
}
