import * as React from "react";

function PaymentLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.286 9.543h-.1V13.5c0 .41.332.743.743.743H16.07c.41 0 .743-.333.743-.743V9.543H1.286zm15.428-1.086h.1V6.971H1.186V8.457h15.528zM1.186 5.786v.1h15.628V4.5a.743.743 0 00-.743-.743H1.93a.743.743 0 00-.743.743v1.286zm.743-3.115H16.07c1.01 0 1.829.82 1.829 1.829v9c0 1.01-.819 1.829-1.829 1.829H1.93A1.829 1.829 0 01.1 13.5v-9c0-1.01.819-1.829 1.829-1.829z"
        fill={props.color}
        stroke="#fff"
        strokeWidth={0.2}
      />
      <path
        d="M12.214 11.028h2.572a.543.543 0 010 1.086h-2.572a.543.543 0 010-1.086z"
        fill={props.color}
        stroke="#fff"
        strokeWidth={0.2}
      />
    </svg>
  );
}

export default PaymentLogo;
