import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ManagedEmployeesRecognitionDataTableQueryVariables = Types.Exact<{
  filter: Types.ListMyManagedEmployeesRecognitionSummaryV2FilterInput;
  page: Types.ListMyManagedEmployeesRecognitionSummaryV2PageInput;
}>;


export type ManagedEmployeesRecognitionDataTableQuery = (
  { __typename?: 'Query' }
  & { listMyManagedEmployeesRecognitionSummaryV2: (
    { __typename?: 'ListMyManagedEmployeesRecognitionSummaryV2Response' }
    & Pick<Types.ListMyManagedEmployeesRecognitionSummaryV2Response, 'total'>
    & { items: Array<(
      { __typename?: 'ListMyManagedEmployeesRecognitionSummaryV2ResponseItem' }
      & Pick<Types.ListMyManagedEmployeesRecognitionSummaryV2ResponseItem, 'id' | 'pointsReceivedInRange' | 'pointsReceivedYTD'>
      & { employee: (
        { __typename?: 'RewardsUser' }
        & Pick<Types.RewardsUser, 'id' | 'firstName' | 'lastName' | 'active'>
      ) }
    )> }
  ) }
);


export const ManagedEmployeesRecognitionDataTableDocument = gql`
    query ManagedEmployeesRecognitionDataTable($filter: ListMyManagedEmployeesRecognitionSummaryV2FilterInput!, $page: ListMyManagedEmployeesRecognitionSummaryV2PageInput!) {
  listMyManagedEmployeesRecognitionSummaryV2(filter: $filter, page: $page) {
    total
    items {
      id
      employee {
        id
        firstName
        lastName
        active
      }
      pointsReceivedInRange
      pointsReceivedYTD
    }
  }
}
    `;

/**
 * __useManagedEmployeesRecognitionDataTableQuery__
 *
 * To run a query within a React component, call `useManagedEmployeesRecognitionDataTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagedEmployeesRecognitionDataTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagedEmployeesRecognitionDataTableQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useManagedEmployeesRecognitionDataTableQuery(baseOptions: Apollo.QueryHookOptions<ManagedEmployeesRecognitionDataTableQuery, ManagedEmployeesRecognitionDataTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManagedEmployeesRecognitionDataTableQuery, ManagedEmployeesRecognitionDataTableQueryVariables>(ManagedEmployeesRecognitionDataTableDocument, options);
      }
export function useManagedEmployeesRecognitionDataTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManagedEmployeesRecognitionDataTableQuery, ManagedEmployeesRecognitionDataTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManagedEmployeesRecognitionDataTableQuery, ManagedEmployeesRecognitionDataTableQueryVariables>(ManagedEmployeesRecognitionDataTableDocument, options);
        }
export type ManagedEmployeesRecognitionDataTableQueryHookResult = ReturnType<typeof useManagedEmployeesRecognitionDataTableQuery>;
export type ManagedEmployeesRecognitionDataTableLazyQueryHookResult = ReturnType<typeof useManagedEmployeesRecognitionDataTableLazyQuery>;
export type ManagedEmployeesRecognitionDataTableQueryResult = Apollo.QueryResult<ManagedEmployeesRecognitionDataTableQuery, ManagedEmployeesRecognitionDataTableQueryVariables>;