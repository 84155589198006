import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";

interface OverBudgetConfirmationModalProps {
  open: boolean;
  points: number;
  overBudget: number;
  firstName: string;

  onCancel(): void;
  onConfirm(): void;
}

export function OverBudgetConfirmationModal(
  props: OverBudgetConfirmationModalProps
): JSX.Element {
  return (
    <Modal width="550px" open={props.open} onClose={props.onCancel}>
      <ModalTitle>{"You are over budget. Do you want to continue?"}</ModalTitle>
      <ModalContent>
        <Typography>
          {`Giving ${numberWithCommas(props.points)} points to ${
            props.firstName
          } will result in you being over budget by ${numberWithCommas(
            props.overBudget
          )} points.`}
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          variant="outlined"
          onClick={props.onCancel}
          label="No, Cancel"
        />
        <Button onClick={props.onConfirm} color={"primary"} label="Yes, Send" />
      </ModalActions>
    </Modal>
  );
}
