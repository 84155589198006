import { ApolloError } from "@apollo/client";
import { compact } from "lodash";

import { reportError } from "@rewards-web/shared/modules/error";

import { serializeMonthDate } from "../../../shared/lib/serialize-month-date";
import { useHasPermissionQuery } from "../../../shared/modules/permissions/hooks/use-has-permission-query";
import { useRole } from "../../../shared/modules/role";
import { useEnabledRecognitionPageTabsDataQuery } from "./use-enabled-recognition-page-tabs-data.generated";

export enum RecognitionPageTab {
  MySummary = "MySummary",
  AwardedPoints = "AwardedPoints",
  MyScheduledPoints = "MyScheduledPoints",
}

export const RecognitionTabPathByTab = {
  [RecognitionPageTab.MySummary]: "/recognition/my-summary",
  [RecognitionPageTab.AwardedPoints]: "/recognition/awarded-points",
  [RecognitionPageTab.MyScheduledPoints]: "/recognition/my-scheduled-points",
};

interface RecognitionPageTabItem {
  tab: RecognitionPageTab;
  badge?: number;
}

export function useEnabledRecognitionPageTabsQuery(): {
  loading: boolean;
  data: RecognitionPageTabItem[] | undefined;
  error: ApolloError | undefined;
} {
  const roleQuery = useRole();

  const fullRecognitionPermissionQuery = useHasPermissionQuery(
    "full",
    "recognitionPoints"
  );

  const dataQuery = useEnabledRecognitionPageTabsDataQuery({
    fetchPolicy: "cache-and-network",
    onError: reportError,
    skip: roleQuery.data?.role !== "admin",
    variables: {
      // this month value doesn't matter for the purposes of this query
      month: serializeMonthDate(new Date()),
    },
  });

  const tabs = ((): RecognitionPageTabItem[] | undefined => {
    if (
      !roleQuery.data ||
      (roleQuery.data.role === "admin" &&
        (fullRecognitionPermissionQuery.hasPermission === undefined ||
          !dataQuery.data))
    ) {
      // still loading data
      return undefined;
    }

    const summaryEnabled =
      roleQuery.data.role === "admin" && // non-admins (superusers) cannot see 'my summary' page
      dataQuery.data!.getMyRewardsOrganization.recognitionBudgetsEnabled &&
      fullRecognitionPermissionQuery.hasPermission;

    const myScheduledPointsEnabled =
      roleQuery.data.role === "admin" && // non-admins (superusers) cannot see 'scheduled points' page
      fullRecognitionPermissionQuery.hasPermission &&
      dataQuery.data!.totalScheduledRecognitionPoints.total > 0;

    return compact([
      summaryEnabled && { tab: RecognitionPageTab.MySummary },
      { tab: RecognitionPageTab.AwardedPoints },
      myScheduledPointsEnabled && {
        tab: RecognitionPageTab.MyScheduledPoints,
        badge: dataQuery.data!.totalScheduledRecognitionPointsRequiringMessage
          .total,
      },
    ]);
  })();

  return {
    data: tabs,
    loading:
      roleQuery.loading ??
      fullRecognitionPermissionQuery.loading ??
      dataQuery.loading,
    error:
      roleQuery.error ??
      fullRecognitionPermissionQuery.error ??
      dataQuery.error,
  };
}
