/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { useAdminAppAuthenticatedOrganizationConfigQuery } from "../../../../../pages/authenticated/admin-app-authenticated-organization-config.generated";
import { useCognitoAuth } from "../../../cognito-auth";
import CongratsIllustration from "./congrats-illustration.png";

export interface LaunchCongratulationsModalProps {
  open: boolean;
  onClose(): void;
}

export function LaunchCongratulationsModal({
  open,
  onClose,
}: LaunchCongratulationsModalProps): JSX.Element {
  const { signedIn } = useCognitoAuth();

  const organizationWhiteLabelConfigQuery = useAdminAppAuthenticatedOrganizationConfigQuery(
    {
      skip: !signedIn, // it's possible this is rendered when not signed in
    }
  );
  const rewardsProgramShortName =
    organizationWhiteLabelConfigQuery.data?.getMyRewardsOrganization
      ?.whiteLabelConfig?.rewardsProgramShortName ?? "Caribou Rewards";

  return (
    <Modal width="750px" open={open} onClose={onClose}>
      <ModalContent>
        <div
          css={(theme: AppTheme) => css`
            text-align: center;
            margin-top: ${theme.spacing(2)};
          `}
        >
          <img
            src={CongratsIllustration}
            alt="Contest illustration"
            css={css`
              width: 50%;
              height: auto;
            `}
          />
        </div>
        <div
          css={(theme: AppTheme) => css`
            padding: ${theme.spacing(3)} ${theme.spacing(6)} ${theme.spacing(1)}
              ${theme.spacing(6)};
          `}
        >
          <Typography
            css={css`
              text-align: center;
              color: #93c6c1;
            `}
            variant="h2"
            color="primary"
          >
            Cariboo-yah!!
          </Typography>
          <Typography
            css={(theme: AppTheme) => css`
              margin-top: ${theme.spacing(2)};
            `}
            variant="body"
            color="textSecondary"
          >
            {`You've completed the launch set-up and your agency is ready to go
            live. A member of the Caribou customer success team will be in touch
            shortly to share best practices to make the most of your
            ${rewardsProgramShortName} experience.`}
          </Typography>

          <Typography
            css={(theme: AppTheme) => css`
              margin-top: ${theme.spacing(2)};
            `}
            variant="body"
            color="textSecondary"
          >
            {`Getting in front of your staff to build awareness and excitement
              about ${rewardsProgramShortName} will help you maximize your results!`}
          </Typography>
          <Button
            css={(theme: AppTheme) => css`
              display: block;
              margin: 0 auto;
              margin-top: ${theme.spacing(2)};
            `}
            width="auto"
            color="primary"
            onClick={onClose}
            label="Confirm"
          />
        </div>
      </ModalContent>
    </Modal>
  );
}
