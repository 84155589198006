/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import {
  Control,
  FieldValues,
  get,
  Path,
  useFormState,
  useWatch,
} from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";
import { isValidHexColor } from "@rewards-web/shared/lib/validate-hex-color";

import { FormFieldOutline } from "../../../../components/content-container";

interface SettingsJobPostingThemeColorFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export function SettingsJobPostingThemeColorField<T extends FieldValues>({
  control,
  name,
}: SettingsJobPostingThemeColorFieldProps<T>) {
  const formState = useFormState({ control });
  const theme = useTheme();
  const value = useWatch({ control, name });

  const effectiveThemeColor = isValidHexColor(value)
    ? `#${value}`
    : theme.palette.primary.main;

  return (
    <FormFieldOutline
      label="Theme color"
      name="theme-color"
      description="Enter the hexadecimal value of your theme color."
      divider={false}
    >
      <div
        css={css`
          display: grid;
          grid-template-columns: 25% 75%;
        `}
      >
        <div
          css={css`
            justify-content: center;
            width: 100%;
            display: flex;
          `}
        >
          <div
            css={css`
              border-width: 1px;
              border-color: ${effectiveThemeColor};
              border-style: solid;
              border-radius: 5px;
              min-width: 58px;
              width: 58px;
              margin: ${theme.spacing(1)};
              margin-left: ${theme.spacing(1.5)};
              height: 58px;
              padding: 3px;
            `}
          >
            <div
              css={css`
                background-color: ${effectiveThemeColor};
                height: 100%;
                border-radius: 5px;
              `}
            ></div>
          </div>
        </div>

        <TextField
          css={css`
            padding: ${theme.spacing(1)};
            padding-right: ${theme.spacing(2)};
          `}
          hideLabel
          disableAutocomplete
          label="Theme color"
          error={get(formState.errors, name)}
          startAdornment="#"
          {...control.register(name, {
            required: "Theme color is required",
            validate: (value) => {
              if (!isValidHexColor(value)) {
                return "Please enter a valid hexadecimal color";
              }
            },
          })}
        />
      </div>
    </FormFieldOutline>
  );
}
