/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  darken,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { forwardRef, ReactNode, useState } from "react";
import { useDropzone } from "react-dropzone";

import { useImagePreviewSrc } from "@rewards-web/shared/hooks/use-image-preview-src";
import { ControlledFormFieldProps } from "@rewards-web/shared/types";

import { AppTheme } from "../../style/theme";
import { useFormControlContext } from "../form/form-control";
import { FileInputActions } from "./file-input-actions";
import { FileInputContents } from "./file-input-contents";

const SUPPORTED_IMAGE_MIME_TYPES = ["image/jpeg", "image/png"] as const;

export type ImageFieldSupportedMimeType = typeof SUPPORTED_IMAGE_MIME_TYPES[number];

export interface ImageFieldProps extends ControlledFormFieldProps {
  label: ReactNode;
  description?: ReactNode;
  height?: string;
}

export const ImageField = forwardRef<HTMLDivElement, ImageFieldProps>(
  (
    {
      label,
      name,
      value,
      onChange,
      description,
      error: errorProp,
      height = "280px",
    }: ImageFieldProps,
    ref
  ) => {
    const selectedImage = useImagePreviewSrc(value);
    const [selectedInvalidFile, setSelectedInvalidFile] = useState(false);

    const error =
      errorProp?.message ??
      (selectedInvalidFile
        ? "Ensure that you select a .png or .jpg file"
        : undefined);

    const { submitting, readOnly } = useFormControlContext();

    const disabled = submitting || readOnly;

    const dropzone = useDropzone({
      disabled,
      maxFiles: 1,
      accept: SUPPORTED_IMAGE_MIME_TYPES.join(","),
      onDropAccepted: (files) => {
        setSelectedInvalidFile(false);
        const file = files[0];
        onChange?.(file);
      },
      onDropRejected: (errors) => {
        setSelectedInvalidFile(true);
        const file = errors[0].file;
        if (file) {
          // set the file and show it anyway
          onChange?.(file);
        }
      },
    });

    return (
      <FormControl
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(4)};
        `}
        disabled={disabled}
        variant="outlined"
        fullWidth
        ref={ref}
        error={!!error}
      >
        <InputLabel htmlFor={name} shrink>
          {label}
        </InputLabel>
        <OutlinedInput
          id={name}
          inputComponent={FileInputContents as any}
          inputProps={dropzone.getRootProps({
            selectedFileUrl: selectedImage.imageUrl,
            loadingImage: selectedImage.loading,
          })}
          label={label}
          notched
          fullWidth
          css={(theme: AppTheme) => css`
            cursor: default;
            height: ${height};
            &.MuiOutlinedInput-root.Mui-disabled
              .MuiOutlinedInput-notchedOutline,
            .MuiOutlinedInput-notchedOutline {
              border-color: ${theme.palette.divider};
            }

            &:hover:not(.Mui-focused):not(.Mui-error)
              .MuiOutlinedInput-notchedOutline {
              border-color: ${darken(theme.palette.divider, 0.15)};
            }

            & .MuiOutlinedInput-notchedOutline {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }

            &.Mui-focused .MuiOutlinedInput-notchedOutline {
              border-width: 1px;
            }

            transition: background-color 0.1s ease-in-out;
            ${dropzone.isDragActive &&
            css`
              background-color: rgba(245, 245, 245, 0.7);
            `}
          `}
        />
        <FileInputActions
          description={description}
          openFilePicker={dropzone.open}
          selectedFileUrl={selectedImage.imageUrl}
          error={error}
        />
        <input
          name={name}
          data-testid="photo-input"
          type="file"
          hidden
          {...dropzone.getInputProps()}
        />
      </FormControl>
    );
  }
);
