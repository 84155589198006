/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

import { RadioButton } from "@rewards-web/shared/components/radio-buttons";
import { RadioGroup } from "@rewards-web/shared/components/radio-buttons/radio-group";
import { JobPostingLocationField } from "@rewards-web/shared/graphql-types";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { FormFieldOutline } from "../../../../components/content-container";

interface SettingsJobPostingLocationFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export function SettingsJobPostingLocationField<T extends FieldValues>({
  control,
  name,
}: SettingsJobPostingLocationFieldProps<T>) {
  return (
    <FormFieldOutline
      label="ZIP Code, Postal Code, or Residing City"
      name={name}
      description="Candidates will either enter their ZIP code, their postal code, or their residing city when applying to the job posting."
    >
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <div
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(3)};
            `}
          >
            <RadioGroup ariaLabel="location" {...field}>
              <RadioButton
                value={JobPostingLocationField.ZipCode}
                label="ZIP Code"
              />
              <RadioButton
                value={JobPostingLocationField.PostalCode}
                label="Postal Code"
              />
              <RadioButton
                value={JobPostingLocationField.ResidingCity}
                label="Residing City"
              />
            </RadioGroup>
          </div>
        )}
      />
    </FormFieldOutline>
  );
}
