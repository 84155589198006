import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type WinnersToApproveQueryVariables = Types.Exact<{
  drawId: Types.Scalars['ID'];
}>;


export type WinnersToApproveQuery = (
  { __typename?: 'Query' }
  & { draw?: Types.Maybe<(
    { __typename?: 'Draw' }
    & Pick<Types.Draw, 'id' | 'name' | 'scheduledStartDate' | 'scheduledEndDate'>
    & { prizeStructure: (
      { __typename: 'DrawPrizeStructureSinglePrizeMultipleWinners' }
      & Pick<Types.DrawPrizeStructureSinglePrizeMultipleWinners, 'id' | 'numWinners'>
      & { prize: (
        { __typename: 'DrawPrizePoints' }
        & Pick<Types.DrawPrizePoints, 'id' | 'pointValue'>
      ) | (
        { __typename: 'DrawTieredPrizePoints' }
        & Pick<Types.DrawTieredPrizePoints, 'id'>
      ) }
    ) | (
      { __typename: 'DrawPrizeStructureTieredPrizesMultipleWinners' }
      & Pick<Types.DrawPrizeStructureTieredPrizesMultipleWinners, 'id' | 'numWinners' | 'totalPrizePointValue'>
      & { prizes: Array<{ __typename: 'DrawPrizePoints' } | (
        { __typename: 'DrawTieredPrizePoints' }
        & Pick<Types.DrawTieredPrizePoints, 'pointValue' | 'numWinners'>
      )> }
    ), winnersToApprove?: Types.Maybe<Array<(
      { __typename?: 'DrawWinnerToApprove' }
      & Pick<Types.DrawWinnerToApprove, 'id' | 'prizeTierIndex'>
      & { user: (
        { __typename?: 'RewardsUser' }
        & Pick<Types.RewardsUser, 'id' | 'firstName' | 'lastName' | 'workEmail' | 'workPhoneNumber' | 'active'>
        & { personalContactInfo?: Types.Maybe<(
          { __typename: 'RewardsUserPersonalContactInfoData' }
          & Pick<Types.RewardsUserPersonalContactInfoData, 'id' | 'email' | 'phoneNumber'>
        ) | (
          { __typename: 'RewardsUserPersonalContactInfoSuppressed' }
          & Pick<Types.RewardsUserPersonalContactInfoSuppressed, 'id'>
        )> }
      ) }
    )>> }
  )>, organization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'pointsPerDollar'>
  ) }
);


export const WinnersToApproveDocument = gql`
    query WinnersToApprove($drawId: ID!) {
  draw: getDrawById(drawId: $drawId) {
    id
    name
    scheduledStartDate
    scheduledEndDate
    prizeStructure {
      __typename
      ... on BaseDrawPrizeStructure {
        id
      }
      ... on DrawPrizeStructureSinglePrizeMultipleWinners {
        prize {
          __typename
          ... on BaseDrawPrize {
            id
          }
          ... on DrawPrizePoints {
            pointValue
          }
        }
        numWinners
      }
      ... on DrawPrizeStructureTieredPrizesMultipleWinners {
        prizes {
          __typename
          ... on DrawTieredPrizePoints {
            pointValue
            numWinners
          }
        }
        numWinners
        totalPrizePointValue
      }
    }
    winnersToApprove {
      id
      prizeTierIndex
      user {
        id
        firstName
        lastName
        personalContactInfo {
          __typename
          ... on RewardsUserPersonalContactInfoData {
            id
            email
            phoneNumber
          }
          ... on RewardsUserPersonalContactInfoSuppressed {
            id
          }
        }
        workEmail
        workPhoneNumber
        active
      }
    }
  }
  organization: getMyRewardsOrganization {
    id
    pointsPerDollar
  }
}
    `;

/**
 * __useWinnersToApproveQuery__
 *
 * To run a query within a React component, call `useWinnersToApproveQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinnersToApproveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinnersToApproveQuery({
 *   variables: {
 *      drawId: // value for 'drawId'
 *   },
 * });
 */
export function useWinnersToApproveQuery(baseOptions: Apollo.QueryHookOptions<WinnersToApproveQuery, WinnersToApproveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WinnersToApproveQuery, WinnersToApproveQueryVariables>(WinnersToApproveDocument, options);
      }
export function useWinnersToApproveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WinnersToApproveQuery, WinnersToApproveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WinnersToApproveQuery, WinnersToApproveQueryVariables>(WinnersToApproveDocument, options);
        }
export type WinnersToApproveQueryHookResult = ReturnType<typeof useWinnersToApproveQuery>;
export type WinnersToApproveLazyQueryHookResult = ReturnType<typeof useWinnersToApproveLazyQuery>;
export type WinnersToApproveQueryResult = Apollo.QueryResult<WinnersToApproveQuery, WinnersToApproveQueryVariables>;