import { JobPostingScreenerQuestionType } from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";

import {
  JobScreenerQuestionPresets,
  JobScreenerQuestionValueWithId,
} from "../typings";

export function serializeScreenerQuestions(
  screenerQuestions: JobScreenerQuestionValueWithId[]
) {
  return (
    screenerQuestions
      // eslint-disable-next-line array-callback-return
      .map((screenerQuestion) => {
        switch (screenerQuestion.type) {
          case JobScreenerQuestionPresets.HAS_DRIVERS_LICENSE_AND_ACCESS_TO_CAR:
            return {
              type:
                JobPostingScreenerQuestionType.HasDriversLicenseAndAccessToCar,
              autoRejectResponses: screenerQuestion.autoReject ?? undefined,
            };
          case "YES_OR_NO":
            return {
              type: JobPostingScreenerQuestionType.YesOrNo,
              question: screenerQuestion.question,
              autoRejectResponses: screenerQuestion.autoReject ?? undefined,
            };
          default:
            assertNever(screenerQuestion);
        }
      })
  );
}
