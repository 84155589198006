import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type BudgetUtilizationGoalAdminListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type BudgetUtilizationGoalAdminListQuery = (
  { __typename?: 'Query' }
  & { listRewardsAdmins: (
    { __typename?: 'ListRewardsAdminsResponse' }
    & Pick<Types.ListRewardsAdminsResponse, 'total'>
    & { items: Array<(
      { __typename?: 'RewardsAdminUser' }
      & Pick<Types.RewardsAdminUser, 'id' | 'firstName' | 'lastName' | 'role' | 'active'>
    )> }
  ) }
);


export const BudgetUtilizationGoalAdminListDocument = gql`
    query BudgetUtilizationGoalAdminList {
  listRewardsAdmins(filter: {budgetsEnabled: true, active: true}) {
    total
    items {
      id
      firstName
      lastName
      role
      active
    }
  }
}
    `;

/**
 * __useBudgetUtilizationGoalAdminListQuery__
 *
 * To run a query within a React component, call `useBudgetUtilizationGoalAdminListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetUtilizationGoalAdminListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetUtilizationGoalAdminListQuery({
 *   variables: {
 *   },
 * });
 */
export function useBudgetUtilizationGoalAdminListQuery(baseOptions?: Apollo.QueryHookOptions<BudgetUtilizationGoalAdminListQuery, BudgetUtilizationGoalAdminListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BudgetUtilizationGoalAdminListQuery, BudgetUtilizationGoalAdminListQueryVariables>(BudgetUtilizationGoalAdminListDocument, options);
      }
export function useBudgetUtilizationGoalAdminListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BudgetUtilizationGoalAdminListQuery, BudgetUtilizationGoalAdminListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BudgetUtilizationGoalAdminListQuery, BudgetUtilizationGoalAdminListQueryVariables>(BudgetUtilizationGoalAdminListDocument, options);
        }
export type BudgetUtilizationGoalAdminListQueryHookResult = ReturnType<typeof useBudgetUtilizationGoalAdminListQuery>;
export type BudgetUtilizationGoalAdminListLazyQueryHookResult = ReturnType<typeof useBudgetUtilizationGoalAdminListLazyQuery>;
export type BudgetUtilizationGoalAdminListQueryResult = Apollo.QueryResult<BudgetUtilizationGoalAdminListQuery, BudgetUtilizationGoalAdminListQueryVariables>;