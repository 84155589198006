/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface ConfirmReassignCandidateModalProps {
  open: boolean;
  onConfirm(): void;
  onCancel(): void;
  assignedToAdminName: string;
}

export function ConfirmReassignCandidateModal({
  open,
  onConfirm,
  onCancel,
  assignedToAdminName,
}: ConfirmReassignCandidateModalProps): JSX.Element {
  return (
    <Modal width="500px" open={open} onClose={onCancel}>
      <ModalTitle>Confirm?</ModalTitle>
      <ModalContent>
        <Typography
          variant="body"
          color="textSecondary"
          css={(theme: AppTheme) =>
            css`
              padding-bottom: ${theme.spacing(2)};
            `
          }
        >
          Are you sure you want to progress{" "}
          <strong>{assignedToAdminName}</strong>'s candidate to the next step?
          After the update, you will be assigned to the candidate.
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          size="medium"
          label="No"
          variant="outlined"
          onClick={() => {
            onCancel();
          }}
        />
        <Button
          testId="confirm-reassign-yes-button"
          size="medium"
          label="Yes"
          color="primary"
          onClick={() => {
            onConfirm();
          }}
        />
      </ModalActions>
    </Modal>
  );
}
