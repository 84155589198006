/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Checkbox } from "@material-ui/core";
import {
  FieldValues,
  Control,
  Path,
  Controller,
  useWatch,
} from "react-hook-form";

import { RadioButton } from "@rewards-web/shared/components/radio-buttons";
import { RadioGroup } from "@rewards-web/shared/components/radio-buttons/radio-group";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { getCharactersRemainingText } from "@rewards-web/shared/lib/characters-remaining-text";
import { isURL } from "@rewards-web/shared/lib/is-url";
import { AppTheme } from "@rewards-web/shared/style/theme";

const MAX_JOB_CUSTOM_BOOKING_LINK_LENGTH = 255;

interface JobInterviewBookingLinkFieldProps<T extends FieldValues> {
  name: Path<T>;
  checkBoxName: Path<T>;
  mustUploadResumeFieldName: Path<T>;
  control: Control<T>;
}

export function JobInterviewBookingLinkField<T extends FieldValues>({
  name,
  control,
  checkBoxName,
  mustUploadResumeFieldName,
}: JobInterviewBookingLinkFieldProps<T>): JSX.Element {
  const open = useWatch({ control, name: checkBoxName });

  return (
    <div>
      <div
        css={(theme: AppTheme) => css`
          display: inline-flex;
          align-items: center;
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <Checkbox
          data-testid="booking-link-checkbox"
          color="primary"
          checked={open}
          {...control.register(checkBoxName)}
        />

        <div
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(2)};
          `}
        >
          <Typography variant="body" color="textPrimary">
            Add calendar booking link (E.g. Calendly, Microsoft Bookings)
          </Typography>
          <Typography
            css={css`
              font-style: italic;
            `}
            variant="footnote"
            color="textSecondary"
          >
            Allow applicants to schedule their own screener interview with you
          </Typography>
        </div>
      </div>

      {open && (
        <div
          css={(theme: AppTheme) => css`
            padding-left: ${theme.spacing(1.5)};
          `}
        >
          <Controller
            control={control}
            name={name}
            rules={{
              required: "Interview booking link is required if checked",
              validate: (value) => {
                if (
                  value &&
                  value.length > MAX_JOB_CUSTOM_BOOKING_LINK_LENGTH
                ) {
                  return getCharactersRemainingText(
                    value,
                    MAX_JOB_CUSTOM_BOOKING_LINK_LENGTH
                  );
                }
                if (value && !isURL(value)) {
                  return "Please enter a valid URL / Link";
                }
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fixLabelToTop
                placeholder="https://calendly.com/my-link-here"
                label="Calendar link / URL"
                error={fieldState.error}
                disableAutocomplete
              />
            )}
          />

          <div
            css={(theme: AppTheme) => css`
              margin-top: ${theme.spacing(-1.5)};
              margin-left: ${theme.spacing(2)};
              margin-bottom: ${theme.spacing(1.5)};
            `}
          >
            <Controller
              control={control}
              name={mustUploadResumeFieldName}
              rules={{
                required: "This input is required if checked",
              }}
              render={({ field: formField, fieldState }) => (
                <RadioGroup
                  ariaLabel="interview-booking-link"
                  error={fieldState.error}
                  {...formField}
                >
                  <RadioButton value="NO" label="Visible to all candidates" />
                  <RadioButton
                    value="YES"
                    label="Visible to all candidates who have uploaded a resume"
                  />
                </RadioGroup>
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
}
