import * as React from "react";

function SelectedPermissionIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="50"
      height="51"
      viewBox="0 0 50 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.5078 8.95312C47.2656 9.73438 48.4375 11.3945 48.4375 13.25C48.4375 38.6406 29.7852 50.75 24.9023 50.75C20.0195 50.75 1.5625 38.4453 1.5625 13.25C1.5625 11.3945 2.63672 9.73438 4.39453 8.95312L23.1445 1.14062C23.7305 0.945312 24.3164 0.847656 25 0.847656C25.5859 0.847656 26.1719 0.945312 26.7578 1.14062L45.5078 8.95312ZM45.2148 13.3477C45.2148 13.0547 45.2148 12.2734 44.3359 11.8828L25.5859 4.07031C25.3906 3.97266 25.1953 3.875 25 3.875C24.707 3.875 24.5117 3.97266 24.3164 4.07031L5.56641 11.8828C4.98047 12.0781 4.6875 12.6641 4.6875 13.25C4.6875 35.2227 20.8008 47.625 25 47.625C29.0039 47.625 45.2148 35.3203 45.2148 13.3477ZM32.8125 18.5234C33.6914 18.5234 34.375 19.207 34.375 20.0859C34.375 20.4766 34.1797 20.8672 33.8867 21.1602L23.4375 31.6094C23.1445 31.9023 22.7539 32 22.3633 32C21.9727 32 21.582 31.9023 21.2891 31.6094L16.0156 26.4336C15.7227 26.043 15.625 25.6523 15.625 25.2617C15.625 24.4805 16.2109 23.6992 17.1875 23.6992C17.5781 23.6992 17.9688 23.8945 18.2617 24.1875L22.3633 28.2891L31.6406 18.9141C31.9336 18.6211 32.3242 18.5234 32.8125 18.5234Z"
        fill={props.color}
      />
    </svg>
  );
}

export default SelectedPermissionIcon;
