import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateHhaxCaribouUserSyncFiltersMutationVariables = Types.Exact<{
  userSyncId: Types.Scalars['ID'];
  filters: Types.HhaxCaribouUserSyncFiltersInput;
}>;


export type UpdateHhaxCaribouUserSyncFiltersMutation = (
  { __typename?: 'Mutation' }
  & { updateHHAXCaribouUserSyncFilters: (
    { __typename?: 'HHAXCaribouUserSync' }
    & Pick<Types.HhaxCaribouUserSync, 'id' | 'enabled'>
    & { filters?: Types.Maybe<(
      { __typename?: 'HHAXCaribouUserSyncFilters' }
      & Pick<Types.HhaxCaribouUserSyncFilters, 'disciplines' | 'hasCompletedFirstShift'>
      & { branches: Array<(
        { __typename?: 'HHAXCaribouUserSyncBranchFilterOption' }
        & Pick<Types.HhaxCaribouUserSyncBranchFilterOption, 'branchId'>
      )>, offices: Array<(
        { __typename?: 'HHAXCaribouUserSyncOfficeFilterOption' }
        & Pick<Types.HhaxCaribouUserSyncOfficeFilterOption, 'officeId'>
      )>, teams: Array<(
        { __typename?: 'HHAXCaribouUserSyncTeamFilterOption' }
        & Pick<Types.HhaxCaribouUserSyncTeamFilterOption, 'teamId'>
      )> }
    )> }
  ) }
);


export const UpdateHhaxCaribouUserSyncFiltersDocument = gql`
    mutation UpdateHHAXCaribouUserSyncFilters($userSyncId: ID!, $filters: HHAXCaribouUserSyncFiltersInput!) {
  updateHHAXCaribouUserSyncFilters(userSyncId: $userSyncId, filters: $filters) {
    id
    enabled
    filters {
      disciplines
      branches {
        branchId
      }
      offices {
        officeId
      }
      teams {
        teamId
      }
      hasCompletedFirstShift
    }
  }
}
    `;
export type UpdateHhaxCaribouUserSyncFiltersMutationFn = Apollo.MutationFunction<UpdateHhaxCaribouUserSyncFiltersMutation, UpdateHhaxCaribouUserSyncFiltersMutationVariables>;

/**
 * __useUpdateHhaxCaribouUserSyncFiltersMutation__
 *
 * To run a mutation, you first call `useUpdateHhaxCaribouUserSyncFiltersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHhaxCaribouUserSyncFiltersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHhaxCaribouUserSyncFiltersMutation, { data, loading, error }] = useUpdateHhaxCaribouUserSyncFiltersMutation({
 *   variables: {
 *      userSyncId: // value for 'userSyncId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUpdateHhaxCaribouUserSyncFiltersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHhaxCaribouUserSyncFiltersMutation, UpdateHhaxCaribouUserSyncFiltersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHhaxCaribouUserSyncFiltersMutation, UpdateHhaxCaribouUserSyncFiltersMutationVariables>(UpdateHhaxCaribouUserSyncFiltersDocument, options);
      }
export type UpdateHhaxCaribouUserSyncFiltersMutationHookResult = ReturnType<typeof useUpdateHhaxCaribouUserSyncFiltersMutation>;
export type UpdateHhaxCaribouUserSyncFiltersMutationResult = Apollo.MutationResult<UpdateHhaxCaribouUserSyncFiltersMutation>;
export type UpdateHhaxCaribouUserSyncFiltersMutationOptions = Apollo.BaseMutationOptions<UpdateHhaxCaribouUserSyncFiltersMutation, UpdateHhaxCaribouUserSyncFiltersMutationVariables>;