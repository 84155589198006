import { ClassNames } from "@emotion/react";
import { Tab, Tabs, TabsOwnProps } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import { Typography } from "@rewards-web/shared/components/typography";

import { Badge } from "./badge";

interface NavigationTabsProps {
  ariaLabel: string;
  tabsVariant?: TabsOwnProps["variant"];
  tabs: Array<{
    label: string;
    path: string;
    badge?: number;
  }>;
}

export function NavigationTabs({
  ariaLabel,
  tabsVariant,
  tabs,
}: NavigationTabsProps) {
  const location = useLocation();

  return (
    <ClassNames>
      {({ css, theme }) => {
        const tabClasses = {
          root: css`
            font-weight: 500;
          `,
          selected: css`
            color: ${theme.palette.primary.main};
          `,
        };

        const tabProps = (props: {
          label: string;
          to: string;
          badge?: number;
        }) => ({
          ...props,
          label: (
            <div
              className={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                text-transform: none;
              `}
            >
              <Typography>{props.label} </Typography>
              {typeof props.badge === "number" && props.badge > 0 && (
                <Badge
                  value={props.badge}
                  className={css`
                    margin-left: ${theme.spacing(1)};
                  `}
                />
              )}
            </div>
          ),
          component: Link,
          value: props.to,
          classes: tabClasses,
        });

        return (
          <Tabs
            value={location.pathname}
            aria-label={ariaLabel}
            classes={{
              root: css`
                margin-bottom: ${theme.spacing(2)};
              `,
              indicator: css`
                background-color: ${theme.palette.primary.main};
                height: 5px;
              `,
              flexContainer: css`
                border-bottom: 1px solid #e5e5e5;
              `,
            }}
            variant={tabsVariant ?? undefined}
          >
            {tabs.map(({ label, path, badge }) => (
              <Tab
                {...tabProps({
                  label,
                  to: path,
                  badge,
                })}
              />
            ))}
          </Tabs>
        );
      }}
    </ClassNames>
  );
}
