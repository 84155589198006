import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CloseJobPostingMutationVariables = Types.Exact<{
  jobPostingId: Types.Scalars['ID'];
  candidateIdsToArchive: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type CloseJobPostingMutation = (
  { __typename?: 'Mutation' }
  & { closeJobPosting: (
    { __typename?: 'JobPosting' }
    & Pick<Types.JobPosting, 'id'>
  ), bulkArchiveCandidates: Array<(
    { __typename?: 'Candidate' }
    & Pick<Types.Candidate, 'id' | 'archived' | 'archivedAt'>
  )> }
);


export const CloseJobPostingDocument = gql`
    mutation CloseJobPosting($jobPostingId: ID!, $candidateIdsToArchive: [ID!]!) {
  closeJobPosting(jobPostingId: $jobPostingId) {
    id
  }
  bulkArchiveCandidates(candidateIds: $candidateIdsToArchive, reason: JOB_CLOSED) {
    id
    archived
    archivedAt
  }
}
    `;
export type CloseJobPostingMutationFn = Apollo.MutationFunction<CloseJobPostingMutation, CloseJobPostingMutationVariables>;

/**
 * __useCloseJobPostingMutation__
 *
 * To run a mutation, you first call `useCloseJobPostingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseJobPostingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeJobPostingMutation, { data, loading, error }] = useCloseJobPostingMutation({
 *   variables: {
 *      jobPostingId: // value for 'jobPostingId'
 *      candidateIdsToArchive: // value for 'candidateIdsToArchive'
 *   },
 * });
 */
export function useCloseJobPostingMutation(baseOptions?: Apollo.MutationHookOptions<CloseJobPostingMutation, CloseJobPostingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseJobPostingMutation, CloseJobPostingMutationVariables>(CloseJobPostingDocument, options);
      }
export type CloseJobPostingMutationHookResult = ReturnType<typeof useCloseJobPostingMutation>;
export type CloseJobPostingMutationResult = Apollo.MutationResult<CloseJobPostingMutation>;
export type CloseJobPostingMutationOptions = Apollo.BaseMutationOptions<CloseJobPostingMutation, CloseJobPostingMutationVariables>;