import { useApolloClient } from "@apollo/client";
import { Auth } from "aws-amplify";
import { useContext, useState } from "react";

import { serializePhoneNumber } from "@rewards-web/shared/lib/serialize-phone-number";
import { reportError } from "@rewards-web/shared/modules/error";

import { CognitoAuthContext } from "../context";
import { AuthError } from "../errors";
import { isCognitoError } from "../lib";
import { useEnableSmsMfaMutation } from "./enable-sms-mfa.generated";
import {
  GetMyAdminMfaStatusDocument,
  GetMyAdminMfaStatusQuery,
  useGetMyAdminMfaStatusQuery,
} from "./get-my-admin-mfa-status.generated";
import {
  setLoggedInSinceMfaMandatory,
  getLoggedInSinceMfaMandatory,
} from "./storage";

export function useSmsMfaStatus() {
  const { userId } = useContext(CognitoAuthContext);
  const apolloClient = useApolloClient();
  const [
    loggedInSinceMfaMandatory,
    setLoggedInSinceMfaMandatoryState,
  ] = useState(() => getLoggedInSinceMfaMandatory());
  const mfaStatusQuery = useGetMyAdminMfaStatusQuery({
    skip: !userId,
    onError: reportError,
    fetchPolicy: "cache-first",
  });
  const [enableSmsMfa] = useEnableSmsMfaMutation();

  return {
    mustEnableSmsMfa:
      loggedInSinceMfaMandatory &&
      Boolean(mfaStatusQuery.data?.mfaStatus.smsMFARequired) &&
      !Boolean(mfaStatusQuery.data?.mfaStatus.smsNumberVerified),

    async setMfaStatusPostLogin() {
      try {
        const result = await apolloClient.query<GetMyAdminMfaStatusQuery>({
          query: GetMyAdminMfaStatusDocument,
        });

        if (
          result.data.mfaStatus.smsMFARequired &&
          !result.data.mfaStatus.smsNumberVerified
        ) {
          setLoggedInSinceMfaMandatory(true);
          setLoggedInSinceMfaMandatoryState(true);
        } else {
          setLoggedInSinceMfaMandatory(false);
          setLoggedInSinceMfaMandatoryState(false);
        }
      } catch (error) {
        reportError(error);
      }
    },

    /**
     * User must be authenticated to use this.
     * Sets a phone number to use for MFA.
     * This will send a verification code to the user.
     */
    async setMfaPhoneNumber(rawPhoneNumber: string) {
      try {
        const serializedPhoneNumber = serializePhoneNumber(rawPhoneNumber);
        const user = await Auth.currentAuthenticatedUser();
        const currentAttributes = await Auth.userAttributes(user);
        const currentPhoneNumberAttribute = currentAttributes.find(
          (attr) => attr.Name === "phone_number"
        );

        if (
          currentPhoneNumberAttribute &&
          currentPhoneNumberAttribute.Value === serializedPhoneNumber
        ) {
          // resend the existing verification code, since
          // updating the attribute will not resend the code if already set
          await Auth.verifyCurrentUserAttribute("phone_number");
        } else {
          const res = await Auth.updateUserAttributes(user, {
            phone_number: serializedPhoneNumber,
          });

          if (res !== "SUCCESS") {
            throw new Error(
              "Could not update attribute. Response was not SUCCESS"
            );
          }
        }
      } catch (error) {
        if (isCognitoError(error)) {
          throw AuthError.fromCognitoError(
            "An error occurred setting MFA phone number",
            error
          );
        }

        throw error;
      }
    },

    /**
     * This should be invoked with the code the user receives
     * after invoking `setMfaPhoneNumber`.
     */
    async verifyNewMfaPhoneNumber(code: string) {
      try {
        const res = await Auth.verifyCurrentUserAttributeSubmit(
          "phone_number",
          code
        );

        if (res !== "SUCCESS") {
          throw new Error(
            "Could not update attribute. Response was not SUCCESS"
          );
        }

        await enableSmsMfa();
      } catch (error) {
        if (isCognitoError(error)) {
          throw AuthError.fromCognitoError(
            "An error occurred verifying new MFA phone number",
            error
          );
        }

        throw error;
      }
    },

    /**
     * Resends the verification code when a user sets
     * their MFA phone number.
     */
    async resendNewMfaVerificationCode() {
      try {
        await Auth.verifyCurrentUserAttribute("phone_number");
      } catch (error) {
        if (isCognitoError(error)) {
          throw AuthError.fromCognitoError(
            "An error occurred verifying new MFA phone number",
            error
          );
        }

        throw error;
      }
    },
  };
}
