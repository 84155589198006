/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { Divider } from "@rewards-web/shared/components/divider";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface ContainerContentProps {
  description: string;
  children?: ReactNode;
  divider: boolean;
}

export function ContainerContent({
  description,
  children,
  divider,
}: ContainerContentProps) {
  return (
    <div
      css={css`
        width: 100%;
        flex-direction: column;
      `}
    >
      <Typography
        variant="caption"
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(2)};
          font-size: 0.83em;
          font-style: italic;
          color: #7f8e9f;
          pointer-events: none;
        `}
      >
        {description}
      </Typography>
      {divider && (
        <Divider
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(2)};
            `
          }
        />
      )}

      <div
        css={(theme: AppTheme) =>
          css`
            padding-left: ${theme.spacing(2)};
            padding-right: ${theme.spacing(2)};
          `
        }
      >
        {children}
      </div>
    </div>
  );
}
