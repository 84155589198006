/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Control, FieldValues, Path, Controller } from "react-hook-form";

import { SwitchField } from "@rewards-web/shared/components/switch";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

interface RecognitionCategoryDisableSendFromManagersProps<
  T extends FieldValues
> {
  control: Control<T>;
  enabledSwitchName: Path<T>;
}

export function RecognitionCategoryDisableSendFromManagers<
  T extends FieldValues
>({
  control,
  enabledSwitchName,
}: RecognitionCategoryDisableSendFromManagersProps<T>) {
  return (
    <div
      css={(theme: AppTheme) => css`
        border-radius: 10px;
        border: 1px solid ${theme.palette.grey[400]};
        padding: ${theme.spacing(2)};
        padding-bottom: 0;
        margin-bottom: ${theme.spacing(2.5)};
      `}
    >
      <div
        css={(theme: AppTheme) => css`
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <div>
          <Typography variant="subtitle" color="textPrimary">
            Don't allow admins to send
          </Typography>
        </div>
        <Controller
          control={control}
          name={enabledSwitchName}
          render={({ field }) => (
            <SwitchField
              label="Category disable send from Manager"
              hideLabel
              {...field}
              value={field.value ?? false}
              onChange={(e, value) => {
                field.onChange(e, value);
              }}
            />
          )}
        />
      </div>
    </div>
  );
}
