import { SVGProps } from "react";

function TotalHiresIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={27}
      height={27}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.464 4.991c-1.75 0-3.13 1.538-3.13 3.359s1.38 3.359 3.13 3.359 3.13-1.538 3.13-3.359-1.38-3.359-3.13-3.359Zm0 1.222c1.028 0 1.908.923 1.908 2.137s-.88 2.137-1.908 2.137c-1.029 0-1.909-.923-1.909-2.137s.88-2.137 1.909-2.137ZM13.463 12.926c-1.786 0-3.401.408-4.618 1.088-1.216.68-2.099 1.678-2.099 2.881v1.708a.61.61 0 0 0 .143.391 8.534 8.534 0 0 0 13.148 0 .61.61 0 0 0 .143-.39v-1.709c0-1.203-.882-2.201-2.099-2.881-1.216-.68-2.831-1.088-4.618-1.088Zm0 1.221c1.598 0 3.036.382 4.027.935.991.554 1.47 1.231 1.47 1.813v1.45c-1.345 1.533-3.296 2.52-5.496 2.52-2.2 0-4.152-.987-5.496-2.52v-1.45c0-.582.478-1.26 1.47-1.813.99-.553 2.429-.935 4.026-.935ZM13.454.093a.61.61 0 0 0-.6.62V2.24a.613.613 0 0 0 .61.619.613.613 0 0 0 .61-.619V.713a.61.61 0 0 0-.62-.62Z"
        fill="#4D4D4D"
      />
      <path
        d="M13.463 1.938c-6.4 0-11.602 5.201-11.602 11.602 0 6.191 4.88 11.212 10.992 11.536v1.288a.613.613 0 0 0 .61.619c.164 0 .32-.066.436-.181a.613.613 0 0 0 .175-.438v-1.832a.61.61 0 0 0-.61-.61c-5.741 0-10.382-4.641-10.382-10.382 0-5.74 4.641-10.38 10.381-10.38S23.846 7.8 23.846 13.54c0 4.945-3.453 9.073-8.073 10.124a.608.608 0 0 0-.217 1.14.61.61 0 0 0 .494.043c5.166-1.175 9.017-5.793 9.017-11.307 0-6.4-5.202-11.602-11.603-11.602Z"
        fill="#4D4D4D"
      />
      <path
        d="M24.703 12.93a.61.61 0 1 0 .057 1.221h1.527a.612.612 0 0 0 .62-.61.613.613 0 0 0-.619-.61H24.76a.456.456 0 0 0-.058-.001ZM.583 12.93A.61.61 0 0 0 .2 13.993a.61.61 0 0 0 .44.158h1.527a.613.613 0 0 0 .619-.61.613.613 0 0 0-.619-.61H.64a.456.456 0 0 0-.057-.001Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}

export default TotalHiresIcon;
