import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
export type EmployeeAccountSettingsFragmentFragment = (
  { __typename?: 'RewardsOrganization' }
  & Pick<Types.RewardsOrganization, 'id' | 'usesWorkDevices' | 'workEmailDomain'>
);

export const EmployeeAccountSettingsFragmentFragmentDoc = gql`
    fragment EmployeeAccountSettingsFragment on RewardsOrganization {
  id
  usesWorkDevices
  workEmailDomain
}
    `;