export function DollarIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.71094 8.44141C10.9062 8.75781 12.9102 9.28516 12.5234 11.3242C12.3477 12.3086 11.5039 12.9766 10.3438 13.1523V13.75C10.3438 14.2422 9.95703 14.5938 9.5 14.5938C9.00781 14.5938 8.65625 14.2422 8.65625 13.75V13.1172C8.12891 13.0469 7.63672 12.8711 7.17969 12.7305L6.96875 12.6602C6.51172 12.5195 6.30078 12.0273 6.44141 11.6055C6.58203 11.1484 7.03906 10.9023 7.49609 11.043L7.70703 11.1484C8.12891 11.2891 8.62109 11.4297 9.00781 11.5C9.99219 11.6406 10.8008 11.4297 10.8711 11.0078C10.9414 10.6562 10.8008 10.4805 9.25391 10.0938L8.97266 9.98828C8.09375 9.77734 6.05469 9.21484 6.44141 7.21094C6.61719 6.22656 7.46094 5.55859 8.65625 5.38281V4.75C8.65625 4.29297 9.00781 3.90625 9.5 3.90625C9.95703 3.90625 10.3438 4.29297 10.3438 4.75V5.41797C10.6602 5.45312 10.9766 5.55859 11.4336 5.66406C11.8555 5.83984 12.1016 6.29688 11.9609 6.75391C11.8203 7.17578 11.3633 7.42188 10.9062 7.28125C10.5195 7.17578 10.2031 7.07031 9.92188 7.03516C8.97266 6.89453 8.16406 7.10547 8.09375 7.52734C8.05859 7.77344 7.98828 7.98438 9.39453 8.37109L9.71094 8.44141ZM9.5 0.25C14.457 0.25 18.5 4.29297 18.5 9.25C18.5 14.2422 14.457 18.25 9.5 18.25C4.50781 18.25 0.5 14.2422 0.5 9.25C0.5 4.29297 4.50781 0.25 9.5 0.25ZM9.5 16.5625C13.5078 16.5625 16.8125 13.293 16.8125 9.25C16.8125 5.24219 13.5078 1.9375 9.5 1.9375C5.45703 1.9375 2.1875 5.24219 2.1875 9.25C2.1875 13.293 5.45703 16.5625 9.5 16.5625Z"
        fill="#444444"
      />
    </svg>
  );
}
