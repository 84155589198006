import { Control, Controller, FieldValues, Path } from "react-hook-form";

import { SearchTextField } from "@rewards-web/shared/components/search-text-field";
import { RewardsOrganizationOnboardingBusinessType } from "@rewards-web/shared/graphql-types";

interface SettingsBusinessTypeFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  isRequired?: boolean;
}

const BUSINESS_TYPE_LABELS: {
  [key in RewardsOrganizationOnboardingBusinessType]: string;
} = {
  [RewardsOrganizationOnboardingBusinessType.Cooperative]: "Cooperative",
  [RewardsOrganizationOnboardingBusinessType.Corporation]: "Corporation",
  [RewardsOrganizationOnboardingBusinessType.LimitedLiabilityCorporation]:
    "Limited Liability Corporation",
  [RewardsOrganizationOnboardingBusinessType.NonProfitCorporation]:
    "Non Profit Corporation",
  [RewardsOrganizationOnboardingBusinessType.Partnership]: "Partnership",
  [RewardsOrganizationOnboardingBusinessType.SoleProprietorship]:
    "Sole Proprietorship",
};

export function SettingsBusinessTypeField<T extends FieldValues>({
  control,
  name,
  isRequired,
}: SettingsBusinessTypeFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: isRequired }}
      render={({ field, fieldState }) => (
        <SearchTextField
          {...field}
          error={fieldState.error}
          label="Business type"
          options={(Object.values(
            RewardsOrganizationOnboardingBusinessType
          ) as RewardsOrganizationOnboardingBusinessType[]).map(
            (businessType) => ({
              value: businessType,
              label: BUSINESS_TYPE_LABELS[businessType],
            })
          )}
        />
      )}
    />
  );
}
