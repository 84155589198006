import { SVGProps } from "react";

export function SmallScreenIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={43}
      height={59}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.777 9.932c.264-.263.614-.393.922-.393.351 0 .658.131.922.393a1.322 1.322 0 0 1 0 1.884l-1.755 1.752h18.34l-1.755-1.752a1.321 1.321 0 0 1 0-1.884 1.327 1.327 0 0 1 1.887 0l4.037 4.03a1.322 1.322 0 0 1 0 1.884l-4.037 4.03a1.336 1.336 0 0 1-.922.393 1.27 1.27 0 0 1-.922-.394 1.321 1.321 0 0 1 0-1.884l1.755-1.752H12.91l1.755 1.752a1.322 1.322 0 0 1 0 1.884 1.327 1.327 0 0 1-1.887 0l-4.036-4.03a1.322 1.322 0 0 1 0-1.883l4.036-4.03Z"
        fill="#116872"
      />
      <path
        d="M42.336 22.652h-1.582c-.316 0-.587-.263-.587-.569V2.668H36.19l.002 4.723c0 .306-.272.57-.588.57h-1.581c-.316 0-.588-.264-.588-.57V2.668H29.46v2.843c0 .305-.272.568-.588.568H27.29c-.316 0-.588-.263-.588-.568V2.668h-3.885v2.843c0 .305-.272.568-.587.568h-1.582c-.316 0-.588-.263-.588-.568V2.668h-3.976v2.843c0 .305-.272.568-.588.568h-1.581c-.316 0-.588-.263-.588-.568V2.668H9.351l.001 4.723c0 .306-.271.57-.587.57H7.183c-.316 0-.587-.264-.587-.57V2.668H2.71v19.417c0 .306-.272.569-.588.569l-1.535-.002c-.316 0-.588-.263-.588-.569V.57C0 .263.272 0 .588 0h41.747c.316 0 .588.263.588.569v21.516c0 .306-.272.567-.588.567Z"
        fill="#116872"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 32.812h42.485v-4.245c0-.967-.791-1.76-1.76-1.76H1.76c-.966 0-1.759.791-1.759 1.76v4.245Zm13.533-3.687a1.028 1.028 0 1 1 0 2.057 1.028 1.028 0 0 1 0-2.057Zm-3.947 0a1.028 1.028 0 1 1 0 2.057 1.028 1.028 0 0 1 0-2.057Zm-3.944 0a1.028 1.028 0 1 1 0 2.057 1.028 1.028 0 0 1 0-2.057Zm36.845 5.057H0v23.059C0 58.208.79 59 1.76 59h38.967c.967 0 1.76-.79 1.76-1.76V34.183ZM15.24 38.778c0-.378.306-.685.685-.685h16.989a.685.685 0 1 1 0 1.37h-16.99a.686.686 0 0 1-.684-.685Zm0 4.98c0-.379.306-.685.685-.685h16.989a.685.685 0 1 1 0 1.37h-16.99a.685.685 0 0 1-.684-.685Zm-9.624 4.296h31.25a.685.685 0 1 1 0 1.37H5.617a.685.685 0 1 1 0-1.37Zm0 4.98h31.25a.685.685 0 1 1 0 1.37H5.617a.685.685 0 1 1 0-1.37Zm5.787-14.07v4.727c0 .48-.393.873-.874.873l-4.724-.002a.875.875 0 0 1-.873-.873v-4.724c0-.48.392-.873.873-.873h4.726c.479 0 .872.392.872.873Z"
        fill="#116872"
      />
    </svg>
  );
}
