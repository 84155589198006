/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useDeleteDrawMutation } from "./delete-draw.generated";

interface DeleteDrawConfirmationModalProps {
  open: boolean;
  drawId: string | null;
  drawName: string | null;
  onClose(): void;
  onDeleted(): void;
  onExited(): void;
}

export function DeleteDrawConfirmationModal({
  open,
  drawId,
  drawName,
  onClose,
  onDeleted,
  onExited,
}: DeleteDrawConfirmationModalProps) {
  const snackbar = useSnackbar();
  const track = useTrack();
  const [deleteDraw, { loading: deletingDraw }] = useDeleteDrawMutation();

  const handleDelete = async () => {
    try {
      await deleteDraw({ variables: { drawId: drawId! } });
      onDeleted();
      snackbar.show({
        severity: "success",
        message: `Success! Draw ${drawName} has been deleted.`,
      });
      track("Deleted draw", { drawId });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message:
          "Oops! There was a technical error while deleting the draw. Please try again.",
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      onExited={onExited}
      disableBackdropClick={true}
      width="630px"
    >
      <ModalTitle>Delete draw?</ModalTitle>
      <ModalContent>
        <Typography
          variant="body"
          color="grey.800"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          Deleting this draw will permanently remove this draw and will not go
          live.
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          disabled={deletingDraw}
          type="button"
          label="Cancel"
          onClick={onClose}
          size="large"
          variant="outlined"
        />
        <Button
          loading={deletingDraw}
          type="button"
          label="Confirm"
          onClick={handleDelete}
          size="large"
          color="primary"
        />
      </ModalActions>
    </Modal>
  );
}
