/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useMemo, useState } from "react";

import { SelectCheckboxesFieldOption } from "@rewards-web/shared/components/select-checkboxes-field";
import {
  HasCompletedFirstShiftFilterOptions,
  HhaxCaribouUserSyncFilters,
  HhaxCaribouUserSyncFiltersInput,
} from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";

import { CaregiverFilter } from "./caregiver-filter";

const FILTER_OPTION_NULL_VALUE = "-";
enum FILTER_TRACKING_KEYS {
  discipline = "discipline",
  office = "office",
  branch = "branch",
  team = "team",
  startedWork = "startedWork",
}

interface HhaxAutoSyncFiltersProps {
  value: HhaxCaribouUserSyncFiltersInput;
  onChange(newFilters: HhaxCaribouUserSyncFiltersInput): void;
  filterOptions?: HhaxCaribouUserSyncFilters | null;
  initializeAllPossibleValuesOnOptionsLoad?: boolean;
  integrationId: string;
}

export function HhaxAutoSyncFilters({
  value,
  onChange,
  filterOptions,
  initializeAllPossibleValuesOnOptionsLoad,
  integrationId,
}: HhaxAutoSyncFiltersProps) {
  const track = useTrack();

  const allDisciplineValues = useMemo(
    () =>
      filterOptions?.disciplines.map(
        (disc) => disc ?? FILTER_OPTION_NULL_VALUE
      ) ?? [],
    [filterOptions]
  );

  const allOfficeIdValues = useMemo(
    () =>
      filterOptions?.offices.map(
        (office) => office.officeId ?? FILTER_OPTION_NULL_VALUE
      ) ?? [],
    [filterOptions]
  );

  const allBranchIdValues = useMemo(
    () =>
      filterOptions?.branches.map(
        (branch) => branch.branchId ?? FILTER_OPTION_NULL_VALUE
      ) ?? [],
    [filterOptions]
  );

  const allTeamIdValues = useMemo(
    () =>
      filterOptions?.teams.map(
        (team) => team.teamId ?? FILTER_OPTION_NULL_VALUE
      ) ?? [],
    [filterOptions]
  );

  const serializeValues = (values: string[]) =>
    values.map((value) => (value === FILTER_OPTION_NULL_VALUE ? null : value));

  // Set initial filter options on load, after options are initialized.
  // they should initially all be checked
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (
      !initialized &&
      initializeAllPossibleValuesOnOptionsLoad &&
      filterOptions
    ) {
      setInitialized(true);
      onChange({
        disciplines: allDisciplineValues,
        officeIds: allOfficeIdValues,
        branchIds: allBranchIdValues,
        teamIds: allTeamIdValues,
        hasCompletedFirstShift: filterOptions.hasCompletedFirstShift,
      });
    }
  }, [
    initialized,
    initializeAllPossibleValuesOnOptionsLoad,
    filterOptions,
    onChange,
    allDisciplineValues,
    allOfficeIdValues,
    allBranchIdValues,
    allTeamIdValues,
  ]);

  const trackFilterUpdated = (
    filterTrackingKey: FILTER_TRACKING_KEYS,
    selectedFilterOptions: string[]
  ) =>
    track("HHAX Auto Sync updated filter", {
      filterTrackingKey,
      selectedFilterOptions,
      integrationId,
    });

  const trackFilterOpened = (filterTrackingKey: FILTER_TRACKING_KEYS) =>
    track("HHAX Auto Sync opened filter", { filterTrackingKey, integrationId });

  const handleDisciplineFilterChange = (disciplines: string[]) => {
    // If no options are selected, reset to all
    if (disciplines.length === 0) {
      disciplines = allDisciplineValues;
    }

    trackFilterUpdated(FILTER_TRACKING_KEYS.discipline, disciplines);
    onChange({ ...value, disciplines: serializeValues(disciplines) });
  };

  const handleOfficeFilterChange = (officeIds: string[]) => {
    // If no options are selected, reset to all
    if (officeIds.length === 0) {
      officeIds = allOfficeIdValues;
    }

    trackFilterUpdated(FILTER_TRACKING_KEYS.office, officeIds);
    onChange({ ...value, officeIds: serializeValues(officeIds) });
  };

  const handleBranchFilterChange = (branchIds: string[]) => {
    // If no options are selected, reset to all
    if (branchIds.length === 0) {
      branchIds = allBranchIdValues;
    }

    trackFilterUpdated(FILTER_TRACKING_KEYS.branch, branchIds);
    onChange({ ...value, branchIds: serializeValues(branchIds) });
  };

  const handleTeamFilterChange = (teamIds: string[]) => {
    // If no options are selected, reset to all
    if (teamIds.length === 0) {
      teamIds = allTeamIdValues;
    }

    trackFilterUpdated(FILTER_TRACKING_KEYS.team, teamIds);
    onChange({ ...value, teamIds: serializeValues(teamIds) });
  };

  const handleStartedWorkFilterChange = (
    hasCompletedFirstShift: HasCompletedFirstShiftFilterOptions[]
  ) => {
    // If no options are selected, reset to all
    if (hasCompletedFirstShift.length === 0) {
      hasCompletedFirstShift = filterOptions!.hasCompletedFirstShift;
    }

    trackFilterUpdated(
      FILTER_TRACKING_KEYS.startedWork,
      hasCompletedFirstShift
    );
    onChange({
      ...value,
      hasCompletedFirstShift,
    });
  };

  const disciplineOptions: SelectCheckboxesFieldOption[] = (
    filterOptions?.disciplines ?? []
  ).map((disc) => ({
    value: disc ?? FILTER_OPTION_NULL_VALUE,
    label: disc ?? "(None)",
  }));

  const officeOptions: SelectCheckboxesFieldOption[] = (
    filterOptions?.offices ?? []
  ).map((office) => ({
    value: office.officeId ?? FILTER_OPTION_NULL_VALUE,
    label: office.officeId ?? "(None)",
  }));

  const branchOptions: SelectCheckboxesFieldOption[] = (
    filterOptions?.branches ?? []
  ).map((branch) => ({
    value: branch.branchId ?? FILTER_OPTION_NULL_VALUE,
    label: branch.branchId ?? "(None)",
  }));

  const teamOptions: SelectCheckboxesFieldOption[] = (
    filterOptions?.teams ?? []
  ).map((team) => ({
    value: team.teamId ?? FILTER_OPTION_NULL_VALUE,
    label: team.teamId ?? "(None)",
  }));

  const startedWorkOptions: SelectCheckboxesFieldOption[] = (
    filterOptions?.hasCompletedFirstShift ?? []
  ).map((startedWork) => {
    switch (startedWork) {
      case HasCompletedFirstShiftFilterOptions.CompletedFirstShift:
        return {
          value: startedWork,
          label: "Has worked first shift",
        };
      case HasCompletedFirstShiftFilterOptions.HasntCompletedFirstShift:
        return {
          value: startedWork,
          label: "Hasn't worked first shift",
        };
      default: {
        return {
          value: startedWork,
          label: startedWork,
        };
      }
    }
  });

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      `}
    >
      <CaregiverFilter
        id="discipline-filter"
        label="Discipline"
        options={disciplineOptions}
        value={(value.disciplines ?? []).map(
          (disciplineId) => disciplineId ?? FILTER_OPTION_NULL_VALUE
        )}
        onChange={handleDisciplineFilterChange}
        onOpen={() => trackFilterOpened(FILTER_TRACKING_KEYS.discipline)}
      />
      <CaregiverFilter
        id="office-filter"
        label="Office"
        options={officeOptions}
        value={(value.officeIds ?? []).map(
          (officeId) => officeId ?? FILTER_OPTION_NULL_VALUE
        )}
        onChange={handleOfficeFilterChange}
        onOpen={() => trackFilterOpened(FILTER_TRACKING_KEYS.office)}
      />
      <CaregiverFilter
        id="branch-filter"
        label="Branch"
        options={branchOptions}
        value={(value.branchIds ?? []).map(
          (branchId) => branchId ?? FILTER_OPTION_NULL_VALUE
        )}
        onChange={handleBranchFilterChange}
        onOpen={() => trackFilterOpened(FILTER_TRACKING_KEYS.branch)}
      />
      <CaregiverFilter
        id="team-filter"
        label="Team"
        options={teamOptions}
        value={(value.teamIds ?? []).map(
          (teamId) => teamId ?? FILTER_OPTION_NULL_VALUE
        )}
        onChange={handleTeamFilterChange}
        onOpen={() => trackFilterOpened(FILTER_TRACKING_KEYS.team)}
      />
      <CaregiverFilter
        id="started-work-filter"
        label="Started work"
        options={startedWorkOptions}
        value={value.hasCompletedFirstShift ?? []}
        onChange={handleStartedWorkFilterChange}
        onOpen={() => trackFilterOpened(FILTER_TRACKING_KEYS.startedWork)}
      />
    </div>
  );
}
