import { SVGProps } from "react";

function HomeLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={17}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m16.544 7.394-.002-.001L9.607.458A1.555 1.555 0 0 0 8.5 0c-.418 0-.811.163-1.107.458L.462 7.39l-.007.008a1.567 1.567 0 0 0 1.09 2.668h.276v5.103c0 1.01.822 1.832 1.832 1.832h2.713a.498.498 0 0 0 .498-.498v-4.001c0-.46.375-.836.836-.836h1.6c.461 0 .836.375.836.836v4.001c0 .275.223.498.498.498h2.713c1.01 0 1.832-.822 1.832-1.832v-5.103h.257c.418 0 .81-.163 1.107-.458.61-.61.61-1.603 0-2.213Zm-.706 1.508a.566.566 0 0 1-.402.167h-.755a.498.498 0 0 0-.498.498v5.601c0 .461-.375.836-.835.836h-2.216V12.5c0-1.01-.822-1.832-1.832-1.832H7.7c-1.01 0-1.832.822-1.832 1.832v3.503H3.653a.837.837 0 0 1-.836-.836v-5.6a.498.498 0 0 0-.498-.499h-.765a.57.57 0 0 1-.392-.97v-.002l6.936-6.934a.565.565 0 0 1 .804 0l6.934 6.932c0 .002.002.003.003.004a.57.57 0 0 1 0 .803Z"
        fill={props.color}
      />
    </svg>
  );
}

export default HomeLogo;
