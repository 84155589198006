import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type JobsPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type JobsPageDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'referralsEnabled'>
    & { branches: Array<(
      { __typename?: 'RewardsOrganizationBranch' }
      & Pick<Types.RewardsOrganizationBranch, 'id' | 'name'>
    )> }
  ) }
);


export const JobsPageDataDocument = gql`
    query JobsPageData {
  getMyRewardsOrganization {
    id
    referralsEnabled
    branches {
      id
      name
    }
  }
}
    `;

/**
 * __useJobsPageDataQuery__
 *
 * To run a query within a React component, call `useJobsPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobsPageDataQuery(baseOptions?: Apollo.QueryHookOptions<JobsPageDataQuery, JobsPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobsPageDataQuery, JobsPageDataQueryVariables>(JobsPageDataDocument, options);
      }
export function useJobsPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobsPageDataQuery, JobsPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobsPageDataQuery, JobsPageDataQueryVariables>(JobsPageDataDocument, options);
        }
export type JobsPageDataQueryHookResult = ReturnType<typeof useJobsPageDataQuery>;
export type JobsPageDataLazyQueryHookResult = ReturnType<typeof useJobsPageDataLazyQuery>;
export type JobsPageDataQueryResult = Apollo.QueryResult<JobsPageDataQuery, JobsPageDataQueryVariables>;