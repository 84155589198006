/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Control,
  FieldValues,
  get,
  Path,
  useFormState,
  useWatch,
} from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { extractNumber } from "@rewards-web/shared/lib/extract-number";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";

interface ReferralRewardPointValueFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  pointsPerDollar: number;
  min: number;
  max: number;
  showExplanationTooltip: boolean;
}

export function ReferralRewardPointValueField<T extends FieldValues>({
  control,
  name,
  pointsPerDollar,
  min,
  max,
  showExplanationTooltip,
}: ReferralRewardPointValueFieldProps<T>) {
  const formState = useFormState({ control });
  const value = useWatch({ control, name });

  const theme = useTheme();
  return (
    <TextField
      css={css`
        width: 290px;
        margin-bottom: auto;
      `}
      {...control.register(name, {
        required: "This field is required",
        validate: (value) => {
          if (value) {
            const valueConvertedToNumber = extractNumber(value);
            if (valueConvertedToNumber < min || valueConvertedToNumber > max) {
              return `Value should be in the range of ${min} and ${max} points`;
            }
          }
        },
      })}
      label="Point value"
      hideLabel
      startAdornment={
        showExplanationTooltip && (
          <Tooltip
            title={`The minimum number of points is ${min}, with a max limit of ${max}`}
          >
            <FontAwesomeIcon
              icon={faQuestionCircle}
              color={theme.palette.grey[600]}
              width={18}
            />
          </Tooltip>
        )
      }
      endAdornment={
        <Typography variant="body" fontWeight={600} color="textPrimary">
          {formatDollars(extractNumber(value) / pointsPerDollar)}
        </Typography>
      }
      error={get(formState.errors, name)}
      size="small"
    />
  );
}
