import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateAgencyCommunicationMutationVariables = Types.Exact<{
  longName?: Types.Maybe<Types.Scalars['String']>;
  mailingAddress?: Types.Maybe<Types.MailingAddressInput>;
}>;


export type UpdateAgencyCommunicationMutation = (
  { __typename?: 'Mutation' }
  & { partialUpdateMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id'>
  ) }
);


export const UpdateAgencyCommunicationDocument = gql`
    mutation UpdateAgencyCommunication($longName: String, $mailingAddress: MailingAddressInput) {
  partialUpdateMyRewardsOrganization(
    longName: $longName
    mailingAddress: $mailingAddress
  ) {
    id
  }
}
    `;
export type UpdateAgencyCommunicationMutationFn = Apollo.MutationFunction<UpdateAgencyCommunicationMutation, UpdateAgencyCommunicationMutationVariables>;

/**
 * __useUpdateAgencyCommunicationMutation__
 *
 * To run a mutation, you first call `useUpdateAgencyCommunicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgencyCommunicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgencyCommunicationMutation, { data, loading, error }] = useUpdateAgencyCommunicationMutation({
 *   variables: {
 *      longName: // value for 'longName'
 *      mailingAddress: // value for 'mailingAddress'
 *   },
 * });
 */
export function useUpdateAgencyCommunicationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAgencyCommunicationMutation, UpdateAgencyCommunicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAgencyCommunicationMutation, UpdateAgencyCommunicationMutationVariables>(UpdateAgencyCommunicationDocument, options);
      }
export type UpdateAgencyCommunicationMutationHookResult = ReturnType<typeof useUpdateAgencyCommunicationMutation>;
export type UpdateAgencyCommunicationMutationResult = Apollo.MutationResult<UpdateAgencyCommunicationMutation>;
export type UpdateAgencyCommunicationMutationOptions = Apollo.BaseMutationOptions<UpdateAgencyCommunicationMutation, UpdateAgencyCommunicationMutationVariables>;