/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

import TotalThreeMonthsRetainedIcon from "../stats-icons/total-3-months-retained-icon";
import TotalSixMonthsRetainedIcon from "../stats-icons/total-6-months-retained-icon";

interface RetentionStatsGroupProps {
  title: string;
  threeMonthsRetentionTotal?: string;
  sixMonthsRetentionTotal?: string;
}

export function RetentionStatsGroup({
  title,
  threeMonthsRetentionTotal,
  sixMonthsRetentionTotal,
}: RetentionStatsGroupProps): JSX.Element | null {
  return (
    <div
      css={(theme: AppTheme) => css`
        width: 160px;
        height: 180px;
        border-width: 1px;
        border-color: ${theme.palette.divider};
        margin-right: ${theme.spacing(1.5)};
        border-style: solid;
        padding: ${theme.spacing(2.5)};
        border-radius: 5px;
        justify-content: center;
      `}
    >
      <Typography
        variant="h4"
        css={(theme: AppTheme) =>
          css`
            margin-bottom: ${theme.spacing(1.5)};
            text-transform: uppercase;
          `
        }
      >
        {title}
      </Typography>
      {threeMonthsRetentionTotal && (
        <>
          <div
            css={css`
              display: flex;
            `}
          >
            <TotalThreeMonthsRetainedIcon />
            <Typography
              variant="h3"
              display="inline"
              css={(theme: AppTheme) =>
                css`
                  margin-left: ${theme.spacing(1)};
                  color: ${theme.palette.text.secondary};
                `
              }
            >
              {threeMonthsRetentionTotal}
            </Typography>
          </div>
          <Typography
            variant="body"
            css={(theme: AppTheme) =>
              css`
                color: ${theme.palette.text.secondary};
                font-size: 0.8em;
                margin-bottom: ${theme.spacing(0.5)};
              `
            }
          >
            3 - Months
          </Typography>
        </>
      )}

      {sixMonthsRetentionTotal && (
        <>
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              margin-top: ${theme.spacing(1.5)};
            `}
          >
            <TotalSixMonthsRetainedIcon />
            <Typography
              variant="h3"
              display="inline"
              css={(theme: AppTheme) =>
                css`
                  margin-left: ${theme.spacing(1)};
                  color: ${theme.palette.text.secondary};
                `
              }
            >
              {sixMonthsRetentionTotal}
            </Typography>
          </div>
          <Typography
            variant="body"
            css={(theme: AppTheme) =>
              css`
                color: ${theme.palette.text.secondary};
                font-size: 0.8em;
                margin-bottom: ${theme.spacing(0.5)};
              `
            }
          >
            6 - Months
          </Typography>
        </>
      )}
    </div>
  );
}
