/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { CandidateRecruitmentStepName } from "@rewards-web/shared/graphql-types";
import { AppTheme } from "@rewards-web/shared/style/theme";

import CandidateLogo from "../../../../../shared/components/page-layout/side-menu/candidate-logo";
import { StatusLink } from "../status-link";
import PointerIcon from "./pointer-icon";

export function HiringPipelineEmptyState(): JSX.Element {
  return (
    <div
      css={css`
        text-align: center;
      `}
    >
      <CandidateLogo
        color="#444444"
        css={css`
          width: 70px;
          height: 70px;
        `}
      />

      <Typography
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(1)};
        `}
        variant="h3"
      >
        No Jobs
      </Typography>

      <Typography
        css={(theme: AppTheme) => css`
          max-width: 600px;
          margin: auto;
          margin-bottom: ${theme.spacing(4)};
        `}
        variant="body"
        color="textSecondary"
      >
        The hiring pipeline gives you an overview of the referrals that your
        employees have recruited. This section will be enabled after you have
        created at least one job
      </Typography>

      <div
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(1.5)};
          max-width: 500px;
          margin: auto;
          border-width: 1px;
          border-color: ${theme.palette.divider};
          border-radius: 10px;
          border-style: solid;
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: ${theme.spacing(1)};
            margin-top: ${theme.spacing(2)};
            line-height: 45px;
          `}
        >
          <div>
            <Typography
              css={css`
                display: inline-block;
              `}
            >
              <strong>Contacted?</strong>
              <br />
              (New Applicants)
            </Typography>
          </div>
          <div
            css={css`
              height: 100%;
            `}
          >
            <Typography
              css={css`
                display: inline-block;
                font-weight: bold;
              `}
            >
              Scheduled?
            </Typography>
          </div>
          <div>
            <Typography
              css={css`
                display: inline-block;
                font-weight: bold;
              `}
            >
              Offer Extended?
            </Typography>
          </div>
        </div>
        <div
          css={(theme: AppTheme) => css`
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            margin-top: ${theme.spacing(2)};
            margin-bottom: ${theme.spacing(2)};
            justify-items: center;
            pointer-events: none;
          `}
        >
          <div>
            <StatusLink
              hovered
              disableTab
              stepStatus={{
                __typename:
                  "NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment",
                recruitmentStepName: CandidateRecruitmentStepName.Contacted,
                numActiveCandidates: 13,
              }}
            />
            <div
              css={(theme: AppTheme) => css`
                position: relative;
                float: right;
                margin-top: -${theme.spacing(1)};
                margin-right: ${theme.spacing(0.5)};
              `}
            >
              <PointerIcon />
            </div>
          </div>
          <div
            css={css`
              height: 100%;
            `}
          >
            <StatusLink
              disableTab
              stepStatus={{
                __typename:
                  "NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment",
                recruitmentStepName:
                  CandidateRecruitmentStepName.InterviewScheduled,
                numActiveCandidates: 8,
              }}
            />
          </div>
          <div>
            <StatusLink
              disableTab
              stepStatus={{
                __typename:
                  "NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment",
                recruitmentStepName:
                  CandidateRecruitmentStepName.InterviewSuccessful,
                numActiveCandidates: 0,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
