/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

import { Card } from "@rewards-web/shared/components/card";
import { AppTheme } from "@rewards-web/shared/style/types";

import { DateRangeSelectField } from "../../components/date-range-select-field";
import {
  DateRangeOptions,
  DateRangeSelection,
} from "../../components/date-range-select-field/types";
import { getDefaultDateRangeSelection } from "../../components/date-range-select-field/utils";
import { EngagementInsightMetricGroup } from "../../insight-metric-groups/engagement";
import { OnboardingInsightMetricGroup } from "../../insight-metric-groups/onboarding";
import { ReferralsInsightMetricGroup } from "../../insight-metric-groups/referrals";

interface QuarterlyInsightMetricGroupRangeProps {
  quarterlyDateRangeOptions: {
    startDate: number;
    endDate: number;
    type: "quarterly";
  }[];
  timezone: string;
  referralsEnabled: boolean;
  hasContactedStep: boolean;
}

/**
 * A collection of insight metric groups that only use quarterly date ranges.
 */
export function QuarterlyInsightMetricGroupRange({
  quarterlyDateRangeOptions,
  timezone,
  referralsEnabled,
  hasContactedStep,
}: QuarterlyInsightMetricGroupRangeProps) {
  const dateRangeOptions: DateRangeOptions = {
    quarterly: quarterlyDateRangeOptions,
    monthly: [],
  };
  const [
    selectedDateRange,
    setSelectedDateRange,
  ] = useState<DateRangeSelection | null>(
    getDefaultDateRangeSelection(dateRangeOptions)
  );

  if (!selectedDateRange) {
    return null;
  }

  return (
    <Card
      css={(theme: AppTheme) => css`
        position: relative;
        padding: ${theme.spacing(3)};
        padding-top: ${theme.spacing(2.5)};
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(3.75)};
      `}
    >
      <div
        css={(theme: AppTheme) => css`
          position: absolute;
          right: ${theme.spacing(3)};
          top: ${theme.spacing(2.5)};
        `}
      >
        <DateRangeSelectField
          value={
            selectedDateRange
              ? {
                  startDate: selectedDateRange.currentRange.startDate.valueOf(),
                  endDate: selectedDateRange.currentRange.endDate.valueOf(),
                  type: selectedDateRange.type,
                }
              : undefined
          }
          options={dateRangeOptions}
          timezone={timezone}
          setDateRange={(params) =>
            setSelectedDateRange({ ...params, type: "quarterly" })
          }
          trackingProperties={{ dateRangeName: "quarterly" }}
        />
      </div>
      <ReferralsInsightMetricGroup
        currentRange={selectedDateRange.currentRange}
        previousRange={selectedDateRange.previousRange?.partialRange ?? null}
        referralsEnabled={referralsEnabled}
        hasContactedStep={hasContactedStep}
      />
      <OnboardingInsightMetricGroup />
      <EngagementInsightMetricGroup
        currentRange={selectedDateRange.currentRange}
        previousRange={selectedDateRange.previousRange?.partialRange ?? null}
      />
    </Card>
  );
}
