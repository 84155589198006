import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UploadBulkUserFileMutationVariables = Types.Exact<{
  file: Types.Scalars['Upload'];
}>;


export type UploadBulkUserFileMutation = (
  { __typename?: 'Mutation' }
  & { submitBulkRewardsUserUploadFile: (
    { __typename?: 'BulkRewardsUserUpload' }
    & Pick<Types.BulkRewardsUserUpload, 'id'>
  ) }
);


export const UploadBulkUserFileDocument = gql`
    mutation UploadBulkUserFile($file: Upload!) {
  submitBulkRewardsUserUploadFile(file: $file) {
    id
  }
}
    `;
export type UploadBulkUserFileMutationFn = Apollo.MutationFunction<UploadBulkUserFileMutation, UploadBulkUserFileMutationVariables>;

/**
 * __useUploadBulkUserFileMutation__
 *
 * To run a mutation, you first call `useUploadBulkUserFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBulkUserFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBulkUserFileMutation, { data, loading, error }] = useUploadBulkUserFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadBulkUserFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadBulkUserFileMutation, UploadBulkUserFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadBulkUserFileMutation, UploadBulkUserFileMutationVariables>(UploadBulkUserFileDocument, options);
      }
export type UploadBulkUserFileMutationHookResult = ReturnType<typeof useUploadBulkUserFileMutation>;
export type UploadBulkUserFileMutationResult = Apollo.MutationResult<UploadBulkUserFileMutation>;
export type UploadBulkUserFileMutationOptions = Apollo.BaseMutationOptions<UploadBulkUserFileMutation, UploadBulkUserFileMutationVariables>;