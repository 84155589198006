import * as React from "react";

function GoalsLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="octicon:goal-24">
        <path
          id="Vector"
          d="M19.8315 6.57049H18.0481L13.6723 10.9443C13.823 11.3284 13.8369 11.7527 13.7116 12.1458C13.5863 12.5389 13.3294 12.8769 12.9842 13.1029C12.639 13.3289 12.2265 13.4291 11.8161 13.3867C11.4057 13.3444 11.0224 13.162 10.7306 12.8702C10.4389 12.5785 10.2565 12.1952 10.2141 11.7847C10.1717 11.3743 10.272 10.9618 10.498 10.6166C10.724 10.2714 11.0619 10.0146 11.455 9.88924C11.8481 9.76392 12.2724 9.77778 12.6565 9.92849L17.0313 5.5537V3.77024C17.0314 3.53159 17.1262 3.30274 17.2948 3.13391L18.9527 1.47599C18.9834 1.44511 19.0219 1.42317 19.0642 1.41254C19.1064 1.4019 19.1507 1.40296 19.1923 1.41561C19.2776 1.44053 19.3409 1.51145 19.3581 1.59866L19.799 3.80378L22.0031 4.24461C22.0894 4.26186 22.1603 4.32511 22.1852 4.41041C22.1978 4.45184 22.1989 4.49591 22.1884 4.53792C22.178 4.57994 22.1563 4.61834 22.1258 4.64903L20.4669 6.30695C20.2983 6.47538 20.0699 6.57014 19.8315 6.57049Z"
          fill={props.color}
        />
        <path
          id="Vector_2"
          d="M3.01562 11.6016C3.01562 13.9844 3.96219 16.2696 5.64709 17.9545C7.33198 19.6394 9.6172 20.5859 12 20.5859C13.1798 20.5859 14.3481 20.3536 15.4382 19.9021C16.5282 19.4505 17.5186 18.7888 18.3529 17.9545C19.1872 17.1202 19.849 16.1298 20.3005 15.0397C20.752 13.9497 20.9844 12.7814 20.9844 11.6016C20.9844 10.741 20.8636 9.90915 20.6384 9.12236C20.5937 8.94171 20.6207 8.75073 20.7139 8.58962C20.8071 8.42851 20.9591 8.30983 21.138 8.25855C21.317 8.20727 21.5088 8.22739 21.6732 8.31469C21.8375 8.40198 21.9617 8.54963 22.0194 8.72657C22.282 9.64082 22.4219 10.6049 22.4219 11.6016C22.4219 17.3573 17.7558 22.0234 12 22.0234C6.24425 22.0234 1.57812 17.3573 1.57812 11.6016C1.57812 5.84582 6.24425 1.17969 12 1.17969C12.9727 1.17873 13.9397 1.31386 14.875 1.58123C14.9662 1.60682 15.0514 1.65017 15.1258 1.70881C15.2002 1.76744 15.2623 1.8402 15.3084 1.92291C15.3546 2.00561 15.384 2.09662 15.3949 2.19071C15.4057 2.2848 15.3979 2.38011 15.3718 2.47117C15.3458 2.56223 15.3019 2.64723 15.2429 2.7213C15.1839 2.79536 15.1108 2.85703 15.0278 2.90275C14.9449 2.94847 14.8537 2.97734 14.7595 2.9877C14.6654 2.99807 14.5701 2.98973 14.4792 2.96315C13.6729 2.73301 12.8385 2.61657 12 2.61719C9.6172 2.61719 7.33198 3.56376 5.64709 5.24866C3.96219 6.93355 3.01562 9.21876 3.01562 11.6016Z"
          fill={props.color}
        />
        <path
          id="Vector_3"
          d="M7.32841 11.6014C7.33108 12.2361 7.46309 12.8636 7.71639 13.4456C7.96968 14.0276 8.33893 14.5519 8.8016 14.9864C9.26428 15.421 9.81066 15.7566 10.4074 15.973C11.0041 16.1893 11.6387 16.2817 12.2723 16.2446C12.906 16.2075 13.5254 16.0416 14.0928 15.7571C14.6602 15.4727 15.1637 15.0755 15.5725 14.5899C15.9813 14.1044 16.2868 13.5406 16.4705 12.933C16.6541 12.3254 16.712 11.6868 16.6405 11.0561C16.6204 10.9283 16.6352 10.7975 16.6834 10.6775C16.7315 10.5575 16.8113 10.4528 16.9141 10.3744C17.0169 10.296 17.1391 10.2469 17.2676 10.2323C17.396 10.2177 17.5261 10.2381 17.6439 10.2913C17.8844 10.3987 18.0464 10.6277 18.0675 10.8903C18.2163 12.1507 17.9687 13.4262 17.3592 14.5395C16.7496 15.6527 15.8084 16.5484 14.6663 17.1021C13.5243 17.6557 12.2381 17.8398 10.9865 17.6287C9.73502 17.4177 8.58034 16.822 7.68299 15.9244C6.78589 15.0278 6.19003 13.8743 5.97804 12.6238C5.76606 11.3733 5.94846 10.0878 6.49995 8.94567C7.05144 7.80351 7.94468 6.86127 9.05579 6.24963C10.1669 5.63799 11.4408 5.38727 12.7008 5.53224C12.7961 5.54107 12.8886 5.56884 12.973 5.6139C13.0574 5.65896 13.1319 5.72042 13.1922 5.79466C13.2526 5.86891 13.2975 5.95445 13.3243 6.04627C13.3511 6.13809 13.3594 6.23434 13.3485 6.32939C13.3377 6.42444 13.308 6.51636 13.2612 6.59978C13.2143 6.68319 13.1513 6.75642 13.0758 6.81517C13.0003 6.87391 12.9138 6.917 12.8215 6.9419C12.7291 6.96679 12.6327 6.973 12.5379 6.96015C11.8834 6.88448 11.2203 6.94808 10.5921 7.14678C9.96387 7.34547 9.38479 7.67477 8.89284 8.11306C8.40088 8.55134 8.00718 9.08871 7.73756 9.6899C7.46794 10.2911 7.32851 10.9425 7.32841 11.6014Z"
          fill={props.color}
        />
      </g>
    </svg>
  );
}

export default GoalsLogo;
