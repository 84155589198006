import { createContext } from "react";

export interface FeatureFlagUser {
  userId: string;
  attributes: Record<
    string,
    string | number | boolean | Array<string | number>
  >;
}

export interface FeatureFlagContextValue {
  user: FeatureFlagUser | null;
  setUser(user: FeatureFlagUser | null): void;
  initialized: boolean;
  getFlagEnabled(key: string): boolean;
  getAppFlagEnabled(key: string, opts?: { default?: boolean }): boolean;
}

export const FeatureFlagContext = createContext<FeatureFlagContextValue>({
  user: null,
  setUser: () => {},
  initialized: true,
  getFlagEnabled: () => false,
  getAppFlagEnabled: () => false,
});
