/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { CardContent as MuiCardContent } from "@material-ui/core";
import { ReactNode } from "react";

import { StylableProps } from "../../types";

export interface CardContentProps extends StylableProps {
  /**
   * The contents of the card.
   *
   * This should only include the other child Card components,
   * such as `CardContent` or `CardActions`.
   */
  children: ReactNode;
}

export function CardContent({
  children,
  className,
}: CardContentProps): JSX.Element {
  return (
    <MuiCardContent
      className={className}
      css={css`
        box-sizing: border-box;
      `}
    >
      {children}
    </MuiCardContent>
  );
}
