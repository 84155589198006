/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Outlet } from "react-router";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { NavigationTabs } from "../../../shared/components/navigation-tabs";

export function SurveysPageContents() {
  const navTabs = [
    {
      label: "Pulse",
      path: "/surveys/pulse",
    },
  ];

  return (
    <>
      <Typography variant="h1" color="textPrimary" gutterBottom>
        Surveys
      </Typography>
      <NavigationTabs ariaLabel="Surveys navigation" tabs={navTabs} />

      <div
        css={(theme: AppTheme) => css`
          margin-top: ${theme.spacing(4)};
        `}
      >
        <Outlet />
      </div>
    </>
  );
}
