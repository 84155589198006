import { compact } from "lodash";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { RewardsAdminPermissionsType } from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

import { NavigationTabs } from "../../../../shared/components/navigation-tabs";
import { useEmployeeAutoSyncAvailableQuery } from "../../../../shared/modules/employee-auto-sync/hooks";
import { usePermissionsQuery } from "../../../../shared/modules/permissions/hooks/use-permissions-query";
import { useEmployeeTabsIdentityDataQuery } from "./employee-tabs-identity-query.generated";

export enum EmployeesPageTab {
  Accounts = "Accounts",
  Groups = "Groups",
  AutoSync = "AutoSync",
}

export const EmployeesPageTabPathByTab = {
  [EmployeesPageTab.Accounts]: "/employees/accounts",
  [EmployeesPageTab.Groups]: "/employees/groups",
  [EmployeesPageTab.AutoSync]: "/employees/auto-sync",
};

export function EmployeesPageTabs() {
  const featureFlagEnabled = useFeatureFlag("admin-app-groups-ui-enabled-temp");

  const identityQuery = useEmployeeTabsIdentityDataQuery({
    onError: reportError,
  });

  const permissions = usePermissionsQuery();

  const fullAccessAdmin =
    permissions.data?.getMyRewardsAdminUser.permissions.type ===
    RewardsAdminPermissionsType.FullAccess;

  const autoSyncAvailableQuery = useEmployeeAutoSyncAvailableQuery();

  if (identityQuery.error || autoSyncAvailableQuery.error) {
    return (
      <Alert
        severity="error"
        message="An unexpected error occurred. Please try again later."
      />
    );
  }

  if (!identityQuery.data || !autoSyncAvailableQuery.data) {
    return <PageLoadingState />;
  }

  const tabs = compact([
    EmployeesPageTab.Accounts,
    featureFlagEnabled && EmployeesPageTab.Groups,
    fullAccessAdmin && autoSyncAvailableQuery.data?.enabled
      ? EmployeesPageTab.AutoSync
      : null,
  ]);

  return (
    <NavigationTabs
      ariaLabel="Employee navigation"
      tabs={tabs.map((tab) => ({
        path: EmployeesPageTabPathByTab[tab],
        label: (() => {
          switch (tab) {
            case EmployeesPageTab.Accounts:
              return "Accounts";
            case EmployeesPageTab.Groups:
              return "Groups";
            case EmployeesPageTab.AutoSync:
              return "Auto Sync";
            default:
              assertNever(tab);
          }
        })(),
      }))}
    />
  );
}
