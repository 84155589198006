import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAwardedRecognitionPointsByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetAwardedRecognitionPointsByIdQuery = (
  { __typename?: 'Query' }
  & { getRecognitionPointsById?: Types.Maybe<(
    { __typename?: 'RecognitionPoints' }
    & Pick<Types.RecognitionPoints, 'id' | 'message' | 'from' | 'pointValue' | 'sentAt' | 'mustBeClaimed' | 'claimedAt'>
    & { employee: (
      { __typename?: 'RewardsUser' }
      & Pick<Types.RewardsUser, 'id' | 'firstName' | 'lastName'>
    ), reminders: Array<(
      { __typename?: 'BonusPointsReminder' }
      & Pick<Types.BonusPointsReminder, 'id' | 'sentAt'>
    )> }
  )> }
);


export const GetAwardedRecognitionPointsByIdDocument = gql`
    query GetAwardedRecognitionPointsById($id: ID!) {
  getRecognitionPointsById(id: $id) {
    id
    employee {
      id
      firstName
      lastName
    }
    message
    from
    pointValue
    sentAt
    mustBeClaimed
    claimedAt
    reminders {
      id
      sentAt
    }
  }
}
    `;

/**
 * __useGetAwardedRecognitionPointsByIdQuery__
 *
 * To run a query within a React component, call `useGetAwardedRecognitionPointsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAwardedRecognitionPointsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAwardedRecognitionPointsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAwardedRecognitionPointsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAwardedRecognitionPointsByIdQuery, GetAwardedRecognitionPointsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAwardedRecognitionPointsByIdQuery, GetAwardedRecognitionPointsByIdQueryVariables>(GetAwardedRecognitionPointsByIdDocument, options);
      }
export function useGetAwardedRecognitionPointsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAwardedRecognitionPointsByIdQuery, GetAwardedRecognitionPointsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAwardedRecognitionPointsByIdQuery, GetAwardedRecognitionPointsByIdQueryVariables>(GetAwardedRecognitionPointsByIdDocument, options);
        }
export type GetAwardedRecognitionPointsByIdQueryHookResult = ReturnType<typeof useGetAwardedRecognitionPointsByIdQuery>;
export type GetAwardedRecognitionPointsByIdLazyQueryHookResult = ReturnType<typeof useGetAwardedRecognitionPointsByIdLazyQuery>;
export type GetAwardedRecognitionPointsByIdQueryResult = Apollo.QueryResult<GetAwardedRecognitionPointsByIdQuery, GetAwardedRecognitionPointsByIdQueryVariables>;