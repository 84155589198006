/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { compact } from "lodash";
import { Outlet } from "react-router";

import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { RewardsAdminPermissionsType } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { ErrorPage } from "@rewards-web/shared/pages/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { NavigationTabs } from "../../../shared/components/navigation-tabs";
import { usePermissionsQuery } from "../../../shared/modules/permissions/hooks/use-permissions-query";
import { useSettingsPageDataQuery } from "./settings-page-data.generated";

export function SettingsPageContent() {
  const {
    data: settingsDataQueryData,
    error: settingsDataQueryError,
  } = useSettingsPageDataQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
  });
  const { data: permissionsQueryData } = usePermissionsQuery();
  const evvRulesEnabled = useFeatureFlag("admin-app-evv-rules-temp");

  const goalsEnabled = useFeatureFlag(
    "admin-app-budget-utilization-goal-available-temp"
  ); // add flags for other goal types here

  const hasFullAccessLevel =
    permissionsQueryData?.getMyRewardsAdminUser.permissions.type ===
    RewardsAdminPermissionsType.FullAccess;

  if (settingsDataQueryError) {
    return <ErrorPage />;
  }

  if (!settingsDataQueryData) {
    return <PageLoadingState />;
  }

  const navTabs = (() => {
    const tabsForAllAdmins = [
      {
        label: "Referral Structure",
        path: "/settings/referral-structure",
      },
      ...(evvRulesEnabled
        ? [
            {
              label: "EVV Compliance",
              path: "/settings/evv-rules",
            },
          ]
        : []),
    ];

    const tabsForFullAccessAdmins = hasFullAccessLevel
      ? [
          goalsEnabled && {
            label: "Coordinator Goals",
            path: "/settings/coordinator-goals",
          },
          {
            label: "Recognition",
            path: "/settings/recognition",
          },
          {
            label: "Communication",
            path: "/settings/communication",
          },
          settingsDataQueryData?.getMyRewardsOrganization?.usesWorkDevices && {
            label: "Employee Accounts",
            path: "/settings/employee-accounts",
          },
          settingsDataQueryData?.getMyRewardsOrganization?.referralsEnabled && {
            label: "Job Postings",
            path: "/settings/job-posting",
          },
          {
            label: "Redemption",
            path: "/settings/redemption",
          },
        ]
      : [];

    const allTabs = compact([...tabsForAllAdmins, ...tabsForFullAccessAdmins]);

    return allTabs;
  })();

  return (
    <>
      <Typography variant="h1" color="textPrimary" gutterBottom>
        Settings
      </Typography>
      <NavigationTabs
        ariaLabel="Settings navigation"
        tabs={navTabs}
        tabsVariant="scrollable"
      />

      <div
        css={(theme: AppTheme) => css`
          margin-top: ${theme.spacing(4)};
        `}
      >
        <Outlet />
      </div>
    </>
  );
}
