import { CircularProgress } from "@material-ui/core";

import { StylableProps } from "@rewards-web/shared/types";

export interface ProgressSpinnerProps extends StylableProps {
  size?: number;
  thickness?: number;
}

export function ProgressSpinner({
  className,
  size,
  thickness,
}: ProgressSpinnerProps): JSX.Element {
  return (
    <CircularProgress className={className} thickness={thickness} size={size} />
  );
}
