/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import {
  faBellExclamation,
  faCheckCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Table } from "@rewards-web/shared/components/table-components/table";
import { TableBody } from "@rewards-web/shared/components/table-components/table-body";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableHeaders } from "@rewards-web/shared/components/table-components/table-headers";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { DrawWinnerClaimedPrize } from "@rewards-web/shared/graphql-types";
import { AppTheme } from "@rewards-web/shared/style/types";

import { getPrizeTierName } from "../lib";
import { DrawDetailsQuery } from "./draw-details.generated";

interface WinnersTableProps {
  winners: NonNullable<DrawDetailsQuery["draw"]>["winners"];
  prizes: Extract<
    Extract<
      NonNullable<DrawDetailsQuery["draw"]>["prizeStructure"],
      { __typename: "DrawPrizeStructureTieredPrizesMultipleWinners" }
    >["prizes"][number],
    { __typename: "DrawTieredPrizePoints" }
  >[];
}
type WinnerType = NonNullable<
  NonNullable<DrawDetailsQuery["draw"]>["winners"]
>[number];
export function WinnersTable({ winners, prizes }: WinnersTableProps) {
  const theme = useTheme();
  if (!winners) {
    return <div>No winners available</div>;
  }
  const claimedPrizeStatus = (winner: WinnerType) => {
    return winner.claimedPrize === DrawWinnerClaimedPrize.Yes ? (
      <Tooltip title="Employee has claimed points">
        <FontAwesomeIcon
          icon={faCheckCircle}
          color={theme.palette.success.main}
          css={css`
            margin-right: 10px;
            width: 18px;
            height: 18px;
            vertical-align: middle;
          `}
        />
      </Tooltip>
    ) : (
      <Tooltip title="Employee hasn't claimed points">
        <FontAwesomeIcon
          icon={faBellExclamation}
          color={theme.palette.gold.light}
          css={css`
            margin-right: 10px;
            width: 18px;
            height: 18px;
            vertical-align: middle;
          `}
        />
      </Tooltip>
    );
  };

  return (
    <>
      <Table
        css={(theme: AppTheme) =>
          css`
            margin-bottom: ${theme.spacing(4)};
          `
        }
      >
        <TableHeaders>
          <TableRow>
            <TableHeader>
              <Typography variant="subtitle" color="textPrimary">
                Name
              </Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="subtitle" color="textPrimary">
                Email
              </Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="subtitle" color="textPrimary">
                Prize Value
              </Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="subtitle" color="textPrimary">
                Category
              </Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="subtitle" color="textPrimary">
                Status
              </Typography>
            </TableHeader>
          </TableRow>
        </TableHeaders>
        <TableBody>
          {winners
            .filter((winner) => winner.prizeTierIndex !== 2)
            .map((winner) => {
              const { prizeTierIndex } = winner;
              const prize =
                prizeTierIndex !== undefined &&
                prizeTierIndex !== null &&
                prizeTierIndex < prizes.length
                  ? prizes[prizeTierIndex]
                  : null;
              return (
                <TableRow key={winner.id}>
                  <TableCell divider>
                    <Typography variant="body" color="grey.800">
                      {winner.user.firstName} {winner.user.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell divider>
                    <Typography variant="body" color="grey.800">
                      {(() => {
                        if (
                          winner.user.personalContactInfo?.__typename ===
                          "RewardsUserPersonalContactInfoSuppressed"
                        ) {
                          return "(Suppressed)";
                        }

                        return (
                          winner.user.personalContactInfo?.email ??
                          winner.user.workEmail ??
                          "-"
                        );
                      })()}
                    </Typography>
                  </TableCell>

                  <TableCell divider>
                    <Typography variant="body" color="grey.800">
                      {prize ? prize.pointValue : "N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell divider>
                    <Typography variant="body" color="grey.800">
                      {prizeTierIndex !== undefined && prizeTierIndex !== null
                        ? getPrizeTierName(prizeTierIndex)
                        : "N/A"}
                    </Typography>
                  </TableCell>

                  <TableCell divider>
                    <Typography variant="body" color="grey.800">
                      {claimedPrizeStatus(winner)}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </>
  );
}
