import * as React from "react";

function MergeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 10"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16 5a.403.403 0 01-.126.298l-1.97 1.87a.452.452 0 01-.616 0 .397.397 0 010-.582l1.246-1.173H7.9L5.757 8.385C5.029 9.395 3.82 10 2.53 10H.434A.424.424 0 010 9.588c0-.228.194-.412.434-.412H2.53c1.005 0 1.944-.471 2.511-1.257L7.151 5l-2.11-2.92C4.474 1.294 3.535.824 2.531.824H.433A.423.423 0 010 .412C0 .184.194 0 .434 0H2.53c1.291 0 2.5.604 3.227 1.615L7.899 4.59h6.607L13.264 3.41a.399.399 0 01.002-.585.453.453 0 01.617.002l1.928 1.832c.119.077.19.205.189.342z" />
    </svg>
  );
}

export default MergeIcon;
