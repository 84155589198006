/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import ErrorOutlinedIcon from "@material-ui/icons/ErrorOutlined";

import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface DisclaimerProps {
  message: string;
  tooltipMessage: string;
}

export function Disclaimer({
  message,
  tooltipMessage,
}: DisclaimerProps): JSX.Element {
  return (
    <div
      css={(theme: AppTheme) => css`
        display: grid;
        grid-template-columns:
          20px
          calc(95% - ${theme.spacing(1)});
        grid-column-gap: ${theme.spacing(1)};
        background-color: #e2b93b;
        border-radius: 5px;
        align-items: center;
        color: white;
        min-height: 20px;
        max-width: fit-content;
        max-height: fit-content;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(172, 172, 42, 0.3);
          width: 100%;
          height: 100%;
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
        `}
      >
        <ErrorOutlinedIcon
          css={css`
            font-size: 1em;
          `}
        />
      </div>
      <Tooltip
        title={tooltipMessage}
        placement="bottom"
        disabled={!tooltipMessage}
      >
        <Typography
          css={css`
            font-size: 0.8em;
            font-weight: bold;
            margin-right: 8px;
          `}
        >
          {message}
        </Typography>
      </Tooltip>
    </div>
  );
}
