import { reportError } from "@rewards-web/shared/modules/error";

import { useJobsListQuery } from "../../graphql/jobs-list.generated";

export function useAdminJobsTableQuery(params: {
  currentPageIndex: number;
  perPage: number;
  includeStats?: boolean;
  branchIds?: Array<string | null>;
}) {
  const { data, loading, refetch } = useJobsListQuery({
    variables: {
      branchIds: params.branchIds,
      limit: params.perPage,
      offset: params.currentPageIndex * params.perPage,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onError: reportError,
  });
  const items = data?.listJobPostings!.items;
  const total = data?.listJobPostings!.total;

  return {
    items,
    total,
    loading,
    refetch,
  };
}
