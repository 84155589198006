import { faMobile } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LoggedInUsersInsightMetric } from "@rewards-web/shared/graphql-types";

import { InsightMetric } from "../../../../components/insight-metric";

const getLoginFraction = ({
  usersWhoLoggedInCount,
  activeUserCount,
}: LoggedInUsersInsightMetric) => ({
  numerator: Math.min(usersWhoLoggedInCount, activeUserCount), // capped at 100%
  denominator: activeUserCount,
});

export interface AllTimeUserLoginsInsightMetricProps {
  userLoginsInsightMetric: LoggedInUsersInsightMetric | undefined;
}

export function AllTimeUserLoginsInsightMetric({
  userLoginsInsightMetric,
}: AllTimeUserLoginsInsightMetricProps) {
  return (
    <InsightMetric
      label="Log ins"
      icon={<FontAwesomeIcon icon={faMobile} />}
      tooltipText="Percentage of caregivers who logged into the Caribou app since launch."
      value={
        userLoginsInsightMetric !== undefined
          ? {
              type: "percent",
              ...getLoginFraction(userLoginsInsightMetric),
            }
          : {
              type: "loading",
            }
      }
      percentPointChange={null}
    />
  );
}
