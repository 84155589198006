import { useEffect } from "react";
import { useNavigate } from "react-router";

import { ONBOARDING_STEP_PATH_BY_KEY } from "../constants";
import { useOnboardingState } from "../hooks/use-onboarding-state";

/**
 * Logic for determining which onboarding step to show first for the user
 * when they hit the /onboarding screen.
 */
export function OnboardingIndex(): null {
  const navigate = useNavigate();
  const onboardingState = useOnboardingState({ loadFreshData: true });

  useEffect(() => {
    if (!onboardingState.loading) {
      const firstUncompletedStep = onboardingState.orderedSteps.find(
        (step) => !onboardingState.completedSteps.has(step)
      );

      if (firstUncompletedStep) {
        navigate(
          `/onboarding/${ONBOARDING_STEP_PATH_BY_KEY[firstUncompletedStep]}`,
          {
            replace: true,
          }
        );
      }
    }
  }, [navigate, onboardingState]);

  return null;
}
