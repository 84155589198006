import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AutoSyncIntegrationsTableDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AutoSyncIntegrationsTableDataQuery = (
  { __typename?: 'Query' }
  & { organization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'launched'>
  ), hhaxIntegrations: Array<(
    { __typename?: 'HHAXIntegration' }
    & Pick<Types.HhaxIntegration, 'id' | 'status'>
    & { userAutoSyncs: Array<Types.Maybe<(
      { __typename?: 'HHAXCaribouUserSync' }
      & Pick<Types.HhaxCaribouUserSync, 'id' | 'hhaxIntegrationId' | 'enabled'>
      & { filters?: Types.Maybe<(
        { __typename?: 'HHAXCaribouUserSyncFilters' }
        & Pick<Types.HhaxCaribouUserSyncFilters, 'disciplines' | 'hasCompletedFirstShift'>
        & { branches: Array<(
          { __typename?: 'HHAXCaribouUserSyncBranchFilterOption' }
          & Pick<Types.HhaxCaribouUserSyncBranchFilterOption, 'branchId'>
        )>, offices: Array<(
          { __typename?: 'HHAXCaribouUserSyncOfficeFilterOption' }
          & Pick<Types.HhaxCaribouUserSyncOfficeFilterOption, 'officeId'>
        )>, teams: Array<(
          { __typename?: 'HHAXCaribouUserSyncTeamFilterOption' }
          & Pick<Types.HhaxCaribouUserSyncTeamFilterOption, 'teamId'>
        )> }
      )> }
    )>> }
  )>, alayaCareIntegrations: Array<(
    { __typename?: 'AlayaCareIntegration' }
    & Pick<Types.AlayaCareIntegration, 'id' | 'enabled'>
    & { userAutoSyncs: Array<(
      { __typename?: 'AlayaCareCaribouUserSync' }
      & Pick<Types.AlayaCareCaribouUserSync, 'id' | 'enabled'>
      & { filters: (
        { __typename?: 'AlayaCareCaribouUserSyncFilters' }
        & Pick<Types.AlayaCareCaribouUserSyncFilters, 'jobTitles' | 'emailDomains'>
        & { departments: Array<(
          { __typename?: 'AlayaCareCaribouUserSyncFilterOption' }
          & Pick<Types.AlayaCareCaribouUserSyncFilterOption, 'id'>
        )>, branches: Array<(
          { __typename?: 'AlayaCareCaribouUserSyncFilterOption' }
          & Pick<Types.AlayaCareCaribouUserSyncFilterOption, 'id'>
        )>, groups: Array<(
          { __typename?: 'AlayaCareCaribouUserSyncFilterOption' }
          & Pick<Types.AlayaCareCaribouUserSyncFilterOption, 'id'>
        )>, roles: Array<(
          { __typename?: 'AlayaCareCaribouUserSyncFilterOption' }
          & Pick<Types.AlayaCareCaribouUserSyncFilterOption, 'id'>
        )> }
      ) }
    )> }
  )> }
);


export const AutoSyncIntegrationsTableDataDocument = gql`
    query AutoSyncIntegrationsTableData {
  organization: getMyRewardsOrganization {
    id
    launched
  }
  hhaxIntegrations: getEnabledHHAXIntegrations {
    id
    status
    userAutoSyncs {
      id
      hhaxIntegrationId
      enabled
      filters {
        disciplines
        branches {
          branchId
        }
        offices {
          officeId
        }
        teams {
          teamId
        }
        hasCompletedFirstShift
      }
    }
  }
  alayaCareIntegrations: getEnabledAlayaCareIntegrations {
    id
    enabled
    userAutoSyncs {
      id
      enabled
      filters {
        departments {
          id
        }
        branches {
          id
        }
        groups {
          id
        }
        roles {
          id
        }
        jobTitles
        emailDomains
      }
    }
  }
}
    `;

/**
 * __useAutoSyncIntegrationsTableDataQuery__
 *
 * To run a query within a React component, call `useAutoSyncIntegrationsTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutoSyncIntegrationsTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutoSyncIntegrationsTableDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAutoSyncIntegrationsTableDataQuery(baseOptions?: Apollo.QueryHookOptions<AutoSyncIntegrationsTableDataQuery, AutoSyncIntegrationsTableDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AutoSyncIntegrationsTableDataQuery, AutoSyncIntegrationsTableDataQueryVariables>(AutoSyncIntegrationsTableDataDocument, options);
      }
export function useAutoSyncIntegrationsTableDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutoSyncIntegrationsTableDataQuery, AutoSyncIntegrationsTableDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AutoSyncIntegrationsTableDataQuery, AutoSyncIntegrationsTableDataQueryVariables>(AutoSyncIntegrationsTableDataDocument, options);
        }
export type AutoSyncIntegrationsTableDataQueryHookResult = ReturnType<typeof useAutoSyncIntegrationsTableDataQuery>;
export type AutoSyncIntegrationsTableDataLazyQueryHookResult = ReturnType<typeof useAutoSyncIntegrationsTableDataLazyQuery>;
export type AutoSyncIntegrationsTableDataQueryResult = Apollo.QueryResult<AutoSyncIntegrationsTableDataQuery, AutoSyncIntegrationsTableDataQueryVariables>;