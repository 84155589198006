/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import {
  faBellExclamation,
  faCheckCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OpenIcon from "@material-ui/icons/OpenInNew";
import { format } from "date-fns";

import { Alert } from "@rewards-web/shared/components/alert";
import { IconButton } from "@rewards-web/shared/components/icon-button";
import { ObscureRecordedText } from "@rewards-web/shared/components/obscure-recorded-text";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  BonusPointsReminder,
  Maybe,
  RecognitionCategory,
  RecognitionPoints,
  RewardsUser,
} from "@rewards-web/shared/graphql-types";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { reportError } from "@rewards-web/shared/modules/error";

import { DataTable } from "../../../../../shared/components/data-table";
import { useOrganizationCategoriesQuery } from "../../get-organization-categories.generated";
import { RecognitionPointsDetailsDrawer } from "./recognition-points-details-drawer";

export interface RecognitionPointsDataTableProps {
  currentPageIndex: number;
  onChangeCurrentPageIndex(currentPageIndex: number): void;
  perPage: number;
  loading: boolean;
  items:
    | undefined
    | Array<
        Pick<
          RecognitionPoints,
          | "id"
          | "from"
          | "pointValue"
          | "sentAt"
          | "claimedAt"
          | "mustBeClaimed"
        > & {
          category?: Maybe<
            { __typename?: "RecognitionCategory" } & Pick<
              RecognitionCategory,
              "id" | "name"
            >
          >;
          reminders: Array<
            { __typename?: "BonusPointsReminder" } & Pick<
              BonusPointsReminder,
              "id"
            >
          >;
          employee: { __typename?: "RewardsUser" } & Pick<
            RewardsUser,
            "id" | "firstName" | "lastName"
          >;
        }
      >;
  total: number | undefined;
  viewingRecognitionPointsId: string | undefined;
  onViewRecognitionPointsId(recognitionPointsId: string): void;
  onCloseEditEmployee(): void;
}

export function RecognitionPointsDataTable({
  perPage,
  currentPageIndex,
  onChangeCurrentPageIndex,
  loading,
  items,
  total,
  viewingRecognitionPointsId,
  onCloseEditEmployee,
  onViewRecognitionPointsId,
}: RecognitionPointsDataTableProps): JSX.Element {
  const theme = useTheme();
  const categoryQuery = useOrganizationCategoriesQuery({
    onError: reportError,
  });

  if (categoryQuery.error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  if (!categoryQuery.data || categoryQuery.loading) {
    return <PageLoadingState />;
  }

  const recognitionPointsCategoriesEnabled =
    categoryQuery.data?.getMyRewardsOrganizationRecognitionCategories.length >
    0;

  return (
    <>
      <RecognitionPointsDetailsDrawer
        open={!!viewingRecognitionPointsId}
        onClose={onCloseEditEmployee}
        recognitionPointsId={viewingRecognitionPointsId ?? null}
      />

      <DataTable
        itemsPerPage={perPage}
        currentPageIndex={currentPageIndex}
        onCurrentPageIndexChange={onChangeCurrentPageIndex}
        emptyText="No recognition points have been awarded yet."
        loading={loading}
        items={items}
        total={total}
        tableHeaders={
          <TableRow>
            <TableHeader>
              <Typography variant="body">Employee Name</Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="body">Points given</Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="body">Date given</Typography>
            </TableHeader>
            {recognitionPointsCategoriesEnabled && (
              <TableHeader>
                <Typography variant="body">Category</Typography>
              </TableHeader>
            )}
            <TableHeader>
              <Typography variant="body">Sent by</Typography>
            </TableHeader>
            <TableHeader align="right">
              <Typography variant="body">View message</Typography>
            </TableHeader>
          </TableRow>
        }
        tableBody={items?.map((recognitionPointsReference) => {
          const pointsAwarded = (() => {
            const pointAmount = (
              <Typography
                css={css`
                  padding-right: 8px;
                `}
                display="inline"
                variant="body"
                color="textSecondary"
              >
                {numberWithCommas(recognitionPointsReference.pointValue)}
              </Typography>
            );

            if (recognitionPointsReference.mustBeClaimed) {
              if (recognitionPointsReference.claimedAt) {
                return (
                  <Tooltip title="This employee has claimed their recognition points">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      color={theme.palette.success.main}
                      css={css`
                        margin-right: 4px;
                      `}
                    />
                    {pointAmount}
                  </Tooltip>
                );
              }

              if (recognitionPointsReference.reminders.length > 0) {
                return (
                  <Tooltip title="This employee has not claimed their recognition points">
                    <FontAwesomeIcon
                      icon={faBellExclamation}
                      color={theme.palette.gold.light}
                      css={css`
                        margin-right: 4px;
                      `}
                    />
                    {pointAmount}
                  </Tooltip>
                );
              }
            }

            return pointAmount;
          })();

          return (
            <TableRow key={recognitionPointsReference.id}>
              <TableCell divider>
                <ObscureRecordedText>
                  <Typography variant="body" color="textSecondary">
                    {recognitionPointsReference.employee.firstName}{" "}
                    {recognitionPointsReference.employee.lastName}
                  </Typography>
                </ObscureRecordedText>
              </TableCell>
              <TableCell divider>{pointsAwarded}</TableCell>
              <TableCell divider>
                <Typography variant="body" color="textSecondary">
                  {format(recognitionPointsReference.sentAt, "LLLL d, y")}
                </Typography>
              </TableCell>
              {recognitionPointsCategoriesEnabled && (
                <TableCell divider>
                  <Typography variant="body" color="textSecondary">
                    {recognitionPointsReference.category
                      ? recognitionPointsReference.category.name
                      : "-"}
                  </Typography>
                </TableCell>
              )}
              <TableCell divider>
                <Typography variant="body" color="textSecondary">
                  {recognitionPointsReference.from}
                </Typography>
              </TableCell>
              <TableCell divider align="right">
                <IconButton
                  css={css`
                    margin-top: -14px;
                    margin-bottom: -14px;
                  `}
                  onClick={() =>
                    onViewRecognitionPointsId(recognitionPointsReference.id)
                  }
                  aria-label="Read Message"
                >
                  <OpenIcon
                    css={css`
                      height: 0.8em;
                      width: 0.8em;
                    `}
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      />
    </>
  );
}
