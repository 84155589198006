/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { RewardsOrganizationOnboardingStepName } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { EmployeeAutoSyncIntegrations } from "../../../../../shared/modules/employee-auto-sync/components/employee-auto-sync-integrations";
import { useAutoSyncIntegrationsTableDataQuery } from "../../../../../shared/modules/employee-auto-sync/components/employee-auto-sync-integrations/auto-sync-integrations-table-data.generated";
import { AdminOnboardingCard } from "../../components/onboarding-card/onboarding-card";
import { AdminOnboardingCardContent } from "../../components/onboarding-card/onboarding-card-content";
import { AdminOnboardingCardHeader } from "../../components/onboarding-card/onboarding-card-header";
import { AdminOnboardingStepActions } from "../../components/step-actions";
import { useNextOnboardingStepPath } from "../../hooks/use-next-step-path";
import { useOnboardingState } from "../../hooks/use-onboarding-state";

export function OnboardingEmployeeAutoSyncPage() {
  const onboardingState = useOnboardingState();
  const integrationsQuery = useAutoSyncIntegrationsTableDataQuery({
    onError: reportError,
  });
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const nextStepPath = useNextOnboardingStepPath();

  const stepPreviouslyCompleted = onboardingState.completedSteps.has(
    RewardsOrganizationOnboardingStepName.EmployeeAutoSync
  );

  if (integrationsQuery.error) {
    return (
      <Alert
        severity="error"
        message="An unexpected error occurred. Please try again later."
      />
    );
  }

  if (!integrationsQuery.data) {
    return <PageLoadingState />;
  }

  return (
    <div>
      <AdminOnboardingCard
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <AdminOnboardingCardHeader title="📤 Sync Employee List" />
        <AdminOnboardingCardContent>
          <Typography
            variant="body"
            color="text.secondary"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            Sync your home care software with Caribou to give us access to your
            employee list.{" "}
            {!stepPreviouslyCompleted && (
              <>
                Click <strong>"Set up"</strong> and select filters to determine
                who is eligible for the rewards platform.{" "}
              </>
            )}
            Caribou will automatically create, update, and deactivate employee
            accounts based on the filters you set.
          </Typography>

          <Typography variant="body" color="text.secondary">
            Please note: Employees will only be invited and able to log in on
            the launch date you will select (step{" "}
            {onboardingState.orderedSteps.length}).
          </Typography>
        </AdminOnboardingCardContent>
      </AdminOnboardingCard>

      <EmployeeAutoSyncIntegrations
        data={integrationsQuery.data}
        onClickSetUp={(integration) => {
          switch (integration.__typename) {
            case "HHAXIntegration":
              navigate(
                `/onboarding/employee-auto-sync/enable-hhax/${integration.id}`
              );
              break;
            default:
              snackbar.show({
                severity: "error",
                message: "Auto-sync is not supported for this integration.",
              });
          }
        }}
        onClickEdit={(integration) => {
          switch (integration.__typename) {
            case "HHAXIntegration":
              navigate(
                `/onboarding/employee-auto-sync/edit-hhax/${
                  integration.userAutoSyncs[0]!.id
                }`
              );
              break;
            default:
              snackbar.show({
                severity: "error",
                message: "Auto-sync is not supported for this integration.",
              });
          }
        }}
      />

      <AdminOnboardingStepActions
        disableSkip={stepPreviouslyCompleted}
        disableContinue={!stepPreviouslyCompleted}
        submitButtonLabel="Save & Continue"
        onSubmit={() => {
          if (nextStepPath) {
            navigate(nextStepPath);
          }
        }}
      />
    </div>
  );
}
