import * as React from "react";

function NotSelectedPermissionIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="48"
      height="51"
      viewBox="0 0 48 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.5078 8.95312C46.2656 9.73438 47.4375 11.3945 47.4375 13.25C47.4375 38.6406 28.7852 50.75 23.9023 50.75C19.0195 50.75 0.5625 38.4453 0.5625 13.25C0.5625 11.3945 1.63672 9.73438 3.39453 8.95312L22.1445 1.14062C22.7305 0.945312 23.3164 0.75 24 0.75C24.5859 0.75 25.1719 0.945312 25.7578 1.14062L44.5078 8.95312ZM44.2148 13.3477C44.2148 13.0547 44.2148 12.2734 43.3359 11.8828L24.5859 4.07031C24.3906 3.97266 24.1953 3.875 24 3.875C23.707 3.875 23.5117 3.97266 23.3164 4.07031L4.56641 11.8828C3.98047 12.0781 3.6875 12.6641 3.6875 13.25C3.6875 35.2227 19.8008 47.625 24 47.625C28.0039 47.625 44.2148 35.3203 44.2148 13.3477ZM31.8125 19.5C31.8125 19.9883 31.6172 20.3789 31.3242 20.6719L26.1484 25.75L31.3242 30.9258C31.6172 31.2188 31.8125 31.6094 31.8125 32C31.8125 32.9766 31.0312 33.5625 30.25 33.5625C29.7617 33.5625 29.3711 33.4648 29.0781 33.1719L24 27.9961L18.8242 33.1719C18.5312 33.4648 18.1406 33.5625 17.75 33.5625C16.7734 33.5625 16.1875 32.8789 16.1875 32C16.1875 31.6094 16.2852 31.2188 16.5781 30.9258L21.7539 25.75L16.5781 20.6719C16.2852 20.3789 16.1875 19.9883 16.1875 19.5C16.1875 18.7188 16.7734 17.9375 17.75 17.9375C18.1406 17.9375 18.5312 18.1328 18.8242 18.4258L24 23.6016L29.0781 18.4258C29.3711 18.1328 29.7617 17.9375 30.25 17.9375C31.1289 17.9375 31.8125 18.7188 31.8125 19.5Z"
        fill={props.color}
      />
    </svg>
  );
}

export default NotSelectedPermissionIcon;
