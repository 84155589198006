/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

import { Card } from "@rewards-web/shared/components/card";
import { AppTheme } from "@rewards-web/shared/style/types";

import { DateRangeSelectField } from "../../components/date-range-select-field";
import {
  DateRangeOptions,
  DateRangeSelection,
} from "../../components/date-range-select-field/types";
import { getDefaultDateRangeSelection } from "../../components/date-range-select-field/utils";
import { RecognitionInsightMetricGroup } from "../../insight-metric-groups/recognition";

interface QuarterlyMonthlyInsightMetricGroupRangeProps {
  dateRangeOptions: DateRangeOptions;
  timezone: string;
  pointsPerDollar: number;
}

/**
 * A collection of insight metric groups that use quarterly and monthly date ranges.
 */
export function QuarterlyMonthlyInsightMetricGroupRange({
  dateRangeOptions,
  timezone,
  pointsPerDollar,
}: QuarterlyMonthlyInsightMetricGroupRangeProps) {
  const [
    selectedDateRange,
    setSelectedDateRange,
  ] = useState<DateRangeSelection | null>(
    getDefaultDateRangeSelection(dateRangeOptions)
  );

  if (!selectedDateRange) {
    return null;
  }

  return (
    <Card
      css={(theme: AppTheme) => css`
        position: relative;
        padding: ${theme.spacing(3)};
        padding-top: ${theme.spacing(2.5)};
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(3.75)};
      `}
    >
      <div
        css={(theme: AppTheme) => css`
          position: absolute;
          right: ${theme.spacing(3)};
          top: ${theme.spacing(2.5)};
        `}
      >
        <DateRangeSelectField
          value={
            selectedDateRange
              ? {
                  startDate: selectedDateRange.currentRange.startDate.valueOf(),
                  endDate: selectedDateRange.currentRange.endDate.valueOf(),
                  type: selectedDateRange.type,
                }
              : undefined
          }
          options={dateRangeOptions}
          timezone={timezone}
          setDateRange={setSelectedDateRange}
          trackingProperties={{ dateRangeName: "quarterly-and-monthly" }}
        />
      </div>
      <RecognitionInsightMetricGroup
        currentRange={selectedDateRange.currentRange}
        previousRange={selectedDateRange.previousRange?.partialRange ?? null}
        pointsPerDollar={pointsPerDollar}
      />
    </Card>
  );
}
