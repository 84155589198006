import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RecognitionPointsSentSummaryQueryVariables = Types.Exact<{
  startDate: Types.Scalars['Date'];
  endDate: Types.Scalars['Date'];
}>;


export type RecognitionPointsSentSummaryQuery = (
  { __typename?: 'Query' }
  & { getMyRecognitionPointsSentSummaryV2: (
    { __typename?: 'GetMyRecognitionPointsSentSummaryV2Response' }
    & Pick<Types.GetMyRecognitionPointsSentSummaryV2Response, 'daysLeftToAward' | 'pointsSentTotal' | 'pointsSentToManagedEmployees' | 'pointsSentToOtherEmployees' | 'pointsAvailableInBudget' | 'pointsBudget'>
  ) }
);


export const RecognitionPointsSentSummaryDocument = gql`
    query RecognitionPointsSentSummary($startDate: Date!, $endDate: Date!) {
  getMyRecognitionPointsSentSummaryV2(startDate: $startDate, endDate: $endDate) {
    daysLeftToAward
    pointsSentTotal
    pointsSentToManagedEmployees
    pointsSentToOtherEmployees
    pointsAvailableInBudget
    pointsBudget
  }
}
    `;

/**
 * __useRecognitionPointsSentSummaryQuery__
 *
 * To run a query within a React component, call `useRecognitionPointsSentSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecognitionPointsSentSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecognitionPointsSentSummaryQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useRecognitionPointsSentSummaryQuery(baseOptions: Apollo.QueryHookOptions<RecognitionPointsSentSummaryQuery, RecognitionPointsSentSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecognitionPointsSentSummaryQuery, RecognitionPointsSentSummaryQueryVariables>(RecognitionPointsSentSummaryDocument, options);
      }
export function useRecognitionPointsSentSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecognitionPointsSentSummaryQuery, RecognitionPointsSentSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecognitionPointsSentSummaryQuery, RecognitionPointsSentSummaryQueryVariables>(RecognitionPointsSentSummaryDocument, options);
        }
export type RecognitionPointsSentSummaryQueryHookResult = ReturnType<typeof useRecognitionPointsSentSummaryQuery>;
export type RecognitionPointsSentSummaryLazyQueryHookResult = ReturnType<typeof useRecognitionPointsSentSummaryLazyQuery>;
export type RecognitionPointsSentSummaryQueryResult = Apollo.QueryResult<RecognitionPointsSentSummaryQuery, RecognitionPointsSentSummaryQueryVariables>;