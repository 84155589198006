import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PermissionsTableRecognitionBudgetsEnabledQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PermissionsTableRecognitionBudgetsEnabledQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'recognitionBudgetsEnabled'>
  ) }
);


export const PermissionsTableRecognitionBudgetsEnabledDocument = gql`
    query PermissionsTableRecognitionBudgetsEnabled {
  getMyRewardsOrganization {
    id
    recognitionBudgetsEnabled
  }
}
    `;

/**
 * __usePermissionsTableRecognitionBudgetsEnabledQuery__
 *
 * To run a query within a React component, call `usePermissionsTableRecognitionBudgetsEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsTableRecognitionBudgetsEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsTableRecognitionBudgetsEnabledQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionsTableRecognitionBudgetsEnabledQuery(baseOptions?: Apollo.QueryHookOptions<PermissionsTableRecognitionBudgetsEnabledQuery, PermissionsTableRecognitionBudgetsEnabledQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PermissionsTableRecognitionBudgetsEnabledQuery, PermissionsTableRecognitionBudgetsEnabledQueryVariables>(PermissionsTableRecognitionBudgetsEnabledDocument, options);
      }
export function usePermissionsTableRecognitionBudgetsEnabledLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermissionsTableRecognitionBudgetsEnabledQuery, PermissionsTableRecognitionBudgetsEnabledQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PermissionsTableRecognitionBudgetsEnabledQuery, PermissionsTableRecognitionBudgetsEnabledQueryVariables>(PermissionsTableRecognitionBudgetsEnabledDocument, options);
        }
export type PermissionsTableRecognitionBudgetsEnabledQueryHookResult = ReturnType<typeof usePermissionsTableRecognitionBudgetsEnabledQuery>;
export type PermissionsTableRecognitionBudgetsEnabledLazyQueryHookResult = ReturnType<typeof usePermissionsTableRecognitionBudgetsEnabledLazyQuery>;
export type PermissionsTableRecognitionBudgetsEnabledQueryResult = Apollo.QueryResult<PermissionsTableRecognitionBudgetsEnabledQuery, PermissionsTableRecognitionBudgetsEnabledQueryVariables>;