import * as Types from '@rewards-web/shared/graphql-types';

import { CandidateListDetailsFragment } from './candidate-fragment.generated';
import { gql } from '@apollo/client';
import { CandidateListDetailsFragmentDoc } from './candidate-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CandidatesListQueryVariables = Types.Exact<{
  searchQuery?: Types.Maybe<Types.Scalars['String']>;
  archived?: Types.Maybe<Types.Scalars['Boolean']>;
  hasUploadedResume?: Types.Maybe<Types.Scalars['Boolean']>;
  statuses?: Types.Maybe<Array<Types.ListCandidateV2FilterStatusInput> | Types.ListCandidateV2FilterStatusInput>;
  offset: Types.Scalars['Int'];
  limit: Types.Scalars['Int'];
  order?: Types.Maybe<Types.ListCandidatesV2OrderInput>;
  assignedToJobPostingIds?: Types.Maybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
}>;


export type CandidatesListQuery = (
  { __typename?: 'Query' }
  & { listCandidatesV2: (
    { __typename?: 'ListCandidatesResponse' }
    & Pick<Types.ListCandidatesResponse, 'total'>
    & { items: Array<(
      { __typename?: 'Candidate' }
      & CandidateListDetailsFragment
    )> }
  ) }
);


export const CandidatesListDocument = gql`
    query CandidatesList($searchQuery: String, $archived: Boolean, $hasUploadedResume: Boolean, $statuses: [ListCandidateV2FilterStatusInput!], $offset: Int!, $limit: Int!, $order: ListCandidatesV2OrderInput, $assignedToJobPostingIds: [ID!]) {
  listCandidatesV2(
    filter: {searchQuery: $searchQuery, archived: $archived, statuses: $statuses, hasUploadedResume: $hasUploadedResume, assignedToJobPostingIds: $assignedToJobPostingIds}
    offset: $offset
    limit: $limit
    order: $order
  ) {
    total
    items {
      ...CandidateListDetails
    }
  }
}
    ${CandidateListDetailsFragmentDoc}`;

/**
 * __useCandidatesListQuery__
 *
 * To run a query within a React component, call `useCandidatesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidatesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidatesListQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *      archived: // value for 'archived'
 *      hasUploadedResume: // value for 'hasUploadedResume'
 *      statuses: // value for 'statuses'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      assignedToJobPostingIds: // value for 'assignedToJobPostingIds'
 *   },
 * });
 */
export function useCandidatesListQuery(baseOptions: Apollo.QueryHookOptions<CandidatesListQuery, CandidatesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidatesListQuery, CandidatesListQueryVariables>(CandidatesListDocument, options);
      }
export function useCandidatesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidatesListQuery, CandidatesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidatesListQuery, CandidatesListQueryVariables>(CandidatesListDocument, options);
        }
export type CandidatesListQueryHookResult = ReturnType<typeof useCandidatesListQuery>;
export type CandidatesListLazyQueryHookResult = ReturnType<typeof useCandidatesListLazyQuery>;
export type CandidatesListQueryResult = Apollo.QueryResult<CandidatesListQuery, CandidatesListQueryVariables>;