import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateEmployeeAccountsSettingsMutationVariables = Types.Exact<{
  workEmailDomain?: Types.Maybe<Types.Scalars['String']>;
}>;


export type UpdateEmployeeAccountsSettingsMutation = (
  { __typename?: 'Mutation' }
  & { partialUpdateMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'usesWorkDevices' | 'workEmailDomain'>
  ) }
);


export const UpdateEmployeeAccountsSettingsDocument = gql`
    mutation UpdateEmployeeAccountsSettings($workEmailDomain: String) {
  partialUpdateMyRewardsOrganization(workEmailDomain: $workEmailDomain) {
    id
    usesWorkDevices
    workEmailDomain
  }
}
    `;
export type UpdateEmployeeAccountsSettingsMutationFn = Apollo.MutationFunction<UpdateEmployeeAccountsSettingsMutation, UpdateEmployeeAccountsSettingsMutationVariables>;

/**
 * __useUpdateEmployeeAccountsSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeAccountsSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeAccountsSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeAccountsSettingsMutation, { data, loading, error }] = useUpdateEmployeeAccountsSettingsMutation({
 *   variables: {
 *      workEmailDomain: // value for 'workEmailDomain'
 *   },
 * });
 */
export function useUpdateEmployeeAccountsSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeAccountsSettingsMutation, UpdateEmployeeAccountsSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeAccountsSettingsMutation, UpdateEmployeeAccountsSettingsMutationVariables>(UpdateEmployeeAccountsSettingsDocument, options);
      }
export type UpdateEmployeeAccountsSettingsMutationHookResult = ReturnType<typeof useUpdateEmployeeAccountsSettingsMutation>;
export type UpdateEmployeeAccountsSettingsMutationResult = Apollo.MutationResult<UpdateEmployeeAccountsSettingsMutation>;
export type UpdateEmployeeAccountsSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeAccountsSettingsMutation, UpdateEmployeeAccountsSettingsMutationVariables>;