/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { CardContent } from "@rewards-web/shared/components/card";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface AdminOnboardingCardContentProps {
  children: ReactNode;
}

export function AdminOnboardingCardContent({
  children,
}: AdminOnboardingCardContentProps) {
  return (
    <CardContent
      css={(theme: AppTheme) => css`
        position: relative;
        padding: ${theme.spacing(3)};
      `}
    >
      {children}
    </CardContent>
  );
}
