/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";

interface PipelineTableProps {
  children?: ReactNode;
}

export function PipelineTable({ children }: PipelineTableProps): JSX.Element {
  return (
    <table
      css={(theme: AppTheme) => css`
        width: 100%;
        border-spacing: ${theme.spacing(1)} ${theme.spacing(1)};
        & tr:not(:last-of-type) td {
          padding-bottom: ${theme.spacing(1)};
        }
      `}
    >
      {children}
    </table>
  );
}
