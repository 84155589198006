import { SVGProps } from "react";

export function JobStatsCandidateIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.994 6.445h.361c1.552-.002 2.81-1.236 2.813-2.76V2.76C8.165 1.236 6.908.002 5.355 0h-.361c-1.553.002-2.81 1.236-2.812 2.76v.926C2.183 5.21 3.44 6.443 4.994 6.445ZM3.307 2.759c0-.914.755-1.654 1.687-1.655h.361c.931.001 1.686.742 1.688 1.656v.926C7.04 4.6 6.286 5.34 5.355 5.34h-.361c-.932-.001-1.687-.742-1.687-1.656v-.926Zm7.041 8.142v1.542a.558.558 0 0 1-.562.552.557.557 0 0 1-.563-.552v-1.541c-.001-1.242-1.026-2.248-2.292-2.25H3.417c-1.265.002-2.29 1.007-2.292 2.25v1.54a.558.558 0 0 1-.563.553.558.558 0 0 1-.562-.552v-1.541c0-.89.361-1.742 1.002-2.37a3.453 3.453 0 0 1 2.415-.984h3.514c.906 0 1.774.355 2.415.983a3.323 3.323 0 0 1 1.002 2.37Zm2.298-4.457h.36c1.553-.001 2.81-1.235 2.813-2.76V2.76C15.817 1.236 14.559.002 13.006 0h-.361c-1.552.002-2.81 1.236-2.813 2.76v.926c.003 1.524 1.26 2.757 2.813 2.759v-.001Zm-1.688-3.686v.002c.001-.914.756-1.655 1.688-1.656h.36c.932.001 1.687.742 1.688 1.656v.926c-.001.914-.756 1.654-1.688 1.655h-.361c-.93-.001-1.686-.742-1.688-1.656l.001-.927ZM18 10.902v1.546a.557.557 0 0 1-.563.552.557.557 0 0 1-.562-.552v-1.546c-.002-1.243-1.028-2.25-2.295-2.252h-3.42a.557.557 0 0 1-.562-.552c0-.304.251-.552.563-.552h3.42a3.46 3.46 0 0 1 2.416.984A3.331 3.331 0 0 1 18 10.902Z"
        fill="#fff"
      />
    </svg>
  );
}
