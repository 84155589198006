import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CoordinatorGoalsSettingsPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CoordinatorGoalsSettingsPageDataQuery = (
  { __typename?: 'Query' }
  & { getCurrentAdminGoalConfigs: Array<(
    { __typename?: 'AdminGoalConfig' }
    & Pick<Types.AdminGoalConfig, 'id' | 'goalType' | 'startDate' | 'endDate' | 'launched'>
  )> }
);


export const CoordinatorGoalsSettingsPageDataDocument = gql`
    query CoordinatorGoalsSettingsPageData {
  getCurrentAdminGoalConfigs {
    id
    goalType
    startDate
    endDate
    launched
  }
}
    `;

/**
 * __useCoordinatorGoalsSettingsPageDataQuery__
 *
 * To run a query within a React component, call `useCoordinatorGoalsSettingsPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoordinatorGoalsSettingsPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoordinatorGoalsSettingsPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoordinatorGoalsSettingsPageDataQuery(baseOptions?: Apollo.QueryHookOptions<CoordinatorGoalsSettingsPageDataQuery, CoordinatorGoalsSettingsPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoordinatorGoalsSettingsPageDataQuery, CoordinatorGoalsSettingsPageDataQueryVariables>(CoordinatorGoalsSettingsPageDataDocument, options);
      }
export function useCoordinatorGoalsSettingsPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoordinatorGoalsSettingsPageDataQuery, CoordinatorGoalsSettingsPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoordinatorGoalsSettingsPageDataQuery, CoordinatorGoalsSettingsPageDataQueryVariables>(CoordinatorGoalsSettingsPageDataDocument, options);
        }
export type CoordinatorGoalsSettingsPageDataQueryHookResult = ReturnType<typeof useCoordinatorGoalsSettingsPageDataQuery>;
export type CoordinatorGoalsSettingsPageDataLazyQueryHookResult = ReturnType<typeof useCoordinatorGoalsSettingsPageDataLazyQuery>;
export type CoordinatorGoalsSettingsPageDataQueryResult = Apollo.QueryResult<CoordinatorGoalsSettingsPageDataQuery, CoordinatorGoalsSettingsPageDataQueryVariables>;