/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

import { ProgressBar } from "@rewards-web/shared/components/progress-bar";
import { Typography } from "@rewards-web/shared/components/typography";

interface SurveyQuestionScoreComparisonProps {
  previousScores: { averageScore: number; label: string; year: string }[];
  maxScore: 5 | 10;
}

export function SurveyQuestionScoreComparison({
  previousScores,
  maxScore,
}: SurveyQuestionScoreComparisonProps) {
  const theme = useTheme();

  return (
    <div
      css={css`
        display: flex;
        gap: ${theme.spacing(1)};
        text-align: center;
      `}
    >
      {previousScores.map((score, i) => {
        return (
          <div
            key={i}
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            <div
              css={css`
                height: 50px;
              `}
            >
              <ProgressBar
                value={(score.averageScore / maxScore) * 100}
                orientation="vertical"
                greyBackground={true}
              />
            </div>
            <Typography fontSize={10} color={theme.palette.grey[800]}>
              {score.label}
            </Typography>
            <Typography fontSize={10} color={theme.palette.grey[800]}>
              {score.year.slice(-2)}
            </Typography>
          </div>
        );
      })}
    </div>
  );
}
