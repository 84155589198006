export function formatDollars(dollars: number) {
  const roundedValue = Math.round(dollars * 100) / 100;
  let formattedWithCents: string;

  if (roundedValue % 1 === 0) {
    formattedWithCents = roundedValue.toLocaleString(undefined, {
      maximumFractionDigits: 0,
    });
  } else {
    formattedWithCents = roundedValue.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return `$${formattedWithCents}`;
}
