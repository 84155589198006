import { AuthorizedAdminPageWrapper } from "../../../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { CoordinatorGoalsSettingsPageContents } from "./page-contents";

export function SettingsCoordinatorGoalsPage() {
  return (
    <AuthorizedAdminPageWrapper resource="settingsCoordinatorGoals">
      <CoordinatorGoalsSettingsPageContents />
    </AuthorizedAdminPageWrapper>
  );
}
