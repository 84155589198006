import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type HiringJobPostingListQueryVariables = Types.Exact<{
  branchIds?: Types.Maybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
}>;


export type HiringJobPostingListQuery = (
  { __typename?: 'Query' }
  & { getNumActiveCandidatesByJobPostingAndStatus: (
    { __typename?: 'NumActiveCandidatesByJobPostingAndStatus' }
    & { items: Array<(
      { __typename?: 'NumActiveCandidatesByJobPostingAndStatusItem' }
      & Pick<Types.NumActiveCandidatesByJobPostingAndStatusItem, 'total'>
      & { jobPosting: (
        { __typename?: 'JobPosting' }
        & Pick<Types.JobPosting, 'id' | 'title' | 'geography' | 'closedForSubmission'>
      ), totalsByStatus: Array<(
        { __typename: 'NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment' }
        & Pick<Types.NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment, 'numActiveCandidates' | 'recruitmentStepName'>
      ) | (
        { __typename: 'NumActiveCandidatesByJobPostingAndStatusItemTotalRetention' }
        & Pick<Types.NumActiveCandidatesByJobPostingAndStatusItemTotalRetention, 'numActiveCandidates' | 'retentionDurationMonths'>
      )> }
    )> }
  ) }
);


export const HiringJobPostingListDocument = gql`
    query HiringJobPostingList($branchIds: [ID!]) {
  getNumActiveCandidatesByJobPostingAndStatus(branchIds: $branchIds) {
    items {
      jobPosting {
        id
        title
        geography
        closedForSubmission
      }
      total
      totalsByStatus {
        ... on NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment {
          __typename
          numActiveCandidates
          recruitmentStepName
        }
        ... on NumActiveCandidatesByJobPostingAndStatusItemTotalRetention {
          __typename
          numActiveCandidates
          retentionDurationMonths
        }
      }
    }
  }
}
    `;

/**
 * __useHiringJobPostingListQuery__
 *
 * To run a query within a React component, call `useHiringJobPostingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHiringJobPostingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHiringJobPostingListQuery({
 *   variables: {
 *      branchIds: // value for 'branchIds'
 *   },
 * });
 */
export function useHiringJobPostingListQuery(baseOptions?: Apollo.QueryHookOptions<HiringJobPostingListQuery, HiringJobPostingListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HiringJobPostingListQuery, HiringJobPostingListQueryVariables>(HiringJobPostingListDocument, options);
      }
export function useHiringJobPostingListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HiringJobPostingListQuery, HiringJobPostingListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HiringJobPostingListQuery, HiringJobPostingListQueryVariables>(HiringJobPostingListDocument, options);
        }
export type HiringJobPostingListQueryHookResult = ReturnType<typeof useHiringJobPostingListQuery>;
export type HiringJobPostingListLazyQueryHookResult = ReturnType<typeof useHiringJobPostingListLazyQuery>;
export type HiringJobPostingListQueryResult = Apollo.QueryResult<HiringJobPostingListQuery, HiringJobPostingListQueryVariables>;