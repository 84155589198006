import { RewardsOrganizationWhiteLabelConfig } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

import { useCognitoAuth } from "../../../shared/modules/cognito-auth";
import { useSelectedSuperuserTenantId } from "../../../shared/modules/superuser-tenant-selector";
import { useAdminAppAuthenticatedOrganizationConfigQuery } from "../admin-app-authenticated-organization-config.generated";
import { useIdentifyUserForScreenRecording } from "./use-identify-user-for-screen-recording";
import { useInitializeFeatureFlagsForUser } from "./use-initialize-feature-flags-for-user";
import { useLoginRedirect } from "./use-login-redirect";

type AdminAppOrganizationWhiteLabelConfig = Pick<
  RewardsOrganizationWhiteLabelConfig,
  | "id"
  | "rewardsProgramShortName"
  | "faviconImageUrl"
  | "primaryColor"
  | "secondaryColor"
  | "rewardsPageTabIconUrl"
>;

interface UseInitializeFeatureFlagsForUserResult {
  loading: boolean;
  error?: Error;
  whiteLabelConfig?: AdminAppOrganizationWhiteLabelConfig;
  mustEnableSmsMfa: boolean;
}

/**
 * Kicks off app initialization
 */

export function useAdminAppInitializer(): UseInitializeFeatureFlagsForUserResult {
  const forceSetSmsMfaDisabledFlag = useFeatureFlag(
    // the feature flag lets us override to allow the user to bypass the MFA screen
    // incase they are stuck and can't complete the flow
    "admin-app-force-set-sms-mfa-disabled-perm"
  );
  const { mustEnableSmsMfa } = useCognitoAuth();
  const { initialized } = useInitializeFeatureFlagsForUser();
  useIdentifyUserForScreenRecording();
  const { shouldRedirectToLogin } = useLoginRedirect();
  const [{ canMakeQueriesWithTenant }] = useSelectedSuperuserTenantId();
  const {
    error: organizationConfigQueryError,
    loading: organizationConfigQueryLoading,
    data: organizationConfigQueryData,
  } = useAdminAppAuthenticatedOrganizationConfigQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first",
    skip: shouldRedirectToLogin || !canMakeQueriesWithTenant,
  });

  return {
    loading:
      !initialized ||
      shouldRedirectToLogin ||
      (organizationConfigQueryLoading && !organizationConfigQueryData),
    error: organizationConfigQueryError,
    whiteLabelConfig:
      organizationConfigQueryData?.getMyRewardsOrganization.whiteLabelConfig ??
      undefined,
    mustEnableSmsMfa: mustEnableSmsMfa && !forceSetSmsMfaDisabledFlag,
  };
}
