/**
 * Takes a valid URL (see `isUrl`) and either:
 * - Returns the URL as-is (if it has "http://" prefix), or
 * - Prefixes with `https://
 *
 */
export function serializeUrl(url: string) {
  if (!url.startsWith("https://")) {
    return `https://${url}`;
  }

  return url;
}
