/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { ProgressSpinner } from "@rewards-web/shared/components/progress-spinner";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

export function UploadingFileSpinner(props: { videoName: string }) {
  return (
    <div
      css={(theme: AppTheme) => css`
        width: 100%;
        height: 100%;
        cursor: pointer;
        padding: ${theme.spacing(4)} ${theme.spacing(2)};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & > *:not(:last-child) {
          margin-bottom: ${theme.spacing(1)};
        }
      `}
    >
      <ProgressSpinner />
      <Typography variant="h4" color="grey.800">
        Uploading{" "}
        <Typography
          variant="h4"
          color="grey.800"
          component="span"
          css={css`
            font-weight: bolder;
          `}
        >
          {props.videoName}
        </Typography>
      </Typography>

      <Typography variant="h4" color="grey.800">
        This can take a couple of minutes, depending on your internet
        connection.
      </Typography>
    </div>
  );
}
