import { PageLayout } from "../../../shared/components/page-layout";
import {
  ADMIN_PERMISSION_KEY_BY_PAGE,
  AdminPageName,
} from "../../../shared/constants/admin-page";
import { AuthorizedAdminPageWrapper } from "../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { HomePageContents } from "./page-contents";

const MAX_CONTENT_WIDTH = 1300;

export function LegacyHomePage(): JSX.Element {
  return (
    <AuthorizedAdminPageWrapper
      resource={ADMIN_PERMISSION_KEY_BY_PAGE[AdminPageName.hiring]}
    >
      <PageLayout maxContentWidth={MAX_CONTENT_WIDTH}>
        <HomePageContents />
      </PageLayout>
    </AuthorizedAdminPageWrapper>
  );
}
