/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { AppTheme } from "@rewards-web/shared/style/types";
import { StylableProps } from "@rewards-web/shared/types";

interface BadgeProps extends StylableProps {
  value: number;
}

export function Badge({ value, className }: BadgeProps) {
  return (
    <div
      className={className}
      css={(theme: AppTheme) => css`
        font-size: 0.9em;
        display: inline-block;
        border-radius: 1000px;
        padding: 0px 8px;
        color: ${theme.palette.secondary.contrastText};
        background-color: ${theme.palette.secondary.main};
        font-family: ${theme.typography.fontFamily};
      `}
    >
      {value}
    </div>
  );
}
