import { SVGProps } from "react";

export function StatsCheckIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={17}
      fill="none"
      {...props}
    >
      <circle
        cx={8.651}
        cy={8.821}
        r={8.173}
        fill={props.fill}
        opacity={0.89}
      />
      <path
        fill="#fff"
        d="m5.128 8.31 2.037 1.675L11.074 5.1l1.842 1.451L7.5 13.335l-3.88-3.21L5.128 8.31Z"
      />
    </svg>
  );
}
