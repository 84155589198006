/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

interface PipelineTableHeaderCellProps {
  children?: ReactNode;
  leftAlign?: boolean;
  width?: "full" | "auto";
}

export function PipelineTableHeaderCell({
  children,
  leftAlign = false,
}: PipelineTableHeaderCellProps): JSX.Element {
  return (
    <th
      css={css`
        width: 120px;
        ${leftAlign &&
        css`
          text-align: left;
        `};
      `}
    >
      {children}
    </th>
  );
}
