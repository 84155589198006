import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AdminAppAuthenticatedOrganizationConfigQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AdminAppAuthenticatedOrganizationConfigQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'referralsEnabled'>
    & { whiteLabelConfig?: Types.Maybe<(
      { __typename?: 'RewardsOrganizationWhiteLabelConfig' }
      & Pick<Types.RewardsOrganizationWhiteLabelConfig, 'id' | 'rewardsProgramShortName' | 'faviconImageUrl' | 'primaryColor' | 'secondaryColor' | 'rewardsPageTabIconUrl'>
    )> }
  ) }
);


export const AdminAppAuthenticatedOrganizationConfigDocument = gql`
    query AdminAppAuthenticatedOrganizationConfig {
  getMyRewardsOrganization {
    id
    referralsEnabled
    whiteLabelConfig {
      id
      rewardsProgramShortName
      faviconImageUrl
      primaryColor
      secondaryColor
      rewardsPageTabIconUrl
    }
  }
}
    `;

/**
 * __useAdminAppAuthenticatedOrganizationConfigQuery__
 *
 * To run a query within a React component, call `useAdminAppAuthenticatedOrganizationConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminAppAuthenticatedOrganizationConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminAppAuthenticatedOrganizationConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminAppAuthenticatedOrganizationConfigQuery(baseOptions?: Apollo.QueryHookOptions<AdminAppAuthenticatedOrganizationConfigQuery, AdminAppAuthenticatedOrganizationConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminAppAuthenticatedOrganizationConfigQuery, AdminAppAuthenticatedOrganizationConfigQueryVariables>(AdminAppAuthenticatedOrganizationConfigDocument, options);
      }
export function useAdminAppAuthenticatedOrganizationConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminAppAuthenticatedOrganizationConfigQuery, AdminAppAuthenticatedOrganizationConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminAppAuthenticatedOrganizationConfigQuery, AdminAppAuthenticatedOrganizationConfigQueryVariables>(AdminAppAuthenticatedOrganizationConfigDocument, options);
        }
export type AdminAppAuthenticatedOrganizationConfigQueryHookResult = ReturnType<typeof useAdminAppAuthenticatedOrganizationConfigQuery>;
export type AdminAppAuthenticatedOrganizationConfigLazyQueryHookResult = ReturnType<typeof useAdminAppAuthenticatedOrganizationConfigLazyQuery>;
export type AdminAppAuthenticatedOrganizationConfigQueryResult = Apollo.QueryResult<AdminAppAuthenticatedOrganizationConfigQuery, AdminAppAuthenticatedOrganizationConfigQueryVariables>;