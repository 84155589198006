/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { CandidateIcon } from "./candidate-icon";

export function CandidateSummaryLabel() {
  const theme = useTheme();

  return (
    <div
      css={(theme: AppTheme) => css`
        display: flex;
        align-items: center;
        padding: ${theme.spacing(2)} 0;
      `}
    >
      <CandidateIcon width={30} height={30} fill={theme.palette.primary.main} />

      <Typography
        css={(theme: AppTheme) => css`
          text-transform: uppercase;
          font-size: 1.4em;
          font-weight: bolder;
          margin-left: ${theme.spacing(1)};
        `}
      >
        Candidate
      </Typography>
    </div>
  );
}
