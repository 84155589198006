import * as Types from '@rewards-web/shared/graphql-types';

import { CandidateListDetailsFragment } from '../../candidate-fragment.generated';
import { gql } from '@apollo/client';
import { CandidateListDetailsFragmentDoc } from '../../candidate-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ArchiveCandidateMutationVariables = Types.Exact<{
  candidateId: Types.Scalars['ID'];
  updatedAdminNotes?: Types.Maybe<Types.Scalars['String']>;
}>;


export type ArchiveCandidateMutation = (
  { __typename?: 'Mutation' }
  & { archiveCandidate: (
    { __typename?: 'Candidate' }
    & CandidateListDetailsFragment
  ) }
);


export const ArchiveCandidateDocument = gql`
    mutation ArchiveCandidate($candidateId: ID!, $updatedAdminNotes: String) {
  archiveCandidate(
    candidateId: $candidateId
    updatedAdminNotes: $updatedAdminNotes
  ) {
    ...CandidateListDetails
  }
}
    ${CandidateListDetailsFragmentDoc}`;
export type ArchiveCandidateMutationFn = Apollo.MutationFunction<ArchiveCandidateMutation, ArchiveCandidateMutationVariables>;

/**
 * __useArchiveCandidateMutation__
 *
 * To run a mutation, you first call `useArchiveCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveCandidateMutation, { data, loading, error }] = useArchiveCandidateMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      updatedAdminNotes: // value for 'updatedAdminNotes'
 *   },
 * });
 */
export function useArchiveCandidateMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveCandidateMutation, ArchiveCandidateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveCandidateMutation, ArchiveCandidateMutationVariables>(ArchiveCandidateDocument, options);
      }
export type ArchiveCandidateMutationHookResult = ReturnType<typeof useArchiveCandidateMutation>;
export type ArchiveCandidateMutationResult = Apollo.MutationResult<ArchiveCandidateMutation>;
export type ArchiveCandidateMutationOptions = Apollo.BaseMutationOptions<ArchiveCandidateMutation, ArchiveCandidateMutationVariables>;