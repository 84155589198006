import { ClassNames } from "@emotion/react";
import { StepIcon, StepIconProps } from "@material-ui/core";

import { Typography } from "@rewards-web/shared/components/typography";

import {
  DisplayStep,
  formatCandidateActionDate,
  getCompletedStepLabel,
  getActionableStepLabel,
} from "../../lib";

interface InactiveCandidateStepProps extends StepIconProps {
  step: DisplayStep;
}

export function InactiveCandidateStep({
  icon: index,
  active,
  step,
}: InactiveCandidateStepProps) {
  return (
    <ClassNames>
      {({ css, theme }) => (
        <div
          className={css`
            width: 138px;
            min-height: 130px;
            display: flex;
            flex-direction: row;
            padding-left: ${theme.spacing(1.5)};
            margin-top: 20px;
          `}
        >
          <StepIcon
            active={active}
            icon={index}
            completed={step.status === "completed"}
            classes={{
              root: css`
                margin-top: ${theme.spacing(0.5)};
                margin-left: -${theme.spacing(0.5)};
                margin-right: ${theme.spacing(1)};
              `,
              completed: css`
                &.MuiStepIcon-root {
                  color: rgba(0, 0, 0, 0.38);
                }
              `,
            }}
          />
          <div>
            <Typography variant="footnote" display="block">
              {step.status === "completed"
                ? getCompletedStepLabel(step)
                : getActionableStepLabel(step)}
            </Typography>
            {step.date && (
              <Typography variant="footnote">
                {formatCandidateActionDate(step.date)}
              </Typography>
            )}
          </div>
        </div>
      )}
    </ClassNames>
  );
}
