/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Table } from "@rewards-web/shared/components/table-components/table";
import { TableBody } from "@rewards-web/shared/components/table-components/table-body";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

export interface GroupRow {
  id: string;
  name: string;
  tagsCount: number;
}

export interface GroupTableProps {
  rows: GroupRow[];
}

export function GroupListTable(props: GroupTableProps) {
  return (
    <>
      <Table
        css={(theme: AppTheme) =>
          css`
            margin-bottom: ${theme.spacing(4)};
          `
        }
      >
        <TableBody
          css={(theme: AppTheme) => css`
            &:first-child {
              border-top: 1px solid ${theme.palette.grey[200]};
              border-top-style: "solid";
            }
          `}
        >
          {props.rows.map(({ id, name, tagsCount }) => {
            return (
              <TableRow key={id}>
                <TableCell divider>
                  <Typography
                    css={css`
                      font-weight: bolder;
                    `}
                    variant="body"
                    component="span"
                    color="grey.800"
                  >
                    {name}
                  </Typography>
                  <Typography variant="body" component="span" color="grey.800">
                    {" "}
                    &bull; {tagsCount} tags
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}
