/** @jsxImportSource @emotion/react */
import { QueryResult } from "@apollo/client";
import { css } from "@emotion/react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

import { EmployeeDrawerStatsQuery } from "../../employee-drawer-stats.generated";
import { EmployeeStatCard } from "./employee-stat-card";
import { StatsBriefcaseIcon } from "./icons/stats-briefcase-icon";
import { StatsCheckIcon } from "./icons/stats-check-icon";
import { StatsPageIcon } from "./icons/stats-page-icon";
import { StatsShareIcon } from "./icons/stats-share-icon";
import { StatsStarIcon } from "./icons/stats-star-icon";

export interface EmployeeStatsProps {
  query: Pick<
    QueryResult<EmployeeDrawerStatsQuery>,
    "data" | "error" | "loading"
  >;
}

export function EmployeeStats({ query }: EmployeeStatsProps) {
  return (
    <>
      <Typography variant="h3">
        <div
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(2)};
            margin-left: ${theme.spacing(2)};
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          Points Stats
        </div>
      </Typography>

      {query.error ? (
        <Alert severity="error" message="An error occurred loading the stats" />
      ) : (
        <div
          css={(theme: AppTheme) => css`
            margin: 0 ${theme.spacing(1.5)};
          `}
        >
          <EmployeeStatCard
            value={
              query.data?.getRewardsUserById?.pointsAvailableToRedeem ??
              undefined
            }
            IconComponent={StatsStarIcon}
            label="Current Points"
          />

          <EmployeeStatCard
            value={query.data?.getRewardsUserById?.pointsRedeemed ?? undefined}
            IconComponent={StatsCheckIcon}
            label="Points Redeemed"
          />
        </div>
      )}

      {query.data?.getReferralStatsForUser && (
        <>
          <Typography variant="h3">
            <div
              css={(theme: AppTheme) => css`
                margin-top: ${theme.spacing(2)};
                margin-left: ${theme.spacing(2)};
                margin-bottom: ${theme.spacing(1)};
              `}
            >
              Referral Stats
            </div>

            <div
              css={(theme: AppTheme) => css`
                display: grid;
                column-gap: ${theme.spacing(1.5)};
                grid-template-columns: 1fr 1fr;
                margin: 0 ${theme.spacing(1.5)};
              `}
            >
              <EmployeeStatCard
                value={query.data.getReferralStatsForUser.totalNumberOfShares}
                IconComponent={StatsShareIcon}
                label="Total Job Shares"
              />

              <EmployeeStatCard
                value={
                  query.data.getReferralStatsForUser.totalNumberOfApplicants
                }
                IconComponent={StatsPageIcon}
                label="Total Applications"
              />

              <EmployeeStatCard
                value={
                  query.data.getReferralStatsForUser
                    .totalNumberOfCandidatesHired
                }
                IconComponent={StatsBriefcaseIcon}
                label="Total Hires"
              />
            </div>
          </Typography>
        </>
      )}
    </>
  );
}
