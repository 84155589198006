import { Navigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";

import {
  RecognitionTabPathByTab,
  useEnabledRecognitionPageTabsQuery,
} from "./use-enabled-recognition-page-tabs-query";

export function RecognitionPointsIndexPage() {
  const query = useEnabledRecognitionPageTabsQuery();

  if (!query.data) {
    return null;
  }

  if (query.data.length === 0) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  return <Navigate replace to={RecognitionTabPathByTab[query.data[0].tab]} />;
}
