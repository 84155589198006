import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { useEffect, useState } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { useGenerateRewardsAppLoginLinkMutation } from "./generate-rewards-app-login-link.generated";

interface OpenRewardsAppButtonProps {
  rewardsProgramName: string;
}

export function OpenRewardsAppButton({
  rewardsProgramName,
}: OpenRewardsAppButtonProps) {
  const track = useTrack();
  const snackbar = useSnackbar();
  const [generateLoginLink] = useGenerateRewardsAppLoginLinkMutation();
  const [modalOpen, setModalOpen] = useState(false);
  const [loginLink, setLoginLink] = useState<string>();

  useEffect(() => {
    track("Open caribou rewards button visible");
  }, [track]);

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleButtonClick = async () => {
    setModalOpen(true);
    track("Open caribou rewards button clicked");

    try {
      const res = await generateLoginLink();
      const {
        generateMyRewardsUserLoginLink: { loginLink },
      } = res.data!;

      const loginLinkWithTrackingParam = new URL(loginLink);
      loginLinkWithTrackingParam.searchParams.set("utm_source", "admin_app");

      setLoginLink(loginLinkWithTrackingParam.toString());
    } catch (error) {
      setModalOpen(false);
      snackbar.show({
        severity: "error",
        message: "An error occurred. Please try again later.",
      });
      reportError(error);
    }
  };

  return (
    <>
      <Button
        color="primaryLight"
        variant="contained"
        size="small"
        label={`Open ${rewardsProgramName}`}
        endIcon={<OpenInNewIcon />}
        onClick={handleButtonClick}
      />
      <Modal open={modalOpen} disableBackdropClick onClose={handleCancel}>
        <ModalTitle>Open Caribou Rewards?</ModalTitle>
        <ModalContent>
          <Typography variant="body" gutterBottom>
            The app will open in another tab.
          </Typography>
        </ModalContent>
        <ModalActions>
          <Button
            loading={!loginLink}
            variant="outlined"
            label="Cancel"
            onClick={handleCancel}
          />
          <Button
            color="primary"
            externalLink
            loading={!loginLink}
            label="Open"
            endIcon={<OpenInNewIcon />}
            linkTo={loginLink}
            target="_blank"
            onClick={() => {
              track("Open caribou rewards modal confirm button clicked");
              setModalOpen(false);
            }}
          />
        </ModalActions>
      </Modal>
    </>
  );
}
