/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Divider } from "@rewards-web/shared/components/divider";
import { FormControlProvider } from "@rewards-web/shared/components/form/form-control";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalErrorState } from "@rewards-web/shared/components/modal/modal-error-state";
import { ModalLoadingState } from "@rewards-web/shared/components/modal/modal-loading-state";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { useConfirmChangeJobDataQuery } from "./confirm-change-job-data.generated";

interface ConfirmChangeJobModalProps {
  open: boolean;
  confirming: boolean;
  onConfirm(details: { movedOutOfOrganization: boolean }): void;
  onCancel(): void;
  currentJobPostingId: string | null;
  nextJobPostingId: string | null;
}

export function ConfirmChangeJobModal({
  open,
  confirming,
  onConfirm,
  onCancel,
  currentJobPostingId,
  nextJobPostingId,
}: ConfirmChangeJobModalProps): JSX.Element {
  const confirmChangeJobDataQuery = useConfirmChangeJobDataQuery({
    onError: reportError,
    skip: !currentJobPostingId || !nextJobPostingId,
    variables: {
      currentJobPostingId: currentJobPostingId!,
      nextJobPostingId: nextJobPostingId!,
    },
  });

  useEffect(() => {
    if (
      confirmChangeJobDataQuery.data &&
      (!confirmChangeJobDataQuery.data.currentJobPosting ||
        !confirmChangeJobDataQuery.data.nextJobPosting)
    ) {
      reportError(new Error(`Current or next job posting could not be found`));
    }
  }, [confirmChangeJobDataQuery.data]);

  const content = (() => {
    if (confirmChangeJobDataQuery.error) {
      return <ModalErrorState onClose={onCancel} />;
    }

    if (!confirmChangeJobDataQuery.data) {
      return <ModalLoadingState />;
    }

    if (
      !confirmChangeJobDataQuery.data.currentJobPosting ||
      !confirmChangeJobDataQuery.data.nextJobPosting
    ) {
      return <ModalErrorState onClose={onCancel} />;
    }

    const {
      data: { currentJobPosting, nextJobPosting },
    } = confirmChangeJobDataQuery;

    return (
      <>
        <ModalTitle>Confirm</ModalTitle>
        <ModalContent>
          <Typography
            variant="body"
            color="textPrimary"
            css={(theme: AppTheme) =>
              css`
                padding-bottom: ${theme.spacing(2)};
              `
            }
          >
            This candidate's job will be changed.
            <br />
            <strong>Current job:</strong> {currentJobPosting.title} (
            {currentJobPosting.geography})
            <br />
            <strong>New job:</strong> {nextJobPosting.title} (
            {nextJobPosting.geography})
            <br />
          </Typography>
          <Divider />
          {nextJobPosting.candidateAlertEmail &&
            currentJobPosting.candidateAlertEmail !==
              nextJobPosting.candidateAlertEmail && (
              <Typography
                variant="body"
                color="textPrimary"
                css={(theme: AppTheme) =>
                  css`
                    padding: ${theme.spacing(2)} 0;
                  `
                }
              >
                {nextJobPosting.candidateAlertEmail}
                {nextJobPosting.organization.branchName && (
                  <>
                    {" "}
                    at {nextJobPosting.organization.shortName} (
                    {nextJobPosting.organization.branchName})
                  </>
                )}{" "}
                will be notified of the new candidate.
              </Typography>
            )}

          {nextJobPosting.organization.branchName &&
            nextJobPosting.organization.branchName !==
              currentJobPosting.organization.branchName && (
              <Typography
                variant="body"
                color="textPrimary"
                css={(theme: AppTheme) =>
                  css`
                    padding: ${theme.spacing(2)} 0;
                  `
                }
              >
                The candidate be will sent to{" "}
                {nextJobPosting.organization.shortName} (
                {nextJobPosting.organization.branchName})
              </Typography>
            )}

          <Typography
            variant="body"
            color="textPrimary"
            css={(theme: AppTheme) =>
              css`
                padding: ${theme.spacing(2)} 0;
              `
            }
          >
            Email{" "}
            <a
              css={css`
                font-weight: bold;
              `}
              href={`mailto:${encodeURI("help+admin@caribou.care")}`}
            >
              help+admin@caribou.care
            </a>{" "}
            for further help.
          </Typography>
          <Divider />
        </ModalContent>
        <ModalActions>
          <Button
            width="auto"
            size="large"
            label="Cancel"
            variant="outlined"
            onClick={() => {
              onCancel();
            }}
          />
          <Button
            width="auto"
            size="large"
            label="Confirm"
            color="primary"
            onClick={() => {
              onConfirm({
                movedOutOfOrganization:
                  currentJobPosting.organization.id !==
                  nextJobPosting.organization.id,
              });
            }}
          />
        </ModalActions>
      </>
    );
  })();

  return (
    <FormControlProvider value={{ submitting: confirming }}>
      <Modal
        width="650px"
        open={open}
        onClose={onCancel}
        disableBackdropClick={confirming}
      >
        {content}
      </Modal>
    </FormControlProvider>
  );
}
