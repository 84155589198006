import * as Types from '@rewards-web/shared/graphql-types';

import { EmployeeAccountSettingsFragmentFragment } from './employee-account-settings-fragment.generated';
import { gql } from '@apollo/client';
import { EmployeeAccountSettingsFragmentFragmentDoc } from './employee-account-settings-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EmployeeAccountsCardDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EmployeeAccountsCardDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & EmployeeAccountSettingsFragmentFragment
  ) }
);


export const EmployeeAccountsCardDataDocument = gql`
    query EmployeeAccountsCardData {
  getMyRewardsOrganization {
    ...EmployeeAccountSettingsFragment
  }
}
    ${EmployeeAccountSettingsFragmentFragmentDoc}`;

/**
 * __useEmployeeAccountsCardDataQuery__
 *
 * To run a query within a React component, call `useEmployeeAccountsCardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeAccountsCardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeAccountsCardDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployeeAccountsCardDataQuery(baseOptions?: Apollo.QueryHookOptions<EmployeeAccountsCardDataQuery, EmployeeAccountsCardDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeAccountsCardDataQuery, EmployeeAccountsCardDataQueryVariables>(EmployeeAccountsCardDataDocument, options);
      }
export function useEmployeeAccountsCardDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeAccountsCardDataQuery, EmployeeAccountsCardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeAccountsCardDataQuery, EmployeeAccountsCardDataQueryVariables>(EmployeeAccountsCardDataDocument, options);
        }
export type EmployeeAccountsCardDataQueryHookResult = ReturnType<typeof useEmployeeAccountsCardDataQuery>;
export type EmployeeAccountsCardDataLazyQueryHookResult = ReturnType<typeof useEmployeeAccountsCardDataLazyQuery>;
export type EmployeeAccountsCardDataQueryResult = Apollo.QueryResult<EmployeeAccountsCardDataQuery, EmployeeAccountsCardDataQueryVariables>;