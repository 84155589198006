/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { JobPostingStats } from "@rewards-web/shared/graphql-types";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { JobStatsCandidateIcon } from "./job-stats-candidates-icon";
import { JobStatsHiresIcon } from "./job-stats-hires-icon";
import { JobStatsInterviewsIcon } from "./job-stats-interviews-icon";
import { JobStatsShareIcon } from "./job-stats-share-icon";

export interface JobStatsTooltipContentProps {
  stats: Pick<
    JobPostingStats,
    "id" | "numShares" | "numCandidates" | "numInterviewsScheduled" | "numHires"
  >;
}

export function JobStatsTooltipContent({ stats }: JobStatsTooltipContentProps) {
  return (
    <table
      css={(theme: AppTheme) => css`
        color: white;
        padding: ${theme.spacing(2)} 0;
        & td:first-of-type {
          text-align: right;
          padding-right: ${theme.spacing(2)};
        }
        & tr:not(:last-of-type) td {
          padding-bottom: ${theme.spacing(1)};
        }
        td svg {
          margin-right: 8px;
        }
      `}
    >
      <tbody>
        {[
          {
            label: "Shares",
            value: stats.numShares,
            icon: <JobStatsShareIcon />,
          },
          {
            label: "Candidates",
            value: stats.numCandidates,
            icon: <JobStatsCandidateIcon />,
          },
          {
            label: "Interviews Scheduled",
            value: stats.numInterviewsScheduled,
            icon: <JobStatsInterviewsIcon />,
          },
          {
            label: "Hires",
            value: stats.numHires,
            icon: <JobStatsHiresIcon />,
          },
        ].map(({ label, value, icon }) => (
          <tr key={label}>
            <td>{label}:</td>
            <td>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                {icon} {numberWithCommas(value)}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
