import * as React from "react";

function HiringLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={23}
      height={23}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill={props.color}>
        <path d="M11.5 4C9.57 4 8 5.57 8 7.5S9.57 11 11.5 11 15 9.43 15 7.5 13.43 4 11.5 4zm0 6A2.503 2.503 0 019 7.5C9 6.122 10.122 5 11.5 5S14 6.122 14 7.5 12.878 10 11.5 10zM18.5 8a2.503 2.503 0 00-2.5 2.5c0 1.378 1.122 2.5 2.5 2.5s2.5-1.122 2.5-2.5S19.878 8 18.5 8zm0 4c-.827 0-1.5-.673-1.5-1.5S17.673 9 18.5 9s1.5.673 1.5 1.5-.673 1.5-1.5 1.5zM4.57 8a2.503 2.503 0 00-2.5 2.5c0 1.378 1.123 2.5 2.5 2.5 1.379 0 2.5-1.122 2.5-2.5S5.95 8 4.57 8zm0 4c-.826 0-1.5-.673-1.5-1.5S3.745 9 4.57 9c.828 0 1.5.673 1.5 1.5S5.399 12 4.57 12zM11.5 12A6.508 6.508 0 005 18.5a.5.5 0 001 0c0-3.033 2.467-5.5 5.5-5.5s5.5 2.467 5.5 5.5a.5.5 0 001 0c0-3.584-2.916-6.5-6.5-6.5z" />
        <path d="M18.5 14a4.5 4.5 0 00-2.331.65.5.5 0 00.519.855A3.504 3.504 0 0122 18.5a.5.5 0 001 0c0-2.481-2.019-4.5-4.5-4.5zM6.83 14.649A4.503 4.503 0 000 18.5a.5.5 0 001 0 3.504 3.504 0 015.311-2.996.5.5 0 00.519-.855z" />
      </g>
    </svg>
  );
}
export default HiringLogo;
