import * as Types from '@rewards-web/shared/graphql-types';

import { JobPostingSettingsFragmentFragment } from '../job-posting-settings-fragment.generated';
import { gql } from '@apollo/client';
import { JobPostingSettingsFragmentFragmentDoc } from '../job-posting-settings-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateJobPostingSettingsMutationVariables = Types.Exact<{
  jobPostingBannerImage?: Types.Maybe<Types.Scalars['Upload']>;
  jobPostingLogoImage?: Types.Maybe<Types.Scalars['Upload']>;
  jobPostingThemeColor?: Types.Maybe<Types.Scalars['String']>;
  jobPostingLocationField?: Types.Maybe<Types.JobPostingLocationField>;
}>;


export type UpdateJobPostingSettingsMutation = (
  { __typename?: 'Mutation' }
  & { partialUpdateMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & JobPostingSettingsFragmentFragment
  ) }
);


export const UpdateJobPostingSettingsDocument = gql`
    mutation UpdateJobPostingSettings($jobPostingBannerImage: Upload, $jobPostingLogoImage: Upload, $jobPostingThemeColor: String, $jobPostingLocationField: JobPostingLocationField) {
  partialUpdateMyRewardsOrganization(
    jobPostingBannerImage: $jobPostingBannerImage
    jobPostingLogoImage: $jobPostingLogoImage
    jobPostingThemeColor: $jobPostingThemeColor
    jobPostingLocationField: $jobPostingLocationField
  ) {
    ...JobPostingSettingsFragment
  }
}
    ${JobPostingSettingsFragmentFragmentDoc}`;
export type UpdateJobPostingSettingsMutationFn = Apollo.MutationFunction<UpdateJobPostingSettingsMutation, UpdateJobPostingSettingsMutationVariables>;

/**
 * __useUpdateJobPostingSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateJobPostingSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobPostingSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobPostingSettingsMutation, { data, loading, error }] = useUpdateJobPostingSettingsMutation({
 *   variables: {
 *      jobPostingBannerImage: // value for 'jobPostingBannerImage'
 *      jobPostingLogoImage: // value for 'jobPostingLogoImage'
 *      jobPostingThemeColor: // value for 'jobPostingThemeColor'
 *      jobPostingLocationField: // value for 'jobPostingLocationField'
 *   },
 * });
 */
export function useUpdateJobPostingSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobPostingSettingsMutation, UpdateJobPostingSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJobPostingSettingsMutation, UpdateJobPostingSettingsMutationVariables>(UpdateJobPostingSettingsDocument, options);
      }
export type UpdateJobPostingSettingsMutationHookResult = ReturnType<typeof useUpdateJobPostingSettingsMutation>;
export type UpdateJobPostingSettingsMutationResult = Apollo.MutationResult<UpdateJobPostingSettingsMutation>;
export type UpdateJobPostingSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateJobPostingSettingsMutation, UpdateJobPostingSettingsMutationVariables>;