/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { TextField } from "@rewards-web/shared/components/text-field";
import { usePrevious } from "@rewards-web/shared/hooks/use-previous";
import { getCharactersRemainingText } from "@rewards-web/shared/lib/characters-remaining-text";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { useUpdateCandidateNotesMutation } from "../update-candidate-notes.generated";

const MAX_NOTES_LENGTH = 1000;

interface CandidateDetailsFormValues {
  adminNotes: string;
}

interface InternalNotesFormProps {
  adminPreviousNotes: string | undefined;
  candidateId: string;
  hasEditPermission: boolean;
}

export function InternalNotesForm({
  adminPreviousNotes,
  candidateId,
  hasEditPermission,
}: InternalNotesFormProps): JSX.Element {
  const track = useTrack();
  const snackbar = useSnackbar();

  const form = useForm<CandidateDetailsFormValues>({
    defaultValues: {
      adminNotes: "",
    },
  });

  const notes = useWatch({ control: form.control, name: "adminNotes" });

  const [updateCandidateNotes] = useUpdateCandidateNotesMutation();

  const adminNotes = adminPreviousNotes;
  const previousAdminNotes = usePrevious(adminNotes);
  useEffect(() => {
    if (adminNotes !== undefined && adminNotes !== previousAdminNotes) {
      form.reset({ adminNotes: adminNotes ?? "" });
    }
  }, [adminNotes, previousAdminNotes, form]);

  const handleSubmit = async (
    values: CandidateDetailsFormValues
  ): Promise<void> => {
    try {
      await updateCandidateNotes({
        variables: {
          candidateId: candidateId,
          adminNotes: values.adminNotes,
        },
      });
      snackbar.show({
        severity: "success",
        message: "The candidate's notes have been saved.",
      });
      track("Updated candidate notes", { candidateId });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  return (
    <Form
      onSubmit={form.handleSubmit(handleSubmit)}
      submitting={form.formState.isSubmitting}
    >
      <TextField
        type="textarea"
        label="Notes"
        minRows={5}
        helperText={getCharactersRemainingText(notes, MAX_NOTES_LENGTH)}
        error={form.formState.errors.adminNotes}
        {...form.register("adminNotes", {
          validate: (value) => {
            if (value && value.length > MAX_NOTES_LENGTH) {
              return getCharactersRemainingText(value, MAX_NOTES_LENGTH);
            }
          },
        })}
        disabled={!hasEditPermission}
        disableAutocomplete
      />
      <Button
        css={css`
          display: block;
          margin-left: auto;
        `}
        type="submit"
        color="primary"
        label="Save Notes"
        disabled={!hasEditPermission}
      />
    </Form>
  );
}
