/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Card } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

import FileProcessingIcon from "./file-processing-icon";

export function FileProcessingBanner(): JSX.Element {
  return (
    <Card
      variant="flat"
      borderRadius={5}
      css={(theme: AppTheme) =>
        css`
          margin-bottom: ${theme.spacing(2)};
          padding: ${theme.spacing(2)};
        `
      }
    >
      <div
        css={(theme: AppTheme) => css`
          display: grid;
          grid-template-columns:
            calc(10% - ${theme.spacing(2)})
            calc(90% - ${theme.spacing(1)});
          align-items: center;
        `}
      >
        <FileProcessingIcon />
        <div>
          <Typography
            css={css`
              color: #d37844;
            `}
          >
            Your bulk upload is currently processing
          </Typography>
          <Typography
            css={css`
              font-size: 0.8em;
            `}
          >
            This may take up to 48 hours. We will notify you when your employees
            have been updated
          </Typography>
        </div>
      </div>
    </Card>
  );
}
