import { ListRewardsUsersFilterInput } from "@rewards-web/shared/graphql-types";
import { isNullOrUndefined } from "@rewards-web/shared/lib/is-null-or-undefined";

export type SerializedEmployeeFilter = Pick<
  ListRewardsUsersFilterInput,
  "active" | "providedPersonalInfo"
>;

/**
 * Top-level filter values, which enable the child filter values.
 * If these are not selected, their corresponding child values are not applied at all.
 */
export type EmployeeFilterEnabledValue =
  | "ACTIVE_FILTER_ENABLED"
  | "PROVIDED_PERSONAL_INFO_FILTER_ENABLED";

/**
 * These child values are applied when their corresponding parent filter is selected as well.
 * For instance, in order to apply "ACTIVE" filter value, "ACTIVE_FILTER_ENABLED" must be selected as well.
 */
export type EmployeeFilterChildValue =
  | "ACTIVE"
  | "DEACTIVATED"
  | "PROVIDED_PERSONAL_INFO"
  | "NOT_PROVIDED_PERSONAL_INFO";

export type EmployeeFilterValue =
  | EmployeeFilterEnabledValue
  | EmployeeFilterChildValue;

export function getEmployeeFilterCheckboxOptions(): Array<{
  label: string;
  value: EmployeeFilterEnabledValue;
  children: Array<{ label: string; value: EmployeeFilterChildValue }>;
}> {
  return [
    {
      label: "Active",
      value: "ACTIVE_FILTER_ENABLED",
      children: [
        {
          label: "Active",
          value: "ACTIVE",
        },
        {
          label: "De-activated",
          value: "DEACTIVATED",
        },
      ],
    },
    {
      label: "Signed Up",
      value: "PROVIDED_PERSONAL_INFO_FILTER_ENABLED",
      children: [
        {
          label: "Has signed up",
          value: "PROVIDED_PERSONAL_INFO",
        },
        {
          label: "Has not signed up",
          value: "NOT_PROVIDED_PERSONAL_INFO",
        },
      ],
    },
  ];
}

/**
 * Converts the filter values into a GraphQL filter input to query the API.
 */
export function serializeEmployeeFilter(
  filterValue: Set<EmployeeFilterValue>
): SerializedEmployeeFilter {
  return {
    active: (() => {
      if (filterValue.has("ACTIVE_FILTER_ENABLED")) {
        if (filterValue.has("ACTIVE") && !filterValue.has("DEACTIVATED")) {
          return true;
        }

        if (filterValue.has("DEACTIVATED") && !filterValue.has("ACTIVE")) {
          return false;
        }
      }

      return null;
    })(),

    providedPersonalInfo: (() => {
      if (filterValue.has("PROVIDED_PERSONAL_INFO_FILTER_ENABLED")) {
        if (
          filterValue.has("PROVIDED_PERSONAL_INFO") &&
          !filterValue.has("NOT_PROVIDED_PERSONAL_INFO")
        ) {
          return true;
        }

        if (
          filterValue.has("NOT_PROVIDED_PERSONAL_INFO") &&
          !filterValue.has("PROVIDED_PERSONAL_INFO")
        ) {
          return false;
        }
      }

      return null;
    })(),
  };
}

export function deserializeEmployeeFilter(
  serializedFilter: SerializedEmployeeFilter,
  previousFilters = new Set<EmployeeFilterValue>()
): Set<EmployeeFilterValue> {
  if (
    previousFilters &&
    Object.entries(serializeEmployeeFilter(previousFilters)).every(
      ([key, previousValue]) =>
        serializedFilter[key as keyof SerializedEmployeeFilter] ===
        previousValue
    )
  ) {
    // if it's functionally equivalent, keep the filter currently in state.
    // that way, we can keep the checkboxes the user previously had if
    // they re-enable the section.
    return previousFilters;
  }
  const filterValue = new Set<EmployeeFilterValue>();

  if (!isNullOrUndefined(serializedFilter.active)) {
    if (serializedFilter.active === true) {
      filterValue.add("ACTIVE_FILTER_ENABLED");
      filterValue.add("ACTIVE");
    } else if (serializedFilter.active === false) {
      filterValue.add("ACTIVE_FILTER_ENABLED");
      filterValue.add("DEACTIVATED");
    }
  }

  if (!isNullOrUndefined(serializedFilter.providedPersonalInfo)) {
    if (serializedFilter.providedPersonalInfo === true) {
      filterValue.add("PROVIDED_PERSONAL_INFO_FILTER_ENABLED");
      filterValue.add("PROVIDED_PERSONAL_INFO");
    } else if (serializedFilter.providedPersonalInfo === false) {
      filterValue.add("PROVIDED_PERSONAL_INFO_FILTER_ENABLED");
      filterValue.add("NOT_PROVIDED_PERSONAL_INFO");
    }
  }

  return filterValue;
}
