import { SVGProps } from "react";

function TotalInterviewsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={26}
      height={23}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.805 4.318H1.72C.772 4.318 0 5.09 0 6.039v11.59c0 .95.772 1.722 1.721 1.722H3.56c.166 0 .317.09.4.232l1.264 2.22c.227.396.633.64 1.09.648h.028a1.27 1.27 0 0 0 1.087-.603l1.432-2.283a.46.46 0 0 1 .39-.215h10.556c.949 0 1.721-.773 1.721-1.722V6.04a1.725 1.725 0 0 0-1.722-1.721Zm.897 13.313a.896.896 0 0 1-.896.896H9.249c-.445 0-.852.225-1.088.601L6.73 21.411a.45.45 0 0 1-.4.216.451.451 0 0 1-.39-.233l-1.264-2.22a1.29 1.29 0 0 0-1.117-.65l-1.837.001a.896.896 0 0 1-.895-.895L.824 6.04c0-.496.402-.896.896-.896h18.085c.495 0 .895.401.895.895l.002 11.592Z"
        fill="#4D4D4D"
      />
      <path
        d="M24.277.394H6.195c-.949 0-1.721.772-1.721 1.72v.56a.413.413 0 1 0 .825 0v-.56c0-.495.402-.896.896-.896H24.28c.495 0 .895.402.895.896v11.59a.896.896 0 0 1-.895.896h-.76a.413.413 0 1 0 0 .825h.76c.949 0 1.721-.772 1.721-1.721V2.114a1.724 1.724 0 0 0-1.723-1.72Z"
        fill="#4D4D4D"
      />
      <path
        d="M17.054 7.953H4.472a.413.413 0 1 0 0 .826h12.584a.413.413 0 1 0-.002-.826ZM17.054 11.422H4.472a.412.412 0 1 0 0 .826h12.584a.413.413 0 1 0-.002-.826ZM17.054 14.89H4.472a.413.413 0 1 0 0 .826h12.584a.413.413 0 1 0-.002-.826Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}

export default TotalInterviewsIcon;
