/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/types";

interface MfaPageContainerProps {
  children: ReactNode;
}

export function MfaPageContainer({ children }: MfaPageContainerProps) {
  return (
    <div
      css={css`
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: white;
        overflow-y: scroll;
      `}
    >
      <div
        css={(theme: AppTheme) => css`
          max-width: 430px;
          margin: 0 auto;
          margin-top: ${theme.spacing(4)};
          margin-bottom: ${theme.spacing(4)};
          ${theme.breakpoints.up("md")} {
            margin-top: ${theme.spacing(12)};
            margin-bottom: ${theme.spacing(12)};
          }
          text-align: center;
        `}
      >
        {children}
      </div>
    </div>
  );
}
