import {
  EvvRules,
  EvvRulesClockMethod,
} from "@rewards-web/shared/graphql-types";
import { extractNumber } from "@rewards-web/shared/lib/extract-number";

import { EvvRulesClockMethodToRender } from ".";

export const getTheInitialValidClockMethodValue = (
  initialValidClockMethods: EvvRulesClockMethod[]
) => {
  if (
    initialValidClockMethods?.includes(EvvRulesClockMethod.MobileApp) &&
    initialValidClockMethods?.includes(EvvRulesClockMethod.Ivr)
  )
    return EvvRulesClockMethodToRender.IvrAndMobileApp;
  if (initialValidClockMethods?.includes(EvvRulesClockMethod.MobileApp))
    return EvvRulesClockMethodToRender.MobileApp;
};

export const isEVVRulesEnabled = (rules: EvvRules) => {
  if (!rules) return false;
  if (
    !(
      rules.minutesGreaterThanScheduledDuration ||
      rules.minutesGreaterThanScheduledEnd ||
      rules.minutesGreaterThanScheduledStart ||
      rules.minutesLessThanScheduledDuration ||
      rules.minutesLessThanScheduledEnd ||
      rules.minutesLessThanScheduledStart ||
      rules.validClockMethods
    )
  )
    return false;
  return true;
};

export const convertValidTimeToNumber = (timeInMinutes: string | null) => {
  return timeInMinutes ? Number(extractNumber(timeInMinutes)) : null;
};

export const mapValidClockMethods = (
  validClockMethods: EvvRulesClockMethodToRender
) => {
  if (validClockMethods === EvvRulesClockMethodToRender.MobileApp)
    return [EvvRulesClockMethod.MobileApp, EvvRulesClockMethod.SystemAutoClock];
  else if (validClockMethods === EvvRulesClockMethodToRender.IvrAndMobileApp)
    return [
      EvvRulesClockMethod.MobileApp,
      EvvRulesClockMethod.Ivr,
      EvvRulesClockMethod.SystemAutoClock,
    ];
  return null;
};
