import { useForm } from "react-hook-form";

import { Form } from "@rewards-web/shared/components/form";

import {
  EditReferralStructureFormContent,
  EditReferralStructureFormValues,
} from "../../../../../../shared/components/edit-referral-structure-form-content";
import { calculateTotalReferralPointValue } from "../../../../../../shared/components/edit-referral-structure-form-content/calculate-total-referral-point-value";
import { generateEditRefferalStructureTableRows } from "../../../../../../shared/components/edit-referral-structure-form-content/generate-edit-referral-structure-table-rows";
import {
  EditReferralStructureFormProps,
  getDefaultValues,
} from "../../../../settings/pages/referral-structure/edit-referral-structure/edit-referral-structure-form";
import { AdminOnboardingCard } from "../../../components/onboarding-card/onboarding-card";
import { AdminOnboardingCardContent } from "../../../components/onboarding-card/onboarding-card-content";
import { AdminOnboardingStepActions } from "../../../components/step-actions";

export function EditRewardStructureForm({
  data,
  onSubmit,
}: EditReferralStructureFormProps): JSX.Element {
  const initialValues = getDefaultValues(data);

  const form = useForm<EditReferralStructureFormValues>({
    defaultValues: initialValues,
  });
  const { control } = form;

  const values = form.watch();
  const totalPointValue = calculateTotalReferralPointValue(values);

  const tableRows = generateEditRefferalStructureTableRows({
    data,
  });

  const pointsPerDollar = data.getMyRewardsOrganization.pointsPerDollar;

  return (
    <Form
      submitting={form.formState.isSubmitting}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <AdminOnboardingCard>
        <AdminOnboardingCardContent>
          <EditReferralStructureFormContent
            tableRows={tableRows}
            control={control}
            pointsPerDollar={pointsPerDollar}
            totalPointValue={totalPointValue}
            showExplanationTooltip
          />
        </AdminOnboardingCardContent>
      </AdminOnboardingCard>

      <AdminOnboardingStepActions
        submitButtonLabel="Save & Continue"
        disableSkip
      />
    </Form>
  );
}
