import { faWallet } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BudgetUsageInsightMetric as BudgetUsageInsightMetricType } from "@rewards-web/shared/graphql-types";

import { InsightMetric } from "../../../../components/insight-metric";
import { getDifferenceOfPercentages } from "../../../../utils";

const getBudgetUsageFraction = ({
  pointsSentByAdminsWithBudgets,
  adminBudgetTotal,
}: BudgetUsageInsightMetricType) => ({
  numerator: Math.min(pointsSentByAdminsWithBudgets, adminBudgetTotal), // capped at 100%
  denominator: adminBudgetTotal,
});

export interface BudgetUsageInsightMetricProps {
  currentBudgetUsageInsightMetric: BudgetUsageInsightMetricType | undefined;
  previousBudgetUsageInsightMetric: BudgetUsageInsightMetricType | undefined;
}

export function BudgetUsageInsightMetric({
  currentBudgetUsageInsightMetric,
  previousBudgetUsageInsightMetric,
}: BudgetUsageInsightMetricProps) {
  const percentPointChange = (() => {
    if (
      currentBudgetUsageInsightMetric === undefined ||
      previousBudgetUsageInsightMetric === undefined
    ) {
      return null;
    }
    return getDifferenceOfPercentages({
      prev: getBudgetUsageFraction(previousBudgetUsageInsightMetric),
      curr: getBudgetUsageFraction(currentBudgetUsageInsightMetric),
    });
  })();

  return (
    <InsightMetric
      label="Budget usage"
      icon={<FontAwesomeIcon icon={faWallet} />}
      tooltipText="Percentage of allocated budget used for recognitions by admins with budgets."
      value={
        currentBudgetUsageInsightMetric !== undefined
          ? {
              type: "percent",
              ...getBudgetUsageFraction(currentBudgetUsageInsightMetric),
            }
          : {
              type: "loading",
            }
      }
      percentPointChange={percentPointChange}
    />
  );
}
