import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UserFilterOptionsQueryVariables = Types.Exact<{
  searchQuery?: Types.Maybe<Types.Scalars['String']>;
}>;


export type UserFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { searchForRewardsUsers: Array<(
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'firstName' | 'lastName'>
  )> }
);


export const UserFilterOptionsDocument = gql`
    query UserFilterOptions($searchQuery: String) {
  searchForRewardsUsers(searchQuery: $searchQuery, limit: 30) {
    id
    firstName
    lastName
  }
}
    `;

/**
 * __useUserFilterOptionsQuery__
 *
 * To run a query within a React component, call `useUserFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFilterOptionsQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useUserFilterOptionsQuery(baseOptions?: Apollo.QueryHookOptions<UserFilterOptionsQuery, UserFilterOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserFilterOptionsQuery, UserFilterOptionsQueryVariables>(UserFilterOptionsDocument, options);
      }
export function useUserFilterOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserFilterOptionsQuery, UserFilterOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserFilterOptionsQuery, UserFilterOptionsQueryVariables>(UserFilterOptionsDocument, options);
        }
export type UserFilterOptionsQueryHookResult = ReturnType<typeof useUserFilterOptionsQuery>;
export type UserFilterOptionsLazyQueryHookResult = ReturnType<typeof useUserFilterOptionsLazyQuery>;
export type UserFilterOptionsQueryResult = Apollo.QueryResult<UserFilterOptionsQuery, UserFilterOptionsQueryVariables>;