import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UploadCandidateResumeMutationVariables = Types.Exact<{
  candidateId: Types.Scalars['ID'];
  resume: Types.Scalars['Upload'];
}>;


export type UploadCandidateResumeMutation = (
  { __typename?: 'Mutation' }
  & { uploadCandidateResume: (
    { __typename?: 'UploadCandidateResumeResponse' }
    & Pick<Types.UploadCandidateResumeResponse, 'resumeId'>
  ) }
);


export const UploadCandidateResumeDocument = gql`
    mutation UploadCandidateResume($candidateId: ID!, $resume: Upload!) {
  uploadCandidateResume(candidateId: $candidateId, resume: $resume) {
    resumeId
  }
}
    `;
export type UploadCandidateResumeMutationFn = Apollo.MutationFunction<UploadCandidateResumeMutation, UploadCandidateResumeMutationVariables>;

/**
 * __useUploadCandidateResumeMutation__
 *
 * To run a mutation, you first call `useUploadCandidateResumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCandidateResumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCandidateResumeMutation, { data, loading, error }] = useUploadCandidateResumeMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      resume: // value for 'resume'
 *   },
 * });
 */
export function useUploadCandidateResumeMutation(baseOptions?: Apollo.MutationHookOptions<UploadCandidateResumeMutation, UploadCandidateResumeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadCandidateResumeMutation, UploadCandidateResumeMutationVariables>(UploadCandidateResumeDocument, options);
      }
export type UploadCandidateResumeMutationHookResult = ReturnType<typeof useUploadCandidateResumeMutation>;
export type UploadCandidateResumeMutationResult = Apollo.MutationResult<UploadCandidateResumeMutation>;
export type UploadCandidateResumeMutationOptions = Apollo.BaseMutationOptions<UploadCandidateResumeMutation, UploadCandidateResumeMutationVariables>;