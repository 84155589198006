/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/client";
import { css } from "@emotion/react";
import { Checkbox } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import GetAppIcon from "@material-ui/icons/GetApp";
import { format } from "date-fns";
import { useState } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { ObscureRecordedText } from "@rewards-web/shared/components/obscure-recorded-text";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  RedemptionMethod,
  RedemptionStatus,
} from "@rewards-web/shared/graphql-types";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import {
  useTrack,
  useTrackScreenRecordingEvent,
} from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { BranchFilter } from "../../../../shared/components/branch-filter";
import { DataTable } from "../../../../shared/components/data-table";
import { UserFilter } from "../../../../shared/components/user-filter";
import { useMyBranches } from "../../../../shared/modules/branches/use-my-branches";
import { useHasPermissionQuery } from "../../../../shared/modules/permissions/hooks/use-has-permission-query";
import { useBulkMarkRedemptionsAsGenericGiftCardSentMutation } from "../bulk-mark-redemptions-as-generic-gift-card-sent.generated";
import { useBulkMarkRedemptionsAsSentToPayrollMutation } from "../bulk-mark-redemptions-as-sent-to-payroll.generated";
import { useGenerateRedemptionExportMutation } from "../generate-redemption-export.generated";
import { useListRedemptionsQuery } from "../get-redemptions.generated";
import { ExportRedemptionModal } from "./export-redemption-modal";
import { MarkAsGiftCardSentModal } from "./mark-as-gift-card-sent-modal";
import { MarkAsSentToPayrollModal } from "./mark-as-sent-to-payroll-modal";
import { useOrganizationRedemptionMethodsQuery } from "./organization-redemption-methods.generated";
import {
  SelectedRedemptionFragment,
  SelectedRedemptionFragmentDoc,
} from "./selected-redemption-fragment.generated";

const ITEMS_PER_PAGE = 10;
/**
 * Generating more than 100 redemptions at a time takes more han 30s and can cause a timeout.
 * That results in an Internal Server Error and the user keeps trying.
 */
const MAX_REDEMPTIONS_TO_EXPORT = 100;

export function RedemptionList(): JSX.Element {
  const trackScreenRecordingEvent = useTrackScreenRecordingEvent();
  const client = useApolloClient();
  const snackbar = useSnackbar();
  const hasFullRedemptionPermissionsQuery = useHasPermissionQuery(
    "full",
    "redemptions"
  );
  const branchesQuery = useMyBranches();

  const track = useTrack();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [sentToPayrollModalOpen, setSentToPayrollModalOpen] = useState(false);
  const [giftCardSentModalOpen, setGiftCardSentModalOpen] = useState(false);
  const [exportModalState, setExportModalState] = useState<
    | {
        open: true;
        downloadLink: string | null;
        redemptionExportId: string | null;
      }
    | {
        open: false;
      }
  >({ open: false });

  const [
    bulkMarkGenericGiftCardSent,
    { loading: markingGiftCardSent },
  ] = useBulkMarkRedemptionsAsGenericGiftCardSentMutation();

  const [
    bulkMarkSentToPayroll,
    { loading: markingSentToPayroll },
  ] = useBulkMarkRedemptionsAsSentToPayrollMutation();
  const [generateRedemptionExport] = useGenerateRedemptionExportMutation();

  const [selectedUserFilterId, setSelectedUserFilterId] = useState<
    string | null
  >(null);
  const [selectedBranchFilterIds, setSelectedBranchFilterIds] = useState<
    Array<string | null>
  >([]);
  const [selectedRedemptionIds, setSelectedRedemptionIds] = useState(
    new Set<string>()
  );

  const { data, loading, error, refetch } = useListRedemptionsQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only", // prevent from reloading query automatically after a mutation
    variables: {
      filter: {
        branchIds:
          selectedBranchFilterIds.length > 0
            ? selectedBranchFilterIds
            : undefined,
        userId: selectedUserFilterId,
      },
      offset: currentPageIndex * ITEMS_PER_PAGE,
      limit: ITEMS_PER_PAGE,
    },
    onError: reportError,
  });

  const {
    data: allowedRedemptionMethodsQueryData,
    loading: allowedRedemptionMethodsQueryLoading,
  } = useOrganizationRedemptionMethodsQuery();

  if (allowedRedemptionMethodsQueryLoading) {
    return <PageLoadingState />;
  }

  const allowedRedemptionMethods = allowedRedemptionMethodsQueryData
    ?.getMyRewardsOrganization?.allowedRedemptionMethods ?? [
    RedemptionMethod.Payroll,
  ];

  const giftCardRedemptionsAllowed = allowedRedemptionMethods.includes(
    RedemptionMethod.GenericGiftCardLink
  );

  const handleRedemptionSelect = (id: string) => {
    setSelectedRedemptionIds((prev) => {
      if (selectedRedemptionIds.has(id)) {
        const next = new Set(prev);
        next.delete(id);
        return next;
      } else {
        return new Set(prev).add(id);
      }
    });
  };

  const selectedAnyRedemptionIds = selectedRedemptionIds.size > 0;

  const canExportRedemptions =
    selectedRedemptionIds.size > 0 &&
    selectedRedemptionIds.size <= MAX_REDEMPTIONS_TO_EXPORT;
  const exportRedemptionsDisabledTooltip =
    selectedRedemptionIds.size > MAX_REDEMPTIONS_TO_EXPORT
      ? "You can only export up to 100 redemptions at a time. Consider exporting in smaller batches."
      : "You must select at least 1 redemption to export a redemption CSV.";

  const everySelectedRedemptionIsRequestedOrFailed = Array.from(
    selectedRedemptionIds
  ).every((redemptionId) => {
    const redemption = client.cache.readFragment<SelectedRedemptionFragment>({
      id: `Redemption:${redemptionId}`,
      fragment: SelectedRedemptionFragmentDoc,
    });

    if (!redemption) {
      reportError(new Error("Could not read redemption from cache"));
      return false;
    }

    return (
      redemption.status === RedemptionStatus.Requested ||
      redemption.status === RedemptionStatus.GenericGiftCardFulfillmentFailed
    );
  });

  const canUpdateRedemptionStatus =
    selectedAnyRedemptionIds && everySelectedRedemptionIsRequestedOrFailed;

  const selectedAll =
    data?.listRedemptions.items &&
    data?.listRedemptions.items.length > 0 &&
    data?.listRedemptions.items.every((redemption) =>
      selectedRedemptionIds.has(redemption.id)
    );

  const resetPageAndCheckedItems = () => {
    setCurrentPageIndex(0);
    setSelectedRedemptionIds(new Set());
  };

  const handleSelectAllClick = () => {
    if (selectedAll) {
      // deselect all (on current page)
      setSelectedRedemptionIds((prev) =>
        (data?.listRedemptions.items ?? []).reduce((prev, redemption) => {
          prev.delete(redemption.id);
          return prev;
        }, new Set(prev))
      );
    } else {
      // select all
      setSelectedRedemptionIds((prev) =>
        (data?.listRedemptions.items ?? []).reduce(
          (prev, redemption) => prev.add(redemption.id),
          new Set(prev)
        )
      );
    }
  };

  const onExport = async () => {
    try {
      const redemptionIds = Array.from(selectedRedemptionIds);
      setExportModalState({
        open: true,
        downloadLink: null,
        redemptionExportId: null,
      });
      const res = await generateRedemptionExport({
        variables: { redemptionIds },
      });
      track("Generated redemption export", {
        redemptionIds,
        redemptionExportId: res.data!.generateRewardsRedemptionExport.id,
      });
      setExportModalState({
        open: true,
        redemptionExportId: res.data!.generateRewardsRedemptionExport.id,
        downloadLink: res.data!.generateRewardsRedemptionExport.downloadLink,
      });
    } catch (error) {
      setExportModalState({ open: false });
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  const onMarkSentToPayroll = async () => {
    try {
      const redemptionIds = Array.from(selectedRedemptionIds);
      await bulkMarkSentToPayroll({
        variables: { redemptionIds },
      });
      setSelectedRedemptionIds(new Set());
      track("Marked redemptions as sent to payroll", { redemptionIds });
      snackbar.show({
        severity: "success",
        message: `${redemptionIds.length} redemption(s) have been updated`,
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    } finally {
      setSentToPayrollModalOpen(false);
      refetch();
    }
  };

  const onMarkGiftCardSent = async () => {
    try {
      const redemptionIds = Array.from(selectedRedemptionIds);
      await bulkMarkGenericGiftCardSent({
        variables: { redemptionIds },
      });
      setSelectedRedemptionIds(new Set());
      track("Marked redemptions as Gift Card Sent", { redemptionIds });
      snackbar.show({
        severity: "success",
        message: `${redemptionIds.length} redemption(s) have been updated`,
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    } finally {
      setGiftCardSentModalOpen(false);
      refetch();
    }
  };

  if (error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  const total = data?.listRedemptions.total;
  const hasBranches =
    (branchesQuery.data?.getMyRewardsOrganization.branches.length ?? 0) > 0;

  return (
    <>
      <Typography variant="h1" color="textPrimary" gutterBottom>
        Redemptions
      </Typography>
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: ${theme.spacing(2)};
          flex-wrap: wrap;
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            align-items: center;
            & > *:not(:first-child) {
              margin-left: ${theme.spacing(2)};
            }
          `}
        >
          <UserFilter
            value={selectedUserFilterId}
            onChange={(nextId) => {
              resetPageAndCheckedItems();
              setSelectedUserFilterId(nextId);
              trackScreenRecordingEvent("redemption_employee_filter_changed");
              track("Updated redemption employee filter");
            }}
            css={(theme: AppTheme) => css`
              margin-top: ${theme.spacing(1)};
            `}
          />

          {hasBranches && (
            <BranchFilter
              value={selectedBranchFilterIds}
              onChange={(nextIds) => {
                resetPageAndCheckedItems();
                setSelectedBranchFilterIds(nextIds);
              }}
              resourceName="Redemptions"
              width={350}
              onOpen={() => {
                trackScreenRecordingEvent("redemption_branch_filter_opened");
                track("Opened redemption branch filter");
              }}
            />
          )}
        </div>
        <div>
          <Typography
            variant="body"
            color="textPrimary"
            display="inline"
            css={(theme: AppTheme) => css`
              margin-right: ${theme.spacing(2)};
              transition: opacity 0.2s;
              ${selectedRedemptionIds.size === 0 &&
              css`
                opacity: 0.4;
              `}
            `}
          >
            With selected
            {selectedRedemptionIds.size > 0 ? (
              <Typography
                variant="inherit"
                color="primary"
                css={css`
                  font-weight: bold;
                `}
              >
                {" "}
                ({selectedRedemptionIds.size})
              </Typography>
            ) : (
              ""
            )}
            :
          </Typography>

          <Tooltip
            title={exportRedemptionsDisabledTooltip}
            disabled={canExportRedemptions}
          >
            <Button
              width="auto"
              startIcon={<GetAppIcon />}
              size="medium"
              color="primary"
              label="Export to CSV"
              onClick={onExport}
              disabled={!canExportRedemptions}
              css={(theme: AppTheme) => css`
                margin-right: ${theme.spacing(1)};
              `}
            />
          </Tooltip>
          <Tooltip
            title={
              selectedAnyRedemptionIds
                ? 'Only "requested" redemptions can have their statuses updated'
                : "Select redemptions to update their status"
            }
            disabled={canUpdateRedemptionStatus}
          >
            {giftCardRedemptionsAllowed ? (
              <Button
                width="auto"
                startIcon={<CheckCircleOutlineIcon />}
                size="medium"
                color="primary"
                label='Mark as "Gift Card Sent"'
                onClick={() => {
                  setGiftCardSentModalOpen(true);
                }}
                disabled={!canUpdateRedemptionStatus}
              />
            ) : (
              <Button
                width="auto"
                startIcon={<CheckCircleOutlineIcon />}
                size="medium"
                color="primary"
                label='Mark as "Sent to Payroll"'
                onClick={() => {
                  setSentToPayrollModalOpen(true);
                }}
                disabled={!canUpdateRedemptionStatus}
              />
            )}
          </Tooltip>
        </div>
      </div>

      <DataTable
        itemsPerPage={ITEMS_PER_PAGE}
        currentPageIndex={currentPageIndex}
        onCurrentPageIndexChange={setCurrentPageIndex}
        emptyText="There are no redemptions."
        loading={loading}
        items={data?.listRedemptions.items}
        total={total}
        tableHeaders={
          <TableRow>
            {hasFullRedemptionPermissionsQuery.hasPermission && (
              <TableHeader size="small">
                <Checkbox
                  color="primary"
                  disabled={loading}
                  css={css`
                    width: 40px;
                    max-height: 20px;
                  `}
                  checked={selectedAll}
                  onChange={handleSelectAllClick}
                />
              </TableHeader>
            )}

            <TableHeader>
              <Typography
                variant="body"
                css={css`
                  font-weight: normal;
                  display: inline-block;
                `}
              >
                Employee
              </Typography>
            </TableHeader>

            <TableHeader>
              <Typography
                variant="body"
                css={css`
                  font-weight: normal;
                `}
              >
                Reward requested
              </Typography>
            </TableHeader>

            <TableHeader>
              <Typography
                variant="body"
                css={css`
                  font-weight: normal;
                `}
              >
                Date requested
              </Typography>
            </TableHeader>

            <TableHeader>
              <Typography
                variant="body"
                css={css`
                  font-weight: normal;
                `}
              >
                Date sent to payroll
              </Typography>
            </TableHeader>

            <TableHeader>
              <Typography
                variant="body"
                css={css`
                  font-weight: normal;
                `}
              >
                Status
              </Typography>
            </TableHeader>

            <TableHeader>
              <Typography
                variant="body"
                css={css`
                  font-weight: normal;
                `}
              >
                Employee ID
              </Typography>
            </TableHeader>

            <TableHeader>
              <Typography
                variant="body"
                css={css`
                  font-weight: normal;
                `}
              >
                Group
              </Typography>
            </TableHeader>
          </TableRow>
        }
        tableBody={data?.listRedemptions.items.map((request) => {
          return (
            <TableRow key={request.id}>
              {hasFullRedemptionPermissionsQuery.hasPermission && (
                <TableCell divider>
                  <Checkbox
                    checked={selectedRedemptionIds.has(request.id)}
                    color="primary"
                    disabled={loading}
                    css={css`
                      width: 40px;
                      max-height: 20px;
                    `}
                    onChange={() => {
                      handleRedemptionSelect(request.id);
                    }}
                  />
                </TableCell>
              )}

              <TableCell divider>
                <ObscureRecordedText>
                  <Typography variant="body" color="textSecondary">
                    {`${request.user.firstName} ${request.user.lastName}`}
                  </Typography>
                </ObscureRecordedText>
              </TableCell>

              <TableCell divider>
                <Typography variant="body" color="textSecondary">
                  {`${numberWithCommas(request.points)} points (${formatDollars(
                    request.dollars
                  )})`}
                </Typography>
              </TableCell>

              <TableCell divider>
                <Typography variant="body" color="textSecondary">
                  {format(request.createdAt, "LLLL d y")}
                </Typography>
              </TableCell>

              <TableCell divider>
                <Typography variant="body" color="textSecondary">
                  {(() => {
                    const date =
                      request.markedAsSentToPayrollAt || request.markedAsPaidAt;

                    return date ? format(date, "LLLL d y") : "-";
                  })()}
                </Typography>
              </TableCell>

              <TableCell divider>
                <Typography variant="body" color="textSecondary">
                  {(() => {
                    switch (request.status) {
                      case RedemptionStatus.Paid:
                        return "Paid";
                      case RedemptionStatus.Requested:
                        return "Requested";
                      case RedemptionStatus.SentToPayroll:
                        return "Sent to payroll";
                      case RedemptionStatus.SentGenericGiftCardLink:
                        return "Gift Card Sent";
                      case RedemptionStatus.GenericGiftCardFulfillmentFailed:
                        return "Gift Card Failed";
                      default:
                        return "Unknown";
                    }
                  })()}
                </Typography>
              </TableCell>

              <TableCell divider>
                <Typography variant="body" color="textSecondary">
                  {request.user.externalEmployeeId || "-"}
                </Typography>
              </TableCell>

              <TableCell divider>
                <Typography variant="body" color="textSecondary">
                  {request.user.groups.length > 0
                    ? request.user.groups.map((group) => group.name).join(", ")
                    : "-"}
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
      />

      <ExportRedemptionModal
        open={exportModalState.open}
        redemptionExportId={
          exportModalState.open ? exportModalState.redemptionExportId : null
        }
        downloadLink={
          exportModalState.open ? exportModalState.downloadLink : null
        }
        onClose={() => {
          setExportModalState({ open: false });
        }}
      />

      <MarkAsSentToPayrollModal
        open={sentToPayrollModalOpen}
        loading={markingSentToPayroll}
        numRedemptions={selectedRedemptionIds.size}
        onClose={() => setSentToPayrollModalOpen(false)}
        onConfirm={onMarkSentToPayroll}
      />

      <MarkAsGiftCardSentModal
        open={giftCardSentModalOpen}
        loading={markingGiftCardSent}
        numRedemptions={selectedRedemptionIds.size}
        onClose={() => setGiftCardSentModalOpen(false)}
        onConfirm={onMarkGiftCardSent}
      />
    </>
  );
}
