/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import {
  RewardsAdminRestrictedAccessPermissionsMapV2,
  RewardsAdminRestrictedAccessPermissionLevel,
} from "@rewards-web/shared/graphql-types";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface AdminRestrictedPermissionsInfoTooltipProps {
  permissions: RewardsAdminRestrictedAccessPermissionsMapV2;
}

export function AdminRestrictedPermissionsInfoTooltip({
  permissions,
}: AdminRestrictedPermissionsInfoTooltipProps): JSX.Element {
  const enabledPermissions = Object.entries(permissions)
    .filter(([key]) => key !== "__typename")
    .map(
      ([resource, level]) =>
        ({
          resource,
          level: (level as any)?.permissionLevel ?? null,
        } as {
          resource: keyof Omit<
            RewardsAdminRestrictedAccessPermissionsMapV2,
            "__typename"
          >;
          level: RewardsAdminRestrictedAccessPermissionLevel | null;
        })
    );

  const fullAccessPermissions = enabledPermissions.filter(
    ({ level }) =>
      level === RewardsAdminRestrictedAccessPermissionLevel.FullAccess
  );

  const readAccessPermissions = enabledPermissions.filter(
    ({ level }) =>
      level === RewardsAdminRestrictedAccessPermissionLevel.ReadOnly
  );

  return (
    <div
      css={(theme: AppTheme) => css`
        & > *:not(:last-child) {
          margin-bottom: ${theme.spacing(1)};
        }
      `}
    >
      {fullAccessPermissions.length > 0 && (
        <div>
          <strong>Can Edit</strong>
          <br />
          &#09;
          {fullAccessPermissions
            .map(({ resource }) => RESOURCE_LABEL_MAP[resource])
            .join(", ")}
        </div>
      )}

      {readAccessPermissions.length > 0 && (
        <div>
          <strong>Can View</strong>
          <br />
          &#09;
          {readAccessPermissions
            .map(({ resource }) => RESOURCE_LABEL_MAP[resource])
            .join(", ")}
        </div>
      )}
    </div>
  );
}

const RESOURCE_LABEL_MAP: {
  [key in keyof Omit<
    RewardsAdminRestrictedAccessPermissionsMapV2,
    "__typename"
  >]: string;
} = {
  jobs: "Jobs",
  candidates: "Candidates",
  redemptions: "Redemptions",
  recognitionPoints: "Recognition",
  employees: "Employees",
  administrators: "Admins",
};
