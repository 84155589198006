import * as React from "react";

function BonusPointsLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)" fill={props.color}>
        <path d="M1.554 7.88a1.31 1.31 0 01-.333-1.353 1.31 1.31 0 011.064-.897l4.377-.636a.264.264 0 00.198-.145L8.817.884A1.31 1.31 0 0110 .149c.506 0 .959.282 1.183.735L13.14 4.85a.264.264 0 00.198.144l4.376.636c.5.072.909.416 1.065.897.157.481.029 1-.334 1.352l-3.166 3.087a.264.264 0 00-.076.234l.748 4.358a1.31 1.31 0 01-.525 1.29c-.41.297-.942.336-1.39.1l-3.913-2.057a.263.263 0 00-.246 0l-3.914 2.057a1.313 1.313 0 01-1.389-.1 1.31 1.31 0 01-.525-1.29l.748-4.358a.264.264 0 00-.076-.234L1.554 7.879zm4.283 3.498l-.748 4.358a.257.257 0 00.105.258c.04.03.147.09.278.02l3.914-2.057a1.318 1.318 0 011.228 0l3.914 2.057a.257.257 0 00.277-.02.257.257 0 00.105-.258l-.747-4.358a1.318 1.318 0 01.38-1.168l3.166-3.086a.257.257 0 00.067-.27.257.257 0 00-.213-.18l-4.376-.636c-.43-.062-.801-.332-.994-.722l-1.957-3.965A.257.257 0 0010 1.204a.257.257 0 00-.237.147L7.806 5.316c-.192.39-.563.66-.993.722l-4.376.636a.257.257 0 00-.213.18.257.257 0 00.067.27l3.166 3.087c.311.303.453.739.38 1.167zM3.837.884A.528.528 0 014.574 1l.56.771a.528.528 0 01-.854.62l-.56-.77a.528.528 0 01.117-.737zM14.869 1.768l.56-.77a.528.528 0 01.853.62l-.56.77a.527.527 0 11-.853-.62zM18.064 11.986a.528.528 0 01.665-.34l.906.295a.527.527 0 11-.326 1.004l-.906-.295a.527.527 0 01-.339-.665zM10 17.843c.291 0 .527.236.527.528v.953a.528.528 0 01-1.055 0v-.953c0-.292.236-.528.528-.528zM.365 11.942l.906-.294a.528.528 0 01.326 1.003l-.906.294a.527.527 0 11-.326-1.003z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path
            fill={props.color}
            transform="matrix(-1 0 0 1 20 0)"
            d="M0 0h20v20H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BonusPointsLogo;
