import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type LinkedEmployeeSearchQueryVariables = Types.Exact<{
  searchQuery?: Types.Maybe<Types.Scalars['String']>;
}>;


export type LinkedEmployeeSearchQuery = (
  { __typename?: 'Query' }
  & { searchForRewardsUsers: Array<(
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'firstName' | 'lastName' | 'workEmail' | 'workPhoneNumber'>
    & { personalContactInfo?: Types.Maybe<(
      { __typename: 'RewardsUserPersonalContactInfoData' }
      & Pick<Types.RewardsUserPersonalContactInfoData, 'id' | 'email' | 'phoneNumber'>
    ) | (
      { __typename: 'RewardsUserPersonalContactInfoSuppressed' }
      & Pick<Types.RewardsUserPersonalContactInfoSuppressed, 'id'>
    )> }
  )> }
);


export const LinkedEmployeeSearchDocument = gql`
    query LinkedEmployeeSearch($searchQuery: String) {
  searchForRewardsUsers(searchQuery: $searchQuery, limit: 30) {
    id
    firstName
    lastName
    personalContactInfo {
      __typename
      ... on RewardsUserPersonalContactInfoData {
        id
        email
        phoneNumber
      }
      ... on RewardsUserPersonalContactInfoSuppressed {
        id
      }
    }
    workEmail
    workPhoneNumber
  }
}
    `;

/**
 * __useLinkedEmployeeSearchQuery__
 *
 * To run a query within a React component, call `useLinkedEmployeeSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkedEmployeeSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkedEmployeeSearchQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useLinkedEmployeeSearchQuery(baseOptions?: Apollo.QueryHookOptions<LinkedEmployeeSearchQuery, LinkedEmployeeSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LinkedEmployeeSearchQuery, LinkedEmployeeSearchQueryVariables>(LinkedEmployeeSearchDocument, options);
      }
export function useLinkedEmployeeSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinkedEmployeeSearchQuery, LinkedEmployeeSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LinkedEmployeeSearchQuery, LinkedEmployeeSearchQueryVariables>(LinkedEmployeeSearchDocument, options);
        }
export type LinkedEmployeeSearchQueryHookResult = ReturnType<typeof useLinkedEmployeeSearchQuery>;
export type LinkedEmployeeSearchLazyQueryHookResult = ReturnType<typeof useLinkedEmployeeSearchLazyQuery>;
export type LinkedEmployeeSearchQueryResult = Apollo.QueryResult<LinkedEmployeeSearchQuery, LinkedEmployeeSearchQueryVariables>;