/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { StylableProps } from "@rewards-web/shared/types";

interface TableProps extends StylableProps {
  children?: ReactNode;
}

export function Table({ className, children }: TableProps): JSX.Element {
  return (
    <table
      className={className}
      css={css`
        border-collapse: collapse;
        width: 100%;
      `}
    >
      {children}
    </table>
  );
}
