import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type MyBudgetsDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyBudgetsDataQuery = (
  { __typename?: 'Query' }
  & { listMyRecognitionBudgetPeriods: (
    { __typename?: 'ListMyRecognitionBudgetPeriodsResponse' }
    & { items: Array<(
      { __typename?: 'RecognitionBudgetPeriod' }
      & Pick<Types.RecognitionBudgetPeriod, 'id' | 'startDate' | 'endDate' | 'current'>
    )> }
  ) }
);


export const MyBudgetsDataDocument = gql`
    query MyBudgetsData {
  listMyRecognitionBudgetPeriods {
    items {
      id
      startDate
      endDate
      current
    }
  }
}
    `;

/**
 * __useMyBudgetsDataQuery__
 *
 * To run a query within a React component, call `useMyBudgetsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBudgetsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBudgetsDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyBudgetsDataQuery(baseOptions?: Apollo.QueryHookOptions<MyBudgetsDataQuery, MyBudgetsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyBudgetsDataQuery, MyBudgetsDataQueryVariables>(MyBudgetsDataDocument, options);
      }
export function useMyBudgetsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyBudgetsDataQuery, MyBudgetsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyBudgetsDataQuery, MyBudgetsDataQueryVariables>(MyBudgetsDataDocument, options);
        }
export type MyBudgetsDataQueryHookResult = ReturnType<typeof useMyBudgetsDataQuery>;
export type MyBudgetsDataLazyQueryHookResult = ReturnType<typeof useMyBudgetsDataLazyQuery>;
export type MyBudgetsDataQueryResult = Apollo.QueryResult<MyBudgetsDataQuery, MyBudgetsDataQueryVariables>;