import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

import { PageLayout } from "../../../shared/components/page-layout";
import {
  ADMIN_PERMISSION_KEY_BY_PAGE,
  AdminPageName,
} from "../../../shared/constants/admin-page";
import { AuthorizedAdminPageWrapper } from "../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { ForbiddenErrorPage } from "../../../shared/modules/permissions/components/forbidden-error-page";
import { GoalsPageContents } from "./page-contents";

const MAX_CONTENT_WIDTH = 1400;

export function GoalsPage(): JSX.Element {
  const goalsEnabled = useFeatureFlag(
    "admin-app-budget-utilization-goal-available-temp"
  ); // add flags for other goal types here

  if (!goalsEnabled) {
    return <ForbiddenErrorPage />;
  }

  return (
    <AuthorizedAdminPageWrapper
      resource={ADMIN_PERMISSION_KEY_BY_PAGE[AdminPageName.goals]}
    >
      <PageLayout maxContentWidth={MAX_CONTENT_WIDTH}>
        <GoalsPageContents />
      </PageLayout>
    </AuthorizedAdminPageWrapper>
  );
}
