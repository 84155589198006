/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { CandidateRecruitmentStepName } from "@rewards-web/shared/graphql-types";
import { extractNumber } from "@rewards-web/shared/lib/extract-number";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { EditReferralStructureFormValues } from "../../../../../../shared/components/edit-referral-structure-form-content";
import { useRewardsStructureQuery } from "../../../../../../shared/components/referrals-structure-table/rewards-structure.generated";
import { EditRewardStructureForm } from "./edit-referral-structure-form";
import { useUpdateReferralStructureMutation } from "./edit-referral-structure.generated";

export const prepareRetentionMilestones = (
  values: EditReferralStructureFormValues
) => {
  const retentionMilestones = [];
  if (values.hundredHourWorkedPointValue) {
    retentionMilestones.push({
      unit: "hours",
      pointValue: extractNumber(values.hundredHourWorkedPointValue),
      durationHours: 100,
    });
  }
  if (values.threeHundredHourWorkedPointValue) {
    retentionMilestones.push({
      unit: "hours",
      pointValue: extractNumber(values.threeHundredHourWorkedPointValue),
      durationHours: 300,
    });
  }
  return retentionMilestones;
};

export const prepareRecruitmentSteps = (
  values: EditReferralStructureFormValues
) => {
  const recruitmentSteps = [];
  if (values.candidateExpressInterestPointValue) {
    recruitmentSteps.push({
      step: CandidateRecruitmentStepName.ApplicationSubmitted,
      pointValue: extractNumber(values.candidateExpressInterestPointValue),
    });
  }
  if (values.CompletedOrientationPointValue) {
    recruitmentSteps.push({
      step: CandidateRecruitmentStepName.CompletedOrientation,
      pointValue: extractNumber(values.CompletedOrientationPointValue),
    });
  }
  if (values.CompletedFirstShiftPointValue) {
    recruitmentSteps.push({
      step: CandidateRecruitmentStepName.CompletedFirstShift,
      pointValue: extractNumber(values.CompletedFirstShiftPointValue),
    });
  }
  return recruitmentSteps;
};

export function EditReferralStructurePageContents(): JSX.Element {
  const rewardStructureQueryResponse = useRewardsStructureQuery({
    onError: reportError,
  });
  const navigate = useNavigate();

  const { data } = useRewardsStructureQuery({ onError: reportError });

  const snackbar = useSnackbar();

  const [editReferralStructure] = useUpdateReferralStructureMutation();

  const onSubmit = async (values: EditReferralStructureFormValues) => {
    const recruitmentSteps = prepareRecruitmentSteps(values);

    const retentionMilestones = prepareRetentionMilestones(values);
    try {
      await editReferralStructure({
        variables: {
          input: {
            recruitmentSteps: recruitmentSteps,
            retentionMilestones: retentionMilestones,
          },
        },
      });
      snackbar.show({
        severity: "success",
        message: "Updated Referral Structure",
      });
      navigate("/settings/referral-structure");
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  if (!data) {
    return (
      <div
        css={css`
          height: 200px;
        `}
      >
        <PageLoadingState />
      </div>
    );
  }
  const content = (() => {
    if (rewardStructureQueryResponse.error) {
      return (
        <Alert
          severity="error"
          message="An unexpected error occurred. Please try again later."
        />
      );
    }
    if (!rewardStructureQueryResponse.data) {
      return (
        <div
          css={css`
            height: 200px;
          `}
        >
          <PageLoadingState />
        </div>
      );
    }

    return (
      <EditRewardStructureForm
        data={rewardStructureQueryResponse.data}
        onSubmit={onSubmit}
      />
    );
  })();

  return (
    <>
      <>
        <Button
          variant="text"
          startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
          label="Back"
          width="auto"
          typographyVariant="body"
          linkTo="/settings/referral-structure"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        />

        <Typography
          css={(theme: AppTheme) => css`
            font-weight: 700;
            padding-left: ${theme.spacing(1)};
            padding-bottom: ${theme.spacing(3)};
          `}
          color="textPrimary"
          variant="h3"
        >
          Edit Referral Structure
        </Typography>
      </>

      {content}
    </>
  );
}
