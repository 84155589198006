import { reportError } from "@rewards-web/shared/modules/error";

import { MyBranchesQuery, useMyBranchesQuery } from "./my-branches.generated";

export function useMyBranches() {
  return useMyBranchesQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first",
  });
}

export type MyBranchesData = MyBranchesQuery;
