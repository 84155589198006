/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

export function DollarIcon() {
  const theme = useTheme();
  return (
    <svg
      width="50"
      height="51"
      viewBox="0 0 50 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3906 24.2852C28.8086 25.2617 33.5938 26.6289 32.6172 31.8047C32.2266 34.5391 29.8828 36.2969 26.5625 36.6875V39.8125C26.5625 40.6914 25.7812 41.375 25 41.375C24.1211 41.375 23.4375 40.6914 23.4375 39.8125V36.5898C23.3398 36.5898 23.2422 36.5898 23.1445 36.5898C21.6797 36.2969 20.1172 35.8086 18.8477 35.3203L18.1641 35.125C17.3828 34.832 16.8945 33.9531 17.1875 33.0742C17.4805 32.293 18.457 31.8047 19.2383 32.0977L19.8242 32.3906C21.0938 32.7812 22.4609 33.2695 23.6328 33.4648C26.0742 33.8555 29.1992 33.5625 29.5898 31.2188C29.9805 29.168 28.7109 28.4844 24.5117 27.3125L23.7305 27.0195C21.0938 26.3359 16.4062 24.8711 17.2852 19.793C17.6758 17.0586 20.0195 15.3008 23.4375 14.9102V11.6875C23.4375 10.9062 24.1211 10.125 25 10.125C25.7812 10.125 26.5625 10.9062 26.5625 11.6875V15.0078C26.5625 15.0078 26.6602 15.0078 26.7578 15.0078C27.7344 15.2031 28.8086 15.4961 30.1758 15.9844C30.957 16.1797 31.4453 17.1562 31.1523 17.9375C30.8594 18.7188 29.9805 19.207 29.1016 18.9141C27.9297 18.5234 27.0508 18.2305 26.2695 18.1328C23.8281 17.7422 20.7031 18.1328 20.3125 20.3789C20.0195 22.2344 20.8984 23.0156 24.6094 24.0898L25.3906 24.2852ZM25 0.75C38.7695 0.75 50 11.9805 50 25.75C50 39.6172 38.7695 50.75 25 50.75C11.1328 50.75 0 39.6172 0 25.75C0 11.9805 11.1328 0.75 25 0.75ZM25 47.625C37.0117 47.625 46.875 37.8594 46.875 25.75C46.875 13.7383 37.0117 3.875 25 3.875C12.8906 3.875 3.125 13.7383 3.125 25.75C3.125 37.8594 12.8906 47.625 25 47.625Z"
        fill={theme.palette.text.primary}
      />
    </svg>
  );
}
