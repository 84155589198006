import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ManagerFieldSearchQueryVariables = Types.Exact<{
  searchQuery?: Types.Maybe<Types.Scalars['String']>;
}>;


export type ManagerFieldSearchQuery = (
  { __typename?: 'Query' }
  & { listRewardsAdmins: (
    { __typename?: 'ListRewardsAdminsResponse' }
    & { items: Array<(
      { __typename?: 'RewardsAdminUser' }
      & Pick<Types.RewardsAdminUser, 'id' | 'email' | 'firstName' | 'lastName' | 'active'>
    )> }
  ) }
);


export const ManagerFieldSearchDocument = gql`
    query ManagerFieldSearch($searchQuery: String) {
  listRewardsAdmins(filter: {searchQuery: $searchQuery}, limit: 10, offset: 0) {
    items {
      id
      email
      firstName
      lastName
      active
    }
  }
}
    `;

/**
 * __useManagerFieldSearchQuery__
 *
 * To run a query within a React component, call `useManagerFieldSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagerFieldSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerFieldSearchQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useManagerFieldSearchQuery(baseOptions?: Apollo.QueryHookOptions<ManagerFieldSearchQuery, ManagerFieldSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManagerFieldSearchQuery, ManagerFieldSearchQueryVariables>(ManagerFieldSearchDocument, options);
      }
export function useManagerFieldSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManagerFieldSearchQuery, ManagerFieldSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManagerFieldSearchQuery, ManagerFieldSearchQueryVariables>(ManagerFieldSearchDocument, options);
        }
export type ManagerFieldSearchQueryHookResult = ReturnType<typeof useManagerFieldSearchQuery>;
export type ManagerFieldSearchLazyQueryHookResult = ReturnType<typeof useManagerFieldSearchLazyQuery>;
export type ManagerFieldSearchQueryResult = Apollo.QueryResult<ManagerFieldSearchQuery, ManagerFieldSearchQueryVariables>;