import {
  ApplicationSubmission,
  Candidate,
  CandidateRecruitmentStepName,
  JobPostingLocationField,
  RecordedCandidateRecruitmentStep,
} from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";

import { CandidateDetailsTableItem } from "./candidate-details-table";

export function getLocationFieldFromSubmission(
  submission: Pick<
    ApplicationSubmission,
    "postalCode" | "zipCode" | "residingCity"
  >
): CandidateDetailsTableItem {
  for (const locationField of Object.values(JobPostingLocationField)) {
    switch (locationField) {
      case JobPostingLocationField.ResidingCity: {
        if (submission.residingCity) {
          return {
            label: "Residing City",
            value: submission.residingCity,
          };
        }

        break;
      }

      case JobPostingLocationField.PostalCode: {
        if (submission.postalCode) {
          return {
            label: "Postal Code",
            value: submission.postalCode,
          };
        }

        break;
      }

      case JobPostingLocationField.ZipCode: {
        if (submission.zipCode) {
          return {
            label: "ZIP Code",
            value: submission.zipCode,
          };
        }

        break;
      }

      default:
        assertNever(locationField);
    }
  }

  return null;
}

export function isHired(
  candidate: Pick<Candidate, "completedStepsV2">
): Boolean {
  if (
    candidate.completedStepsV2.some(
      (step) =>
        step.__typename === "RecordedCandidateMonthlyRetentionStep" ||
        step.__typename === "RecordedCandidateHourlyRetentionStep"
    )
  ) {
    return true;
  }

  const HIRED_STEPS = [
    CandidateRecruitmentStepName.Hired,
    CandidateRecruitmentStepName.StartedOrientation,
    CandidateRecruitmentStepName.CompletedOrientation,
    CandidateRecruitmentStepName.StartedWork,
    CandidateRecruitmentStepName.CompletedFirstShift,
  ];

  return candidate.completedStepsV2
    .filter(
      (step): step is RecordedCandidateRecruitmentStep =>
        step.__typename === "RecordedCandidateRecruitmentStep"
    )
    .some((step) => HIRED_STEPS.includes(step.stepName));
}
