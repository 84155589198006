/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faFolderTree,
  faGift,
  faUser,
  faUsers,
  faFile,
  faInfoCircle,
  faNotes,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { AppTheme } from "@rewards-web/shared/style/types";

import { UploadBulkFilePreviewValues } from ".";

export interface BulkRecognitionFilePreviewModalProps {
  open: boolean;
  isSubmitting: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  previewValues: UploadBulkFilePreviewValues;
  pointsPerDollar: number;
}

export function BulkRecognitionFilePreviewModal({
  open,
  isSubmitting,
  onCancel,
  onSubmit,
  previewValues,
  pointsPerDollar,
}: BulkRecognitionFilePreviewModalProps): JSX.Element {
  const sections = [
    {
      icon: faUser,
      title: "Sender name",
      content: previewValues.from,
    },
    {
      icon: faFolderTree,
      title: "Category",
      content: previewValues.categoryName,
    },
    {
      icon: faUsers,
      title: "No. of Recognitions",
      content: numberWithCommas(previewValues.totalRecognitions),
    },
    {
      icon: faGift,
      title: "Total points",
      content: `${numberWithCommas(
        previewValues.totalPoints
      )} points (${formatDollars(
        previewValues.totalPoints / pointsPerDollar
      )})`,
    },
  ];

  return (
    <Modal width="691px" open={open} onClose={onCancel}>
      <ModalTitle>Confirm the following details</ModalTitle>
      <ModalContent>
        <Card
          variant="outlined"
          css={(theme: AppTheme) => css`
            padding: ${theme.spacing(2)};
            margin-bottom: ${theme.spacing()};
          `}
        >
          {sections
            .filter((s) => s.content)
            .map(({ icon, title, content }) => (
              <div
                key={title}
                css={(theme: AppTheme) => css`
                  display: flex;
                  align-items: center;
                  padding-bottom: 15px;
                  margin-bottom: 15px;
                  border-bottom: 1px solid ${theme.palette.divider};
                  &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                    margin-bottom: 0;
                  }
                `}
              >
                <div
                  css={(theme: AppTheme) => css`
                    flex: 1;
                    align-self: flex-start;
                    display: flex;
                    align-items: center;
                    margin-right: ${theme.spacing(2)};
                  `}
                >
                  <FontAwesomeIcon
                    icon={icon}
                    size="lg"
                    css={(theme: AppTheme) => css`
                      margin-right: ${theme.spacing(2)};
                    `}
                  />
                  <Typography
                    variant="subtitle"
                    color="textPrimary"
                    fontWeight={600}
                  >
                    {title}
                  </Typography>
                </div>
                <Typography variant="body" color="textPrimary">
                  {content}
                </Typography>
              </div>
            ))}
        </Card>
        {previewValues.notes && (
          <Card
            variant="outlined"
            css={(theme: AppTheme) => css`
              padding: ${theme.spacing(2)};
              margin-bottom: ${theme.spacing()};
            `}
          >
            <div
              css={(theme: AppTheme) => css`
                display: flex;
                align-items: center;
                margin-right: ${theme.spacing(2)};
                margin-bottom: 10px;
              `}
            >
              <FontAwesomeIcon
                icon={faNotes}
                size="lg"
                css={(theme: AppTheme) => css`
                  margin-right: ${theme.spacing(2)};
                `}
              />
              <Typography
                variant="subtitle"
                color="textPrimary"
                fontWeight={600}
              >
                Internal note
              </Typography>
            </div>
            <Typography
              variant="body"
              css={css`
                margin-left: 32px;
              `}
            >
              {previewValues.notes}
            </Typography>
          </Card>
        )}
        <Card
          variant="outlined"
          css={(theme: AppTheme) => css`
            padding: ${theme.spacing(2)};
            margin-bottom: ${theme.spacing()};
          `}
        >
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              align-items: center;
              margin-right: ${theme.spacing(2)};
              margin-bottom: 10px;
            `}
          >
            <FontAwesomeIcon
              icon={faFile}
              size="lg"
              css={(theme: AppTheme) => css`
                margin-right: ${theme.spacing(2)};
              `}
            />
            <Typography variant="subtitle" color="textPrimary" fontWeight={600}>
              Uploaded bulk file
            </Typography>
          </div>
          <Typography
            variant="body"
            color="primary"
            fontWeight={500}
            css={css`
              margin-left: 30px;
            `}
          >
            {previewValues.file?.name}
          </Typography>
        </Card>
        <Card
          variant="outlined"
          css={(theme: AppTheme) => css`
            padding: ${theme.spacing(2)};
            margin-bottom: ${theme.spacing()};
          `}
        >
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              align-items: center;
              margin-right: ${theme.spacing(2)};
              margin-bottom: 10px;
            `}
          >
            <FontAwesomeIcon
              icon={faInfoCircle}
              size="lg"
              css={(theme: AppTheme) => css`
                margin-right: ${theme.spacing(2)};
              `}
            />
            <Typography variant="subtitle" color="textPrimary" fontWeight={600}>
              File Processing
            </Typography>
          </div>
          <Typography variant="body" color="textPrimary">
            Processing will take 2-3 business days to complete.
          </Typography>
          <br />
          <Typography variant="body" color="textPrimary">
            You will receive an email once recognition has been delivered to
            employees.
          </Typography>
        </Card>
      </ModalContent>
      <ModalActions>
        <Button
          variant="outlined"
          onClick={onCancel}
          label="Back"
          width="auto"
          size="large"
          disabled={isSubmitting}
        />
        <Button
          onClick={onSubmit}
          loading={isSubmitting}
          color={"primary"}
          label="Send for processing"
          width="auto"
          size="large"
        />
      </ModalActions>
    </Modal>
  );
}
