import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GenerateRedemptionExportMutationVariables = Types.Exact<{
  redemptionIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type GenerateRedemptionExportMutation = (
  { __typename?: 'Mutation' }
  & { generateRewardsRedemptionExport: (
    { __typename?: 'GenerateRedemptionExportResult' }
    & Pick<Types.GenerateRedemptionExportResult, 'id' | 'downloadLink'>
  ) }
);


export const GenerateRedemptionExportDocument = gql`
    mutation GenerateRedemptionExport($redemptionIds: [ID!]!) {
  generateRewardsRedemptionExport(redemptionIds: $redemptionIds) {
    id
    downloadLink
  }
}
    `;
export type GenerateRedemptionExportMutationFn = Apollo.MutationFunction<GenerateRedemptionExportMutation, GenerateRedemptionExportMutationVariables>;

/**
 * __useGenerateRedemptionExportMutation__
 *
 * To run a mutation, you first call `useGenerateRedemptionExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateRedemptionExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateRedemptionExportMutation, { data, loading, error }] = useGenerateRedemptionExportMutation({
 *   variables: {
 *      redemptionIds: // value for 'redemptionIds'
 *   },
 * });
 */
export function useGenerateRedemptionExportMutation(baseOptions?: Apollo.MutationHookOptions<GenerateRedemptionExportMutation, GenerateRedemptionExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateRedemptionExportMutation, GenerateRedemptionExportMutationVariables>(GenerateRedemptionExportDocument, options);
      }
export type GenerateRedemptionExportMutationHookResult = ReturnType<typeof useGenerateRedemptionExportMutation>;
export type GenerateRedemptionExportMutationResult = Apollo.MutationResult<GenerateRedemptionExportMutation>;
export type GenerateRedemptionExportMutationOptions = Apollo.BaseMutationOptions<GenerateRedemptionExportMutation, GenerateRedemptionExportMutationVariables>;