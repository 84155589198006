import { JobPostingScreenerQuestionInput } from "@rewards-web/shared/graphql-types";

import { JobScreenerQuestionValue } from "../typings";
import { serializeJobScreenerQuestions } from "./serialize-screener-question";

export function serializeJobPostingScreenerQuestion(
  screenerQuestions: JobScreenerQuestionValue[]
): JobPostingScreenerQuestionInput[] {
  return [...serializeJobScreenerQuestions(screenerQuestions)];
}
