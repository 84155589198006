import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ScheduleDrawV2MutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  start: Types.Scalars['Timestamp'];
  end: Types.Scalars['Timestamp'];
  prizeStructure: Array<Types.DrawPrizeStructureInput> | Types.DrawPrizeStructureInput;
  announcementVideoId?: Types.Maybe<Types.Scalars['ID']>;
}>;


export type ScheduleDrawV2Mutation = (
  { __typename?: 'Mutation' }
  & { scheduleDrawV2: (
    { __typename?: 'Draw' }
    & Pick<Types.Draw, 'id'>
  ) }
);


export const ScheduleDrawV2Document = gql`
    mutation ScheduleDrawV2($name: String!, $start: Timestamp!, $end: Timestamp!, $prizeStructure: [DrawPrizeStructureInput!]!, $announcementVideoId: ID) {
  scheduleDrawV2(
    name: $name
    start: $start
    end: $end
    prizeStructure: $prizeStructure
    announcementVideoId: $announcementVideoId
  ) {
    id
  }
}
    `;
export type ScheduleDrawV2MutationFn = Apollo.MutationFunction<ScheduleDrawV2Mutation, ScheduleDrawV2MutationVariables>;

/**
 * __useScheduleDrawV2Mutation__
 *
 * To run a mutation, you first call `useScheduleDrawV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleDrawV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleDrawV2Mutation, { data, loading, error }] = useScheduleDrawV2Mutation({
 *   variables: {
 *      name: // value for 'name'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      prizeStructure: // value for 'prizeStructure'
 *      announcementVideoId: // value for 'announcementVideoId'
 *   },
 * });
 */
export function useScheduleDrawV2Mutation(baseOptions?: Apollo.MutationHookOptions<ScheduleDrawV2Mutation, ScheduleDrawV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleDrawV2Mutation, ScheduleDrawV2MutationVariables>(ScheduleDrawV2Document, options);
      }
export type ScheduleDrawV2MutationHookResult = ReturnType<typeof useScheduleDrawV2Mutation>;
export type ScheduleDrawV2MutationResult = Apollo.MutationResult<ScheduleDrawV2Mutation>;
export type ScheduleDrawV2MutationOptions = Apollo.BaseMutationOptions<ScheduleDrawV2Mutation, ScheduleDrawV2MutationVariables>;