import { SVGProps } from "react";

function OptInIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={26}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity={0.5} fill="#4D4D4D">
        <path d="m.09 22.302 4.236-5.648-1.96-1.961a.447.447 0 0 1 .162-.738L16.68 8.74a.447.447 0 0 1 .576.575L12.042 23.47a.447.447 0 0 1-.737.161l-1.961-1.96-5.649 4.236a.45.45 0 0 1-.586-.041l-2.98-2.98a.45.45 0 0 1-.04-.585Zm3.38 2.656 5.648-4.236a.449.449 0 0 1 .587.041l1.74 1.74L16.08 9.918 3.497 14.555l1.74 1.74a.45.45 0 0 1 .042.586L1.042 22.53l2.428 2.428Z" />
        <path d="M20.506 7.98a4.67 4.67 0 0 0-1.007-1.48 4.682 4.682 0 0 0-1.482-1.008.23.23 0 0 1-.05-.026 4.823 4.823 0 0 0-6.467 2.98.443.443 0 0 1-.563.293.448.448 0 0 1-.294-.562 5.595 5.595 0 0 1 1.406-2.313A5.713 5.713 0 0 1 18.36 4.66a.321.321 0 0 1 .052.027 5.59 5.59 0 0 1 1.722 1.18c.503.502.9 1.08 1.176 1.716a.375.375 0 0 1 .03.055 5.711 5.711 0 0 1-1.206 6.31 5.608 5.608 0 0 1-2.313 1.407.447.447 0 0 1-.562-.293.45.45 0 0 1 .294-.563 4.729 4.729 0 0 0 1.947-1.184 4.819 4.819 0 0 0 1.034-5.28.357.357 0 0 1-.028-.053Z" />
        <path d="M15.689 18.927a9.089 9.089 0 0 0 6.791-2.632c3.442-3.445 3.508-9.114.15-12.624l-.293-.294C18.816.01 13.148.074 9.704 3.519a9.034 9.034 0 0 0-2.633 6.792.447.447 0 0 1-.426.468c-.249.028-.459-.179-.469-.426A9.928 9.928 0 0 1 9.07 2.885C12.856-.902 19.087-.97 22.967 2.738l.303.302c3.7 3.87 3.63 10.1-.156 13.888a9.932 9.932 0 0 1-7.467 2.894.448.448 0 0 1-.426-.469c.009-.247.226-.453.468-.426Z" />
      </g>
    </svg>
  );
}

export default OptInIcon;
