/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useForm } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  // JobPostingScreenerQuestion,
  RewardsOrganizationBranch,
} from "@rewards-web/shared/graphql-types";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { FormFieldOutline } from "../../../../../../components/content-container";
import {
  RightDrawerActions,
  RightDrawerContent,
} from "../../../../../../components/right-drawer";
import { RightDrawerBaseCard } from "../../../../../../components/right-drawer/right-drawer-base-card";
import { JobBranchField } from "../../../../fields/job-branch-field";
import { JobCandidateAlertEmailField } from "../../../../fields/job-candidate-alert-email-field";
import { JobCustomEmailField } from "../../../../fields/job-custom-email-field";
import { JobCustomSMSField } from "../../../../fields/job-custom-sms-field";
import { JobDescriptionField } from "../../../../fields/job-description-field";
import { JobHoursRequiredField } from "../../../../fields/job-hours-required-field";
import { JobInterviewBookingLinkField } from "../../../../fields/job-interview-booking-link-field";
import { JobLocationField } from "../../../../fields/job-location-field";
import { JobRateOfPayField } from "../../../../fields/job-rate-of-pay-field";
import { JobScreenerQuestionsField } from "../../../../fields/job-screener-questions";
import { JobTitleField } from "../../../../fields/job-title-field";
import { JobPostingEditDetailsFragment } from "../../../../graphql/job-posting-fragment.generated";
import { getJobPostingDefaultValues } from "../../../../lib/get-job-posting-default-values";
import { JobScreenerQuestionValueWithId } from "../../../../typings";

export interface EditJobFormValues {
  branchId: string | null;
  rateOfPay: string;
  location: string;
  hoursRequired: string;
  jobTitle: string;
  jobDescription: string;
  // screenerQuestions: JobPostingScreenerQuestion[];
  screenerQuestions: JobScreenerQuestionValueWithId[];

  candidateAlertEmail: string;
  customizedEmailMessage: string;
  customizedSMSMessage: string;
  customizedSMSMessageChecked: boolean;
  customizedEmailMessageChecked: boolean;
  interviewBookingLink: string;
  interviewBookingLinkChecked: boolean;
  mustUploadResumeBeforeBookingInterview: "YES" | "NO";
}

interface EditJobFormProps {
  onSubmit(values: EditJobFormValues): Promise<void>;
  onClose(): void;
  jobPosting: JobPostingEditDetailsFragment;
  adminEmailOptions: string[];
  readOnly: boolean;
  saveDescription: string;
  organizationBranches: Pick<RewardsOrganizationBranch, "id" | "name">[];
}

export function EditJobForm({
  onSubmit,
  onClose,
  jobPosting,
  adminEmailOptions,
  readOnly,
  saveDescription,
  organizationBranches,
}: EditJobFormProps) {
  const form = useForm<EditJobFormValues>({
    defaultValues: getJobPostingDefaultValues(jobPosting),
  });
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const organizationHasBranches = organizationBranches.length > 0;

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      submitting={isSubmitting}
      readOnly={readOnly}
      css={css`
        display: contents;
      `}
    >
      <RightDrawerContent>
        <div
          css={(theme: AppTheme) => css`
            padding-top: ${theme.spacing(1.5)};
            padding-left: ${theme.spacing(1.5)};
            padding-right: ${theme.spacing(1.5)};
          `}
        >
          <JobTitleField control={form.control} name="jobTitle" />
          {organizationHasBranches && (
            <JobBranchField control={form.control} name="branchId" />
          )}
          <JobLocationField control={form.control} name="location" />
          <JobRateOfPayField control={form.control} name="rateOfPay" />
          <JobHoursRequiredField control={form.control} name="hoursRequired" />
          <FormFieldOutline
            label="Description"
            name="job-description"
            divider={false}
          >
            <JobDescriptionField control={control} name="jobDescription" />
          </FormFieldOutline>

          <FormFieldOutline
            label="Screening Questions"
            name="screener-questions"
            divider={false}
            description={`Feel free to add your own custom questions. All questions must be formatted as yes-no questions, as answers are not customizable.`}
          >
            <JobScreenerQuestionsField
              control={control}
              name="screenerQuestions"
            />
          </FormFieldOutline>

          <FormFieldOutline
            label="Notification preferences"
            name="alert-preferences"
            divider={false}
            description="Who should receive email alerts about new candidates?"
          >
            <JobCandidateAlertEmailField
              control={control}
              name="candidateAlertEmail"
              emailAutocompleteOptions={adminEmailOptions}
            />
          </FormFieldOutline>

          <FormFieldOutline
            label="Customization"
            name="customization"
            divider={false}
          >
            <JobCustomEmailField
              name="customizedEmailMessage"
              checkBoxName="customizedEmailMessageChecked"
              control={control}
            />
            <JobCustomSMSField
              name="customizedSMSMessage"
              checkBoxName="customizedSMSMessageChecked"
              control={form.control}
            />

            <JobInterviewBookingLinkField
              name="interviewBookingLink"
              checkBoxName="interviewBookingLinkChecked"
              mustUploadResumeFieldName="mustUploadResumeBeforeBookingInterview"
              control={control}
            />
          </FormFieldOutline>
        </div>

        {!readOnly && (
          <RightDrawerBaseCard>
            <Typography variant="body">{saveDescription}</Typography>
          </RightDrawerBaseCard>
        )}
      </RightDrawerContent>

      <RightDrawerActions>
        <Button onClick={onClose} variant="outlined" label="Cancel" />
        <Button type="submit" color="primary" label="Save" />
      </RightDrawerActions>
    </Form>
  );
}
