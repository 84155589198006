import {
  createTheme as createMui4Theme,
  Theme as Mui4Theme,
} from "@material-ui/core";

import { AppThemeProperties } from "./types";

export function useMui4Theme(appTheme: AppThemeProperties): Mui4Theme {
  return createMui4Theme({
    props: {
      MuiUseMediaQuery: {
        noSsr: appTheme.mediaQueryNoSSR,
      },
      MuiButton: {
        size: appTheme.defaultButtonSize,
      },
    },
    palette: appTheme.palette,
    shape: appTheme.shape,
    typography: {
      fontFamily: appTheme.fonts.main,
    },
  });
}
