/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { CardContent } from "@rewards-web/shared/components/card";
import { Divider } from "@rewards-web/shared/components/divider";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme, fontFamily } from "@rewards-web/shared/style/theme";

interface PageCardTitleProps {
  title: string;
}

export function PageCardTitle({ title }: PageCardTitleProps) {
  return (
    <>
      <CardContent
        css={(theme: AppTheme) => css`
          padding-left: ${theme.spacing(3)};
          padding-right: ${theme.spacing(3)};
        `}
      >
        <Typography
          css={css`
            font-weight: 800;
            font-family: ${fontFamily};
          `}
          color="textPrimary"
          variant="h3"
          component="h2"
        >
          {title}
        </Typography>
      </CardContent>
      <Divider />
    </>
  );
}
