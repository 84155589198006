/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { usePrevious } from "@rewards-web/shared/hooks/use-previous";
import { getCharactersRemainingText } from "@rewards-web/shared/lib/characters-remaining-text";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { useCandidateNotesQuery } from "./candidate-notes.generated";

const MAX_NOTES_LENGTH = 1000;

interface ConfirmArchiveFormValues {
  notes: string;
}

interface ConfirmArchiveCandidateModalProps {
  open: boolean;
  onConfirm(updatedNotes: string): void;
  onClose(): void;
  candidateId: string;
  candidateName: string;
}

export function ConfirmArchiveCandidateModal({
  open,
  onClose,
  onConfirm,
  candidateId,
  candidateName,
}: ConfirmArchiveCandidateModalProps): JSX.Element {
  const candidateNotesQuery = useCandidateNotesQuery({
    onError: reportError,
    skip: !open,
    variables: {
      candidateId,
    },
    fetchPolicy: "network-only",
  });

  return (
    <Modal minHeight="400px" width="700px" open={open} onClose={onClose}>
      {candidateNotesQuery.data?.getCandidateById && (
        <ConfirmArchiveCandidateForm
          adminNotes={
            candidateNotesQuery.data.getCandidateById.adminNotes ?? null
          }
          onConfirm={onConfirm}
          onClose={onClose}
          candidateName={candidateName}
        />
      )}
    </Modal>
  );
}

interface ConfirmArchiveCandidateFormProps {
  adminNotes: string | null;
  candidateName: string;
  onConfirm(notes: string): void;
  onClose(): void;
}

function ConfirmArchiveCandidateForm({
  adminNotes,
  onConfirm,
  onClose,
  candidateName,
}: ConfirmArchiveCandidateFormProps) {
  const form = useForm<ConfirmArchiveFormValues>({
    defaultValues: {
      notes: "",
    },
  });

  const notes = useWatch({ control: form.control, name: "notes" });

  const previousAdminNotes = usePrevious(adminNotes);
  useEffect(() => {
    if (adminNotes !== undefined && adminNotes !== previousAdminNotes) {
      form.reset({ notes: adminNotes ?? "" });
    }
  }, [adminNotes, previousAdminNotes, form]);

  return (
    <Form
      onSubmit={form.handleSubmit((values) => {
        onConfirm(values.notes);
        onClose();
      })}
      submitting={form.formState.isSubmitting}
      css={css`
        display: contents; // Modal child components must be direct DOM descendents of Modal
      `}
    >
      <ModalTitle>Archive Candidate?</ModalTitle>
      <ModalContent>
        <Typography
          variant="body"
          color="textPrimary"
          css={(theme: AppTheme) =>
            css`
              padding-bottom: ${theme.spacing(2)};
            `
          }
        >
          For internal tracking, feel free to note why {candidateName} was
          terminated or not successful in their application.
        </Typography>
        <TextField
          type="textarea"
          label="Notes"
          minRows={7}
          helperText={getCharactersRemainingText(notes, MAX_NOTES_LENGTH)}
          error={form.formState.errors.notes}
          {...form.register("notes", {
            validate: (value) => {
              if (value && value.length > MAX_NOTES_LENGTH) {
                return getCharactersRemainingText(value, MAX_NOTES_LENGTH);
              }
            },
          })}
          disableAutocomplete
        />
      </ModalContent>
      <ModalActions>
        <Button
          size="large"
          label="Cancel"
          variant="outlined"
          onClick={() => {
            onClose();
          }}
        />
        <Button size="large" label="Confirm" color="primary" type="submit" />
      </ModalActions>
    </Form>
  );
}
