import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RecognitionListQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  offset: Types.Scalars['Int'];
  filter?: Types.Maybe<Types.ListSentRecognitionPointsFilter>;
}>;


export type RecognitionListQuery = (
  { __typename?: 'Query' }
  & { listSentRecognitionPoints: (
    { __typename?: 'ListSentRecognitionPointsResponse' }
    & Pick<Types.ListSentRecognitionPointsResponse, 'total'>
    & { items: Array<(
      { __typename?: 'RecognitionPoints' }
      & Pick<Types.RecognitionPoints, 'id' | 'from' | 'pointValue' | 'sentAt' | 'claimedAt' | 'mustBeClaimed'>
      & { employee: (
        { __typename?: 'RewardsUser' }
        & Pick<Types.RewardsUser, 'id' | 'firstName' | 'lastName'>
      ), category?: Types.Maybe<(
        { __typename?: 'RecognitionCategory' }
        & Pick<Types.RecognitionCategory, 'id' | 'name'>
      )>, reminders: Array<(
        { __typename?: 'BonusPointsReminder' }
        & Pick<Types.BonusPointsReminder, 'id'>
      )> }
    )> }
  ) }
);


export const RecognitionListDocument = gql`
    query RecognitionList($limit: Int!, $offset: Int!, $filter: ListSentRecognitionPointsFilter) {
  listSentRecognitionPoints(limit: $limit, offset: $offset, filter: $filter) {
    total
    items {
      id
      employee {
        id
        firstName
        lastName
      }
      category {
        id
        name
      }
      from
      pointValue
      sentAt
      claimedAt
      mustBeClaimed
      reminders {
        id
      }
    }
  }
}
    `;

/**
 * __useRecognitionListQuery__
 *
 * To run a query within a React component, call `useRecognitionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecognitionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecognitionListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRecognitionListQuery(baseOptions: Apollo.QueryHookOptions<RecognitionListQuery, RecognitionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecognitionListQuery, RecognitionListQueryVariables>(RecognitionListDocument, options);
      }
export function useRecognitionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecognitionListQuery, RecognitionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecognitionListQuery, RecognitionListQueryVariables>(RecognitionListDocument, options);
        }
export type RecognitionListQueryHookResult = ReturnType<typeof useRecognitionListQuery>;
export type RecognitionListLazyQueryHookResult = ReturnType<typeof useRecognitionListLazyQuery>;
export type RecognitionListQueryResult = Apollo.QueryResult<RecognitionListQuery, RecognitionListQueryVariables>;