const MIN_PASSWORD_LENGTH = 8;

export function validatePassword(password: string) {
  return [
    {
      message: `at least ${MIN_PASSWORD_LENGTH} characters`,
      fulfilled: !!(password.length >= MIN_PASSWORD_LENGTH),
    },
    {
      message: "a special character",
      fulfilled: !!password.match(/[^0-9a-zA-Z]/),
    },
    {
      message: "a lowercase letter",
      fulfilled: !!password.match(/[a-z]/),
    },
    {
      message: "an uppercase letter",
      fulfilled: !!password.match(/[A-Z]/),
    },
    {
      message: "a number",
      fulfilled: !!password.match(/[0-9]/),
    },
  ];
}
