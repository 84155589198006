import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type HhaxCaregiversToSyncQueryVariables = Types.Exact<{
  hhaxIntegrationId: Types.Scalars['ID'];
  limit: Types.Scalars['Int'];
  offset: Types.Scalars['Int'];
  filters: Types.HhaxCaribouUserSyncFiltersInput;
}>;


export type HhaxCaregiversToSyncQuery = (
  { __typename?: 'Query' }
  & { caregiversToSync?: Types.Maybe<(
    { __typename?: 'GetHHAXCaregiversToSyncResponse' }
    & Pick<Types.GetHhaxCaregiversToSyncResponse, 'total'>
    & { items: Array<(
      { __typename?: 'HHAXCaregiverToSync' }
      & Pick<Types.HhaxCaregiverToSync, 'id' | 'firstName' | 'lastName' | 'personalEmail' | 'personalPhone' | 'disciplines' | 'branchId' | 'officeId' | 'teamId' | 'firstWorkedDate'>
    )> }
  )> }
);


export const HhaxCaregiversToSyncDocument = gql`
    query HHAXCaregiversToSync($hhaxIntegrationId: ID!, $limit: Int!, $offset: Int!, $filters: HHAXCaribouUserSyncFiltersInput!) {
  caregiversToSync: getCaregiversToSyncForHHAXIntegration(
    hhaxIntegrationId: $hhaxIntegrationId
    limit: $limit
    offset: $offset
    filters: $filters
  ) {
    items {
      id
      firstName
      lastName
      personalEmail
      personalPhone
      disciplines
      branchId
      officeId
      teamId
      firstWorkedDate
    }
    total
  }
}
    `;

/**
 * __useHhaxCaregiversToSyncQuery__
 *
 * To run a query within a React component, call `useHhaxCaregiversToSyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useHhaxCaregiversToSyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHhaxCaregiversToSyncQuery({
 *   variables: {
 *      hhaxIntegrationId: // value for 'hhaxIntegrationId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useHhaxCaregiversToSyncQuery(baseOptions: Apollo.QueryHookOptions<HhaxCaregiversToSyncQuery, HhaxCaregiversToSyncQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HhaxCaregiversToSyncQuery, HhaxCaregiversToSyncQueryVariables>(HhaxCaregiversToSyncDocument, options);
      }
export function useHhaxCaregiversToSyncLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HhaxCaregiversToSyncQuery, HhaxCaregiversToSyncQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HhaxCaregiversToSyncQuery, HhaxCaregiversToSyncQueryVariables>(HhaxCaregiversToSyncDocument, options);
        }
export type HhaxCaregiversToSyncQueryHookResult = ReturnType<typeof useHhaxCaregiversToSyncQuery>;
export type HhaxCaregiversToSyncLazyQueryHookResult = ReturnType<typeof useHhaxCaregiversToSyncLazyQuery>;
export type HhaxCaregiversToSyncQueryResult = Apollo.QueryResult<HhaxCaregiversToSyncQuery, HhaxCaregiversToSyncQueryVariables>;