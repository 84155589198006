/** @jsxImportSource @emotion/react */
import { ClassNames } from "@emotion/react";
import { Dialog } from "@material-ui/core";
import { ReactNode } from "react";

import { StylableProps } from "../../types";

interface ModalProps extends StylableProps {
  open: boolean;
  onClose?(): void;
  onExited?(): void;
  children: ReactNode;
  width?: string;
  minHeight?: string;
  disableBackdropClick?: boolean;
  disablePadding?: boolean;
  background?: "default" | "white";
}

export function Modal({
  className,
  width,
  open,
  onClose,
  onExited,
  children,
  disableBackdropClick,
  minHeight,
  disablePadding,
  background = "default",
}: ModalProps) {
  return (
    <ClassNames>
      {({ css, theme }) => (
        <Dialog
          classes={{
            paper: css`
              background-color: ${background === "default"
                ? "#f9f9fb"
                : "#ffffff"};
              padding: ${disablePadding ? 0 : theme.spacing(2)};
              width: ${width};
              max-width: initial;
              ${minHeight &&
              `${css`
                min-height: ${minHeight};
              `}`}
            `,
          }}
          className={className}
          open={open}
          maxWidth="xs"
          onClose={(_, reason) => {
            if (disableBackdropClick && reason === "backdropClick") {
              return; // not disabling backdrop click
            }

            onClose?.();
          }}
          onExited={onExited}
        >
          {children}
        </Dialog>
      )}
    </ClassNames>
  );
}
