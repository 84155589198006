import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
export type JobPostingSettingsFragmentFragment = (
  { __typename?: 'RewardsOrganization' }
  & Pick<Types.RewardsOrganization, 'themeJobPostingBannerImageUrl' | 'themeSquareLogoUrl' | 'themePrimaryColor' | 'jobPostingLocationField'>
);

export const JobPostingSettingsFragmentFragmentDoc = gql`
    fragment JobPostingSettingsFragment on RewardsOrganization {
  themeJobPostingBannerImageUrl
  themeSquareLogoUrl
  themePrimaryColor
  jobPostingLocationField
}
    `;