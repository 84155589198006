import * as Types from '@rewards-web/shared/graphql-types';

import { EmailCommunicationSettingsFragmentFragment } from './email-communication-settings-fragment.generated';
import { gql } from '@apollo/client';
import { EmailCommunicationSettingsFragmentFragmentDoc } from './email-communication-settings-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateEmailCommunicationSettingsMutationVariables = Types.Exact<{
  shortName: Types.Scalars['String'];
  emailBannerLogoImage?: Types.Maybe<Types.Scalars['Upload']>;
  timezone: Types.Scalars['String'];
  websiteUrl?: Types.Maybe<Types.Scalars['String']>;
}>;


export type UpdateEmailCommunicationSettingsMutation = (
  { __typename?: 'Mutation' }
  & { partialUpdateMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & EmailCommunicationSettingsFragmentFragment
  ) }
);


export const UpdateEmailCommunicationSettingsDocument = gql`
    mutation UpdateEmailCommunicationSettings($shortName: String!, $emailBannerLogoImage: Upload, $timezone: String!, $websiteUrl: String) {
  partialUpdateMyRewardsOrganization(
    shortName: $shortName
    emailBannerLogoImage: $emailBannerLogoImage
    timezone: $timezone
    websiteUrl: $websiteUrl
  ) {
    ...EmailCommunicationSettingsFragment
  }
}
    ${EmailCommunicationSettingsFragmentFragmentDoc}`;
export type UpdateEmailCommunicationSettingsMutationFn = Apollo.MutationFunction<UpdateEmailCommunicationSettingsMutation, UpdateEmailCommunicationSettingsMutationVariables>;

/**
 * __useUpdateEmailCommunicationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateEmailCommunicationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailCommunicationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailCommunicationSettingsMutation, { data, loading, error }] = useUpdateEmailCommunicationSettingsMutation({
 *   variables: {
 *      shortName: // value for 'shortName'
 *      emailBannerLogoImage: // value for 'emailBannerLogoImage'
 *      timezone: // value for 'timezone'
 *      websiteUrl: // value for 'websiteUrl'
 *   },
 * });
 */
export function useUpdateEmailCommunicationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmailCommunicationSettingsMutation, UpdateEmailCommunicationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmailCommunicationSettingsMutation, UpdateEmailCommunicationSettingsMutationVariables>(UpdateEmailCommunicationSettingsDocument, options);
      }
export type UpdateEmailCommunicationSettingsMutationHookResult = ReturnType<typeof useUpdateEmailCommunicationSettingsMutation>;
export type UpdateEmailCommunicationSettingsMutationResult = Apollo.MutationResult<UpdateEmailCommunicationSettingsMutation>;
export type UpdateEmailCommunicationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateEmailCommunicationSettingsMutation, UpdateEmailCommunicationSettingsMutationVariables>;