import {
  addMonths,
  addQuarters,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  subDays,
  subMonths,
  subQuarters,
} from "date-fns";
import { utcToZonedTime, zonedTimeToUtc, format } from "date-fns-tz";

export type ReportDateRangeMonth =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;
export type ReportDateRangeQuarter = 1 | 2 | 3 | 4;

/**
 * The form value representation of a `ReportDateRangeOption`,
 * which is persisted into the form state.
 */
export type ReportDateRangeFormValue = {
  /**
   * Represents a 'preset' value that the user is able to select.
   */
  preset: ReportDateRangeFormValuePreset | null;

  /**
   * The start of the date range, if the `custom` preset is selected.
   */
  customStart: string | null;

  /**
   * The end of the date range, if the `custom` preset is selected.
   */
  customEnd: string | null;
};

/**
 * A serialized version of a date range preset (to be used in the `SelectField`).
 */
export type ReportDateRangeFormValuePreset =
  | "last_7_days"
  | "last_30_days"
  | "last_90_days"
  | "current_month"
  | "last_month"
  | "current_quarter"
  | "last_quarter"
  | `specific_month:${number}:${ReportDateRangeMonth}`
  | `specific_quarter:${number}:${ReportDateRangeQuarter}`
  | "custom";

/**
 * Returns the date range to use for the given preset, current date, and timezone.
 */
export function reportDateRangePresetToDateRange(
  preset: Exclude<ReportDateRangeFormValuePreset, "custom">,

  now: Date,
  timezone: string
): {
  startUTC: Date;
  endUTC: Date;
} {
  const range = reportDateRangePresetToLocalizedDateRange(
    preset,
    now,
    timezone
  );

  return {
    startUTC: zonedTimeToUtc(range.startInTimezone, timezone),
    endUTC: zonedTimeToUtc(range.endInTimezone, timezone),
  };
}

function reportDateRangePresetToLocalizedDateRange(
  preset: Exclude<ReportDateRangeFormValuePreset, "custom">,
  now: Date,
  timezone: string
): { startInTimezone: Date; endInTimezone: Date } {
  const startOfDayInZone = startOfDay(utcToZonedTime(now, timezone));
  const startOfMonthInZone = startOfMonth(startOfDayInZone);
  const startOfQuarterInZone = startOfQuarter(startOfDayInZone);

  if (preset === "last_7_days") {
    return {
      startInTimezone: subDays(startOfDayInZone, 7),
      endInTimezone: startOfDayInZone,
    };
  } else if (preset === "last_30_days") {
    return {
      startInTimezone: subDays(startOfDayInZone, 30),
      endInTimezone: startOfDayInZone,
    };
  } else if (preset === "last_90_days") {
    return {
      startInTimezone: subDays(startOfDayInZone, 90),
      endInTimezone: startOfDayInZone,
    };
  } else if (preset === "current_month") {
    return {
      startInTimezone: startOfMonthInZone,
      endInTimezone: addMonths(startOfMonthInZone, 1),
    };
  } else if (preset === "last_month") {
    return {
      startInTimezone: subMonths(startOfMonthInZone, 1),
      endInTimezone: startOfMonthInZone,
    };
  } else if (preset === "current_quarter") {
    return {
      startInTimezone: startOfQuarterInZone,
      endInTimezone: addQuarters(startOfQuarterInZone, 1),
    };
  } else if (preset === "last_quarter") {
    return {
      startInTimezone: subQuarters(startOfQuarterInZone, 1),
      endInTimezone: startOfQuarterInZone,
    };
  } else if (preset.startsWith("specific_month")) {
    const parts = preset.split(":");
    const year = Number(parts[1]);
    const month = Number(parts[2]);

    const startLocal = parseISO(
      `${year.toString().padStart(4, "0")}-${month
        .toString()
        .padStart(2, "0")}-01`
    );

    return {
      startInTimezone: startLocal,
      endInTimezone: addMonths(startLocal, 1),
    };
  } else if (preset.startsWith("specific_quarter")) {
    const parts = preset.split(":");
    const year = Number(parts[1]);
    const quarter = Number(parts[2]);

    const startLocal = parseISO(
      `${year.toString().padStart(4, "0")}-${((quarter - 1) * 3 + 1)
        .toString()
        .padStart(2, "0")}-01`
    );

    return {
      startInTimezone: startLocal,
      endInTimezone: addQuarters(startLocal, 1),
    };
  } else {
    throw new Error(`Unexpected preset ${preset}`);
  }
}

/**
 * Given a date range preset option, returns
 * labels which can be provided to `SelectField`
 * with the date properly formatted in the given timezone.
 */
export function getLabelsForDateRangePreset(
  preset: ReportDateRangeFormValuePreset,
  now: Date,
  timezone: string
): {
  label: string;
  subLabel?: string;
} {
  if (preset === "custom") {
    return {
      label: "Custom",
    };
  }

  const presetDateRange = reportDateRangePresetToDateRange(
    preset,
    now,
    timezone
  );

  if (preset === "last_7_days") {
    return {
      label: "Last 7 days",
      subLabel: `(${formatDateRangeInTimezone(presetDateRange, timezone)})`,
    };
  } else if (preset === "last_30_days") {
    return {
      label: "Last 30 days",
      subLabel: `(${formatDateRangeInTimezone(presetDateRange, timezone)})`,
    };
  } else if (preset === "last_90_days") {
    return {
      label: "Last 90 days",
      subLabel: `(${formatDateRangeInTimezone(presetDateRange, timezone)})`,
    };
  } else if (preset === "current_month") {
    return {
      label: "Current month",
      subLabel: `(${formatUtcMonthInTimezone(
        presetDateRange.startUTC,
        timezone
      )})`,
    };
  } else if (preset === "last_month") {
    return {
      label: "Last month",
      subLabel: `(${formatUtcMonthInTimezone(
        presetDateRange.startUTC,
        timezone
      )})`,
    };
  } else if (preset === "current_quarter") {
    return {
      label: "Current quarter",
      subLabel: `(${formatUtcQuarterInTimezone(
        presetDateRange.startUTC,
        timezone
      )})`,
    };
  } else if (preset === "last_quarter") {
    return {
      label: "Last quarter",
      subLabel: `(${formatUtcQuarterInTimezone(
        presetDateRange.startUTC,
        timezone
      )})`,
    };
  } else if (preset.startsWith("specific_month")) {
    return {
      label: formatUtcMonthInTimezone(presetDateRange.startUTC, timezone),
    };
  } else if (preset.startsWith("specific_quarter")) {
    return {
      label: formatUtcQuarterInTimezone(presetDateRange.startUTC, timezone),
    };
  } else {
    throw new Error(`Unexpected preset ${preset}`);
  }
}

function formatUtcMonthInTimezone(utcDate: Date, timezone: string) {
  return formatUtcDateInTimezone(utcDate, timezone, "MMMM yyyy");
}

function formatUtcQuarterInTimezone(utcDate: Date, timezone: string) {
  const startLocal = startOfQuarter(utcToZonedTime(utcDate, timezone));
  const endLocal = addQuarters(startLocal, 1);

  const quarterString = formatUtcDateInTimezone(utcDate, timezone, "QQQ");

  return `${quarterString} ${formatDateRangeInTimezone(
    {
      startUTC: zonedTimeToUtc(startLocal, timezone),
      endUTC: zonedTimeToUtc(endLocal, timezone),
    },
    timezone
  )}`;
}

function formatDateRangeInTimezone(
  range: { startUTC: Date; endUTC: Date },
  timezone: string
) {
  return `${format(utcToZonedTime(range.startUTC, timezone), "MMM d", {
    timeZone: timezone,
  })} -  ${format(
    subDays(
      utcToZonedTime(range.endUTC, timezone),
      1
    ) /* displays last day in range */,
    "MMM d",
    {
      timeZone: timezone,
    }
  )}`;
}

function formatUtcDateInTimezone(
  utcDate: Date,
  timezone: string,
  dateFormat: string
) {
  return format(utcToZonedTime(utcDate, timezone), dateFormat, {
    timeZone: timezone,
  });
}
