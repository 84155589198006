import { PageLayout } from "../../../../../shared/components/page-layout";
import { EnableSyncPageContents } from "./page-contents";

const MAX_CONTENT_WIDTH = 1400;

export function EnableHHAXAutoSyncPage(): JSX.Element {
  return (
    <PageLayout maxContentWidth={MAX_CONTENT_WIDTH}>
      <EnableSyncPageContents />
    </PageLayout>
  );
}
