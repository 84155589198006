/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { FieldError } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Divider } from "@rewards-web/shared/components/divider";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { FileInput } from "../../../../../components/file-input";
import { useUploadBulkUserFileMutation } from "./bulk-employee-upload.generated";

export interface BulkEmployeeUploadModalProps {
  open: boolean;
  onClose(): void;
  onFileUploaded(): void;
}

export function BulkEmployeeUploadModal({
  open,
  onClose,
  onFileUploaded,
}: BulkEmployeeUploadModalProps): JSX.Element {
  const snackbar = useSnackbar();
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [error, setError] = useState<FieldError | undefined>(undefined);
  const [uploadFile, { loading: uploading }] = useUploadBulkUserFileMutation();

  // clear file when modal is closed
  useEffect(() => {
    if (!open) {
      setUploadedFile(null);
    }
  }, [open]);

  const handleFileUpload = async () => {
    if (!uploadedFile) {
      setError({
        type: "required",
        message: "Please select a file before uploading.",
      });
    } else {
      try {
        await uploadFile({
          variables: {
            file: uploadedFile,
          },
        });
        onFileUploaded();
        onClose();
      } catch (error) {
        reportError(error);
        snackbar.show({
          severity: "error",
          message: "An unexpected error occurred. Please try again later.",
        });
      }
    }
  };

  return (
    <Modal width="700px" open={open} onClose={onClose}>
      <ModalTitle>Bulk Upload</ModalTitle>
      <ModalContent>
        <Typography
          css={(theme: AppTheme) => css`
            font-weight: bold;
            margin-top: -${theme.spacing(1)};
          `}
          variant="body"
        >
          It may take up to 48 hours to process bulk uploads
        </Typography>
        <FileInput
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
          isDisabled={uploading}
          error={error}
          setError={setError}
        />
        <Typography variant="body" color="textSecondary">
          Select a CSV or Excel file with your employee’s first name, last name,
          phone numbers, and emails. New employees will be added to Caribou
          Rewards and existing active employees will be updated. If you have any
          questions contact us at{" "}
          <a href={`mailto:${encodeURI("help+admin@caribou.care")}`}>
            help+admin@caribou.care
          </a>
          .
        </Typography>

        <Divider
          css={(theme: AppTheme) =>
            css`
              margin-top: ${theme.spacing(2)};
              margin-bottom: ${theme.spacing(1)};
            `
          }
        />
      </ModalContent>

      <ModalActions>
        <Button
          disabled={uploading}
          variant="outlined"
          onClick={onClose}
          label="Cancel"
        />
        <Button
          loading={uploading}
          onClick={handleFileUpload}
          color="primary"
          label="Upload"
        />
      </ModalActions>
    </Modal>
  );
}
