/** @jsxImportSource @emotion/react */
import { Typography } from "@rewards-web/shared/components/typography";
import { SurveyQuestionType } from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";

import { SurveyQuestionTypeSupportedByUI } from "../constants";

export function QuestionText({
  questionType,
  variant,
}: {
  questionType: SurveyQuestionTypeSupportedByUI;
  variant?: Parameters<typeof Typography>[0]["variant"];
}) {
  const questionText = (() => {
    switch (questionType) {
      case SurveyQuestionType.MeaningfulRecognition:
        return "I receive meaningful recognition for the work I do.";
      case SurveyQuestionType.ManageableWorkload:
        return "I feel I am assigned a manageable workload each week.";
      case SurveyQuestionType.ReliableTransportation:
        return "I have reliable transportation to reach my clients' locations.";
      case SurveyQuestionType.LeadershipSupport:
        return "I'm satisfied with the support I receive from my supervisors.";
      case SurveyQuestionType.Resources:
        return "I have access to the resources I need to succeed in my job.";
      case SurveyQuestionType.Nps:
        return "I would recommend working here to my friends.";
      default:
        assertNever(questionType);
    }
  })();

  return (
    <Typography variant={variant ?? "subtitle"} fontWeight={700}>
      {questionText}
    </Typography>
  );
}
