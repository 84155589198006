/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Card, CardContent } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import * as GraphQLTypes from "@rewards-web/shared/graphql-types";

export function CandidateSummaryCard({
  candidate,
}: {
  candidate: Pick<GraphQLTypes.Candidate, "id" | "firstName" | "lastName"> & {
    assignedToJobPosting?: Pick<
      GraphQLTypes.JobPosting,
      "id" | "title" | "geography"
    > | null;
  };
}) {
  return (
    <Card
      css={css`
        background-color: transparent;
        min-width: 200px;
        flex-grow: 1;
      `}
      variant="outlined"
    >
      <CardContent>
        <Typography
          css={css`
            font-weight: bolder;
            font-size: 1.1em;
          `}
          variant="h2"
        >
          {candidate.firstName} {candidate.lastName}
        </Typography>
        <Typography variant="body">
          {candidate.assignedToJobPosting?.title} (
          {candidate.assignedToJobPosting?.geography})
        </Typography>
      </CardContent>
    </Card>
  );
}
