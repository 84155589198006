/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { SubmitErrorHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router";

import { Alert } from "@rewards-web/shared/components/alert";
import { Form } from "@rewards-web/shared/components/form";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  RewardsOrganizationOnboardingBusinessType,
  RewardsOrganizationOnboardingStepName,
} from "@rewards-web/shared/graphql-types";
import { useNavigationBlockingPrompt } from "@rewards-web/shared/hooks/use-navigation-blocking-prompt";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { SettingsBusinessTypeField } from "../../../../../shared/modules/settings/fields/settings-business-type-field";
import { SettingsEmailBannerLogoImageField } from "../../../../../shared/modules/settings/fields/settings-email-banner-logo-image-field";
import { SettingsLegalBusinessNameField } from "../../../../../shared/modules/settings/fields/settings-legal-business-name-field";
import { SettingsOrganizationTimezoneField } from "../../../../../shared/modules/settings/fields/settings-organization-timezone-field";
import { SettingsShortOrganizationNameField } from "../../../../../shared/modules/settings/fields/settings-short-organization-name-field";
import { AdminOnboardingCard } from "../../components/onboarding-card/onboarding-card";
import { AdminOnboardingCardContent } from "../../components/onboarding-card/onboarding-card-content";
import { AdminOnboardingCardHeader } from "../../components/onboarding-card/onboarding-card-header";
import { AdminOnboardingStepActions } from "../../components/step-actions";
import { useMarkOnboardingStepCompletedMutation } from "../../hooks/mark-step-completed.generated";
import { useNextOnboardingStepPath } from "../../hooks/use-next-step-path";
import { useOnboardingState } from "../../hooks/use-onboarding-state";
import { useOnboardingProfileDataQuery } from "./onboarding-profile-data.generated";
import { useSaveOnboardingProfileDataMutation } from "./save-onboarding-profile-data.generated";

interface OnboardingProfileFormValues {
  shortName: string;
  longName: string;
  businessType: RewardsOrganizationOnboardingBusinessType;
  timezone: string;
  emailBannerImage: File | string | null;
}

const REQUIRED_FIELDS: (keyof OnboardingProfileFormValues)[] = [
  "shortName",
  "longName",
  "timezone",
];

export function OnboardingProfilePage() {
  const onboardingState = useOnboardingState({ loadFreshData: true });

  const profilePreviouslyCompleted = onboardingState.completedSteps.has(
    RewardsOrganizationOnboardingStepName.YourProfile
  );

  const snackbar = useSnackbar();
  const [markStepCompleted] = useMarkOnboardingStepCompletedMutation();
  const navigate = useNavigate();
  const nextOnboardingStepPath = useNextOnboardingStepPath();
  const onboardingProfileData = useOnboardingProfileDataQuery({
    onError: reportError,
  });
  const [saveOnboardingProfileData] = useSaveOnboardingProfileDataMutation();
  const [formInitialized, setFormInitialized] = useState(false);
  const form = useForm<OnboardingProfileFormValues>();

  useNavigationBlockingPrompt(
    "Are you sure you want to leave this page? You will lose all unsaved changes.",
    form.formState.isDirty
  );

  const onSubmit = async (values: OnboardingProfileFormValues) => {
    const hasCompletedForm = REQUIRED_FIELDS.every(
      (valueKey) => values[valueKey]
    );

    try {
      await saveOnboardingProfileData({
        variables: {
          shortName: values.shortName,
          longName: values.longName,
          onboardingBusinessType: values.businessType,
          emailBannerLogoImage:
            values.emailBannerImage instanceof File
              ? values.emailBannerImage
              : undefined,
          timezone: values.timezone,
        },
      });

      if (hasCompletedForm) {
        await markStepCompleted({
          variables: {
            step: RewardsOrganizationOnboardingStepName.YourProfile,
          },
        });

        if (!nextOnboardingStepPath) {
          throw new Error("No next onboarding step path");
        }

        navigate(nextOnboardingStepPath);
      } else {
        snackbar.show({
          severity: "warning",
          message:
            "Your progress has been saved, but there are incomplete fields.",
        });
      }
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error has occurred. Please try again later.",
      });
    }
  };

  const onSubmitWithErrors: SubmitErrorHandler<OnboardingProfileFormValues> = () => {
    snackbar.show({
      severity: "error",
      message:
        "We encountered a minor error. Please review the highlighted fields and try again.",
    });
  };

  useEffect(() => {
    if (onboardingProfileData.data) {
      form.reset({
        shortName:
          onboardingProfileData.data.getMyRewardsOrganization.shortName,
        longName: onboardingProfileData.data.getMyRewardsOrganization.longName,
        businessType:
          onboardingProfileData.data.getMyRewardsOrganization.onboardingState
            .businessType ?? undefined,
        emailBannerImage:
          onboardingProfileData.data.getMyRewardsOrganization
            .emailBannerLogoImageUrl ?? null,
        timezone: onboardingProfileData.data.getMyRewardsOrganization.timezone,
      });
      setFormInitialized(true);
    }
  }, [form, onboardingProfileData.data]);

  const content = (() => {
    if (onboardingProfileData.error || onboardingState.error) {
      return (
        <Alert
          severity="error"
          message="An unexpected error occurred. Please try again later."
        />
      );
    }

    if (!onboardingProfileData.data || onboardingState.loading) {
      return (
        <PageLoadingState
          css={css`
            position: absolute;
          `}
        />
      );
    }

    if (!formInitialized) {
      return null;
    }

    return (
      <>
        <AdminOnboardingCardHeader title="🏣 Business information" />
        <AdminOnboardingCardContent>
          <div
            css={(appTheme: AppTheme) => css`
              display: flex;
              margin-bottom: ${appTheme.spacing(3.75)};
            `}
          >
            <Typography color="textSecondary">
              Let's start with the basics. Your business information is used to
              send communications to caregivers on your behalf.
            </Typography>
          </div>
          <SettingsShortOrganizationNameField
            control={form.control}
            name="shortName"
            isRequired
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(2)};
            `}
          />
          <SettingsLegalBusinessNameField
            control={form.control}
            name="longName"
            isRequired
          />
          <SettingsBusinessTypeField
            control={form.control}
            name="businessType"
          />

          <SettingsOrganizationTimezoneField
            control={form.control}
            name="timezone"
            isRequired
          />
          <SettingsEmailBannerLogoImageField
            control={form.control}
            name="emailBannerImage"
          />
        </AdminOnboardingCardContent>
      </>
    );
  })();

  return (
    <Form
      onSubmit={form.handleSubmit(onSubmit, onSubmitWithErrors)}
      submitting={form.formState.isSubmitting}
    >
      <AdminOnboardingCard
        css={css`
          min-height: 500px;
        `}
      >
        {content}
      </AdminOnboardingCard>
      <AdminOnboardingStepActions
        disableSkip={profilePreviouslyCompleted}
        submitButtonLabel={"Save and Continue"}
      />
    </Form>
  );
}
