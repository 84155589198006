/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { alpha } from "@material-ui/core";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";

import {
  SelectCheckboxesField,
  SelectCheckboxesFieldOption,
} from "@rewards-web/shared/components/select-checkboxes-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useOrganizationSendersQuery } from "../../../pages/authenticated/recognition/get-all-recognition-senders-for-organization.generated";

const noFromOptions = {
  label: "No Sender",
  value: "no-sender",
};

interface FromFilterProps {
  value: (string | null)[];
  onChange(senderIds: string[]): void;
  onOpen?(): void;
  width?: number;
}

export function FromFilter({
  value,
  onChange,
  onOpen,
  width,
}: FromFilterProps) {
  let fromsQuery = useOrganizationSendersQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onError: reportError,
  });
  const listOfSenders =
    fromsQuery?.data?.getAllRecognitionSendersForOrganization;
  const FromOptions = ((): SelectCheckboxesFieldOption[] => {
    const FromOptions: SelectCheckboxesFieldOption[] = [];

    FromOptions.push(
      ...(listOfSenders ?? []).map(
        (from) =>
          ({
            label: from,
            value: from,
          } as SelectCheckboxesFieldOption)
      )
    );

    return FromOptions;
  })();

  const selectedFromIds = value.map(
    (selectedFromId) => selectedFromId ?? noFromOptions.value
  );
  return (
    <SelectCheckboxesField
      css={css`
        max-width: ${width ?? 200}px;
      `}
      label="Sender"
      options={FromOptions}
      onChange={(selectedOptions: string[]) => {
        const options: string[] = [];
        for (const option of selectedOptions) {
          if (option !== noFromOptions.value) {
            options.push(option);
          }
        }
        onChange(options);
      }}
      onOpen={onOpen}
      id="froms"
      value={selectedFromIds || []}
      leftIcon={<FilterListOutlinedIcon fontSize="small" />}
      rightAdornment={
        <Typography
          css={(theme: AppTheme) => css`
            font-weight: 800;
            padding-left: ${theme.spacing(1.5)};
            padding-bottom: 2px;
            height: 14px;
            display: flex;
            align-items: center;
            ${selectedFromIds.length > 0 &&
            css`
              border-left: 1px solid ${alpha("#444444", 0.2)};
            `}
          `}
          color={selectedFromIds.length > 0 ? "primary" : "textPrimary"}
          variant="body"
          align="right"
        >
          {selectedFromIds.length || (listOfSenders && listOfSenders?.length)}
        </Typography>
      }
    />
  );
}
