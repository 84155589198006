import { SVGProps } from "react";

function TotalThreeMonthsRetainedIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={17}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.348 19.797H1.993a1.992 1.992 0 0 1-1.99-1.99V3.452c0-1.096.892-1.99 1.99-1.99h12.355c1.098 0 1.99.894 1.99 1.99v14.357a1.99 1.99 0 0 1-1.99 1.99ZM1.993 2.275c-.65 0-1.177.528-1.177 1.176v14.357c0 .648.528 1.176 1.177 1.176h12.355a1.18 1.18 0 0 0 1.178-1.176V3.45c0-.648-.528-1.176-1.178-1.176H1.993Z"
        fill="#4D4D4D"
      />
      <path
        d="M4.715 3.576a.406.406 0 0 1-.406-.406V.406C4.31.18 4.491 0 4.715 0c.223 0 .408.182.408.405V3.17a.41.41 0 0 1-.408.406ZM11.563 3.576a.407.407 0 0 1-.407-.406V.406a.406.406 0 1 1 .813 0V3.17a.406.406 0 0 1-.406.406ZM8.421 11.234c.755.073 1.331.308 1.727.704.396.389.594.887.594 1.496 0 .44-.11.84-.33 1.199-.22.352-.55.634-.99.847-.433.205-.964.308-1.595.308-.55 0-1.078-.08-1.584-.242-.506-.169-.92-.4-1.243-.693l.506-.869c.264.25.601.451 1.012.605.41.147.847.22 1.309.22.572 0 1.016-.121 1.331-.363.323-.242.484-.576.484-1.001 0-.425-.158-.755-.473-.99-.315-.235-.792-.352-1.43-.352h-.616v-.77l1.936-2.376H5.352V8h5.082v.748l-2.013 2.486ZM15.931 6.056H.407a.407.407 0 0 1 0-.812H15.93c.225 0 .408.182.408.406a.408.408 0 0 1-.408.406Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}

export default TotalThreeMonthsRetainedIcon;
