/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";

interface RightDrawerActionsProps {
  children: ReactNode;
}

export function RightDrawerActions({
  children,
}: RightDrawerActionsProps): JSX.Element {
  return (
    <div
      css={(theme: AppTheme) => css`
        display: flex;
        flex: 0 0 auto;
        padding: ${theme.spacing(1)};
        & > :not(:first-child) {
          margin-left: ${theme.spacing(1)};
        }
      `}
    >
      {children}
    </div>
  );
}
