/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

interface ObscureRecordedTextProps {
  children: ReactNode;
}

/**
 * Obscures the contents from screen recording (hotjar)
 */
export function ObscureRecordedText({ children }: ObscureRecordedTextProps) {
  return (
    <div
      css={css`
        display: contents;
      `}
      data-hj-suppress
    >
      {children}
    </div>
  );
}
