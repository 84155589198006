/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface RecognitionPointsDetailsActivityTableProps {
  items: Array<{
    icon?: ReactNode;
    label: string;
    value: string;
  }>;
}

export function RecognitionPointsDetailsActivityTable({
  items,
}: RecognitionPointsDetailsActivityTableProps) {
  return (
    <table
      css={css`
        width: 100%;
      `}
    >
      <tbody>
        {items.map(({ icon, label, value }) => (
          <tr key={label}>
            <td
              css={css`
                width: 10%;
                text-align: center;
              `}
            >
              {icon ?? <div />}
            </td>
            <td
              css={css`
                width: 30%;
              `}
            >
              <Typography variant="body" align="right" color="textSecondary">
                {label}:
              </Typography>
            </td>
            <td
              css={(theme: AppTheme) => css`
                width: 60%;
                padding-left: ${theme.spacing(1)};
              `}
            >
              <Typography variant="body" align="left">
                {value}
              </Typography>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
