/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { CardActions } from "@rewards-web/shared/components/card";
import { Divider } from "@rewards-web/shared/components/divider";

interface PageCardActionsProps {
  disabled?: boolean;
}

export function PageCardActions({ disabled }: PageCardActionsProps) {
  return (
    <>
      <Divider />
      <CardActions
        css={css`
          display: flex;
          justify-content: flex-end;
        `}
      >
        <Button
          width="auto"
          minWidthPx={130}
          type="submit"
          color="primary"
          label="Save"
          disabled={disabled}
        />
      </CardActions>
    </>
  );
}
