import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateRewardsUserInfoMutationVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
  workEmail?: Types.Maybe<Types.Scalars['String']>;
  workPhoneNumber?: Types.Maybe<Types.Scalars['String']>;
  email?: Types.Maybe<Types.Scalars['String']>;
  phoneNumber?: Types.Maybe<Types.Scalars['String']>;
  branchId?: Types.Maybe<Types.Scalars['ID']>;
  active?: Types.Maybe<Types.Scalars['Boolean']>;
  managerId?: Types.Maybe<Types.Scalars['ID']>;
  referralLinkDisabled?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type UpdateRewardsUserInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateRewardsUserInformation: (
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id'>
  ) }
);


export const UpdateRewardsUserInfoDocument = gql`
    mutation UpdateRewardsUserInfo($userId: ID!, $workEmail: String, $workPhoneNumber: String, $email: String, $phoneNumber: String, $branchId: ID, $active: Boolean, $managerId: ID, $referralLinkDisabled: Boolean) {
  updateRewardsUserInformation(
    userId: $userId
    workEmail: $workEmail
    workPhoneNumber: $workPhoneNumber
    email: $email
    phoneNumber: $phoneNumber
    branchId: $branchId
    active: $active
    managerId: $managerId
    referralLinkDisabled: $referralLinkDisabled
  ) {
    id
  }
}
    `;
export type UpdateRewardsUserInfoMutationFn = Apollo.MutationFunction<UpdateRewardsUserInfoMutation, UpdateRewardsUserInfoMutationVariables>;

/**
 * __useUpdateRewardsUserInfoMutation__
 *
 * To run a mutation, you first call `useUpdateRewardsUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRewardsUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRewardsUserInfoMutation, { data, loading, error }] = useUpdateRewardsUserInfoMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      workEmail: // value for 'workEmail'
 *      workPhoneNumber: // value for 'workPhoneNumber'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      branchId: // value for 'branchId'
 *      active: // value for 'active'
 *      managerId: // value for 'managerId'
 *      referralLinkDisabled: // value for 'referralLinkDisabled'
 *   },
 * });
 */
export function useUpdateRewardsUserInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRewardsUserInfoMutation, UpdateRewardsUserInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRewardsUserInfoMutation, UpdateRewardsUserInfoMutationVariables>(UpdateRewardsUserInfoDocument, options);
      }
export type UpdateRewardsUserInfoMutationHookResult = ReturnType<typeof useUpdateRewardsUserInfoMutation>;
export type UpdateRewardsUserInfoMutationResult = Apollo.MutationResult<UpdateRewardsUserInfoMutation>;
export type UpdateRewardsUserInfoMutationOptions = Apollo.BaseMutationOptions<UpdateRewardsUserInfoMutation, UpdateRewardsUserInfoMutationVariables>;