/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { alpha } from "@material-ui/core";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";

import {
  SelectCheckboxesField,
  SelectCheckboxesFieldOption,
} from "@rewards-web/shared/components/select-checkboxes-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useOrganizationCategoriesQuery } from "../../../pages/authenticated/recognition/get-organization-categories.generated";

const noRecognitionCategoriesOptions = {
  label: "No Category",
  value: "no-category",
};

interface CategoryFilterProps {
  value: (string | null)[];
  onChange(recognitionCategoryIds: (string | null)[]): void;
  onOpen?(): void;
  width?: number;
}

export function CategoryFilter({
  value,
  onChange,
  onOpen,
  width,
}: CategoryFilterProps) {
  const categoriesQuery = useOrganizationCategoriesQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first",
  });

  const recognitionCategories =
    categoriesQuery?.data?.getMyRewardsOrganizationRecognitionCategories;

  const recognitionCategoriesOptions = ((): SelectCheckboxesFieldOption[] => {
    const recognitionCategoriesOptions: SelectCheckboxesFieldOption[] = [];

    recognitionCategoriesOptions.push(
      ...(recognitionCategories ?? []).map(
        (categoryOption) =>
          ({
            label: categoryOption.name,
            value: categoryOption.id,
          } as SelectCheckboxesFieldOption)
      )
    );
    recognitionCategoriesOptions.push(noRecognitionCategoriesOptions);

    return recognitionCategoriesOptions;
  })();

  const selectedCategoryIds = value.map(
    (selectedCategoryId) =>
      selectedCategoryId ?? noRecognitionCategoriesOptions.value
  );
  return (
    <SelectCheckboxesField
      css={css`
        width: ${width ?? 300}px;
      `}
      label="Category"
      options={recognitionCategoriesOptions}
      onChange={(selectedOptions: string[]) => {
        onChange(
          selectedOptions.map((option) =>
            option === noRecognitionCategoriesOptions.value ? null : option
          )
        );
      }}
      onOpen={onOpen}
      id="categories"
      value={selectedCategoryIds || []}
      leftIcon={<FilterListOutlinedIcon fontSize="small" />}
      rightAdornment={
        <Typography
          css={(theme: AppTheme) => css`
            font-weight: 800;
            padding-left: ${theme.spacing(1.5)};
            padding-bottom: 2px;
            height: 14px;
            display: flex;
            align-items: center;
            ${selectedCategoryIds.length > 0 &&
            css`
              border-left: 1px solid ${alpha("#444444", 0.2)};
            `}
          `}
          color={selectedCategoryIds.length > 0 ? "primary" : "textPrimary"}
          variant="body"
          align="right"
        >
          {selectedCategoryIds.length ||
            (recognitionCategories && recognitionCategories.length)}
        </Typography>
      }
    />
  );
}
