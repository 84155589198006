/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useForm } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { RecognitionCategoryDefaultPoints } from "../fields/recognition-category-default-points-field";
import { RecognitionCategoryDisableSendFromManagers } from "../fields/recognition-category-disable-send-from-managers-field";
import { RecognitionCategoryNameField } from "../fields/recognition-category-name-field";
import { OverrideCategoryInfoAlert } from "../override-category-info-alert";
import { useCreateRecognitionCategoryMutation } from "./create-recognition-category.generated";

export interface CreateRecognitionCategoryModalProps {
  open: boolean;
  onClose(): void;
  onCreated(): void;
  pointsPerDollar: number;
  isSuperuser: boolean;
}

interface CreateRecognitionCategoryFormValues {
  name: string;
  defaultPointsEnabled: boolean;
  defaultPoints: string;
  disableSendFromManagers: boolean;
}

export function CreateRecognitionCategoryModal({
  open,
  onClose,
  onCreated,
  pointsPerDollar,
  isSuperuser,
}: CreateRecognitionCategoryModalProps) {
  const track = useTrack();
  const snackbar = useSnackbar();
  const [createRecognitionCategory] = useCreateRecognitionCategoryMutation();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm<CreateRecognitionCategoryFormValues>({
    defaultValues: {
      name: "",
      defaultPointsEnabled: true,
      defaultPoints: "",
      disableSendFromManagers: false,
    },
  });

  const onSubmit = async (values: CreateRecognitionCategoryFormValues) => {
    try {
      const res = await createRecognitionCategory({
        variables: {
          name: values.name,
          defaultPointAmount:
            values.defaultPoints === "" ? null : Number(values.defaultPoints),
          disableSendFromManagers: values.disableSendFromManagers,
        },
      });
      onCreated();
      onClose();
      reset();
      snackbar.show({
        severity: "success",
        message: `Success! Category ${values.name} has been added.`,
      });

      track("Created recognition category", {
        recognitionCategoryId: res.data?.createRecognitionCategory.id,
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  const onCancel = () => {
    onClose();
    reset();
  };

  return (
    <Modal
      open={open}
      onClose={onCancel}
      width="684px"
      background="white"
      disableBackdropClick={isSubmitting}
    >
      <Form
        css={css`
          display: contents; // Modal child components must be direct DOM descendents of Modal
        `}
        onSubmit={handleSubmit(onSubmit)}
        submitting={isSubmitting}
      >
        <ModalTitle>Create recognition category</ModalTitle>
        <ModalContent>
          <Typography
            variant="body"
            color="textPrimary"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            When recognizing employees, approved Admins can specify a category.
          </Typography>
          <RecognitionCategoryNameField
            control={control}
            name="name"
            autoFocus
          />
          <RecognitionCategoryDefaultPoints
            control={control}
            name="defaultPoints"
            enabledSwitchName="defaultPointsEnabled"
            setValue={setValue}
            pointsPerDollar={pointsPerDollar}
          />
          {isSuperuser && (
            <RecognitionCategoryDisableSendFromManagers
              control={control}
              enabledSwitchName="disableSendFromManagers"
            />
          )}
          <OverrideCategoryInfoAlert />
        </ModalContent>
        <ModalActions>
          <Button label="Cancel" variant="outlined" onClick={onCancel} />
          <Button label="Confirm" color="primary" type="submit" />
        </ModalActions>
      </Form>
    </Modal>
  );
}
