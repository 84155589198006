/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ButtonHTMLAttributes } from "react";

/**
 * Thin layer around HTML button to be styled like an anchor tag (with underline).
 */
export function TextButton(
  props: ButtonHTMLAttributes<HTMLButtonElement>
): JSX.Element {
  return (
    <button
      {...props}
      css={css`
        padding: 0;
        background: none;
        border: none;
        font-size: inherit;
        text-align: left;
        color: inherit;
        text-decoration: underline;
        cursor: pointer;
        font-family: inherit;
      `}
      className={props.className}
    />
  );
}
