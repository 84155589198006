/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface ConfirmCompleteCandidateModalProps {
  open: boolean;
  title: string;
  onConfirm(): void;
  onCancel(): void;
  candidateName: string;
  referredByUserName: string;
  finalStep?: boolean;
}

export function ConfirmCompleteCandidateModal({
  open,
  title,
  onConfirm,
  onCancel,
  candidateName,
  referredByUserName,
  finalStep,
}: ConfirmCompleteCandidateModalProps): JSX.Element {
  return (
    <Modal width="500px" open={open} onClose={onCancel}>
      <ModalTitle>{title}</ModalTitle>
      <ModalContent>
        <Typography
          variant="body"
          color="textSecondary"
          css={(theme: AppTheme) =>
            css`
              padding-bottom: ${theme.spacing(2)};
            `
          }
        >
          The referring employee {referredByUserName} will be awarded points
          {finalStep && ` and ${candidateName} will be archived`}. This cannot
          be undone.
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          size="medium"
          label="No"
          variant="outlined"
          onClick={() => {
            onCancel();
          }}
        />
        <Button
          testId="confirm-complete-yes-button"
          size="medium"
          label="Yes"
          color="primary"
          onClick={() => {
            onConfirm();
          }}
        />
      </ModalActions>
    </Modal>
  );
}
