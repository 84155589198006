import { useTheme } from "@emotion/react";
import * as React from "react";

export function UploadIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25 17.125C26.9141 17.125 28.5 18.7109 28.5 20.625V25C28.5 26.9688 26.9141 28.5 25 28.5H4C2.03125 28.5 0.5 26.9688 0.5 25V20.625C0.5 18.7109 2.03125 17.125 4 17.125H11V19.75H4C3.50781 19.75 3.125 20.1875 3.125 20.625V25C3.125 25.4922 3.50781 25.875 4 25.875H25C25.4375 25.875 25.875 25.4922 25.875 25V20.625C25.875 20.1875 25.4375 19.75 25 19.75H18V17.125H25ZM7.9375 10.1797C7.44531 10.7266 6.625 10.7266 6.13281 10.1797C5.58594 9.6875 5.58594 8.86719 6.13281 8.375L13.5703 0.9375C14.0625 0.390625 14.8828 0.390625 15.375 0.9375L22.8125 8.375C23.3594 8.86719 23.3594 9.6875 22.8125 10.1797C22.5938 10.4531 22.2656 10.5625 21.9375 10.5625C21.5547 10.5625 21.2266 10.4531 21.0078 10.1797L15.8125 4.98438V19.75C15.8125 20.5156 15.2109 21.0625 14.5 21.0625C13.7344 21.0625 13.1875 20.5156 13.1875 19.75V4.98438L7.9375 10.1797ZM24.125 22.8125C24.125 23.5781 23.5234 24.125 22.8125 24.125C22.0469 24.125 21.5 23.5781 21.5 22.8125C21.5 22.1016 22.0469 21.5 22.8125 21.5C23.5234 21.5 24.125 22.1016 24.125 22.8125Z"
        fill={theme.palette.primary.main ?? "#1D51D6"}
      />
    </svg>
  );
}
