/** @jsxImportSource @emotion/react */
import { noop } from "lodash";
import { ReactNode, useState, useContext, createContext } from "react";

import { Snackbar, SnackbarSeverity } from "../../components/snackbar";

interface ShowSnackbarFnParams {
  message: string;
  severity?: SnackbarSeverity;
  durationMs?: number;
  position?: "bottom" | "top";
}
type ShowSnackbarFn = (params: ShowSnackbarFnParams) => void;

const SnackbarContext = createContext<{
  show: ShowSnackbarFn;
}>({
  show: noop,
});

export function useSnackbar() {
  return useContext(SnackbarContext);
}

interface SnackbarProviderProps {
  children: ReactNode;
  position?: "bottom" | "top";
  greyscale?: boolean;
  smaller?: boolean;
  adjustedForMobile?: boolean;
}

export function SnackbarProvider({
  children,
  greyscale = false,
  smaller = false,
  adjustedForMobile,
}: SnackbarProviderProps) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [activeSnackbar, setActiveSnackbar] = useState<
    undefined | ShowSnackbarFnParams
  >();

  const showSnackbar: ShowSnackbarFn = (params) => {
    setActiveSnackbar(params);
    setSnackbarOpen(true);
  };

  const handleClose = () => {
    setSnackbarOpen(false);
  };
  return (
    <>
      {activeSnackbar && (
        // the `activeSnackbar` check shouldn't be needed,
        // but there's a bug preventing onExited from working correctly:
        // https://github.com/mui-org/material-ui/issues/28339
        <Snackbar
          open={snackbarOpen}
          position={activeSnackbar.position}
          onClose={handleClose}
          onExited={() => {
            setActiveSnackbar(undefined);
          }}
          greyscale={greyscale}
          smaller={smaller}
          adjustedForMobile={adjustedForMobile}
          message={activeSnackbar.message}
          severity={activeSnackbar.severity}
          durationMs={activeSnackbar.durationMs}
        />
      )}
      <SnackbarContext.Provider
        value={{
          show: showSnackbar,
        }}
      >
        {children}
      </SnackbarContext.Provider>
    </>
  );
}
