/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

export function CircleCheckIcon() {
  const theme = useTheme();
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7773 6.25586C12.0938 5.93945 12.6211 5.93945 12.9375 6.29102C13.2891 6.60742 13.2891 7.13477 12.9375 7.45117L8.4375 11.9512C8.29688 12.127 8.08594 12.1973 7.875 12.1973C7.62891 12.1973 7.41797 12.127 7.24219 11.9512L4.99219 9.70117C4.64062 9.38477 4.64062 8.85742 4.99219 8.50586C5.30859 8.18945 5.83594 8.18945 6.1875 8.50586L7.875 10.1934L11.7773 6.25586ZM9 0.103516C13.957 0.103516 18 4.14648 18 9.10352C18 14.0957 13.957 18.1035 9 18.1035C4.00781 18.1035 0 14.0957 0 9.10352C0 4.14648 4.00781 0.103516 9 0.103516ZM9 16.416C13.0078 16.416 16.3125 13.1465 16.3125 9.10352C16.3125 5.0957 13.0078 1.79102 9 1.79102C4.95703 1.79102 1.6875 5.0957 1.6875 9.10352C1.6875 13.1465 4.95703 16.416 9 16.416Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
}
