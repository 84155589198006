/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { RewardsAdminPermissionsType } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useHasPermissionsQuery } from "../../../../shared/modules/permissions/hooks/use-has-permissions-query";
import { usePermissionsQuery } from "../../../../shared/modules/permissions/hooks/use-permissions-query";
import { useEditAdminRecognitionBudgetsQuery } from "./budgets-enabled.generated";
import { EditAdminForm } from "./edit-admin-form";
import { useRewardsAdminToEditQuery } from "./get-rewards-admin-by-id.generated";
import { ResendAdminInvite } from "./resend-admin-invite";

export function EditAdminPageContents(): JSX.Element {
  const params = useParams();
  const adminId = params.id!;

  const {
    data: permissionsQueryData,
    loading: permissionsQueryLoading,
    error: permissionsQueryError,
  } = usePermissionsQuery();
  const hasFullAccessLevel =
    permissionsQueryData?.getMyRewardsAdminUser.permissions.type ===
    RewardsAdminPermissionsType.FullAccess;

  const {
    data: rewardsAdminToEditQueryData,
    loading: rewardsAdminToEditQueryLoading,
    error: rewardsAdminToEditQueryError,
  } = useRewardsAdminToEditQuery({
    variables: {
      id: adminId,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    skip: !adminId,
    onError: reportError,
  });

  const {
    permissionResults,
    loading: hasPermissionsQueryLoading,
    error: hasPermissionsQueryError,
  } = useHasPermissionsQuery({
    recognitionPoints: "read",
  });

  const {
    data: recognitionBudgetsQueryData,
    loading: recognitionBudgetsQueryLoading,
    error: recognitionBudgetsQueryError,
  } = useEditAdminRecognitionBudgetsQuery({
    skip: permissionResults?.recognitionPoints !== true,
    onError: reportError,
  });

  const recognitionBudgetsEnabled =
    recognitionBudgetsQueryData?.getMyRewardsOrganization
      ?.recognitionBudgetsEnabled;
  const recognitionBudgetsFrequency =
    recognitionBudgetsQueryData?.getMyRewardsOrganization
      ?.automaticRecognitionBudgetConfig.frequency;
  const pointsPerDollar =
    recognitionBudgetsQueryData?.getMyRewardsOrganization?.pointsPerDollar;

  const admin = rewardsAdminToEditQueryData?.getRewardsAdminById;

  if (
    rewardsAdminToEditQueryError ||
    permissionsQueryError ||
    hasPermissionsQueryError ||
    recognitionBudgetsQueryError
  ) {
    return (
      <div
        css={css`
          max-width: 500px;
        `}
      >
        <Alert
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(3)};
          `}
          severity="error"
          message="An unexpected error occurred. Please try again later."
        />
        <Button color="primary" label="Go Back" linkTo="/admins" />
      </div>
    );
  }

  if (
    permissionsQueryLoading ||
    rewardsAdminToEditQueryLoading ||
    hasPermissionsQueryLoading ||
    recognitionBudgetsQueryLoading ||
    !admin
  ) {
    return <PageLoadingState />;
  }

  return (
    <>
      <Button
        variant="text"
        startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        label="Back"
        width="auto"
        typographyVariant="body"
        linkTo="/admins"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      />

      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        `}
      >
        <div>
          <Typography
            variant="h1"
            color="textPrimary"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            {hasFullAccessLevel ? "Edit administrator" : "View administrator"}
          </Typography>

          <Typography variant="body" color="grey.800">
            Admin:{" "}
            <strong
              css={(theme: AppTheme) => css`
                color: ${theme.palette.primary.main};
              `}
            >
              {`${admin.firstName} ${admin.lastName}`}
            </strong>
          </Typography>
        </div>

        <ResendAdminInvite admin={admin} />
      </div>

      <EditAdminForm
        admin={admin}
        hasFullAccessLevel={hasFullAccessLevel}
        recognitionBudgetsEnabled={recognitionBudgetsEnabled}
        pointsPerDollar={pointsPerDollar}
        recognitionBudgetsFrequency={recognitionBudgetsFrequency}
      />
    </>
  );
}
