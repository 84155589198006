import * as Types from '@rewards-web/shared/graphql-types';

import { EmailCommunicationSettingsFragmentFragment } from './email-communication-settings-fragment.generated';
import { gql } from '@apollo/client';
import { EmailCommunicationSettingsFragmentFragmentDoc } from './email-communication-settings-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EmailCommunicationCardDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EmailCommunicationCardDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & EmailCommunicationSettingsFragmentFragment
  ) }
);


export const EmailCommunicationCardDataDocument = gql`
    query EmailCommunicationCardData {
  getMyRewardsOrganization {
    ...EmailCommunicationSettingsFragment
  }
}
    ${EmailCommunicationSettingsFragmentFragmentDoc}`;

/**
 * __useEmailCommunicationCardDataQuery__
 *
 * To run a query within a React component, call `useEmailCommunicationCardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailCommunicationCardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailCommunicationCardDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmailCommunicationCardDataQuery(baseOptions?: Apollo.QueryHookOptions<EmailCommunicationCardDataQuery, EmailCommunicationCardDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailCommunicationCardDataQuery, EmailCommunicationCardDataQueryVariables>(EmailCommunicationCardDataDocument, options);
      }
export function useEmailCommunicationCardDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailCommunicationCardDataQuery, EmailCommunicationCardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailCommunicationCardDataQuery, EmailCommunicationCardDataQueryVariables>(EmailCommunicationCardDataDocument, options);
        }
export type EmailCommunicationCardDataQueryHookResult = ReturnType<typeof useEmailCommunicationCardDataQuery>;
export type EmailCommunicationCardDataLazyQueryHookResult = ReturnType<typeof useEmailCommunicationCardDataLazyQuery>;
export type EmailCommunicationCardDataQueryResult = Apollo.QueryResult<EmailCommunicationCardDataQuery, EmailCommunicationCardDataQueryVariables>;