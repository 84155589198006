import { JobPostingAutoRejectResponse } from "@rewards-web/shared/graphql-types";

export enum JobScreenerQuestionPresets {
  HAS_DRIVERS_LICENSE_AND_ACCESS_TO_CAR = "HAS_DRIVERS_LICENSE_AND_ACCESS_TO_CAR",
}
export enum SCREENER_QUESTION_TYPE {
  hasDriversLicenseAndAccessToCar = "hasDriversLicenseAndAccessToCar",
  yesOrNo = "YES_OR_NO",
}
export type JobScreenerQuestionPopulatedValue =
  | {
      type: "YES_OR_NO";
      question: string;
      autoReject?: [JobPostingAutoRejectResponse];
    }
  | {
      type: JobScreenerQuestionPresets;
      autoReject?: [JobPostingAutoRejectResponse];
    };

export type JobScreenerQuestionValue =
  | {
      type: null; // not set yet
      autoReject?: [JobPostingAutoRejectResponse];
    }
  | JobScreenerQuestionPopulatedValue;

export type JobScreenerQuestionValueWithId = JobScreenerQuestionPopulatedValue & {
  id: string;
};
