/** @jsxImportSource @emotion/react */
import { uniq } from "lodash";
import { useEffect } from "react";

import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalErrorState } from "@rewards-web/shared/components/modal/modal-error-state";
import { ModalLoadingState } from "@rewards-web/shared/components/modal/modal-loading-state";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { useMyBranches } from "../../../../branches/use-my-branches";
import { useCreateJobPostingMutation } from "../../../graphql/create-job-posting.generated";
import { serializeJobPostingScreenerQuestion } from "../../../lib/serialize-job-screener-questions";
import { AddJobFormValues, AddNewJobForm } from "./add-new-job-form";
import { useDefaultCandidateAlertEmailQuery } from "./default-candidate-alert-email.generated";
import { useJobPostingToDuplicateQuery } from "./duplicating-job-posting.generated";

export interface AddNewJobModalProps {
  open: boolean;
  onClose(): void;
  onAdded(): void;
  duplicatingJobPostingId: string | null;
  submitButtonLabel: string;
  successSnackbarMessage: string;
}

export function AddNewJobModal({
  open,
  onClose,
  onAdded,
  duplicatingJobPostingId,
  submitButtonLabel,
  successSnackbarMessage,
}: AddNewJobModalProps): JSX.Element {
  const track = useTrack();
  const snackbar = useSnackbar();
  const duplicateJobPostingQuery = useJobPostingToDuplicateQuery({
    onError: reportError,
    skip: !duplicatingJobPostingId,
    variables: {
      jobPostingId: duplicatingJobPostingId!,
    },
    fetchPolicy: "cache-and-network",
  });
  const defaultCandidateAlertEmailQuery = useDefaultCandidateAlertEmailQuery({
    skip: !open,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onError: reportError,
  });
  const myOrganizationBranchesQuery = useMyBranches();

  useEffect(() => {
    if (open) {
      defaultCandidateAlertEmailQuery.refetch();
    }
  }, [open, defaultCandidateAlertEmailQuery]);

  const [
    createJobPosting,
    { loading: submitting },
  ] = useCreateJobPostingMutation();

  const onSubmit = async (values: AddJobFormValues) => {
    try {
      const response = await createJobPosting({
        variables: {
          title: values.jobTitle,
          branchId: values.branchId,
          hoursRequired: values.hoursRequired,
          ratesOfPay: values.rateOfPay || null,
          geography: values.location,
          descriptionHTML: values.jobDescription,
          candidateAlertEmail: values.candidateAlertEmail,
          screenerQuestions: serializeJobPostingScreenerQuestion(
            values.screenerQuestions
          ),
          wasDuplicatedFromJobPostingId: duplicatingJobPostingId ?? null,
          interviewBookingLink: values.interviewBookingLinkChecked
            ? {
                url: values.interviewBookingLink,
                mustUploadResumeBeforeBookingInterview:
                  values.mustUploadResumeBeforeBookingInterview === "YES",
              }
            : undefined,
          customizedApplicantEmailMessage: values.customizedEmailMessageChecked
            ? values.customizedEmailMessage
            : undefined,
          customizedApplicantSMSMessage: values.customizedSMSMessageChecked
            ? values.customizedSMSMessage
            : undefined,
        },
      });

      onAdded();
      onClose();
      snackbar.show({
        severity: "success",
        message: successSnackbarMessage,
      });
      track("Added job posting", {
        jobPostingId: response.data?.createJobPosting.id,
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  const content = (() => {
    if (
      defaultCandidateAlertEmailQuery.error ||
      duplicateJobPostingQuery.error ||
      myOrganizationBranchesQuery.error
    ) {
      return <ModalErrorState onClose={onClose} />;
    }

    if (
      !defaultCandidateAlertEmailQuery.data ||
      (duplicatingJobPostingId &&
        (duplicateJobPostingQuery.loading || !duplicateJobPostingQuery.data)) ||
      !myOrganizationBranchesQuery.data
    ) {
      return <ModalLoadingState />;
    }

    if (
      duplicatingJobPostingId &&
      !duplicateJobPostingQuery.data?.getJobPostingById
    ) {
      throw new Error("Invariant; no duplicate job posted loaded");
    }

    return (
      <AddNewJobForm
        open={open}
        onClose={onClose}
        onSubmit={onSubmit}
        submitButtonLabel={submitButtonLabel}
        adminEmailOptions={uniq(
          [
            defaultCandidateAlertEmailQuery.data.getMyRewardsOrganization
              .defaultCandidateAlertEmail,
            ...defaultCandidateAlertEmailQuery.data
              .listUniqueCandidateAlertEmails!.emails,
          ].filter((email): email is string => !!email)
        ).sort()}
        jobPosting={duplicateJobPostingQuery.data?.getJobPostingById ?? null}
        myBranches={
          myOrganizationBranchesQuery.data?.getMyRewardsAdminUser?.branches ??
          []
        }
        organizationBranches={
          myOrganizationBranchesQuery.data?.getMyRewardsOrganization.branches ??
          []
        }
      />
    );
  })();

  return (
    <Modal
      width="700px"
      open={open}
      onClose={onClose}
      disableBackdropClick={submitting}
    >
      {content}
    </Modal>
  );
}
