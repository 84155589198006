/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { useDebounce } from "use-debounce";

import { ObscureRecordedText } from "@rewards-web/shared/components/obscure-recorded-text";
import { SearchTextField } from "@rewards-web/shared/components/search-text-field";
import { reportError } from "@rewards-web/shared/modules/error";
import { StylableProps } from "@rewards-web/shared/types";

import { useUserFilterOptionsQuery } from "./user-filter-options.generated";

const SEARCH_DEBOUNCE_MS = 300;

interface UserFilterProps extends StylableProps {
  value: string | null;
  onChange(value: string | null): void;
}

export function UserFilter({ value, onChange, className }: UserFilterProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedUserSearchQuery] = useDebounce(
    searchQuery,
    SEARCH_DEBOUNCE_MS
  );
  const userOptionsQuery = useUserFilterOptionsQuery({
    fetchPolicy: "cache-first",
    onError: reportError,
    skip: !debouncedUserSearchQuery,
    variables: {
      searchQuery: debouncedUserSearchQuery || null,
    },
  });

  return (
    <div className={className}>
      <ObscureRecordedText>
        <SearchTextField
          css={css`
            width: 240px;
          `}
          value={value}
          onChange={onChange}
          hideLabel
          label="Filter by Recipient"
          placeholder="Filter by Recipient"
          size="small"
          hideSpaceForErrorText
          obscureOptionsFromRecordings
          searchIcon
          options={(
            (userOptionsQuery.data ?? userOptionsQuery.previousData)
              ?.searchForRewardsUsers ?? []
          ).map((user) => ({
            value: user.id,
            label: `${user.firstName} ${user.lastName}`,
          }))}
          loadingOptions={userOptionsQuery.loading}
          onInputChange={(text) => setSearchQuery(text)}
          debouncedInputValue={debouncedUserSearchQuery}
          closedWhenInputTextEmpty
        />
      </ObscureRecordedText>
    </div>
  );
}
