/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Accordion as MUIAccordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
} from "@material-ui/core";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { ReactNode } from "react";

import { Divider } from "@rewards-web/shared/components/divider";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface AccordionProps {
  open: boolean;
  onChange: () => void;
  children: ReactNode;
  title: string;
}

export function Accordion({
  open,
  onChange,
  children,
  title,
}: AccordionProps): JSX.Element {
  return (
    <div
      css={(theme: AppTheme) => css`
        padding: 0 ${theme.spacing(2.5)};
      `}
    >
      <MUIAccordion
        onChange={onChange}
        expanded={open}
        css={css`
          background-color: white;
          box-shadow: 0px 0px 0px 0px;
          border-width: 1px;
          border-style: solid;
          border-color: ${alpha("#000", 0.05)};
        `}
      >
        <AccordionSummary>
          <Typography
            variant="h3"
            css={css`
              flex-grow: 1;
              font-size: 1.2em;
              font-weight: bold;
            `}
          >
            {title}
          </Typography>
          {open ? <ArrowDownIcon /> : <ArrowRightIcon />}
        </AccordionSummary>
        <Divider
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(-1)};
          `}
        />
        <AccordionDetails
          css={(theme: AppTheme) => css`
            display: block;
            padding: ${theme.spacing(2)};
          `}
        >
          {children}
        </AccordionDetails>
      </MUIAccordion>
    </div>
  );
}
