import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SendInvitationLinkToEmployeeMutationVariables = Types.Exact<{
  employeeId: Types.Scalars['ID'];
}>;


export type SendInvitationLinkToEmployeeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'sendInvitationLinkToEmployee'>
);


export const SendInvitationLinkToEmployeeDocument = gql`
    mutation SendInvitationLinkToEmployee($employeeId: ID!) {
  sendInvitationLinkToEmployee(employeeId: $employeeId)
}
    `;
export type SendInvitationLinkToEmployeeMutationFn = Apollo.MutationFunction<SendInvitationLinkToEmployeeMutation, SendInvitationLinkToEmployeeMutationVariables>;

/**
 * __useSendInvitationLinkToEmployeeMutation__
 *
 * To run a mutation, you first call `useSendInvitationLinkToEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationLinkToEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitationLinkToEmployeeMutation, { data, loading, error }] = useSendInvitationLinkToEmployeeMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useSendInvitationLinkToEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<SendInvitationLinkToEmployeeMutation, SendInvitationLinkToEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInvitationLinkToEmployeeMutation, SendInvitationLinkToEmployeeMutationVariables>(SendInvitationLinkToEmployeeDocument, options);
      }
export type SendInvitationLinkToEmployeeMutationHookResult = ReturnType<typeof useSendInvitationLinkToEmployeeMutation>;
export type SendInvitationLinkToEmployeeMutationResult = Apollo.MutationResult<SendInvitationLinkToEmployeeMutation>;
export type SendInvitationLinkToEmployeeMutationOptions = Apollo.BaseMutationOptions<SendInvitationLinkToEmployeeMutation, SendInvitationLinkToEmployeeMutationVariables>;