import { Outlet } from "react-router-dom";

import { EmployeesPageTabs } from "./employees-page-tabs";

export function EmployeesPageContents(): JSX.Element {
  return (
    <>
      <EmployeesPageTabs />
      <Outlet />
    </>
  );
}
