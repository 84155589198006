/** @jsxImportSource @emotion/react */
import { ClassNames } from "@emotion/react";
import { Checkbox as MuiCheckbox, FormControlLabel } from "@material-ui/core";

import {
  ControlledFormFieldProps,
  StylableProps,
} from "@rewards-web/shared/types";

import { useFormControlContext } from "../form/form-control";
import { Tooltip } from "../tooltip";
import { Typography } from "../typography";

export interface CheckboxFieldProps
  extends ControlledFormFieldProps,
    StylableProps {
  label?: React.ReactNode;
  display?: "block" | "inline";
  disabled?: boolean;
  fontWeight?: number;
  disabledTooltipTitle?: string;
}

export function CheckboxField({
  display = "inline",
  label,
  value,
  onChange,
  disabled = false,
  fontWeight = 800,
  disabledTooltipTitle,
  className,
}: CheckboxFieldProps) {
  const { submitting } = useFormControlContext();

  return (
    <ClassNames>
      {({ css, cx }) => (
        <div>
          <Tooltip
            disabled={!disabled || !disabledTooltipTitle}
            title={disabledTooltipTitle ?? ""}
            placement="right"
          >
            <FormControlLabel
              classes={{
                label: css`
                  font-weight: ${fontWeight};
                `,
              }}
              className={cx(
                className,
                css`
                  display: ${display === "block" ? "flex" : "inline-flex"};
                `
              )}
              label={<Typography>{label}</Typography>}
              control={
                <MuiCheckbox
                  color="primary"
                  disabled={disabled || submitting}
                  checked={value !== undefined ? !!value : undefined}
                  onChange={
                    onChange ? (_, checked) => onChange(checked) : undefined
                  }
                  className={css`
                    padding: 6px;
                  `}
                />
              }
            />
          </Tooltip>
        </div>
      )}
    </ClassNames>
  );
}
