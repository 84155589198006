/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { alpha } from "@material-ui/core";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import { useEffect, useState } from "react";

import {
  countSelectedOptionChildren,
  SelectCheckboxesField,
} from "@rewards-web/shared/components/select-checkboxes-field";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  useTrack,
  useTrackScreenRecordingEvent,
} from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/theme";

import {
  deserializeEmployeeFilter,
  EmployeeFilterValue,
  getEmployeeFilterCheckboxOptions,
  SerializedEmployeeFilter,
  serializeEmployeeFilter,
} from "./lib";

export interface EmployeeFilterProps {
  onChange(value: SerializedEmployeeFilter): void;
  value: SerializedEmployeeFilter;
  disabled?: boolean;
}

export function EmployeeFilter({
  onChange,
  value,
  disabled,
}: EmployeeFilterProps): JSX.Element {
  const track = useTrack();
  const trackScreenRecordingEvent = useTrackScreenRecordingEvent();

  const [selectedFilterValues, setSelectedFilterValues] = useState<
    Set<EmployeeFilterValue>
  >(() => deserializeEmployeeFilter(value));

  useEffect(() => {
    setSelectedFilterValues((prev) => deserializeEmployeeFilter(value, prev));
  }, [value]);

  const options = getEmployeeFilterCheckboxOptions();
  const numItemsSelected = countSelectedOptionChildren(
    selectedFilterValues,
    options
  );

  return (
    <SelectCheckboxesField
      css={css`
        width: 226px;
      `}
      disabled={disabled}
      id="employee-filter"
      label="Filter by Status"
      leftIcon={<FilterListOutlinedIcon fontSize="small" />}
      options={options}
      value={Array.from(selectedFilterValues)}
      onChange={(value) => {
        const nextValue = new Set(value as EmployeeFilterValue[]);
        setSelectedFilterValues(nextValue);
        onChange(serializeEmployeeFilter(nextValue));
        track("Employees filter updated", {
          checkedFilterOptions: Array.from(nextValue),
        });
        trackScreenRecordingEvent("employees_filter_updated");
      }}
      rightAdornment={
        <Typography
          css={(theme: AppTheme) => css`
            font-weight: 800;
            padding-left: ${theme.spacing(1.5)};
            padding-bottom: 2px;
            height: 14px;
            display: flex;
            align-items: center;
            ${numItemsSelected > 0 &&
            css`
              border-left: 1px solid ${alpha("#444444", 0.2)};
            `}
          `}
          color={numItemsSelected > 0 ? "primary" : "textPrimary"}
          variant="body"
          align="right"
        >
          {numItemsSelected}
        </Typography>
      }
    />
  );
}
