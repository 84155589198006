import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
export type SelectedRedemptionFragment = (
  { __typename?: 'Redemption' }
  & Pick<Types.Redemption, 'id' | 'status'>
);

export const SelectedRedemptionFragmentDoc = gql`
    fragment SelectedRedemption on Redemption {
  id
  status
}
    `;