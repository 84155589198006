import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { Form } from "@rewards-web/shared/components/form";
import { TextField } from "@rewards-web/shared/components/text-field";
import { useNavigationBlockingPrompt } from "@rewards-web/shared/hooks/use-navigation-blocking-prompt";

import { PageCardActions } from "../../../../../../../shared/components/page-card/page-card-actions";
import { PageCardContent } from "../../../../../../../shared/components/page-card/page-card-content";
import { useAdminAppAuthenticatedOrganizationConfigQuery } from "../../../../../admin-app-authenticated-organization-config.generated";
import { EmployeeAccountsCardDataQuery } from "../employee-accounts-card-data.generated";

const MAX_WORK_EMAIL_DOMAIN_LENGTH = 50;

export interface SettingsEmployeeAccountsFormValues {
  workEmailDomain: string;
}

interface SettingsEmployeeAccountsFormProps {
  data: EmployeeAccountsCardDataQuery;
  onSubmit(values: SettingsEmployeeAccountsFormValues): Promise<void>;
}

function getInitialValues(
  data: EmployeeAccountsCardDataQuery
): SettingsEmployeeAccountsFormValues {
  return {
    workEmailDomain: data.getMyRewardsOrganization.workEmailDomain ?? "",
  };
}

export function SettingsEmployeeAccountsForm({
  data,
  onSubmit,
}: SettingsEmployeeAccountsFormProps): JSX.Element {
  const form = useForm<SettingsEmployeeAccountsFormValues>({
    defaultValues: getInitialValues(data),
  });

  const organizationWhiteLabelConfigQuery = useAdminAppAuthenticatedOrganizationConfigQuery();
  const rewardsProgramShortName =
    organizationWhiteLabelConfigQuery.data?.getMyRewardsOrganization
      ?.whiteLabelConfig?.rewardsProgramShortName ?? "Caribou Rewards";

  useEffect(() => {
    // reset form when the data changes (signifying that it's been updated)
    form.reset(getInitialValues(data));
  }, [data, form]);

  useNavigationBlockingPrompt(
    "Are you sure you want to leave this page? You will lose all unsaved changes.",
    form.formState.isDirty
  );

  return (
    <Form
      submitting={form.formState.isSubmitting}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <PageCardContent>
        <TextField
          startAdornment="@"
          label="Expected work email domain (optional)"
          error={form.formState.errors.workEmailDomain}
          helperText={`All employee emails must be from this domain in order to gain access to the ${rewardsProgramShortName} app.`}
          placeholder="my-company.com"
          disableAutocomplete
          {...form.register("workEmailDomain", {
            pattern: {
              value: /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/,
              message: "Domain must be formated e.g. like 'example.com'",
            },
            maxLength: {
              value: MAX_WORK_EMAIL_DOMAIN_LENGTH,
              message: `The work email domain cannot exceed ${MAX_WORK_EMAIL_DOMAIN_LENGTH} characters in length`,
            },
          })}
        />
      </PageCardContent>
      <PageCardActions disabled={!form.formState.isDirty} />
    </Form>
  );
}
