import {
  Control,
  FieldValues,
  Path,
  useFormState,
  get,
  useWatch,
} from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";
import { isEmail } from "@rewards-web/shared/lib/is-email";

interface EmployeePersonalEmailFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  parsonalPhoneNumberName: Path<T>;
  personalEmailName: Path<T>;
  usesWorkDevices: boolean;
}

export function EmployeePersonalEmailField<T extends FieldValues>({
  control,
  name,
  personalEmailName,
  parsonalPhoneNumberName,
  usesWorkDevices,
}: EmployeePersonalEmailFieldProps<T>) {
  const formState = useFormState({ control, name });

  const personalPhoneNumber = useWatch({
    control,
    name: parsonalPhoneNumberName,
  });
  const personalEmail = useWatch({ control, name: personalEmailName });

  return (
    <TextField
      type="email"
      label={`Personal email${usesWorkDevices ? " (optional)" : ""}`}
      error={get(formState.errors, name)}
      disableAutocomplete
      {...control.register(name, {
        required: !usesWorkDevices ? `Email is required` : undefined,
        validate: (value: string) => {
          if (value && !isEmail(value)) {
            return "This is not a valid email";
          }
          if (!personalEmail && personalPhoneNumber) {
            return "Email is required if phone number is provided";
          }
        },
      })}
    />
  );
}
