import { Alert } from "@rewards-web/shared/components/alert";
import { TextButton } from "@rewards-web/shared/components/text-button";
import { ListCandidatesV2FilterInput } from "@rewards-web/shared/graphql-types";

import { allCandidatesShowingExceptArchived, OrganizationSteps } from "../lib";

interface CandidateListEmptyStateProps {
  totalCandidatesIgnoringFilters: number | undefined;
  organizationSteps: OrganizationSteps;
  setFiltersToDefault(): void;
  showAllCandidates(): void;
  selectedFilters: ListCandidatesV2FilterInput;
  openAddCandidateModal(): void;
  readOnly: boolean;
}

export function CandidateListEmptyState({
  totalCandidatesIgnoringFilters,
  organizationSteps,
  setFiltersToDefault,
  showAllCandidates,
  selectedFilters,
  openAddCandidateModal,
  readOnly,
}: CandidateListEmptyStateProps) {
  if (totalCandidatesIgnoringFilters === 0) {
    return (
      <Alert
        severity="info"
        message={
          <>
            There are no candidates yet.
            {!readOnly && (
              <>
                {" "}
                Check back when someone has applied, or{" "}
                <TextButton
                  type="button"
                  onClick={() => openAddCandidateModal()}
                >
                  create a candidate manually.
                </TextButton>
              </>
            )}
          </>
        }
      />
    );
  }

  if (allCandidatesShowingExceptArchived(organizationSteps, selectedFilters)) {
    return (
      <Alert
        severity="info"
        message={
          <>
            There are no active candidates.{" "}
            <TextButton type="button" onClick={() => showAllCandidates()}>
              Click to view archived candidates.
            </TextButton>
          </>
        }
      />
    );
  }

  return (
    <Alert
      severity="info"
      message={
        <>
          No candidates match the given filters.{" "}
          <TextButton type="button" onClick={() => setFiltersToDefault()}>
            Click to show all active candidates.
          </TextButton>
        </>
      }
    />
  );
}
