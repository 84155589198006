import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateRedemptionThresholdMutationVariables = Types.Exact<{
  minimumPointsNeededToRedeem?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type UpdateRedemptionThresholdMutation = (
  { __typename?: 'Mutation' }
  & { partialUpdateMyRewardsOrganizationRedemptionSettings: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'minimumPointsNeededToRedeem'>
  ) }
);


export const UpdateRedemptionThresholdDocument = gql`
    mutation UpdateRedemptionThreshold($minimumPointsNeededToRedeem: Int) {
  partialUpdateMyRewardsOrganizationRedemptionSettings(
    minimumPointsNeededToRedeem: $minimumPointsNeededToRedeem
  ) {
    id
    minimumPointsNeededToRedeem
  }
}
    `;
export type UpdateRedemptionThresholdMutationFn = Apollo.MutationFunction<UpdateRedemptionThresholdMutation, UpdateRedemptionThresholdMutationVariables>;

/**
 * __useUpdateRedemptionThresholdMutation__
 *
 * To run a mutation, you first call `useUpdateRedemptionThresholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRedemptionThresholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRedemptionThresholdMutation, { data, loading, error }] = useUpdateRedemptionThresholdMutation({
 *   variables: {
 *      minimumPointsNeededToRedeem: // value for 'minimumPointsNeededToRedeem'
 *   },
 * });
 */
export function useUpdateRedemptionThresholdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRedemptionThresholdMutation, UpdateRedemptionThresholdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRedemptionThresholdMutation, UpdateRedemptionThresholdMutationVariables>(UpdateRedemptionThresholdDocument, options);
      }
export type UpdateRedemptionThresholdMutationHookResult = ReturnType<typeof useUpdateRedemptionThresholdMutation>;
export type UpdateRedemptionThresholdMutationResult = Apollo.MutationResult<UpdateRedemptionThresholdMutation>;
export type UpdateRedemptionThresholdMutationOptions = Apollo.BaseMutationOptions<UpdateRedemptionThresholdMutation, UpdateRedemptionThresholdMutationVariables>;