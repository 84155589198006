import { ApolloError } from "@apollo/client";

import { AdminPermissionType } from "../../types";
import { hasPermission } from "../lib";
import { usePermissionsQuery } from "../use-permissions-query";

export function useHasPermissionsQuery<
  T extends Partial<
    {
      [key in AdminPermissionType]: "read";
    }
  >
>(
  permissionRequests: T
): {
  permissionResults: { [key in keyof T]: boolean } | undefined;
  loading?: boolean;
  error?: ApolloError;
} {
  const query = usePermissionsQuery();

  const permissionResults = (() => {
    if (!query.data) {
      return undefined;
    }

    const {
      getMyRewardsAdminUser: { permissions },
    } = query.data;

    return Object.entries(permissionRequests).reduce<
      { [key in keyof T]: boolean }
    >(
      (prev, [resource, level]) => ({
        ...prev,
        [resource]: hasPermission(permissions, level, resource as any),
      }),
      {} as { [key in keyof T]: boolean }
    );
  })();

  return {
    ...query,
    permissionResults,
  };
}
