/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  RewardsAdminAuthStatus,
  RewardsAdminUser,
} from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { useRewardsAdminAuthStatusQuery } from "./get-rewards-admin-auth-status.generated";
import { useResendAdminInviteMutation } from "./resend-admin-invite.generated";

interface ResendAdminInviteProps {
  admin: Pick<RewardsAdminUser, "id" | "firstName">;
}
export function ResendAdminInvite({ admin }: ResendAdminInviteProps) {
  const { data, error } = useRewardsAdminAuthStatusQuery({
    variables: { adminId: admin.id },
    onError: (e) => {
      reportError(e);
    },
  });

  const [
    resendAdminInvite,
    { loading: isSending },
  ] = useResendAdminInviteMutation({
    variables: { adminId: admin.id },
    onError: (e) => {
      reportError(e);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    },
    onCompleted: () => {
      snackbar.show({
        severity: "success",
        message: "The admin's invite has been sent",
      });
    },
  });

  const snackbar = useSnackbar();

  const onSubmit = async () => {
    await resendAdminInvite({
      variables: { adminId: admin.id },
    });
  };

  if (error) {
    return (
      <Alert
        severity="warning"
        message="The user's auth status could not be determined"
      ></Alert>
    );
  }

  if (
    data?.getRewardsAdminAuthStatus.status ===
    RewardsAdminAuthStatus.InitialPasswordRequiredByUser
  ) {
    return (
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #b1b3b3;
          border-radius: 10px;
          padding: ${theme.spacing(2)};
          margin-right: ${theme.spacing(8)};
        `}
      >
        <Typography variant="body" color="grey.800" width={225}>
          {admin.firstName} has not logged in yet. Re-send their invite through
          email
        </Typography>
        <div>
          <Button
            label="Re-send invite"
            variant="outlined"
            loading={isSending}
            onClick={onSubmit}
          />
        </div>
      </div>
    );
  }
  return null;
}
