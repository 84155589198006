/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Radio, Checkbox, ListItemText } from "@material-ui/core";

import { AppTheme } from "@rewards-web/shared/style/theme";

interface SelectCheckboxesItemContentsProps {
  type: "checkboxes" | "radio";
  checked: boolean;
  label: string;
  subLabel: string | undefined;
}

export function SelectCheckboxesItemContents({
  type,
  checked,
  label,
  subLabel,
}: SelectCheckboxesItemContentsProps): JSX.Element {
  return (
    <>
      {type === "radio" ? (
        <Radio
          size="small"
          color="primary"
          checked={checked}
          css={css`
            padding: 5px;
          `}
        />
      ) : (
        <Checkbox
          size="small"
          color="primary"
          checked={checked}
          css={css`
            padding: 5px;
          `}
        />
      )}
      <ListItemText
        primary={label}
        secondary={subLabel}
        secondaryTypographyProps={{
          title: subLabel,
          className: "secondary-typography",
        }}
        css={(theme: AppTheme) => css`
          span {
            font-size: 0.9rem;
            white-space: break-spaces;
            line-height: 1.2em;
          }

          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          padding-right: ${theme.spacing(1)};

          & .secondary-typography {
            font-size: 0.8rem;
            padding-left: ${theme.spacing(2)};
            max-width: 160px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        `}
      />
    </>
  );
}
