import Papa from "papaparse";

export const parseCsv = (rawFile: File): Promise<any[]> => {
  return new Promise<any[]>((resolve) => {
    Papa.parse(rawFile, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        resolve(results.data);
      },
    });
  });
};
