/** @jsxImportSource @emotion/react */
import { ClassNames } from "@emotion/react";
import { darken } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { isValid } from "date-fns";
import { forwardRef } from "react";

import {
  ControlledFormFieldProps,
  StylableProps,
} from "@rewards-web/shared/types";

import { TimeFieldProvider } from "./provider";

export interface TimeFieldProps
  extends StylableProps,
    ControlledFormFieldProps {
  label: string;
}

export const TimeField = forwardRef(
  (
    { className, label, name, error, value, onChange, onBlur }: TimeFieldProps,
    ref: any
  ): JSX.Element => {
    return (
      <TimeFieldProvider>
        <ClassNames>
          {({ css, theme }) => (
            <TimePicker
              className={className}
              slotProps={{
                textField: {
                  name,
                  error: Boolean(error),
                  // ensure helper text stays rendered so height doesn't jump around:
                  helperText: error?.message || " ",
                  className: css`
                    &:hover:not(.Mui-focused):not(.Mui-error)
                      .MuiOutlinedInput-notchedOutline {
                      border-color: ${darken(theme.palette.divider, 0.15)};
                    }
                    &.Mui-focused .MuiOutlinedInput-notchedOutline {
                      border-width: 1px;
                    }

                    & .MuiOutlinedInput-notchedOutline {
                      border-width: 1px;
                      border-radius: ${theme.spacing(1)};
                      border-color: ${theme.palette.divider};
                    }
                  `,
                },
              }}
              label={label}
              value={(() => {
                if (!value) {
                  return null;
                }

                const parts = value.split(":");

                const date = new Date();
                date.setHours(parts[0]);
                date.setMinutes(parts[1]);
                return date;
              })()}
              onChange={(date: Date | null) => {
                if (isValid(date)) {
                  onChange?.(
                    date
                      ? `${date
                          .getHours()
                          .toString()
                          .padStart(
                            2,
                            "0"
                          )}:${date.getMinutes().toString().padStart(2, "0")}`
                      : null
                  );
                } else {
                  onChange?.(null);
                }
              }}
            />
          )}
        </ClassNames>
      </TimeFieldProvider>
    );
  }
);
