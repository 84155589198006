import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RewardsStructureQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RewardsStructureQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'pointsPerDollar' | 'maxPointsEarnedPerCandidate'>
    & { referralRewardStructure: (
      { __typename?: 'ReferralRewardStructure' }
      & { items: Array<(
        { __typename?: 'ReferralRewardStructureJobShareItem' }
        & Pick<Types.ReferralRewardStructureJobShareItem, 'maxAwardableJobSharesPerMonth'>
        & { reward: (
          { __typename?: 'ReferralRewardStructurePointReward' }
          & Pick<Types.ReferralRewardStructurePointReward, 'pointValue'>
        ) | (
          { __typename?: 'ReferralRewardStructureDrawTicketReward' }
          & Pick<Types.ReferralRewardStructureDrawTicketReward, 'numTickets'>
        ) | { __typename?: 'ReferralRewardStructureManualMonthlyRaffleReward' } }
      ) | (
        { __typename?: 'ReferralRewardStructureReferralRecruitmentItem' }
        & Pick<Types.ReferralRewardStructureReferralRecruitmentItem, 'step'>
        & { reward: (
          { __typename?: 'ReferralRewardStructurePointReward' }
          & Pick<Types.ReferralRewardStructurePointReward, 'pointValue'>
        ) | (
          { __typename?: 'ReferralRewardStructureDrawTicketReward' }
          & Pick<Types.ReferralRewardStructureDrawTicketReward, 'numTickets'>
        ) | { __typename?: 'ReferralRewardStructureManualMonthlyRaffleReward' } }
      ) | (
        { __typename?: 'ReferralRewardStructureReferralRetentionItem' }
        & { duration: (
          { __typename?: 'ReferralRewardStructureReferralRetentionItemDuration' }
          & Pick<Types.ReferralRewardStructureReferralRetentionItemDuration, 'unit' | 'amount'>
        ), reward: (
          { __typename?: 'ReferralRewardStructurePointReward' }
          & Pick<Types.ReferralRewardStructurePointReward, 'pointValue'>
        ) | (
          { __typename?: 'ReferralRewardStructureDrawTicketReward' }
          & Pick<Types.ReferralRewardStructureDrawTicketReward, 'numTickets'>
        ) | { __typename?: 'ReferralRewardStructureManualMonthlyRaffleReward' } }
      )> }
    ) }
  ) }
);


export const RewardsStructureDocument = gql`
    query RewardsStructure {
  getMyRewardsOrganization {
    id
    pointsPerDollar
    maxPointsEarnedPerCandidate
    referralRewardStructure {
      items {
        ... on ReferralRewardStructureReferralRecruitmentItem {
          step
          reward {
            ... on ReferralRewardStructurePointReward {
              pointValue
            }
            ... on ReferralRewardStructureDrawTicketReward {
              numTickets
            }
          }
        }
        ... on ReferralRewardStructureReferralRetentionItem {
          duration {
            unit
            amount
          }
          reward {
            ... on ReferralRewardStructurePointReward {
              pointValue
            }
            ... on ReferralRewardStructureDrawTicketReward {
              numTickets
            }
          }
        }
        ... on ReferralRewardStructureJobShareItem {
          maxAwardableJobSharesPerMonth
          reward {
            ... on ReferralRewardStructurePointReward {
              pointValue
            }
            ... on ReferralRewardStructureDrawTicketReward {
              numTickets
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useRewardsStructureQuery__
 *
 * To run a query within a React component, call `useRewardsStructureQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsStructureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsStructureQuery({
 *   variables: {
 *   },
 * });
 */
export function useRewardsStructureQuery(baseOptions?: Apollo.QueryHookOptions<RewardsStructureQuery, RewardsStructureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RewardsStructureQuery, RewardsStructureQueryVariables>(RewardsStructureDocument, options);
      }
export function useRewardsStructureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RewardsStructureQuery, RewardsStructureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RewardsStructureQuery, RewardsStructureQueryVariables>(RewardsStructureDocument, options);
        }
export type RewardsStructureQueryHookResult = ReturnType<typeof useRewardsStructureQuery>;
export type RewardsStructureLazyQueryHookResult = ReturnType<typeof useRewardsStructureLazyQuery>;
export type RewardsStructureQueryResult = Apollo.QueryResult<RewardsStructureQuery, RewardsStructureQueryVariables>;