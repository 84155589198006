import { css } from "@emotion/react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface ModalErrorStateProps {
  onClose(): void;
  message?: string;
}

export function ModalErrorState({
  message,
  onClose,
}: ModalErrorStateProps): JSX.Element {
  return (
    <>
      <ModalContent>
        <Alert
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(3)};
          `}
          severity="error"
          message={
            message || "An unexpected error occurred. Please try again later."
          }
        />
      </ModalContent>
      <ModalActions>
        <Button color="primary" label="OK" onClick={onClose} />
      </ModalActions>
    </>
  );
}
