import * as Types from '@rewards-web/shared/graphql-types';

import { CandidateListDetailsFragment } from '../../candidate-fragment.generated';
import { gql } from '@apollo/client';
import { CandidateListDetailsFragmentDoc } from '../../candidate-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ReopenCandidateMutationVariables = Types.Exact<{
  candidateId: Types.Scalars['ID'];
}>;


export type ReopenCandidateMutation = (
  { __typename?: 'Mutation' }
  & { reopenCandidate: (
    { __typename?: 'Candidate' }
    & CandidateListDetailsFragment
  ) }
);


export const ReopenCandidateDocument = gql`
    mutation ReopenCandidate($candidateId: ID!) {
  reopenCandidate(candidateId: $candidateId) {
    ...CandidateListDetails
  }
}
    ${CandidateListDetailsFragmentDoc}`;
export type ReopenCandidateMutationFn = Apollo.MutationFunction<ReopenCandidateMutation, ReopenCandidateMutationVariables>;

/**
 * __useReopenCandidateMutation__
 *
 * To run a mutation, you first call `useReopenCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenCandidateMutation, { data, loading, error }] = useReopenCandidateMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useReopenCandidateMutation(baseOptions?: Apollo.MutationHookOptions<ReopenCandidateMutation, ReopenCandidateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReopenCandidateMutation, ReopenCandidateMutationVariables>(ReopenCandidateDocument, options);
      }
export type ReopenCandidateMutationHookResult = ReturnType<typeof useReopenCandidateMutation>;
export type ReopenCandidateMutationResult = Apollo.MutationResult<ReopenCandidateMutation>;
export type ReopenCandidateMutationOptions = Apollo.BaseMutationOptions<ReopenCandidateMutation, ReopenCandidateMutationVariables>;