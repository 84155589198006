import { ApolloError } from "@apollo/client";

import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import {
  AddEditEmployeeAdminConflictError,
  AddEditEmployeeConflictError,
  AddEditEmployeeInvalidWorkDomainError,
  UserCannotAssignManagerAsThemselvesError,
} from "../../errors/errors";

export function useEmployeeErrorHandler(): (error: unknown) => void {
  const snackbar = useSnackbar();

  const handleEmployeeError = (error: unknown) => {
    if (
      error instanceof ApolloError &&
      error.graphQLErrors[0]?.message ===
        "An existing user exists with the given contact information"
    ) {
      throw new AddEditEmployeeConflictError(
        error.graphQLErrors[0]!.extensions!.fieldsAndUsers
      );
    } else if (
      error instanceof ApolloError &&
      error.graphQLErrors[0]?.message ===
        "An existing admin exists with the given contact information"
    ) {
      throw new AddEditEmployeeAdminConflictError(
        error.graphQLErrors[0]!.extensions!.fields
      );
    } else if (
      error instanceof ApolloError &&
      error.graphQLErrors[0]?.message ===
        "The provided work email does not match the organization's work email domain"
    ) {
      throw new AddEditEmployeeInvalidWorkDomainError(
        error.graphQLErrors[0]!.extensions!.requiredDomain
      );
    } else if (
      error instanceof ApolloError &&
      error.graphQLErrors[0]?.message ===
        "User cannot be assigned to themselves as a manager"
    ) {
      throw new UserCannotAssignManagerAsThemselvesError();
    } else {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  return handleEmployeeError;
}
