import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type OrganizationInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrganizationInfoQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'usesWorkDevices' | 'integratedWithAlayaCare' | 'managersEnabled' | 'referralsEnabled' | 'bulkUserUploadIsProcessing'>
    & { branches: Array<(
      { __typename?: 'RewardsOrganizationBranch' }
      & Pick<Types.RewardsOrganizationBranch, 'id' | 'name'>
    )>, whiteLabelConfig?: Types.Maybe<(
      { __typename?: 'RewardsOrganizationWhiteLabelConfig' }
      & Pick<Types.RewardsOrganizationWhiteLabelConfig, 'id' | 'rewardsProgramShortName'>
    )> }
  ) }
);


export const OrganizationInfoDocument = gql`
    query OrganizationInfo {
  getMyRewardsOrganization {
    id
    usesWorkDevices
    integratedWithAlayaCare
    managersEnabled
    referralsEnabled
    bulkUserUploadIsProcessing
    branches {
      id
      name
    }
    whiteLabelConfig {
      id
      rewardsProgramShortName
    }
  }
}
    `;

/**
 * __useOrganizationInfoQuery__
 *
 * To run a query within a React component, call `useOrganizationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationInfoQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationInfoQuery, OrganizationInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationInfoQuery, OrganizationInfoQueryVariables>(OrganizationInfoDocument, options);
      }
export function useOrganizationInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationInfoQuery, OrganizationInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationInfoQuery, OrganizationInfoQueryVariables>(OrganizationInfoDocument, options);
        }
export type OrganizationInfoQueryHookResult = ReturnType<typeof useOrganizationInfoQuery>;
export type OrganizationInfoLazyQueryHookResult = ReturnType<typeof useOrganizationInfoLazyQuery>;
export type OrganizationInfoQueryResult = Apollo.QueryResult<OrganizationInfoQuery, OrganizationInfoQueryVariables>;