import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type MyRewardsOrganizationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyRewardsOrganizationQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'referralResumesEnabled'>
    & { candidateSteps: Array<(
      { __typename?: 'CandidateRecruitmentStep' }
      & Pick<Types.CandidateRecruitmentStep, 'stepName'>
    ) | (
      { __typename?: 'CandidateRetentionStep' }
      & Pick<Types.CandidateRetentionStep, 'durationMonths'>
    )>, referralRewardStructure: (
      { __typename?: 'ReferralRewardStructure' }
      & Pick<Types.ReferralRewardStructure, 'id'>
      & { referralRecruitmentStructure: Array<(
        { __typename?: 'ReferralRewardStructureReferralRecruitmentItem' }
        & Pick<Types.ReferralRewardStructureReferralRecruitmentItem, 'step'>
        & { reward: (
          { __typename?: 'ReferralRewardStructurePointReward' }
          & Pick<Types.ReferralRewardStructurePointReward, 'pointValue'>
        ) | (
          { __typename?: 'ReferralRewardStructureDrawTicketReward' }
          & Pick<Types.ReferralRewardStructureDrawTicketReward, 'numTickets'>
        ) | (
          { __typename?: 'ReferralRewardStructureManualMonthlyRaffleReward' }
          & Pick<Types.ReferralRewardStructureManualMonthlyRaffleReward, 'numTickets' | 'numWinnersSelectedPerMonth' | 'prizeText'>
        ) }
      )>, referralRetentionStructure: Array<(
        { __typename?: 'ReferralRewardStructureReferralRetentionItem' }
        & { duration: (
          { __typename?: 'ReferralRewardStructureReferralRetentionItemDuration' }
          & Pick<Types.ReferralRewardStructureReferralRetentionItemDuration, 'unit' | 'amount'>
        ), reward: (
          { __typename?: 'ReferralRewardStructurePointReward' }
          & Pick<Types.ReferralRewardStructurePointReward, 'pointValue'>
        ) | (
          { __typename?: 'ReferralRewardStructureDrawTicketReward' }
          & Pick<Types.ReferralRewardStructureDrawTicketReward, 'numTickets'>
        ) | (
          { __typename?: 'ReferralRewardStructureManualMonthlyRaffleReward' }
          & Pick<Types.ReferralRewardStructureManualMonthlyRaffleReward, 'numTickets' | 'numWinnersSelectedPerMonth' | 'prizeText'>
        ) }
      )> }
    ) }
  ) }
);


export const MyRewardsOrganizationDocument = gql`
    query MyRewardsOrganization {
  getMyRewardsOrganization {
    id
    referralResumesEnabled
    candidateSteps {
      ... on CandidateRecruitmentStep {
        stepName
      }
      ... on CandidateRetentionStep {
        durationMonths
      }
    }
    referralRewardStructure {
      id
      referralRecruitmentStructure {
        ... on ReferralRewardStructureReferralRecruitmentItem {
          step
          reward {
            ... on ReferralRewardStructurePointReward {
              pointValue
            }
            ... on ReferralRewardStructureDrawTicketReward {
              numTickets
            }
            ... on ReferralRewardStructureManualMonthlyRaffleReward {
              numTickets
              numWinnersSelectedPerMonth
              prizeText
            }
          }
        }
      }
      referralRetentionStructure {
        ... on ReferralRewardStructureReferralRetentionItem {
          duration {
            unit
            amount
          }
          reward {
            ... on ReferralRewardStructurePointReward {
              pointValue
            }
            ... on ReferralRewardStructureDrawTicketReward {
              numTickets
            }
            ... on ReferralRewardStructureManualMonthlyRaffleReward {
              numTickets
              numWinnersSelectedPerMonth
              prizeText
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMyRewardsOrganizationQuery__
 *
 * To run a query within a React component, call `useMyRewardsOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRewardsOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRewardsOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyRewardsOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<MyRewardsOrganizationQuery, MyRewardsOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyRewardsOrganizationQuery, MyRewardsOrganizationQueryVariables>(MyRewardsOrganizationDocument, options);
      }
export function useMyRewardsOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyRewardsOrganizationQuery, MyRewardsOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyRewardsOrganizationQuery, MyRewardsOrganizationQueryVariables>(MyRewardsOrganizationDocument, options);
        }
export type MyRewardsOrganizationQueryHookResult = ReturnType<typeof useMyRewardsOrganizationQuery>;
export type MyRewardsOrganizationLazyQueryHookResult = ReturnType<typeof useMyRewardsOrganizationLazyQuery>;
export type MyRewardsOrganizationQueryResult = Apollo.QueryResult<MyRewardsOrganizationQuery, MyRewardsOrganizationQueryVariables>;