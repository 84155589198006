import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
export type EmailCommunicationSettingsFragmentFragment = (
  { __typename?: 'RewardsOrganization' }
  & Pick<Types.RewardsOrganization, 'id' | 'shortName' | 'emailBannerLogoImageUrl' | 'timezone' | 'websiteUrl'>
);

export const EmailCommunicationSettingsFragmentFragmentDoc = gql`
    fragment EmailCommunicationSettingsFragment on RewardsOrganization {
  id
  shortName
  emailBannerLogoImageUrl
  timezone
  websiteUrl
}
    `;