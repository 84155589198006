/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { DialogContent } from "@material-ui/core";
import { ReactNode } from "react";

import { StylableProps } from "../../types";

interface ModalContentProps extends StylableProps {
  children: ReactNode;
  disablePadding?: boolean;
}

export function ModalContent({
  className,
  disablePadding,
  children,
}: ModalContentProps) {
  return (
    <DialogContent
      className={className}
      css={css`
        ${disablePadding &&
        css`
          padding: 0;
          &:first-child {
            padding-top: 0;
          }
        `}
        // force scrollbars to show

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
      `}
    >
      {children}
    </DialogContent>
  );
}
