/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { compact } from "lodash";
import { useForm } from "react-hook-form";

import { CardContent } from "@rewards-web/shared/components/card/card-content";
import { Form } from "@rewards-web/shared/components/form";
import { useNavigationBlockingPrompt } from "@rewards-web/shared/hooks/use-navigation-blocking-prompt";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { PageCard } from "../../../../../../shared/components/page-card";
import { PageCardActions } from "../../../../../../shared/components/page-card/page-card-actions";
import { PageCardTitle } from "../../../../../../shared/components/page-card/page-card-title";
import {
  DEFAULT_RECIPIENT,
  RedemptionPayrollEmailRecipientsField,
  RedemptionSummaryDescription,
  RedemptionSummaryNotificationEmailValue,
} from "../../../../../../shared/modules/settings/fields/settings-redemption-summary-emails-field";
import { useUpdateRedemptionNotificationEmailsMutation } from "./update-redemption-notification-emails.generated";

export interface SettingsPayrollRedemptionNotificationEmailFormValues {
  payrollRedemptionNotificationRecipients: RedemptionSummaryNotificationEmailValue[];
}

interface SettingsPayrollRedemptionNotificationEmailFormProps {
  initialValue?: RedemptionSummaryNotificationEmailValue[];
}

export function SettingsPayrollRedemptionNotificationEmailForm({
  initialValue,
}: SettingsPayrollRedemptionNotificationEmailFormProps): JSX.Element {
  const form = useForm<SettingsPayrollRedemptionNotificationEmailFormValues>({
    defaultValues: {
      payrollRedemptionNotificationRecipients: initialValue ?? [
        DEFAULT_RECIPIENT,
      ],
    },
  });

  const [
    updateRedemptionNotificationEmails,
  ] = useUpdateRedemptionNotificationEmailsMutation();
  const snackbar = useSnackbar();

  useNavigationBlockingPrompt(
    "Are you sure you want to leave this page? You will lose all unsaved changes.",
    form.formState.isDirty
  );

  return (
    <Form
      submitting={form.formState.isSubmitting}
      onSubmit={form.handleSubmit(async (values) => {
        try {
          await updateRedemptionNotificationEmails({
            variables: {
              redemptionSummaryNotificationEmails: compact(
                values.payrollRedemptionNotificationRecipients.map(
                  (v) => v.email
                )
              ),
            },
          });
          snackbar.show({
            severity: "success",
            message: "Success! The minimum redemption amount has been updated.",
          });
        } catch (e) {
          reportError(e);
          snackbar.show({
            severity: "error",
            message:
              "An unexpected error has occurred. Please try again later.",
          });
        }
      })}
    >
      <PageCard>
        <PageCardTitle
          title={"Recipient(s) of weekly payroll redemptions report"}
        />

        <CardContent
          css={(theme: AppTheme) => css`
            padding-left: ${theme.spacing(3)};
            max-width: 768px;
          `}
        >
          <RedemptionSummaryDescription />

          <RedemptionPayrollEmailRecipientsField
            control={form.control}
            name="payrollRedemptionNotificationRecipients"
          />
        </CardContent>

        <PageCardActions disabled={!form.formState.isDirty} />
      </PageCard>
    </Form>
  );
}
