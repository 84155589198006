/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { darken, lighten } from "@material-ui/core";

import { Button } from "@rewards-web/shared/components/button";
import { Divider } from "@rewards-web/shared/components/divider";
import { NoImageIcon } from "@rewards-web/shared/components/image-field/file-input-contents/no-image-icon";
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { JobPostingLocationField } from "@rewards-web/shared/graphql-types";
import { useImagePreviewSrc } from "@rewards-web/shared/hooks/use-image-preview-src";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { isValidHexColor } from "@rewards-web/shared/lib/validate-hex-color";
import { AppTheme, AppThemeProvider } from "@rewards-web/shared/style/theme";

interface JobPostingPreviewProps {
  themeColor: string;
  logoImage: File | string | null;
  bannerImage: File | string | null;
  locationField: JobPostingLocationField;
}

const SKELETON_SIZES = [
  "95%",
  "95%",
  "80%",
  "80%",
  "80%",
  "70%",
  "65%",
  "65%",
  "60%",
] as const;

export function JobPostingPreview({
  themeColor: themeColorProp,
  logoImage,
  bannerImage,
  locationField,
}: JobPostingPreviewProps): JSX.Element {
  const logoImageUrl = useImagePreviewSrc(logoImage);
  const bannerImageUrl = useImagePreviewSrc(bannerImage);
  const theme = useTheme();

  const themeColor = isValidHexColor(themeColorProp)
    ? `#${themeColorProp}`
    : theme.palette.primary.main;

  return (
    <AppThemeProvider
      theme={{
        palette: {
          primary: {
            main: themeColor,
            dark: darken(themeColor, 0.3),
            light: lighten(themeColor, 0.3),
          },
        },
      }}
    >
      <div
        css={(theme: AppTheme) => css`
          border-style: solid;
          border-color: ${theme.palette.divider};
          border-width: 1px;
          width: 100%;
          margin-bottom: ${theme.spacing(2)};
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        `}
      >
        {bannerImageUrl.imageUrl && !bannerImageUrl.loading ? (
          <img
            src={bannerImageUrl.imageUrl ?? ""}
            alt="Banner"
            css={css`
              height: 220px;
              width: 100%;
              object-fit: cover;
            `}
          />
        ) : (
          <div
            css={css`
              height: 220px;
              background-color: ${themeColor};
              display: grid;
              grid-template-columns: 40% 20% 40%;
              padding: 20px;
            `}
          >
            <div>
              <Divider />
            </div>
            <div>
              <Typography
                css={css`
                  margin-top: -8px;
                  color: #ffffff;
                `}
                align="center"
              >
                Banner
              </Typography>
            </div>
            <div>
              <Divider />
            </div>
          </div>
        )}

        <div
          css={css`
            position: relative;
            padding: 5px;
            background-color: #fff;
            top: -60px;
            left: 40px;
            width: 130px;
            height: 130px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          {logoImageUrl.imageUrl && !logoImageUrl.loading ? (
            <img
              src={logoImageUrl.imageUrl}
              alt="Square logo"
              css={(theme: AppTheme) => css`
                border-radius: ${theme.spacing(1)};
                width: 110px;
                height: 110px;
                object-fit: contain;
                padding: 3px;
              `}
            />
          ) : (
            <NoImageIcon
              css={css`
                pointer-events: none;
              `}
              width="4em"
              height="4em"
            />
          )}
        </div>

        <div
          css={(theme: AppTheme) => css`
            padding-left: 20px;
            margin-top: -${theme.spacing(4)};
          `}
        >
          {SKELETON_SIZES.map((width, index) => {
            return (
              <Skeleton
                key={index}
                animated={false}
                width={width}
                height="45px"
              />
            );
          })}
        </div>

        <div
          css={(theme: AppTheme) => css`
            padding: ${theme.spacing(2)};
            margin-bottom: ${theme.spacing(2)};
            margin-top: ${theme.spacing(2)};
          `}
        >
          <Button color="primary" label="I’m Interested" />
        </div>
        <Divider />

        <div
          css={(theme: AppTheme) => css`
            padding: ${theme.spacing(2)};
            margin-top: ${theme.spacing(3)};
          `}
        >
          <TextField
            fixLabelToTop
            label={(() => {
              switch (locationField) {
                case JobPostingLocationField.PostalCode:
                  return "Postal Code";
                case JobPostingLocationField.ResidingCity:
                  return "Residing City";
                case JobPostingLocationField.ZipCode:
                  return "ZIP Code";
                default:
                  assertNever(locationField);
              }
            })()}
            placeholder={(() => {
              switch (locationField) {
                case JobPostingLocationField.PostalCode:
                  return "L3R 9Z7";
                case JobPostingLocationField.ResidingCity:
                  return "New York";
                case JobPostingLocationField.ZipCode:
                  return "22958";
                default:
                  assertNever(locationField);
              }
            })()}
          />
        </div>
      </div>
    </AppThemeProvider>
  );
}
