/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { InputBaseComponentProps } from "@material-ui/core";
import { DropzoneRootProps } from "react-dropzone";

import { AppTheme, fontFamily } from "../../../style/theme";
import { Typography } from "../../typography";
import { CHOOSE_IMAGE_BUTTON_TEXT } from "../constants";
import { NoImageIcon } from "./no-image-icon";

export function FileInputContents(
  props: DropzoneRootProps &
    InputBaseComponentProps & {
      selectedFileUrl: string;
      loadingImage: boolean;
    }
) {
  const { type, inputRef, selectedFileUrl, loadingImage, ...restProps } = props;

  const content = (() => {
    if (loadingImage) {
      return null;
    }

    if (selectedFileUrl) {
      return (
        <img
          css={css`
            max-height: 85%;
            max-width: 65%;
            pointer-events: none;
          `}
          src={selectedFileUrl}
          alt={restProps.name}
        />
      );
    }

    return (
      <>
        <NoImageIcon
          css={css`
            pointer-events: none;
          `}
          width="4em"
          height="4em"
        />
        <Typography
          css={css`
            font-family: ${fontFamily};
            font-weight: 600;
            font-size: 1.1em;
            pointer-events: none;
          `}
          color="textSecondary"
          component="span"
        >
          No image selected.
        </Typography>

        <Typography
          variant="caption"
          css={css`
            font-size: 0.83em;
            font-style: italic;
            color: #7f8e9f;
            pointer-events: none;
          `}
        >
          Drag and Drop image here to upload or click on "
          {CHOOSE_IMAGE_BUTTON_TEXT}"
        </Typography>
      </>
    );
  })();

  return (
    <div
      {...restProps}
      css={(theme: AppTheme) => css`
        width: 100%;
        height: 200px;
        cursor: pointer;
        padding: ${theme.spacing(4)} ${theme.spacing(2)};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & > *:not(:last-child) {
          margin-bottom: ${theme.spacing(1)};
        }
      `}
    >
      {content}
    </div>
  );
}
