/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertFromRaw,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { forwardRef, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useFormControlContext } from "@rewards-web/shared/components/form/form-control";
import { Typography } from "@rewards-web/shared/components/typography";
import { usePrevious } from "@rewards-web/shared/hooks/use-previous";
import { AppTheme } from "@rewards-web/shared/style/theme";
import { ControlledFormFieldProps } from "@rewards-web/shared/types";

const DEFAULT_VALUE = "<p><br></p>";

interface WYSIWYGTextFieldProps extends ControlledFormFieldProps {
  /**
   * Adds space on the top between the cursor and the top border
   */
  topPadding?: number;
  helperText?: string;
  label: string;
  heightAuto?: boolean;
  toolBarInline?: boolean;
}

function contentStateToHTML(contentState: ContentState) {
  return stateToHTML(contentState);
}

function htmlToEditorState(html: string) {
  const blocksFromHTML = convertFromHTML(html);

  return EditorState.createWithContent(
    ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )
  );
}

export const WYSIWYGTextField = forwardRef(
  (
    {
      topPadding,
      name,
      onChange,
      value,
      error,
      label,
      helperText,
      toolBarInline,
      heightAuto,
    }: WYSIWYGTextFieldProps,
    ref: any
  ): JSX.Element => {
    const { readOnly } = useFormControlContext();

    const [editorState, setEditorState] = useState(() =>
      htmlToEditorState(value)
    );
    const [htmlValue, setHTMLValue] = useState(() =>
      contentStateToHTML(editorState.getCurrentContent())
    );

    const prevHtmlValue = usePrevious(htmlValue); // wait one cycle because htmlValue updates quicker than form state
    useEffect(() => {
      // reset form state when value changes from locally-tracked html value
      if (
        typeof prevHtmlValue === "string" &&
        prevHtmlValue !== DEFAULT_VALUE &&
        value !== prevHtmlValue
      ) {
        setHTMLValue(prevHtmlValue);
        setEditorState(htmlToEditorState(value));
      }
    }, [value, prevHtmlValue, setHTMLValue]);

    let toolbarDisplay = "block";
    if (readOnly) {
      toolbarDisplay = "none";
    } else if (toolBarInline) {
      toolbarDisplay = "flex";
    }

    return (
      <div
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        <Editor
          readOnly={readOnly}
          spellCheck
          ariaLabel={label}
          editorClassName={name}
          editorState={editorState}
          onContentStateChange={(contentState) => {
            const nextHtmlValue = contentStateToHTML(
              convertFromRaw(contentState)
            );
            setHTMLValue(nextHtmlValue);
            // sync HTML updates with parent
            if (onChange) {
              onChange(nextHtmlValue);
            }
          }}
          onEditorStateChange={setEditorState}
          ref={ref}
          stripPastedStyles
          toolbar={{
            options: ["inline", "list"],
            inline: {
              className: css`
                div {
                  border: 0px;
                  background-color: transparent;
                }
              `,
              options: ["bold", "italic", "underline"],
            },
            list: {
              className: css`
                div {
                  border: 0px;
                  background-color: transparent;
                }
              `,
              options: ["unordered", "ordered"],
            },
          }}
          toolbarStyle={{
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            backgroundColor: "#FAFAFD",
            borderColor: "#dfe4eb",
            borderBottomColor: "transparent",
            marginBottom: "-1px",
            display: toolbarDisplay,
          }}
          editorStyle={{
            border: "1px solid",
            borderTopColor: "#dfe4eb",
            borderLeftColor: error ? "#ae5234" : "#dfe4eb",
            borderRightColor: error ? "#ae5234" : "#dfe4eb",
            borderBottomColor: error ? "#ae5234" : "#dfe4eb",
            // for some reason setting height explicitly causes scrolling to function weirdly
            // so we're setting min & max instead
            minHeight: heightAuto ? "auto" : "240px",
            maxHeight: heightAuto ? "auto" : "240px",
            backgroundColor: "white",
            borderTopLeftRadius: readOnly ? "10px" : undefined,
            borderTopRightRadius: readOnly ? "10px" : undefined,
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            padding: "0 10px",
            paddingTop: topPadding ? `${topPadding}px` : undefined,
            cursor: "text",
            opacity: readOnly ? 0.7 : undefined,
          }}
        />
        <Typography
          variant="footnote"
          color={error ? "error" : undefined}
          css={(theme: AppTheme) =>
            css`
              margin-left: ${theme.spacing(2)};
            `
          }
        >
          {error ? error.message : helperText ?? ""}
        </Typography>
      </div>
    );
  }
);
