import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { Form } from "@rewards-web/shared/components/form";
import { useNavigationBlockingPrompt } from "@rewards-web/shared/hooks/use-navigation-blocking-prompt";

import { PageCardActions } from "../../../../../../../shared/components/page-card/page-card-actions";
import { PageCardContent } from "../../../../../../../shared/components/page-card/page-card-content";
import { SettingsEmailBannerLogoImageField } from "../../../../../../../shared/modules/settings/fields/settings-email-banner-logo-image-field";
import { SettingsOrganizationTimezoneField } from "../../../../../../../shared/modules/settings/fields/settings-organization-timezone-field";
import { SettingsShortOrganizationURLField } from "../../../../../../../shared/modules/settings/fields/settings-organization-url-field";
import { SettingsShortOrganizationNameField } from "../../../../../../../shared/modules/settings/fields/settings-short-organization-name-field";
import { EmailCommunicationCardDataQuery } from "../email-communication-card-data.generated";

export interface SettingsEmailCommunicationFormValues {
  organizationShortName: string;
  emailBannerLogoImage: File | string | null;
  timezone: string;
  websiteUrl: string | null;
}

interface SettingsEmailCommunicationFormProps {
  data: EmailCommunicationCardDataQuery;
  onSubmit(values: SettingsEmailCommunicationFormValues): Promise<void>;
}

function getInitialValues(
  data: EmailCommunicationCardDataQuery
): SettingsEmailCommunicationFormValues {
  return {
    organizationShortName: data.getMyRewardsOrganization.shortName,
    emailBannerLogoImage:
      data.getMyRewardsOrganization.emailBannerLogoImageUrl ?? null,
    timezone: data.getMyRewardsOrganization.timezone,
    websiteUrl: data.getMyRewardsOrganization.websiteUrl ?? null,
  };
}

export function SettingsEmailCommunicationForm({
  data,
  onSubmit,
}: SettingsEmailCommunicationFormProps): JSX.Element {
  const form = useForm<SettingsEmailCommunicationFormValues>({
    defaultValues: getInitialValues(data),
  });

  useEffect(() => {
    // reset form when the data changes (signifying that it's been updated)
    form.reset(getInitialValues(data));
  }, [data, form]);

  useNavigationBlockingPrompt(
    "Are you sure you want to leave this page? You will lose all unsaved changes.",
    form.formState.isDirty
  );

  return (
    <Form
      submitting={form.formState.isSubmitting}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <PageCardContent>
        <SettingsShortOrganizationNameField
          control={form.control}
          name="organizationShortName"
          isRequired
        />
        <SettingsShortOrganizationURLField
          control={form.control}
          name="websiteUrl"
        />
        <SettingsOrganizationTimezoneField
          control={form.control}
          name="timezone"
        />
        <SettingsEmailBannerLogoImageField
          control={form.control}
          name="emailBannerLogoImage"
        />
      </PageCardContent>
      <PageCardActions disabled={!form.formState.isDirty} />
    </Form>
  );
}
