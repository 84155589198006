import { useContext, useMemo } from "react";

import { FeatureFlagContext, FeatureFlagUser } from "./context";

export interface UseFeatureFlagValue {
  enabled: boolean;
}

export function useFeatureFlagUser(): [
  FeatureFlagUser | null,
  (user: FeatureFlagUser | null) => void
] {
  const { user, setUser } = useContext(FeatureFlagContext);
  return [user, setUser];
}

export function useFeatureFlagsInitialized() {
  const { initialized } = useContext(FeatureFlagContext);
  return initialized;
}

export function useFeatureFlag(key: string): boolean {
  const { getFlagEnabled } = useContext(FeatureFlagContext);

  return useMemo(() => getFlagEnabled(key), [key, getFlagEnabled]);
}

export function useAppFeatureFlag(
  key: string,
  opts: {
    /**
     * The default value to use the for the flag, if the flag does not exist
     * or cannot be fetched from the feature flagging service.
     *
     * Defaults to `false`
     */
    default?: boolean;
  } = {}
): boolean {
  const { getAppFlagEnabled } = useContext(FeatureFlagContext);

  return useMemo(() => getAppFlagEnabled(key, { default: opts?.default }), [
    key,
    getAppFlagEnabled,
    opts?.default,
  ]);
}
