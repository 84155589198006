/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { RecognitionCategoryDefaultPoints } from "../fields/recognition-category-default-points-field";
import { RecognitionCategoryDisableSendFromManagers } from "../fields/recognition-category-disable-send-from-managers-field";
import { RecognitionCategoryNameField } from "../fields/recognition-category-name-field";
import { OverrideCategoryInfoAlert } from "../override-category-info-alert";
import { useEditRecognitionCategoryMutation } from "./edit-recognition-category.generated";
import { useRecognitionCategoryToEditQuery } from "./recognition-category-to-edit.generated";

export interface EditRecognitionCategoryModalProps {
  open: boolean;
  recognitionCategoryId: string | undefined;
  onClose(): void;
  onExited(): void;
  onSaved(): void;
  pointsPerDollar: number;
  isSuperuser: boolean;
}

interface EditRecognitionCategoryFormValues {
  name: string;
  defaultPointsEnabled: boolean;
  defaultPoints: string;
  disableSendFromManagers: boolean;
}

export function EditRecognitionCategoryModal({
  open,
  recognitionCategoryId,
  onClose,
  onExited,
  onSaved,
  pointsPerDollar,
  isSuperuser,
}: EditRecognitionCategoryModalProps) {
  const track = useTrack();
  const snackbar = useSnackbar();

  const categoryToEditQuery = useRecognitionCategoryToEditQuery({
    fetchPolicy: "network-only",
    skip: !recognitionCategoryId,
    variables: {
      recognitionCategoryId: recognitionCategoryId!,
    },
  });
  const [editRecognitionCategory] = useEditRecognitionCategoryMutation();

  const defaultValues = useMemo(
    () =>
      categoryToEditQuery.data?.getRecognitionCategoryById && {
        name: categoryToEditQuery.data.getRecognitionCategoryById.name,
        defaultPointsEnabled:
          typeof categoryToEditQuery.data.getRecognitionCategoryById
            .defaultPointAmount === "number",
        defaultPoints: categoryToEditQuery.data.getRecognitionCategoryById
          .defaultPointAmount
          ? String(
              categoryToEditQuery.data.getRecognitionCategoryById
                .defaultPointAmount
            )
          : "",
        disableSendFromManagers:
          categoryToEditQuery.data.getRecognitionCategoryById
            .disableSendFromManagers,
      },
    [categoryToEditQuery.data?.getRecognitionCategoryById]
  );

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm<EditRecognitionCategoryFormValues>({
    defaultValues: {
      name: "",
      defaultPoints: "",
    },
  });

  // handle loading the category async
  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [reset, defaultValues]);

  const loading = !defaultValues;

  const onSubmit = async (values: EditRecognitionCategoryFormValues) => {
    try {
      const res = await editRecognitionCategory({
        variables: {
          recognitionCategoryId: recognitionCategoryId!,
          name: values.name,
          defaultPointAmount:
            values.defaultPoints === ""
              ? null // clears default points (undefined would not clear it)
              : Number(values.defaultPoints),
          disableSendFromManagers: values.disableSendFromManagers,
        },
      });
      onSaved();
      onClose();
      reset();
      snackbar.show({
        severity: "success",
        message: `Success! Category ${values.name} has been updated.`,
      });

      track("Edited recognition category", {
        recognitionCategoryId: res.data?.partialUpdateRecognitionCategory.id,
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  const onCancel = () => {
    onClose();
    reset();
  };

  const modalContents = (() => {
    if (categoryToEditQuery.error) {
      return (
        <>
          <ModalContent>
            <Alert
              severity="error"
              message="An unexpected error occurred. Please try again later."
            />
          </ModalContent>
          <ModalActions>
            <Button label="Close" variant="outlined" onClick={onClose} />
          </ModalActions>
        </>
      );
    }

    if (
      categoryToEditQuery.data &&
      !categoryToEditQuery.data.getRecognitionCategoryById
    ) {
      return (
        <>
          <ModalContent>
            <Alert severity="warning" message="This category doesn't exist." />
          </ModalContent>
          <ModalActions>
            <Button label="Close" variant="outlined" onClick={onClose} />
          </ModalActions>
        </>
      );
    }

    return (
      <Form
        css={css`
          display: contents; // Modal child components must be direct DOM descendents of Modal
        `}
        onSubmit={handleSubmit(onSubmit)}
        submitting={isSubmitting}
      >
        <ModalTitle>Edit recognition category</ModalTitle>
        <ModalContent>
          {!loading ? (
            <>
              <RecognitionCategoryNameField
                control={control}
                name="name"
                autoFocus
              />
              <RecognitionCategoryDefaultPoints
                control={control}
                name="defaultPoints"
                enabledSwitchName="defaultPointsEnabled"
                setValue={setValue}
                pointsPerDollar={pointsPerDollar}
              />
              {isSuperuser && (
                <RecognitionCategoryDisableSendFromManagers
                  control={control}
                  enabledSwitchName="disableSendFromManagers"
                />
              )}
            </>
          ) : (
            <>
              <Skeleton height={80} animated />
              <Skeleton height={160} animated />
            </>
          )}
          <OverrideCategoryInfoAlert />
        </ModalContent>
        <ModalActions>
          <Button label="Cancel" variant="outlined" onClick={onCancel} />
          <Button
            label="Save"
            color="primary"
            type="submit"
            disabled={loading}
          />
        </ModalActions>
      </Form>
    );
  })();

  return (
    <Modal
      open={open}
      onClose={onCancel}
      onExited={onExited}
      width="684px"
      background="white"
      disableBackdropClick={isSubmitting}
    >
      {modalContents}
    </Modal>
  );
}
