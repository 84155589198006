import { SVGProps } from "react";

function TotalSharesIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={23}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.5}
        d="M3.38 16.849a3.596 3.596 0 0 0 2.716-.879c.236-.205.44-.437.616-.687l9.136 5.354a4.026 4.026 0 0 0-.27 1.842c.091 1.031.562 1.961 1.325 2.625a3.596 3.596 0 0 0 2.716.878c2.042-.195 3.55-2.082 3.365-4.21a3.905 3.905 0 0 0-1.325-2.624 3.589 3.589 0 0 0-2.715-.88 3.65 3.65 0 0 0-2.52 1.38c-.05.063-.095.13-.141.196l-9.135-5.355a3.996 3.996 0 0 0 .001-2.979l9.136-5.354c.609.867 1.554 1.47 2.659 1.575a3.596 3.596 0 0 0 2.716-.879 3.903 3.903 0 0 0 1.324-2.623 3.963 3.963 0 0 0-.845-2.832 3.653 3.653 0 0 0-2.518-1.38 3.59 3.59 0 0 0-2.716.88 3.902 3.902 0 0 0-1.325 2.624c-.057.65.046 1.277.27 1.84l-9.135 5.354c-.609-.867-1.554-1.47-2.658-1.574a3.593 3.593 0 0 0-2.716.88 3.902 3.902 0 0 0-1.325 2.624c-.09 1.031.21 2.036.845 2.832a3.661 3.661 0 0 0 2.518 1.372Zm15.645 2.328c.09-.008.176-.011.262-.011 1.45 0 2.691 1.155 2.826 2.69.144 1.624-1.009 3.068-2.568 3.216-1.566.149-2.945-1.052-3.086-2.676-.146-1.627 1.006-3.07 2.566-3.22Zm-2.57-15.575c.135-1.534 1.376-2.69 2.827-2.69a2.9 2.9 0 0 1 .262.011c1.559.149 2.711 1.592 2.568 3.216-.143 1.624-1.52 2.825-3.087 2.676-1.56-.148-2.712-1.59-2.57-3.213ZM.89 12.722c.134-1.534 1.375-2.69 2.826-2.69.085 0 .174.004.262.012 1.559.149 2.711 1.592 2.568 3.216-.143 1.624-1.523 2.824-3.087 2.675a2.788 2.788 0 0 1-1.923-1.053 3.014 3.014 0 0 1-.646-2.16Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}

export default TotalSharesIcon;
