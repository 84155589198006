import { InputHTMLAttributes } from "react";
import MaskedInput, { conformToMask } from "react-text-mask";

import { stripNonNumericCharacters } from "../../../lib/strip-non-numeric-characters";

const mask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export function TelMask({
  inputRef,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & { inputRef?: any }) {
  return (
    <MaskedInput
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      pipe={(conformedValue, config: { rawValue: string }) => {
        const numericCharacters = stripNonNumericCharacters(
          config.rawValue || ""
        );

        if (numericCharacters.length === 11 && numericCharacters[0] === "1") {
          // strip off leading one if we think it's the country code (aka +1)
          return conformToMask(numericCharacters.substring(1), mask)
            .conformedValue;
        }

        return conformedValue;
      }}
      mask={mask}
      {...props}
    />
  );
}

TelMask.displayName = "TelMask";
