import { Navigate } from "react-router-dom";

import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { ErrorPage } from "@rewards-web/shared/pages/error";

import { useOrganizationLaunchStatusQuery } from "../../../shared/modules/launch/hooks/use-organization-launch-status/organization-launch-status.generated";
import { usePermissionsQuery } from "../../../shared/modules/permissions/hooks/use-permissions-query";
import { useMyAdminDetailsQuery } from "./get-admin-details-query.generated";
import { getDefaultPath } from "./get-default-path";

export function AdminIndexPage(): JSX.Element {
  const permissionsQuery = usePermissionsQuery();
  const launchStatusQuery = useOrganizationLaunchStatusQuery({
    fetchPolicy: "cache-first",
    onError: reportError,
  });

  const goalsEnabled = useFeatureFlag(
    "admin-app-budget-utilization-goal-available-temp"
  ); // add flags for other goal types here

  // currently only querying if admin has available goals, so we can
  // skip this if goals are not enabled for the org
  const adminQuery = useMyAdminDetailsQuery({
    onError: reportError,
    skip: !goalsEnabled,
  });

  if (launchStatusQuery.error || permissionsQuery.error || adminQuery.error) {
    return <ErrorPage />;
  }

  if (
    !launchStatusQuery.data ||
    !permissionsQuery.data ||
    (goalsEnabled && !adminQuery.data)
  ) {
    return <PageLoadingState />;
  }

  return (
    <Navigate
      to={getDefaultPath({
        permissions: permissionsQuery.data.getMyRewardsAdminUser.permissions,
        launched: launchStatusQuery.data.getMyRewardsOrganization.launched,
        onboardingDisabled:
          launchStatusQuery.data.getMyRewardsOrganization.onboardingState
            .disabledOnboarding === true,
        hasAvailableGoal:
          adminQuery.data?.getMyRewardsAdminUser?.hasAvailableGoal ?? false,
      })}
      replace
    />
  );
}
