/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Divider } from "@material-ui/core";
import { sortBy } from "lodash";
import { Fragment } from "react";

import { Typography } from "@rewards-web/shared/components/typography";
import {
  ApplicationSubmission,
  ApplicationSubmissionYesNoAnswer,
  JobApplicationDriversLicenseAnswer,
  JobPosting,
  Maybe,
} from "@rewards-web/shared/graphql-types";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { QuestionRow } from "./question-row";

interface CandidateScreenerQuestionsProps {
  submissions: Array<
    { __typename?: "ApplicationSubmission" } & Pick<
      ApplicationSubmission,
      | "id"
      | "submittedAt"
      | "postalCode"
      | "zipCode"
      | "residingCity"
      | "hasDriversLicenseAndAccessToCar"
      | "yearsOfExperience"
    > & {
        jobPosting: { __typename?: "JobPosting" } & Pick<
          JobPosting,
          "id" | "title" | "geography"
        >;
        yesNoAnswers?: Maybe<
          Array<
            { __typename?: "ApplicationSubmissionYesNoAnswer" } & Pick<
              ApplicationSubmissionYesNoAnswer,
              "question" | "answer"
            >
          >
        >;
      }
  >;
}

interface ScreenerQuestionsSections {
  jobTitle: string;
  jobId: string;
  geography: string;
  drivingLicence: {
    question: string;
    answer: JobApplicationDriversLicenseAnswer;
  } | null;
  yearsOfExperience: {
    question: string;
    textAnswer: string;
  } | null;
  yesNoAnswers?: {
    question: string;
    answer: "YES" | "NO";
  }[];
}

export function CandidateScreenerQuestions({
  submissions,
}: CandidateScreenerQuestionsProps): JSX.Element {
  const screenerQuestions = () => {
    const ids: string[] = [];
    const screenerSections: ScreenerQuestionsSections[] = [];

    sortBy(submissions, (submission) => submission.submittedAt).forEach(
      (submission) => {
        if (ids.includes(submission.jobPosting.id)) {
          return null;
        }

        const getYesNoAnswersSection = () => {
          return (submission.yesNoAnswers ?? []).map<{
            question: string;
            answer: "YES" | "NO";
          }>((yesNoAnswer) => ({
            question: yesNoAnswer.question,
            answer: yesNoAnswer.answer,
          }));
        };

        if (
          submission.hasDriversLicenseAndAccessToCar ||
          submission.yearsOfExperience ||
          getYesNoAnswersSection().length > 0
        ) {
          screenerSections.push({
            jobTitle: submission.jobPosting.title,
            jobId: submission.jobPosting.id,
            geography: submission.jobPosting.geography,
            drivingLicence: submission.hasDriversLicenseAndAccessToCar
              ? {
                  question:
                    "Do you have a driving license and access to a car?",
                  answer: submission.hasDriversLicenseAndAccessToCar,
                }
              : null,
            yearsOfExperience: submission.yearsOfExperience
              ? {
                  question: "Years of experience with role",
                  textAnswer: (() => {
                    switch (submission.yearsOfExperience) {
                      case "GREATER_THAN_THREE_YEARS":
                        return "> 3 years";
                      case "LESS_THAN_A_YEAR":
                        return "< 1 year";
                      case "ONE_TO_THREE_YEARS":
                        return "1-3 years";
                      default:
                        return "N/A";
                    }
                  })(),
                }
              : null,
            yesNoAnswers: getYesNoAnswersSection(),
          });
        }

        ids.push(submission.jobPosting.id);
      }
    );

    return screenerSections;
  };

  const allScreenerQuestions = screenerQuestions();
  return (
    <div>
      {allScreenerQuestions.length > 0 ? (
        allScreenerQuestions.map((questions, idx) => {
          return (
            <Fragment key={questions.jobId}>
              {idx !== 0 && (
                <Divider
                  css={(theme: AppTheme) =>
                    css`
                      margin-bottom: ${theme.spacing(1.5)};
                    `
                  }
                />
              )}
              {allScreenerQuestions.length > 1 && (
                <>
                  <Typography
                    variant="h4"
                    css={css`
                      font-weight: bold;
                    `}
                  >
                    {questions.jobTitle}
                  </Typography>
                  <Typography variant="body">{questions.geography}</Typography>
                </>
              )}
              <ol
                css={(theme: AppTheme) => css`
                  padding-left: ${theme.spacing(2)};

                  li {
                    margin-top: ${theme.spacing(0.5)};
                  }

                  li::marker {
                    font-weight: bold;
                  }
                `}
              >
                {questions.drivingLicence && (
                  <li>
                    <QuestionRow
                      question={questions.drivingLicence.question}
                      answer={questions.drivingLicence.answer}
                    />
                  </li>
                )}
                {questions.yearsOfExperience && (
                  <li>
                    <QuestionRow
                      question={questions.yearsOfExperience.question}
                      textAnswer={questions.yearsOfExperience.textAnswer}
                    />
                  </li>
                )}
                {questions.yesNoAnswers?.map((yesNoQuestion) => (
                  <li key={yesNoQuestion.question}>
                    {
                      <QuestionRow
                        question={yesNoQuestion.question}
                        answer={yesNoQuestion.answer}
                      />
                    }
                  </li>
                ))}
              </ol>
            </Fragment>
          );
        })
      ) : (
        <Typography variant="body">
          No screener questions have been submitted.
        </Typography>
      )}
    </div>
  );
}
