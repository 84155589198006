import { StepIconProps } from "@material-ui/core";

import { RewardsAdminUser } from "@rewards-web/shared/graphql-types";

import { DisplayStep } from "../lib";
import { ActionableCandidateStep } from "./actionable-candidate-step";
import { InactiveCandidateStep } from "./inactive-candidate-step";

export interface CandidateStepProps extends StepIconProps {
  finalStep: boolean;
  candidateId: string;
  assignedToAdmin:
    | Pick<RewardsAdminUser, "id" | "firstName" | "lastName">
    | null
    | undefined;
  candidateName: string;
  referredBy: string;
  step: DisplayStep;
  onArchive(updatedAdminNotes: string): void;
  onComplete(): void;
  onReopen(): void;
}

export function CandidateStep({ step, ...otherProps }: CandidateStepProps) {
  if (step.status === "actionable" || step.status === "archived") {
    return <ActionableCandidateStep step={step} {...otherProps} />;
  }

  return <InactiveCandidateStep {...otherProps} step={step} />;
}
