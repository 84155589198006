import { ClassNames } from "@emotion/react";
import { FormControlLabel, Switch } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { forwardRef } from "react";

import {
  ControlledFormFieldProps,
  StylableProps,
} from "@rewards-web/shared/types";

import { useFormControlContext } from "../form/form-control";

export interface SwitchFieldProps
  extends ControlledFormFieldProps,
    StylableProps {
  label: string;
  hideLabel?: boolean;
  color?: "primary" | "error";
  disabled?: boolean;
}

export const SwitchField = forwardRef(
  (
    {
      value,
      label,
      hideLabel,
      onChange,
      className,
      color = "primary",
      disabled,
    }: SwitchFieldProps,
    ref
  ): JSX.Element => {
    const { submitting } = useFormControlContext();
    const theme = useTheme();
    return (
      <ClassNames>
        {({ css }) => (
          <FormControlLabel
            className={className}
            classes={{
              label: css`
                ${hideLabel &&
                css`
                  display: none;
                `}
              `,
            }}
            control={
              <Switch
                disabled={disabled || submitting}
                color={color === "error" ? undefined : "primary"}
                checked={value}
                onChange={onChange}
                classes={{
                  track: "custom-switch-track",
                  checked: "custom-switch-checked",
                }}
                className={
                  color === "error"
                    ? css`
                        .custom-switch-checked + .custom-switch-track {
                          background-color: ${theme.palette.error.main};
                        }

                        .custom-switch-checked {
                          color: ${theme.palette.error.main};
                        }
                      `
                    : undefined
                }
              />
            }
            ref={ref}
            label={label}
          />
        )}
      </ClassNames>
    );
  }
);
