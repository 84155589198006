import { extractNumber } from "@rewards-web/shared/lib/extract-number";

import { EditReferralStructureFormValues } from ".";

export function calculateTotalReferralPointValue(
  values: EditReferralStructureFormValues
): number {
  return (
    (extractNumber(values.hundredHourWorkedPointValue) || 0) +
    (extractNumber(values.threeHundredHourWorkedPointValue) || 0) +
    (extractNumber(values.CompletedOrientationPointValue) || 0) +
    (extractNumber(values.candidateExpressInterestPointValue) || 0) +
    (extractNumber(values.CompletedFirstShiftPointValue) || 0)
  );
}
