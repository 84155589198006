import { Control, FieldValues, get, Path, useFormState } from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";

const MAX_LENGTH = 255;

interface SettingsLegalBusinessNameFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  isRequired?: boolean;
}

export function SettingsLegalBusinessNameField<T extends FieldValues>({
  control,
  name,
  isRequired,
}: SettingsLegalBusinessNameFieldProps<T>) {
  const formState = useFormState({ control });

  return (
    <TextField
      label="Legal business name"
      disableAutocomplete
      error={get(formState.errors, name)}
      {...control.register(name, {
        required: isRequired,
        maxLength: {
          value: MAX_LENGTH,
          message: `The name must not exceed ${MAX_LENGTH} characters`,
        },
      })}
    />
  );
}
