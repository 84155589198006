import { SVGProps } from "react";

function TotalSuccesfulInterViews(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={27}
      height={27}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.866.54H4.232A4.244 4.244 0 0 0 0 4.772v11.285a4.245 4.245 0 0 0 4.232 4.232h6.348l6.101 6.03a.507.507 0 0 0 .353.142c.142 0 .282-.106.247-.282l-.175-5.89h4.76a4.244 4.244 0 0 0 4.232-4.233V4.771A4.244 4.244 0 0 0 21.866.54Zm2.821 15.517a2.83 2.83 0 0 1-2.82 2.82H15.66l.036 1.446.07 3.104-4.232-4.162-.388-.387H4.232a2.83 2.83 0 0 1-2.82-2.821V4.772a2.83 2.83 0 0 1 2.82-2.821h17.633a2.83 2.83 0 0 1 2.82 2.82l.002 11.286Z"
        fill="#4D4D4D"
      />
      <path
        d="m17.669 6.393-6.56 6.595-2.68-2.68a.683.683 0 0 0-.988 0 .683.683 0 0 0 0 .988l3.174 3.174a.695.695 0 0 0 .493.211c.176 0 .353-.07.493-.211l7.053-7.053a.683.683 0 0 0 0-.988.653.653 0 0 0-.985-.036Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}

export default TotalSuccesfulInterViews;
