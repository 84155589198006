import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetOrganizationStepsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetOrganizationStepsQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id'>
    & { candidateSteps: Array<(
      { __typename?: 'CandidateRecruitmentStep' }
      & Pick<Types.CandidateRecruitmentStep, 'stepName'>
    ) | (
      { __typename?: 'CandidateRetentionStep' }
      & Pick<Types.CandidateRetentionStep, 'durationMonths'>
    )> }
  ) }
);


export const GetOrganizationStepsDocument = gql`
    query GetOrganizationSteps {
  getMyRewardsOrganization {
    id
    candidateSteps {
      ... on CandidateRecruitmentStep {
        stepName
      }
      ... on CandidateRetentionStep {
        durationMonths
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationStepsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationStepsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationStepsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationStepsQuery, GetOrganizationStepsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationStepsQuery, GetOrganizationStepsQueryVariables>(GetOrganizationStepsDocument, options);
      }
export function useGetOrganizationStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationStepsQuery, GetOrganizationStepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationStepsQuery, GetOrganizationStepsQueryVariables>(GetOrganizationStepsDocument, options);
        }
export type GetOrganizationStepsQueryHookResult = ReturnType<typeof useGetOrganizationStepsQuery>;
export type GetOrganizationStepsLazyQueryHookResult = ReturnType<typeof useGetOrganizationStepsLazyQuery>;
export type GetOrganizationStepsQueryResult = Apollo.QueryResult<GetOrganizationStepsQuery, GetOrganizationStepsQueryVariables>;