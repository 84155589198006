import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RecognitionModalDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RecognitionModalDataQuery = (
  { __typename?: 'Query' }
  & { myIdentity?: Types.Maybe<(
    { __typename?: 'Identity' }
    & Pick<Types.Identity, 'id'>
    & { roles: Array<(
      { __typename?: 'IdentityRole' }
      & Pick<Types.IdentityRole, 'id' | 'roleName' | 'active'>
    )> }
  )>, myAdmin?: Types.Maybe<(
    { __typename?: 'RewardsAdminUser' }
    & Pick<Types.RewardsAdminUser, 'id'>
    & { permissions: { __typename?: 'RewardsAdminFullAccessPermissions' } | (
      { __typename?: 'RewardsAdminRestrictedAccessPermissions' }
      & { permissionsV2?: Types.Maybe<(
        { __typename?: 'RewardsAdminRestrictedAccessPermissionsMapV2' }
        & { recognitionPoints?: Types.Maybe<(
          { __typename?: 'RewardsAdminRestrictedAccessRecognitionPointsPermissions' }
          & Pick<Types.RewardsAdminRestrictedAccessRecognitionPointsPermissions, 'canOverrideRecognitionCategoryDefaultAmounts' | 'canOverrideBudget'>
        )> }
      )> }
    ) }
  )>, myOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'pointsPerDollar' | 'recognitionRubricFileUrl' | 'recognitionBudgetsEnabled'>
  ), usableCategories: Array<(
    { __typename?: 'RecognitionCategory' }
    & Pick<Types.RecognitionCategory, 'id' | 'name' | 'disableSendFromManagers' | 'defaultPointAmount'>
  )> }
);


export const RecognitionModalDataDocument = gql`
    query RecognitionModalData {
  myIdentity: getMyIdentity {
    id
    roles {
      id
      roleName
      active
    }
  }
  myAdmin: getMyRewardsAdminUser {
    id
    permissions {
      ... on RewardsAdminRestrictedAccessPermissions {
        permissionsV2 {
          recognitionPoints {
            canOverrideRecognitionCategoryDefaultAmounts
            canOverrideBudget
          }
        }
      }
    }
  }
  myOrganization: getMyRewardsOrganization {
    id
    pointsPerDollar
    recognitionRubricFileUrl
    recognitionBudgetsEnabled
  }
  usableCategories: getMyRewardsOrganizationRecognitionCategories(
    filter: {disableSendFromManagers: false}
  ) {
    id
    name
    disableSendFromManagers
    defaultPointAmount
  }
}
    `;

/**
 * __useRecognitionModalDataQuery__
 *
 * To run a query within a React component, call `useRecognitionModalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecognitionModalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecognitionModalDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecognitionModalDataQuery(baseOptions?: Apollo.QueryHookOptions<RecognitionModalDataQuery, RecognitionModalDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecognitionModalDataQuery, RecognitionModalDataQueryVariables>(RecognitionModalDataDocument, options);
      }
export function useRecognitionModalDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecognitionModalDataQuery, RecognitionModalDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecognitionModalDataQuery, RecognitionModalDataQueryVariables>(RecognitionModalDataDocument, options);
        }
export type RecognitionModalDataQueryHookResult = ReturnType<typeof useRecognitionModalDataQuery>;
export type RecognitionModalDataLazyQueryHookResult = ReturnType<typeof useRecognitionModalDataLazyQuery>;
export type RecognitionModalDataQueryResult = Apollo.QueryResult<RecognitionModalDataQuery, RecognitionModalDataQueryVariables>;