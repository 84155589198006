import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { reportError } from "@rewards-web/shared/modules/error";

import { PageCard } from "../../../../../../shared/components/page-card";
import { PageCardTitle } from "../../../../../../shared/components/page-card/page-card-title";
import { useGetOrganizationInfoQuery } from "./get-organization-info.generated";
import { JobPostingCardContent } from "./job-posting-card-content";

export function SettingsJobPostingCard(): JSX.Element {
  const { data, loading, error } = useGetOrganizationInfoQuery({
    onError: reportError,
  });

  if (!data?.getMyRewardsOrganization && loading) {
    return <PageLoadingState />;
  }

  if (!data?.getMyRewardsOrganization || error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  return (
    <PageCard>
      <PageCardTitle title="Job Postings" />
      <JobPostingCardContent organizationInfo={data.getMyRewardsOrganization} />
    </PageCard>
  );
}
