import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AdminNavBarUserDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AdminNavBarUserDataQuery = (
  { __typename?: 'Query' }
  & { myIdentity?: Types.Maybe<(
    { __typename?: 'Identity' }
    & Pick<Types.Identity, 'id' | 'name'>
    & { roles: Array<(
      { __typename?: 'IdentityRole' }
      & Pick<Types.IdentityRole, 'id' | 'roleName' | 'active'>
    )> }
  )> }
);


export const AdminNavBarUserDataDocument = gql`
    query AdminNavBarUserData {
  myIdentity: getMyIdentity {
    id
    name
    roles {
      id
      roleName
      active
    }
  }
}
    `;

/**
 * __useAdminNavBarUserDataQuery__
 *
 * To run a query within a React component, call `useAdminNavBarUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminNavBarUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminNavBarUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminNavBarUserDataQuery(baseOptions?: Apollo.QueryHookOptions<AdminNavBarUserDataQuery, AdminNavBarUserDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminNavBarUserDataQuery, AdminNavBarUserDataQueryVariables>(AdminNavBarUserDataDocument, options);
      }
export function useAdminNavBarUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminNavBarUserDataQuery, AdminNavBarUserDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminNavBarUserDataQuery, AdminNavBarUserDataQueryVariables>(AdminNavBarUserDataDocument, options);
        }
export type AdminNavBarUserDataQueryHookResult = ReturnType<typeof useAdminNavBarUserDataQuery>;
export type AdminNavBarUserDataLazyQueryHookResult = ReturnType<typeof useAdminNavBarUserDataLazyQuery>;
export type AdminNavBarUserDataQueryResult = Apollo.QueryResult<AdminNavBarUserDataQuery, AdminNavBarUserDataQueryVariables>;