/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { Card } from "@rewards-web/shared/components/card";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Table } from "@rewards-web/shared/components/table-components/table";
import { TableBody } from "@rewards-web/shared/components/table-components/table-body";
import { TableHeaders } from "@rewards-web/shared/components/table-components/table-headers";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { Pagination } from "../pagination";

export interface DataTableProps {
  tableHeaders: ReactNode;
  tableBody: ReactNode;
  itemsPerPage: number;
  currentPageIndex: number;
  onCurrentPageIndexChange(nextPageIndex: number): void;
  emptyText?: string;
  loading?: boolean;
  items?: Array<any>;
  total?: number;
  disableBorderRadius?: boolean;
  title?: ReactNode;
  paddingDisabled?: boolean;
}

/**
 * A paginated table for use in admin pages.
 */
export function DataTable({
  tableHeaders,
  tableBody,
  title,
  loading,
  items,
  total,
  emptyText,
  itemsPerPage,
  currentPageIndex,
  onCurrentPageIndexChange,
  disableBorderRadius,
  paddingDisabled,
}: DataTableProps): JSX.Element {
  return (
    <Card
      variant="outlined"
      css={(theme: AppTheme) => css`
        width: 100%;
        border-width: 0px;
        ${!paddingDisabled && `padding: 0 ${theme.spacing(3)};`}
      `}
      borderRadius={disableBorderRadius ? 0 : undefined}
      disableHorizontalScroll
    >
      {title && (
        <div
          css={(theme: AppTheme) => css`
            padding-top: ${theme.spacing(3)};
            padding-bottom: ${theme.spacing(1)};
          `}
        >
          {title}
        </div>
      )}
      {loading && (
        <div
          css={css`
            position: sticky;
            top: 45%;
            height: 0px;
          `}
        >
          <PageLoadingState />
        </div>
      )}

      <div
        css={css`
          // scroll horizontally
          overflow-x: auto;
          display: block; // this make it not full width

          // force scrollbars to show
          &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.5);
            box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
          }
        `}
      >
        <Table
          css={css`
            margin-bottom: 0px;
          `}
        >
          <TableHeaders>{tableHeaders}</TableHeaders>

          <TableBody
            css={css`
              position: relative;
              ${!items?.length &&
              css`
                height: 300px;
              `}
            `}
            disabled={loading}
          >
            {!loading && !total && emptyText && (
              <div
                css={css`
                  position: absolute;
                  height: 300px;
                  width: 100%;
                `}
              >
                <Typography
                  variant="body"
                  color="textPrimary"
                  css={(theme: AppTheme) => css`
                    margin-bottom: ${theme.spacing(4)};
                    text-align: center;
                    top: 45%;
                    position: relative;
                  `}
                >
                  {emptyText}
                </Typography>
              </div>
            )}
            {tableBody}
          </TableBody>
        </Table>
      </div>

      {typeof total === "number" && total > 0 && total > itemsPerPage && (
        <Pagination
          total={total}
          itemsPerPage={itemsPerPage}
          currentPageIndex={currentPageIndex}
          onChange={(_, page) => {
            onCurrentPageIndexChange(page - 1);
          }}
        />
      )}
    </Card>
  );
}
