/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { TextButton } from "@rewards-web/shared/components/text-button";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { useQueryParam } from "@rewards-web/shared/hooks/use-query-param";
import { useTrack } from "@rewards-web/shared/modules/analytics/hooks";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { AuthErrorCode } from "../../../shared/modules/cognito-auth/constants";
import { LoginPageContainer } from "../login-page-container";
import { LoginFormValues, LoginSubmissionError } from "../types";
import { DeactivatedAdminModal } from "./deactivated-admin-modal";

export interface LoginFormProps {
  onSubmit(values: LoginFormValues, extra: { passwordPasted: boolean }): void;
  onForgotPassword(): void;
  submissionError: LoginSubmissionError | null;
  redirecting: boolean;
  sessionExpired: boolean;
}

export function LoginForm({
  onSubmit,
  submissionError,
  onForgotPassword,
  redirecting,
  sessionExpired,
}: LoginFormProps): JSX.Element {
  const track = useTrack();
  const [passwordPasted, setPasswordPasted] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useFormContext<LoginFormValues>();

  const adminEmail = useWatch({ control, name: "email" });

  const [deactivatedModal] = useQueryParam("deactivated");
  const [deactivatedAdminModal, setDeactivatedAdminModal] = useState(
    deactivatedModal === "true"
  );

  return (
    <LoginPageContainer>
      <Form
        onSubmit={handleSubmit((values) =>
          onSubmit(values, { passwordPasted })
        )}
        submitting={isSubmitting}
      >
        <div
          css={css`
            max-width: 370px;
            margin: 0 auto;
          `}
        >
          <Typography
            variant="h1"
            color="textPrimary"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(5)};
            `}
          >
            Log In
          </Typography>
          {(redirecting || submissionError || sessionExpired) && (
            <Alert
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(3)};
              `}
              message={((): ReactNode => {
                if (submissionError) {
                  switch (submissionError.code) {
                    case AuthErrorCode.UserDoesNotExist:
                    case AuthErrorCode.NotAuthorized:
                      return (
                        <>
                          Your email or password is incorrect.
                          <br />
                          Please try again.
                        </>
                      );
                    case AuthErrorCode.Deactivated:
                      return (
                        <>
                          Your account has been de-activated.
                          <br />
                          Contact someone at your company to re-activate your
                          account.{" "}
                          <TextButton
                            type="button"
                            onClick={() => {
                              track("Deactivated admin requested access", {
                                adminEmail: adminEmail,
                              });
                              setDeactivatedAdminModal(true);
                            }}
                          >
                            Request Access
                          </TextButton>
                        </>
                      );
                    case AuthErrorCode.UserNotConfirmed:
                      return (
                        <>
                          Your account is not confirmed.
                          <br />
                          Please contact an administrator.
                        </>
                      );
                    case AuthErrorCode.TooManyRequests:
                      track("Admin user hit the incorrect password limit", {
                        adminEmail: adminEmail,
                      });
                      return (
                        <>
                          You've tried too many times to sign in.
                          <br />
                          Please try again later.
                        </>
                      );

                    case "SET_NEW_PASSWORD_EXPIRED":
                      return (
                        <>
                          You took too long to set your password.
                          <br />
                          Please enter your temporary password and try again.
                        </>
                      );
                  }
                }

                if (redirecting) {
                  return "You must be logged in to view this page.";
                }

                if (sessionExpired) {
                  return "Your session has expired. Please log back in.";
                }

                return (
                  <>
                    An unexpected error occurred.
                    <br />
                    Please try again later.
                  </>
                );
              })()}
              severity={submissionError ? submissionError.severity : "warning"}
            />
          )}
          <DeactivatedAdminModal
            open={deactivatedAdminModal}
            onClose={() => setDeactivatedAdminModal(false)}
          />
          <TextField
            label="Email"
            error={errors.email}
            {...register("email", { required: "Email is required" })}
          />
          <TextField
            label="Password"
            type="password"
            error={errors.password}
            onPaste={() => setPasswordPasted(true)}
            {...register("password", { required: "Password is required" })}
          />
          <Button
            css={(theme: AppTheme) =>
              css`
                margin-bottom: ${theme.spacing(2)};
              `
            }
            color="primary"
            label="Log In"
            type="submit"
            loading={isSubmitting}
          />
          <Button
            variant="outlined"
            onClick={onForgotPassword}
            label="Forgot your password?"
            disabled={isSubmitting}
          />

          <div
            css={(theme: AppTheme) => css`
              margin-top: ${theme.spacing(2)};
            `}
          >
            <Typography display="inline" variant="body" color="textSecondary">
              By using this product, you agree to the{" "}
              <a
                href="https://www.caribou.care/privacy-policy-rewards"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>{" "}
              as well as the{" "}
              <a
                href="https://www.caribou.care/terms-rewards"
                target="_blank"
                rel="noreferrer"
              >
                Terms and Conditions
              </a>
              .
            </Typography>
          </div>
        </div>
      </Form>
    </LoginPageContainer>
  );
}
