import { SVGProps } from "react";

function TotalSixMonthsRetainedIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={17}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.348 19.797H1.993a1.992 1.992 0 0 1-1.99-1.99V3.452c0-1.096.892-1.99 1.99-1.99h12.355c1.098 0 1.99.894 1.99 1.99v14.357a1.99 1.99 0 0 1-1.99 1.99ZM1.993 2.275c-.65 0-1.177.528-1.177 1.176v14.357c0 .648.528 1.176 1.177 1.176h12.355a1.18 1.18 0 0 0 1.178-1.176V3.45c0-.648-.528-1.176-1.178-1.176H1.993Z"
        fill="#4D4D4D"
      />
      <path
        d="M4.715 3.576a.406.406 0 0 1-.406-.406V.406C4.31.18 4.491 0 4.715 0c.223 0 .408.182.408.405V3.17a.41.41 0 0 1-.408.406ZM11.563 3.576a.407.407 0 0 1-.407-.406V.406a.406.406 0 1 1 .813 0V3.17a.406.406 0 0 1-.406.406ZM15.931 6.056H.407a.407.407 0 0 1 0-.812H15.93c.225 0 .408.182.408.406a.408.408 0 0 1-.408.406ZM8.322 11.325c.506 0 .957.095 1.353.286a2.2 2.2 0 0 1 1.287 2.046c0 .484-.121.91-.363 1.276a2.377 2.377 0 0 1-.979.858 3.15 3.15 0 0 1-1.386.297c-1.034 0-1.833-.334-2.398-1.001C5.279 14.42 5 13.474 5 12.249c0-.858.15-1.588.451-2.19.308-.608.733-1.066 1.276-1.374.55-.315 1.188-.473 1.914-.473.374 0 .726.04 1.056.12.33.074.616.184.858.33l-.418.859c-.367-.242-.858-.363-1.474-.363-.8 0-1.426.25-1.881.748-.455.491-.682 1.21-.682 2.156 0 .117.004.209.011.275.22-.33.52-.58.902-.748a3.139 3.139 0 0 1 1.309-.264Zm-.132 3.872c.506 0 .917-.136 1.232-.407.315-.271.473-.634.473-1.09 0-.454-.161-.813-.484-1.077-.315-.271-.74-.407-1.276-.407-.345 0-.653.066-.924.198a1.61 1.61 0 0 0-.627.539 1.37 1.37 0 0 0-.22.759c0 .271.07.52.209.748.147.22.356.4.627.539.279.132.609.198.99.198Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}

export default TotalSixMonthsRetainedIcon;
