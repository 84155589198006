/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import InfoIcon from "@material-ui/icons/Info";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import OpenIcon from "@material-ui/icons/OpenInNew";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import { Alert } from "@rewards-web/shared/components/alert";
import { IconButton } from "@rewards-web/shared/components/icon-button";
import { ObscureRecordedText } from "@rewards-web/shared/components/obscure-recorded-text";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  RewardsAdminPermissionsType,
  RewardsAdminRestrictedAccessPermissions,
} from "@rewards-web/shared/graphql-types";
import {
  useTrack,
  useTrackScreenRecordingEvent,
} from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { BranchFilter } from "../../../shared/components/branch-filter";
import { DataTable } from "../../../shared/components/data-table";
import { EditIcon } from "../../../shared/components/edit-icon";
import { HeaderWithButton } from "../../../shared/components/header-with-button";
import { useMyBranches } from "../../../shared/modules/branches/use-my-branches";
import { usePermissionsQuery } from "../../../shared/modules/permissions/hooks/use-permissions-query";
import { AdminRestrictedPermissionsInfoTooltip } from "./admin-restricted-permissions-info-tooltip";
import { AdminsHeader } from "./admins-header";
import { CreateAdminButton } from "./create-admin-button";
import { adminRolesMap } from "./lib";
import {
  ListRewardsAdminsQueryVariables,
  useListRewardsAdminsQuery,
} from "./list-rewards-admins.generated";

const ITEMS_PER_PAGE = 10;
const SEARCH_DEBOUNCE_MS = 300;

export function AdminsPageContents(): JSX.Element {
  const trackScreenRecordingEvent = useTrackScreenRecordingEvent();
  const track = useTrack();
  const navigate = useNavigate();
  const { data: permissionsQueryData } = usePermissionsQuery();
  const hasFullAccessLevel =
    permissionsQueryData?.getMyRewardsAdminUser.permissions.type ===
    RewardsAdminPermissionsType.FullAccess;
  const myOrganizationBranchesQuery = useMyBranches();
  const roleSectionEnabled = useFeatureFlag("admin-app-admin-role-temp");
  const [selectedBranchIds, setSelectedBranchIds] = useState<string[]>([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchQueryText, setSearchQueryText] = useState("");
  const debouncedSearchQueryTextChange = useDebouncedCallback(
    (value: string) => {
      setCurrentPageIndex(0);
      setSearchQueryText(value);
    },
    SEARCH_DEBOUNCE_MS
  );

  const handleSearchTermChange = (value: string) => {
    setSearchTerm(value);
    debouncedSearchQueryTextChange(value);
  };

  const adminsQueryVariables = (() => {
    const variables: ListRewardsAdminsQueryVariables = {
      offset: currentPageIndex * ITEMS_PER_PAGE,
      limit: ITEMS_PER_PAGE,
      branchIds: selectedBranchIds.length > 0 ? selectedBranchIds : undefined,
      searchQuery: searchQueryText || undefined,
    };

    return variables;
  })();

  const listRewardsAdminsQuery = useListRewardsAdminsQuery({
    variables: adminsQueryVariables,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only", // prevent from reloading query automatically after a mutation
    onError: reportError,
  });

  if (listRewardsAdminsQuery.error || myOrganizationBranchesQuery.error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }
  const listLoading = listRewardsAdminsQuery.loading;
  const total = listRewardsAdminsQuery.data?.listRewardsAdmins?.total;
  const items = listRewardsAdminsQuery.data?.listRewardsAdmins?.items ?? [];

  const organizationHasBranches =
    (myOrganizationBranchesQuery.data?.getMyRewardsOrganization.branches ?? [])
      .length > 0;

  const EditOrOpenIcon = hasFullAccessLevel ? EditIcon : OpenIcon;

  return (
    <>
      <HeaderWithButton
        header={<AdminsHeader />}
        button={<CreateAdminButton disabled={!hasFullAccessLevel} />}
      />
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            align-items: center;
            & > *:not(:first-of-type) {
              margin-left: ${theme.spacing(2)};
            }
          `}
        >
          <TextField
            css={css`
              width: 312px;
              margin-top: auto;
              margin-bottom: auto;
            `}
            disableAutocomplete
            hideLabel
            hideSpaceForErrorText
            label="Search"
            placeholder="Search by name"
            size="small"
            type="search"
            value={searchTerm}
            onChange={(e) => {
              handleSearchTermChange(e.target.value);
            }}
          />
          {
            <BranchFilter
              value={selectedBranchIds ?? [null]}
              onChange={(values) => setSelectedBranchIds(values as string[])}
              resourceName="Admins"
              disableNoBranchesOption
              onOpen={() => {
                trackScreenRecordingEvent("admin_branch_filter_opened");
                track("Opened admin branch filter");
              }}
            />
          }
        </div>
      </div>

      <DataTable
        itemsPerPage={ITEMS_PER_PAGE}
        currentPageIndex={currentPageIndex}
        onCurrentPageIndexChange={setCurrentPageIndex}
        emptyText="No admins found"
        loading={listLoading}
        items={items}
        total={total}
        tableHeaders={
          <TableRow>
            <TableHeader>
              <Typography variant="body">Name</Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="body">Status</Typography>
            </TableHeader>
            {roleSectionEnabled && (
              <TableHeader>
                <Typography variant="body">Role</Typography>
              </TableHeader>
            )}
            <TableHeader>
              <Typography variant="body">Job title</Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="body">Email</Typography>
            </TableHeader>
            {organizationHasBranches && (
              <TableHeader>
                <Typography variant="body">Branches</Typography>
              </TableHeader>
            )}
            <TableHeader>
              <Typography variant="body">Access level</Typography>
            </TableHeader>
            <TableHeader align="right">
              <Typography variant="body">Modify</Typography>
            </TableHeader>
          </TableRow>
        }
        tableBody={items.map((adminReference) => {
          return (
            <TableRow key={adminReference.id}>
              <TableCell divider>
                <ObscureRecordedText>
                  <Typography variant="body" color="textSecondary">
                    {adminReference.firstName} {adminReference.lastName}
                  </Typography>
                </ObscureRecordedText>
              </TableCell>
              <TableCell divider>
                <Typography variant="body" color="textSecondary">
                  {adminReference.active ? "Active" : "De-activated"}
                </Typography>
              </TableCell>
              {roleSectionEnabled && (
                <TableCell divider>
                  <ObscureRecordedText>
                    <Typography variant="body" color="textSecondary">
                      {adminReference.role
                        ? adminRolesMap[adminReference.role]
                        : "-"}
                    </Typography>
                  </ObscureRecordedText>
                </TableCell>
              )}
              <TableCell divider>
                <Typography variant="body" color="textSecondary">
                  {adminReference.jobTitle ?? "-"}
                </Typography>
              </TableCell>
              <TableCell divider>
                <Typography variant="body" color="textSecondary">
                  {adminReference.email}
                </Typography>
              </TableCell>
              {organizationHasBranches && (
                <TableCell divider>
                  <Typography variant="body" color="textSecondary">
                    {adminReference.branches.length > 0
                      ? adminReference.branches
                          .map(({ name }) => name)
                          .join(", ")
                      : "All"}
                  </Typography>
                </TableCell>
              )}
              <TableCell divider>
                {(() => {
                  let Component: OverridableComponent<SvgIconTypeMap>;
                  let label: string;

                  if (
                    adminReference.permissions.type ===
                    RewardsAdminPermissionsType.FullAccess
                  ) {
                    Component = VerifiedUserOutlinedIcon;
                    label = "Full Access";
                  } else {
                    Component = LockOutlinedIcon;
                    label = "Restricted";
                  }

                  return (
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                      `}
                    >
                      <Component
                        fontSize="small"
                        color="disabled"
                        css={(theme: AppTheme) => css`
                          margin-right: ${theme.spacing(0.5)};
                        `}
                      />
                      <Typography
                        display="inline"
                        variant="body"
                        color="textSecondary"
                      >
                        {label}
                      </Typography>

                      {adminReference.permissions.__typename ===
                        "RewardsAdminRestrictedAccessPermissions" && (
                        <Tooltip
                          title={
                            <AdminRestrictedPermissionsInfoTooltip
                              permissions={
                                (adminReference.permissions as RewardsAdminRestrictedAccessPermissions)
                                  .permissionsV2!
                              }
                            />
                          }
                          css={(theme: AppTheme) => css`
                            margin-left: ${theme.spacing(1)};
                            align-items: center;
                            justify-content: center;
                          `}
                        >
                          <InfoIcon
                            css={css`
                              opacity: 0.1;
                              display: flex;
                            `}
                          />
                        </Tooltip>
                      )}
                    </div>
                  );
                })()}
              </TableCell>
              <TableCell divider align="right">
                <IconButton
                  css={css`
                    margin-top: -14px;
                    margin-bottom: -14px;
                  `}
                  onClick={() => {
                    navigate(`/admins/${adminReference.id}/edit`);
                  }}
                  aria-label="Modify"
                >
                  <EditOrOpenIcon
                    css={css`
                      height: 0.8em;
                      width: 0.8em;
                    `}
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      />
    </>
  );
}
