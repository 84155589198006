import {
  Control,
  FieldValues,
  Path,
  useFormState,
  get,
  useWatch,
} from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";
import { isEmail } from "@rewards-web/shared/lib/is-email";

interface EmployeeWorkEmailFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  personalPhoneNumberName: Path<T>;
  personalEmailName: Path<T>;
}

export function EmployeeWorkEmailField<T extends FieldValues>({
  control,
  name,
  personalEmailName,
  personalPhoneNumberName,
}: EmployeeWorkEmailFieldProps<T>) {
  const formState = useFormState({ control, name });
  const personalPhoneNumber = useWatch({
    control,
    name: personalPhoneNumberName,
  });
  const personalEmail = useWatch({ control, name: personalEmailName });

  return (
    <TextField
      type="email"
      label="Work email"
      disableAutocomplete
      error={get(formState.errors, name)}
      {...control.register(name, {
        validate: (value: string) => {
          if (value && !isEmail(value)) {
            return "This is not a valid email";
          }
          if (!personalPhoneNumber && !personalEmail && !value) {
            return "Work email is required if no personal email OR phone number is provided";
          }
        },
      })}
    />
  );
}
