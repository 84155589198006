/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import MailIcon from "@material-ui/icons/Mail";
import { formatRelative } from "date-fns";
import { useState } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import * as GraphQLTypes from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { CandidateDetailsTable } from "../candidate-details-table";
import { useCandidateResumeDownloadLinkQuery } from "./candidate-resume-download-link.generated";
import { ConfirmSendResumeReminderModal } from "./confirm-send-resume-reminder-modal";
import { UploadResumeModal } from "./upload-resume-modal";

interface CandidateResumeDetailsProps {
  onSentResume(): void;
  onUploadedResume(): void;
  hasEditPermission: boolean;
  candidate: Pick<
    GraphQLTypes.Candidate,
    "id" | "hasUploadedResume" | "archived"
  > & {
    resumeReminders: Array<
      { __typename?: "CandidateResumeReminder" } & Pick<
        GraphQLTypes.CandidateResumeReminder,
        "id" | "sentAt"
      >
    >;
  };
}

export function CandidateResumeDetails({
  candidate,
  onSentResume,
  onUploadedResume,
  hasEditPermission,
}: CandidateResumeDetailsProps): JSX.Element {
  const resumeDownloadLinkQuery = useCandidateResumeDownloadLinkQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    variables: { candidateId: candidate.id },
    onError: reportError,
    pollInterval: 1000 * 60 * 10, // refresh every 10 minutes, since the resume download link expires every 15 minutes
  });

  const [uploadResumeModalOpen, setUploadResumeModalOpen] = useState(false);
  const [
    confirmSendResumeReminderModalOpen,
    setConfirmSendResumeReminderModalOpen,
  ] = useState(false);

  return (
    <>
      <CandidateDetailsTable
        items={[
          {
            label: "Status",
            value: (
              <Typography variant="body" align="right">
                {candidate.hasUploadedResume ? (
                  <>Provided</>
                ) : (
                  <>Not provided yet</>
                )}
              </Typography>
            ),
          },
          ...candidate.resumeReminders.map((reminder, idx) => ({
            label: `Reminder ${idx + 1}`,
            value: `Email sent ${formatRelative(
              new Date(reminder.sentAt),
              new Date()
            )}`,
          })),
        ]}
      />
      {!candidate.hasUploadedResume && !candidate.archived && (
        <>
          <Button
            css={(theme: AppTheme) => css`
              margin-top: ${theme.spacing(2)};
              margin-right: ${theme.spacing(2)};
            `}
            color="primary"
            width="auto"
            label="Upload Resume"
            size="medium"
            disabled={!hasEditPermission}
            onClick={() => {
              setUploadResumeModalOpen(true);
            }}
          />

          <Button
            css={(theme: AppTheme) => css`
              margin-top: ${theme.spacing(2)};
              margin-left: auto;
            `}
            color="tertiary"
            width="auto"
            startIcon={<MailIcon />}
            label="Send Reminder"
            size="medium"
            disabled={!hasEditPermission}
            onClick={() => {
              setConfirmSendResumeReminderModalOpen(true);
            }}
          />

          <ConfirmSendResumeReminderModal
            candidateId={candidate.id}
            open={confirmSendResumeReminderModalOpen}
            onClose={() => {
              setConfirmSendResumeReminderModalOpen(false);
            }}
            onSentResume={onSentResume}
          />
        </>
      )}
      {candidate.hasUploadedResume && (
        <>
          <Button
            css={(theme: AppTheme) => css`
              margin-top: ${theme.spacing(2)};
              margin-right: ${theme.spacing(1)};
              padding-left: ${theme.spacing(1.5)};
              padding-right: ${theme.spacing(1.5)};
            `}
            color="primary"
            width="auto"
            label="Update Resume"
            size="medium"
            disabled={!hasEditPermission}
            onClick={() => {
              setUploadResumeModalOpen(true);
            }}
          />

          <Button
            css={(theme: AppTheme) => css`
              margin-top: ${theme.spacing(2)};
              margin-left: auto;
              padding-left: ${theme.spacing(1.5)};
              padding-right: ${theme.spacing(1.5)};
            `}
            target="_blank"
            linkTo={
              resumeDownloadLinkQuery.data?.getCandidateById
                ?.temporaryResumeDownloadLink ?? undefined
            }
            download
            color="tertiary"
            width="auto"
            startIcon={<DownloadIcon />}
            label="Download Resume"
            size="medium"
            loading={resumeDownloadLinkQuery.loading}
          />
        </>
      )}

      <UploadResumeModal
        candidateId={candidate.id}
        open={uploadResumeModalOpen}
        hasUploadedResume={candidate.hasUploadedResume}
        onClose={() => {
          setUploadResumeModalOpen(false);
        }}
        onUploadedResume={() => {
          onUploadedResume();
          resumeDownloadLinkQuery.refetch();
        }}
      />
    </>
  );
}
