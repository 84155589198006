import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type TotalOrgPointsAwardedStatQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TotalOrgPointsAwardedStatQuery = (
  { __typename?: 'Query' }
  & { getRewardsOrganizationStats?: Types.Maybe<(
    { __typename?: 'RewardsOrganizationStats' }
    & Pick<Types.RewardsOrganizationStats, 'id' | 'totalPointsAwarded'>
  )> }
);


export const TotalOrgPointsAwardedStatDocument = gql`
    query TotalOrgPointsAwardedStat {
  getRewardsOrganizationStats {
    id
    totalPointsAwarded
  }
}
    `;

/**
 * __useTotalOrgPointsAwardedStatQuery__
 *
 * To run a query within a React component, call `useTotalOrgPointsAwardedStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalOrgPointsAwardedStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalOrgPointsAwardedStatQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotalOrgPointsAwardedStatQuery(baseOptions?: Apollo.QueryHookOptions<TotalOrgPointsAwardedStatQuery, TotalOrgPointsAwardedStatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TotalOrgPointsAwardedStatQuery, TotalOrgPointsAwardedStatQueryVariables>(TotalOrgPointsAwardedStatDocument, options);
      }
export function useTotalOrgPointsAwardedStatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TotalOrgPointsAwardedStatQuery, TotalOrgPointsAwardedStatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TotalOrgPointsAwardedStatQuery, TotalOrgPointsAwardedStatQueryVariables>(TotalOrgPointsAwardedStatDocument, options);
        }
export type TotalOrgPointsAwardedStatQueryHookResult = ReturnType<typeof useTotalOrgPointsAwardedStatQuery>;
export type TotalOrgPointsAwardedStatLazyQueryHookResult = ReturnType<typeof useTotalOrgPointsAwardedStatLazyQuery>;
export type TotalOrgPointsAwardedStatQueryResult = Apollo.QueryResult<TotalOrgPointsAwardedStatQuery, TotalOrgPointsAwardedStatQueryVariables>;