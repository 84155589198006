import * as React from "react";

function FullAccessIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={37}
      height={44}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.834 16.732c-4.606 0-8.366-3.76-8.366-8.366S12.228 0 16.834 0 25.2 3.76 25.2 8.366c0 4.608-3.758 8.366-8.366 8.366zm0-14.612c-3.442 0-6.249 2.807-6.249 6.248 0 3.442 2.807 6.249 6.249 6.249 3.441 0 6.248-2.807 6.248-6.249 0-3.441-2.807-6.248-6.248-6.248zM36.16 23.477a21.44 21.44 0 01-7.713-3.667l-.78-.588a1.112 1.112 0 00-1.334 0l-.778.585a21.48 21.48 0 01-7.711 3.667c-.497.123-.845.57-.844 1.081v5.134c.01 4.11 1.945 7.979 5.228 10.453l4.105 3.08c.395.296.939.296 1.334 0l4.111-3.08A13.13 13.13 0 0037 29.688v-5.133c0-.51-.347-.954-.84-1.078zm-1.382 6.212a10.9 10.9 0 01-4.333 8.677L27 40.944l-3.445-2.578a10.899 10.899 0 01-4.333-8.677v-4.283a23.636 23.636 0 007.667-3.822L27 21.5l.111.084a23.635 23.635 0 007.667 3.822v4.283z"
        fill={props.color}
      />
      <path
        d="M24.452 30.436a1.11 1.11 0 00-1.57 1.571l2.221 2.222a1.11 1.11 0 001.57 0l4.446-4.445v.002a1.11 1.11 0 00-1.571-1.571l-3.66 3.658-1.436-1.437zM1.533 43.314a1.061 1.061 0 01-1.059-1.059v-9.373c0-7.094 5.77-12.867 12.867-12.867.583 0 1.059.476 1.059 1.06 0 .582-.476 1.058-1.06 1.058-5.929 0-10.748 4.82-10.748 10.749v9.371c0 .583-.476 1.061-1.059 1.061z"
        fill={props.color}
      />
    </svg>
  );
}

export default FullAccessIcon;
