import { stripNonNumericCharacters } from "./strip-non-numeric-characters";

/**
 * e.g. converts "(123) 445-5566" to "+11234455566"
 */
export function serializePhoneNumber(phoneNumber: string): string {
  const numericCharacters = stripNonNumericCharacters(phoneNumber);
  if (numericCharacters.length === 10) {
    return `+1${numericCharacters}`;
  } else if (numericCharacters.length === 11 && numericCharacters[0] === "1") {
    return `+${numericCharacters}`;
  } else {
    return numericCharacters; // fallback
  }
}
