import { SVGProps } from "react";

export function StatsBriefcaseIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      {...props}
    >
      <path
        fill={props.fill}
        d="m9.552 12.41-1.234.01-.012-1.65h1.246v1.64Zm-2.723-1.688a43.334 43.334 0 0 1-5.55-.605.304.304 0 0 0-.347.296v4.555c0 1.235.998 2.238 2.233 2.243h11.538a2.242 2.242 0 0 0 2.233-2.243v-4.555a.3.3 0 0 0-.109-.23.3.3 0 0 0-.245-.066c-1.835.32-3.689.52-5.549.603V12.42a1.475 1.475 0 0 1-1.478 1.478H8.306a1.477 1.477 0 0 1-1.477-1.478V10.722ZM.001 7.862v-2.18c0-.987.8-1.788 1.788-1.788h3.227V2.523a2.29 2.29 0 0 1 2.29-2.29h3.258a2.289 2.289 0 0 1 2.289 2.29v1.371h3.225c.988 0 1.788.8 1.788 1.788v2.18a.6.6 0 0 1-.476.588c-2.784.56-5.617.84-8.456.832A42.244 42.244 0 0 1 .478 8.45a.6.6 0 0 1-.476-.588Zm6.923-3.968h4.02v-1.37a.372.372 0 0 0-.373-.371H7.304a.372.372 0 0 0-.372.372l-.007 1.37Z"
      />
    </svg>
  );
}
