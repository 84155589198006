/** @jsxImportSource @emotion/react */
import { Typography } from "@rewards-web/shared/components/typography";
import { SurveyQuestionType } from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";

import { SurveyQuestionTypeSupportedByUI } from "../constants";

export function QuestionCommentText({
  questionType,
  variant,
}: {
  questionType: SurveyQuestionTypeSupportedByUI;
  variant?: Parameters<typeof Typography>[0]["variant"];
}) {
  const questionText = (() => {
    switch (questionType) {
      case SurveyQuestionType.MeaningfulRecognition:
        return "What moment in the last month made you feel proud to work here?";
      case SurveyQuestionType.ManageableWorkload:
        return "What would make your workload better?";
      case SurveyQuestionType.ReliableTransportation:
        return "What would make your transportation experience better?";
      case SurveyQuestionType.LeadershipSupport:
        return "What specific actions or behavior from your supervisor would make you feel more supported?";
      case SurveyQuestionType.Resources:
        return "Is there anything else you need to do your job better?";
      case SurveyQuestionType.Nps:
        // No associated comments for NPS
        return null;
      default:
        assertNever(questionType);
    }
  })();

  return (
    <Typography variant={variant ?? "h2"} fontWeight={700}>
      {questionText}
    </Typography>
  );
}
