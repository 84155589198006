/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface SharesAndPointsGroupProps {
  title: string;
  textTotal?: string | JSX.Element;
  icon: JSX.Element;
  total: string;
}

export function SharesAndPointsGroup({
  title,
  icon,
  total,
  textTotal,
}: SharesAndPointsGroupProps): JSX.Element {
  return (
    <div
      css={(theme: AppTheme) =>
        css`
          display: inline-flex;
          place-items: center;
          margin-bottom: ${theme.spacing(1.5)};
        `
      }
    >
      <div
        css={(theme: AppTheme) => css`
          min-width: 100px;
          height: 50px;
          border-width: 1px;
          border-color: ${theme.palette.divider};
          margin-right: ${theme.spacing(2)};
          border-style: solid;
          padding: ${theme.spacing(1.5)};
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <Typography
          variant="h3"
          display="inline"
          css={(theme: AppTheme) =>
            css`
              color: ${theme.palette.text.secondary};
            `
          }
        >
          {total}
        </Typography>
      </div>
      {icon}
      <div
        css={(theme: AppTheme) =>
          css`
            margin-left: ${theme.spacing(2)};
          `
        }
      >
        <Typography
          css={css`
            font-family: "Montserrat";
          `}
          variant="body"
        >
          {title}
        </Typography>
        {textTotal && (
          <Typography
            css={(theme: AppTheme) =>
              css`
                color: ${theme.palette.text.secondary};
                font-size: 0.9em;
                font-family: "Montserrat";
              `
            }
            variant="body"
          >
            {textTotal}
          </Typography>
        )}
      </div>
    </div>
  );
}
