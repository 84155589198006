/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Card } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

import { QuestionMarkIcon } from "../question-mark-icon";

export interface InfoCardProps {
  title: string;
  subtitle?: string;
  children?: JSX.Element;
}

export function InfoCard({ title, subtitle, children }: InfoCardProps) {
  return (
    <Card
      backgroundColor="grey"
      variant="flat"
      css={(appTheme: AppTheme) =>
        css`
          margin-top: ${appTheme.spacing(3.75)};
          padding: ${appTheme.spacing(2.5)} ${appTheme.spacing(3.75)};
        `
      }
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <QuestionMarkIcon />
        <Typography
          css={(appTheme: AppTheme) => css`
            font-weight: 600;
            margin-left: ${appTheme.spacing(1)};
          `}
          color="textPrimary"
        >
          {title}
        </Typography>
      </div>
      <Typography
        css={(appTheme: AppTheme) => css`
          margin-top: ${appTheme.spacing(0.5)};
        `}
        color="textPrimary"
      >
        {subtitle}
      </Typography>
      {children}
    </Card>
  );
}
