/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  ArrayPath,
  Control,
  Controller,
  FieldValues,
  useFieldArray,
} from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { IconButton } from "@rewards-web/shared/components/icon-button";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { isEmail } from "@rewards-web/shared/lib/is-email";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { PlusIcon } from "./plus-icon";
import { TrashIcon } from "./trash-icon";

export type RedemptionSummaryNotificationEmailValue = { email: string };

export const DEFAULT_RECIPIENT = { email: "" };
/**
 * Limit on backend
 */
const MAX_ALLOWED_NOTIFICATION_EMAILS = 5;
const SUPPORT_ARTICLE_URL =
  "https://support.caribou.care/hc/en-us/articles/15620118972183-Settings-page";

export function RedemptionSummaryDescription() {
  return (
    <Typography
      color="textSecondary"
      css={(appTheme: AppTheme) =>
        css`
          margin-bottom: ${appTheme.spacing(3.75)};
        `
      }
    >
      A weekly payroll redemption report is sent to the below email(s) on
      Mondays.
      <br />
      <br />
      The payroll redemption report contains the latest week's employee
      redemptions. Use it to adjust the employee's paycheck with their reward.
      View{" "}
      <a
        href={SUPPORT_ARTICLE_URL}
        target="_blank"
        rel="noreferrer"
        css={(theme: AppTheme) => css`
          cursor: pointer;
          color: ${theme.palette.primary.main};
          text-decoration: none;
          font-weight: bolder;
        `}
      >
        support article
      </a>{" "}
      for a sample report.
      <br />
      <br />
      Please add at least one email address, up to a maximum of five.
    </Typography>
  );
}

interface RedemptionPayrollEmailRecipientsFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: ArrayPath<T>;
}

export function RedemptionPayrollEmailRecipientsField<T extends FieldValues>({
  control,
  name,
}: RedemptionPayrollEmailRecipientsFieldProps<T>): JSX.Element {
  const { fields, append, remove } = useFieldArray({ control, name });

  return (
    <>
      {((fields as unknown) as (RedemptionSummaryNotificationEmailValue & {
        id: string;
      })[]).map((field, index) => (
        <div
          key={field.id}
          css={css`
            display: flex;
            align-items: baseline;
          `}
        >
          <Controller
            control={control}
            name={`${name}.${index}` as any}
            render={({ fieldState }) => (
              <TextField
                label={`Admin email address #${index + 1}`}
                type="email"
                error={fieldState.error}
                disableAutocomplete
                {...control.register(`${name}.${index}.email` as any, {
                  required: "A valid email is required",
                  validate: (value: string) => {
                    if (value && !isEmail(value)) {
                      return "This is not a valid email";
                    }
                  },
                })}
              />
            )}
          />

          <IconButton
            aria-label={`remove-email-${index}`}
            onClick={() => remove(index)}
            css={css`
              visibility: ${index > 0 ? "visible" : "hidden"};
              font-size: 5rem;
            `}
          >
            <TrashIcon />
          </IconButton>
        </div>
      ))}

      {fields.length < MAX_ALLOWED_NOTIFICATION_EMAILS && (
        <Button
          variant="outlined"
          size="small"
          color="primary"
          width="auto"
          label="Add another"
          startIcon={<PlusIcon />}
          onClick={() => append(DEFAULT_RECIPIENT as any)}
        />
      )}
    </>
  );
}
