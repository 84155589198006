import { useContext, createContext, ReactNode } from "react";

interface FormControlContextValue {
  submitting?: boolean;
  readOnly?: boolean;
}

export const FormControlContext = createContext<FormControlContextValue>({
  submitting: false,
  readOnly: false,
});

interface FormControlProviderProps {
  value: FormControlContextValue;
  children: ReactNode;
}

export function FormControlProvider({
  value,
  children,
}: FormControlProviderProps) {
  return (
    <FormControlContext.Provider value={value}>
      {children}
    </FormControlContext.Provider>
  );
}

export function useFormControlContext() {
  return useContext(FormControlContext);
}
