import { ResponsiveBar } from "@nivo/bar";

import { assertNever } from "@rewards-web/shared/lib/assert-never";

interface StyleConfig {
  color: string;
  pattern?: "none" | "lines";
}

export interface BarChartProps<TKey extends string> {
  keys: TKey[];
  data: [{ [key in TKey]: number | string }];
  style: { [key in TKey]: StyleConfig };
  labels: { [key in TKey]: string };
  enableTooltips?: boolean;
}

export function BarChart<T extends string>(
  props: BarChartProps<T>
): JSX.Element {
  return (
    <ResponsiveBar<BarChartProps<T>["data"][number]>
      data={props.data}
      keys={props.keys}
      enableLabel={false} // disables labels from displaying on top of bars
      isInteractive={props.enableTooltips ?? true}
      tooltipLabel={(data) => props.labels[data.id as T]}
      enableGridX={false}
      enableGridY={false}
      axisLeft={null}
      layout="horizontal"
      fill={Object.keys(props.style).map((key) => ({
        id: `${key}-style`,
        match: {
          id: key,
        },
      }))}
      colors={({ id }) => props.style[id as T].color}
      defs={(Object.entries(props.style) as Array<[string, StyleConfig]>).map(
        ([key, style]) => ({
          id: `${key}-style`,
          color: style.color,
          ...(() => {
            switch (style.pattern) {
              case undefined:
              case "none":
                return {};
              case "lines":
                return {
                  type: "patternLines",
                  rotation: -45,
                  lineWidth: 2,
                  spacing: 4,
                };
              default:
                assertNever(style.pattern);
            }
          })(),
        })
      )}
    />
  );
}
