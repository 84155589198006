/** @jsxImportSource @emotion/react */
import { ClassNames } from "@emotion/react";
import { MenuItem, OutlinedInput, Select } from "@material-ui/core";
import { times } from "lodash";

import { rawScoreText } from "../../../components/score-text";

export const DEFAULT_VALUE = "all";

export function ScoreSelectField({
  value,
  onChange,
}: {
  value: string;
  onChange: (event: React.ChangeEvent<any>) => void;
}) {
  const label = "Answer";
  const options = [
    {
      label: "All answers",
      value: DEFAULT_VALUE,
    },
    ...times(5, (i) => ({
      label: rawScoreText({ score: i + 1, includeScore: false }).toString(),
      value: (i + 1).toString(),
    })),
  ];

  return (
    <ClassNames>
      {({ css, theme }) => (
        <Select
          input={
            <OutlinedInput
              label={label}
              notched={false}
              classes={{
                root: "custom-input-root",
                notchedOutline: "custom-notched-outline",
              }}
            />
          }
          classes={{
            root: css`
              background-color: #fff;
              padding: ${theme.spacing(1)} ${theme.spacing(2)};
              height: 0.8em;
              width: 180px;

              &:focus {
                background-color: #fff;
              }
            `,
          }}
          value={value}
          onChange={onChange}
          label={label}
        >
          {options.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      )}
    </ClassNames>
  );
}
