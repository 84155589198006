/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faGift } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useApproveDrawWinnersMutation } from "./approve-draw-winners.generated";

interface ConfirmWinnersModalProps {
  drawId: string;
  winnerIds: string[];
  open: boolean;
  prizeTierIndexes?: number[];
  onClose(): void;
}

export function ConfirmWinnersModal({
  drawId,
  prizeTierIndexes,
  winnerIds,
  open,
  onClose,
}: ConfirmWinnersModalProps) {
  const track = useTrack();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [
    approveWinners,
    { loading: approvingWinners },
  ] = useApproveDrawWinnersMutation();

  const handleConfirm = async () => {
    try {
      if (winnerIds.length === 0) {
        // this shouldn't happen
        throw new Error("Winner IDs must not be empty");
      }

      await approveWinners({
        variables: {
          drawId,
          winnerIds,
          prizeTierIndexes,
        },
      });
      track("Approved draw winners", { drawId });
      snackbar.show({
        severity: "success",
        message: "Draw winner(s) have been successfully approved.",
      });
      navigate("/draw"); // no need to close modal, since it will be unmounted anyway
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      width="580px"
      disableBackdropClick={approvingWinners}
    >
      <ModalContent>
        <FontAwesomeIcon
          icon={faGift}
          size="3x"
          css={(theme: AppTheme) =>
            css`
              color: ${theme.palette.primary.main};
              display: block;
              margin: 0 auto;
              margin-bottom: ${theme.spacing(2)};
            `
          }
        />
        <Typography
          variant="h2"
          color="textPrimary"
          textAlign="center"
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(2)};
            `
          }
        >
          Confirm winner(s)?
        </Typography>
        <Typography
          variant="body"
          color="grey.800"
          textAlign="center"
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(1)};
            `
          }
        >
          Users will receive a notification to see the winner(s) of the draw.
        </Typography>
        <Typography
          variant="body"
          color="grey.800"
          textAlign="center"
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(1)};
            `
          }
        >
          Winners can claim their points in-app.
        </Typography>
      </ModalContent>
      <ModalActions>
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            margin: 0 auto;
            gap: ${theme.spacing(2)};
          `}
        >
          <Button
            variant="outlined"
            width="auto"
            size="large"
            label="Cancel"
            onClick={onClose}
            disabled={approvingWinners}
          />
          <Button
            width="auto"
            size="large"
            color="primary"
            label="Confirm"
            onClick={handleConfirm}
            loading={approvingWinners}
          />
        </div>
      </ModalActions>
    </Modal>
  );
}
