import { SVGProps } from "react";

export function InfoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 0C4.02754 0 0 4.02754 0 9C0 13.9725 4.02754 18 9 18C13.9725 18 18 13.9694 18 9C18 4.03001 13.9725 0 9 0ZM10.2878 14.0769C10.2878 14.7836 9.70828 15.3631 9.00161 15.3631C8.29494 15.3631 7.71544 14.7836 7.71544 14.0769L7.71624 8.93386C7.71624 8.22719 8.29574 7.64769 9.00241 7.64769C9.70828 7.64769 10.2886 8.22719 10.2886 8.93386V14.0769H10.2878ZM9.00161 5.20735C8.29172 5.20735 7.71544 4.63187 7.71544 3.92118C7.71544 3.2113 8.29092 2.63501 9.00161 2.63501C9.71149 2.63501 10.2878 3.21049 10.2878 3.92118C10.2878 4.63187 9.71229 5.20735 9.00161 5.20735Z"
        fill={props.fill || "#EF5998"}
      />
    </svg>
  );
}
