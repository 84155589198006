/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { camelCase, compact, upperFirst } from "lodash";
import { useEffect, useState } from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  SurveyInsightsDateRange,
  SurveyInsightsDateRangeInput,
  SurveyQuestionType,
} from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { DateRangeSelectField } from "../../components/date-range-select-field";
import { QuestionCategory } from "../../components/question-category";
import { QuestionText } from "../../components/question-text";
import { ResponseBreakdownCard } from "../../components/response-breakdown-card";
import { ScoreComparisonCard } from "../../components/score-comparison-card";
import {
  convertDateRangeToValue,
  convertValueToDateRange,
  isSurveyTypeSupportedByUI,
} from "../../utils";
import { usePulseSurveyAvailableDateRangesQuery } from "../pulse-survey-available-date-ranges.generated";
import {
  getCurrentScoreForComparison,
  getPreviousScoresForComparison,
} from "../utils";
import { EmployeeFeedbackTable } from "./employee-feedback-table";
import { useSurveyQuestionDetailsPageDataQuery } from "./question-details-page-data.generated";

export function SurveyQuestionDetailsPageContents() {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const questionType =
    SurveyQuestionType[
      // converts "question_type" to "QuestionType"
      upperFirst(
        camelCase(params.question_type!)
      ) as keyof typeof SurveyQuestionType
    ];

  const validSurveyType = isSurveyTypeSupportedByUI(questionType);

  const {
    data: dateRangeData,
    error: dateRangeError,
  } = usePulseSurveyAvailableDateRangesQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
  });

  const [selectedDateRange, setSelectedDateRange] = useState<
    SurveyInsightsDateRange | undefined
  >();

  const dateRangeInput = ((): SurveyInsightsDateRangeInput | undefined => {
    if (!selectedDateRange) {
      return undefined;
    }

    return {
      label: selectedDateRange.label,
      year: selectedDateRange.year,
    };
  })();

  const { data, error } = useSurveyQuestionDetailsPageDataQuery({
    variables: {
      questionType,
      dateRange: dateRangeInput,
    },
    onError: reportError,
    skip: !validSurveyType || !dateRangeInput,
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    // set initial date range on load
    if (dateRangeData && !selectedDateRange) {
      const searchedDateRange = searchParams.get("dateRange");

      // initialize with searched date range, or else most recent
      const dateRange = searchedDateRange
        ? convertValueToDateRange(
            searchedDateRange,
            dateRangeData.getAvailableSurveyDateRanges
          )
        : dateRangeData.getAvailableSurveyDateRanges[0];

      return setSelectedDateRange(dateRange);
    }
  }, [dateRangeData, selectedDateRange, searchParams]);

  if (!validSurveyType) {
    return <Navigate to="/surveys/pulse" replace />;
  }

  if (error || dateRangeError) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  if (!dateRangeData) {
    return <PageLoadingState />;
  }

  const previousScores = getPreviousScoresForComparison(
    data?.getSurveyQuestionResults.previousAverageScores ?? []
  );

  const currentScoreWithDateRange = getCurrentScoreForComparison(
    data?.getSurveyQuestionResults.averageScore,
    dateRangeInput
  );

  const totalResponseCount = data?.getScoreDistributionForSurveyQuestion.reduce(
    (acc, { count }) => acc + count,
    0
  );

  return (
    <>
      <Button
        variant="text"
        startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        label="Back"
        width="auto"
        typographyVariant="body"
        linkTo={`/surveys/pulse?dateRange=${convertDateRangeToValue(
          selectedDateRange
        )}`}
      />
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            margin: ${theme.spacing(3)} 0;
          `}
        >
          <QuestionCategory questionType={questionType} variant="subtitle" />
          <Typography
            variant="h4"
            fontWeight={700}
            css={(theme: AppTheme) =>
              css`
                margin-bottom: ${theme.spacing(1)};
              `
            }
          >
            Question
          </Typography>
          <QuestionText questionType={questionType} variant="h2" />
        </div>
        <div
          css={css`
            align-self: center;
          `}
        >
          <DateRangeSelectField
            value={selectedDateRange}
            options={dateRangeData?.getAvailableSurveyDateRanges ?? []}
            onChange={(dateRange: SurveyInsightsDateRange) =>
              setSelectedDateRange(dateRange)
            }
          />
        </div>
      </div>
      {data && (
        <>
          {totalResponseCount === 0 ? (
            <Alert
              severity="info"
              message="No responses received during this time period."
            />
          ) : (
            <>
              <div
                css={(theme: AppTheme) => css`
                  display: flex;
                  flex-wrap: wrap;
                  gap: ${theme.spacing(3)};
                `}
              >
                <ResponseBreakdownCard
                  questionType={questionType}
                  scores={data.getScoreDistributionForSurveyQuestion}
                />
                {previousScores?.length ? (
                  <ScoreComparisonCard
                    previousScores={compact([
                      ...previousScores,
                      currentScoreWithDateRange,
                    ])}
                    maxScore={questionType === SurveyQuestionType.Nps ? 10 : 5}
                  />
                ) : null}
              </div>
              {questionType !== SurveyQuestionType.Nps && (
                // NPS does not have associated feedback (comments)
                <EmployeeFeedbackTable
                  questionType={questionType}
                  dateRangeInput={dateRangeInput}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
