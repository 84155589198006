import { Control, FieldValues, Path, useFormState, get } from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";
import { isPhoneNumber } from "@rewards-web/shared/lib/is-phone-number";
import { stripNonNumericCharacters } from "@rewards-web/shared/lib/strip-non-numeric-characters";

interface EmployeeWorkCellFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export function EmployeeWorkCellField<T extends FieldValues>({
  control,
  name,
}: EmployeeWorkCellFieldProps<T>) {
  const formState = useFormState({ control, name });

  return (
    <TextField
      type="tel"
      label="Work cell phone"
      disableAutocomplete
      error={get(formState.errors, name)}
      {...control.register(name, {
        validate: (value: string) => {
          if (value && !isPhoneNumber(stripNonNumericCharacters(value))) {
            return "This is not a valid phone number" + value;
          }
        },
      })}
    />
  );
}
