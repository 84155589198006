import { useState } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { useDebounce } from "use-debounce";

import { SearchTextField } from "@rewards-web/shared/components/search-text-field";
import { formatPhoneNumber } from "@rewards-web/shared/lib/phone-number-format";
import { reportError } from "@rewards-web/shared/modules/error";

import { useLinkedEmployeeSearchQuery } from "./search-for-linked-employee.generated";

const SEARCH_DEBOUNCE_MS = 300;

interface LinkedEmployeeSearchFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export function LinkedEmployeeSearchField<T extends FieldValues>({
  control,
  name,
}: LinkedEmployeeSearchFieldProps<T>) {
  const [employeeSearchQuery, setEmployeeSearchQuery] = useState("");
  const [debouncedEmployeeSearchQuery] = useDebounce(
    employeeSearchQuery,
    SEARCH_DEBOUNCE_MS
  );
  const employeeSearchDataQuery = useLinkedEmployeeSearchQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
    variables: {
      searchQuery: debouncedEmployeeSearchQuery || null,
    },
  });

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: "Linked employee is required",
      }}
      render={({ field, fieldState }) => (
        <SearchTextField
          {...field}
          error={fieldState.error}
          label="Search active Caribou account to link"
          options={(
            (
              employeeSearchDataQuery.data ??
              employeeSearchDataQuery.previousData
            )?.searchForRewardsUsers ?? []
          ).map((user) => {
            const subLabels = (() => {
              if (
                user.personalContactInfo?.__typename ===
                "RewardsUserPersonalContactInfoData"
              ) {
                return [
                  user.personalContactInfo.email,
                  formatPhoneNumber(user.personalContactInfo.phoneNumber),
                ];
              }

              return [];
            })();

            return {
              value: user.id,
              label: `${user.firstName} ${user.lastName}`,
              subLabels,
            };
          })}
          loadingOptions={employeeSearchDataQuery.loading}
          onInputChange={(text) => setEmployeeSearchQuery(text)}
          debouncedInputValue={debouncedEmployeeSearchQuery}
          closedWhenInputTextEmpty
          placeholder="Type employee's name, email, or phone number"
          obscureOptionsFromRecordings
        />
      )}
    />
  );
}
