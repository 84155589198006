import MuiIconButton from "@material-ui/core/IconButton";
import { MouseEventHandler, ReactNode } from "react";

import { StylableProps } from "@rewards-web/shared/types";

interface IconButtonProps extends StylableProps {
  children: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
  "aria-label": string;
  disabled?: boolean;
}

export function IconButton({
  className,
  children,
  onClick,
  "aria-label": ariaLabel,
  disabled,
}: IconButtonProps): JSX.Element {
  return (
    <MuiIconButton
      className={className}
      aria-label={ariaLabel}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </MuiIconButton>
  );
}
