import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateRewardsUserMutationVariables = Types.Exact<{
  firstName: Types.Scalars['String'];
  lastName: Types.Scalars['String'];
  workEmail?: Types.Maybe<Types.Scalars['String']>;
  workPhoneNumber?: Types.Maybe<Types.Scalars['String']>;
  email?: Types.Maybe<Types.Scalars['String']>;
  phoneNumber?: Types.Maybe<Types.Scalars['String']>;
  assignedToUserGroupIds?: Types.Maybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  branchId?: Types.Maybe<Types.Scalars['ID']>;
  managerId?: Types.Maybe<Types.Scalars['ID']>;
}>;


export type CreateRewardsUserMutation = (
  { __typename?: 'Mutation' }
  & { createRewardsUser: (
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id'>
  ) }
);


export const CreateRewardsUserDocument = gql`
    mutation CreateRewardsUser($firstName: String!, $lastName: String!, $workEmail: String, $workPhoneNumber: String, $email: String, $phoneNumber: String, $assignedToUserGroupIds: [ID!], $branchId: ID, $managerId: ID) {
  createRewardsUser(
    firstName: $firstName
    lastName: $lastName
    workEmail: $workEmail
    workPhoneNumber: $workPhoneNumber
    email: $email
    phoneNumber: $phoneNumber
    assignedToUserGroupIds: $assignedToUserGroupIds
    branchId: $branchId
    managerId: $managerId
  ) {
    id
  }
}
    `;
export type CreateRewardsUserMutationFn = Apollo.MutationFunction<CreateRewardsUserMutation, CreateRewardsUserMutationVariables>;

/**
 * __useCreateRewardsUserMutation__
 *
 * To run a mutation, you first call `useCreateRewardsUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRewardsUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRewardsUserMutation, { data, loading, error }] = useCreateRewardsUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      workEmail: // value for 'workEmail'
 *      workPhoneNumber: // value for 'workPhoneNumber'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      assignedToUserGroupIds: // value for 'assignedToUserGroupIds'
 *      branchId: // value for 'branchId'
 *      managerId: // value for 'managerId'
 *   },
 * });
 */
export function useCreateRewardsUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateRewardsUserMutation, CreateRewardsUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRewardsUserMutation, CreateRewardsUserMutationVariables>(CreateRewardsUserDocument, options);
      }
export type CreateRewardsUserMutationHookResult = ReturnType<typeof useCreateRewardsUserMutation>;
export type CreateRewardsUserMutationResult = Apollo.MutationResult<CreateRewardsUserMutation>;
export type CreateRewardsUserMutationOptions = Apollo.BaseMutationOptions<CreateRewardsUserMutation, CreateRewardsUserMutationVariables>;