import { ClassNames } from "@emotion/react";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { TextField } from "@rewards-web/shared/components/text-field";
import { useTrackScreenRecordingEvent } from "@rewards-web/shared/modules/analytics";

interface CandidateSearchBarsProps {
  value: string;
  disabled?: boolean;
  onChange(value: string): void;
}

export function CandidateSearchBar({
  value,
  onChange,
  disabled = false,
}: CandidateSearchBarsProps): JSX.Element {
  const trackScreenRecordingEvent = useTrackScreenRecordingEvent();

  // store local search query string which will control the input, and not be debounced
  const [localSearchValue, setLocalSearchValue] = useState<string>(value);

  // update value in parent
  const updateWithDebounce = useDebouncedCallback((searchText) => {
    trackScreenRecordingEvent("candidates_search_updated");
    onChange(searchText ?? "");
  }, 350);

  // update local search query string if the parent changes
  useEffect(() => {
    setLocalSearchValue(value);
  }, [value, setLocalSearchValue]);

  return (
    <ClassNames>
      {({ css, theme }) => {
        return (
          <div
            className={css`
              ${disabled &&
              css`
                border-color: ${theme.palette.divider};
              `}
              display: flex;
              flex-direction: row;
              height: 48px;
              margin-bottom: ${theme.spacing(4)};
              max-width: 800px;
            `}
          >
            <TextField
              label="Search"
              type="search"
              hideLabel
              placeholder="Search by name or job title"
              value={localSearchValue}
              readOnly={disabled}
              onChange={(e) => {
                const { value: nextValue } = e.target;
                setLocalSearchValue(nextValue);
                updateWithDebounce(nextValue);
              }}
              exposeToHotjar
              onFocus={() =>
                trackScreenRecordingEvent("candidates_search_field_focused")
              }
              size="medium"
              hideSpaceForErrorText
              disableAutocomplete
              className={css`
                margin-bottom: 0px;

                & .custom-root {
                  height: 100%;
                  border-radius: ${theme.spacing(1)};
                }
                & input {
                  height: auto;
                }
              `}
            />
          </div>
        );
      }}
    </ClassNames>
  );
}
