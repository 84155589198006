import {
  Control,
  FieldValues,
  Path,
  Controller,
  useWatch,
} from "react-hook-form";

import {
  SelectField,
  ValueLabel,
} from "@rewards-web/shared/components/select-field";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { reportError } from "@rewards-web/shared/modules/error";
import { ErrorPage } from "@rewards-web/shared/pages/error";

import { useOrganizationRedemptionThresholdQuery } from "./organization-redemption-threshold.generated";

export const OPTIONS_SET = new Set([1000, 1500, 2000, 2500]);
export const DESCRIPTION_TEXT =
  "These are the minimum points needed by the employee before they can redeem their points.";

export const RECOMMENDATION_TEXT =
  "Having a lower redemption threshold means caregivers can more quickly cash out rewards and redeem, but might also create additional work for pay processing if many caregivers are frequently redeeming small amounts. We recommend $15 as a starting point!";

interface RedemptionThresholdFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export function RedemptionThresholdField<T extends FieldValues>({
  control,
  name,
}: RedemptionThresholdFieldProps<T>) {
  const { data } = useOrganizationRedemptionThresholdQuery({
    onError: reportError,
  });

  const minimumPointsNeededToRedeemValue = useWatch({
    control,
    name,
  });

  if (!data) {
    return <ErrorPage />;
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <SelectField
          {...field}
          error={fieldState.error}
          width="full"
          label="Minimum redemption"
          name="minimumPointsNeededToRedeem"
          options={generateOptions(
            data.getMyRewardsOrganization.pointsPerDollar,
            parseInt(minimumPointsNeededToRedeemValue)
          )}
          disabled={
            !OPTIONS_SET.has(parseInt(minimumPointsNeededToRedeemValue))
          }
        />
      )}
    />
  );
}

/**
 * Returns all supported and custom values for SelectField Component
 *
 * @param pointsPerDollar to derive correct dollar value in label
 * @param value to derive correct points value
 * @returns ValueLabel[] for SelectField Component
 */
export function generateOptions(
  pointsPerDollar: number,
  value?: number
): ValueLabel[] {
  const deduplicatedOptions = value
    ? new Set([...Array.from(OPTIONS_SET), value])
    : OPTIONS_SET;

  return Array.from(deduplicatedOptions).map((option) => ({
    value: option.toString(),
    label: `${numberWithCommas(option)} points (${formatDollars(
      option / pointsPerDollar
    )})`,
  }));
}
