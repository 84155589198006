import { SVGProps } from "react";

export function StatsPageIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={19}
      fill="none"
      {...props}
    >
      <path
        fill={props.fill}
        fillRule="evenodd"
        d="M15.585.765a1.288 1.288 0 0 0-.911-.377H1.816a1.285 1.285 0 0 0-1.26 1.288V17.1a1.286 1.286 0 0 0 1.26 1.288h12.858a1.288 1.288 0 0 0 1.288-1.288V1.676a1.305 1.305 0 0 0-.377-.911Zm-9.27 3.476h3.86a.648.648 0 0 1 0 1.288h-3.86a.648.648 0 0 1 0-1.288Zm1.93 9h-4.5a.646.646 0 0 1 0-1.288h4.5a.647.647 0 0 1 0 1.287Zm4.5-3.218h-9a.641.641 0 1 1 0-1.283h9a.641.641 0 1 1 0 1.283Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
