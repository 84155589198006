/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Controller, useForm, useWatch } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalErrorState } from "@rewards-web/shared/components/modal/modal-error-state";
import { ModalLoadingState } from "@rewards-web/shared/components/modal/modal-loading-state";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { RadioButton } from "@rewards-web/shared/components/radio-buttons";
import { RadioGroup } from "@rewards-web/shared/components/radio-buttons/radio-group";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { useCloseJobPostingMutation } from "./close-job-posting.generated";
import { useJobPostingToCloseQuery } from "./job-posting-to-close.generated";

enum ArchiveCandidateOption {
  yes = "yes",
  no = "no",
}

interface ConfirmCloseJobFormValues {
  /**
   * Feature flag to temporarily disable this feature
   * (it will be re-enabled in a sprint or two)
   */
  archiveCandidates: ArchiveCandidateOption | null;
}

export interface ConfirmCloseJobModalProps {
  onClose(): void;
  onSuccess(): void;
  jobPostingId: string | null;
  getDescription(jobPostingTitle: string): string;
}

export function ConfirmCloseJobModal({
  onClose,
  onSuccess,
  jobPostingId,
  getDescription,
}: ConfirmCloseJobModalProps): JSX.Element {
  const track = useTrack();
  const snackbar = useSnackbar();
  const query = useJobPostingToCloseQuery({
    onError: reportError,
    skip: !jobPostingId,
    fetchPolicy: "network-only",
    variables: {
      jobPostingId: jobPostingId!,
    },
  });

  const form = useForm<ConfirmCloseJobFormValues>({
    defaultValues: {
      archiveCandidates: null,
    },
  });

  const selectedYes =
    useWatch({ control: form.control, name: "archiveCandidates" }) ===
    ArchiveCandidateOption.yes;

  const [closeJobPosting, { loading: closing }] = useCloseJobPostingMutation();

  const handleConfirm = async (values: ConfirmCloseJobFormValues) => {
    const candidateIdsToArchive =
      values.archiveCandidates === ArchiveCandidateOption.yes
        ? query.data!.listCandidatesV2.items.map((item) => item.id)
        : [];
    try {
      await closeJobPosting({
        variables: {
          jobPostingId: jobPostingId!,
          candidateIdsToArchive,
        },
      });
      snackbar.show({ severity: "success", message: "Job posting closed." });
      onSuccess();
      onClose();
      track("Closed job posting", {
        jobPostingId,
        archivedCandidateIds: candidateIdsToArchive,
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  const content = (() => {
    if (query.error) {
      return <ModalErrorState onClose={onClose} />;
    }

    if (!query.data) {
      return <ModalLoadingState />;
    }

    if (!query.data.getJobPostingById) {
      return (
        <ModalErrorState
          onClose={onClose}
          message="This job posting does not appear to exist."
        />
      );
    }

    const canArchiveCandidates = query.data.listCandidatesV2.total > 0;

    return (
      <Form
        css={css`
          display: contents;
        `}
        onSubmit={form.handleSubmit(handleConfirm)}
        submitting={form.formState.isSubmitting}
      >
        <ModalTitle>
          {canArchiveCandidates ? "Archive candidates?" : "Close this posting?"}
        </ModalTitle>
        <ModalContent>
          {canArchiveCandidates ? (
            <Controller
              control={form.control}
              name="archiveCandidates"
              rules={{
                required: "You must select one option.",
              }}
              render={({ field: formField, fieldState }) => (
                <RadioGroup
                  row
                  ariaLabel="license"
                  error={fieldState.error}
                  label={`Do you want to automatically archive ${
                    query.data!.listCandidatesV2.total
                  } recent applicant(s) for this job posting? They will be informed that the job posting has closed.`}
                  {...formField}
                >
                  <RadioButton value={ArchiveCandidateOption.yes} label="Yes" />
                  <RadioButton value={ArchiveCandidateOption.no} label="No" />
                </RadioGroup>
              )}
            />
          ) : (
            <Typography
              variant="body"
              color="textPrimary"
              css={(theme: AppTheme) =>
                css`
                  padding-bottom: ${theme.spacing(2)};
                `
              }
            >
              {getDescription(query.data.getJobPostingById.title)}
            </Typography>
          )}
        </ModalContent>
        <ModalActions>
          <Button
            size="large"
            onClick={() => {
              onClose();
            }}
            label="Cancel"
            variant="outlined"
          />
          <Button
            size="large"
            label={
              canArchiveCandidates
                ? selectedYes
                  ? "Close & Archive Candidate(s)"
                  : "Close Job Posting"
                : "Yes"
            }
            color="primary"
            type="submit"
            loading={closing}
          />
        </ModalActions>
      </Form>
    );
  })();

  return (
    <Modal
      width="488px"
      open={!!jobPostingId}
      onClose={onClose}
      disableBackdropClick={closing}
    >
      {content}
    </Modal>
  );
}
