/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { alpha } from "@material-ui/core";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";

import {
  SelectCheckboxesField,
  SelectCheckboxesFieldOption,
} from "@rewards-web/shared/components/select-checkboxes-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useMyBranches } from "../../modules/branches/use-my-branches";

const noBranchOption = { label: "No Branch", value: "no-branch" };

interface BranchFilterProps {
  value: (string | null)[];
  onChange(branchIds: (string | null)[]): void;
  resourceName: string;
  onOpen?(): void;
  width?: number;
  disableNoBranchesOption?: boolean;
}

export function BranchFilter({
  value,
  onChange,
  resourceName,
  onOpen,
  width,
  disableNoBranchesOption,
}: BranchFilterProps) {
  const branchesQuery = useMyBranches();
  const branches = branchesQuery.data?.getMyRewardsOrganization.branches;

  const branchOptions = ((): SelectCheckboxesFieldOption[] => {
    const branchOptions: SelectCheckboxesFieldOption[] = [];

    branchOptions.push(
      ...(branches ?? []).map(
        (branch) =>
          ({
            label: branch.name,
            value: branch.id,
          } as SelectCheckboxesFieldOption)
      )
    );

    if (!disableNoBranchesOption) {
      branchOptions.push(noBranchOption);
    }

    return branchOptions;
  })();

  const filterLabel = () => {
    if (value.length === 0) {
      return `${resourceName} in: All Branches`;
    }
    if (value.length === 1) {
      return `${resourceName} in: ${
        value[0] === null
          ? noBranchOption.label
          : branchOptions.find(
              (branchOption) => branchOption.value === value[0]
            )?.label
      }`;
    }
    return `${resourceName} in: Multiple branches`;
  };

  const selectedBranchIds = value.map(
    (branchId) => branchId ?? noBranchOption.value
  );

  return (
    <SelectCheckboxesField
      css={css`
        width: ${width ?? 300}px;
      `}
      label={filterLabel()}
      options={branchOptions}
      onChange={(selectedOptions: string[]) => {
        onChange(
          selectedOptions.map((option) =>
            option === noBranchOption.value ? null : option
          )
        );
      }}
      onOpen={onOpen}
      id="branches"
      value={selectedBranchIds || []}
      leftIcon={<FilterListOutlinedIcon fontSize="small" />}
      rightAdornment={
        <Typography
          css={(theme: AppTheme) => css`
            font-weight: 800;
            padding-left: ${theme.spacing(1.5)};
            padding-bottom: 2px;
            height: 14px;
            display: flex;
            align-items: center;
            ${selectedBranchIds &&
            selectedBranchIds.length > 0 &&
            css`
              border-left: 1px solid ${alpha("#444444", 0.2)};
            `}
          `}
          color={
            selectedBranchIds && selectedBranchIds.length > 0
              ? "primary"
              : "textPrimary"
          }
          variant="body"
          align="right"
        >
          {(selectedBranchIds && selectedBranchIds.length) ||
            (branches && branches.length)}
        </Typography>
      }
    />
  );
}
