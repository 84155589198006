/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import {
  CandidateRecruitmentStep,
  CandidateRecruitmentStepName,
  CandidateRetentionStep,
  RewardsOrganizationStats,
} from "@rewards-web/shared/graphql-types";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { PageCard } from "../../../../shared/components/page-card";
import { PageCardContent } from "../../../../shared/components/page-card/page-card-content";
import { PageCardTitle } from "../../../../shared/components/page-card/page-card-title";
import { useGetOrganizationStepsQuery } from "./get-organization-steps.generated";
import { RetentionStatsGroup } from "./retention-stats-group";
import { SharesAndPointsGroup } from "./shares-and-points-group";
import TotalInterviewsIcon from "./stats-icons/interview-icon";
import OptInIcon from "./stats-icons/opt-in-icon";
import PointsRedeemedIcon from "./stats-icons/points-redeemed-icon";
import TotalApplicationsIcon from "./stats-icons/total-applications-icon";
import TotalHiresIcon from "./stats-icons/total-hires-icon";
import TotalSharesIcon from "./stats-icons/total-shares-icon";
import TotalSuccesfulInterViews from "./stats-icons/total-succesful-interviews";
import { SummaryGroup } from "./summary-group";
import { useTotalOrgPointsAwardedStatQuery } from "./total-org-points-awarded-stat.generated";

export interface OrganizationStatsProps {
  organizationStats: { __typename?: "RewardsOrganizationStats" } & Pick<
    RewardsOrganizationStats,
    | "id"
    | "totalNumberOfActiveUsers"
    | "totalNumberOfActiveUsersWhoOptedIn"
    | "totalNumberOfUsersWhoSharedAJob"
    | "totalNumberOfShares"
    | "totalNumberOfApplicants"
    | "totalNumberOfCandidatesContacted"
    | "totalNumberOfCandidatesScheduledInterview"
    | "totalNumberOfCandidatesPassedInterview"
    | "totalNumberOfCandidatesStartedWork"
    | "totalNumberOfCandidatesHired"
    | "totalNumberOfCandidatesStartedOrientation"
    | "totalNumberOfCandidatesCompletedOrientation"
    | "totalNumberOfCandidatesCompletedFirstShift"
    | "totalNumberOfRetainedCandidatesAfter3Months"
    | "totalNumberOfRetainedCandidatesAfter6Months"
    | "totalPointsRedeemed"
  >;
  usesWorkDevices: boolean;
}

export function OrganizationStats({
  organizationStats,
  usesWorkDevices,
}: OrganizationStatsProps): JSX.Element {
  const organizationStepsQuery = useGetOrganizationStepsQuery({
    fetchPolicy: "cache-first",
    onError: reportError,
  });
  // this is in a separate query since this is currently a super slow query,
  // and we don't want to block the rest of the page
  const totalOrgPointsAwardedStatQuery = useTotalOrgPointsAwardedStatQuery({
    fetchPolicy: "cache-and-network",
    onError: reportError,
  });

  if (organizationStepsQuery.loading || !organizationStepsQuery.data) {
    return <PageLoadingState />;
  }

  if (organizationStepsQuery.error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  const organizationSteps =
    organizationStepsQuery.data?.getMyRewardsOrganization.candidateSteps;

  const candidateRecruitmentSteps = organizationSteps
    .filter(
      (val): val is CandidateRecruitmentStep =>
        val.__typename === "CandidateRecruitmentStep"
    )
    .map((step) => step.stepName);

  const candidateRetentionStep = organizationSteps
    .filter(
      (val): val is CandidateRetentionStep =>
        val.__typename === "CandidateRetentionStep"
    )
    .map((step) => step.durationMonths);

  const summaryGroupSteps = [
    {
      icon: <TotalApplicationsIcon />,
      name: CandidateRecruitmentStepName.ApplicationSubmitted,
      total: organizationStats.totalNumberOfApplicants,
      title: "Total Applicants",
    },
    {
      name: CandidateRecruitmentStepName.Contacted,
      total: organizationStats.totalNumberOfCandidatesContacted,
      title: "Total Contacted",
    },
    {
      icon: <TotalInterviewsIcon />,
      name: CandidateRecruitmentStepName.InterviewScheduled,
      total: organizationStats.totalNumberOfCandidatesScheduledInterview,
      title: "Total Interviews",
    },
    {
      icon: <TotalSuccesfulInterViews />,
      name: CandidateRecruitmentStepName.InterviewSuccessful,
      total: organizationStats.totalNumberOfCandidatesPassedInterview,
      title: "Successful Interviews",
    },
    {
      icon: <TotalHiresIcon />,
      name: CandidateRecruitmentStepName.Hired,
      total: organizationStats.totalNumberOfCandidatesHired,
      title: "Total Hires",
    },
    {
      name: CandidateRecruitmentStepName.StartedWork,
      total: organizationStats.totalNumberOfCandidatesStartedWork,
      title: "Started Work",
    },
    {
      name: CandidateRecruitmentStepName.StartedOrientation,
      total: organizationStats.totalNumberOfCandidatesStartedOrientation,
      title: "Started Orientation",
    },
    {
      name: CandidateRecruitmentStepName.CompletedOrientation,
      total: organizationStats.totalNumberOfCandidatesCompletedOrientation,
      title: "Completed Orientation",
    },
    {
      name: CandidateRecruitmentStepName.CompletedFirstShift,
      total: organizationStats.totalNumberOfCandidatesCompletedFirstShift,
      title: "Completed First Shift",
    },
    // Remove steps not included in the agency
  ].filter((step) => candidateRecruitmentSteps.includes(step.name));

  return (
    <div
      css={(theme: AppTheme) => css`
        display: grid;
        margin-bottom: ${theme.spacing(4)};
        grid-template-columns:
          calc(70% - ${theme.spacing(1)})
          calc(30% - ${theme.spacing(1)});
        column-gap: ${theme.spacing(2)};
      `}
    >
      <PageCard>
        <PageCardTitle title="Summary" />

        <PageCardContent>
          <div
            css={(theme: AppTheme) =>
              css`
                margin-top: ${theme.spacing(-2)};
                display: inline-flex;
              `
            }
          >
            {summaryGroupSteps.map((step) => {
              return (
                <SummaryGroup
                  key={step.name}
                  icon={step.icon}
                  title={step.title}
                  total={numberWithCommas(step.total)}
                />
              );
            })}

            {candidateRetentionStep.length > 0 && (
              <RetentionStatsGroup
                title="Started Work"
                threeMonthsRetentionTotal={
                  candidateRetentionStep.includes(3)
                    ? numberWithCommas(
                        organizationStats.totalNumberOfRetainedCandidatesAfter3Months
                      )
                    : undefined
                }
                sixMonthsRetentionTotal={
                  candidateRetentionStep.includes(6)
                    ? numberWithCommas(
                        organizationStats.totalNumberOfRetainedCandidatesAfter6Months
                      )
                    : undefined
                }
              />
            )}
          </div>
        </PageCardContent>
      </PageCard>
      <PageCard>
        <PageCardTitle title="Shares & Points" />
        <PageCardContent>
          <div
            css={(theme: AppTheme) =>
              css`
                margin-top: ${theme.spacing(-2)};
              `
            }
          >
            <SharesAndPointsGroup
              total={numberWithCommas(organizationStats.totalPointsRedeemed)}
              icon={<PointsRedeemedIcon />}
              title="Points Redeemed"
              textTotal={
                <>
                  {typeof totalOrgPointsAwardedStatQuery.data
                    ?.getRewardsOrganizationStats?.totalPointsAwarded ===
                  "number" ? (
                    numberWithCommas(
                      totalOrgPointsAwardedStatQuery.data
                        .getRewardsOrganizationStats.totalPointsAwarded
                    )
                  ) : (
                    <Skeleton
                      width={50}
                      height={16}
                      animated
                      css={css`
                        display: inline-block;
                      `}
                    />
                  )}{" "}
                  Total points awarded
                </>
              }
            />
            <SharesAndPointsGroup
              total={numberWithCommas(
                organizationStats.totalNumberOfActiveUsersWhoOptedIn
              )}
              icon={<OptInIcon />}
              title={usesWorkDevices ? "Opt-Ins" : "Active Users"}
              textTotal={
                usesWorkDevices
                  ? `${numberWithCommas(
                      organizationStats.totalNumberOfActiveUsersWhoOptedIn
                    )} of ${numberWithCommas(
                      organizationStats.totalNumberOfActiveUsers
                    )}`
                  : undefined
              }
            />
            <SharesAndPointsGroup
              total={numberWithCommas(organizationStats.totalNumberOfShares)}
              icon={<TotalSharesIcon />}
              title="Total Shares"
              textTotal={`${numberWithCommas(
                organizationStats.totalNumberOfShares
              )} shares by ${numberWithCommas(
                organizationStats.totalNumberOfUsersWhoSharedAJob
              )} staff`}
            />
          </div>
        </PageCardContent>
      </PageCard>
    </div>
  );
}
