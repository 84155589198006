/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";

import { InsightMetricGroup } from "../../components/insight-metric-group";
import megaphoneIconUrl from "../../graphics/megaphone-icon.png";
import { ApplicantsInsightMetric } from "./insight-metrics/applicants";
import { JobSharesInsightMetric } from "./insight-metrics/job-shares";
import { NewHiresInsightMetric } from "./insight-metrics/new-hires";
import { TimeToContactInsightMetric } from "./insight-metrics/time-to-contact";
import { useReferralsInsightMetricGroupDataQuery } from "./referrals-metric-group-query.generated";

export interface ReferralsInsightMetricGroupProps {
  currentRange: {
    startDate: Date;
    endDate: Date;
  };
  previousRange: {
    startDate: Date;
    endDate: Date;
  } | null;
  referralsEnabled: boolean;
  hasContactedStep: boolean;
}

export function ReferralsInsightMetricGroup({
  currentRange,
  previousRange,
  referralsEnabled,
  hasContactedStep,
}: ReferralsInsightMetricGroupProps) {
  const theme = useTheme();
  const track = useTrack();
  const skipPreviousRangeQuery = !previousRange;

  const currentReferralQuery = useReferralsInsightMetricGroupDataQuery({
    fetchPolicy: "cache-first",
    variables: {
      startDate: currentRange.startDate.valueOf(),
      endDate: currentRange.endDate.valueOf(),
    },
  });

  const previousReferralQuery = useReferralsInsightMetricGroupDataQuery({
    fetchPolicy: "cache-first",
    variables: {
      startDate: previousRange?.startDate.valueOf(),
      endDate: previousRange?.endDate.valueOf(),
    },
    skip: skipPreviousRangeQuery,
  });

  const queriesToValidate = [
    currentReferralQuery,
    ...(skipPreviousRangeQuery ? [] : [previousReferralQuery]),
    // no need to validate previous range queries if we skip them
  ];

  if (
    queriesToValidate.some(
      (query) => !query.loading && (query.error || !query.data)
    )
  ) {
    reportError(
      new Error(
        `Empty referral metric queries after loading: ${JSON.stringify(
          queriesToValidate.map(({ error }) => ({ error }))
        )}`
      )
    );
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  const content = (() => {
    if (!referralsEnabled) {
      return (
        <Card
          css={css`
            text-align: center;
            padding: ${theme.spacing(2.25)};
          `}
          variant="outlined"
        >
          <Typography variant="h3" fontWeight={700}>
            Grow your team with referrals
          </Typography>
          <Typography marginTop={theme.spacing(1.25)} color="grey.800">
            Enable referrals to track how new hires are brought in through your
            team's connections.
          </Typography>
          <Button
            css={css`
              margin-top: ${theme.spacing(2)};
              border-radius: 100px;
              height: auto;
              padding: ${theme.spacing(1)} ${theme.spacing(2)};
            `}
            width="auto"
            variant="outlined"
            linkTo="mailto:help@caribou.care"
            externalLink
            label="Contact us"
            onClick={() =>
              track("Pressed 'Contact us' in referrals insight metric group")
            }
          />
        </Card>
      );
    }
    return (
      <>
        <JobSharesInsightMetric
          currentJobSharesInsightMetric={
            currentReferralQuery.data?.getJobSharesInsightMetric
          }
          previousJobSharesInsightMetric={
            previousReferralQuery.data?.getJobSharesInsightMetric
          }
        />
        <ApplicantsInsightMetric
          currentApplicantsInsightMetric={
            currentReferralQuery.data?.getApplicantsInsightMetric
          }
          previousApplicantsInsightMetric={
            previousReferralQuery.data?.getApplicantsInsightMetric
          }
        />
        {hasContactedStep && <TimeToContactInsightMetric />}
        <NewHiresInsightMetric
          currentNewHiresInsightMetric={
            currentReferralQuery.data?.getTotalHiresInsightMetric
          }
          previousNewHiresInsightMetric={
            previousReferralQuery.data?.getTotalHiresInsightMetric
          }
        />
      </>
    );
  })();

  return (
    <InsightMetricGroup
      label="Referrals"
      icon={<img src={megaphoneIconUrl} width={24} alt="megaphone" />}
      trackingProperties={{
        startDate: currentRange.startDate,
        endDate: currentRange.endDate,
      }}
    >
      {content}
    </InsightMetricGroup>
  );
}
