import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type BulkMarkRedemptionsAsSentToPayrollMutationVariables = Types.Exact<{
  redemptionIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type BulkMarkRedemptionsAsSentToPayrollMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'bulkMarkRedemptionsAsSentToPayroll'>
);


export const BulkMarkRedemptionsAsSentToPayrollDocument = gql`
    mutation BulkMarkRedemptionsAsSentToPayroll($redemptionIds: [ID!]!) {
  bulkMarkRedemptionsAsSentToPayroll(redemptionIds: $redemptionIds)
}
    `;
export type BulkMarkRedemptionsAsSentToPayrollMutationFn = Apollo.MutationFunction<BulkMarkRedemptionsAsSentToPayrollMutation, BulkMarkRedemptionsAsSentToPayrollMutationVariables>;

/**
 * __useBulkMarkRedemptionsAsSentToPayrollMutation__
 *
 * To run a mutation, you first call `useBulkMarkRedemptionsAsSentToPayrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkMarkRedemptionsAsSentToPayrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkMarkRedemptionsAsSentToPayrollMutation, { data, loading, error }] = useBulkMarkRedemptionsAsSentToPayrollMutation({
 *   variables: {
 *      redemptionIds: // value for 'redemptionIds'
 *   },
 * });
 */
export function useBulkMarkRedemptionsAsSentToPayrollMutation(baseOptions?: Apollo.MutationHookOptions<BulkMarkRedemptionsAsSentToPayrollMutation, BulkMarkRedemptionsAsSentToPayrollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkMarkRedemptionsAsSentToPayrollMutation, BulkMarkRedemptionsAsSentToPayrollMutationVariables>(BulkMarkRedemptionsAsSentToPayrollDocument, options);
      }
export type BulkMarkRedemptionsAsSentToPayrollMutationHookResult = ReturnType<typeof useBulkMarkRedemptionsAsSentToPayrollMutation>;
export type BulkMarkRedemptionsAsSentToPayrollMutationResult = Apollo.MutationResult<BulkMarkRedemptionsAsSentToPayrollMutation>;
export type BulkMarkRedemptionsAsSentToPayrollMutationOptions = Apollo.BaseMutationOptions<BulkMarkRedemptionsAsSentToPayrollMutation, BulkMarkRedemptionsAsSentToPayrollMutationVariables>;