/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { SubmitErrorHandler, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router";

import { Alert } from "@rewards-web/shared/components/alert";
import { Form } from "@rewards-web/shared/components/form";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  JobPostingLocationField,
  RewardsOrganizationOnboardingStepName,
} from "@rewards-web/shared/graphql-types";
import { useNavigationBlockingPrompt } from "@rewards-web/shared/hooks/use-navigation-blocking-prompt";
import { serializeUrl } from "@rewards-web/shared/lib/serialize-url";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme, fontFamily } from "@rewards-web/shared/style/theme";

import { JobPostingPreview } from "../../../../../shared/modules/settings/components/job-posting-preview";
import { SettingsJobPostingBannerImageField } from "../../../../../shared/modules/settings/fields/settings-job-posting-banner-image-field";
import { SettingsJobPostingLocationField } from "../../../../../shared/modules/settings/fields/settings-job-posting-location-field";
import { SettingsJobPostingLogoImageField } from "../../../../../shared/modules/settings/fields/settings-job-posting-logo-image";
import { SettingsShortOrganizationURLField } from "../../../../../shared/modules/settings/fields/settings-organization-url-field";
import { AdminOnboardingCard } from "../../components/onboarding-card/onboarding-card";
import { AdminOnboardingCardContent } from "../../components/onboarding-card/onboarding-card-content";
import { AdminOnboardingCardHeader } from "../../components/onboarding-card/onboarding-card-header";
import { AdminOnboardingStepActions } from "../../components/step-actions";
import { useNextOnboardingStepPath } from "../../hooks/use-next-step-path";
import { useOnboardingState } from "../../hooks/use-onboarding-state";
import {
  useOnboardingJobSettingsQuery,
  useSaveOnboardingJobSettingsMutation,
} from "./job-settings-data.generated";

interface OnboardingJobSettingsFormValues {
  jobPostingLocationField: JobPostingLocationField;
  jobPostingBannerImage: File | string | null;
  jobPostingLogoImage: File | string | null;
  websiteUrl: string;
}

const REQUIRED_FIELDS: (keyof OnboardingJobSettingsFormValues)[] = [
  "jobPostingLocationField",
  "jobPostingBannerImage",
];

export function OnboardingJobSettingsPage() {
  const onboardingState = useOnboardingState({ loadFreshData: true });

  const hasPreviouslyCompleted = onboardingState.completedSteps.has(
    RewardsOrganizationOnboardingStepName.JobSetting
  );

  const theme = useTheme();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const nextOnboardingStepPath = useNextOnboardingStepPath();
  const jobSettingsQuery = useOnboardingJobSettingsQuery({
    onError: reportError,
  });
  const [saveJobSettings] = useSaveOnboardingJobSettingsMutation();
  const [formInitialized, setFormInitialized] = useState(false);
  const form = useForm<OnboardingJobSettingsFormValues>();

  useNavigationBlockingPrompt(
    "Are you sure you want to leave this page? You will lose all unsaved changes.",
    form.formState.isDirty
  );

  const onSubmit = async (values: OnboardingJobSettingsFormValues) => {
    const hasCompletedForm = REQUIRED_FIELDS.every(
      (valueKey) => values[valueKey]
    );

    try {
      await saveJobSettings({
        variables: {
          jobPostingBannerImage:
            values.jobPostingBannerImage instanceof File
              ? values.jobPostingBannerImage
              : undefined,
          jobPostingLogoImage:
            values.jobPostingLogoImage instanceof File
              ? values.jobPostingLogoImage
              : undefined,
          jobPostingLocationField: values.jobPostingLocationField,
          websiteUrl: values.websiteUrl
            ? serializeUrl(values.websiteUrl)
            : null,
        },
      });

      if (hasCompletedForm) {
        await onboardingState.recordCompletedOnboardingStep(
          RewardsOrganizationOnboardingStepName.JobSetting
        );

        if (!nextOnboardingStepPath) {
          throw new Error("No next onboarding step path");
        }

        navigate(nextOnboardingStepPath!);
      } else {
        snackbar.show({
          severity: "warning",
          message:
            "Your progress has been saved, but there are incomplete fields.",
        });
      }
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error has occurred. Please try again later.",
      });
    }
  };

  const onSubmitWithErrors: SubmitErrorHandler<OnboardingJobSettingsFormValues> = () => {
    snackbar.show({
      severity: "error",
      message:
        "We encountered a minor error. Please review the highlighted fields and try again.",
    });
  };

  const jobPostingBannerImage = useWatch({
    control: form.control,
    name: "jobPostingBannerImage",
  });
  const jobPostingLogoImage = useWatch({
    control: form.control,
    name: "jobPostingLogoImage",
  });
  const jobPostingLocationField = useWatch({
    control: form.control,
    name: "jobPostingLocationField",
  });

  useEffect(() => {
    if (jobSettingsQuery.data) {
      form.reset({
        jobPostingLocationField:
          jobSettingsQuery.data.getMyRewardsOrganization
            .jobPostingLocationField,
        jobPostingBannerImage:
          jobSettingsQuery.data.getMyRewardsOrganization
            .themeJobPostingBannerImageUrl ?? null,
        jobPostingLogoImage:
          jobSettingsQuery.data.getMyRewardsOrganization.themeSquareLogoUrl ??
          null,
        websiteUrl:
          jobSettingsQuery.data.getMyRewardsOrganization.websiteUrl ?? "",
      });
      setFormInitialized(true);
    }
  }, [form, jobSettingsQuery.data]);

  const content = (() => {
    if (jobSettingsQuery.error || onboardingState.error) {
      return (
        <Alert
          severity="error"
          message="An unexpected error occurred. Please try again later."
        />
      );
    }

    if (!jobSettingsQuery.data || onboardingState.loading) {
      return (
        <PageLoadingState
          css={css`
            position: absolute;
          `}
        />
      );
    }

    if (!formInitialized) {
      return null;
    }

    return (
      <>
        <AdminOnboardingCardHeader title="🎨️ Job settings" />
        <AdminOnboardingCardContent>
          <div
            css={(appTheme: AppTheme) => css`
              display: flex;
              margin-bottom: ${appTheme.spacing(3.75)};
            `}
          >
            <Typography
              css={(appTheme: AppTheme) =>
                css`
                  margin-right: ${appTheme.spacing(3.75)};
                `
              }
              color="textSecondary"
            >
              When a candidate applies to one of your job postings, it's
              important that your agency's branding is well represented. First
              impressions matter!
            </Typography>
          </div>
          <SettingsJobPostingBannerImageField
            control={form.control}
            name="jobPostingBannerImage"
          />
          <SettingsJobPostingLogoImageField
            control={form.control}
            name="jobPostingLogoImage"
          />
          <SettingsJobPostingLocationField
            control={form.control}
            name="jobPostingLocationField"
          />
          <SettingsShortOrganizationURLField
            control={form.control}
            name="websiteUrl"
          />

          <Typography
            css={(theme: AppTheme) => css`
              font-family: ${fontFamily};
              padding: ${theme.spacing(2)};
              margin-top: ${theme.spacing(2)};
              margin-bottom: ${theme.spacing(2)};
            `}
            variant="h4"
          >
            Your job posting preview
          </Typography>
          <JobPostingPreview
            themeColor={
              jobSettingsQuery.data.getMyRewardsOrganization
                .themePrimaryColor ?? theme.palette.primary.main
            }
            logoImage={jobPostingLogoImage}
            bannerImage={jobPostingBannerImage}
            locationField={jobPostingLocationField}
          />
        </AdminOnboardingCardContent>
      </>
    );
  })();

  return (
    <Form
      onSubmit={form.handleSubmit(onSubmit, onSubmitWithErrors)}
      submitting={form.formState.isSubmitting}
    >
      <AdminOnboardingCard
        css={css`
          min-height: 500px;
        `}
      >
        {content}
      </AdminOnboardingCard>
      <AdminOnboardingStepActions
        disableSkip={hasPreviouslyCompleted}
        submitButtonLabel={
          hasPreviouslyCompleted ? "Save and Continue" : "Save"
        }
      />
    </Form>
  );
}
