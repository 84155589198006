// import { ThemeProvider as Mui5ThemeProvider } from "@mui/material";
import { ThemeProvider as EmotionProvider } from "@emotion/react";
import {
  CssBaseline,
  darken,
  lighten,
  MuiThemeProvider as Mui4ThemeProvider,
} from "@material-ui/core";
import { merge } from "lodash";
import { createContext, ReactNode, useContext, useMemo } from "react";

import { DeepPartial } from "../types";
import { useMui4Theme } from "./theme-mui4";
import { useMui5Theme } from "./theme-mui5";
import type { AppTheme, AppThemeProperties } from "./types";

export type { AppTheme, AppThemeProperties };

export const fontFamily = [
  "Open Sans",
  "Helvetica",
  "Arial",
  "sans-serif",
].join(",");
export const headerFontFamily = `Montserrat,${fontFamily}`;

export const DEFAULT_APP_THEME: AppThemeProperties = {
  defaultButtonSize: "medium",
  defaultTextFieldSize: "medium",
  buttonBorderRadius: 8,
  mediaQueryNoSSR: true,
  maxContentWidth: 790,
  typography: {
    allVariants: { fontFamily },
    h1: {
      fontFamily: headerFontFamily,
      fontSize: "1.9rem",
      fontWeight: 500,
    },
    h2: {
      fontFamily: headerFontFamily,
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    h3: {
      fontFamily: headerFontFamily,
      fontSize: "1.15rem",
      fontWeight: 500,
    },
    h4: {
      fontFamily: headerFontFamily,
      fontSize: "0.87rem",
      fontWeight: 500,
    },
    h5: {
      fontFamily: headerFontFamily,
      fontSize: "1.10rem",
      fontWeight: 500,
    },
    h6: {
      fontFamily: headerFontFamily,
      fontSize: "1rem",
      fontWeight: 500,
    },
    body: {
      fontFamily,
      fontSize: "0.9rem",
      fontWeight: 400,
    },
    subtitle: {
      fontWeight: 600,
      fontSize: "1.115rem", // 18px
      lineHeight: "1.688rem", // 27px
    },
    caption: {
      fontFamily,
      fontSize: "0.8rem",
      fontWeight: 400,
      color: "#6f707c",
    },
    footnote: {
      fontWeight: 400,
      fontSize: "0.85rem",
      lineHeight: "1.3rem",
    },
    buttonLarge: {
      fontFamily,
      fontSize: "0.88rem",
      fontWeight: 600,
      textTransform: "initial",
    },
    buttonSmall: {
      fontFamily,
      fontSize: "0.88rem",
      fontWeight: 600,
      textTransform: "initial",
    },
  },
  shape: {
    borderRadius: 12,
  },
  fonts: {
    main: fontFamily,
    headers: headerFontFamily,
  },
  palette: {
    primary: {
      main: "#1D51D6",
      contrastText: "#fff",
    },
    secondary: {
      main: "#EA2276",
      contrastText: "#FFFFFF",
    },
    gold: {
      light: "#FFC700",
      dark: "#FAA33D",
    },
    tertiary: {
      main: "#00C7C7",
      light: lighten("#00C7C7", 0.3),
      dark: darken("#00C7C7", 0.3),
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#EE3B3B",
    },
    warning: {
      main: "#FAA33D",
    },
    success: {
      main: "#1AB971",
    },
    background: {
      default: "#EDF4F6",
      paper: "#ffffff",
    },

    text: {
      primary: "#2C393F",
      secondary: "#747676",
    },
    grey: {
      200: "#F1F1F1",
      400: "#E5E5E5",
      600: "#B1B3B3",
      800: "#747676",
    },
    divider: "#dfe4eb",
  },
};

const AppThemeContext = createContext<AppThemeProperties>(DEFAULT_APP_THEME);

/**
 * Returns the app theme at the current point in the tree.
 */
export function useAppTheme() {
  return useContext(AppThemeContext);
}

interface AppThemeProviderProps {
  children: ReactNode;
  theme: DeepPartial<AppThemeProperties>;
  renderCssBaseline?: boolean;
}

/**
 * Provides theme overrides for the app.
 *
 * It applies the following:
 * - App theme context so we can use `useAppTheme()` to get the theme
 * - Replaces the emotion theme with the theme, so we can access it when styling
 * - Converts it to an MUI4 theme and applies the MUI provider for MUI components
 */
export function AppThemeProvider({
  children,
  theme: themeProp,
  renderCssBaseline,
}: AppThemeProviderProps) {
  const parentTheme = useAppTheme();
  const theme = useMemo(() => merge({}, parentTheme, themeProp), [
    parentTheme,
    themeProp,
  ]);
  const mui4Theme = useMui4Theme(theme);
  const mui5Theme = useMui5Theme(theme);

  return (
    <AppThemeContext.Provider value={theme}>
      <Mui4ThemeProvider theme={mui4Theme}>
        <EmotionProvider theme={mui5Theme}>
          {renderCssBaseline && <CssBaseline />}
          {children}
        </EmotionProvider>
      </Mui4ThemeProvider>
    </AppThemeContext.Provider>
  );
}
