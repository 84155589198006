import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CandidateResumeDownloadLinkQueryVariables = Types.Exact<{
  candidateId: Types.Scalars['ID'];
}>;


export type CandidateResumeDownloadLinkQuery = (
  { __typename?: 'Query' }
  & { getCandidateById?: Types.Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Types.Candidate, 'id' | 'temporaryResumeDownloadLink'>
  )> }
);


export const CandidateResumeDownloadLinkDocument = gql`
    query CandidateResumeDownloadLink($candidateId: ID!) {
  getCandidateById(candidateId: $candidateId) {
    id
    temporaryResumeDownloadLink
  }
}
    `;

/**
 * __useCandidateResumeDownloadLinkQuery__
 *
 * To run a query within a React component, call `useCandidateResumeDownloadLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateResumeDownloadLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateResumeDownloadLinkQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useCandidateResumeDownloadLinkQuery(baseOptions: Apollo.QueryHookOptions<CandidateResumeDownloadLinkQuery, CandidateResumeDownloadLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidateResumeDownloadLinkQuery, CandidateResumeDownloadLinkQueryVariables>(CandidateResumeDownloadLinkDocument, options);
      }
export function useCandidateResumeDownloadLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidateResumeDownloadLinkQuery, CandidateResumeDownloadLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidateResumeDownloadLinkQuery, CandidateResumeDownloadLinkQueryVariables>(CandidateResumeDownloadLinkDocument, options);
        }
export type CandidateResumeDownloadLinkQueryHookResult = ReturnType<typeof useCandidateResumeDownloadLinkQuery>;
export type CandidateResumeDownloadLinkLazyQueryHookResult = ReturnType<typeof useCandidateResumeDownloadLinkLazyQuery>;
export type CandidateResumeDownloadLinkQueryResult = Apollo.QueryResult<CandidateResumeDownloadLinkQuery, CandidateResumeDownloadLinkQueryVariables>;