import {
  Control,
  Controller,
  FieldValues,
  Path,
  ValidateResult,
} from "react-hook-form";

import {
  ImageField,
  ImageFieldSupportedMimeType,
} from "@rewards-web/shared/components/image-field";
import { megabytesToBytes } from "@rewards-web/shared/lib/megabytes-to-bytes";

const IMAGE_MAX_SIZE_MB = 10;
const IMAGE_FILE_TYPES: ImageFieldSupportedMimeType[] = [
  "image/png",
  "image/jpeg",
];

interface SettingsJobPostingBannerImageFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export function SettingsJobPostingBannerImageField<T extends FieldValues>({
  control,
  name,
}: SettingsJobPostingBannerImageFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (
          value: File | string | undefined
        ): ValidateResult | undefined => {
          if (value instanceof File) {
            if (
              !IMAGE_FILE_TYPES.includes(
                value.type as ImageFieldSupportedMimeType
              )
            ) {
              return `The image must be a .jpeg or .png file`;
            }

            if (value.size > megabytesToBytes(IMAGE_MAX_SIZE_MB)) {
              return `The image cannot exceed ${IMAGE_MAX_SIZE_MB} megabytes in size`;
            }
          }
        },
      }}
      render={({ field, fieldState }) => (
        <ImageField
          {...field}
          error={fieldState.error}
          height="200px"
          label="Banner"
          description="This image will appear when a candidate goes to your job posting."
        />
      )}
    />
  );
}
