/**
 * Cognito error code:
 * https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html#API_InitiateAuth_Errors
 */
export enum CognitoErrorCode {
  UserNotFoundException = "UserNotFoundException",
  NotAuthorizedException = "NotAuthorizedException",
  TooManyRequestsException = "TooManyRequestsException",
  UserNotConfirmedException = "UserNotConfirmedException",
  LimitExceededException = "LimitExceededException",
  CodeMismatchException = "CodeMismatchException",
}

export interface CognitoError {
  code: CognitoErrorCode;
  name: string;
  message: string;
}

export enum AuthErrorCode {
  UserDoesNotExist = "UserDoesNotExist",
  TooManyRequests = "TooManyRequests",
  UserNotConfirmed = "UserNotConfirmed",
  Deactivated = "Deactivated",
  NotAuthorized = "NotAuthorized",
  Unknown = "Unknown",
  IncorrectVerificationCode = "IncorrectVerificationCode",
}
