import {
  AppThemeProperties,
  DEFAULT_APP_THEME,
} from "@rewards-web/shared/style/theme";
import { DeepPartial } from "@rewards-web/shared/types";

export const adminAppTheme: DeepPartial<AppThemeProperties> = {
  defaultButtonSize: "large",
  defaultTextFieldSize: "large",
  palette: {
    primary: {
      main: "#116872",
    },
    text: {
      primary: "#2C393F",
      secondary: DEFAULT_APP_THEME.palette.grey[800],
    },
  },
};
