/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  darken,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { forwardRef, ReactNode, useEffect, useState } from "react";
import { useDropzone, ErrorCode } from "react-dropzone";

import { useFormControlContext } from "@rewards-web/shared/components/form/form-control";
import { AppTheme } from "@rewards-web/shared/style/theme";
import { ControlledFormFieldProps } from "@rewards-web/shared/types";

import { FileInputError } from "./field-input-error";
import { FileInputContents } from "./file-input-contents";
import { UploadingFileSpinner } from "./uploading-file-spinner";

interface VideoPreviewSrcReturnValues {
  loading: boolean;
  videoUrl: string | null;
  videoName: string | null;
}

export function useVideoPreviewSrc(
  video: File | string | null
): VideoPreviewSrcReturnValues {
  const [loadingSelectedVideoUrl, setLoadingSelectedVideoUrl] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState<string | null>(null);
  const [selectedVideoName, setSelectedVideoName] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (video instanceof File && video.type.startsWith("video/")) {
      setLoadingSelectedVideoUrl(true);

      const reader = new FileReader();
      reader.onload = (e) => {
        setLoadingSelectedVideoUrl(false);
        setSelectedVideoUrl(e.target!.result as string);
        setSelectedVideoName(video.name);
      };
      reader.readAsDataURL(video);
    }
  }, [video]);

  const selectedFileUrl = (() => {
    if (video instanceof File) {
      return selectedVideoUrl ?? null;
    }

    return video;
  })();

  return {
    loading: loadingSelectedVideoUrl,
    videoUrl: selectedFileUrl,
    videoName: selectedVideoName,
  };
}

export interface VideoFieldProps extends ControlledFormFieldProps {
  label: ReactNode;
  onVideoReset: () => void;
  height?: string;
  uploading?: boolean;
}

export const VideoField = forwardRef<HTMLDivElement, VideoFieldProps>(
  (
    {
      label,
      name,
      value,
      onChange,
      onVideoReset,
      uploading,
      error: errorProp,
      height = "280px",
    }: VideoFieldProps,
    ref
  ) => {
    const selectedVideo = useVideoPreviewSrc(value);
    const [selectedInvalidFile, setSelectedInvalidFile] = useState(false);
    const error =
      errorProp?.message ??
      (selectedInvalidFile
        ? "Ensure that you select a valid video file"
        : undefined);

    const { submitting, readOnly } = useFormControlContext();

    const disabled = submitting || readOnly;

    const dropzone = useDropzone({
      disabled,
      maxFiles: 1,
      // Accepting all video files to track file types attempted by the admins
      accept: "video/*",
      validator: (file) => {
        return file.type.startsWith("video/")
          ? null
          : {
              message: "Unsupported file type",
              code: ErrorCode.FileInvalidType,
            };
      },
      onDropAccepted: (files) => {
        setSelectedInvalidFile(false);
        const file = files[0];
        onChange?.(file);
      },
      onDropRejected: () => {
        setSelectedInvalidFile(true);
      },
    });

    return (
      <FormControl
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(4)};
          pointer-events: ${selectedVideo.videoUrl ? "none" : "auto"};
        `}
        disabled={disabled}
        variant="outlined"
        fullWidth
        ref={ref}
        error={!!error}
      >
        <InputLabel htmlFor={name} shrink>
          {label}
        </InputLabel>

        <OutlinedInput
          id={name}
          inputComponent={
            uploading ? UploadingFileSpinner : (FileInputContents as any)
          }
          inputProps={dropzone.getRootProps({
            selectedFileUrl: selectedVideo.videoUrl,
            loadingImage: selectedVideo.loading,
            videoName: selectedVideo.videoName,
            onReset: () => {
              setSelectedInvalidFile(false);
              onVideoReset();
            },
          })}
          label={label}
          notched
          fullWidth
          css={(theme: AppTheme) => css`
            cursor: ${selectedVideo.videoUrl ? "none" : "default"};
            pointer-events: ${selectedVideo.videoUrl ? "none" : "auto"};
            height: ${height};
            &.MuiOutlinedInput-root.Mui-disabled
              .MuiOutlinedInput-notchedOutline,
            .MuiOutlinedInput-notchedOutline {
              border-color: ${theme.palette.divider};
            }

            &:hover:not(.Mui-focused):not(.Mui-error)
              .MuiOutlinedInput-notchedOutline {
              border-color: ${darken(theme.palette.divider, 0.15)};
            }

            & .MuiOutlinedInput-notchedOutline {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }

            &.Mui-focused .MuiOutlinedInput-notchedOutline {
              border-width: 1px;
            }

            transition: background-color 0.1s ease-in-out;
            ${dropzone.isDragActive &&
            css`
              background-color: rgba(245, 245, 245, 0.7);
            `}
          `}
        />

        <input
          name={name}
          type="file"
          hidden
          {...dropzone.getInputProps()}
          css={css`
            pointer-events: ${selectedVideo.videoUrl ? "none" : "auto"};
            height: 100%;
          `}
        />

        {error && <FileInputError error={error} />}
      </FormControl>
    );
  }
);
