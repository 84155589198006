/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

interface HeaderWithButtonProps {
  header: React.ReactNode;
  button: React.ReactNode;
}

export function HeaderWithButton(props: HeaderWithButtonProps): JSX.Element {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
      `}
    >
      <div>{props.header}</div>
      <div>{props.button}</div>
    </div>
  );
}
