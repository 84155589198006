import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RewardsAdminAuthStatusQueryVariables = Types.Exact<{
  adminId: Types.Scalars['ID'];
}>;


export type RewardsAdminAuthStatusQuery = (
  { __typename?: 'Query' }
  & { getRewardsAdminAuthStatus: (
    { __typename?: 'GetRewardsAdminAuthStatusResponse' }
    & Pick<Types.GetRewardsAdminAuthStatusResponse, 'id' | 'status'>
  ) }
);


export const RewardsAdminAuthStatusDocument = gql`
    query RewardsAdminAuthStatus($adminId: ID!) {
  getRewardsAdminAuthStatus(adminId: $adminId) {
    id
    status
  }
}
    `;

/**
 * __useRewardsAdminAuthStatusQuery__
 *
 * To run a query within a React component, call `useRewardsAdminAuthStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsAdminAuthStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsAdminAuthStatusQuery({
 *   variables: {
 *      adminId: // value for 'adminId'
 *   },
 * });
 */
export function useRewardsAdminAuthStatusQuery(baseOptions: Apollo.QueryHookOptions<RewardsAdminAuthStatusQuery, RewardsAdminAuthStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RewardsAdminAuthStatusQuery, RewardsAdminAuthStatusQueryVariables>(RewardsAdminAuthStatusDocument, options);
      }
export function useRewardsAdminAuthStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RewardsAdminAuthStatusQuery, RewardsAdminAuthStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RewardsAdminAuthStatusQuery, RewardsAdminAuthStatusQueryVariables>(RewardsAdminAuthStatusDocument, options);
        }
export type RewardsAdminAuthStatusQueryHookResult = ReturnType<typeof useRewardsAdminAuthStatusQuery>;
export type RewardsAdminAuthStatusLazyQueryHookResult = ReturnType<typeof useRewardsAdminAuthStatusLazyQuery>;
export type RewardsAdminAuthStatusQueryResult = Apollo.QueryResult<RewardsAdminAuthStatusQuery, RewardsAdminAuthStatusQueryVariables>;