/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { Alert as MuiAlert } from "@material-ui/lab";
import { forwardRef, ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";

import { StylableProps } from "../../types";
import { Typography } from "../typography";

export interface AlertProps extends StylableProps {
  severity: "success" | "info" | "error" | "warning";
  title?: ReactNode;
  message: ReactNode;
  variant?: "filled";
  onClose?: () => void;
  customIcon?: ReactNode;
}

/**
 * Displays an alert message to the user
 */
export const Alert = forwardRef(
  (
    {
      className,
      title,
      severity,
      message,
      variant,
      onClose,
      customIcon,
    }: AlertProps,
    ref
  ): JSX.Element => {
    return (
      <MuiAlert
        ref={ref}
        variant={variant}
        onClose={onClose}
        icon={
          customIcon ? (
            customIcon
          ) : (
            <>
              {severity === "success" && <CheckCircleIcon fontSize="inherit" />}
              {severity === "info" && (
                <InfoOutlinedIcon
                  css={css`
                    ${!variant &&
                    css`
                      color: #616161;
                    `}
                  `}
                  fontSize="inherit"
                />
              )}
              {severity === "error" && <ErrorOutlineIcon fontSize="inherit" />}
              {severity === "warning" && (
                <ReportProblemOutlinedIcon fontSize="inherit" />
              )}
            </>
          )
        }
        css={css`
          text-align: left;
          ${severity === "info" &&
          !variant &&
          css`
            background-color: #f5f9fa;
          `}
        `}
        className={className}
        severity={severity}
      >
        {title && (
          <Typography
            variant="subtitle"
            color="textPrimary"
            css={(theme: AppTheme) => css`
              margin-top: -4px;
              margin-bottom: ${theme.spacing(1)};
            `}
          >
            {title}
          </Typography>
        )}
        <Typography
          variant="body"
          color={title && severity === "info" ? "grey.800" : undefined}
        >
          {message}
        </Typography>
      </MuiAlert>
    );
  }
);
