/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import SortIcon from "@material-ui/icons/Sort";

import { SelectCheckboxesField } from "@rewards-web/shared/components/select-checkboxes-field";
import {
  ListCandidatesV2OrderField,
  ListCandidatesV2OrderInput,
  Sort,
} from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/theme";

export interface CandidateSorterProps {
  value: ListCandidatesV2OrderInput;
  onChange(value: ListCandidatesV2OrderInput): void;
  defaultValue: ListCandidatesV2OrderInput;
  disabled?: boolean;
}

type CandidateSorterValue =
  | "APPLICATION_DATE_DESC"
  | "APPLICATION_DATE_ASC"
  | "UPDATED_DATE_DESC"
  | "UPDATED_DATE_ASC";

const CANDIDATE_SORT_OPTIONS: Array<{
  label: string;
  children: Array<{
    label: string;
    value: CandidateSorterValue;
  }>;
}> = [
  {
    label: "Application Date",
    children: [
      {
        label: "Newest First",
        value: "APPLICATION_DATE_DESC",
      },
      {
        label: "Oldest First",
        value: "APPLICATION_DATE_ASC",
      },
    ],
  },
  {
    label: "Updated Date",
    children: [
      {
        label: "Newest First",
        value: "UPDATED_DATE_DESC",
      },
      {
        label: "Oldest First",
        value: "UPDATED_DATE_ASC",
      },
    ],
  },
];

function serializeValue(
  value: CandidateSorterValue
): ListCandidatesV2OrderInput {
  switch (value) {
    case "APPLICATION_DATE_ASC":
      return {
        field: ListCandidatesV2OrderField.MostRecentApplicationDate,
        direction: Sort.Asc,
      };
    case "APPLICATION_DATE_DESC":
      return {
        field: ListCandidatesV2OrderField.MostRecentApplicationDate,
        direction: Sort.Desc,
      };
    case "UPDATED_DATE_ASC":
      return {
        field: ListCandidatesV2OrderField.LastUpdateDate,
        direction: Sort.Asc,
      };
    case "UPDATED_DATE_DESC":
      return {
        field: ListCandidatesV2OrderField.LastUpdateDate,
        direction: Sort.Desc,
      };
    default:
      throw new Error(`Could not recognize sort value ${value}`);
  }
}

function deserializeValue(
  input: ListCandidatesV2OrderInput
): CandidateSorterValue {
  switch (input.field) {
    case ListCandidatesV2OrderField.MostRecentApplicationDate: {
      switch (input.direction) {
        case Sort.Asc:
          return "APPLICATION_DATE_ASC";
        case Sort.Desc:
          return "APPLICATION_DATE_DESC";
        default:
          throw new Error(`Could not recognize sort ${input.direction}`);
      }
    }
    case ListCandidatesV2OrderField.LastUpdateDate: {
      switch (input.direction) {
        case Sort.Asc:
          return "UPDATED_DATE_ASC";
        case Sort.Desc:
          return "UPDATED_DATE_DESC";
        default:
          throw new Error(`Could not recognize sort ${input.direction}`);
      }
    }
    default:
      throw new Error(`Could not recognize field ${input.field}`);
  }
}

export function CandidateSorter({
  value,
  onChange,
  defaultValue,
  disabled,
}: CandidateSorterProps): JSX.Element {
  const track = useTrack();

  return (
    <SelectCheckboxesField
      css={css`
        width: 194px;
      `}
      disabled={disabled}
      id="candidate-sorter"
      label="Sort"
      options={CANDIDATE_SORT_OPTIONS}
      value={[deserializeValue(value)]}
      onChange={(value) => {
        if (value) {
          onChange(serializeValue(value as CandidateSorterValue));
          track("Candidates sorting updated", { sortValue: value });
        }
      }}
      type="radio"
      rightAdornment={
        <SortIcon
          css={(theme: AppTheme) => css`
            color: ${deserializeValue(value) === deserializeValue(defaultValue)
              ? "#000"
              : theme.palette.primary.main};

            transform: rotate(
              ${value.direction === Sort.Desc ? "0deg" : "180deg"}
            );
          `}
        />
      }
    />
  );
}
