import { faUsers } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ApplicantsInsightMetric as ApplicantsInsightMetricType } from "@rewards-web/shared/graphql-types";

import { InsightMetric } from "../../../../components/insight-metric";
import { getPercentDifference } from "../../../../utils";

export interface ApplicantsInsightMetricProps {
  currentApplicantsInsightMetric: ApplicantsInsightMetricType | undefined;
  previousApplicantsInsightMetric: ApplicantsInsightMetricType | undefined;
}

export function ApplicantsInsightMetric({
  currentApplicantsInsightMetric,
  previousApplicantsInsightMetric,
}: ApplicantsInsightMetricProps) {
  const currentApplicants = currentApplicantsInsightMetric?.applicantCount;
  const previousApplicants = previousApplicantsInsightMetric?.applicantCount;

  const percentPointChange = (() => {
    if (currentApplicants === undefined || previousApplicants === undefined) {
      return null;
    }
    return getPercentDifference(previousApplicants, currentApplicants);
  })();

  return (
    <InsightMetric
      label="Applicants"
      icon={<FontAwesomeIcon icon={faUsers} />}
      tooltipText="The total number of applications received."
      value={
        currentApplicants !== undefined
          ? { type: "number", number: currentApplicants }
          : {
              type: "loading",
            }
      }
      percentPointChange={percentPointChange}
    />
  );
}
