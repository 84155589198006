import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateRecognitionCategoryMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  defaultPointAmount?: Types.Maybe<Types.Scalars['Int']>;
  disableSendFromManagers?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type CreateRecognitionCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createRecognitionCategory: (
    { __typename?: 'RecognitionCategory' }
    & Pick<Types.RecognitionCategory, 'id'>
  ) }
);


export const CreateRecognitionCategoryDocument = gql`
    mutation CreateRecognitionCategory($name: String!, $defaultPointAmount: Int, $disableSendFromManagers: Boolean) {
  createRecognitionCategory(
    name: $name
    defaultPointAmount: $defaultPointAmount
    disableSendFromManagers: $disableSendFromManagers
  ) {
    id
  }
}
    `;
export type CreateRecognitionCategoryMutationFn = Apollo.MutationFunction<CreateRecognitionCategoryMutation, CreateRecognitionCategoryMutationVariables>;

/**
 * __useCreateRecognitionCategoryMutation__
 *
 * To run a mutation, you first call `useCreateRecognitionCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecognitionCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecognitionCategoryMutation, { data, loading, error }] = useCreateRecognitionCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      defaultPointAmount: // value for 'defaultPointAmount'
 *      disableSendFromManagers: // value for 'disableSendFromManagers'
 *   },
 * });
 */
export function useCreateRecognitionCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecognitionCategoryMutation, CreateRecognitionCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRecognitionCategoryMutation, CreateRecognitionCategoryMutationVariables>(CreateRecognitionCategoryDocument, options);
      }
export type CreateRecognitionCategoryMutationHookResult = ReturnType<typeof useCreateRecognitionCategoryMutation>;
export type CreateRecognitionCategoryMutationResult = Apollo.MutationResult<CreateRecognitionCategoryMutation>;
export type CreateRecognitionCategoryMutationOptions = Apollo.BaseMutationOptions<CreateRecognitionCategoryMutation, CreateRecognitionCategoryMutationVariables>;