import { useMemo } from "react";
import {
  Control,
  FieldValues,
  Path,
  useWatch,
  Controller,
} from "react-hook-form";
import { useDebounce } from "use-debounce";

import { getCharactersRemainingText } from "@rewards-web/shared/lib/characters-remaining-text";
import { stripHTMLTags } from "@rewards-web/shared/lib/strip-html-tags";
import { stripWhitespaceAndNewlines } from "@rewards-web/shared/lib/strip-whitespace-and-newlines";

import { WYSIWYGTextField } from "../../../../components/wysiwyg-text-field";

const MAX_JOB_DESCRIPTION_LENGTH = 10000;

interface JobDescriptionFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export function JobDescriptionField<T extends FieldValues>({
  control,
  name,
}: JobDescriptionFieldProps<T>) {
  const value = useWatch({ control, name });
  const [debouncedValue] = useDebounce(value, 300);
  const jobDescriptionCharactersRemaining = useMemo(
    () =>
      getCharactersRemainingText(
        stripHTMLTags(debouncedValue),
        MAX_JOB_DESCRIPTION_LENGTH
      ),
    [debouncedValue]
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (value) => {
          const withStrippedTags = stripHTMLTags(value);

          if (stripWhitespaceAndNewlines(withStrippedTags).length === 0) {
            return "Job description is required";
          }

          if (withStrippedTags.length > MAX_JOB_DESCRIPTION_LENGTH) {
            return jobDescriptionCharactersRemaining;
          }
        },
      }}
      render={({ field, fieldState }) => (
        <WYSIWYGTextField
          {...field}
          label="Description"
          error={fieldState.error}
          helperText={jobDescriptionCharactersRemaining}
        />
      )}
    />
  );
}
