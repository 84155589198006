import { noop } from "lodash";
import {
  ReactNode,
  useState,
  createContext,
  useContext,
  useCallback,
} from "react";

import { LaunchCongratulationsModal } from "../launch-congratulations-modal";

const LaunchModalCongratulationsContext = createContext<{
  openLaunchCongratulationsModal(): void;
}>({
  openLaunchCongratulationsModal: noop,
});

export interface LaunchCongratulationsModalProviderProps {
  children: ReactNode;
}

export function LaunchCongratulationsModalProvider({
  children,
}: LaunchCongratulationsModalProviderProps) {
  const [open, setOpen] = useState(false);
  const openLaunchCongratulationsModal = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  return (
    <LaunchModalCongratulationsContext.Provider
      value={{ openLaunchCongratulationsModal }}
    >
      <LaunchCongratulationsModal open={open} onClose={() => setOpen(false)} />
      {children}
    </LaunchModalCongratulationsContext.Provider>
  );
}

export function useLaunchCongratulationsActions() {
  const { openLaunchCongratulationsModal } = useContext(
    LaunchModalCongratulationsContext
  );

  return {
    open: openLaunchCongratulationsModal,
  };
}
