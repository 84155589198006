import {
  CandidateRecruitmentStepName,
  NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment,
  NumActiveCandidatesByJobPostingAndStatusItemTotalRetention,
} from "@rewards-web/shared/graphql-types";

export function getStepLabel(
  step:
    | ({
        __typename: "NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment";
      } & Pick<
        NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment,
        "recruitmentStepName"
      >)
    | ({
        __typename: "NumActiveCandidatesByJobPostingAndStatusItemTotalRetention";
      } & Pick<
        NumActiveCandidatesByJobPostingAndStatusItemTotalRetention,
        "retentionDurationMonths"
      >)
) {
  switch (step.__typename) {
    case "NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment": {
      switch (step.recruitmentStepName) {
        case CandidateRecruitmentStepName.Contacted:
          return "Contacted?";
        case CandidateRecruitmentStepName.InterviewScheduled:
          return "Scheduled?";
        case CandidateRecruitmentStepName.InterviewSuccessful:
          return "Offer extended?";
        case CandidateRecruitmentStepName.Hired:
          return "Hired?";
        case CandidateRecruitmentStepName.StartedWork:
          return "Started working?";
        case CandidateRecruitmentStepName.StartedOrientation:
          return "Started orientation?";
        case CandidateRecruitmentStepName.CompletedOrientation:
          return "Completed orientation?";
        case CandidateRecruitmentStepName.CompletedFirstShift:
          return "Completed first shift?";
        default:
          return `${step.recruitmentStepName}`;
      }
    }
    case "NumActiveCandidatesByJobPostingAndStatusItemTotalRetention":
      return `Worked for ${step.retentionDurationMonths}mos?`;
    default: {
      throw new Error("Could not recognize step type");
    }
  }
}

export function getCandidatesFilterPathFromStep(
  step:
    | ({
        __typename: "NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment";
      } & Pick<
        NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment,
        "recruitmentStepName"
      >)
    | ({
        __typename: "NumActiveCandidatesByJobPostingAndStatusItemTotalRetention";
      } & Pick<
        NumActiveCandidatesByJobPostingAndStatusItemTotalRetention,
        "retentionDurationMonths"
      >),
  jobPostingId: string
) {
  switch (step.__typename) {
    case "NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment": {
      return `/candidates?${new URLSearchParams({
        statuses: step.recruitmentStepName,
        archived: "false",
        jobs: jobPostingId,
      })}`;
    }
    case "NumActiveCandidatesByJobPostingAndStatusItemTotalRetention": {
      return `/candidates?${new URLSearchParams({
        statuses: `${step.retentionDurationMonths}_months`,
        archived: "false",
        jobs: jobPostingId,
      })}`;
    }
    default: {
      throw new Error("Could not recognize step type");
    }
  }
}
