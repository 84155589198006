import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ConfirmChangeJobDataQueryVariables = Types.Exact<{
  currentJobPostingId: Types.Scalars['ID'];
  nextJobPostingId: Types.Scalars['ID'];
}>;


export type ConfirmChangeJobDataQuery = (
  { __typename?: 'Query' }
  & { currentJobPosting?: Types.Maybe<(
    { __typename?: 'JobPosting' }
    & Pick<Types.JobPosting, 'id' | 'title' | 'geography' | 'candidateAlertEmail'>
    & { organization: (
      { __typename?: 'RewardsOrganization' }
      & Pick<Types.RewardsOrganization, 'id' | 'shortName' | 'branchName'>
    ) }
  )>, nextJobPosting?: Types.Maybe<(
    { __typename?: 'JobPosting' }
    & Pick<Types.JobPosting, 'id' | 'title' | 'geography' | 'candidateAlertEmail'>
    & { organization: (
      { __typename?: 'RewardsOrganization' }
      & Pick<Types.RewardsOrganization, 'id' | 'shortName' | 'branchName'>
    ) }
  )> }
);


export const ConfirmChangeJobDataDocument = gql`
    query ConfirmChangeJobData($currentJobPostingId: ID!, $nextJobPostingId: ID!) {
  currentJobPosting: getJobPostingById(jobPostingId: $currentJobPostingId) {
    id
    title
    geography
    candidateAlertEmail
    organization {
      id
      shortName
      branchName
    }
  }
  nextJobPosting: getJobPostingById(jobPostingId: $nextJobPostingId) {
    id
    title
    geography
    candidateAlertEmail
    organization {
      id
      shortName
      branchName
    }
  }
}
    `;

/**
 * __useConfirmChangeJobDataQuery__
 *
 * To run a query within a React component, call `useConfirmChangeJobDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmChangeJobDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmChangeJobDataQuery({
 *   variables: {
 *      currentJobPostingId: // value for 'currentJobPostingId'
 *      nextJobPostingId: // value for 'nextJobPostingId'
 *   },
 * });
 */
export function useConfirmChangeJobDataQuery(baseOptions: Apollo.QueryHookOptions<ConfirmChangeJobDataQuery, ConfirmChangeJobDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfirmChangeJobDataQuery, ConfirmChangeJobDataQueryVariables>(ConfirmChangeJobDataDocument, options);
      }
export function useConfirmChangeJobDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfirmChangeJobDataQuery, ConfirmChangeJobDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfirmChangeJobDataQuery, ConfirmChangeJobDataQueryVariables>(ConfirmChangeJobDataDocument, options);
        }
export type ConfirmChangeJobDataQueryHookResult = ReturnType<typeof useConfirmChangeJobDataQuery>;
export type ConfirmChangeJobDataLazyQueryHookResult = ReturnType<typeof useConfirmChangeJobDataLazyQuery>;
export type ConfirmChangeJobDataQueryResult = Apollo.QueryResult<ConfirmChangeJobDataQuery, ConfirmChangeJobDataQueryVariables>;