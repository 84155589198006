/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/client";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { RewardsOrganizationOnboardingStepName } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { EditReferralStructureFormValues } from "../../../../../shared/components/edit-referral-structure-form-content";
import { referralStructureIsEditable } from "../../../../../shared/components/edit-referral-structure-form-content/lib";
import { ReferralsStructureTable } from "../../../../../shared/components/referrals-structure-table";
import {
  RewardsStructureDocument,
  useRewardsStructureQuery,
} from "../../../../../shared/components/referrals-structure-table/rewards-structure.generated";
import { useUpdateReferralStructureMutation } from "../../../settings/pages/referral-structure/edit-referral-structure/edit-referral-structure.generated";
import {
  prepareRecruitmentSteps,
  prepareRetentionMilestones,
} from "../../../settings/pages/referral-structure/edit-referral-structure/page-content";
import { AdminOnboardingCard } from "../../components/onboarding-card/onboarding-card";
import { AdminOnboardingCardContent } from "../../components/onboarding-card/onboarding-card-content";
import { AdminOnboardingCardHeader } from "../../components/onboarding-card/onboarding-card-header";
import { useNextOnboardingStepPath } from "../../hooks/use-next-step-path";
import { useOnboardingState } from "../../hooks/use-onboarding-state";
import { EditRewardStructureForm } from "./edit-referral-structure";

export function OnboardingReferralsPage() {
  const navigate = useNavigate();
  const onboardingState = useOnboardingState({ loadFreshData: true });
  const nextOnboardingStepPath = useNextOnboardingStepPath();
  const rewardStructureQueryResponse = useRewardsStructureQuery({
    onError: reportError,
  });
  const snackbar = useSnackbar();
  const apolloClient = useApolloClient();

  const [editReferralStructure] = useUpdateReferralStructureMutation();

  const onSubmit = async (values: EditReferralStructureFormValues) => {
    const recruitmentSteps = prepareRecruitmentSteps(values);

    const retentionMilestones = prepareRetentionMilestones(values);
    try {
      await editReferralStructure({
        variables: {
          input: {
            recruitmentSteps: recruitmentSteps,
            retentionMilestones: retentionMilestones,
          },
        },
      });
      apolloClient.refetchQueries({
        include: [RewardsStructureDocument],
      });
      snackbar.show({
        severity: "success",
        message: "Updated Referral Structure",
      });

      await onboardingState.recordCompletedOnboardingStep(
        RewardsOrganizationOnboardingStepName.ReferralStructure
      );
      if (!nextOnboardingStepPath) {
        throw new Error("No next onboarding step path");
      }
      navigate(nextOnboardingStepPath);
    } catch (e) {
      snackbar.show({
        severity: "error",
        message: "An unexpected error has occurred. Please try again later.",
      });
      reportError(e);
    }
  };

  if (onboardingState.loading) {
    return <PageLoadingState />;
  }

  if (onboardingState.error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }
  const content = (() => {
    if (rewardStructureQueryResponse.error) {
      return (
        <Alert
          severity="error"
          message="An unexpected error occurred. Please try again later."
        />
      );
    }
    if (!rewardStructureQueryResponse.data) {
      return (
        <div
          css={css`
            height: 200px;
          `}
        >
          <PageLoadingState />
        </div>
      );
    }
    if (
      !referralStructureIsEditable(
        rewardStructureQueryResponse.data.getMyRewardsOrganization
          .referralRewardStructure
      )
    ) {
      return <ReferralsStructureTable />;
    }
    return (
      <EditRewardStructureForm
        data={rewardStructureQueryResponse.data}
        onSubmit={onSubmit}
      />
    );
  })();
  return (
    <>
      <AdminOnboardingCard
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <AdminOnboardingCardHeader title="☎️ Referral Structure" />
        <AdminOnboardingCardContent>
          <div
            css={(appTheme: AppTheme) => css`
              margin-bottom: ${appTheme.spacing(3)};
            `}
          >
            <Typography
              color="textSecondary"
              variant="body"
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(2)};
              `}
            >
              Strong referrals matter! The secret to our referral program and
              helping you hire the best caregivers is that caregivers get
              rewarded every step of the way, even just for sharing a job
              posting!
            </Typography>

            <Typography color="textSecondary" variant="body">
              Here's our recommended referral payout structure to the referring
              caregiver. Please note that the budget you select here is not
              included in the current subscription and will be an additional
              charge.
            </Typography>
          </div>
        </AdminOnboardingCardContent>
      </AdminOnboardingCard>
      {content}
    </>
  );
}
