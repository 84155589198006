/** @jsxImportSource @emotion/react */
import { ClassNames } from "@emotion/react";
import { lighten, LinearProgress } from "@material-ui/core";

export interface ProgressBarProps {
  value: number;
  whiteBar?: boolean;
  borderRadius?: number;
  greyBackground?: boolean;
  orientation?: "horizontal" | "vertical";
  barWidth?: number;
}

export function ProgressBar({
  value,
  whiteBar = false,
  borderRadius = 5,
  greyBackground = false,
  orientation = "horizontal",
  barWidth = 10,
}: ProgressBarProps): JSX.Element {
  return (
    <ClassNames>
      {({ css, theme }) => (
        <LinearProgress
          css={css`
            min-width: ${orientation === "vertical" ? "auto" : "100%"};
            min-height: ${orientation === "vertical" ? "100%" : "auto"};
          `}
          classes={{
            root: css`
              height: ${orientation === "vertical" ? "auto" : `${barWidth}px`};
              width: ${orientation === "vertical" ? `${barWidth}px` : "auto"};
              border-radius: ${borderRadius}px;
            `,
            colorPrimary: css`
              background-color: ${greyBackground
                ? "#f1f1f1"
                : whiteBar
                ? lighten(theme.palette.primary.main, 0.1)
                : "#fff"};
            `,
            bar: css`
              border-radius: 5;
              background-color: ${whiteBar
                ? theme.palette.background.default
                : theme.palette.primary.main};
              transform: ${orientation === "vertical"
                ? `translateY(${100 - value}%) !important`
                : "auto"};
            `,
          }}
          variant="determinate"
          value={value}
        ></LinearProgress>
      )}
    </ClassNames>
  );
}
