import { useState } from "react";

export interface CandidateDrawerAccordionState {
  candidateEmployeeLinkSectionOpen: boolean;
  screenerQuestionsSectionOpen: boolean;
  resumeSectionOpen: boolean;
  referringSectionOpen: boolean;
  internalNotesSectionOpen: boolean;
  jobSectionOpen: boolean;
}

export function useCandidateDrawerAccordionState(
  initialState: CandidateDrawerAccordionState = {
    candidateEmployeeLinkSectionOpen: true,
    screenerQuestionsSectionOpen: true,
    resumeSectionOpen: true,
    referringSectionOpen: true,
    internalNotesSectionOpen: true,
    jobSectionOpen: true,
  }
) {
  return useState<CandidateDrawerAccordionState>(initialState);
}
