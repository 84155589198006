import * as Types from '@rewards-web/shared/graphql-types';

import { CandidateListDetailsFragment } from '../../candidate-fragment.generated';
import { gql } from '@apollo/client';
import { CandidateListDetailsFragmentDoc } from '../../candidate-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RecordHourlyRetentionStepMutationVariables = Types.Exact<{
  candidateId: Types.Scalars['ID'];
  durationHours: Types.Scalars['Int'];
}>;


export type RecordHourlyRetentionStepMutation = (
  { __typename?: 'Mutation' }
  & { recordCandidateHourlyRetentionStep: (
    { __typename?: 'Candidate' }
    & CandidateListDetailsFragment
  ) }
);


export const RecordHourlyRetentionStepDocument = gql`
    mutation RecordHourlyRetentionStep($candidateId: ID!, $durationHours: Int!) {
  recordCandidateHourlyRetentionStep(
    candidateId: $candidateId
    durationHours: $durationHours
  ) {
    ...CandidateListDetails
  }
}
    ${CandidateListDetailsFragmentDoc}`;
export type RecordHourlyRetentionStepMutationFn = Apollo.MutationFunction<RecordHourlyRetentionStepMutation, RecordHourlyRetentionStepMutationVariables>;

/**
 * __useRecordHourlyRetentionStepMutation__
 *
 * To run a mutation, you first call `useRecordHourlyRetentionStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordHourlyRetentionStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordHourlyRetentionStepMutation, { data, loading, error }] = useRecordHourlyRetentionStepMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      durationHours: // value for 'durationHours'
 *   },
 * });
 */
export function useRecordHourlyRetentionStepMutation(baseOptions?: Apollo.MutationHookOptions<RecordHourlyRetentionStepMutation, RecordHourlyRetentionStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecordHourlyRetentionStepMutation, RecordHourlyRetentionStepMutationVariables>(RecordHourlyRetentionStepDocument, options);
      }
export type RecordHourlyRetentionStepMutationHookResult = ReturnType<typeof useRecordHourlyRetentionStepMutation>;
export type RecordHourlyRetentionStepMutationResult = Apollo.MutationResult<RecordHourlyRetentionStepMutation>;
export type RecordHourlyRetentionStepMutationOptions = Apollo.BaseMutationOptions<RecordHourlyRetentionStepMutation, RecordHourlyRetentionStepMutationVariables>;