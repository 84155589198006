/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Checkbox } from "@material-ui/core";
import {
  FieldValues,
  Control,
  Path,
  Controller,
  useWatch,
} from "react-hook-form";

import { Typography } from "@rewards-web/shared/components/typography";
import { stripHTMLTags } from "@rewards-web/shared/lib/strip-html-tags";
import { stripWhitespaceAndNewlines } from "@rewards-web/shared/lib/strip-whitespace-and-newlines";
import { AppTheme, headerFontFamily } from "@rewards-web/shared/style/theme";

import { WYSIWYGTextField } from "../../../../components/wysiwyg-text-field";

const MAX_CUSTOM_EMAIL_LENGTH = 600;

interface JobCustomEmailFieldProps<T extends FieldValues> {
  name: Path<T>;
  checkBoxName: Path<T>;
  control: Control<T>;
}

export function JobCustomEmailField<T extends FieldValues>({
  name,
  control,
  checkBoxName,
}: JobCustomEmailFieldProps<T>): JSX.Element {
  const open = useWatch({ control, name: checkBoxName });

  return (
    <div>
      <div
        css={(theme: AppTheme) => css`
          display: inline-flex;
          align-items: center;
          margin-top: -${theme.spacing(1.5)};
        `}
      >
        <Checkbox
          data-testid="email-checkbox"
          color="primary"
          checked={open}
          {...control.register(checkBoxName)}
        />

        <div
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(2)};
          `}
        >
          <Typography variant="body" color="textPrimary">
            Customize the email response
          </Typography>
          <Typography
            css={css`
              font-style: italic;
            `}
            variant="footnote"
            color="textSecondary"
          >
            Edit the default email that is sent to applicants
          </Typography>
        </div>
      </div>

      {open && (
        <div
          css={(theme: AppTheme) => css`
            position: relative;
            padding-left: ${theme.spacing(1.5)};
            margin-top: ${theme.spacing(1.5)};
          `}
        >
          <div
            css={css`
              position: absolute;
              top: 55px;
              margin-left: 11px;
              z-index: 1;
            `}
          >
            <Typography
              variant="body"
              color="textSecondary"
              css={css`
                font-family: ${headerFontFamily};
              `}
            >
              {"Hi <Applicant name>"}
            </Typography>
          </div>
          <Controller
            control={control}
            name={name}
            rules={{
              validate: (value) => {
                const withStrippedTags = stripHTMLTags(value);

                if (stripWhitespaceAndNewlines(withStrippedTags).length === 0) {
                  return "Custom email is required if checked";
                }
                if (withStrippedTags.length > MAX_CUSTOM_EMAIL_LENGTH) {
                  return `Characters limit of ${MAX_CUSTOM_EMAIL_LENGTH} exceeded`;
                }
              },
            }}
            render={({ field, fieldState }) => (
              <WYSIWYGTextField
                {...field}
                topPadding={20}
                heightAuto
                label="Custom email"
                toolBarInline
                error={fieldState.error}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}
