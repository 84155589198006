/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface DeactivatedAdminModalProps {
  open: boolean;
  onClose(): void;
}

export function DeactivatedAdminModal({
  open,
  onClose,
}: DeactivatedAdminModalProps): JSX.Element {
  return (
    <Modal width="600px" open={open} onClose={onClose}>
      <ModalTitle>Your account has been deactived.</ModalTitle>

      <ModalContent>
        <Typography
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(2)};
            `
          }
          variant="body"
        >
          If you think this is an error, request access from your agency lead or
          contact us at{" "}
          <a href={`mailto:${encodeURI("help+admin@caribou.care")}`}>
            help+admin@caribou.care
          </a>
          .
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button color="primary" onClick={onClose} label="Ok" />
      </ModalActions>
    </Modal>
  );
}
