import { Control, FieldValues, Path, useFormState, get } from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";

interface EmployeeNameFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export function EmployeeNameField<T extends FieldValues>({
  control,
  name,
}: EmployeeNameFieldProps<T>) {
  const formState = useFormState({ control, name });

  return (
    <TextField
      label="First name"
      error={get(formState.errors, name)}
      disableAutocomplete
      {...control.register(name, {
        required: "First name is required",
      })}
    />
  );
}
