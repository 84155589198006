/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Pagination as MUIPagination } from "@material-ui/lab";

import { AppTheme } from "@rewards-web/shared/style/theme";
import { StylableProps } from "@rewards-web/shared/types";

interface PaginationProps extends StylableProps {
  total: number;
  currentPageIndex: number;
  itemsPerPage: number;
  onChange: (_: any, page: any) => void;
}

export function Pagination({
  total,
  currentPageIndex,
  itemsPerPage,
  onChange,
}: PaginationProps) {
  return (
    <MUIPagination
      css={(theme: AppTheme) => css`
        margin: ${theme.spacing(2)};
        margin-top: ${theme.spacing(3.5)};
      `}
      color="primary"
      count={Math.ceil(total / itemsPerPage)}
      page={currentPageIndex + 1}
      onChange={onChange}
      shape="rounded"
    />
  );
}
