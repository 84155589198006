import { ClassNames } from "@emotion/react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useLocation, useNavigate } from "react-router-dom";

import { Typography } from "@rewards-web/shared/components/typography";

interface ToggleButtonsProps {
  ariaLabel: string;
  toggleButtons: Array<{
    label: string;
    path: string;
  }>;
}

/*
 * Toggle buttons used for navigation between pages, or between versions of a page
 */
export function ToggleButtons({
  ariaLabel,
  toggleButtons,
}: ToggleButtonsProps) {
  const location = useLocation();
  const navigate = useNavigate();

  function handleTabChange(_: any, value: string | null) {
    if (value) {
      navigate(value);
    }
  }

  return (
    <ClassNames>
      {({ css, theme }) => {
        return (
          <ToggleButtonGroup
            value={location.pathname}
            aria-label={ariaLabel}
            exclusive
            onChange={handleTabChange}
            classes={{
              root: css`
                width: 100%;
                margin-bottom: 30px;
                background-color: ${theme.palette.grey[200]};
                border-radius: 100px;
                padding: ${theme.spacing(1)};
              `,
              groupedHorizontal: css`
                &:not(:last-child) {
                  border-top-right-radius: 100px;
                  border-bottom-right-radius: 100px;
                }
                &:not(:first-child) {
                  margin-left: 0px;
                  border-top-left-radius: 100px;
                  border-bottom-left-radius: 100px;
                }
              `,
            }}
          >
            {toggleButtons.map(({ label, path }, idx) => (
              <ToggleButton
                key={idx}
                value={path}
                classes={{
                  root: css`
                    text-transform: none;
                    padding: 8px;
                    width: calc(100% / ${toggleButtons.length});
                    background-color: ${theme.palette.grey[200]};
                    color: ${theme.palette.grey[800]};
                    border-radius: 100px;
                    border: none;

                    &.Mui-selected {
                      background-color: ${theme.palette.primary.main};
                      color: white;

                      &:hover {
                        background-color: ${theme.palette.primary.dark};
                      }
                    }
                  `,
                }}
              >
                <Typography variant="body" fontWeight={600}>
                  {label}
                </Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        );
      }}
    </ClassNames>
  );
}
