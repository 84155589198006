import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

import { PageLayout } from "../../../../../shared/components/page-layout";
import { AuthorizedAdminPageWrapper } from "../../../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { ForbiddenErrorPage } from "../../../../../shared/modules/permissions/components/forbidden-error-page";
import { SurveyQuestionDetailsPageContents } from "./page-contents";

const MAX_CONTENT_WIDTH = 1400;

export function SurveyQuestionDetailsPage(): JSX.Element {
  const surveysPageEnabled = useFeatureFlag(
    "admin-app-surveys-page-enabled-temp"
  );

  if (!surveysPageEnabled) {
    return <ForbiddenErrorPage />;
  }

  return (
    <AuthorizedAdminPageWrapper resource="surveys">
      <PageLayout maxContentWidth={MAX_CONTENT_WIDTH}>
        <SurveyQuestionDetailsPageContents />
      </PageLayout>
    </AuthorizedAdminPageWrapper>
  );
}
