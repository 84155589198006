/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Control, FieldValues, get, Path, useFormState } from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { extractNumber } from "@rewards-web/shared/lib/extract-number";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { InfoIcon } from "../../../../pages/authenticated/admins/icons/info-icon";

interface ReferralRewardNumDrawTicketsFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  min: number;
  max: number;
}

export function ReferralRewardNumDrawTicketsField<T extends FieldValues>({
  control,
  name,
  min,
  max,
}: ReferralRewardNumDrawTicketsFieldProps<T>) {
  const formState = useFormState({ control });

  return (
    <TextField
      css={css`
        width: 290px;
      `}
      {...control.register(name, {
        required: "This field is required",
        validate: (value) => {
          if (value) {
            const valueConvertedToNumber = extractNumber(value);
            if (valueConvertedToNumber < min || valueConvertedToNumber > max) {
              return `Value should be in the range of ${min} and ${max} draw tickets`;
            }
          }
        },
      })}
      label="Draw tickets"
      hideLabel
      endAdornment={
        <Typography variant="body" fontWeight={600} color="textPrimary">
          Draw ticket(s)
        </Typography>
      }
      helperText={
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            align-items: center;
            margin-left: ${theme.spacing(-1.8)};
            gap: ${theme.spacing(1)};
          `}
        >
          <InfoIcon />
          <Typography variant="body" fontWeight={400} color="grey.800">
            Limit of 5 per month
          </Typography>
        </div>
      }
      error={get(formState.errors, name)}
      size="small"
    />
  );
}
