import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CandidateNotesQueryVariables = Types.Exact<{
  candidateId: Types.Scalars['ID'];
}>;


export type CandidateNotesQuery = (
  { __typename?: 'Query' }
  & { getCandidateById?: Types.Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Types.Candidate, 'id' | 'adminNotes'>
  )> }
);


export const CandidateNotesDocument = gql`
    query CandidateNotes($candidateId: ID!) {
  getCandidateById(candidateId: $candidateId) {
    id
    adminNotes
  }
}
    `;

/**
 * __useCandidateNotesQuery__
 *
 * To run a query within a React component, call `useCandidateNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateNotesQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useCandidateNotesQuery(baseOptions: Apollo.QueryHookOptions<CandidateNotesQuery, CandidateNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidateNotesQuery, CandidateNotesQueryVariables>(CandidateNotesDocument, options);
      }
export function useCandidateNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidateNotesQuery, CandidateNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidateNotesQuery, CandidateNotesQueryVariables>(CandidateNotesDocument, options);
        }
export type CandidateNotesQueryHookResult = ReturnType<typeof useCandidateNotesQuery>;
export type CandidateNotesLazyQueryHookResult = ReturnType<typeof useCandidateNotesLazyQuery>;
export type CandidateNotesQueryResult = Apollo.QueryResult<CandidateNotesQuery, CandidateNotesQueryVariables>;