import { format, parseISO, subDays } from "date-fns";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// TODO remove this
export function getMonthName(month: number): string {
  return monthNames[month];
}

export function formatBudgetPeriodRange(start: string, end: string) {
  const startDate = parseISO(start);
  const endDate = subDays(parseISO(end), 1);

  return `${formatPeriodDate(startDate)} - ${formatPeriodDate(endDate)}`;
}

function formatPeriodDate(date: Date) {
  return format(date, "MMM d");
}
