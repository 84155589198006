import { CognitoUserSession } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";

import { CognitoError } from "./constants";

export async function getCognitoSession(): Promise<CognitoUserSession> {
  return Auth.currentSession();
}

export function isCognitoError(error: any): error is CognitoError {
  return !!error.code && error.name !== "QuotaExceededError";
}
