import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PulseSurveyAvailableDateRangesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PulseSurveyAvailableDateRangesQuery = (
  { __typename?: 'Query' }
  & { getAvailableSurveyDateRanges: Array<(
    { __typename?: 'SurveyInsightsDateRange' }
    & Pick<Types.SurveyInsightsDateRange, 'label' | 'year' | 'startDate' | 'endDate'>
  )> }
);


export const PulseSurveyAvailableDateRangesDocument = gql`
    query PulseSurveyAvailableDateRanges {
  getAvailableSurveyDateRanges(surveyType: PULSE_CHECK) {
    label
    year
    startDate
    endDate
  }
}
    `;

/**
 * __usePulseSurveyAvailableDateRangesQuery__
 *
 * To run a query within a React component, call `usePulseSurveyAvailableDateRangesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePulseSurveyAvailableDateRangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePulseSurveyAvailableDateRangesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePulseSurveyAvailableDateRangesQuery(baseOptions?: Apollo.QueryHookOptions<PulseSurveyAvailableDateRangesQuery, PulseSurveyAvailableDateRangesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PulseSurveyAvailableDateRangesQuery, PulseSurveyAvailableDateRangesQueryVariables>(PulseSurveyAvailableDateRangesDocument, options);
      }
export function usePulseSurveyAvailableDateRangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PulseSurveyAvailableDateRangesQuery, PulseSurveyAvailableDateRangesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PulseSurveyAvailableDateRangesQuery, PulseSurveyAvailableDateRangesQueryVariables>(PulseSurveyAvailableDateRangesDocument, options);
        }
export type PulseSurveyAvailableDateRangesQueryHookResult = ReturnType<typeof usePulseSurveyAvailableDateRangesQuery>;
export type PulseSurveyAvailableDateRangesLazyQueryHookResult = ReturnType<typeof usePulseSurveyAvailableDateRangesLazyQuery>;
export type PulseSurveyAvailableDateRangesQueryResult = Apollo.QueryResult<PulseSurveyAvailableDateRangesQuery, PulseSurveyAvailableDateRangesQueryVariables>;