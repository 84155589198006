import { serializePhoneNumber } from "@rewards-web/shared/lib/serialize-phone-number";

/**
 * Replaces the first several characters of a phone number with X
 */
export function obfuscatePhoneNumber(rawPhoneNumber: string) {
  const phoneNumber = serializePhoneNumber(rawPhoneNumber);

  return phoneNumber.replace(
    phoneNumber.substring(1, phoneNumber.length - 4),
    "XXXXXXX"
  );
}
