import { useEffect } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { reportError } from "@rewards-web/shared/modules/error";
import { ControlledFormFieldProps } from "@rewards-web/shared/types";

import { MultiSelectAutocomplete } from "./multi-select-autocomplete";
import { useTagsSelectorListQuery } from "./tags-selector-list.generated";

const TAGS_LIMIT = 2000;

export function TagsSelectorField(props: ControlledFormFieldProps) {
  const tagsQuery = useTagsSelectorListQuery({
    onError: reportError,
    variables: {
      offset: 0,
      limit: TAGS_LIMIT,
    },
  });

  const totalTags = tagsQuery.data?.listRewardsTags.total;
  useEffect(() => {
    if (typeof totalTags === "number" && totalTags > TAGS_LIMIT) {
      reportError(
        new Error(
          `Warning -- organization with > ${TAGS_LIMIT} tags; only top ${TAGS_LIMIT} tags shown in TagsSelectorField`
        )
      );
    }
  }, [totalTags]);

  if (tagsQuery.error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  if (!tagsQuery.data) {
    return <PageLoadingState />;
  }

  const options = tagsQuery.data.listRewardsTags.items.map((tag) => ({
    value: tag.id,
    label: tag.externalId,
  }));

  return (
    <MultiSelectAutocomplete
      label="Tags"
      size="medium"
      options={options}
      placeholder="Type to search tags"
      {...props}
    />
  );
}
