import { AuthorizedAdminPageWrapper } from "../../../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { SettingsEmployeeAccountsCard } from "./employee-accounts-card";

export function SettingsEmployeeAccountsPage() {
  return (
    <AuthorizedAdminPageWrapper resource="settingsEmployeeAccounts">
      <SettingsEmployeeAccountsCard />
    </AuthorizedAdminPageWrapper>
  );
}
