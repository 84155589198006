import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type MarkOnboardingStepCompletedMutationVariables = Types.Exact<{
  step: Types.RewardsOrganizationOnboardingStepName;
}>;


export type MarkOnboardingStepCompletedMutation = (
  { __typename?: 'Mutation' }
  & { markRewardsOrganizationOnboardingStepCompleted: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id'>
    & { onboardingState: (
      { __typename?: 'RewardsOrganizationOnboardingState' }
      & Pick<Types.RewardsOrganizationOnboardingState, 'id' | 'completedSteps'>
    ) }
  ) }
);


export const MarkOnboardingStepCompletedDocument = gql`
    mutation MarkOnboardingStepCompleted($step: RewardsOrganizationOnboardingStepName!) {
  markRewardsOrganizationOnboardingStepCompleted(step: $step) {
    id
    onboardingState {
      id
      completedSteps
    }
  }
}
    `;
export type MarkOnboardingStepCompletedMutationFn = Apollo.MutationFunction<MarkOnboardingStepCompletedMutation, MarkOnboardingStepCompletedMutationVariables>;

/**
 * __useMarkOnboardingStepCompletedMutation__
 *
 * To run a mutation, you first call `useMarkOnboardingStepCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkOnboardingStepCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markOnboardingStepCompletedMutation, { data, loading, error }] = useMarkOnboardingStepCompletedMutation({
 *   variables: {
 *      step: // value for 'step'
 *   },
 * });
 */
export function useMarkOnboardingStepCompletedMutation(baseOptions?: Apollo.MutationHookOptions<MarkOnboardingStepCompletedMutation, MarkOnboardingStepCompletedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkOnboardingStepCompletedMutation, MarkOnboardingStepCompletedMutationVariables>(MarkOnboardingStepCompletedDocument, options);
      }
export type MarkOnboardingStepCompletedMutationHookResult = ReturnType<typeof useMarkOnboardingStepCompletedMutation>;
export type MarkOnboardingStepCompletedMutationResult = Apollo.MutationResult<MarkOnboardingStepCompletedMutation>;
export type MarkOnboardingStepCompletedMutationOptions = Apollo.BaseMutationOptions<MarkOnboardingStepCompletedMutation, MarkOnboardingStepCompletedMutationVariables>;