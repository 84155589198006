/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import {
  MailingAddressCountryCode,
  MailingAddressInput,
  CaProvinceCode,
  UsStateAbbreviation,
} from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { ApolloGraphQLResponseContext } from "@rewards-web/shared/types/apollo-response-context";

import { PageCard } from "../../../../../../shared/components/page-card";
import { PageCardTitle } from "../../../../../../shared/components/page-card/page-card-title";
import {
  AgencyCommunicationForm,
  AgencyCommunicationFormValues,
} from "./agency-communication-form";
import { useGetAgencyAddressQuery } from "./get-agency-address.generated";
import { useUpdateAgencyCommunicationMutation } from "./update-agency-communication.generated";

export function SettingsAgencyCommunicationCard(): JSX.Element {
  const snackbar = useSnackbar();
  const track = useTrack();
  const [updateAgencyCommunication] = useUpdateAgencyCommunicationMutation();
  const getAgencyAddressQuery = useGetAgencyAddressQuery({
    fetchPolicy: "network-only",
    onError: reportError,
  });

  const handleSubmit = async (values: AgencyCommunicationFormValues) => {
    try {
      const res = await updateAgencyCommunication({
        variables: {
          longName: values.companyName,
          mailingAddress: values.address1
            ? ((): MailingAddressInput => {
                switch (values.country) {
                  case MailingAddressCountryCode.Ca:
                    return {
                      countryCode: values.country!,
                      caProvinceCode: values.province as CaProvinceCode,
                      city: values.city,
                      addressLine1: values.address1,
                      addressLine2: values.address2 || null,
                      caPostalCode: values.postalCode,
                    };
                  case MailingAddressCountryCode.Us:
                    return {
                      countryCode: values.country!,
                      usStateAbbreviation: values.state as UsStateAbbreviation,
                      city: values.city,
                      addressLine1: values.address1,
                      addressLine2: values.address2 || null,
                      usZipCode: values.zipCode,
                    };
                  default:
                    throw new Error(
                      `Unrecognized country code ${values.country}`
                    );
                }
              })()
            : null,
        },
      });

      track("Agency communications updated", {
        settingsCard: "Agency Communication",
        requestId: (res.context as ApolloGraphQLResponseContext).requestId,
      });

      snackbar.show({
        severity: "success",
        message: "The agency communications have been updated.",
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error has occurred. Please try again later.",
      });
    }
  };

  const organizationMailingAddress =
    getAgencyAddressQuery.data?.getMyRewardsOrganization.mailingAddress;

  const agencyLongName =
    getAgencyAddressQuery.data?.getMyRewardsOrganization.longName;

  const content = (() => {
    if (getAgencyAddressQuery.error) {
      return (
        <Alert
          severity="error"
          message="An unexpected error occurred. Please try again later."
        />
      );
    }

    if (getAgencyAddressQuery.loading || !getAgencyAddressQuery.data) {
      return (
        <div
          css={css`
            height: 200px;
          `}
        >
          <PageLoadingState />
        </div>
      );
    }

    return (
      <AgencyCommunicationForm
        organizationMailingAddress={organizationMailingAddress}
        agencyName={agencyLongName}
        onSubmit={handleSubmit}
      />
    );
  })();

  return (
    <PageCard>
      <PageCardTitle title="Agency Communication" />
      {content}
    </PageCard>
  );
}
