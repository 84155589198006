import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ResendAdminInviteMutationVariables = Types.Exact<{
  adminId: Types.Scalars['ID'];
}>;


export type ResendAdminInviteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'generateAndSendRewardsAdminTemporaryPassword'>
);


export const ResendAdminInviteDocument = gql`
    mutation ResendAdminInvite($adminId: ID!) {
  generateAndSendRewardsAdminTemporaryPassword(adminId: $adminId)
}
    `;
export type ResendAdminInviteMutationFn = Apollo.MutationFunction<ResendAdminInviteMutation, ResendAdminInviteMutationVariables>;

/**
 * __useResendAdminInviteMutation__
 *
 * To run a mutation, you first call `useResendAdminInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendAdminInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendAdminInviteMutation, { data, loading, error }] = useResendAdminInviteMutation({
 *   variables: {
 *      adminId: // value for 'adminId'
 *   },
 * });
 */
export function useResendAdminInviteMutation(baseOptions?: Apollo.MutationHookOptions<ResendAdminInviteMutation, ResendAdminInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendAdminInviteMutation, ResendAdminInviteMutationVariables>(ResendAdminInviteDocument, options);
      }
export type ResendAdminInviteMutationHookResult = ReturnType<typeof useResendAdminInviteMutation>;
export type ResendAdminInviteMutationResult = Apollo.MutationResult<ResendAdminInviteMutation>;
export type ResendAdminInviteMutationOptions = Apollo.BaseMutationOptions<ResendAdminInviteMutation, ResendAdminInviteMutationVariables>;