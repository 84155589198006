import { SVGProps } from "react";

function FileProcessingIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 16a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM9 5a1 1 0 0 0 0 2h10a1 1 0 0 0 0-2H9ZM6 6a1 1 0 1 1-2.002 0A1 1 0 0 1 6 6ZM17 13a1 1 0 1 0-2 0v3a1 1 0 0 0 .486.857l2.5 1.5a.999.999 0 1 0 1.028-1.715L17 15.434V13Z"
        fill="#D37844"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0a6 6 0 0 0-3.318 11A6 6 0 0 0 6 22h4.708A8 8 0 0 0 24 16a7.97 7.97 0 0 0-2.12-5.424A6 6 0 0 0 18 0H6Zm2 16c0 1.457.39 2.823 1.07 4H6a4 4 0 1 1 0-8h3.07A7.963 7.963 0 0 0 8 16Zm8-8a7.972 7.972 0 0 0-5.292 2H6a4.001 4.001 0 0 1 0-8h12a4.001 4.001 0 0 1 2.315 7.263A7.963 7.963 0 0 0 16 8Zm0 2a6 6 0 1 0 0 12 6 6 0 0 0 0-12Z"
        fill="#D37844"
      />
    </svg>
  );
}

export default FileProcessingIcon;
