import { ClassNames } from "@emotion/react";
import { StepIcon, StepIconProps } from "@material-ui/core";
import { useState } from "react";

import { Button } from "@rewards-web/shared/components/button";
import {
  Card,
  CardActions,
  CardContent,
} from "@rewards-web/shared/components/card";
import { TextButton } from "@rewards-web/shared/components/text-button";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  CandidateRecruitmentStepName,
  RewardsAdminUser,
} from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";

import { useCognitoAuth } from "../../../../../../shared/modules/cognito-auth";
import { useHasPermissionQuery } from "../../../../../../shared/modules/permissions/hooks/use-has-permission-query";
import {
  DisplayStep,
  formatCandidateActionDate,
  getActionableStepLabel,
  getArchivedStepLabel,
} from "../../lib";
import { ConfirmArchiveCandidateModal } from "./confirm-archive-candidate-modal";
import { ConfirmCompleteCandidateModal } from "./confirm-complete-candidate-modal";
import { ConfirmReassignCandidateModal } from "./confirm-reassign-candidate-modal";

const PROMPTABLE_STEPS = [
  CandidateRecruitmentStepName.StartedWork,
  CandidateRecruitmentStepName.StartedOrientation,
  CandidateRecruitmentStepName.CompletedOrientation,
  CandidateRecruitmentStepName.CompletedFirstShift,
];

export interface ActionableCandidateStepProps extends StepIconProps {
  finalStep: boolean;
  candidateId: string;
  assignedToAdmin:
    | Pick<RewardsAdminUser, "id" | "firstName" | "lastName">
    | null
    | undefined;
  candidateName: string;
  referredBy: string;
  step: DisplayStep;
  onArchive(updatedAdminNotes: string): void;
  onComplete(): void;
  onReopen(): void;
}

export function ActionableCandidateStep({
  finalStep,
  candidateId,
  assignedToAdmin,
  candidateName,
  referredBy,
  icon,
  active,
  step,
  onArchive,
  onComplete,
  onReopen,
}: ActionableCandidateStepProps) {
  const { userId } = useCognitoAuth();
  const fullCandidatesPermissionQuery = useHasPermissionQuery(
    "full",
    "candidates"
  );

  const withConfirmation =
    step.type === "CandidateMonthlyRetentionStep" ||
    step.type === "CandidateHourlyRetentionStep" ||
    PROMPTABLE_STEPS.includes(step.stepName);

  const [archivePromptOpen, setArchivePromptOpen] = useState(false);
  const [confirmCompletePromptOpen, setConfirmCompletePromptOpen] = useState(
    false
  );
  const [confirmReassignPromptOpen, setConfirmReassignPromptOpen] = useState(
    false
  );

  const track = useTrack();

  const trackCancelledComplete = (
    variant: "late_stage" | "assigned_to_other_admin"
  ) => {
    track("Candidate step progression cancelled", {
      variant,
      candidateId,
      step,
    });
  };

  return (
    <>
      <ClassNames>
        {({ css, cx, theme }) => (
          <Card
            className={css`
              width: 138px;
              ${step.status === "actionable" &&
              css`
                min-height: 108px;
              `}
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-top: ${theme.spacing(1)};
              margin-bottom: ${theme.spacing(1)};
            `}
          >
            <CardContent
              className={css`
                display: flex;
                flex-direction: row;
                padding-top: ${theme.spacing(1.5)};
                padding-left: ${theme.spacing(1.5)};
                padding-right: ${theme.spacing(1)};
                &:last-child {
                  padding-bottom: ${theme.spacing(2)};
                }
              `}
            >
              <StepIcon
                active={active}
                icon={icon}
                classes={{
                  root: cx(
                    "custom-root",
                    css`
                      margin-top: ${theme.spacing(0.5)};
                      margin-left: -${theme.spacing(0.5)};
                      margin-right: ${theme.spacing(1)};
                    `
                  ),
                  active:
                    step.status === "archived"
                      ? css`
                          &.custom-root {
                            color: ${theme.palette.error.dark};
                          }
                        `
                      : undefined,
                }}
              />
              <div>
                <Typography variant="footnote" display="block">
                  {step.status === "actionable"
                    ? getActionableStepLabel(step)
                    : getArchivedStepLabel(step)}
                </Typography>
                {step.date && (
                  <Typography variant="footnote">
                    {formatCandidateActionDate(step.date)}
                  </Typography>
                )}
                {step.status === "archived" &&
                  fullCandidatesPermissionQuery.hasPermission && (
                    <TextButton
                      type="button"
                      onClick={onReopen}
                      className={css`
                        display: block;
                      `}
                    >
                      <Typography variant="footnote" color="textPrimary">
                        Re-open file
                      </Typography>
                    </TextButton>
                  )}
              </div>
            </CardContent>
            {step.status === "actionable" && (
              <CardActions
                className={css`
                  padding-top: 0px;
                  padding-left: ${theme.spacing(1)};
                  padding-right: ${theme.spacing(1)};
                  padding-bottom: ${theme.spacing(1)};
                `}
              >
                <Button
                  size="small"
                  variant="outlined"
                  label="No"
                  disabled={!fullCandidatesPermissionQuery.hasPermission}
                  onClick={() => setArchivePromptOpen(true)}
                />
                <Button
                  size="small"
                  color="primary"
                  label="Yes"
                  disabled={!fullCandidatesPermissionQuery.hasPermission}
                  onClick={() => {
                    const trackPromptOpened = (
                      variant: "late_stage" | "assigned_to_other_admin"
                    ) =>
                      track("Viewed step progression confirmation prompt", {
                        variant,
                        candidateId,
                        step,
                      });

                    if (withConfirmation) {
                      trackPromptOpened("late_stage");
                      setConfirmCompletePromptOpen(true);
                    } else if (
                      step.type === "CandidateRecruitmentStep" &&
                      assignedToAdmin &&
                      userId !== assignedToAdmin.id
                    ) {
                      trackPromptOpened("assigned_to_other_admin");
                      setConfirmReassignPromptOpen(true);
                    } else {
                      onComplete();
                    }
                  }}
                />
              </CardActions>
            )}
          </Card>
        )}
      </ClassNames>

      <ConfirmArchiveCandidateModal
        open={archivePromptOpen}
        onConfirm={onArchive}
        onClose={() => setArchivePromptOpen(false)}
        candidateName={candidateName}
        candidateId={candidateId}
      />

      <ConfirmCompleteCandidateModal
        finalStep={finalStep}
        title={getActionableStepLabel(step)}
        open={confirmCompletePromptOpen}
        onConfirm={() => {
          onComplete();
          setConfirmCompletePromptOpen(false);
        }}
        onCancel={() => {
          setConfirmCompletePromptOpen(false);
          trackCancelledComplete("late_stage");
        }}
        referredByUserName={referredBy}
        candidateName={candidateName}
      />

      <ConfirmReassignCandidateModal
        open={confirmReassignPromptOpen}
        onConfirm={() => {
          onComplete();
          setConfirmReassignPromptOpen(false);
        }}
        onCancel={() => {
          setConfirmReassignPromptOpen(false);
          trackCancelledComplete("assigned_to_other_admin");
        }}
        assignedToAdminName={`${assignedToAdmin?.firstName} ${assignedToAdmin?.lastName}`}
      />
    </>
  );
}
