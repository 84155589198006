import { Routes, Route } from "react-router";

import { Page } from "@rewards-web/shared/modules/page";

import { AuthenticatedRoutes } from "./authenticated/routes";
import { LoginPage } from "./login";

export function AppRoutes(): JSX.Element {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <Page browserTitle="Admin Login" analyticsPageName="Login">
            <LoginPage />
          </Page>
        }
      />
      <Route path="*" element={<AuthenticatedRoutes />} />
    </Routes>
  );
}
