import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GenerateRewardsAppLoginLinkMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type GenerateRewardsAppLoginLinkMutation = (
  { __typename?: 'Mutation' }
  & { generateMyRewardsUserLoginLink: (
    { __typename?: 'GenerateMyRewardsUserLoginLinkResponse' }
    & Pick<Types.GenerateMyRewardsUserLoginLinkResponse, 'loginLink'>
  ) }
);


export const GenerateRewardsAppLoginLinkDocument = gql`
    mutation GenerateRewardsAppLoginLink {
  generateMyRewardsUserLoginLink {
    loginLink
  }
}
    `;
export type GenerateRewardsAppLoginLinkMutationFn = Apollo.MutationFunction<GenerateRewardsAppLoginLinkMutation, GenerateRewardsAppLoginLinkMutationVariables>;

/**
 * __useGenerateRewardsAppLoginLinkMutation__
 *
 * To run a mutation, you first call `useGenerateRewardsAppLoginLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateRewardsAppLoginLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateRewardsAppLoginLinkMutation, { data, loading, error }] = useGenerateRewardsAppLoginLinkMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateRewardsAppLoginLinkMutation(baseOptions?: Apollo.MutationHookOptions<GenerateRewardsAppLoginLinkMutation, GenerateRewardsAppLoginLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateRewardsAppLoginLinkMutation, GenerateRewardsAppLoginLinkMutationVariables>(GenerateRewardsAppLoginLinkDocument, options);
      }
export type GenerateRewardsAppLoginLinkMutationHookResult = ReturnType<typeof useGenerateRewardsAppLoginLinkMutation>;
export type GenerateRewardsAppLoginLinkMutationResult = Apollo.MutationResult<GenerateRewardsAppLoginLinkMutation>;
export type GenerateRewardsAppLoginLinkMutationOptions = Apollo.BaseMutationOptions<GenerateRewardsAppLoginLinkMutation, GenerateRewardsAppLoginLinkMutationVariables>;