import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CandidateJobFilterOptionsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  offset: Types.Scalars['Int'];
}>;


export type CandidateJobFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsAdminUser?: Types.Maybe<(
    { __typename?: 'RewardsAdminUser' }
    & Pick<Types.RewardsAdminUser, 'id'>
    & { branches: Array<(
      { __typename?: 'RewardsOrganizationBranch' }
      & Pick<Types.RewardsOrganizationBranch, 'id' | 'name'>
    )> }
  )>, listJobPostings: (
    { __typename?: 'JobPostingListResponse' }
    & Pick<Types.JobPostingListResponse, 'total'>
    & { items: Array<(
      { __typename?: 'JobPosting' }
      & Pick<Types.JobPosting, 'id' | 'title' | 'geography' | 'closedForSubmission'>
      & { branch?: Types.Maybe<(
        { __typename?: 'RewardsOrganizationBranch' }
        & Pick<Types.RewardsOrganizationBranch, 'id' | 'name'>
      )> }
    )> }
  ) }
);


export const CandidateJobFilterOptionsDocument = gql`
    query CandidateJobFilterOptions($limit: Int!, $offset: Int!) {
  getMyRewardsAdminUser {
    id
    branches {
      id
      name
    }
  }
  listJobPostings(
    limit: $limit
    offset: $offset
    filter: {sort: [{property: CLOSED_FOR_SUBMISSION, direction: ASC}, {property: TITLE, direction: ASC}]}
  ) {
    total
    items {
      id
      title
      geography
      closedForSubmission
      branch {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useCandidateJobFilterOptionsQuery__
 *
 * To run a query within a React component, call `useCandidateJobFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateJobFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateJobFilterOptionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useCandidateJobFilterOptionsQuery(baseOptions: Apollo.QueryHookOptions<CandidateJobFilterOptionsQuery, CandidateJobFilterOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidateJobFilterOptionsQuery, CandidateJobFilterOptionsQueryVariables>(CandidateJobFilterOptionsDocument, options);
      }
export function useCandidateJobFilterOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidateJobFilterOptionsQuery, CandidateJobFilterOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidateJobFilterOptionsQuery, CandidateJobFilterOptionsQueryVariables>(CandidateJobFilterOptionsDocument, options);
        }
export type CandidateJobFilterOptionsQueryHookResult = ReturnType<typeof useCandidateJobFilterOptionsQuery>;
export type CandidateJobFilterOptionsLazyQueryHookResult = ReturnType<typeof useCandidateJobFilterOptionsLazyQuery>;
export type CandidateJobFilterOptionsQueryResult = Apollo.QueryResult<CandidateJobFilterOptionsQuery, CandidateJobFilterOptionsQueryVariables>;