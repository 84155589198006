/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faPlus, faPeopleArrows } from "@fortawesome/pro-regular-svg-icons";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ArrayPath,
  Control,
  Controller,
  FieldValues,
  useFieldArray,
} from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { AppTheme } from "@rewards-web/shared/style/types";

import { TagsSelectorField } from "./tags-selector-field";

export const DEFAULT_VALUE = [{}];
/**
 * Temporary limit for MVP
 */
const MAX_ALLOWED_ADD_TAG_COMBINATIONS = 2;

interface TagsConfigFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: ArrayPath<T>;
}

export function TagsConfigField<T extends FieldValues>({
  control,
  name,
}: TagsConfigFieldProps<T>): JSX.Element {
  const { fields, append, remove } = useFieldArray({ control, name });

  return (
    <>
      {fields.map((field, index) => (
        <div
          key={field.id}
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(2)};
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          {index > 0 && (
            <div
              css={(theme: AppTheme) => css`
                display: flex;
                align-items: center;
                margin-top: ${theme.spacing(2)};
                margin-bottom: ${theme.spacing(2)};
                &::before,
                &::after {
                  flex: 1;
                  content: "";
                  background-color: ${theme.palette.primary.main};
                  padding: ${theme.spacing(0.1)};
                  margin: ${theme.spacing(0.2)};
                }
              `}
            >
              <FontAwesomeIcon icon={faPeopleArrows} />
              &nbsp;
              <span>OR</span>
            </div>
          )}

          <Controller
            control={control}
            name={`${name}.${index}` as any}
            rules={{
              required: "Combination must have at least one tag",
            }}
            render={({ field, fieldState }) => (
              <TagsSelectorField
                {...field}
                css={css`
                  flex-grow: 1;
                `}
                error={fieldState.error}
              />
            )}
          />

          {index > 0 && (
            <Button
              css={(theme: AppTheme) => css`
                margin-top: ${theme.spacing(2)};
                margin-bottom: ${theme.spacing(2)};
              `}
              variant="outlined"
              color="primary"
              width="full"
              size="medium"
              label="Remove condition"
              type="button"
              startIcon={<FontAwesomeIcon icon={faXmark} />}
              onClick={() => remove(index)}
              aria-label={`remove-tags-combination-${index}`}
            />
          )}
        </div>
      ))}

      {fields.length < MAX_ALLOWED_ADD_TAG_COMBINATIONS && (
        <Button
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(2)};
            margin-bottom: ${theme.spacing(2)};
          `}
          variant="contained"
          color="primary"
          width="full"
          size="medium"
          label="Add condition"
          type="button"
          startIcon={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => append(DEFAULT_VALUE as any)}
        />
      )}
    </>
  );
}
