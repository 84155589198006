import { createContext } from "react";

interface AnalyticsContextValue {
  disabled: boolean;
  disable(): void;
}

export const AnalyticsContext = createContext<AnalyticsContextValue>({
  disabled: false,
  disable: () => {},
});
