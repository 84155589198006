import { FieldValues, Control, Path, Controller } from "react-hook-form";

import { SearchTextField } from "@rewards-web/shared/components/search-text-field";
import { isEmail } from "@rewards-web/shared/lib/is-email";

interface JobCandidateAlertEmailFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  emailAutocompleteOptions: string[];
}

export function JobCandidateAlertEmailField<T extends FieldValues>({
  control,
  name,
  emailAutocompleteOptions,
}: JobCandidateAlertEmailFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: "Candidate alert email is required",
        validate: (value: string) => {
          if (value && !isEmail(value)) {
            return "This is not a valid email";
          }
        },
      }}
      render={({ field, fieldState }) => (
        <SearchTextField
          {...field}
          fixLabelToTop
          placeholder="e.g. email@example.com"
          error={fieldState.error}
          label="Admin email"
          options={emailAutocompleteOptions.map((email) => ({
            label: email,
            value: email,
          }))}
          allowCustomValues
        />
      )}
    />
  );
}
