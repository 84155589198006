import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type HhaxCaregiverFilterOptionsQueryVariables = Types.Exact<{
  hhaxIntegrationId: Types.Scalars['ID'];
}>;


export type HhaxCaregiverFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { filterOptions?: Types.Maybe<(
    { __typename?: 'HHAXCaribouUserSyncFilters' }
    & Pick<Types.HhaxCaribouUserSyncFilters, 'disciplines' | 'hasCompletedFirstShift'>
    & { branches: Array<(
      { __typename?: 'HHAXCaribouUserSyncBranchFilterOption' }
      & Pick<Types.HhaxCaribouUserSyncBranchFilterOption, 'branchId'>
    )>, offices: Array<(
      { __typename?: 'HHAXCaribouUserSyncOfficeFilterOption' }
      & Pick<Types.HhaxCaribouUserSyncOfficeFilterOption, 'officeId'>
    )>, teams: Array<(
      { __typename?: 'HHAXCaribouUserSyncTeamFilterOption' }
      & Pick<Types.HhaxCaribouUserSyncTeamFilterOption, 'teamId'>
    )> }
  )> }
);


export const HhaxCaregiverFilterOptionsDocument = gql`
    query HHAXCaregiverFilterOptions($hhaxIntegrationId: ID!) {
  filterOptions: getCaribouUserSyncFilterOptionsForHHAXIntegration(
    hhaxIntegrationId: $hhaxIntegrationId
  ) {
    disciplines
    branches {
      branchId
    }
    offices {
      officeId
    }
    teams {
      teamId
    }
    hasCompletedFirstShift
  }
}
    `;

/**
 * __useHhaxCaregiverFilterOptionsQuery__
 *
 * To run a query within a React component, call `useHhaxCaregiverFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHhaxCaregiverFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHhaxCaregiverFilterOptionsQuery({
 *   variables: {
 *      hhaxIntegrationId: // value for 'hhaxIntegrationId'
 *   },
 * });
 */
export function useHhaxCaregiverFilterOptionsQuery(baseOptions: Apollo.QueryHookOptions<HhaxCaregiverFilterOptionsQuery, HhaxCaregiverFilterOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HhaxCaregiverFilterOptionsQuery, HhaxCaregiverFilterOptionsQueryVariables>(HhaxCaregiverFilterOptionsDocument, options);
      }
export function useHhaxCaregiverFilterOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HhaxCaregiverFilterOptionsQuery, HhaxCaregiverFilterOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HhaxCaregiverFilterOptionsQuery, HhaxCaregiverFilterOptionsQueryVariables>(HhaxCaregiverFilterOptionsDocument, options);
        }
export type HhaxCaregiverFilterOptionsQueryHookResult = ReturnType<typeof useHhaxCaregiverFilterOptionsQuery>;
export type HhaxCaregiverFilterOptionsLazyQueryHookResult = ReturnType<typeof useHhaxCaregiverFilterOptionsLazyQuery>;
export type HhaxCaregiverFilterOptionsQueryResult = Apollo.QueryResult<HhaxCaregiverFilterOptionsQuery, HhaxCaregiverFilterOptionsQueryVariables>;