/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { AuthorizedAdminPageWrapper } from "../../../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { SettingsAgencyCommunicationCard } from "./agency-communication";
import { SettingsEmailCommunicationCard } from "./email-communication-card";

export function SettingsCommunicationPage() {
  return (
    <AuthorizedAdminPageWrapper resource="settingsCommunication">
      <div
        css={css`
          margin-bottom: 16px;
        `}
      >
        <SettingsAgencyCommunicationCard />
      </div>
      <SettingsEmailCommunicationCard />
    </AuthorizedAdminPageWrapper>
  );
}
