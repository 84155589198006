/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { InputBaseComponentProps } from "@material-ui/core";
import { DropzoneRootProps } from "react-dropzone";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { UploadIcon } from "../upload-icon";

export function FileInputContents(
  props: DropzoneRootProps &
    InputBaseComponentProps & {
      selectedFileUrl: string;
      loadingImage: boolean;
      onReset: () => void;
      videoName: string;
    }
) {
  const {
    type,
    inputRef,
    selectedFileUrl,
    loadingImage,
    videoName,
    onReset,
    ...restProps
  } = props;

  const content = (() => {
    if (loadingImage) {
      return null;
    }

    if (selectedFileUrl) {
      return (
        <>
          <video
            css={css`
              max-height: 85%;
              max-width: 65%;
              pointer-events: auto;
            `}
            src={selectedFileUrl}
            controls
            onClick={(event) => {
              // This would prevent the file dialog for getting the click event and opening file upload system dialog
              event.stopPropagation();
            }}
          />
          <Typography variant="h4" color="grey.800">
            {videoName}
          </Typography>
          <div
            css={css`
              display: flex;
              justify-content: space-around;
              gap: 8px;
            `}
          >
            <Button
              size="small"
              width="auto"
              variant="outlined"
              color="primary"
              label={"Remove video"}
              onClick={(event) => {
                // This would prevent the file dialog for getting the click event and opening file upload system dialog
                event.stopPropagation();
                onReset();
              }}
              css={css`
                pointer-events: auto;
              `}
            />
            <Button
              size="small"
              width="auto"
              color="primary"
              label={"Upload a new video"}
              onClick={(event) => {
                // This would prevent the file dialog for getting the click event and opening file upload system dialog
                event.stopPropagation();
                onReset();
              }}
              css={css`
                pointer-events: auto;
              `}
            />
          </div>
        </>
      );
    }

    return (
      <>
        <UploadIcon width="4em" height="4em" />

        <Typography variant="h3">
          Select a file or drag and drop here
        </Typography>

        <Typography variant="h4" color="grey.800">
          Accepted file format is .mp4
        </Typography>

        <Typography variant="h4" color="grey.800">
          Video file should not exceed 100MB in size
        </Typography>

        <Button
          variant="contained"
          label="Select file"
          type="button"
          size="small"
          color="primary"
          css={css`
            max-width: 160px;
          `}
        />
      </>
    );
  })();

  return (
    <div
      {...restProps}
      css={(theme: AppTheme) => css`
        width: 100%;
        height: 100%;
        cursor: pointer;
        padding: ${theme.spacing(4)} ${theme.spacing(2)};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & > *:not(:last-child) {
          margin-bottom: ${theme.spacing(1)};
        }
      `}
    >
      {content}
    </div>
  );
}
