export function DocumentCheckIcon() {
  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.94922 3.52344C10.168 3.22266 10.5781 3.16797 10.8242 3.38672C11.125 3.60547 11.1797 4.01562 10.9609 4.28906L8.55469 7.35156C8.41797 7.51562 8.28125 7.59766 8.08984 7.59766C8.0625 7.625 8.03516 7.625 8.03516 7.625C7.89844 7.625 7.73438 7.57031 7.625 7.48828L6.09375 6.17578C5.82031 5.92969 5.79297 5.51953 6.01172 5.24609C6.25781 4.97266 6.66797 4.94531 6.94141 5.16406L7.95312 6.03906L9.94922 3.52344ZM3.25 10.9062C2.86719 10.9062 2.59375 10.6328 2.59375 10.25C2.59375 9.89453 2.86719 9.59375 3.25 9.59375V2.375C3.25 1.41797 4.01562 0.625 5 0.625H12C12.957 0.625 13.75 1.41797 13.75 2.375V9.59375C14.1055 9.59375 14.4062 9.89453 14.4062 10.25C14.4062 10.6328 14.1055 10.9062 13.75 10.9062H3.25ZM4.5625 2.375V9.59375H12.4375V2.375C12.4375 2.15625 12.2188 1.9375 12 1.9375H5C4.75391 1.9375 4.5625 2.15625 4.5625 2.375ZM14.9531 7.625C15.7188 7.625 16.375 8.28125 16.375 9.04688V11.3438C16.375 12.1914 15.6641 12.875 14.8438 12.875H2.15625C1.30859 12.875 0.625 12.1914 0.625 11.3438V9.04688C0.625 8.28125 1.25391 7.625 2.01953 7.625C2.375 7.625 2.67578 7.92578 2.67578 8.28125C2.67578 8.66406 2.375 8.9375 2.01953 8.9375C1.96484 8.9375 1.9375 8.99219 1.9375 9.04688V11.3438C1.9375 11.4805 2.01953 11.5625 2.15625 11.5625H14.8438C14.9531 11.5625 15.0625 11.4805 15.0625 11.3438V9.04688C15.0625 8.99219 15.0078 8.9375 14.9531 8.9375C14.5977 8.9375 14.2969 8.66406 14.2969 8.28125C14.2969 7.92578 14.5977 7.625 14.9531 7.625Z"
        fill="#444444"
      />
    </svg>
  );
}
