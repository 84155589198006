import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AddAdminRecognitionBudgetsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AddAdminRecognitionBudgetsQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'recognitionBudgetsEnabled' | 'pointsPerDollar'>
    & { automaticRecognitionBudgetConfig: (
      { __typename?: 'RewardsOrganizationAutomaticRecognitionBudgetConfig' }
      & Pick<Types.RewardsOrganizationAutomaticRecognitionBudgetConfig, 'frequency'>
    ) }
  ) }
);


export const AddAdminRecognitionBudgetsDocument = gql`
    query AddAdminRecognitionBudgets {
  getMyRewardsOrganization {
    id
    recognitionBudgetsEnabled
    automaticRecognitionBudgetConfig {
      frequency
    }
    pointsPerDollar
  }
}
    `;

/**
 * __useAddAdminRecognitionBudgetsQuery__
 *
 * To run a query within a React component, call `useAddAdminRecognitionBudgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddAdminRecognitionBudgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddAdminRecognitionBudgetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddAdminRecognitionBudgetsQuery(baseOptions?: Apollo.QueryHookOptions<AddAdminRecognitionBudgetsQuery, AddAdminRecognitionBudgetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddAdminRecognitionBudgetsQuery, AddAdminRecognitionBudgetsQueryVariables>(AddAdminRecognitionBudgetsDocument, options);
      }
export function useAddAdminRecognitionBudgetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddAdminRecognitionBudgetsQuery, AddAdminRecognitionBudgetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddAdminRecognitionBudgetsQuery, AddAdminRecognitionBudgetsQueryVariables>(AddAdminRecognitionBudgetsDocument, options);
        }
export type AddAdminRecognitionBudgetsQueryHookResult = ReturnType<typeof useAddAdminRecognitionBudgetsQuery>;
export type AddAdminRecognitionBudgetsLazyQueryHookResult = ReturnType<typeof useAddAdminRecognitionBudgetsLazyQuery>;
export type AddAdminRecognitionBudgetsQueryResult = Apollo.QueryResult<AddAdminRecognitionBudgetsQuery, AddAdminRecognitionBudgetsQueryVariables>;