import { useSetScreenRecordingIdentity } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";

import { useCognitoAuth } from "../../../../shared/modules/cognito-auth";
import { useRole } from "../../../../shared/modules/role";
import { useAdminAppAnalyticsAttributesQuery } from "./analytics-attributes.generated";

/**
 * Identifies a user for screen recording.
 * It relies on the user being able to query `getMyRewardsOrganization`
 * It will only run if the user is authenticated.
 */
export function useIdentifyUserForScreenRecording() {
  const { userId } = useCognitoAuth();
  const role = useRole();
  const setScreenRecordingIdentity = useSetScreenRecordingIdentity();
  useAdminAppAnalyticsAttributesQuery({
    skip: role.data?.role !== "admin",
    fetchPolicy: "cache-first",
    onError: reportError,
    onCompleted: ({
      getMyRewardsOrganization: { id: organizationId, isQA, isDemo },
    }) => {
      setScreenRecordingIdentity(userId, {
        organizationId: Number(organizationId),
        isQA,
        isDemo,
      });
    },
  });
}
