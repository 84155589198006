import { PageLayout } from "../../../../shared/components/page-layout";
import { AuthorizedAdminPageWrapper } from "../../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { DrawDetailsPageContents } from "./page-contents";

const MAX_CONTENT_WIDTH = 1000;

export function DrawDetailsPage(): JSX.Element {
  return (
    <AuthorizedAdminPageWrapper resource="draw">
      <PageLayout maxContentWidth={MAX_CONTENT_WIDTH}>
        <DrawDetailsPageContents />
      </PageLayout>
    </AuthorizedAdminPageWrapper>
  );
}
