import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ScheduleDrawCreatePresignedVideoUploadUrlMutationVariables = Types.Exact<{
  originalFilename: Types.Scalars['String'];
}>;


export type ScheduleDrawCreatePresignedVideoUploadUrlMutation = (
  { __typename?: 'Mutation' }
  & { createPresignedVideoUploadUrl: (
    { __typename?: 'CreatePresignedVideoUploadUrlResponse' }
    & Pick<Types.CreatePresignedVideoUploadUrlResponse, 'presignedVideoUploadUrl'>
    & { video: (
      { __typename?: 'RewardVideo' }
      & Pick<Types.RewardVideo, 'id' | 'title' | 'status' | 'url'>
    ) }
  ) }
);


export const ScheduleDrawCreatePresignedVideoUploadUrlDocument = gql`
    mutation ScheduleDrawCreatePresignedVideoUploadUrl($originalFilename: String!) {
  createPresignedVideoUploadUrl(originalFilename: $originalFilename) {
    presignedVideoUploadUrl
    video {
      id
      title
      status
      url
    }
  }
}
    `;
export type ScheduleDrawCreatePresignedVideoUploadUrlMutationFn = Apollo.MutationFunction<ScheduleDrawCreatePresignedVideoUploadUrlMutation, ScheduleDrawCreatePresignedVideoUploadUrlMutationVariables>;

/**
 * __useScheduleDrawCreatePresignedVideoUploadUrlMutation__
 *
 * To run a mutation, you first call `useScheduleDrawCreatePresignedVideoUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleDrawCreatePresignedVideoUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleDrawCreatePresignedVideoUploadUrlMutation, { data, loading, error }] = useScheduleDrawCreatePresignedVideoUploadUrlMutation({
 *   variables: {
 *      originalFilename: // value for 'originalFilename'
 *   },
 * });
 */
export function useScheduleDrawCreatePresignedVideoUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleDrawCreatePresignedVideoUploadUrlMutation, ScheduleDrawCreatePresignedVideoUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleDrawCreatePresignedVideoUploadUrlMutation, ScheduleDrawCreatePresignedVideoUploadUrlMutationVariables>(ScheduleDrawCreatePresignedVideoUploadUrlDocument, options);
      }
export type ScheduleDrawCreatePresignedVideoUploadUrlMutationHookResult = ReturnType<typeof useScheduleDrawCreatePresignedVideoUploadUrlMutation>;
export type ScheduleDrawCreatePresignedVideoUploadUrlMutationResult = Apollo.MutationResult<ScheduleDrawCreatePresignedVideoUploadUrlMutation>;
export type ScheduleDrawCreatePresignedVideoUploadUrlMutationOptions = Apollo.BaseMutationOptions<ScheduleDrawCreatePresignedVideoUploadUrlMutation, ScheduleDrawCreatePresignedVideoUploadUrlMutationVariables>;