import { ClassNames } from "@emotion/react";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { darken } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { format, parseISO } from "date-fns";
import { forwardRef } from "react";

import {
  ControlledFormFieldProps,
  StylableProps,
} from "@rewards-web/shared/types";

import { useFormControlContext } from "../form/form-control";
import { DateFieldProvider } from "./provider";

const DATEONLY_FORMAT = "yyyy-MM-dd";
const MONTHONLY_FORMAT = "yyyy-MM";

export function formatDateOnly(date: Date): string {
  return format(date, DATEONLY_FORMAT);
}

export function formatMonthOnly(date: Date): string {
  return format(date, MONTHONLY_FORMAT);
}

export interface DateFieldProps
  extends StylableProps,
    ControlledFormFieldProps {
  label: string;
  variant?: "date" | "month";
  minDate?: Date;
  maxDate?: Date;
}

export const DateField = forwardRef(
  (
    {
      className,
      label,
      error,
      name,
      value,
      onChange,
      onBlur,
      variant = "date",
      minDate,
      maxDate,
    }: DateFieldProps,
    ref: any
  ): JSX.Element => {
    const { submitting, readOnly } = useFormControlContext();

    return (
      <DateFieldProvider>
        <ClassNames>
          {({ css, theme }) => (
            <DatePicker
              innerRef={ref}
              name={name}
              className={className}
              InputProps={{
                className: css`
                  background-color: ${theme.palette.background.paper};
                  border-radius: ${theme.spacing(1)};

                  &:hover:not(.Mui-focused):not(.Mui-error)
                    .MuiOutlinedInput-notchedOutline {
                    border-color: ${darken(theme.palette.divider, 0.15)};
                  }
                  &.Mui-focused .MuiOutlinedInput-notchedOutline {
                    border-width: 1px;
                  }
                `,

                classes: {
                  notchedOutline: css`
                    border-color: ${theme.palette.divider};
                  `,
                },

                endAdornment: (
                  <FontAwesomeIcon
                    icon={faCalendar}
                    color={theme.palette.grey[800]}
                  />
                ),
              }}
              minDate={minDate}
              maxDate={maxDate}
              autoOk
              fullWidth
              variant="inline"
              inputVariant="outlined"
              label={label}
              readOnly={readOnly}
              format={variant === "date" ? "PPPP" : "MMMM Y"}
              value={value ? parseISO(value) : null}
              onChange={(date) => {
                onChange?.(
                  (() => {
                    if (!date) {
                      return null;
                    }

                    switch (variant) {
                      case "date":
                        return formatDateOnly(date);
                      case "month":
                        return formatMonthOnly(date);
                      default:
                        throw new Error(`Unrecognized variant ${variant}`);
                    }
                  })()
                );
              }}
              onBlur={onBlur}
              disabled={submitting}
              // ensure helper text stays rendered so height doesn't jump around:
              helperText={error?.message || " "}
              error={Boolean(error)}
              views={[variant === "month" ? "month" : "date"]}
            />
          )}
        </ClassNames>
      </DateFieldProvider>
    );
  }
);
