import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { HhaxCaribouUserSyncFiltersInput } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { useEnableHhaxAutoSyncMutation } from "./enable-hhax-auto-sync.generated";

export interface HhaxAutoSyncConfirmationModalProps {
  integrationId: string;
  filters: HhaxCaribouUserSyncFiltersInput;
  open: boolean;
  onClose(): void;
  onSuccess(): void;
}

export function EnableHHAXAutoSyncConfirmationModal({
  integrationId,
  filters,
  open,
  onClose,
  onSuccess,
}: HhaxAutoSyncConfirmationModalProps) {
  const track = useTrack();
  const snackbar = useSnackbar();
  const [enableAutoSync, { loading }] = useEnableHhaxAutoSyncMutation();

  const handleConfirm = async () => {
    try {
      await enableAutoSync({
        variables: {
          hhaxIntegrationId: integrationId,
          filters,
        },
      });
      track("HHAX auto-sync enabled", { integrationId });
      onSuccess();
    } catch (error) {
      reportError(error);
      snackbar.show({
        message:
          "Something went wrong while enabling auto-sync. Please try again.",
        severity: "error",
      });
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      width="580px"
      disableBackdropClick={loading}
    >
      <ModalTitle>Invite employees to the program?</ModalTitle>
      <ModalContent>
        <Typography variant="body" color="grey.800">
          Selected employees will be invited to the rewards program and
          auto-synchronization of new employees into Caribou Rewards will be
          activated. Employee invitations will be sent out on the programs
          launch date.
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          variant="outlined"
          width="auto"
          size="large"
          label="Cancel"
          onClick={onClose}
          disabled={loading}
        />
        <Button
          width="auto"
          size="large"
          color="primary"
          label="Confirm"
          onClick={handleConfirm}
          loading={loading}
        />
      </ModalActions>
    </Modal>
  );
}
