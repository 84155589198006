/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

export function BellIcon() {
  const theme = useTheme();
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1.854V2.4165C11.25 2.7915 13 4.76025 13 7.104V8.1665C13 9.57275 13.4688 10.9478 14.3438 12.0728L14.8125 12.6353C15 12.8853 15.0312 13.1978 14.9062 13.4478C14.7812 13.6978 14.5312 13.854 14.25 13.854H1.75C1.4375 13.854 1.1875 13.6978 1.0625 13.4478C0.9375 13.1978 0.96875 12.8853 1.15625 12.6353L1.625 12.0728C2.5 10.9478 3 9.57275 3 8.1665V7.104C3 4.76025 4.71875 2.7915 7 2.4165V1.854C7 1.32275 7.4375 0.854004 8 0.854004C8.53125 0.854004 9 1.32275 9 1.854ZM7.75 3.854C5.9375 3.854 4.5 5.32275 4.5 7.104V8.1665C4.5 9.6665 4.0625 11.104 3.25 12.354H12.7188C11.9062 11.104 11.5 9.6665 11.5 8.1665V7.104C11.5 5.32275 10.0312 3.854 8.25 3.854H7.75ZM10 14.854C10 15.3853 9.78125 15.9165 9.40625 16.2915C9.03125 16.6665 8.5 16.854 8 16.854C7.46875 16.854 6.9375 16.6665 6.5625 16.2915C6.1875 15.9165 6 15.3853 6 14.854H10Z"
        fill={theme.palette.text.primary}
      />
    </svg>
  );
}
