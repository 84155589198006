import * as React from "react";

function EmployeesLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 0.765625C13.957 0.765625 18 4.80859 18 9.76562C18 14.7578 13.957 18.7656 9 18.7656C4.00781 18.7656 0 14.7578 0 9.76562C0 4.80859 4.00781 0.765625 9 0.765625ZM9 17.6406C10.6523 17.6406 12.1992 17.1484 13.4648 16.2695C13.3242 14.5117 11.8828 13.1406 10.125 13.1406H7.875C6.08203 13.1406 4.64062 14.5117 4.5 16.2695C5.76562 17.1484 7.3125 17.6406 9 17.6406ZM14.4492 15.4258C15.9258 13.9844 16.875 11.9805 16.875 9.76562C16.875 5.44141 13.3242 1.89062 9 1.89062C4.64062 1.89062 1.125 5.44141 1.125 9.76562C1.125 11.9805 2.03906 13.9844 3.51562 15.4258C4.00781 13.4922 5.76562 12.0156 7.875 12.0156H10.125C12.1992 12.0156 13.957 13.4922 14.4492 15.4258ZM9 5.26562C10.5469 5.26562 11.8125 6.53125 11.8125 8.07812C11.8125 9.66016 10.5469 10.8906 9 10.8906C7.41797 10.8906 6.1875 9.66016 6.1875 8.07812C6.1875 6.53125 7.41797 5.26562 9 5.26562ZM9 9.76562C9.91406 9.76562 10.6875 9.02734 10.6875 8.07812C10.6875 7.16406 9.91406 6.39062 9 6.39062C8.05078 6.39062 7.3125 7.16406 7.3125 8.07812C7.3125 9.02734 8.05078 9.76562 9 9.76562Z"
        fill={props.color}
      />
    </svg>
  );
}

export default EmployeesLogo;
