/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { Role } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

import { HeaderWithButton } from "../../../shared/components/header-with-button";
import { useHasPermissionQuery } from "../../../shared/modules/permissions/hooks/use-has-permission-query";
import { useMyBudgetsDataQuery } from "./my-budgets-data.generated";
import { OverBudgetModal } from "./overbudget-modal";
import { useRecognitionPageDataQuery } from "./recognition-page-data.generated";
import { RecognitionPointsModal } from "./recognition-points-modal";
import { useRecognitionPointsModalBudgetSummaryQuery } from "./recognition-points-modal-budget-summary.generated";
import { RecognitionTabs } from "./recognition-tabs";

export function RecognitionPageContents() {
  const [recognitionModalOpen, setRecognitionModalOpen] = useState(false);
  const [overBudgetModalOpen, setOverBudgetModalOpen] = useState(false);
  const recognitionPointsPermissionQuery = useHasPermissionQuery(
    "full",
    "recognitionPoints"
  );
  const bulkRecognitionEnabled = useFeatureFlag(
    "admin-app-bulk-recognition-page-enabled-temp"
  );
  const navigate = useNavigate();

  const query = useRecognitionPageDataQuery({
    onError: reportError,
    fetchPolicy: "cache-and-network",
  });

  const isSuperuser = query.data?.myIdentity?.roles.some(
    (role) => role.roleName === Role.Superuser
  );
  const myBudgetsQuery = useMyBudgetsDataQuery({
    fetchPolicy: "cache-first",
    skip: !query.data?.organization.recognitionBudgetsEnabled || isSuperuser,
    onError: reportError,
  });

  const currentBudgetPeriod = myBudgetsQuery.data?.listMyRecognitionBudgetPeriods?.items.find(
    (period) => period.current
  );

  const currentBudgetSummaryQuery = useRecognitionPointsModalBudgetSummaryQuery(
    {
      fetchPolicy: "cache-first",
      skip: !currentBudgetPeriod,
      onError: reportError,
      variables: {
        startDate: currentBudgetPeriod?.startDate,
        endDate: currentBudgetPeriod?.endDate,
      },
    }
  );

  if (query.error) {
    return (
      <Alert
        severity="error"
        message="An unexpected error occurred. Please try again later."
      />
    );
  }

  if (!query.data) {
    return <PageLoadingState />;
  }

  const safePointsAvailableInBudget =
    currentBudgetSummaryQuery.data?.getMyRecognitionPointsSentSummaryV2
      .pointsAvailableInBudget ?? 0;

  const handleOpenRecognitionModal = () => {
    if (
      !isSuperuser &&
      query.data?.organization.recognitionBudgetsEnabled &&
      query.data?.myAdmin?.permissions.__typename ===
        "RewardsAdminRestrictedAccessPermissions" &&
      query.data.myAdmin.permissions.permissionsV2?.recognitionPoints
        ?.canOverrideBudget === false &&
      safePointsAvailableInBudget === 0
    ) {
      setOverBudgetModalOpen(true);
    } else {
      if (bulkRecognitionEnabled) {
        navigate("/recognition/give-points");
      } else {
        setRecognitionModalOpen(true);
      }
    }
  };

  const giveRecognitionPointsDisabled =
    !recognitionPointsPermissionQuery.hasPermission || // can't edit recognition
    query.data.organization.launched === false || // pre-launch
    (query.data.myAdmin?.permissions.__typename ===
      "RewardsAdminRestrictedAccessPermissions" &&
      query.data.myAdmin.permissions.permissionsV2?.recognitionPoints
        ?.canOverrideRecognitionCategoryDefaultAmounts === false &&
      query.data.categoriesWithDefaultAmount.length === 0); // can't send recognition due to permissions

  return (
    <>
      <HeaderWithButton
        header={
          <Typography variant="h1" color="textPrimary" gutterBottom>
            Recognition
          </Typography>
        }
        button={
          <Button
            css={css`
              height: fit-content;
              margin-left: auto;
            `}
            width="auto"
            size="medium"
            color="primary"
            label="Give Recognition Points"
            onClick={handleOpenRecognitionModal}
            disabled={giveRecognitionPointsDisabled}
          />
        }
      />

      <RecognitionPointsModal
        open={recognitionModalOpen}
        onClose={() => setRecognitionModalOpen(false)}
      />

      <OverBudgetModal
        open={overBudgetModalOpen}
        onCancel={() => setOverBudgetModalOpen(false)}
      />

      <RecognitionTabs />
      <Outlet />
    </>
  );
}
