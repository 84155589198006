import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CorrectReferringEmployeeMutationVariables = Types.Exact<{
  candidateId: Types.Scalars['ID'];
  referredByUserId: Types.Scalars['ID'];
}>;


export type CorrectReferringEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { correctCandidateReferredByUser: (
    { __typename?: 'Candidate' }
    & Pick<Types.Candidate, 'id'>
    & { referredByUser: (
      { __typename?: 'RewardsUser' }
      & Pick<Types.RewardsUser, 'id'>
    ) }
  ) }
);


export const CorrectReferringEmployeeDocument = gql`
    mutation CorrectReferringEmployee($candidateId: ID!, $referredByUserId: ID!) {
  correctCandidateReferredByUser(
    candidateId: $candidateId
    referredByUserId: $referredByUserId
  ) {
    id
    referredByUser {
      id
    }
  }
}
    `;
export type CorrectReferringEmployeeMutationFn = Apollo.MutationFunction<CorrectReferringEmployeeMutation, CorrectReferringEmployeeMutationVariables>;

/**
 * __useCorrectReferringEmployeeMutation__
 *
 * To run a mutation, you first call `useCorrectReferringEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCorrectReferringEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [correctReferringEmployeeMutation, { data, loading, error }] = useCorrectReferringEmployeeMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      referredByUserId: // value for 'referredByUserId'
 *   },
 * });
 */
export function useCorrectReferringEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<CorrectReferringEmployeeMutation, CorrectReferringEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CorrectReferringEmployeeMutation, CorrectReferringEmployeeMutationVariables>(CorrectReferringEmployeeDocument, options);
      }
export type CorrectReferringEmployeeMutationHookResult = ReturnType<typeof useCorrectReferringEmployeeMutation>;
export type CorrectReferringEmployeeMutationResult = Apollo.MutationResult<CorrectReferringEmployeeMutation>;
export type CorrectReferringEmployeeMutationOptions = Apollo.BaseMutationOptions<CorrectReferringEmployeeMutation, CorrectReferringEmployeeMutationVariables>;