import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SubmitReportRequestMutationVariables = Types.Exact<{
  dataSets: Array<Types.RewardReportDataSetConfigInput> | Types.RewardReportDataSetConfigInput;
}>;


export type SubmitReportRequestMutation = (
  { __typename?: 'Mutation' }
  & { submitReportRequest: (
    { __typename?: 'RewardReport' }
    & Pick<Types.RewardReport, 'id'>
  ) }
);


export const SubmitReportRequestDocument = gql`
    mutation SubmitReportRequest($dataSets: [RewardReportDataSetConfigInput!]!) {
  submitReportRequest(dataSets: $dataSets) {
    id
  }
}
    `;
export type SubmitReportRequestMutationFn = Apollo.MutationFunction<SubmitReportRequestMutation, SubmitReportRequestMutationVariables>;

/**
 * __useSubmitReportRequestMutation__
 *
 * To run a mutation, you first call `useSubmitReportRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitReportRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitReportRequestMutation, { data, loading, error }] = useSubmitReportRequestMutation({
 *   variables: {
 *      dataSets: // value for 'dataSets'
 *   },
 * });
 */
export function useSubmitReportRequestMutation(baseOptions?: Apollo.MutationHookOptions<SubmitReportRequestMutation, SubmitReportRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitReportRequestMutation, SubmitReportRequestMutationVariables>(SubmitReportRequestDocument, options);
      }
export type SubmitReportRequestMutationHookResult = ReturnType<typeof useSubmitReportRequestMutation>;
export type SubmitReportRequestMutationResult = Apollo.MutationResult<SubmitReportRequestMutation>;
export type SubmitReportRequestMutationOptions = Apollo.BaseMutationOptions<SubmitReportRequestMutation, SubmitReportRequestMutationVariables>;