import * as React from "react";

export function EditIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.737 10.087L15.532.293a1 1 0 011.414 0l1.111 1.111a1 1 0 010 1.414l-9.794 9.795-.005-.005-2.542.027.027-2.542-.006-.006zM0 3.025a1 1 0 011-1h5.858a1 1 0 011 1v.142a1 1 0 01-1 1H0V3.025zm2.143 1.142V16.31h13.571v1.143a1 1 0 01-1 1H1a1 1 0 01-1-1V4.167h2.143zm13.572 7.429a1 1 0 00-1-1h-.143a1 1 0 00-1 1v4.714h2.143v-4.714z"
        fill="#C4C4C4"
      />
    </svg>
  );
}
