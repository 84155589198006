/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

import { ObscureRecordedText } from "@rewards-web/shared/components/obscure-recorded-text";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Typography } from "@rewards-web/shared/components/typography";
import { RecognitionBudgetPeriod } from "@rewards-web/shared/graphql-types";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { DataTable } from "../../../../../shared/components/data-table";
import { formatBudgetPeriodRange } from "../lib";
import { useManagedEmployeesRecognitionDataTableQuery } from "./managed-employees-recognition-data-table-query.generated";

const PER_PAGE = 10;

interface ManagedEmployeesRecognitionDataTableProps {
  period?: Pick<RecognitionBudgetPeriod, "startDate" | "endDate">;
}

export function ManagedEmployeesRecognitionDataTable({
  period,
}: ManagedEmployeesRecognitionDataTableProps) {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const query = useManagedEmployeesRecognitionDataTableQuery({
    onError: reportError,
    variables: {
      filter: {
        range: period
          ? {
              startDate: period.startDate,
              endDate: period.endDate,
            }
          : undefined,
      },
      page: {
        offset: currentPageIndex * PER_PAGE,
        limit: PER_PAGE,
      },
    },
  });

  const hasManagedEmployees =
    ((query.data ?? query.previousData)
      ?.listMyManagedEmployeesRecognitionSummaryV2.total ?? 0) > 0;

  if (!hasManagedEmployees) {
    return null; // just don't show the table if there are no managed employees
  }

  return (
    <>
      <Typography
        color="textPrimary"
        component="h2"
        variant="h3"
        css={(theme: AppTheme) => css`
          margin-top: ${theme.spacing(3)};
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        Points My Team Received{" "}
        {period && (
          <>
            this Period{" "}
            <sub
              css={(theme: AppTheme) =>
                css`
                  color: ${theme.palette.primary.main};
                  vertical-align: middle;
                  white-space: nowrap;
                `
              }
            >
              ({formatBudgetPeriodRange(period.startDate, period.endDate)})
            </sub>
          </>
        )}
      </Typography>
      <DataTable
        itemsPerPage={PER_PAGE}
        currentPageIndex={currentPageIndex}
        onCurrentPageIndexChange={setCurrentPageIndex}
        emptyText="You do not have any managed employees." // this shouldn't really happen
        loading={query.loading}
        items={query.data?.listMyManagedEmployeesRecognitionSummaryV2.items}
        total={query.data?.listMyManagedEmployeesRecognitionSummaryV2.total}
        tableHeaders={
          <TableRow>
            <TableHeader>
              <Typography variant="body">Employee Name</Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="body">Employee Status</Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="body">Points (YTD)</Typography>
            </TableHeader>
            {period && (
              <TableHeader>
                <Typography variant="body">Points (This period)</Typography>
              </TableHeader>
            )}
          </TableRow>
        }
        tableBody={query.data?.listMyManagedEmployeesRecognitionSummaryV2.items.map(
          (item) => {
            return (
              <TableRow key={item.id}>
                <TableCell divider>
                  <ObscureRecordedText>
                    <Typography variant="body" color="textSecondary">
                      {item.employee.firstName} {item.employee.lastName}
                    </Typography>
                  </ObscureRecordedText>
                </TableCell>
                <TableCell divider>
                  <Typography variant="body" color="textSecondary">
                    {item.employee.active ? "Active" : "De-activated"}
                  </Typography>
                </TableCell>
                <TableCell divider>
                  <Typography variant="body" color="textSecondary">
                    {numberWithCommas(item.pointsReceivedYTD)}
                  </Typography>
                </TableCell>
                {period && (
                  <TableCell divider>
                    <Typography variant="body" color="textSecondary">
                      {item.pointsReceivedInRange
                        ? numberWithCommas(item.pointsReceivedInRange)
                        : "-"}
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            );
          }
        )}
      />
    </>
  );
}
