import { reportError } from "@rewards-web/shared/modules/error";

const STORAGE_KEY = "logged_in_since_sms_mfa_mandatory";

/**
 * Sets a flag in localstorage
 */
export function setLoggedInSinceMfaMandatory(value: boolean) {
  try {
    if (value) {
      window.localStorage.setItem(STORAGE_KEY, "true");
    } else {
      window.localStorage.removeItem(STORAGE_KEY);
    }
  } catch (error) {
    reportError(error);
  }
}

export function getLoggedInSinceMfaMandatory(): boolean {
  return window.localStorage.getItem(STORAGE_KEY) === "true";
}
