import { useEffect, useRef } from "react";

/**
 * Returns the previous value of the passed-in value
 * (from the previous render)
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  return ref.current;
}
