import { ClassNames } from "@emotion/react";
import { ReactNode } from "react";

import { Typography } from "../../typography";

interface SelectCheckboxesFieldValueProps {
  leftIcon?: ReactNode;
  rightAdornment?: ReactNode;
  label: string;
  variant: "filter" | "field";
  disabled: boolean;
}

export function SelectCheckboxesFieldValue({
  leftIcon,
  rightAdornment,
  label,
  variant,
  disabled,
}: SelectCheckboxesFieldValueProps) {
  return (
    <ClassNames>
      {({ css, theme }) => (
        <div
          className={css`
            width: 100%;
            display: flex;
          `}
        >
          {leftIcon}
          <div
            className={css`
              ${variant === "filter" &&
              css`
                margin-left: ${theme.spacing(1)};
              `}
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Typography
              color={disabled ? "textSecondary" : "textPrimary"}
              variant="body"
            >
              {label}
            </Typography>
            {rightAdornment}
          </div>
        </div>
      )}
    </ClassNames>
  );
}
