/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Control,
  FieldValues,
  Path,
  useWatch,
  get,
  Controller,
  UseFormSetValue,
  useFormState,
} from "react-hook-form";

import { SwitchField } from "@rewards-web/shared/components/switch";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { AppTheme } from "@rewards-web/shared/style/types";

const INTEGERS_REGEX = /^([+-]?[1-9]\d*|0)$/;
const MIN_VALUE = 1;
const MAX_VALUE = 100_000; // $1,000.00

interface RecognitionCategoryDefaultPointsProps<T extends FieldValues> {
  control: Control<T>;
  enabledSwitchName: Path<T>;
  name: Path<T>;
  setValue: UseFormSetValue<T>;
  pointsPerDollar: number;
}

export function RecognitionCategoryDefaultPoints<T extends FieldValues>({
  control,
  enabledSwitchName,
  name,
  setValue,
  pointsPerDollar,
}: RecognitionCategoryDefaultPointsProps<T>) {
  const formState = useFormState({ control });
  const switchValue: boolean = useWatch({ control, name: enabledSwitchName });
  const defaultPointsValue: string = useWatch({ control, name });

  return (
    <div
      css={(theme: AppTheme) => css`
        border-radius: 10px;
        border: 1px solid ${theme.palette.grey[400]};
        padding: ${theme.spacing(2)};
        padding-bottom: 0;
        margin-bottom: ${theme.spacing(2.5)};
      `}
    >
      <div
        css={(theme: AppTheme) => css`
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <div>
          <Typography
            variant="subtitle"
            color="textPrimary"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(1)};
            `}
          >
            Category default points
          </Typography>
          <Typography variant="footnote" color="grey.800">
            Set default points for this category
          </Typography>
        </div>
        <Controller
          control={control}
          name={enabledSwitchName}
          render={({ field }) => (
            <SwitchField
              label="Category default points enabled"
              hideLabel
              {...field}
              value={field.value ?? false}
              onChange={(e, value) => {
                field.onChange(e, value);
                if (!value) {
                  // clear the actual point value, so it isn't
                  // actually used on submission
                  setValue(name, "" as any);
                }
              }}
            />
          )}
        />
      </div>

      {switchValue && (
        <TextField
          type="text"
          label="Default points"
          disableAutocomplete
          endAdornment={
            defaultPointsValue && defaultPointsValue.match(INTEGERS_REGEX) ? (
              <Typography variant="body" fontWeight={600} color="grey.800">
                {formatDollars(Number(defaultPointsValue) / pointsPerDollar)}
              </Typography>
            ) : undefined
          }
          error={get(formState.errors, name)}
          {...control.register(name, {
            required: switchValue
              ? "If enabled, default points are required"
              : undefined,
            validate: (value) => {
              if (value !== "") {
                if (!value.match(INTEGERS_REGEX)) {
                  return "You can only enter numbers (no decimals)";
                }
                if (parseInt(value, 10) < MIN_VALUE) {
                  return "Category default points must be greater than 0";
                }
                if (parseInt(value, 10) > MAX_VALUE) {
                  return `Category default points must ${MAX_VALUE} or less`;
                }
              }
            },
          })}
        />
      )}
    </div>
  );
}
