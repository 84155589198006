import { useApolloClient } from "@apollo/client";
import { noop } from "lodash";
import { createContext, ReactNode, useContext, useState } from "react";

import { useRole } from "../role";

const SuperuserTenantSelectorContext = createContext<{
  selectedTenantId: number | null;
  loading: boolean;
  setSelectedTenantId(tenantId: number | null): void;
}>({ selectedTenantId: null, loading: false, setSelectedTenantId: noop });

export const selectedTenant: { value: number | null } = { value: null };

interface SuperuserTenantSelectorProviderProps {
  children: ReactNode;
}

export function SuperuserTenantSelectorProvider({
  children,
}: SuperuserTenantSelectorProviderProps) {
  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [selectedTenantId, setSelectedTenantId] = useState<number | null>(null);
  const handleChange = async (tenantId: number | null) => {
    setLoading(true);
    selectedTenant.value = tenantId;
    setSelectedTenantId(tenantId);
    await apolloClient.resetStore();
    setLoading(false);
  };

  return (
    <SuperuserTenantSelectorContext.Provider
      value={{
        selectedTenantId,
        setSelectedTenantId: handleChange,
        loading,
      }}
    >
      {children}
    </SuperuserTenantSelectorContext.Provider>
  );
}

export function useSelectedSuperuserTenantId(): [
  {
    canMakeQueriesWithTenant: boolean;
    selectedTenantId: number | null;
  },
  (tenantId: number | null) => void
] {
  const role = useRole();
  const { selectedTenantId, loading, setSelectedTenantId } = useContext(
    SuperuserTenantSelectorContext
  );

  const canMakeQueriesWithTenant =
    role.data?.role === "admin" || (Boolean(selectedTenantId) && !loading);

  return [{ canMakeQueriesWithTenant, selectedTenantId }, setSelectedTenantId];
}
