import * as Types from '@rewards-web/shared/graphql-types';

import { CandidateListDetailsFragment } from '../../candidate-fragment.generated';
import { gql } from '@apollo/client';
import { CandidateListDetailsFragmentDoc } from '../../candidate-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RecordRetentionStepMutationVariables = Types.Exact<{
  candidateId: Types.Scalars['ID'];
  durationMonths: Types.Scalars['Int'];
}>;


export type RecordRetentionStepMutation = (
  { __typename?: 'Mutation' }
  & { recordCandidateRetentionStep: (
    { __typename?: 'Candidate' }
    & CandidateListDetailsFragment
  ) }
);


export const RecordRetentionStepDocument = gql`
    mutation RecordRetentionStep($candidateId: ID!, $durationMonths: Int!) {
  recordCandidateRetentionStep(
    candidateId: $candidateId
    durationMonths: $durationMonths
  ) {
    ...CandidateListDetails
  }
}
    ${CandidateListDetailsFragmentDoc}`;
export type RecordRetentionStepMutationFn = Apollo.MutationFunction<RecordRetentionStepMutation, RecordRetentionStepMutationVariables>;

/**
 * __useRecordRetentionStepMutation__
 *
 * To run a mutation, you first call `useRecordRetentionStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordRetentionStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordRetentionStepMutation, { data, loading, error }] = useRecordRetentionStepMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      durationMonths: // value for 'durationMonths'
 *   },
 * });
 */
export function useRecordRetentionStepMutation(baseOptions?: Apollo.MutationHookOptions<RecordRetentionStepMutation, RecordRetentionStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecordRetentionStepMutation, RecordRetentionStepMutationVariables>(RecordRetentionStepDocument, options);
      }
export type RecordRetentionStepMutationHookResult = ReturnType<typeof useRecordRetentionStepMutation>;
export type RecordRetentionStepMutationResult = Apollo.MutationResult<RecordRetentionStepMutation>;
export type RecordRetentionStepMutationOptions = Apollo.BaseMutationOptions<RecordRetentionStepMutation, RecordRetentionStepMutationVariables>;