/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

import { CheckboxField } from "@rewards-web/shared/components/checkbox-field";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { AppTheme } from "@rewards-web/shared/style/types";

import { ReportDateRangeField } from "../report-date-range-field";
import { ReportDateRangeFormValuePreset } from "../report-date-range-field/lib";

interface ReportCheckboxAndRangeFieldProps<TValues extends FieldValues> {
  control: Control<TValues>;

  /**
   * If `false`, the report is not available, and should be greyed out.
   */
  available: boolean;

  /**
   * If this report is not available, this may be populated with the date that it becomes available.
   */
  availableAt: Date | null;
  checkboxName: Path<TValues>;
  checkboxLabel: string;
  dateRangeName: Path<TValues>;
  tooltipText: string;
  timezone: string;
  dateRangeOptions: ReportDateRangeFormValuePreset[];
  customRangeVariant?: "date" | "month";
  customRangeMin?: Date;
  customRangeMax?: Date;
  now: Date;
}

/**
 * Convenience component which renders a report checkbox (to enable a report option).
 * If the checkbox is checked, displays the date range field corresponding to that selector.
 */
export function ReportCheckboxAndRangeField<TValues extends FieldValues>({
  control,
  available,
  availableAt,
  checkboxName,
  checkboxLabel,
  dateRangeName,
  tooltipText,
  timezone,
  dateRangeOptions: dateRangePresetOptions,
  customRangeVariant,
  customRangeMin,
  customRangeMax,
  now,
}: ReportCheckboxAndRangeFieldProps<TValues>) {
  return (
    <Controller
      control={control}
      name={checkboxName}
      render={({ field, fieldState }) => (
        <>
          <CheckboxField
            {...field}
            onChange={(...args) => {
              field.onChange(...args);
            }}
            disabled={!available}
            error={fieldState.error}
            disabledTooltipTitle={
              !available
                ? `This report is currently unavailable due to insufficient data for accurate insights. It will be ready for download ${
                    availableAt ? `on ${format(availableAt, "MMMM ko")} ` : ""
                  }as more data accumulates.`
                : undefined
            }
            label={
              <>
                {checkboxLabel}
                {available && (
                  <Tooltip title={tooltipText} placement="right">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      css={(theme: AppTheme) => css`
                        margin-left: ${theme.spacing(1)};
                      `}
                    />
                  </Tooltip>
                )}
              </>
            }
            display="block"
            css={(theme: AppTheme) => css`
              margin-bottom: ${field.value
                ? theme.spacing(2)
                : theme.spacing(0)};
            `}
          />

          {field.value && (
            <ReportDateRangeField
              control={control}
              name={dateRangeName}
              timezone={timezone}
              presetOptions={dateRangePresetOptions}
              now={now}
              customRangeVariant={customRangeVariant}
              customRangeMin={customRangeMin}
              customRangeMax={customRangeMax}
            />
          )}
        </>
      )}
    />
  );
}
