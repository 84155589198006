/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { addMonths, parseISO } from "date-fns";
import { useEffect } from "react";
import {
  Control,
  Controller,
  Path,
  useController,
  useWatch,
} from "react-hook-form";

import { DateField } from "@rewards-web/shared/components/date-field";
import { SelectField } from "@rewards-web/shared/components/select-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

import {
  getLabelsForDateRangePreset,
  ReportDateRangeFormValuePreset,
} from "./lib";

export interface IReportDateRangeField {
  control: Control<any>;

  name: Path<any>;

  /**
   * The specific options to allow the user to select.
   * At least one option must be provided.
   */
  presetOptions: ReportDateRangeFormValuePreset[];

  customRangeVariant?: "date" | "month";

  /**
   * Represents the UTC date of the minimum-selectable custom date
   */
  customRangeMin?: Date;

  /**
   * Represents the UTC date of the maximum-selectable custom date
   */
  customRangeMax?: Date;

  /**
   * The timezone to apply to date ranges
   */
  timezone: string;

  /**
   * Fixes the current date, to avoid re-renders from changing the
   * represented dates.
   *
   * (e.g. if midnight passes while user is looking at the page).
   */
  now: Date;
}

/**
 * A 'date range' field.
 *
 * - The user can select a 'preset' value via a `SelectField`.
 * - (Future) the user can also select a custom date range.
 */
export function ReportDateRangeField({
  control,
  presetOptions,
  name,
  customRangeMin,
  customRangeMax,
  timezone,
  customRangeVariant = "date",
  now,
}: IReportDateRangeField) {
  const presetValue = useWatch({ control, name: `${name}.preset` });
  const startValue = useWatch({ control, name: `${name}.customStart` });
  const endController = useController({ control, name: `${name}.customEnd` });

  useEffect(() => {
    // when start is set, and end is not set, set 'end' to same date as 'start'

    if (!endController.field.value) {
      endController.field.onChange(startValue);
    }
  }, [startValue, endController]);

  return (
    <div>
      <Controller
        control={control}
        name={`${name}.preset`}
        render={({ field }) => (
          <SelectField
            {...field}
            width="full"
            label="Date range"
            options={presetOptions.map((preset) => ({
              value: preset,
              ...getLabelsForDateRangePreset(preset, now, timezone),
            }))}
            hideSpaceForErrorText
          />
        )}
      />

      {presetValue === "custom" && (
        <div>
          <Typography
            variant="footnote"
            color="grey.800"
            css={(theme: AppTheme) => css`
              margin-top: ${theme.spacing(1)};
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            Custom date range
          </Typography>

          <div
            css={(theme: AppTheme) => css`
              display: flex;
              gap: ${theme.spacing(1)};
            `}
          >
            <Controller
              control={control}
              name={`${name}.customStart`}
              rules={{
                required: "Start date is required",
              }}
              render={({ field, fieldState }) => (
                <DateField
                  {...field}
                  error={fieldState.error}
                  label="Start date"
                  variant={customRangeVariant}
                  minDate={customRangeMin}
                  maxDate={customRangeMax}
                />
              )}
            />

            <Controller
              control={control}
              name={`${name}.customEnd`}
              rules={{
                required: "End date is required",
                validate: (value) => {
                  if (!value) {
                    return;
                  }

                  if (startValue) {
                    if (value < startValue) {
                      return "End date cannot be before the start date.";
                    }

                    if (parseISO(value) > addMonths(parseISO(startValue), 3)) {
                      return "Date range cannot exceed 3 months";
                    }
                  }
                },
              }}
              render={({ field, fieldState }) => (
                <DateField
                  {...field}
                  error={fieldState.error}
                  label="End date"
                  variant={customRangeVariant}
                  minDate={customRangeMin}
                  maxDate={customRangeMax}
                />
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
}
