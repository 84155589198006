import {
  StylesProvider,
} from "@material-ui/core/styles";

import { DeepPartial } from "../types";

import "./global.css";

import { AppThemeProvider } from "./theme";
import { AppThemeProperties } from './types'

interface StyleProvidersProps {
  children: React.ReactNode;
  renderCssBaseline?: boolean;
  theme?: DeepPartial<AppThemeProperties>;
}

/**
 * Should wrap the application.
 */
export function StyleProviders({
  children,
  theme,
  renderCssBaseline
}: StyleProvidersProps): JSX.Element {
  return <StylesProvider injectFirst>
    <AppThemeProvider theme={theme ?? {}} renderCssBaseline={renderCssBaseline}>
      {children}
    </AppThemeProvider>
  </StylesProvider>
}
