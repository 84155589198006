import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
export type OnboardingProfileDataFragmentFragment = (
  { __typename?: 'RewardsOrganization' }
  & Pick<Types.RewardsOrganization, 'id' | 'shortName' | 'longName' | 'emailBannerLogoImageUrl' | 'timezone'>
  & { onboardingState: (
    { __typename?: 'RewardsOrganizationOnboardingState' }
    & Pick<Types.RewardsOrganizationOnboardingState, 'id' | 'businessType'>
  ) }
);

export const OnboardingProfileDataFragmentFragmentDoc = gql`
    fragment OnboardingProfileDataFragment on RewardsOrganization {
  id
  shortName
  longName
  onboardingState {
    id
    businessType
  }
  emailBannerLogoImageUrl
  timezone
}
    `;