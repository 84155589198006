import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { assertNever } from "@rewards-web/shared/lib/assert-never";

import { NavigationTabs } from "../../../../shared/components/navigation-tabs";
import {
  RecognitionPageTab,
  RecognitionTabPathByTab,
  useEnabledRecognitionPageTabsQuery,
} from "../use-enabled-recognition-page-tabs-query";

export function RecognitionTabs() {
  const enabledTabsQuery = useEnabledRecognitionPageTabsQuery();

  if (enabledTabsQuery.error) {
    return (
      <Alert
        severity="error"
        message="An unexpected error occurred. Please try again later."
      />
    );
  }

  if (!enabledTabsQuery.data) {
    return <PageLoadingState />;
  }

  return (
    <NavigationTabs
      ariaLabel="Recognition navigation"
      tabs={enabledTabsQuery.data.map(({ tab, badge }) => ({
        path: RecognitionTabPathByTab[tab],
        badge,
        label: (() => {
          switch (tab) {
            case RecognitionPageTab.MySummary:
              return "My Summary";
            case RecognitionPageTab.AwardedPoints:
              return "Awarded Points";
            case RecognitionPageTab.MyScheduledPoints:
              return "My Scheduled Points";
            default:
              assertNever(tab);
          }
        })(),
      }))}
    />
  );
}
