/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  darken,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";

import { ContainerContent } from "./container-content";

interface FormFieldOutlineProps {
  label: string;
  name: string;
  description?: string;
  children?: ReactNode;
  divider?: boolean;
  width?: "full" | "auto";
}

export function FormFieldOutline({
  label,
  name,
  description,
  children,
  divider = true,
  width = "full",
}: FormFieldOutlineProps): JSX.Element {
  return (
    <FormControl
      css={(theme: AppTheme) => css`
        margin-bottom: ${theme.spacing(4)};
        ${width === "full"
          ? css`
              width: 100%;
            `
          : css`
              width: 650px;
            `}
      `}
      variant="outlined"
    >
      <InputLabel htmlFor={name} shrink>
        {label}
      </InputLabel>
      <OutlinedInput
        id={name}
        label={label}
        inputComponent={ContainerContent as any}
        inputProps={{
          description: description,
          children: children,
          divider: divider,
        }}
        classes={{
          notchedOutline: "content-container-notched-outline",
        }}
        notched
        fullWidth
        css={(theme: AppTheme) => css`
          cursor: default;

          .content-container-notched-outline,
          &:hover .content-container-notched-outline {
            border-color: ${theme.palette.divider};
          }

          &:hover
            .custom-root:hover:not(.Mui-focused):not(.Mui-error)
            .custom-notched-outline {
            border-color: ${darken(theme.palette.divider, 0.15)};
          }

          &:hover .custom-root.Mui-disabled .custom-notched-outline,
          &:hover .custom-root:not(.Mui-focused) .custom-notched-outline {
            border-color: ${theme.palette.divider};
          }

          &:hover .custom-root.Mui-focused .custom-notched-outline {
            border-width: 1px;
          }

          &:hover .custom-root.Mui-error .custom-notched-outline {
            border-color: ${theme.palette.error.main};
          }
        `}
      />
    </FormControl>
  );
}
