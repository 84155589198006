import { ApolloError, useApolloClient } from "@apollo/client";
import { useCallback, useEffect } from "react";

import { reportError } from "@rewards-web/shared/modules/error";
import {
  useFeatureFlagsInitialized,
  useFeatureFlagUser,
} from "@rewards-web/shared/modules/feature-flag";

import { useCognitoAuth } from "../../../../shared/modules/cognito-auth";
import {
  AdminAppAnalyticsAttributesDocument,
  AdminAppAnalyticsAttributesQuery,
} from "../use-identify-user-for-screen-recording/analytics-attributes.generated";

/**
 * Initializes feature flags for the currently logged-in user
 */
export function useInitializeFeatureFlagsForUser() {
  const { userId } = useCognitoAuth();
  const apolloClient = useApolloClient();
  const initialized = useFeatureFlagsInitialized();
  const [featureFlagUser, setFeatureFlagUser] = useFeatureFlagUser();

  const loadAttributesAndSetFeatureFlagUser = useCallback(async () => {
    try {
      const {
        data,
      } = await apolloClient.query<AdminAppAnalyticsAttributesQuery>({
        query: AdminAppAnalyticsAttributesDocument,
        fetchPolicy: "cache-first",
      });

      setFeatureFlagUser({
        userId: userId!,
        attributes: {
          organization_id: Number(data.getMyRewardsOrganization.id),
          organization_is_qa: data.getMyRewardsOrganization.isQA,
          organization_is_demo: data.getMyRewardsOrganization.isDemo,
        },
      });
    } catch (error) {
      if (
        !(error instanceof ApolloError) ||
        !error.graphQLErrors.some((error) =>
          error.message.includes("User does not have a tenant")
        )
      ) {
        // don't report the error if it's due to not having a selected tenant
        // (since this only occurs when superusers are logged in)
        reportError(error);
      }

      setFeatureFlagUser({ userId: userId!, attributes: {} });
    }
  }, [apolloClient, setFeatureFlagUser, userId]);

  const shouldLoadUser = userId && userId !== featureFlagUser?.userId;

  useEffect(() => {
    if (shouldLoadUser) {
      loadAttributesAndSetFeatureFlagUser();
    }
  }, [shouldLoadUser, loadAttributesAndSetFeatureFlagUser]);

  return {
    initialized: initialized && !shouldLoadUser,
  };
}
