/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Divider } from "@rewards-web/shared/components/divider";
import { FormControlProvider } from "@rewards-web/shared/components/form/form-control";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalErrorState } from "@rewards-web/shared/components/modal/modal-error-state";
import { ModalLoadingState } from "@rewards-web/shared/components/modal/modal-loading-state";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { CandidateSummaryCard } from "./candidate-summary/candidate-summary-card";
import { CandidateSummaryLabel } from "./candidate-summary/candidate-summary-label";
import { useConfirmCandidateEmployeeLinkDataQuery } from "./confirm-candidate-employee-link-data.generated";
import { EmployeeSummaryCard } from "./employee-summary/employee-summary-card";
import { EmployeeSummaryLabel } from "./employee-summary/employee-summary-label";
import { InfoIcon } from "./icons/info-icon";
import { LinkIcon } from "./icons/link-icon";

interface ConfirmCandidateEmployeeLinkModalProps {
  open: boolean;
  confirming: boolean;
  onConfirm(): void;
  onCancel(): void;
  candidateId: string;
  employeeId: string;
  organizationShortName: string;
}

export function ConfirmCandidateEmployeeLinkModal({
  open,
  confirming,
  onConfirm,
  onCancel,
  candidateId,
  employeeId,
  organizationShortName,
}: ConfirmCandidateEmployeeLinkModalProps): JSX.Element {
  const theme = useTheme();
  const confirmCandidateEmployeeLinkData = useConfirmCandidateEmployeeLinkDataQuery(
    {
      onError: reportError,
      variables: {
        candidateId,
        employeeId,
      },
    }
  );

  const content = (() => {
    if (confirmCandidateEmployeeLinkData.error) {
      return <ModalErrorState onClose={onCancel} />;
    }

    if (!confirmCandidateEmployeeLinkData.data) {
      return <ModalLoadingState />;
    }

    if (
      !confirmCandidateEmployeeLinkData.data.candidate ||
      !confirmCandidateEmployeeLinkData.data.employee
    ) {
      return <ModalErrorState onClose={onCancel} />;
    }

    const {
      data: { candidate, employee },
    } = confirmCandidateEmployeeLinkData;

    return (
      <>
        <ModalTitle>Confirm linking of candidate and employee</ModalTitle>

        <ModalContent>
          <Typography variant="body">
            This link will allow us to notify {organizationShortName} when the
            referring employee should receive referral points.
          </Typography>

          <div
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                flex-grow: 1;
              `}
            >
              <CandidateSummaryLabel />

              <CandidateSummaryCard candidate={candidate} />
            </div>

            <div
              css={(theme: AppTheme) => css`
                margin-top: ${theme.spacing(8)};
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-width: 160px;
              `}
            >
              <LinkIcon width={60} height={60} />
            </div>

            <div
              css={css`
                display: flex;
                flex-direction: column;
                flex-grow: 1;
              `}
            >
              <EmployeeSummaryLabel />

              <EmployeeSummaryCard
                employee={employee}
                jobTitle={candidate.assignedToJobPosting?.title}
              />
            </div>
          </div>
        </ModalContent>

        <Divider
          css={(theme: AppTheme) => css`
            margin: ${theme.spacing(2)};
          `}
        />

        <div
          css={(theme: AppTheme) => css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: ${theme.spacing(1)};
          `}
        >
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              align-items: center;
              margin-left: ${theme.spacing(2)};
            `}
          >
            <InfoIcon fill={theme.palette.secondary.main} />

            <Typography
              variant="body"
              css={(theme: AppTheme) => css`
                color: ${theme.palette.secondary.main};
                margin-left: ${theme.spacing(1)};
              `}
            >
              You cannot undo this link.
            </Typography>
          </div>

          <ModalActions>
            <Button
              size="large"
              label="Cancel"
              variant="outlined"
              onClick={() => {
                onCancel();
              }}
            />
            <Button
              size="large"
              label="Confirm"
              color="primary"
              loading={confirming}
              onClick={onConfirm}
            />
          </ModalActions>
        </div>
      </>
    );
  })();

  return (
    <FormControlProvider value={{ submitting: confirming }}>
      <Modal
        width="800px"
        open={open}
        onClose={onCancel}
        disableBackdropClick={confirming}
      >
        {content}
      </Modal>
    </FormControlProvider>
  );
}
