import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PageBannersDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PageBannersDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'referralsEnabled'>
  ), recognitionCategoriesWithDefaultAmount: Array<(
    { __typename?: 'RecognitionCategory' }
    & Pick<Types.RecognitionCategory, 'id'>
  )>, myAdmin?: Types.Maybe<(
    { __typename?: 'RewardsAdminUser' }
    & Pick<Types.RewardsAdminUser, 'id'>
    & { permissions: { __typename?: 'RewardsAdminFullAccessPermissions' } | (
      { __typename?: 'RewardsAdminRestrictedAccessPermissions' }
      & Pick<Types.RewardsAdminRestrictedAccessPermissions, 'type'>
      & { permissionsV2?: Types.Maybe<(
        { __typename?: 'RewardsAdminRestrictedAccessPermissionsMapV2' }
        & { recognitionPoints?: Types.Maybe<(
          { __typename?: 'RewardsAdminRestrictedAccessRecognitionPointsPermissions' }
          & Pick<Types.RewardsAdminRestrictedAccessRecognitionPointsPermissions, 'canOverrideRecognitionCategoryDefaultAmounts'>
        )> }
      )> }
    ) }
  )> }
);


export const PageBannersDataDocument = gql`
    query PageBannersData {
  getMyRewardsOrganization {
    id
    referralsEnabled
  }
  recognitionCategoriesWithDefaultAmount: getMyRewardsOrganizationRecognitionCategories(
    filter: {disableSendFromManagers: false, hasDefaultAmount: true}
  ) {
    id
  }
  myAdmin: getMyRewardsAdminUser {
    id
    permissions {
      ... on RewardsAdminRestrictedAccessPermissions {
        type
        permissionsV2 {
          recognitionPoints {
            canOverrideRecognitionCategoryDefaultAmounts
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePageBannersDataQuery__
 *
 * To run a query within a React component, call `usePageBannersDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageBannersDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageBannersDataQuery({
 *   variables: {
 *   },
 * });
 */
export function usePageBannersDataQuery(baseOptions?: Apollo.QueryHookOptions<PageBannersDataQuery, PageBannersDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageBannersDataQuery, PageBannersDataQueryVariables>(PageBannersDataDocument, options);
      }
export function usePageBannersDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageBannersDataQuery, PageBannersDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageBannersDataQuery, PageBannersDataQueryVariables>(PageBannersDataDocument, options);
        }
export type PageBannersDataQueryHookResult = ReturnType<typeof usePageBannersDataQuery>;
export type PageBannersDataLazyQueryHookResult = ReturnType<typeof usePageBannersDataLazyQuery>;
export type PageBannersDataQueryResult = Apollo.QueryResult<PageBannersDataQuery, PageBannersDataQueryVariables>;