import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DefaultCandidateAlertEmailQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DefaultCandidateAlertEmailQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'defaultCandidateAlertEmail'>
  ), listUniqueCandidateAlertEmails: (
    { __typename?: 'ListUniqueCandidateAlertEmailsResponse' }
    & Pick<Types.ListUniqueCandidateAlertEmailsResponse, 'emails'>
  ) }
);


export const DefaultCandidateAlertEmailDocument = gql`
    query DefaultCandidateAlertEmail {
  getMyRewardsOrganization {
    id
    defaultCandidateAlertEmail
  }
  listUniqueCandidateAlertEmails {
    emails
  }
}
    `;

/**
 * __useDefaultCandidateAlertEmailQuery__
 *
 * To run a query within a React component, call `useDefaultCandidateAlertEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultCandidateAlertEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultCandidateAlertEmailQuery({
 *   variables: {
 *   },
 * });
 */
export function useDefaultCandidateAlertEmailQuery(baseOptions?: Apollo.QueryHookOptions<DefaultCandidateAlertEmailQuery, DefaultCandidateAlertEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DefaultCandidateAlertEmailQuery, DefaultCandidateAlertEmailQueryVariables>(DefaultCandidateAlertEmailDocument, options);
      }
export function useDefaultCandidateAlertEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DefaultCandidateAlertEmailQuery, DefaultCandidateAlertEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DefaultCandidateAlertEmailQuery, DefaultCandidateAlertEmailQueryVariables>(DefaultCandidateAlertEmailDocument, options);
        }
export type DefaultCandidateAlertEmailQueryHookResult = ReturnType<typeof useDefaultCandidateAlertEmailQuery>;
export type DefaultCandidateAlertEmailLazyQueryHookResult = ReturnType<typeof useDefaultCandidateAlertEmailLazyQuery>;
export type DefaultCandidateAlertEmailQueryResult = Apollo.QueryResult<DefaultCandidateAlertEmailQuery, DefaultCandidateAlertEmailQueryVariables>;