class InvariantError extends Error {}

/**
 * Throws an `InvariantError` with the given `message` if the function is called
 */
export function assertNever(
  value: never,
  message?: string | (() => string)
): never {
  throw new InvariantError(typeof message === "function" ? message() : message);
}
