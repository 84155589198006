import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GiveRecognitionPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GiveRecognitionPageDataQuery = (
  { __typename?: 'Query' }
  & { myIdentity?: Types.Maybe<(
    { __typename?: 'Identity' }
    & Pick<Types.Identity, 'id'>
    & { roles: Array<(
      { __typename?: 'IdentityRole' }
      & Pick<Types.IdentityRole, 'id' | 'roleName' | 'active'>
    )> }
  )>, myAdmin?: Types.Maybe<(
    { __typename?: 'RewardsAdminUser' }
    & Pick<Types.RewardsAdminUser, 'id'>
    & { permissions: { __typename?: 'RewardsAdminFullAccessPermissions' } | (
      { __typename?: 'RewardsAdminRestrictedAccessPermissions' }
      & { permissionsV2?: Types.Maybe<(
        { __typename?: 'RewardsAdminRestrictedAccessPermissionsMapV2' }
        & { recognitionPoints?: Types.Maybe<(
          { __typename?: 'RewardsAdminRestrictedAccessRecognitionPointsPermissions' }
          & Pick<Types.RewardsAdminRestrictedAccessRecognitionPointsPermissions, 'canOverrideRecognitionCategoryDefaultAmounts' | 'canOverrideBudget'>
        )> }
      )> }
    ) }
  )>, myOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'pointsPerDollar' | 'recognitionRubricFileUrl' | 'recognitionBudgetsEnabled'>
  ) }
);


export const GiveRecognitionPageDataDocument = gql`
    query GiveRecognitionPageData {
  myIdentity: getMyIdentity {
    id
    roles {
      id
      roleName
      active
    }
  }
  myAdmin: getMyRewardsAdminUser {
    id
    permissions {
      ... on RewardsAdminRestrictedAccessPermissions {
        permissionsV2 {
          recognitionPoints {
            canOverrideRecognitionCategoryDefaultAmounts
            canOverrideBudget
          }
        }
      }
    }
  }
  myOrganization: getMyRewardsOrganization {
    id
    pointsPerDollar
    recognitionRubricFileUrl
    recognitionBudgetsEnabled
  }
}
    `;

/**
 * __useGiveRecognitionPageDataQuery__
 *
 * To run a query within a React component, call `useGiveRecognitionPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiveRecognitionPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiveRecognitionPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGiveRecognitionPageDataQuery(baseOptions?: Apollo.QueryHookOptions<GiveRecognitionPageDataQuery, GiveRecognitionPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GiveRecognitionPageDataQuery, GiveRecognitionPageDataQueryVariables>(GiveRecognitionPageDataDocument, options);
      }
export function useGiveRecognitionPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GiveRecognitionPageDataQuery, GiveRecognitionPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GiveRecognitionPageDataQuery, GiveRecognitionPageDataQueryVariables>(GiveRecognitionPageDataDocument, options);
        }
export type GiveRecognitionPageDataQueryHookResult = ReturnType<typeof useGiveRecognitionPageDataQuery>;
export type GiveRecognitionPageDataLazyQueryHookResult = ReturnType<typeof useGiveRecognitionPageDataLazyQuery>;
export type GiveRecognitionPageDataQueryResult = Apollo.QueryResult<GiveRecognitionPageDataQuery, GiveRecognitionPageDataQueryVariables>;