import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { reportError } from "@rewards-web/shared/modules/error";

import { useMyBranches } from "../../../shared/modules/branches/use-my-branches";
import { HiringPipeline } from "./hiring-pipeline";
import { useJobPostingListQuery } from "./job-posting-list-query.generated";
import { OrganizationStats } from "./organization-stats";
import { useOrganizationStatsQuery } from "./organization-stats-query.generated";
import { useOrganizationWorkDevicesQuery } from "./organization-work-devices.generated";

export function HomePageContents() {
  const myBranchesQuery = useMyBranches();

  const jobPostingListQuery = useJobPostingListQuery({
    fetchPolicy: "cache-and-network",
    onError: reportError,
    skip: !myBranchesQuery.data,
    variables: {
      branchIds:
        (myBranchesQuery.data?.getMyRewardsAdminUser?.branches ?? []).length > 0
          ? myBranchesQuery.data!.getMyRewardsAdminUser!.branches.map(
              (branch) => branch.id
            )
          : null,
    },
  });

  const organizationStatsQuery = useOrganizationStatsQuery({
    fetchPolicy: "cache-and-network",
    onError: reportError,
  });

  const organizationQuery = useOrganizationWorkDevicesQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
  });

  if (
    [
      jobPostingListQuery,
      organizationStatsQuery,
      organizationQuery,
      myBranchesQuery,
    ].some((query) => query.error)
  ) {
    return (
      <Alert
        severity="error"
        message="An unexpected error occurred. Please try again later."
      />
    );
  }
  if (
    [
      jobPostingListQuery,
      organizationStatsQuery,
      organizationQuery,
      myBranchesQuery,
    ].some((query) => !query.data || query.loading)
  ) {
    return <PageLoadingState />;
  }

  const jobPostings =
    jobPostingListQuery.data?.getNumActiveCandidatesByJobPostingAndStatus.items;

  const organizationStats =
    organizationStatsQuery.data?.getRewardsOrganizationStats;

  const usesWorkDevices =
    organizationQuery.data?.getMyRewardsOrganization.usesWorkDevices;

  return (
    <>
      <OrganizationStats
        usesWorkDevices={usesWorkDevices!}
        organizationStats={organizationStats!}
      />
      <HiringPipeline jobPostings={jobPostings!} />
    </>
  );
}
